import { Component, ElementRef, Injector, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from '../../environments/environment';
import * as Services from '../services';
import * as Constants from '../utils/constants';
import * as Helpers from '../utils/helpers';
import { AppRoutes } from '../models/constants/app-routes.constant';
import { Subject, Subscription } from 'rxjs';
import { AccountStatus } from '@usgm/usgm-payloads-library-front/Enums';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AccountSuspendedDialogComponent } from '../dialogs';
import { takeUntil } from 'rxjs/operators';
import { CaptchaBaseComponent } from '../captcha-base-component/captcha-base-component.component';

@Component({
  selector: 'usgm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends CaptchaBaseComponent implements OnInit {
  public loading = false;
  public imgSrc = Constants.USGM_LOGO;
  public loginForm: FormGroup;
  public mfaForm: FormGroup;
  public fbSignupUrl = '';
  public googleSignupUrl = '';
  public isWeb = true;
  public routes = JSON.parse(JSON.stringify(AppRoutes));
  public passwordFieldInputType = 'password';
  public isMobileView = false;
  public amazonSignupUrl = '';
  public isCordovaAvailable = false;
  public captchaResolutionValue: string = environment.e2e ? 'dummyResolution' : null;
  public amazonLoginView: any;
  public disableSocialLogins;
  public showMFA = false;
  private unsubscribeService: Subject<any> = new Subject();
  public mfaCountdownCounter;
  private mfaCounterInterval;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private http: Services.UsgmHttp,
    public router: Router,
    private apiMapping: Services.ApiMapping,
    public override notificationService: Services.NotificationService,
    private userDataService: Services.UserDataService,
    private location: Location,
    private ref: ElementRef,
    // private fb: Facebook,
    // private googlePlus: GooglePlus,
    // private plt: Platform,
    // private iab: InAppBrowser,
    private ngZone: NgZone,
    public override recaptchaV3Service: ReCaptchaV3Service,
    public override dialog: MatDialog,
    protected injector: Injector,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
  }

  public override ngOnInit() {
    this.userDataService.showCaptcha();
    // if (this.plt.platforms().indexOf('cordova') >= 0) {
    //   this.isCordovaAvailable = true;
    // }
    if (this.ref.nativeElement.offsetWidth < 900) {
      this.imgSrc = Constants.USGM_MINI_LOGO;
      this.isMobileView = true;
    }
    const successRef = `/${AppRoutes.inbox}`;
    this.userDataService.redirectLegacyUser();
    const warehouseId = this.userDataService.getWarehouseId() || '1';
    this.disableSocialLogins = this.userDataService.shouldDisableSocialLogins();
    this.fbSignupUrl = this.apiMapping.facebookAuth() + Helpers.currentUrlAsRef(successRef, this.router.url.split('?')[0], warehouseId);
    this.googleSignupUrl = this.apiMapping.googleAuth() + Helpers.currentUrlAsRef(successRef, this.router.url.split('?')[0], warehouseId);
    this.amazonSignupUrl = this.apiMapping.amazonAuth() + Helpers.currentUrlAsRef(successRef, this.router.url.split('?')[0], warehouseId);
    const tempUser = this.userDataService.getTemporaryUserDetails();
    const email = tempUser ? tempUser.email : '';
    const token = tempUser ? tempUser.token : '';
    this.loginForm = this.formBuilder.group({
      email: [email, [Validators.required, Validators.pattern(Helpers.emailPattern)]],
      password: [token, [Validators.required]],
      rememberSessionPref: [false, []],
    });
    this.mfaForm = this.formBuilder.group({
      code: ['', [Validators.required]],
    });
    if (email && token) {
      this.loginUser();
    }
    this.activatedRoute.queryParams.subscribe(params => {
      setTimeout(() => {
        if (params['error']) {
          this.notificationService.showError('Failed to login. Error: ' + params['message']);
        }
        if (params['mfa_temp_token']) {
          this.handleLoginSuccessWithMFATempToken(
            {
              mfa_temp_token: params['mfa_temp_token'],
              user_id: params['user_id'],
            },
            true,
          );
          this.router.navigate([AppRoutes.login]);
        }
      }, 100);
    });
    if (this.userDataService.getNsLoggedinFbUser().token) {
      this.fbUsgmLogin();
    }
  }

  public backBtnClicked() {
    this.location.back();
  }

  loginUser() {
    Object.keys(this.loginForm.controls).forEach(key => {
      this.loginForm.get(key).markAsDirty();
    });
    if (this.loginForm.invalid) {
      return;
    } // stop here if form is invalid

    this.downloadFormsFirstCall(this.loginCall.bind(this));
  }

  loginCall(token, captchaVersion) {
    this.loading = true;
    this.http
      .http_post(this.apiMapping.loginUser(token, captchaVersion), {
        email: this.loginForm.get('email').value,
        password: this.loginForm.get('password').value,
      })
      .then(
        (data: any) => {
          if (data['error']) {
            this.notificationService.showError('Unable to login. Please try again.');
            this.loading = false;
          } else if (data['token']) {
            this.onAfterLoginOrMFASubmit(data, false);
          } else if (data['mfa_temp_token']) {
            this.handleLoginSuccessWithMFATempToken(data, false);
          }
        },
        (error: any) => {
          this.loading = false;
          if (error.status === 422) {
            this.verifyCaptcha(this.loginCall.bind(this));
          } else {
            if (error.status === 401) {
              if (error.error?.revoked) {
                this.openAccountSuspendedPopup(true);
              } else {
                this.notificationService.showError('Unable to login. ' + ((error.error || {})['message'] || 'Please try again.'));
              }
            } else {
              this.notificationService.showError('Unable to login. Please try again.');
            }
          }
        },
      );
  }

  onAfterLoginOrMFASubmit(data, isSocialLogin) {
    this.userDataService.removeTemporaryUserDetails();
    this.handleLoginSuccessWithSessionData(data, isSocialLogin);
    if (data.account_status === AccountStatus.SUSPENDED) {
      this.openAccountSuspendedPopup(false);
    } else if (data.account_status === AccountStatus.CLOSED) {
      this.openAccountSuspendedPopup(true);
    }
  }

  onHiddenFocus(event, fieldName) {
    if (event.target.value) {
      this.loginForm.controls[fieldName].setValue(event.target.value);
    }
  }

  onHiddenFocusMFA(event, fieldName) {
    if (event.target.value) {
      this.mfaForm.controls[fieldName].setValue(event.target.value);
    }
  }

  async handleLoginSuccessWithSessionData(userData: any, isSocialLogin: boolean) {
    // check account status
    if (this.userDataService.isStatusNew(userData)) {
      this.loading = false;
      return;
    }
    await this.userDataService.setUserSession(userData);
    await this.userDataService.getSubscription();
    await this.userDataService.getUserNotaryPlan();
    this.userDataService.setSocialLoginFlag(isSocialLogin);
    await this.userDataService.setLegacyData(userData);
    this.loading = false;
    this.userDataService.hideCaptcha();
    const accountStatus: AccountStatus = this.userDataService.getAccountStatus();
    if ([AccountStatus.SUSPENDED, AccountStatus.CLOSED].includes(accountStatus)) {
      this.router.navigate([AppRoutes.billingHistory]);
    } else {
      this.router.navigate([AppRoutes.inbox]);
    }
  }

  handleLoginSuccessWithMFATempToken(mfaData: any, isSocialLogin: boolean) {
    this.loading = false;
    this.userDataService.setSocialLoginFlag(isSocialLogin);
    this.userDataService.hideCaptcha();
    this.userDataService.setMFAData(mfaData);
    this.showMFA = true;
    this.startMFAResendCountdown();
  }

  startMFAResendCountdown() {
    this.mfaCountdownCounter = 15; // seconds
    if (this.mfaCounterInterval) {
      this.clearMFAResendInterval();
    }
    this.mfaCounterInterval = setInterval(() => {
      this.mfaCountdownCounter = Math.max(0, this.mfaCountdownCounter - 1);
      if (this.mfaCountdownCounter === 0) {
        this.clearMFAResendInterval();
      }
    }, 1000);
  }

  clearMFAResendInterval() {
    clearInterval(this.mfaCounterInterval);
    this.mfaCounterInterval = null;
  }

  forgotPassword() {
    this.router.navigate([AppRoutes.resetPassword]);
  }

  onFbLogin(eventData) {
    if (eventData.error) {
      console.log('Error during fb login: ' + eventData.error);
    } else {
      this.userDataService.updateNsFbUserDetails('token', eventData.loginResponse.token);
      this.getFbUserDetails();
    }
  }

  getFbUserDetails() {
    this.loading = true;
    const accessToken = this.userDataService.getNsLoggedinFbUser().token;
    this.http.http_get(Constants.FACEBOOK_GRAPH_API_URL + '/me?fields=name,email&access_token=' + accessToken).then(
      userResp => {
        this.userDataService.updateNsFbUserDetails('id', userResp.id);
        this.userDataService.updateNsFbUserDetails('name', userResp.name);
        this.userDataService.updateNsFbUserDetails('email', userResp.email);
        this.loading = false;
        this.fbUsgmLogin();
        this.http.http_get(Constants.FACEBOOK_GRAPH_API_URL + '/' + userResp.userId + '/picture?type=large&redirect=false&access_token=' + accessToken).then(imageResp => {
          this.userDataService.updateNsFbUserDetails('profile_url', (imageResp.data || {}).url);
        });
      },
      err => {
        this.loading = false;
        this.notificationService.showError('Error getting your details from facebook: ' + err);
      },
    );
  }

  fbUsgmLogin() {
    const postData = {
      email: this.userDataService.getNsLoggedinFbUser().email,
      profile_id: this.userDataService.getNsLoggedinFbUser().id,
      token: this.userDataService.getNsLoggedinFbUser().token,
      name: this.userDataService.getNsLoggedinFbUser().name,
      provider: 'facebook',
    };
    this.socialLogin(postData);
  }

  socialLogin(postData) {
    this.loading = true;
    this.http.http_post(this.apiMapping.authNsUser(), postData).then(
      (data: any) => {
        if (data.mfa_temp_token) {
          this.handleLoginSuccessWithMFATempToken(data, true);
        } else if (data['token']) {
          this.onAfterLoginOrMFASubmit(data, true);
        } else {
          this.notificationService.showError('Unable to login. Please try again.');
        }
        this.loading = false;
      },
      (error: any) => {
        console.log(error);
        this.loading = false;
        if (error.status === 401 || error.status === 409) {
          this.notificationService.showError('Unable to login. ' + (error.error.err || 'Please try again.'));
        } else {
          this.notificationService.showError('Unable to login. Please try again.');
        }
      },
    );
  }

  onFbLogout(eventData) {
    if (eventData.error) {
      console.log('Error during fb logout: ' + eventData.error);
    } else {
      this.userDataService.deleteNsFbUser();
    }
  }

  public handleFacebookLoginResponse(user: any, token: any) {
    if (!user.email) {
      this.notificationService.showError('Failed to login. Error: Sign in/Sign up with phone number not supported.');
      return;
    }
    const email = user.email;
    const profileId = user.id;
    const name = user.name;
    this.userDataService.updateNsFbUserDetails('token', token);
    this.userDataService.updateNsFbUserDetails('id', profileId);
    this.userDataService.updateNsFbUserDetails('name', name);
    this.userDataService.updateNsFbUserDetails('email', email);
    this.loading = false;
    const postData = {
      email: this.userDataService.getNsLoggedinFbUser().email,
      profile_id: this.userDataService.getNsLoggedinFbUser().id,
      token: this.userDataService.getNsLoggedinFbUser().token,
      name: this.userDataService.getNsLoggedinFbUser().name,
      provider: 'facebook',
    };
    this.socialLogin(postData);
  }

  public handleGoogleLoginResponse(user: any) {
    const token = user.accessToken;
    const email = user.email;
    const profileId = user.userId;
    const name = user.displayName;
    this.userDataService.updateNsGoogleUserDetails('token', token);
    this.userDataService.updateNsGoogleUserDetails('id', profileId);
    this.userDataService.updateNsGoogleUserDetails('name', name);
    this.userDataService.updateNsGoogleUserDetails('email', email);
    this.loading = false;
    const postData = {
      email: this.userDataService.getNsLoggedinGoogleUser().email,
      profile_id: this.userDataService.getNsLoggedinGoogleUser().id,
      token: this.userDataService.getNsLoggedinGoogleUser().token,
      name: this.userDataService.getNsLoggedinGoogleUser().name,
      provider: 'google',
    };
    this.socialLogin(postData);
  }

  public handleAmazonLoginEvent(event: any) {
    const url: string = event.url;
    if (url.includes('error') && url.includes('message')) {
      const message = url.substring(url.indexOf('message')).split('=')[1].replace(/%20/g, ' ');
      this.amazonLoginView.close();
      this.notificationService.showError(message);
    } else if (url.includes('token')) {
      const params = (url.split('?')[1] || '').split('&');
      const urlParams = {};
      params.forEach(param => {
        const splitParams = param.split('=');
        urlParams[splitParams[0]] = splitParams[1];
      });
      this.amazonLoginView.close();
      this.ngZone.run(() => {
        this.handleLoginSuccessWithSessionData(urlParams, true);
      });
    }
  }

  public navigateToRegisterPage() {
    this.router.navigate([AppRoutes.selectWarehouseByAddress]);
  }

  public goToSelectWarehouse() {
    this.router.navigate([AppRoutes.selectWarehouseByAddress]);
  }

  // public openSignupInBrowser() {
  //   this.iab.create(`${environment.appUrl}/${AppRoutes.selectWarehouseByAddress}`, '_system');
  // }

  // convenience functions for easy access to form fields
  public hasErrors(fieldName: any): boolean {
    return !!this.loginForm.controls[fieldName].errors;
  }

  public hasErrorsMFA(fieldName: any): boolean {
    return !!this.mfaForm.controls[fieldName].errors;
  }

  public hasError(fieldName: any, errorName: any): boolean {
    return this.loginForm.controls[fieldName].hasError(errorName);
  }

  public hasErrorMFA(fieldName: any, errorName: any): boolean {
    return this.mfaForm.controls[fieldName].hasError(errorName);
  }

  public fieldEdited(fieldName: any): boolean {
    return this.loginForm.controls[fieldName].dirty && this.loginForm.controls[fieldName].touched;
  }

  public fieldEditedMFA(fieldName: any): boolean {
    return this.mfaForm.controls[fieldName].dirty && this.mfaForm.controls[fieldName].touched;
  }

  public openAccountSuspendedPopup(revoked?: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '500px';
    dialogConfig.data = { revoked };
    this.dialog.open(AccountSuspendedDialogComponent, dialogConfig);
  }

  public async submitMFACode() {
    Object.keys(this.mfaForm.controls).forEach(key => {
      this.mfaForm.get(key).markAsDirty();
    });
    if (this.mfaForm.invalid) {
      return;
    } // stop here if form is invalid
    const enteredCode = this.mfaForm.get('code').value;
    this.loading = true;
    this.userDataService
      .submitMFACode(enteredCode)
      .pipe(takeUntil(this.unsubscribeService))
      .subscribe(
        mfaResponse => {
          this.loading = false;
          if (mfaResponse?.token) {
            const isSocialLogin = this.userDataService.isSocialLogin();
            this.onAfterLoginOrMFASubmit(mfaResponse, isSocialLogin);
          } else {
            this.notificationService.showError('Invalid verification code');
          }
        },
        (error: any) => {
          this.notificationService.showError(error.error?.message || 'Invalid verification code');
          this.loading = false;
        },
      );
  }

  public resendVerificationCode() {
    this.loading = true;
    const mfaData = this.userDataService.getMFAData();
    this.userDataService
      .generateAndSendMFACode(mfaData.user_id)
      .pipe(takeUntil(this.unsubscribeService))
      .subscribe(
        mfaResponse => {
          this.loading = false;
          if (mfaResponse && mfaResponse['data']) {
            this.userDataService.setMFAData({
              ...mfaData,
              mfa_temp_token: mfaResponse['data'],
            });
            this.notificationService.showSuccess('A new verification code has been sent to you over email');
            this.startMFAResendCountdown();
          } else {
            this.notificationService.showError('Something went wrong in generating and sending a verification code');
          }
        },
        (error: any) => {
          this.notificationService.showError(error.error?.message || 'Something went wrong in generating and sending a verification code');
          this.loading = false;
        },
      );
  }

  public navigateToGoogleSignInURL() {
    window.location.href = this.googleSignupUrl;
  }
}
