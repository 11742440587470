import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OnlineNotarizationPopupComponent } from '../online-notarization-popup/online-notarization-popup.component';
import * as Services from '../../services';
import { VerifyAccountService } from '../verify-account.service';
import { takeUntil, retry } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Relation } from '../../models/get-notarized.model';
import { Router } from '@angular/router';
import { AppRoutes } from '../../models/constants/app-routes.constant';

@Component({
  selector: 'usgm-notarize-with-notarycam',
  templateUrl: './notarize-with-notarycam.component.html',
  styleUrls: ['./notarize-with-notarycam.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotarizeWithNotarycamComponent implements OnInit {
  private unSubscriber: Subject<any> = new Subject();
  public dialogeRef: any;
  public uploadForm = true;
  public userFile = null;
  public userNames = [];
  public forms = [];
  public uploadFormNotarizedComplete = false;
  public selectedId: string;
  public settingPageLoad: boolean;
  public usersFromAccountVerificationsIds = [];

  @Input() data;
  @Input() downLoadingPdf;
  @Output() clearForm = new EventEmitter<string>();
  @Output() navigateBackToForm = new EventEmitter<any>();
  @Output() downloadZipForm = new EventEmitter<any>();
  @ViewChild('notaryLink') notaryLink: ElementRef;

  constructor(
    private matDialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    protected notificationService: Services.NotificationService,
    protected verifyAccountService: VerifyAccountService,
    protected verifyService: Services.VerifyAccountService,
    protected userDataService: Services.UserDataService,
    private router: Router,
    protected apiMapping: Services.ApiMapping,
  ) {}

  @Input() set isNotarizedSelected(value: boolean) {
    if (value) {
      this.uploadFormNotarizedComplete = value;
    }
  }

  public ngOnInit() {
    this.getAddedName();
    this.verifyService.settingsNameId.subscribe(value => {
      if (value) {
        this.settingPageLoad = value.isSettings;
        this.selectedId = value.id;
      } else {
        this.settingPageLoad = false;
        this.selectedId = null;
      }
      this.cdRef.detectChanges();
    });
  }

  onlineNotarizationPopup(url): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      url,
    };
    this.dialogeRef = this.matDialog.open(OnlineNotarizationPopupComponent, dialogConfig);
    this.dialogeRef.afterClosed().subscribe((result: string) => {
      if (result === `success`) {
        this.sendNotaryRequest();
        this.cdRef.detectChanges();
      }
    });
  }

  public getAddedName(): void {
    this.verifyService
      .getName(this.apiMapping.getAccountNamesWithOnlyStatus())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(apiResponse => {
        if (apiResponse) {
          this.usersFromAccountVerificationsIds = apiResponse.data.accountNames.filter(user => user.is_from_account_verification).map(user => user.user.uuid);
          for (const user of apiResponse.data.accountNames) {
            if (user.relation.relationType === Relation.MINOR_CHILD) {
              this.userNames = this.userNames.map(item => {
                if (item.relation) {
                  item.name = item.name + `, ${user.user.name} (${user.relation.relationType})`;
                }
                return item;
              });
            } else {
              this.userNames.push({
                name: user.user.name + ` (${user.relation.relationType})`,
                id: user.user.uuid,
                relation: user.relation.relationType,
              });
            }
          }
          this.cdRef.detectChanges();
        }
      });
  }

  downloadAllForms() {
    if (this.settingPageLoad && this.selectedId) {
      this.downloadZipForm.emit(this.selectedId);
    } else {
      this.downloadZipForm.emit(null);
    }
  }

  public sendNotaryRequest(): void {
    this.downLoadingPdf = true;
    this.verifyService
      .saveNotaryRequest(this.apiMapping.saveNotaryRequest(this.data.uuid), { notaryRequestForUsers: this.usersFromAccountVerificationsIds })
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        apiResponse => {
          this.downLoadingPdf = false;
          this.verifyAccountService.verificationCompleteEvent(true);
          this.userDataService.setNotaryPlan(`notarizecam`);
          this.uploadForm = false;
          this.uploadFormNotarizedComplete = true;
          this.cdRef.detectChanges();
        },
        error => {
          this.downLoadingPdf = false;
          this.cdRef.detectChanges();
          this.notificationService.showError(error.message);
        },
      );
  }

  scrollToTop(): void {
    const stepperElement = document.getElementById('stepper');
    if (stepperElement) {
      stepperElement.scrollIntoView();
    }
  }

  public removeVerifyTabFromList() {
    this.router.navigate([`/${AppRoutes.inbox}`]);
  }

  goBack() {
    this.uploadForm = false;
    this.scrollToTop();
  }

  clearFormValue(): void {
    this.navigateBackToForm.emit();
  }
}
