// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-warning {
  color: #ed843c;
  background-color: #fceee4;
  border-color: #fceee4;
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/id-rejection-resolve/id-rejection-resolve.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,yBAAA;EACA,qBAAA;AACF","sourcesContent":[".alert-warning {\n  color: #ed843c;\n  background-color: #fceee4;\n  border-color: #fceee4;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
