import { Component, EventEmitter, OnInit, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import * as moment from 'moment-timezone';
import { ScanBundle } from '../../settings-page/manage-scan-bundles/manage-scan-bundles.component';
import * as Services from '../../services';
import * as SharedHelpers from '../../utils/helpers';
import { mapUserScanBundle } from '../../utils/helpers';
import * as SettingsConstants from '../../models/constants/settings-options.constant';

export interface UserFreeScan {
  numPages: number;
  validTill: string;
}

@Component({
  selector: 'usgm-scan-options',
  templateUrl: './scan-options.component.html',
  styleUrls: ['./scan-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanOptionsComponent implements OnInit, OnDestroy {
  public singleItemView = true;
  public loading = false;
  public requestSubmittedView = false;
  public userSelectedScanBundle: ScanBundle = null;
  public userFreeScans: UserFreeScan = null;
  public scanBundleApplied = false;
  public showScanBundleSubscriptionInstruction = false;
  public loadingScanBundleData = true;
  public SettingsNavOptions = SettingsConstants.SettingNavViewOptions;

  private _items: any[] = [];
  get items(): any[] {
    return this._items;
  }

  @Input() set items(value: any[]) {
    this._items = value || [];
    this.singleItemView = this._items.length === 1;
  }

  @Input() scanPrices: any;
  @Output() closeNav = new EventEmitter();
  @Output() updateItemList = new EventEmitter();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected http: Services.UsgmHttp,
    protected apiMapping: Services.ApiMapping,
    public userDataService: Services.UserDataService,
    protected notificationService: Services.NotificationService,
  ) {}

  async ngOnInit() {
    try {
      const userFreeScans: any = await this.http.get(this.apiMapping.getUserFreeScans()).toPromise();
      this.userFreeScans = userFreeScans.data?.num_pages
        ? {
            numPages: userFreeScans.data.num_pages,
            validTill: moment(userFreeScans.data.refilled_at).add(1, 'month').format('MM/DD/yyyy'),
          }
        : null;
      const userScanBundle: any = await this.http.get(this.apiMapping.getUserScanBundle()).toPromise();
      this.userSelectedScanBundle = mapUserScanBundle(userScanBundle);
      if (this.userSelectedScanBundle) {
        this.scanBundleApplied = true;
      } else {
        const response = await this.userDataService.getUserSubscription().toPromise();
        if (response && response.length) {
          const subscription = response.find(item => this.userDataService.isActiveSubscription(item));
          if (subscription) {
            const plan = await this.userDataService.getUserPlanById(subscription.plan_id).toPromise();
            if (plan?.other_options?.is_scan_bundles_supported) {
              this.showScanBundleSubscriptionInstruction = true;
            }
          }
        }
      }
      this.loadingScanBundleData = false;
      this.cdr.detectChanges();
    } catch (error) {
      // console.log(error);
    }
  }

  closeSideNav() {
    this.closeNav.emit({});
  }

  confirmScan() {
    this.loading = true;
    this.http.http_post(this.apiMapping.confirmScan(), this.scanPostBody()).then(
      (data: any) => {
        this.requestSubmittedView = true;
        this.loading = false;
        this.updateItemList.emit();
        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        this.loading = false;
        SharedHelpers.detectChanges(this.cdr);
        this.notificationService.showError('Unable to send scan request. Please try again.');
      },
    );
  }

  scanPostBody() {
    const scanPostBody = [];
    this._items.forEach(item => {
      scanPostBody.push({
        id: item.id,
        instruction: item.scanInstructions,
        is_expedite: item.shouldExpedite,
        category_type: 'SCAN_REQUEST',
        user_bundle_id: this.scanBundleApplied ? 1 : null,
      });
    });
    return scanPostBody;
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
