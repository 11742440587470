import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, EventEmitter, Output, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as Services from '../../services';
import { UserType, RecordStatus } from '@usgm/usgm-payloads-library-front';
import { PdfViewerDialogComponent } from '../../components/pdf-viewer-dialog';
import { MatDialog } from '@angular/material/dialog';
import { AccountNamesModel } from '../../models/account-names-model';
import { Relation } from '../../models/get-notarized.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CaptchaBaseComponent } from '../../captcha-base-component/captcha-base-component.component';

@Component({
  selector: 'usgm-id-rejection-resolve',
  templateUrl: './id-rejection-resolve.component.html',
  styleUrls: ['./id-rejection-resolve.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdRejectionResolveComponent extends CaptchaBaseComponent implements OnInit {
  private unSubscriber: Subject<any> = new Subject();
  public documents: any[] = [];
  public loading = false;
  public fileSize = 10 * 1024 * 1024;
  public primaryIdType = '';
  public secondaryIdType = '';
  public allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'image/tiff', 'image/tif'];
  public data: AccountNamesModel;
  public secondaryData: any;
  public relationTypes = Relation;

  @Output() continueEvent: EventEmitter<any> = new EventEmitter();
  @Output() resetEvent: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('primaryId') primaryId: ElementRef;
  @ViewChild('secondaryId') secondaryId: ElementRef;

  @Output() gobackValue = new EventEmitter();

  @Input() selectedId: string;

  @Input() documentType: any[];
  @Input() primaryDocumentType: any[];
  @Input() businessDocumentType: any[];

  @Input() set userData(value) {
    this.data = value;
    if (this.data) {
      if (this.data[0] && this.data[0].user_type === 'PRIMARY') {
        this.primaryIdType = this.data[0].document_type ? this.data[0].document_type.split('_').join(' ') : '';
      }
      if (this.data[1] && this.data[1].user_type === 'SECONDARY') {
        this.secondaryIdType = this.data[1].document_type ? this.data[1].document_type.split('_').join(' ') : '';
      }
    }
    this.cdRef.detectChanges();
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    protected verifyService: Services.VerifyAccountService,
    protected apiMapping: Services.ApiMapping,
    public override notificationService: Services.NotificationService,
    protected userDataService: Services.UserDataService,
    public override dialog: MatDialog,
    public override recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
  }

  public override ngOnInit() {
    this.downloadIDsFirstCall(this.getDocuments.bind(this));
  }

  getDocuments(token, captchVersion) {
    this.verifyService
      .getName(this.apiMapping.getIDsDocumentsOfUser(undefined, token, captchVersion))
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        response => {
          if (response && response.documents && response.documents.length > 0) {
            const data = response.documents;
            const currentUser = data.find((item: any) => item.user_id === this.selectedId);
            if (currentUser !== undefined && currentUser.documents) {
              this.documents = currentUser.documents;
              this.primaryIdType = '';
              this.secondaryIdType = '';
              if (this.documents[0] && this.documents[0].user_type === 'PRIMARY') {
                this.primaryIdType = this.documents[0].document_type ? this.documents[0].document_type.split('_').join(' ') : '';
              }
              if (!this.documents[1] && this.documents[0] && this.documents[0].user_type === 'SECONDARY') {
                this.secondaryDocValue();
              }
              if (this.documents[1] && this.documents[1].user_type === 'SECONDARY') {
                this.secondaryIdType = this.documents[1].document_type ? this.documents[1].document_type.split('_').join(' ') : '';
              }
              if (this.data && this.data.relation) {
                const statusAndNotes = this.processDocumentStatus(this.data.relation, this.documents);
                this.data.status = statusAndNotes.status;
              }
            }
            this.cdRef.detectChanges();
          }
        },
        error => {
          if (error.status === 422) {
            this.verifyCaptcha(this.getDocuments.bind(this));
          }
        },
      );
  }

  secondaryDocValue() {
    this.secondaryData = {
      document_type: this.documents[0].document_type,
      document_url: this.documents[0].document_url,
      user_type: this.documents[0].user_type,
      files: this.documents[0].files,
      status: this.documents[0].status,
      notes: this.documents[0].notes,
      id: this.documents[0].id,
    };
    this.secondaryIdType = this.documents[0].document_type ? this.documents[0].document_type.split('_').join(' ') : '';
    this.documents[1] = this.secondaryData;
    this.documents[1]['files'] = this.documents[0].files;

    this.documents[0]['document_type'] = '';
    this.documents[0]['document_url'] = '';
    this.documents[0]['user_type'] = 'PRIMARY';
    this.documents[0]['files'] = null;
    this.documents[0]['status'] = '';
    this.documents[0]['notes'] = '';
    this.documents[0]['id'] = '';
  }

  deleteFile(index) {
    if (this.documents[index].document_url && this.documents[index].status !== 'APPROVED') {
      this.deleteFileFromServer(this.documents[index].id, index);
    } else if (this.documents[index].files) {
      this.documents[index].files = null;
    }
    this.cdRef.detectChanges();
  }

  deleteFileFromServer(id, index) {
    if (this.loading) {
      return false;
    }
    this.loading = true;
    this.verifyService
      .deleteIDsDocumentsOfUser(this.apiMapping.deleteIDsDocumentsOfUser(id))
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        apiResponse => {
          if (apiResponse) {
            this.notificationService.showSuccess(apiResponse.message);
            this.loading = false;
            if (this.documents[index]) {
              this.documents[index] = null;
              if (index === 1) {
                this.secondaryIdType = '';
              } else {
                this.primaryIdType = '';
              }
            }
            this.resetEvent.emit();
            this.cdRef.detectChanges();
          }
        },
        error => {
          this.loading = false;
          this.cdRef.detectChanges();
          this.notificationService.showError('Error occured while deleting file. Please try again');
        },
      );
  }

  public onContinue() {
    if (!this.primaryIdType || !this.primaryIdType.trim()) {
      this.notificationService.showError('Please select primary id type');
      return false;
    } else if (
      this.data.relation.relationType !== Relation.MINOR_CHILD &&
      this.data.relation.relationType !== Relation.COMPANY &&
      this.data.relation.relationType !== Relation.TRUST &&
      this.data.relation.relationType !== Relation.DECEASED &&
      (!this.secondaryIdType || !this.secondaryIdType.trim())
    ) {
      this.notificationService.showError('Please select secondary id type');
      return false;
    } else if (this.documents.length === 0) {
      this.notificationService.showError('Please upload ids to proceed');
      return false;
    } else if (!this.documents[0]) {
      this.notificationService.showError('Please upload primary id to proceed');
      return false;
    } else if (
      this.data.relation.relationType !== Relation.MINOR_CHILD &&
      this.data.relation.relationType !== Relation.COMPANY &&
      this.data.relation.relationType !== Relation.DECEASED &&
      this.data.relation.relationType !== Relation.TRUST &&
      !this.documents[1].document_url &&
      !this.documents[1].files
    ) {
      this.notificationService.showError('Please upload secondary id to proceed');
      return false;
    } else if (this.data.status === RecordStatus.REJECTED) {
      if ([Relation.MINOR_CHILD, Relation.COMPANY, Relation.DECEASED, Relation.TRUST].includes(this.data.relation.relationType as Relation)) {
        if (!this.documents[0] && !this.documents[0].files) {
          this.notificationService.showError('Please reupload primary id');
          return false;
        }
      } else {
        if (this.documents[0] && this.documents[0].status && this.documents[0].status === RecordStatus.REJECTED && !this.documents[0].files) {
          this.notificationService.showError('Please reupload primary id');
          return false;
        } else if (this.documents[1] && this.documents[1].status && this.documents[1].status === RecordStatus.REJECTED && !this.documents[1].files) {
          this.notificationService.showError('Please reupload secondary id');
          return false;
        } else {
          this.saveIds();
        }
      }
    } else {
      this.saveIds();
    }
  }

  ifUrlIsPdf(url: string) {
    return url.toLowerCase().includes(`.pdf`);
  }

  openFormPreview(id) {
    this.downloadIDsFirstCall(this.downloadIDsFromServer.bind(this), id);
  }

  downloadIDsFromServer(token, captchaVersion, id) {
    this.dialog.open(PdfViewerDialogComponent, {
      maxWidth: 'calc(100vw - 10px)',
      panelClass: 'no-padding',
      data: {
        url: this.apiMapping.downloadIds(id, token, captchaVersion),
        fileType: `pdf`,
        v2CaptchaCallback: () => this.verifyCaptcha(this.downloadIDsFromServer.bind(this), id),
      },
    });
  }

  saveIds() {
    this.loading = true;
    const formData = new FormData();
    const documentTypesString = [];
    const userTypesString = [];
    if (this.documents[0] && this.documents[0].files) {
      formData.append('document_url', this.documents[0].files);
      documentTypesString.push(this.primaryIdType.split(' ').join('_').toUpperCase());
      userTypesString.push(UserType.PRIMARY);
    }
    if (this.documents[1] && this.documents[1].files) {
      documentTypesString.push(this.secondaryIdType.split(' ').join('_').toUpperCase());
      userTypesString.push(UserType.SECONDARY);
      formData.append('document_url', this.documents[1].files);
    }
    formData.append('document_type', `${documentTypesString.join(',')}`);
    formData.append('user_type', `${userTypesString.join(',')}`);

    this.verifyService
      .saveIDsDocumentsOfUser(this.apiMapping.saveIDsDocumentsOfUser(this.data.user.id), formData)
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        apiResponse => {
          if (apiResponse) {
            this.primaryIdType = '';
            this.secondaryIdType = '';
            this.loading = false;
            // this.continueEvent.emit();
            this.goBack();
          }
          this.cdRef.detectChanges();
        },
        error => {
          this.loading = false;
          this.cdRef.detectChanges();
          this.notificationService.showError('Unable to upload IDs!!');
        },
      );
  }

  goBack() {
    this.gobackValue.emit(0);
  }

  onFileDropped(fileEvent: Array<any>, documentType) {
    this.validateFile(fileEvent, documentType);
  }

  validateFile(fileEvent: File[], documentType) {
    const file = fileEvent[0];
    if (file) {
      if (!this.allowedTypes.includes(file.type)) {
        this.notificationService.showWarning('Allowed types are png, jpg, jpeg, pdf, tiff, tif');
        if (documentType === 'secondary') {
          this.secondaryId.nativeElement.value = '';
        } else {
          this.primaryId.nativeElement.value = '';
        }
        return false;
      } else if (file.size > this.fileSize) {
        this.notificationService.showWarning(`uploaded file should be of size less than ${this.fileSize / (1024 * 1024)} MB.`);
        if (documentType === 'secondary') {
          this.secondaryId.nativeElement.value = '';
        } else {
          this.primaryId.nativeElement.value = '';
        }
        return false;
      }
      this.readURL(file, documentType);
    }
  }

  readURL(file, target) {
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        file.data = e.target.result;
        if (target === 'primary') {
          if (!this.documents[0]) {
            this.documents[0] = {
              files: file,
            };
          } else {
            this.documents[0].files = file;
            this.documents[0].document_type = this.primaryIdType.split(' ').join('_').toUpperCase();
          }
          this.cdRef.detectChanges();
        } else {
          if (!this.documents[1]) {
            this.documents[1] = {
              files: file,
            };
          } else {
            this.documents[1].files = file;
            this.documents[1].document_type = this.secondaryIdType.split(' ').join('_').toUpperCase();
          }
          this.cdRef.detectChanges();
        }
      };
      reader.readAsDataURL(file);
    }
  }

  processDocumentStatus(relation, documents) {
    const docStatus = { status: 'Pending' };
    if (documents.length) {
      docStatus.status = ![Relation.MINOR_CHILD, Relation.COMPANY, Relation.DECEASED, Relation.TRUST].includes(relation)
        ? documents.length === 2
          ? 'Complete'
          : 'ID Pending'
        : documents.length === 1
          ? 'Complete'
          : 'ID Pending';
      if ([Relation.MINOR_CHILD, Relation.COMPANY, Relation.DECEASED, Relation.TRUST].includes(relation)) {
        if (documents[0].status) {
          docStatus.status = documents[0].status.split('_').join(' ');
        }
      } else {
        if (documents.length === 2) {
          if (documents[0].status === RecordStatus.REJECTED || (documents[1] && documents[1].status === RecordStatus.REJECTED)) {
            docStatus.status = RecordStatus.REJECTED.split('_').join(' ');
          } else if (documents[0].status === RecordStatus.IN_REVIEW || (documents[1] && documents[1].status === RecordStatus.IN_REVIEW)) {
            docStatus.status = RecordStatus.IN_REVIEW.split('_').join(' ');
          } else if (documents[0].status === RecordStatus.APPROVED && documents[1] && documents[1].status === RecordStatus.APPROVED) {
            docStatus.status = RecordStatus.APPROVED.split('_').join(' ');
          }
        }
      }
    }
    return docStatus;
  }
}
