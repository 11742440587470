import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ApiMapping, NotificationService, UsgmHttp, UserDataService } from '../services';
import * as Constants from '../utils/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'usgm-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeComponent implements OnInit {
  public imgSrc = Constants.USGM_LOGO;
  public mobileMode = false;
  public userData: any = {};

  constructor(
    protected http: UsgmHttp,
    protected apiMapping: ApiMapping,
    protected userDataService: UserDataService,
    protected notificationService: NotificationService,
    public router: Router,
  ) {}

  public ngOnInit() {
    if (this.userDataService.getOnboardingStatus()) {
      this.verifyAccount();
    }
    this.setDisplayMode();
  }

  public setDisplayMode() {
    this.mobileMode = this.userDataService.isMobileMode;
  }

  public verifyAccount() {
    this.router.navigate(['/verify-account']);
  }
}
