export class AddressDataModel {
  addresses: {
    id: number;
    address_type: string;
    city: string;
    country: string;
    createdAt: Date;
    deletedAt: Date;
    is_default: boolean;
    name: string;
    phone_number: string;
    postal_code: string;
    state: string;
    updatedAt: Date;
    user_id: number;
  }[];
}

export class VerifyAccountAddressModel {
  address_line: string;
  address_line_2?: string;
  address_line_3?: string;
  address_type: string;
  city: string;
  country: string;
  createdAt: string;
  deletedAt: string;
  id: number;
  is_default: boolean;
  name: string;
  phone_number: string;
  postal_code: string;
  state: string;
  updatedAt: string;
  user_id: number;
}

export class StateListModel {
  Id: number;
  Name: string;
}

export class FamilyNameModel {
  name: string;
  description: string;
  id: string;
}

export const emptyAddressData = {
  id: 1,
  name: '',
  address_line: '',
  address_line_2: '',
  address_line_3: '',
  city: '',
  country: '',
  state: { GeoNameId: 6252001, Id: 'US', Name: 'United States' },
  postal_code: '',
  phone_number: '',
  is_default: false,
};

export const emptyDeclaration = { description: '', item_value: 0, quantity: 1, is_insured: false };
