import { Component, Inject, ChangeDetectionStrategy, Injector, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserDataService } from '../../services';
import { BaseHeaderComponent } from './base-header.component';
import * as SharedHelpers from '../../utils/helpers';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'usgm-announcements-dialog',
  templateUrl: './announcements-dialog.component.html',
  styleUrls: ['./announcements-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementsDialogComponent extends BaseHeaderComponent implements OnInit {
  private itemsBeingMarkedRead: any = {};

  constructor(
    protected override cdr: ChangeDetectorRef,
    protected override _sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AnnouncementsDialogComponent>,
    protected override userDataService: UserDataService,
    protected override injector: Injector,
  ) {
    super(cdr, userDataService, injector, _sanitizer);
  }

  ngOnInit() {
    this.getUserAnnouncementsForPage();
  }

  announcementClick(announcement) {
    if (announcement.is_read || this.itemsBeingMarkedRead[announcement.id]) {
      return;
    }
    this.itemsBeingMarkedRead[announcement.id] = true;
    this.http.put(this.apiMapping.readUserAnnouncement(announcement.id), {}).subscribe(
      response => {
        announcement.is_read = true;
        delete this.itemsBeingMarkedRead[announcement.id];
        this.getUserAnnouncementsForPage(false);
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {},
    );
  }

  archiveAnnouncement(announcement, index) {
    this.http.put(this.apiMapping.archiveUserAnnouncement(announcement.id), {}).subscribe(
      response => {
        this.userAnnouncements.splice(index, 1);
        this.userDataService.setAnnouncementData(this.userDataService.getAnnouncementData());
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {},
    );
  }

  navigateToPageNo(pageNo) {
    this.userAnnouncementsPage = pageNo;
    this.getUserAnnouncementsForPage();
  }

  isShowMoreEnabled(text: string) {
    const dummyElement = document.createElement('div');
    const el = document.getElementById('announcement-body-0');
    dummyElement.style.font = document.defaultView.getComputedStyle(el, null).getPropertyValue('font');
    dummyElement.style.width = document.defaultView.getComputedStyle(el, null).getPropertyValue('width');
    dummyElement.innerHTML = text;
    document.getElementsByTagName('usgm-app')[0].appendChild(dummyElement);
    const height = dummyElement.getBoundingClientRect().height;
    document.getElementsByTagName('usgm-app')[0].removeChild(dummyElement);
    return height > 42;
  }
}
