import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UsgmHttp } from './usgm-http.service';

@Injectable({
  providedIn: 'root',
})
export class VerifyAccountService {
  public currentStep$: BehaviorSubject<number> = new BehaviorSubject(null);
  public settingsNameId: BehaviorSubject<{
    isSettings: boolean;
    id: string;
  }> = new BehaviorSubject(null);

  public isRejectedId$: BehaviorSubject<boolean>;

  constructor(public http: UsgmHttp) {
    this.isRejectedId$ = new BehaviorSubject<boolean>(false);
  }

  setValue(newValue): void {
    this.isRejectedId$.next(newValue);
  }

  getValue(): Observable<boolean> {
    return this.isRejectedId$.asObservable();
  }

  public addAddress(apiUrl: string, params: any): Observable<any> {
    return this.http.post(apiUrl, params);
  }

  public getAddress(apiUrl: string): Observable<any> {
    return this.http.get(apiUrl);
  }

  public getUserAccountStatus(apiUrl: string): Observable<any> {
    return this.http.get(apiUrl);
  }

  public getStateList(apiUrl: string): Observable<any> {
    return this.http.get(apiUrl);
  }

  public addName(apiUrl: string, params: any): Observable<any> {
    return this.http.post(apiUrl, params);
  }

  public deleteName(apiUrl: string): Observable<any> {
    return this.http.delete(apiUrl);
  }

  public getName(apiUrl: string): Observable<any> {
    return this.http.get(apiUrl);
  }

  public getNamePromise(apiUrl: string): Promise<any> {
    return this.http.get(apiUrl).toPromise();
  }

  public uploadPDF(apiUrl: string, params: any): Observable<any> {
    return this.http.put(apiUrl, params);
  }

  public recreateForm(apiUrl: string): Observable<any> {
    return this.http.get(apiUrl);
  }

  public getPlans(apiUrl: string): Observable<any> {
    return this.http.get(apiUrl);
  }

  public getnotaryPlans(apiUrl: string): Observable<any> {
    return this.http.get(apiUrl);
  }

  public saveNotaryRequest(apiUrl: string, data): Observable<any> {
    return this.http.post(apiUrl, data);
  }

  public saveNotaryRequestPromise(apiUrl: string, data): Promise<any> {
    return this.http.post(apiUrl, data).toPromise();
  }

  public getFormList(apiUrl: string): Observable<any> {
    return this.http.get(apiUrl);
  }

  public getFormListPromise(apiUrl: string): Promise<any> {
    return this.http.get(apiUrl).toPromise();
  }

  public getNotaryPhysicalAddress(apiUrl: string): Observable<any> {
    return this.http.get(apiUrl);
  }

  public saveIDsDocumentsOfUser(apiUrl: string, data: any): Observable<any> {
    return this.http.put(apiUrl, data);
  }

  public deleteIDsDocumentsOfUser(apiUrl: string): Observable<any> {
    return this.http.delete(apiUrl);
  }

  public getUserFormData(apiUrl: string): Observable<any> {
    return this.http.get(apiUrl);
  }

  public setUserFormData(apiUrl: string, params: any): Observable<any> {
    return this.http.post(apiUrl, params);
  }

  public getUserAllForms(apiUrl: string): Observable<any> {
    return this.http.get(apiUrl);
  }

  public createSignNowDocuments(apiUrl: string): Promise<any> {
    return this.http.get(apiUrl).toPromise();
  }
}
