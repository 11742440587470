// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

.red-color {
  color: red;
  font-size: 14px;
}

button.primary-btn {
  height: 50px;
}

.mobile-form {
  display: block !important;
}

.justify-content-between {
  justify-content: space-between;
  position: relative;
}

.disable-function {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  background: rgba(255, 255, 255, 0.6588235294);
}

.padding-top {
  padding-top: 24px;
}

.bold-label {
  font-weight: bold;
  white-space: normal;
  word-break: break-word;
}

.min-width-101 {
  min-width: 101px;
}

.word-break {
  word-break: break-all;
}`, "",{"version":3,"sources":["webpack://./src/app/verify-account/add-address/add-address.component.scss"],"names":[],"mappings":"AAAA;EACE;IACE,yBAAA;EACF;AACF;AAEA;EACE,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,yBAAA;EACA,uCAAA;AAAF;;AAGA;EACE,UAAA;EACA,eAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,8BAAA;EACA,kBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,UAAA;EACA,6CAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,iBAAA;EACA,mBAAA;EACA,sBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,qBAAA;AAAF","sourcesContent":["@keyframes spinner {\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.spinner:before {\n  content: '';\n  box-sizing: border-box;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 20px;\n  height: 20px;\n  margin-top: -10px;\n  margin-left: -10px;\n  border-radius: 50%;\n  border: 2px solid #ffffff;\n  border-top-color: #000000;\n  animation: spinner .8s linear infinite;\n}\n\n.red-color {\n  color: red;\n  font-size: 14px;\n}\n\nbutton.primary-btn {\n  height: 50px;\n}\n\n.mobile-form {\n  display: block !important;\n}\n\n.justify-content-between {\n  justify-content: space-between;\n  position: relative;\n}\n\n.disable-function {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  z-index: 4;\n  background: #ffffffa8;\n}\n\n.padding-top {\n  padding-top: 24px\n}\n\n.bold-label {\n  font-weight: bold;\n  white-space: normal;\n  word-break: break-word;\n}\n\n.min-width-101 {\n  min-width: 101px;\n}\n\n.word-break {\n  word-break: break-all;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
