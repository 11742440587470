import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ClipboardModule } from 'ngx-clipboard';
import { DiscountDialogComponent } from './payment-info/discount-dialog';
import { RegisterComponent } from './register.component';
import { SelectOptionComponent } from './select-option/select-option.component';
import { SelectPlanComponent } from './select-plan/select-plan.component';
import { SelectWarehouseComponent } from './select-warehouse/select-warehouse.component';
import { SelectRegionComponent } from './select-region/select-region.component';
import { SelectTermComponent } from './select-term/select-term.component';
import { SignupComponent } from './signup-page/signup-page.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { PaymentCompleteComponent } from './payment-complete/payment-complete.component';
import { SelectWarehouseByAddressComponent } from './select-warehouse-by-address/select-warehouse-by-address.component';
import { environment } from '../../environments/environment';
import { ComponentsModule } from '../components';
import { ProgressLineComponent } from './progress-line/progress-line.component';
import { DialogWithInputComponent } from '../components/dialog-with-input';

@NgModule({
  declarations: [
    DiscountDialogComponent,
    DialogWithInputComponent,
    RegisterComponent,
    SelectOptionComponent,
    SelectPlanComponent,
    SelectRegionComponent,
    SelectTermComponent,
    SelectWarehouseComponent,
    SignupComponent,
    PaymentInfoComponent,
    PaymentCompleteComponent,
    SelectWarehouseByAddressComponent,
    ProgressLineComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    CreditCardDirectivesModule,
    ComponentsModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressBarModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ReactiveFormsModule,
    RecaptchaV3Module,
    RouterModule,
    NgxSkeletonLoaderModule,
    ClipboardModule,
  ],
  exports: [
    DiscountDialogComponent,
    RegisterComponent,
    SelectOptionComponent,
    SelectPlanComponent,
    SelectRegionComponent,
    SelectTermComponent,
    SelectWarehouseComponent,
    SignupComponent,
    PaymentInfoComponent,
    PaymentCompleteComponent,
    SelectWarehouseByAddressComponent,
    ProgressLineComponent,
  ],
  providers: [DatePipe, { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey }, provideNgxMask()],
})
export class RegisterModule {}
