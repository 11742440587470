import { Injectable } from '@angular/core';
import { ExternalScripts, ScriptStore } from './script.store';

declare let document: any;

@Injectable()
export class ScriptService {
  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        id: script.id,
        loaded: false,
        src: script.src,
      };
    });
  }

  load(...scripts: ExternalScripts[]) {
    const promises: any[] = [];
    scripts.forEach(script => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      const done = () => {
        this.scripts[name].loaded = true;
        resolve({ id: this.scripts[name].id, script: name, loaded: true, status: 'Loaded' });
      };
      // resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ id: this.scripts[name].id, script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (this.scripts[name].id) {
          script.id = this.scripts[name].id;
        }
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              done();
            }
          };
        } else {
          // Others
          script.onload = () => done();
        }
        script.onerror = (error: any) => resolve({ id: this.scripts[name].id, script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}
