import { Component, ChangeDetectionStrategy, OnInit, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ApiMapping, UserDataService, NotificationService, UsgmHttp } from '../../../services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import * as SharedHelpers from '../../../utils/helpers';
import { AccountNamesModel } from '../../../models/account-names-model';
import { AccountStatus, Relation, FormType } from '@usgm/usgm-payloads-library-front';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PdfViewerDialogComponent } from '../../../components/pdf-viewer-dialog';
import * as _ from 'lodash';
import { VerifyAccountService as verifyAccountEventService } from '../../../verify-account/verify-account.service';
import { ChangePlanPopupComponent } from '../../change-plan-popup/change-plan-popup.component';
import { saveAs } from 'file-saver';
import { DialogComponent } from '../../../components/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CaptchaBaseComponent } from '../../../captcha-base-component/captcha-base-component.component';
import { ACCOUNT_NAMES_HARD_LIMIT } from '../../../utils/constants';
import { environment } from '../../../../environments/environment';
import { renderRelation } from '../../../utils/helpers';

@Component({
  selector: 'usgm-manage-account-names',
  templateUrl: './manage-account-names.component.html',
  styleUrls: ['./manage-account-names.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageAccountNamesComponent extends CaptchaBaseComponent implements OnInit, OnDestroy {
  protected readonly renderRelation = renderRelation;
  private isBusinessPlanUser = false;

  public selectedNotaryPlan: any;
  public pendingFormList = [];
  public showSingNowButton = false;
  public activeSigningLinks: any = [];
  public newNotaryRequestApplicableUser: any = [];
  public newNotaryRequestApplicableUserSignNowLinks: any = [];
  public loading = false;
  public accountNames: AccountNamesModel[];
  public settingPageLoad: boolean = Boolean(false);
  public maxNames: number;
  public selectedPlanId: number;
  public subscriptionValue = false;
  public selectedUser: AccountNamesModel;
  public downLoadingPdf = false;
  public notarization: Boolean = Boolean(false);
  public form1583List: any[] = [];
  public userAccountNames: any[] = [];
  public isFormListIsDownloadLoading = true;
  public isAddNameConditionsLoaded = true;
  public unSubscriber: Subject<any> = new Subject();
  public numExtraAccountNames = 0;
  public costOfExtraAccountNamePerMonth = 5; // USD

  @Output() closeNav = new EventEmitter();
  @Output() redirectToOnboarding = new EventEmitter<any>();
  @Output() redirectToIdRejectionScreen = new EventEmitter();
  @Output() redirectToFormRejectionScreen = new EventEmitter();
  @Output() redirectToNotaryRequestScreen = new EventEmitter();

  constructor(
    private matDialog: MatDialog,
    protected cdr: ChangeDetectorRef,
    private userDataService: UserDataService,
    private apiMapping: ApiMapping,
    public override notificationService: NotificationService,
    private verifyService: verifyAccountEventService,
    private http: UsgmHttp,
    public override dialog: MatDialog,
    public override recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
  }

  override ngOnInit() {
    this.getSubscriptions();
    this.getSettingsAccountNames();
    this.getForm1583List();
  }

  downLoadForm(token, captchaVersion, response) {
    this.http.get(this.apiMapping.downloadForms(response.form.id, token, captchaVersion), { responseType: 'blob' }).subscribe(
      result => {
        const fileName = response.form.form_url.split('/').pop().split('#')[0].split('?')[0];
        SharedHelpers.downloadFile(result, fileName);
        this.loading = false;
        this.notificationService.showWarning('File downloaded.');
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        if (error.status === 422) {
          this.verifyCaptcha(this.downLoadForm.bind(this), response);
        } else {
          this.loading = false;
          SharedHelpers.detectChanges(this.cdr);
          this.notificationService.showError('Unable to downloaded form.');
        }
      },
    );
  }

  openFormPDF(token, captchaVersion, id: number) {
    this.loading = false;
    SharedHelpers.detectChanges(this.cdr);
    this.matDialog.open(PdfViewerDialogComponent, {
      maxWidth: 'calc(100vw - 10px)',
      panelClass: 'no-padding',
      data: { url: this.apiMapping.downloadForms(id, token, captchaVersion), fileType: `pdf`, v2CaptchaCallback: () => this.verifyCaptcha(this.openFormPDF.bind(this), id) },
    });
  }

  public downloadPdf(token, captchaVersion, selectedId = null) {
    if (this.downLoadingPdf) {
      return false;
    }
    this.http.get(this.apiMapping.getDownloadForm(selectedId, token, captchaVersion), { responseType: 'blob' }).subscribe(
      result => {
        SharedHelpers.downloadFile(result, `form_1583.zip`, 'application/zip');
        this.notificationService.showWarning('File downloaded.');
        this.loading = false;
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        if (error.status === 422) {
          this.verifyCaptcha(this.downloadPdf.bind(this));
        } else {
          this.loading = false;
          SharedHelpers.detectChanges(this.cdr);
          this.notificationService.showError('Unable to download file. Please try again.');
        }
      },
    );
  }

  public getSettingsAccountNames(): void {
    this.userDataService
      .getSettingsAccountNames(this.apiMapping.getSettingsAccountNamesOnlyStatus(this.userDataService.getUserId()))
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        (apiResponse: AccountNamesModel[]) => {
          this.accountNames = apiResponse['data']['accountNames'];
          SharedHelpers.detectChanges(this.cdr);
          this.userDataService.setAccountNames(this.accountNames);
          this.calculateNumExtraAccountNames();
        },
        (error: HttpErrorResponse) => {
          this.notificationService.showError(error.message);
        },
      );
  }

  private calculateNumExtraAccountNames() {
    if (this.accountNames && this.maxNames) {
      this.numExtraAccountNames = this.accountNames.length - this.maxNames;
      SharedHelpers.detectChanges(this.cdr);
    }
  }

  public editAccountName(accountName: AccountNamesModel) {
    this.selectedUser = accountName;
    if (accountName.accountStatus === AccountStatus.NOTARIZED_DOCUMENT_REJECTED) {
      this.redirectToFormRejectionScreen.emit({ accountName, removeFromTab: [Relation.MINOR_CHILD].includes(accountName.relation.relationType as any) });
    } else if (accountName.accountStatus === AccountStatus.DOCUMENT_PRIMARY_REJECTED || accountName.accountStatus === AccountStatus.DOCUMENT_SECONDARY_REJECTED) {
      this.redirectToOnboarding.emit({ accountName, page: 'ADD_IDS' });
    } else {
      this.redirectToOnboarding.emit({ accountName, removeFromTab: [Relation.MINOR_CHILD].includes(accountName.relation.relationType as any) });
    }
  }

  public deleteAccountName(accountName: AccountNamesModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: `You are going to delete the account name and all associated resources (e.g. forms, ids).`,
      title: 'Are you sure you wish to delete this account name?',
      cancelText: 'No',
      confirmText: 'Yes',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        SharedHelpers.detectChanges(this.cdr);
        this.http
          .delete(this.apiMapping.deleteAccountNames(accountName.user.uuid))
          .pipe(takeUntil(this.unSubscriber))
          .subscribe(
            apiResponse => {
              this.getSettingsAccountNames();
              this.notificationService.showSuccess('Account name deleted successfully');
              this.loading = false;
              SharedHelpers.detectChanges(this.cdr);
            },
            error => {
              this.notificationService.showError('Unable to delete the name !!!!');
              this.loading = false;
              SharedHelpers.detectChanges(this.cdr);
            },
          );
      }
    });
  }

  public editAccountIds(accountName: AccountNamesModel) {
    this.selectedUser = accountName;
    if (accountName.accountStatus === AccountStatus.NOTARIZED_DOCUMENT_REJECTED) {
      this.redirectToFormRejectionScreen.emit({ accountName, removeFromTab: [Relation.MINOR_CHILD].includes(accountName.relation.relationType as any) });
    } else if (accountName.accountStatus === AccountStatus.DOCUMENT_PRIMARY_REJECTED || accountName.accountStatus === AccountStatus.DOCUMENT_SECONDARY_REJECTED) {
      this.redirectToOnboarding.emit({ accountName, page: 'ADD_IDS', removeFromTab: [Relation.MINOR_CHILD].includes(accountName.relation.relationType as any) });
    } else {
      this.redirectToOnboarding.emit({ accountName, page: 'ADD_IDS', removeFromTab: [Relation.MINOR_CHILD].includes(accountName.relation.relationType as any) });
    }
  }

  redirectAddName() {
    if (this.isAddNameConditionsLoaded || this.accountNames.length === 0) {
      return false;
    }
    if (this.accountNames?.length >= ACCOUNT_NAMES_HARD_LIMIT) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.closeOnNavigation = true;
      dialogConfig.data = {
        message: `Please contact support if you need to add more than ${ACCOUNT_NAMES_HARD_LIMIT} account names`,
        title: 'You have reached the global limit of account names',
        confirmText: 'Contact Support',
        showCloseIcon: true,
      };
      const dialogRefContactSupport = this.matDialog.open(DialogComponent, dialogConfig);
      dialogRefContactSupport.afterClosed().subscribe(result => {
        if (result) {
          window.open(`${environment.usglobalMailWordPress}/contact-us`, '_blank');
        }
      });
      return;
    }
    if (this.maxNames && this.accountNames && this.accountNames.length >= this.maxNames) {
      const confirmDialogConfig = new MatDialogConfig();
      confirmDialogConfig.autoFocus = true;
      confirmDialogConfig.closeOnNavigation = true;
      confirmDialogConfig.data = {
        message: 'You can ' + (this.isBusinessPlanUser ? '' : 'either upgrade your membership plan, or ') + 'add additional names for $5/month.',
        title: 'You have reached the maximum number of free account names on your membership plan',
        cancelText: this.isBusinessPlanUser ? 'Cancel' : 'Upgrade Plan',
        confirmText: 'Add Name',
        showCloseIcon: true,
      };
      const dialogRef = this.matDialog.open(DialogComponent, confirmDialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.redirectToOnboarding.emit({});
          SharedHelpers.detectChanges(this.cdr);
        } else if (result === false && !this.isBusinessPlanUser) {
          this.planDetailClicked();
        }
      });
      return;
    }
    if (this.subscriptionValue) {
      return;
    }
    this.redirectToOnboarding.emit({});
    SharedHelpers.detectChanges(this.cdr);
  }

  public closeSideNav() {
    this.closeNav.emit({});
  }

  checkIfRejected(status: string) {
    return status && status.toLowerCase().includes('rejected');
  }

  getSubscriptions() {
    this.userDataService
      .getUserSubscription()
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        response => {
          if (response && response.length) {
            const subscription = response.find(item => this.userDataService.isActiveSubscription(item));
            if (subscription) {
              this.selectedPlanId = subscription.plan_id;
              this.getSelectedPlan();
            } else {
              this.subscriptionValue = true;
              this.isAddNameConditionsLoaded = false;
              SharedHelpers.detectChanges(this.cdr);
            }
          } else {
            this.subscriptionValue = true;
            this.isAddNameConditionsLoaded = false;
            SharedHelpers.detectChanges(this.cdr);
          }
        },
        error => {
          this.isAddNameConditionsLoaded = false;
          SharedHelpers.detectChanges(this.cdr);
        },
      );
  }

  getSelectedPlan() {
    this.userDataService
      .getUserPlanById(this.selectedPlanId)
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        response => {
          this.maxNames = SharedHelpers.maxNamesForPlan(response);
          this.isBusinessPlanUser = response?.options?.is_business;
          this.calculateNumExtraAccountNames();
          this.isAddNameConditionsLoaded = false;
          SharedHelpers.detectChanges(this.cdr);
        },
        error => {
          this.isAddNameConditionsLoaded = false;
          SharedHelpers.detectChanges(this.cdr);
        },
      );
  }

  displayEditOptions(accountName) {
    if (accountName?.relation?.relationType === Relation.PRIMARY) {
      return ![AccountStatus.FORM_1583_APPROVED, AccountStatus.NOTARIZED_DOCUMENT_ADDED, AccountStatus.NOTARIZED_DOCUMENT_APPROVED, AccountStatus.APPROVED].includes(
        accountName.accountStatus,
      );
    }
    if ([Relation.MINOR_CHILD].includes(accountName?.relation?.relationType as any)) {
      return ![AccountStatus.DOCUMENT_PRIMARY_APPROVED, AccountStatus.APPROVED].includes(accountName.accountStatus);
    }
    return ![AccountStatus.FORM_1583_APPROVED, AccountStatus.NOTARIZED_DOCUMENT_ADDED, AccountStatus.NOTARIZED_DOCUMENT_APPROVED, AccountStatus.APPROVED].includes(
      accountName.accountStatus,
    );
  }

  statusRename(accountNames: AccountNamesModel[], accountName: AccountNamesModel) {
    return SharedHelpers.statusFromName(accountNames, accountName);
  }

  getForm1583List() {
    this.userDataService
      .getFormList(this.userDataService.getUserId())
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        res => {
          if (res && res.form1583) {
            this.validateForm1583(res.form1583);
          }
        },
        error => {
          this.isFormListIsDownloadLoading = false;
          SharedHelpers.detectChanges(this.cdr);
        },
      );
  }

  validateForm1583(formList: any[]) {
    if (this.userAccountNames.length > 0) {
      const formListing: any[] = _.clone(formList);
      const data: any[] = [];
      this.userAccountNames.forEach(user => {
        const formDetail = formListing.length > 0 ? formListing.find(name => Number(name.user_id) === Number(user.user.id)) : undefined;
        if (user.relation.relationType !== Relation.MINOR_CHILD) {
          if (formDetail !== undefined) {
            if (!formDetail.form1583?.form_1583_fields) {
              const formData: any = {
                additional_names_in_form: user.user.name,
              };
              formDetail.form1583.form_1583_fields = formData;
            }
            data.push(formDetail);
          } else {
            const form: any = {
              form1583: {
                form_1583_fields: {
                  additional_names_in_form: user.user.name,
                },
                form_type: 'FORM_1583',
                form_url: '-',
                id: 0,
                status: '-',
                user_id: user.user.id,
              },
              user_id: user.user.id,
            };
            data.push(form);
          }
        }
      });
      this.form1583List = data;
    } else {
      this.form1583List = formList;
    }
    this.pendingFormList = this.form1583List?.filter(i => i?.form1583?.form_type === FormType.FORM_1583) || [];
    this.processSignNow(this.pendingFormList);
    this.isFormListIsDownloadLoading = false;
    SharedHelpers.detectChanges(this.cdr);
  }

  async processSignNow(pendingFormList) {
    const signNowApplicableLinksForm = pendingFormList.filter(form => form.form1583.sign_now_document_id);
    if (signNowApplicableLinksForm.length) {
      try {
        const checkNotaryRequestForEachItem = [];
        for (const form of signNowApplicableLinksForm) {
          checkNotaryRequestForEachItem.push(this.http.http_get(this.apiMapping.getnotaryPlansByUserId(form.user_id)));
        }
        const notaryRequests = await Promise.all(checkNotaryRequestForEachItem);
        for (const [i, v] of notaryRequests.entries()) {
          signNowApplicableLinksForm[i].notaryPlan = v.planData;
        }
        this.activeSigningLinks = signNowApplicableLinksForm.filter(form => form.notaryPlan).map(form => form.user_id);
        this.newNotaryRequestApplicableUser = signNowApplicableLinksForm.filter(form => !form.notaryPlan).map(form => form.user_id);
        this.newNotaryRequestApplicableUserSignNowLinks = signNowApplicableLinksForm
          .filter(form => !form.notaryPlan)
          .map(form => ({ url_no_signup: form.form1583.sign_now_document_id }));
        if (this.activeSigningLinks.length || this.newNotaryRequestApplicableUser.length) {
          this.showSingNowButton = true;
          this.cdr.detectChanges();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  signNow() {
    this.loading = true;
    this.http.get(this.apiMapping.zohoSignSendReminder(this.userDataService.getUserId())).subscribe(
      response => {
        this.loading = false;
        this.notificationService.showSuccess('Email for form 1583 is sent.');
        this.cdr.detectChanges();
      },
      error => {
        this.loading = false;
        this.notificationService.showError('Error Occured Please try again. Please try again.');
        this.cdr.detectChanges();
      },
    );
  }

  async downloadAllForms() {
    if (this.isFormListIsDownloadLoading) {
      return false;
    }
    if (this.form1583List.length === 0) {
      this.notificationService.showWarning('Please click on Verify tab and complete verification to access this form.');
      return;
    }
    this.downloadIDsFirstCall(this.downloadFormAPICall.bind(this));
  }

  public downloadFormAPICall(token: string, captchaVersion: number) {
    if (this.loading) {
      return false;
    }
    this.loading = true;
    this.http.get(this.apiMapping.getUserFormDownload(this.userDataService.getUserId(), token, captchaVersion), { responseType: 'blob' }).subscribe(
      result => {
        this.downloadFile(result, `form_1583.zip`, 'application/zip');
        this.notificationService.showWarning('File downloaded.');
        this.loading = false;
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.loading = false;
        if (error.status === 422) {
          this.verifyCaptcha(this.downloadFormAPICall.bind(this));
        } else {
          this.notificationService.showError('Unable to download file. Please try again.');
          SharedHelpers.detectChanges(this.cdr);
        }
      },
    );
  }

  async downloadFile(blob, fileName, fileType = null) {
    const newBlob = new Blob([blob], { type: fileType ? fileType : 'application/pdf' });
    saveAs(newBlob, fileName);
  }

  public planDetailClicked() {
    const dialogRef = this.matDialog.open(ChangePlanPopupComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getSubscriptions();
    });
  }

  override ngOnDestroy() {
    if (this.userDataService.isSessionValid()) {
    }
    this.unSubscriber.next({});
    this.unSubscriber.complete();
  }
}
