import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as Services from '../../services';
import * as SharedHelpers from '../../utils/helpers';
import { AccountStatus } from '@usgm/usgm-payloads-library-front/Enums';

@Component({
  selector: 'usgm-credit-details-popup',
  templateUrl: './credit-details-popup.component.html',
  styleUrls: ['./credit-details-popup.component.scss', '../billing-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditDetailsPopupComponent implements OnInit, OnDestroy {
  public downloading = false;
  public accountStatus: AccountStatus = AccountStatus.APPROVED;

  @Input() invoice: any = {};
  @Output() closeNav = new EventEmitter();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected dialog: MatDialog,
    private http: Services.UsgmHttp,
    private notificationService: Services.NotificationService,
    private userDataService: Services.UserDataService,
    private apiMapping: Services.ApiMapping,
  ) {}

  ngOnInit() {
    this.accountStatus = this.userDataService.getAccountStatus();
  }

  downloadPdf() {
    if (this.userDataService.isMobileMode) {
      this.notificationService.showWarning('Opening pdf in new tab. Please save in desired location');
    }
    this.downloading = true;
    this.http.http_get(this.apiMapping.getCreditPdfUrl(this.invoice.id, this.invoice.reference_invoice_id)).then(
      result => {
        window.open(result.download_url, '_system');
        this.downloading = false;
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.downloading = false;
        this.notificationService.showError('Unable to download invoice. Please try again.');
      },
    );
  }

  closeSideNav() {
    this.closeNav.emit({});
  }

  public statusFormatted(statusStr) {
    return statusStr.split('_').join(' ');
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
