import { Component, ChangeDetectionStrategy, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import * as Services from '../../../services';
import { VerifyAccountService } from '../../../verify-account/verify-account.service';
import * as _ from 'lodash';
import { UsgmHttp } from '../../../services';
import { FormType } from '@usgm/usgm-payloads-library-front';
import { Relation } from '../../../models/get-notarized.model';

enum FORM_SCREENS_NAV_CONTROLS {
  VIEW_FORM = 'VIEW_FORM',
  NOTARY_OPTIONS_LISTING = 'NOTARY_OPTIONS_LISTING',
  UPLOAD_NOTARISED_FORM = 'UPLOAD_NOTARISED_FORM',
  PROCESS_COMPLETED = 'PROCESS_COMPLETED',
}

@Component({
  selector: 'usgm-settings-onboarding-container',
  templateUrl: './process-container.component.html',
  styleUrls: ['./process-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsOnBoardingProcessComponent {
  public processSteps = {
    MANAGE_ACCOUNT_NAME: 'MANAGE_ACCOUNT_NAME',
    ADD_NAME: 'ADD_NAME',
    ADD_IDS: 'ADD_IDS',
    ADD_ADDRESS: 'ADD_ADDRESS',
    REJECTED_IDS: 'REJECTED_IDS',
    REJECTED_FORM: 'REJECTED_FORM',
    VIEW_FORM: {
      weight: 1,
      status: 'VIEW_FORM',
    },
    NOTARY_OPTIONS_LISTING: {
      weight: 2,
      status: 'NOTARY_OPTIONS_LISTING',
    },
    NOTARY_OPTIONS_LISTING_MULTIPLE: {
      weight: 2,
      status: 'NOTARY_OPTIONS_LISTING_MULTIPLE',
    },
    OFFLINE: {
      weight: 3,
      status: 'OFFLINE',
    },
    NOTARYUSGM: {
      weight: 3,
      status: 'NOTARYUSGM',
    },
    NOTARYCAM: {
      weight: 3,
      status: 'NOTARYCAM',
    },
    UPLOAD_NOTARISED_FORM: {
      weight: 4,
      status: 'UPLOAD_NOTARISED_FORM',
    },
    PROCESS_COMPLETED: {
      weight: 5,
      status: 'PROCESS_COMPLETED',
    },
  };
  @Output() closeNav = new EventEmitter();
  public selectedStep = this.processSteps.MANAGE_ACCOUNT_NAME;
  public selectedUser = null;
  public newNotaryRequestApplicableUser = [];
  public selectedNotaryPlan = null;
  public headerText = '';
  public notrayPlanData: any;
  public loadingStatus = true;
  public signNowDirectProcess = false;
  public originalSteps = [
    {
      title: 'Add Names',
      key: 'ADD_NAME',
      status: 'active',
    },
    {
      title: 'Add IDs',
      key: 'ADD_IDS',
      status: 'pending',
    },
    {
      title: 'Get Notarized',
      key: 'ADD_FORM',
      status: 'pending',
    },
  ];
  public steps = _.cloneDeep(this.originalSteps);
  public formScreensNavigationControls: FORM_SCREENS_NAV_CONTROLS = FORM_SCREENS_NAV_CONTROLS.VIEW_FORM;
  public formScreensNavigationOriginal: FORM_SCREENS_NAV_CONTROLS = FORM_SCREENS_NAV_CONTROLS.VIEW_FORM;
  constructor(
    protected notificationService: Services.NotificationService,
    protected verifyAccountService: VerifyAccountService,
    protected verifyService: Services.VerifyAccountService,
    protected userDataService: Services.UserDataService,
    protected apiMapping: Services.ApiMapping,
    protected cdr: ChangeDetectorRef,
    private http: UsgmHttp,
  ) {}

  async getOnboardingStatusForSelectedUser() {
    const steps = _.cloneDeep(this.steps);
    try {
      const accountNames = await this.userDataService.getSettingsAccountNamesPromise(this.apiMapping.getSettingsAccountNamesOnlyStatus(this.userDataService.getUserId()));
      const userRelation = accountNames.data.accountNames.find(user => user.user.id === this.selectedUser);
      if (![Relation.MINOR_CHILD].includes(userRelation.relation.relationType)) {
        const companyAddressResponse = await this.userDataService.getCompanyAddress(this.selectedUser);
        const companyAddress = companyAddressResponse['response'];

        const documents = await this.verifyService.getNamePromise(this.apiMapping.getIDsDocumentsOfUser(this.userDataService.getUserId(), null, null, true));
        const userDocuments = documents.documents.find(document => document.user_id === this.selectedUser)?.documents || [];

        const formList = await this.userDataService.getFormListPromise(this.userDataService.getUserId());
        const userForm = formList.form1583.find(form => form.user_id === this.selectedUser);

        if ([Relation.COMPANY, Relation.TRUST].includes(userRelation.relation.relationType)) {
          steps.splice(1, 0, {
            title: 'Add Address',
            key: 'ADD_ADDRESS',
            status: companyAddress ? 'done' : 'pending',
          });
        }

        const companyAddressPending = !companyAddress;
        const userDocumentPending = !(userDocuments.length && userDocuments.length === 2);
        const formPending = !userForm;

        const findAddressIndex = steps.findIndex(i => i.key === 'ADD_ADDRESS');
        if (findAddressIndex >= 0) {
          steps[findAddressIndex].status = companyAddressPending ? 'pending' : 'done';
        }

        const findIDsIndex = steps.findIndex(i => i.key === 'ADD_IDS');
        if (findIDsIndex >= 0) {
          steps[findIDsIndex].status = userDocumentPending ? 'pending' : 'done';
        }

        const findFormIndex = steps.findIndex(i => i.key === 'ADD_FORM');
        if (findFormIndex >= 0) {
          steps[findFormIndex].status = formPending ? 'pending' : 'done';
        }

        if (userForm) {
          if (userForm.form1583.form_type === FormType.FORM_1583) {
            const userNotaryPlan = await this.http.http_get(this.apiMapping.getnotaryPlansByUserId(this.selectedUser));
            if (!_.isEmpty(userNotaryPlan.planData)) {
              this.formScreensNavigationControls = this.formScreensNavigationOriginal = FORM_SCREENS_NAV_CONTROLS.UPLOAD_NOTARISED_FORM;
            }
          } else {
            this.formScreensNavigationControls = this.formScreensNavigationOriginal = FORM_SCREENS_NAV_CONTROLS.PROCESS_COMPLETED;
          }
        } else {
          this.formScreensNavigationControls = FORM_SCREENS_NAV_CONTROLS.VIEW_FORM;
        }

        const activeStepKey =
          this.selectedStep === this.processSteps.ADD_IDS
            ? 'ADD_IDS'
            : this.selectedStep === this.processSteps.ADD_ADDRESS
              ? 'ADD_ADDRESS'
              : this.selectedStep === this.processSteps.ADD_NAME
                ? 'ADD_NAME'
                : 'ADD_FORM';

        const activeStepIndex = steps.findIndex(i => i.key === activeStepKey);

        steps[activeStepIndex].status = 'active';
      } else {
        this.steps.splice(2, 1);
        const documents = await this.verifyService.getNamePromise(this.apiMapping.getIDsDocumentsOfUser(this.userDataService.getUserId(), null, null, true));
        const userDocuments = documents.documents.find(document => document.user_id === this.selectedUser)?.documents || [];
        if (userDocuments.length) {
          if (this.selectedStep === this.processSteps.ADD_IDS) {
            steps[0].status = 'done';
            steps[1].status = 'active';
          } else {
            steps[0].status = 'active';
            steps[1].status = 'done';
          }
        } else {
          if (this.selectedStep === this.processSteps.ADD_IDS) {
            steps[0].status = 'done';
            steps[1].status = 'active';
          } else {
            steps[0].status = 'active';
            steps[1].status = 'pending';
          }
        }
      }
      this.steps = steps;
      this.loadingStatus = true;
      this.cdr.detectChanges();
    } catch (error) {
      console.log(error);
    }
  }

  closeSettingOptionDrawer() {
    this.closeNav.emit({});
  }

  renderOnboarding() {
    return ![this.processSteps.MANAGE_ACCOUNT_NAME, this.processSteps.REJECTED_FORM, this.processSteps.REJECTED_IDS].includes(this.selectedStep);
  }

  renderRejectionResolveScreen() {
    return [this.processSteps.REJECTED_FORM].includes(this.selectedStep);
  }

  redirectToOnboarding(data) {
    if (data.removeFromTab) {
      const steps = _.cloneDeep(this.steps);
      const index = steps.findIndex(i => i.key === 'ADD_FORM');
      if (index > 0) {
        steps.splice(index, 1);
        this.steps = steps;
        this.cdr.detectChanges();
      }
    }
    if (!_.isEmpty(data?.accountName || {})) {
      this.selectedUser = data.accountName.user.uuid;
      this.getOnboardingStatusForSelectedUser();
      this.headerText = 'Edit Account Name';
    } else {
      this.headerText = 'Add Account Name';
      this.loadingStatus = true;
    }
    this.selectedStep = data?.page || this.processSteps.ADD_NAME;
    this.cdr.detectChanges();
  }

  goToNextPage(data) {
    if (data.removeFromTab) {
      const steps = _.cloneDeep(this.steps);
      const index = steps.findIndex(i => i.key === 'ADD_FORM');
      if (index > 0) {
        steps.splice(index, 1);
        this.steps = steps;
        this.cdr.detectChanges();
      }
    }
    if (data.addAddressTab && !this.steps.find(i => i.key === 'ADD_ADDRESS')) {
      const steps = _.cloneDeep(this.steps);
      steps.splice(1, 0, {
        title: 'Add Address',
        key: 'ADD_ADDRESS',
        status: 'pending',
      });
      this.steps = steps;
      this.cdr.detectChanges();
    }
    if (data.addFormTab) {
      const steps = _.cloneDeep(this.steps);
      if (!this.steps.find(i => i.key === 'ADD_FORM')) {
        steps.push(this.originalSteps.find(i => i.key === 'ADD_FORM'));
      }
      this.steps = steps;
      this.cdr.detectChanges();
    }
    if (data.selectedNotaryPlan) {
      this.selectedNotaryPlan = data.selectedNotaryPlan;
      this.notrayPlanData = data.data;
    }
    if (data.userId) {
      this.selectedUser = data.userId;
    }
    if (data.page === this.processSteps.MANAGE_ACCOUNT_NAME) {
      this.goBackToMain();
    } else if (data.page === this.processSteps.ADD_ADDRESS) {
      const steps = _.cloneDeep(this.steps);
      const addNameIndex = steps.findIndex(i => i.key === 'ADD_NAME');
      const addAddressIndex = steps.findIndex(i => i.key === 'ADD_ADDRESS');
      steps[addNameIndex].status = 'done';
      steps[addAddressIndex].status = 'active';
      this.steps = steps;
      this.selectedStep = data.page;
    } else if (data.page === this.processSteps.ADD_IDS) {
      const steps = _.cloneDeep(this.steps);
      const addNameIndex = data.comingFrom === 'ADD_ADDRESS' ? steps.findIndex(i => i.key === 'ADD_ADDRESS') : steps.findIndex(i => i.key === 'ADD_NAME');
      const addIDsIndex = steps.findIndex(i => i.key === 'ADD_IDS');
      steps[addNameIndex].status = 'done';
      steps[addIDsIndex].status = 'active';
      this.steps = steps;
      this.selectedStep = data.page;
    } else if (
      [
        this.processSteps.VIEW_FORM.status,
        this.processSteps.NOTARY_OPTIONS_LISTING.status,
        this.processSteps.UPLOAD_NOTARISED_FORM.status,
        this.processSteps.PROCESS_COMPLETED.status,
        this.processSteps.OFFLINE.status,
        this.processSteps.NOTARYCAM.status,
        this.processSteps.NOTARYUSGM.status,
      ].includes(data.page)
    ) {
      if (!this.steps.find(i => i.key === 'ADD_FORM')) {
        this.goBackToMain();
        return;
      }
      const steps = _.cloneDeep(this.steps);
      const addIDsIndex = steps.findIndex(i => i.key === 'ADD_IDS');
      const addFormIndex = steps.findIndex(i => i.key === 'ADD_FORM');
      steps[addIDsIndex].status = 'done';
      steps[addFormIndex].status = 'active';
      this.steps = steps;
      this.selectedStep = this.processSteps[this.formScreensNavigationControls].weight < this.processSteps[data.page].weight ? data.page : this.formScreensNavigationControls;
    } else {
      this.selectedStep = data.page;
    }
    this.cdr.detectChanges();
  }

  goBackToMain() {
    this.selectedStep = this.processSteps.MANAGE_ACCOUNT_NAME;
    this.selectedUser = null;
    this.headerText = null;
    this.loadingStatus = false;
    this.newNotaryRequestApplicableUser = [];
    this.formScreensNavigationControls = this.formScreensNavigationOriginal = FORM_SCREENS_NAV_CONTROLS.VIEW_FORM;
    this.steps = this.originalSteps;
    this.cdr.detectChanges();
  }

  forScreenBackNavigation(data) {
    this.selectedStep = data.page;
    this.formScreensNavigationControls = data.page;
    if (data.page === this.processSteps.NOTARY_OPTIONS_LISTING) {
      this.cdr.detectChanges();
    }
  }
  goBackToIdsScreen() {
    this.selectedStep = this.processSteps.ADD_IDS;
    const steps = _.cloneDeep(this.steps);
    const addIDsIndex = steps.findIndex(i => i.key === 'ADD_IDS');
    const addFormIndex = steps.findIndex(i => i.key === 'ADD_FORM');
    steps[addIDsIndex].status = 'active';
    steps[addFormIndex].status = this.formScreensNavigationOriginal === FORM_SCREENS_NAV_CONTROLS.PROCESS_COMPLETED ? 'done' : 'pending';
    this.steps = steps;
    this.cdr.detectChanges();
  }

  redirectToNotaryRequestScreen(data) {
    this.signNowDirectProcess = true;
    this.selectedStep = data.page;
    this.headerText = 'Notarized the form 1583';
    this.newNotaryRequestApplicableUser = data.newNotaryRequestApplicableUser;
    this.cdr.detectChanges();
  }

  redirectToIdRejectionScreen() {
    this.selectedStep = this.processSteps.REJECTED_IDS;
    this.cdr.detectChanges();
  }

  redirectToFormRejectionScreen(data) {
    if (!_.isEmpty(data?.accountName || {})) {
      this.selectedUser = data.accountName.user.uuid;
    }
    this.selectedStep = this.processSteps.REJECTED_FORM;
    this.headerText = 'RESOLVE FORM 1583 REJECTION';
    this.cdr.detectChanges();
  }
}
