import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TabBarComponent } from './tab-bar.component';

@NgModule({
  declarations: [TabBarComponent],
  imports: [BrowserAnimationsModule, CommonModule, IonicModule, RouterModule],
  exports: [TabBarComponent],
})
export class TabBarModule {}
