import { Observable } from 'rxjs';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MailFolderService } from '../../../services';

@Component({
  selector: 'usgm-edit-label-dialog',
  templateUrl: './edit-label-dialog.component.html',
  styleUrls: ['./edit-label-dialog.component.scss'],
})
export class EditLabelDialogComponent implements OnInit {
  @Input()
  public isNew = true;

  public name = '';
  public errorMessage = '';
  public isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<EditLabelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public service: MailFolderService,
  ) {
    this.name = '';
    if (data.data && data.data.id) {
      this.name = data.data.name;
      this.isNew = false;
    }
  }

  ngOnInit() {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onSave(): void {
    if (!this.name) {
      this.errorMessage = 'Name is required';
      return;
    }
    this.errorMessage = '';
    let request: Observable<any>;
    if (this.isNew) {
      request = this.service.create({ name: this.name, color: '' });
    } else {
      request = this.service.update(this.data.data.id, { name: this.name });
    }
    this.isLoading = true;
    request.subscribe(
      result => {
        this.isLoading = false;
        this.dialogRef.close(result);
      },
      error => {
        this.errorMessage = error.message;
        this.isLoading = false;
      },
    );
  }

  public onKeyDown(event) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault();
      this.onSave();
    }
  }
}
