import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Services from '../../services';
import { AppRoutes } from '../../models/constants/app-routes.constant';
import { UserDataService } from '../../services';

const HOUSTON_PLANS = ['BASIC', 'PLUS', 'BUSINESS'];

@Component({
  selector: 'usgm-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPlanComponent implements OnInit, OnDestroy {
  public planData: any = [];
  public loading = false;
  public warehouseText = '';
  public currentPlanId;
  placeHolderPlans = [1, 2, 3];
  public isChannelPartner = false;
  public showTable = false;
  private warehouseCities = ['concord', 'washington', 'eagle', 'portland', 'miami', 'middletown', 'seattle'];
  public physicalMailStorageFee = '$0.03';
  faqArray = [
    {
      question: 'What do you mean by unlimited send/receive',
      answer:
        'There are no caps to the amount of mail you can receive in your account, or that you can have shipped to you. You can receive an unlimited amount of mail in your' +
        'account & you can complete an unlimited amount of shipments.',
    },
    {
      question: 'Are exterior mail scans included in the service',
      answer: 'Yes. When you sign up for any of our plans, you can receive external mail scans for an unlimited number of mail & packages.',
    },
    {
      question: 'What is your 30 day money back guarantee',
      answer: 'Don’t like the service for any reason whatsoever? Cancel & let us know within 30 days and we’ll refund you fully.',
    },
    {
      question: 'Can I cancel or switch plans at any time',
      answer: 'Yep; we are very flexible. Cancel, upgrade, downgrade at any time!',
    },
  ];
  warehouseId: any;
  userEmail: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private http: Services.UsgmHttp,
    private apiMapping: Services.ApiMapping,
    private userDataService: UserDataService,
    private notificationService: Services.NotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  public ngOnInit() {
    if (document.getElementById('launcher')) {
      document.getElementById('launcher').style.display = 'block';
    }
    this.activatedRoute.queryParams.subscribe(params => {
      setTimeout(() => {
        if (params['warehouse_id']) {
          this.warehouseId = params['warehouse_id'];
          this.isChannelPartner = !['1', '2'].includes(params['warehouse_id']);
        } else {
          this.router.navigate([AppRoutes.selectWarehouseByAddress]);
        }
        if (params['email']) {
          this.userEmail = params['email'];
        }
        this.loadPlans();
        this.loadWarehouse();
      }, 100);
    });
  }

  public async loadWarehouse() {
    this.warehouseText = '';
    if (this.warehouseId) {
      this.warehouseText = 'Loading...';
      const warehouses = (await this.userDataService.getWarehousesByState('')).results || [];
      const warehouse = warehouses.find(w => w.id === parseInt(this.warehouseId, 10));
      if (warehouse) {
        this.physicalMailStorageFee = this.warehouseCities.includes(warehouse.Address.city.toLowerCase()) ? '$0.05' : '$0.03';
        this.warehouseText = `Your address will be:\n${warehouse?.Address?.address_line}\n ${warehouse?.Address?.city}, ${warehouse?.Address?.state}, ${warehouse?.Address?.postal_code}`;
      }
    } else {
      this.warehouseText = `Choose the plan that's right for you.`;
    }
    this.cdr.detectChanges();
  }

  public loadPlans() {
    this.loading = true;
    this.http
      .http_get(this.apiMapping.getPlansByWarehouse(this.warehouseId))
      .then(
        (data: any) => {
          this.planData = this.warehouseId === '1' ? data.filter(plan => HOUSTON_PLANS.includes(plan?.name.toUpperCase())) : data.slice(0, 3);
          this.cdr.detectChanges();
          this.loading = false;
        },
        (error: any) => {
          this.loading = false;
          this.notificationService.showError('Unable to fetch plans. Please try again.');
        },
      )
      .catch((exception: any) => {
        this.loading = false;
        this.notificationService.showError('Unable to fetch plans. Please try again.');
        console.log('error fetching data', exception);
      });
  }

  public planButtonClicked(plan: any) {
    localStorage.setItem('selectedPlan', plan.uuid);
    this.router.navigate([AppRoutes.signup], { queryParams: { warehouse_id: this.warehouseId, email: this.userEmail } });
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    this.cdr.detach();
    if (document.getElementById('launcher')) {
      document.getElementById('launcher').style.display = 'none';
    }
  }
}
