import { Component, OnInit, Input, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';
import * as Constants from '../../utils/constants';
import { UserDataService } from '../../services';

@Component({
  selector: 'usgm-register-header',
  templateUrl: './header.component.html',
  styleUrls: ['header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterHeaderComponent implements OnInit {
  public isVisibleStepsHeader: boolean;
  public imgSrc = Constants.USGM_LOGO;
  public isMobileView = false;
  public showBackButton = true;

  @Input() currentStep = 0;

  constructor(
    private ref: ElementRef,
    private location: Location,
    private userDataService: UserDataService,
  ) {}

  public ngOnInit() {
    this.showBackButton = this.userDataService.userSubscribed();
    if (!this.ref.nativeElement.offsetWidth || this.ref.nativeElement.offsetWidth < 900) {
      this.isMobileView = true;
    }
    this.isVisibleStepsHeader = !(this.currentStep === 4);
  }

  public backBtnClicked() {
    this.location.back();
  }

  public logout() {
    this.userDataService.logoutUser();
  }
}
