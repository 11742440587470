import { Component, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import * as Services from '../../../services';
import { VerifyAccountService } from '../../../verify-account/verify-account.service';

@Component({
  selector: 'usgm-notarize-with-completed',
  templateUrl: './notarization-completed.component.html',
  styleUrls: ['./notarization-completed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotarizeCompletedMobileComponent {
  @Output() goToNextPage = new EventEmitter();
  constructor(
    protected notificationService: Services.NotificationService,
    protected verifyAccountService: VerifyAccountService,
    protected verifyService: Services.VerifyAccountService,
    protected userDataService: Services.UserDataService,
    protected apiMapping: Services.ApiMapping,
  ) {}

  goBackToManageNamesScreen() {
    this.goToNextPage.emit({ page: 'MANAGE_ACCOUNT_NAME' });
  }
}
