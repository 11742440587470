export class BaseComponent {
  public loading = false;
  public activeApiCalls = 0;

  startedApiCall() {
    this.loading = true;
    this.activeApiCalls++;
  }

  completedApiCall() {
    this.activeApiCalls--;
    this.activeApiCalls = Math.max(0, this.activeApiCalls);
    if (this.activeApiCalls === 0) {
      this.loading = false;
    }
  }
}
