// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  min-height: 100vh;
  font-weight: 400;
  background: white;
}
:host .warehouse-container {
  max-width: 450px;
}
:host .dropdown-toggle {
  padding: 10px 10px 10px 40px;
  height: 60px;
  border: 2px solid #e8f0f2;
  border-radius: 5px;
  background: #f5f9fa;
  color: black;
}
:host .fa-caret-up,
:host .fa-caret-down {
  top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/register/select-warehouse/select-warehouse.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,4BAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;AAAJ;AAGE;;EAEE,QAAA;AADJ","sourcesContent":[":host {\n  display: block;\n  min-height: 100vh;\n  font-weight: 400;\n  background: white;\n\n  .warehouse-container {\n    max-width: 450px;\n  }\n\n  .dropdown-toggle {\n    padding: 10px 10px 10px 40px;\n    height: 60px;\n    border: 2px solid #e8f0f2;\n    border-radius: 5px;\n    background: #f5f9fa;\n    color: black;\n  }\n\n  .fa-caret-up,\n  .fa-caret-down {\n    top: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
