import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, retry } from 'rxjs/operators';
import * as Services from '../../../services';
import { HttpErrorResponse } from '@angular/common/http';
import * as SharedHelpers from '../../../utils/helpers';
import { IAddressAttributes } from '@usgm/usgm-payloads-library-front';
import { VerifyAccountService } from '../../../verify-account/verify-account.service';
import { Router } from '@angular/router';
import { OnboardFormResponse } from '../../../models/get-notarized.model';
import { UsgmHttp } from '../../../services';
import { CaptchaBaseComponent } from '../../../captcha-base-component/captcha-base-component.component';
import { MatDialog } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'usgm-notarize-offline-mobile',
  templateUrl: './notarize-offline-mobile.component.html',
  styleUrls: ['./notarize-offline-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotarizeOfflineMobileComponent extends CaptchaBaseComponent implements OnInit {
  public notaryAddress: IAddressAttributes;
  public notaryAddressLoading = true;
  public loading;
  private unSubscriber: Subject<any> = new Subject();
  @Input() data;
  @Input() selectedUser;
  @Input() newNotaryRequestApplicableUser = [];
  @Output() goToNextPage = new EventEmitter<any>();
  @Output() forScreenBackNavigation = new EventEmitter<any>();

  constructor(
    private cdRef: ChangeDetectorRef,
    protected verifyService: Services.VerifyAccountService,
    protected apiMapping: Services.ApiMapping,
    public override notificationService: Services.NotificationService,
    protected userDataService: Services.UserDataService,
    protected verifyAccountService: VerifyAccountService,
    private router: Router,
    private http: UsgmHttp,
    public override dialog: MatDialog,
    public override recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
  }

  public override ngOnInit() {
    this.getNotaryPhysicalNotary();
  }

  public goBackToNotarizedOptionsListing() {
    if (this.newNotaryRequestApplicableUser.length) {
      this.forScreenBackNavigation.emit({ page: 'NOTARY_OPTIONS_LISTING_MULTIPLE' });
    } else {
      this.forScreenBackNavigation.emit({ page: 'NOTARY_OPTIONS_LISTING' });
    }
  }

  public sendNotaryRequest(): void {
    this.loading = true;
    this.verifyService
      // tslint:disable-next-line: max-line-length
      .saveNotaryRequest(this.apiMapping.saveNotaryRequest(this.data.uuid), {
        notaryRequestForUsers: this.newNotaryRequestApplicableUser.length ? this.newNotaryRequestApplicableUser : [this.selectedUser],
      })
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        apiResponse => {
          this.loading = false;
          this.cdRef.detectChanges();
          if (this.newNotaryRequestApplicableUser.length) {
            this.goToNextPage.emit({ page: 'MANAGE_ACCOUNT_NAME' });
          } else {
            this.goToNextPage.emit({ page: 'UPLOAD_NOTARISED_FORM' });
          }
        },
        error => {
          this.loading = false;
          this.cdRef.detectChanges();
          this.notificationService.showError(error.message);
        },
      );
  }

  async downloadForm1583FromServer() {
    if (this.newNotaryRequestApplicableUser.length) {
      for (const user of this.newNotaryRequestApplicableUser) {
        this.downloadForm1583(user);
      }
    } else {
      this.downloadForm1583();
    }
  }

  downloadForm1583(userId?: string) {
    this.loading = true;
    this.verifyService
      .getUserFormData(this.apiMapping.getUserOnboardForm(userId || this.selectedUser))
      .pipe(takeUntil(this.unSubscriber), retry(2))
      .subscribe(
        (response: OnboardFormResponse) => {
          this.loading = false;
          if (response?.form?.form_url) {
            this.downloadFormsFirstCall(this.downLoadForm.bind(this), response);
          }
          this.cdRef.detectChanges();
        },
        _error => {
          this.loading = false;
          this.cdRef.detectChanges();
          this.notificationService.showError('Unable to view file.');
        },
      );
  }

  downLoadForm(token, captchaVersion, response) {
    this.http.get(this.apiMapping.downloadForms(response.form.id, token, captchaVersion), { responseType: 'blob' }).subscribe(
      result => {
        const fileName = response.form.form_url.split('/').pop().split('#')[0].split('?')[0];
        SharedHelpers.downloadFile(result, fileName);
        this.notificationService.showWarning('File downloaded.');
        SharedHelpers.detectChanges(this.cdRef);
      },
      error => {
        if (error.status === 422) {
          this.verifyCaptcha(this.downLoadForm.bind(this), response);
        } else {
          this.notificationService.showError('Unable to downloaded form.');
        }
      },
    );
  }

  public getNotaryPhysicalNotary(): void {
    this.verifyService
      .getNotaryPhysicalAddress(this.apiMapping.getNotaryPhysicalAddress())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(
        apiResponse => {
          this.notaryAddress = apiResponse.address;
          this.notaryAddressLoading = false;
          SharedHelpers.detectChanges(this.cdRef);
        },
        (error: HttpErrorResponse) => {
          this.notaryAddressLoading = false;
          SharedHelpers.detectChanges(this.cdRef);
        },
      );
  }
}
