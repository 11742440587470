import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'localDate',
})
export class LocalDatePipe implements PipeTransform {
  defaultValue = '';
  defaultFormat = 'M/D/YYYY hh:mm a';
  timezone = 'America/Chicago';

  constructor() {
    // Get client's timezone
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || this.timezone;
  }

  transform(value: string, format?: any): any {
    if (value) {
      const date = moment(value).tz(this.timezone);
      return date.format(format || this.defaultFormat);
    }
    return this.defaultValue;
  }
}
