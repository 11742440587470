// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host .modal-title {
  font-size: 171%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal/modal.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,eAAA;AACJ","sourcesContent":[":host {\n  display: block;\n\n  .modal-title {\n    font-size: 171%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
