// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 600px;
  width: 100%;
  height: 100%;
  padding: 5px;
}
@media (max-width: 767px) {
  :host .get-help-container {
    min-width: 100vw;
    padding-left: 5px;
  }
}
:host .copy-btn {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}
@media (min-width: 768px) {
  :host .help-link {
    min-width: 380px;
  }
}
@media (max-width: 767px) {
  :host :host {
    min-width: 100%;
  }
}
:host .help-link {
  flex: 1;
  text-transform: none;
  cursor: pointer;
  text-decoration: none !important;
}
:host .help-link input {
  cursor: pointer !important;
}
:host .operation-hours-container table {
  border-collapse: separate;
  border-spacing: 20px;
}
:host .operation-hours-container table .weekday-cell {
  vertical-align: top;
  font-weight: bold;
}
:host .operation-hours-container table .hours-cell p {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/get-help/get-help.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AACF;AACE;EACE;IACE,gBAAA;IACA,iBAAA;EACJ;AACF;AAEE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAGE;EACE;IACE,gBAAA;EADJ;AACF;AAIE;EACE;IACE,eAAA;EAFJ;AACF;AAKE;EACE,OAAA;EACA,oBAAA;EACA,eAAA;EACA,gCAAA;AAHJ;AAKI;EACE,0BAAA;AAHN;AAQI;EACE,yBAAA;EACA,oBAAA;AANN;AAQM;EACE,mBAAA;EACA,iBAAA;AANR;AAUQ;EACE,gBAAA;AARV","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 600px;\n  width: 100%;\n  height: 100%;\n  padding: 5px;\n\n  @media (max-width: 767px) {\n    .get-help-container {\n      min-width: 100vw;\n      padding-left: 5px;\n    }\n  }\n\n  .copy-btn {\n    width: 44px;\n    height: 44px;\n    display: flex;\n    align-items: center;\n    border-radius: 4px;\n  }\n\n  @media (min-width: 768px) {\n    .help-link {\n      min-width: 380px;\n    }\n  }\n\n  @media (max-width: 767px) {\n    :host {\n      min-width: 100%\n    }\n  }\n\n  .help-link {\n    flex: 1;\n    text-transform: none;\n    cursor: pointer;\n    text-decoration: none !important;\n\n    input {\n      cursor: pointer !important;\n    }\n  }\n\n  .operation-hours-container {\n    table {\n      border-collapse: separate;\n      border-spacing: 20px;\n\n      .weekday-cell {\n        vertical-align: top;\n        font-weight: bold;\n      }\n\n      .hours-cell {\n        p {\n          margin-bottom: 0;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
