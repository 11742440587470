import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Platform } from '@ionic/angular';
import * as Constants from '../utils/constants';
import * as Helpers from '../utils/helpers';
import * as Services from '../services';
import { UserDataService } from '../services';
import { emailMatchValidator } from './emailmatch.validation';

@Component({
  selector: 'usgm-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  private legacyEmail;
  public loading = false;
  public imgSrc = Constants.USGM_LOGO;
  public resetPasswordForm: FormGroup;
  public isLegacyUser = false;
  public legacyMaskedEmail;

  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private location: Location,
    private plt: Platform,
    private http: Services.UsgmHttp,
    private notificationService: Services.NotificationService,
    private apiMapping: Services.ApiMapping,
    private userDataService: UserDataService,
  ) {}

  public ngOnInit() {
    // if (this.plt.platforms().indexOf('cordova') >= 0) {
    //   this.imgSrc = Constants.USGM_MINI_LOGO;
    // }
    let legacyRedirectResponse: any = this.userDataService.getLegacyRedirectResponse();
    if (legacyRedirectResponse) {
      legacyRedirectResponse = JSON.parse(legacyRedirectResponse);
      this.legacyEmail = legacyRedirectResponse.email;
      this.legacyMaskedEmail = this.generateMaskedLegacyEmail(legacyRedirectResponse.email);
    }
    this.isLegacyUser = this.userDataService.getLegacyRedirectResponse() !== null;
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(Helpers.emailPattern), emailMatchValidator(this.legacyEmail)]],
      username: [{ value: legacyRedirectResponse?.username, disabled: true }, []],
    });
  }

  public backBtnClicked() {
    this.location.back();
  }

  private generateMaskedLegacyEmail(legacyEmail: string) {
    if (legacyEmail) {
      const atIndex = legacyEmail.indexOf('@');
      const dotIndex = legacyEmail.lastIndexOf('.');
      const maskedAfterFirstTwoChars = legacyEmail.substring(2, atIndex).replace(/./g, '*');
      const maskedCharsAfterAtChars = legacyEmail.substring(atIndex + 3, dotIndex).replace(/./g, '*');
      return legacyEmail.substring(0, 2) + maskedAfterFirstTwoChars + legacyEmail.substring(atIndex, atIndex + 3) + maskedCharsAfterAtChars + legacyEmail.substring(dotIndex);
    }
  }

  onHiddenFocus(event, fieldName) {
    if (event.target.value) {
      this.resetPasswordForm.controls[fieldName].setValue(event.target.value);
    }
  }

  public resetPassword() {
    this.loading = true;
    this.http
      .http_post(this.apiMapping.triggerResetPassword(), {
        email: this.resetPasswordForm.get('email').value,
      })
      .then(
        () => {
          this.loading = false;
          sessionStorage.removeItem('legacy_redirect_response');
          this.router.navigate(['/reset-password-confirmation']);
        },
        (error: any) => {
          this.loading = false;
          this.notificationService.showError('Unable to reset password. Please try again.');
        },
      );
  }

  // convenience functions for easy access to form fields
  public hasErrors(fieldName: any): boolean {
    return !!this.resetPasswordForm.controls[fieldName].errors;
  }

  public hasError(fieldName: any, errorName: any): boolean {
    return this.resetPasswordForm.controls[fieldName].hasError(errorName);
  }

  public fieldEdited(fieldName: any): boolean {
    return this.resetPasswordForm.controls[fieldName].dirty && this.resetPasswordForm.controls[fieldName].touched;
  }
}
