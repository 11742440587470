import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ResolveFormErrorComponent } from './resolve-form-error.component';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '../components';
import { DirectivesModule } from '../directives';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '../pipes';
import { MatExpansionModule } from '@angular/material/expansion';
import { DiscardModule } from '../discard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  imports: [
    CommonModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ComponentsModule,
    DirectivesModule,
    MatButtonModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatIconModule,
    PipesModule,
    MatExpansionModule,
    DiscardModule,
    MatTooltipModule,
    MatCardModule,
  ],
  providers: [],
  declarations: [ResolveFormErrorComponent],
})
export class ResolveFormErrorModule {}
