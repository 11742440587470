import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsgmHttp, ApiMapping, NotificationService } from '../../../services';
import * as SharedHelpers from '../../../utils/helpers';

@Component({
  selector: 'usgm-dialog',
  templateUrl: './discount-dialog.component.html',
  styleUrls: ['./discount-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountDialogComponent {
  public code: string;
  public loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DiscountDialogComponent>,
    public http: UsgmHttp,
    public cdr: ChangeDetectorRef,
    public apiMapping: ApiMapping,
    public notificationService: NotificationService,
  ) {
    this.code = (this.data.value || {})[this.data.type === 'coupon' ? 'coupon_code' : 'referral_code'];
  }

  onCloseClick(): void {
    this.dialogRef.close({});
  }

  verifyCode() {
    this.loading = true;
    let apiUrl = this.apiMapping.getCouponByCode(this.code);
    switch (this.data.type) {
      case 'referral':
        apiUrl = this.apiMapping.getReferrerByCode(this.code);
        break;
    }
    this.http.http_get(apiUrl).then(
      (response: any) => {
        this.loading = false;
        SharedHelpers.detectChanges(this.cdr);
        if ((response || {}).id) {
          this.data.value = response;
          this.dialogRef.close(response);
          this.notificationService.showSuccess(`Successfully applied ${this.data.type}!`);
        } else {
          this.notificationService.showError(`Unable to apply ${this.data.type}. Please try again.`);
        }
      },
      (errorJson: any) => {
        this.loading = false;
        SharedHelpers.detectChanges(this.cdr);
        this.notificationService.showError(`Unable to apply ${this.data.type}. ${errorJson.error.message}.`);
      },
    );
  }
}
