import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OnlineNotarizationPopupComponent } from '../../../verify-account/online-notarization-popup/online-notarization-popup.component';
import * as Services from '../../../services';
import { VerifyAccountService } from '../../../verify-account/verify-account.service';
import { retry, takeUntil } from 'rxjs/operators';
import * as SharedHelpers from '../../../utils/helpers';
import { Subject } from 'rxjs';
import { OnboardFormResponse } from '../../../models/get-notarized.model';
import { UsgmHttp } from '../../../services';
import { CaptchaBaseComponent } from '../../../captcha-base-component/captcha-base-component.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'usgm-notarize-with-notarycam-mobile',
  templateUrl: './notarize-with-notarycam.component.html',
  styleUrls: ['./notarize-with-notarycam.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotarizeWithNotarycamMobileComponent extends CaptchaBaseComponent {
  public dialogeRef: any;
  public uploadForm = true;
  public userFile = null;
  public userNames = [];
  public forms = [];
  public fileSize = 10 * 1024 * 1024;
  public allowedTypes = ['application/pdf'];
  public uploadFormNotarizedComplete = false;
  public selectedId: number;
  public settingPageLoad: boolean;
  private unSubscriber: Subject<any> = new Subject();
  @Input() data;
  @Input() selectedUser;
  @Input() newNotaryRequestApplicableUser = [];
  @Input() loading;
  @Output() goToNextPage = new EventEmitter<any>();
  @Output() forScreenBackNavigation = new EventEmitter<any>();

  constructor(
    private matDialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    public override notificationService: Services.NotificationService,
    protected verifyAccountService: VerifyAccountService,
    protected verifyService: Services.VerifyAccountService,
    protected userDataService: Services.UserDataService,
    protected apiMapping: Services.ApiMapping,
    private http: UsgmHttp,
    public override dialog: MatDialog,
    public override recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
  }

  onlineNotarizationPopup(url: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      url,
    };
    this.dialogeRef = this.matDialog.open(OnlineNotarizationPopupComponent, dialogConfig);
    this.dialogeRef.afterClosed().subscribe((result: string) => {
      if (result === `success`) {
        this.sendNotaryRequest();
        this.cdRef.detectChanges();
      }
    });
  }

  downloadForm1583FromServer() {
    if (this.newNotaryRequestApplicableUser.length) {
      for (const user of this.newNotaryRequestApplicableUser) {
        this.downloadForm1583(user);
      }
    } else {
      this.downloadForm1583();
    }
  }

  public sendNotaryRequest(): void {
    this.loading = true;
    this.verifyService
      // tslint:disable-next-line: max-line-length
      .saveNotaryRequest(this.apiMapping.saveNotaryRequest(this.data.uuid), {
        notaryRequestForUsers: this.newNotaryRequestApplicableUser.length ? this.newNotaryRequestApplicableUser : [this.selectedUser],
      })
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        apiResponse => {
          this.loading = false;
          this.cdRef.detectChanges();
          if (this.newNotaryRequestApplicableUser.length) {
            this.goToNextPage.emit({ page: 'MANAGE_ACCOUNT_NAME' });
          } else {
            this.goToNextPage.emit({ page: 'UPLOAD_NOTARISED_FORM' });
          }
        },
        error => {
          this.loading = false;
          this.cdRef.detectChanges();
          this.notificationService.showError(error.message);
        },
      );
  }

  goBack() {
    if (this.newNotaryRequestApplicableUser.length) {
      this.forScreenBackNavigation.emit({ page: 'NOTARY_OPTIONS_LISTING_MULTIPLE' });
    } else {
      this.forScreenBackNavigation.emit({ page: 'NOTARY_OPTIONS_LISTING' });
    }
  }

  downloadForm1583(userId?: string) {
    this.loading = true;
    this.verifyService
      .getUserFormData(this.apiMapping.getUserOnboardForm(userId || this.selectedUser))
      .pipe(takeUntil(this.unSubscriber), retry(2))
      .subscribe(
        (response: OnboardFormResponse) => {
          this.loading = false;
          if (response && response.form && response.form.form_url) {
            this.downloadFormsFirstCall(this.downLoadForm.bind(this), response);
          }
          this.cdRef.detectChanges();
        },
        _error => {
          this.loading = false;
          this.cdRef.detectChanges();
          this.notificationService.showError('Unable to view file.');
        },
      );
  }

  downLoadForm(token, captchaVersion, response) {
    this.http.get(this.apiMapping.downloadForms(response.form.id, token, captchaVersion), { responseType: 'blob' }).subscribe(
      result => {
        const fileName = response.form.form_url.split('/').pop().split('#')[0].split('?')[0];
        SharedHelpers.downloadFile(result, fileName);
        this.notificationService.showWarning('File downloaded.');
        SharedHelpers.detectChanges(this.cdRef);
      },
      error => {
        if (error.status === 422) {
          this.verifyCaptcha(this.downLoadForm.bind(this), response);
        } else {
          this.notificationService.showError('Unable to downloaded form.');
        }
      },
    );
  }
}
