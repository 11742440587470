import { AbstractControl } from '@angular/forms';

export function passwordMatchValidator() {
  return (control: AbstractControl) => {
    if (control.get('password').valid && control.get('newPassword').valid) {
      const password = control.get('password').value;
      const newPassword = control.get('newPassword').value;
      if (password !== newPassword) {
        control.get('newPassword').setErrors({ passwordMatchInvalid: true });
      } else {
        control.get('newPassword').setErrors(null);
      }
    }
  };
}
