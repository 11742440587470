import { AccountStatus } from '@usgm/usgm-payloads-library-front/Enums';
import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IAddressAttributes } from '@usgm/usgm-payloads-library-front';
import { NotificationService, UserDataService, VerifyAccountService } from '../../services';
import { EditLabelDialogComponent } from './edit-label-dialog/edit-label-dialog.component';
import { DialogComponent } from '../dialog';
import { Animations } from '../../animations/element.animations';
import * as SharedHelpers from '../../utils/helpers';
import { MailFolderService } from '../../services/mail-folders.service';
import { VerifyAccountService as VerifyAccountServices } from '../../verify-account/verify-account.service';
import { MenuItems, MenuItemStoragePrefix } from '../../models/constants/menu-items.constant';
import { AppRoutes } from '../../models/constants/app-routes.constant';
import { SettingNavViewOptions } from '../../models/constants/settings-options.constant';

@Component({
  selector: 'usgm-side-bar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: Animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy {
  public userData: any = {};
  public menuItems = [];
  public warehouseAddress: IAddressAttributes = {} as IAddressAttributes;
  public appVersion;
  public height = 0;
  public boxNumber;
  public isRejected = false;
  public folderId: number = null;
  public scanFolderId: number = null;
  public showAllFolders = false;
  public showAllFoldersInScans = false;
  public visibleFoldersCount = 4;
  public accountStatus: AccountStatus = AccountStatus.APPROVED;
  @Input() showProfile = false;

  private routeChangeSubscriber = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    public userDataService: UserDataService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    protected verifyAccountService: VerifyAccountServices,
    private verifyService: VerifyAccountService,
    private maiFolderService: MailFolderService,
    private activatedRoute: ActivatedRoute,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.recalculateHeight();
  }

  async ngOnInit() {
    this.menuItems = JSON.parse(JSON.stringify(MenuItems));
    this.userData = this.userDataService.getDecodedAccessToken(this.userDataService.getAccessToken()) || {};
    if (this.userData.orgParentId) {
      this.menuItems = this.menuItems.filter(it => !it.excludeForSubAccounts);
    }
    this.verifyAccountService.menuItemsList.subscribe(data => {
      if (this.menuItems.length !== data.length) {
        this.menuItems = data;
      }
      this.setMenuItemsSelected();
    });
    this.verifyService.getValue().subscribe(value => {
      this.isRejected = value;
      SharedHelpers.detectChanges(this.cdr);
    });
    this.getBoxNumber();
    this.recalculateHeight();
    this.appVersion = this.userDataService.getAppVersion();
    this.warehouseAddress = this.userDataService.getWarehouseAddress() || {};
    this.userDataService.warehouseAddressFetched.subscribe(data => {
      this.warehouseAddress = this.userDataService.getWarehouseAddress() || {};
    });
    this.accountStatus = this.userDataService.getAccountStatus();
    this.setMenuItemsSelected();
    this.userDataService.menuCountUpdated$.subscribe(event => {
      sessionStorage.setItem(`${MenuItemStoragePrefix}${event.name}`, event.count);
      this.setMenuItemsSelected();
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.folderId = null;
      this.scanFolderId = null;
      if (params['folderId']) {
        this.folderId = parseInt(params['folderId'], 10);
        SharedHelpers.detectChanges(this.cdr);
      }
      if (params['scanFolderId']) {
        this.scanFolderId = parseInt(params['scanFolderId'], 10);
        SharedHelpers.detectChanges(this.cdr);
      }
    });
    this.routeChangeSubscriber = this.activatedRoute.url.subscribe(segments => {
      const inboxMenuItem = this.menuItems.find(item => item.id === 'mailbox');
      const scansMenuItem = this.menuItems.find(item => item.id === 'scans');
      inboxMenuItem.expandedByDefault = false;
      scansMenuItem.expandedByDefault = false;
      if (segments?.length) {
        const path = segments[0].path;
        if (path === 'inbox') {
          inboxMenuItem.expanded = true;
          inboxMenuItem.expandedByDefault = true;
          SharedHelpers.detectChanges(this.cdr);
        } else if (path === 'scans') {
          scansMenuItem.expanded = true;
          scansMenuItem.expandedByDefault = true;
          SharedHelpers.detectChanges(this.cdr);
        }
      }
    });
    this.loadFolders(false);
    this.userDataService.foldersUpdate.subscribe(folders => {
      this.menuItems.forEach(item => {
        if (['mailbox', 'scans'].includes(item.id)) {
          item.children = folders;
        }
      });
      SharedHelpers.detectChanges(this.cdr);
    });
  }

  async loadFolders(force: boolean = true): Promise<void> {
    const folders = await this.userDataService.getUserFolders(force);
    this.menuItems.forEach(item => {
      if (['mailbox', 'scans'].includes(item.id)) {
        item.children = folders;
      }
    });
    SharedHelpers.detectChanges(this.cdr);
  }

  async getBoxNumber() {
    await this.userDataService.getBoxNumber();
    this.boxNumber = this.userDataService.getWarehouseBoxNumber();
    SharedHelpers.detectChanges(this.cdr);
  }

  public menuItemChildOptionClicked(menuItemChild, calloutOption) {
    if (calloutOption === 'EDIT') {
      this.openDialog(menuItemChild, calloutOption);
    } else if (calloutOption === 'REMOVE FOLDER') {
      this.deleteConfirmation(menuItemChild);
      console.log('delete label');
    }
  }

  openDialog(menuItemChild, calloutOption): void {
    const dialogRef = this.dialog.open(EditLabelDialogComponent, {
      width: '400px',
      data: { data: menuItemChild, calloutOption },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadFolders(true);
      console.log('The dialog was closed', result);
    });
  }

  public addressString(address: IAddressAttributes) {
    return `${address.address_line} ${address.address_line_2 || ''} ${address.address_line_3 || ''} PMB ${this.boxNumber}, ${address.city},
     ${address.state}, ${address.postal_code}, ${address.country}`;
  }

  public copySuccess() {
    this.notificationService.showSuccess('Copied to clipboard!');
  }

  public changeAddress() {
    this.router.navigate(['/settings'], { queryParams: { page: SettingNavViewOptions.CHANGE_WAREHOUSE } });
  }

  public logoutUser() {
    this.userDataService.logoutUser();
  }

  public showMenuError(menuItem) {
    return (
      (this.isRejected && menuItem.path.indexOf(AppRoutes.settings) >= 0) ||
      menuItem.path.indexOf(AppRoutes.welcome) >= 0 ||
      menuItem.path.indexOf(AppRoutes.submitVerificationDocuments) >= 0
    );
  }

  public onAddFolder() {
    this.openDialog({}, {});
    return false;
  }

  public onSubMenuClick(menuItemChild, menuItem) {
    if (menuItem.id === 'mailbox') {
      this.router.navigate(['/inbox'], { queryParams: { folderId: menuItemChild.id } });
    } else if (menuItem.id === 'scans') {
      this.router.navigate(['/scans'], { queryParams: { scanFolderId: menuItemChild.id } });
    }
  }

  getMenuItemChildren(menuItem) {
    const children = menuItem.children;
    if (menuItem.id === 'mailbox') {
      return this.showAllFolders ? children : children.slice(0, this.visibleFoldersCount);
    } else if (menuItem.id === 'scans') {
      return this.showAllFoldersInScans ? children : children.slice(0, this.visibleFoldersCount);
    }
    return children;
  }

  public deleteConfirmation(folder: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: `Deleting this folder will revert your items back into your MailBox`,
      title: 'Are you sure you wish to delete this folder?',
      cancelText: 'No',
      confirmText: 'Yes',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.maiFolderService.delete(folder.id).subscribe(() => {
          this.loadFolders(true);
          if (folder.id === this.folderId) {
            this.router.navigate(['/inbox']);
          }
        });
      }
    });
  }

  public overlayErrorForMenu(menuItem) {
    if (menuItem.path.indexOf(AppRoutes.settings) >= 0) {
      return this.userDataService.documentRejectedStatusMessage;
    }
    if (menuItem.path.indexOf(AppRoutes.welcome) >= 0) {
      return 'Please upload all necessary documents for the names you have added.';
    }
    return '';
  }

  private async setMenuItemsSelected() {
    this.menuItems.forEach(menuItem => {
      menuItem.expanded = !!menuItem.expandedByDefault;
      menuItem.selected = this.router.url.indexOf(menuItem.path) >= 0;
      menuItem.children = menuItem.children || [];
    });
    if (this.folderId) {
      const menuItem = this.menuItems.find(item => item.id === 'mailbox');
      menuItem.expanded = true;
    }
    if (this.scanFolderId) {
      const menuItem = this.menuItems.find(item => item.id === 'scans');
      menuItem.expanded = true;
    }
    this.setMenuItemsCount();
    SharedHelpers.detectChanges(this.cdr);
  }

  private setMenuItemsCount() {
    this.menuItems.forEach(menuItem => {
      menuItem.count = sessionStorage.getItem(`${MenuItemStoragePrefix}${menuItem.icon}`) || 0;
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.routeChangeSubscriber?.unsubscribe();
  }

  private recalculateHeight() {
    this.height = this.userDataService.getScreenHeight() - (this.userDataService.isMobileMode ? 0 : 60);
  }
}
