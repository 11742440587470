import { CONFIRMATION_DIALOGS, TConfirmationDIalogType, TConfirmationMessageParams } from './config';

export type TConfirmationDialogParams = { type: TConfirmationDIalogType; params: TConfirmationMessageParams };

export const getConfirmationDialog = ({ type, params }: TConfirmationDialogParams) => {
  const dialog = CONFIRMATION_DIALOGS[type];

  return {
    ...dialog,
    message: typeof dialog.message === 'function' ? dialog.message(params) : dialog.message,
    title: typeof dialog.title === 'function' ? dialog.title(params) : dialog.title,
  };
};
