import { Injectable } from '@angular/core';
import { IAddressAttributes } from '@usgm/usgm-payloads-library-front';
import { ApiMapping } from './api-mapping.service';
import { UsgmHttp } from './usgm-http.service';
import { NotificationService } from './notification.service';
import { Observable } from 'rxjs';
import { AddressValidationResult } from '../models/address-validation-result';
import { UserDataService } from './user-data.service';

@Injectable()
export class AddressService {
  constructor(
    private apiMapping: ApiMapping,
    private http: UsgmHttp,
    private notificationService: NotificationService,
    private userDataService: UserDataService,
  ) {}

  public async getCountries() {
    try {
      let countries = await this.http.http_get(this.apiMapping.getCountries());
      countries = countries.map(country => {
        return {
          Id: country.alpha2,
          Name: country.name,
        };
      });
      countries.sort(this.compareFn);
      return countries;
    } catch (e) {
      this.notificationService.showError('Unable to fetch countries. Please try again.');
      throw new Error(e);
    }
  }

  public addAddress(address: IAddressAttributes): Promise<any> {
    return this.http.post(this.apiMapping.createUserAddress(this.userDataService.getUserId()), address).toPromise();
  }

  public updateAddress(address: IAddressAttributes): Promise<any> {
    return this.http.put(this.apiMapping.updateUserAddress(this.userDataService.getUserId(), address.id), address).toPromise();
  }

  public async validateAddress(address: IAddressAttributes): Promise<AddressValidationResult> {
    try {
      const validationResults = await this.http.http_post(this.apiMapping.validateAddress(), address);
      return validationResults;
    } catch (e) {
      this.notificationService.showWarningForDuration('Unable to Validate address.', 4000);
      throw new Error(e);
    }
  }

  public async getStatesByCountryId(countryCode) {
    try {
      let states = await this.http.http_get(this.apiMapping.getStates(countryCode));
      states = states.map(state => {
        return {
          Id: state.alpha2,
          Name: state.name,
        };
      });
      return states;
    } catch (e) {
      this.notificationService.showError('Unable to fetch states. Please try again.');
      throw new Error(e);
    }
  }

  public getStatesByCountryIdObservable(countryCode): Observable<any> {
    return this.http.get(this.apiMapping.getStates(countryCode));
  }

  private compareFn(a, b) {
    if (a.Name > b.Name) {
      return 1;
    }
    if (a.Name < b.Name) {
      return -1;
    }
    return 0;
  }
}
