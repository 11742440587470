import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDataService } from '../../services';
import { EnterDeclarationsBaseComponent } from './enter-declarations-base/enter-declarations-base.component';

@Component({
  selector: 'usgm-app-enter-declarations-dialog',
  templateUrl: './enter-declarations-dialog.component.html',
  styleUrls: ['./enter-declarations-dialog.component.scss'],
})
export class EnterDeclarationsDialogComponent implements OnInit {
  @ViewChild(EnterDeclarationsBaseComponent)
  declarationsbase: EnterDeclarationsBaseComponent;

  mailItems = [];

  constructor(
    public dialogRef: MatDialogRef<EnterDeclarationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { items: any },
    protected userDataService: UserDataService,
  ) {
    this.mailItems = data.items;
  }

  ngOnInit() {}

  saveDialog() {
    // auto save
    this.declarationsbase.save();
    this.dialogRef.close();
  }

  cancelDialog() {
    this.dialogRef.close();
  }

  removeDeclarationForItem(item, i) {
    item.shipping_declarations.splice(i, 1);
  }
}
