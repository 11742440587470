import { Component, Input, OnInit } from '@angular/core';
import { MailType } from '@usgm/usgm-payloads-library-front';
import { emptyDeclaration } from '../../../models/address-model';
import { UserDataService } from '../../../services';

@Component({
  selector: 'usgm-app-enter-declarations-base',
  templateUrl: './enter-declarations-base.component.html',
  styleUrls: ['./enter-declarations-base.component.scss'],
})
export class EnterDeclarationsBaseComponent implements OnInit {
  @Input()
  mailItems = [];
  @Input()
  displayTitle = false;

  constructor(protected userDataService: UserDataService) {}

  ngOnInit() {}

  addShippingDeclarationForItem(item) {
    if (!item.shipping_declarations) {
      item.shipping_declarations = [];
    }
    item.shipping_declarations.push(JSON.parse(JSON.stringify(emptyDeclaration)));
  }

  itemShippingDeclarationApplicable(item): boolean {
    const flag = [MailType.PACKAGE, MailType.SOFTPAK].includes(item.mail_type);
    if (flag && !item.shipping_declarations) {
      this.addShippingDeclarationForItem(item);
    }
    return flag;
  }

  save() {
    this.mailItems.forEach(mailItem => {
      if (mailItem.shipping_declarations) {
        const validDeclarations = mailItem.shipping_declarations.filter(mail => mail.description && mail.quantity);
        // remove invalid declarations
        mailItem.shipping_declarations = validDeclarations.length > 0 ? validDeclarations : [mailItem.shipping_declarations[0]];
        if (validDeclarations.length > 0) {
          this.userDataService.postDeclarationInfo(mailItem.id, { declarations: validDeclarations }).subscribe();
        }
      }
    });
  }

  removeDeclarationForItem(item, i) {
    item.shipping_declarations.splice(i, 1);
  }
}
