import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'usgm-notarized-popup',
  templateUrl: './notarized-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotarizedPopupComponent implements OnInit {
  @Input() item;

  constructor(public dialogRef: MatDialogRef<NotarizedPopupComponent>) {}

  public ngOnInit() {}
}
