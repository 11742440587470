import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from '../../environments/environment';
import { CaptchaBaseComponent } from '../captcha-base-component/captcha-base-component.component';
import { DialogComponent } from '../components/dialog';
import { PdfViewerDialogComponent } from '../components/pdf-viewer-dialog';
import { ApiCallMixin } from '../mixins/api-call.mixin';
import { AppRoutes } from '../models/constants/app-routes.constant';
import * as Services from '../services';
import * as Constants from '../utils/constants';
import * as SharedHelpers from '../utils/helpers';
import { ALERT_MESSAGES } from './config';
import { TConfirmationDialogParams, getConfirmationDialog } from './helpers';
import { ScansListTableComponent } from './scans-list-table/scans-list-table.component';

type TSelectionInfo = { total: number; selected: number; allSelected: boolean };

enum RightNavViewOptions {
  SCAN = 0,
  ISSUE = 1,
}

// @TODO: business logic should be moved from component to service
// #copy-pasta #bad_practice

@Component({
  selector: 'usgm-scans-list',
  templateUrl: './scans-list.component.html',
  styleUrls: ['./scans-list.component.scss', '../inbox/inbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScansListComponent extends ApiCallMixin(CaptchaBaseComponent) implements OnInit, OnDestroy {
  public isBulkActionsEnabled: boolean = false;
  public selectedItems: number[] = [];
  public isVisibleItemsSelected: boolean = false;
  public isAllItemsSelected: boolean = false;
  public selectionInfo: TSelectionInfo | null = null;
  public scanData: any = {};
  public scansList: any[] = [];
  public currentScan: any = {};
  public selectedTab = 'pending';
  public imgSrc = Constants.USGM_LOGO;
  public mobileMode = false;
  public userData: any = {};
  public rowsHeight = 591;
  public innerHeight = 0;
  public pageSize = Constants.ROWS_PER_PAGE;
  public scanFolderId: number = null;
  public scanTypes = [
    { name: 'ALL', value: '' },
    { name: 'SCANS', value: 'SCAN_REQUEST' },
    { name: 'OPEN', value: 'UNBOXING_REQUEST' },
  ];
  public selectedScanType = this.scanTypes[0];
  public pullToRefreshEvent: any;
  spinnerLoad = false;
  public NavViewOptions = RightNavViewOptions;
  public rightNavViewOption: any = RightNavViewOptions.SCAN;
  public isLegacy = false;
  public legacyScansDate;
  public legacyScansDeactivationDate = Constants.LEGACY_DEACTIVATION_DATE;
  private currentUnreadCount = 0;

  @ViewChild('scanDetailsDrawer') scanDetailsDrawer: MatSidenav;
  @ViewChild('issueDetailsDrawer') issueDetailsDrawer: MatSidenav;
  @ViewChild('scanListTable') scanListTable: ScansListTableComponent;

  constructor(
    private cdr: ChangeDetectorRef,
    private http: Services.UsgmHttp,
    private apiMapping: Services.ApiMapping,
    private userDataService: Services.UserDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public override dialog: MatDialog,
    public override notificationService: Services.NotificationService,
    public override recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
    this.isLegacy = this.userDataService.isLegacyUser();
    this.legacyScansDate = this.userDataService.getLegacyMigrationDate();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDisplayMode();
    setTimeout(() => {
      this.setItemsHeight();
      SharedHelpers.detectChanges(this.cdr);
    }, 500);
  }

  public override ngOnInit() {
    this.userData = this.userDataService.getDecodedAccessToken(this.userDataService.getAccessToken()) || {};
    !this.userData.orgParentId &&
      this.userDataService.heightUpdated$.subscribe(() => {
        this.onResize({});
      });
    this.activatedRoute.queryParams.subscribe(async params => {
      this.scanFolderId = null;
      if (params['scanFolderId']) {
        this.scanFolderId = parseInt(params['scanFolderId'], 10);
      }
      if (!this.scanFolderId && this.userData?.orgRole === 'REGULAR') {
        const folders = await this.userDataService.getUserFolders(false);
        if (folders?.length && folders[0]) {
          return this.router.navigate([AppRoutes.scans], { queryParams: { scanFolderId: folders[0]['id'] } });
        }
      }
      SharedHelpers.detectChanges(this.cdr);
      this.setPages();
      this.getPendingScans();
      this.getCompletedScans();
      this.onResize({});
    });
  }

  public setDisplayMode() {
    this.mobileMode = this.userDataService.isMobileMode;
  }

  public setPages() {
    this.scanData.pending_scans_page = 0;
    this.scanData.get_pending_scans_page = true;
    // This is actually unread count
    this.scanData.pending_scans_total = 0;
    this.scanData.pending_total = 0;
    this.scanData.pending_scans = [];
    this.scanData.pending_count_per_page = this.pageSize;
    this.scanData.completed_scans_page = 0;
    this.scanData.get_completed_scans_page = true;
    // This is actually unread count
    this.scanData.completed_scans_total = 0;
    this.scanData.completed_total = 0;
    this.scanData.completed_scans = [];
    this.scanData.completed_count_per_page = this.pageSize;
  }

  public setItemsHeight() {
    this.innerHeight = (document.getElementsByClassName('scan-drawer')[0] || {})['offsetHeight'];
    if (this.mobileMode) {
      document.querySelector('mat-drawer-container.inbox-drawer-container')['style'].height = this.userDataService.getScreenHeight() + 'px';
      this.rowsHeight = this.userDataService.getScreenHeight() - 196;
    } else {
      this.rowsHeight = this.innerHeight - 180;
    }
    this.rowsHeight -= this.isLegacy ? 22 : 0;
  }

  setupSelectedScan() {
    if (!this.activatedRoute.snapshot.queryParamMap.get('mail')) {
      return;
    }
    const selectedMailId = this.activatedRoute.snapshot.queryParamMap.get('mail');
    (this.scanData.pending_scans || []).forEach(scan => {
      if (selectedMailId + '' === scan.mail.id + '') {
        const row = document.getElementById((this.mobileMode ? 'mobile-pending-scan-' : 'pending-scan-') + scan.id);
        if (row) {
          row.click();
        }
      }
    });
    this.router.navigate([this.router.url.split('?')[0]], { queryParams: {} });
  }

  public getPendingScans() {
    this.startedApiCall();
    this.http.get(this.apiMapping.getPendingScans(this.scanData.pending_scans_page, this.pageSize, this.selectedScanType.value, this.scanFolderId || -1)).subscribe(
      (data: any) => {
        this.setupScanData(data, 'pending');
        this.tabSelected(this.selectedTab);
        SharedHelpers.detectChanges(this.cdr);
        setTimeout(() => {
          this.setupSelectedScan();
        }, 1000);
      },
      (error: any) => {
        this.completedApiCall();
        if (error.status !== 401 && error.status !== 403) {
          this.notificationService.showError('Unable to fetch items. Please try again.');
        }
      },
    );
  }

  public getCompletedScans() {
    this.startedApiCall();
    this.http.get(this.apiMapping.getCompletedScans(this.scanData.completed_scans_page, this.pageSize, this.selectedScanType.value, this.scanFolderId || -1)).subscribe(
      (data: any) => {
        this.setupScanData(data, 'completed');
        this.currentUnreadCount = parseInt(data['completed_scans'], 10);
        this.userDataService.menuCountUpdated$.next({ name: 'scans', count: data['completed_scans'] });
        this.tabSelected(this.selectedTab);
        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        this.completedApiCall();
        if (error.status !== 401 && error.status !== 403) {
          this.notificationService.showError('Unable to fetch items. Please try again.');
        }
      },
    );
  }

  setupScanData(data: any, status: string) {
    const scans: any[] = data.scans;
    scans.forEach(scan => {
      scan.delete_after_days = scan.auto_delete_after;
      scan.isoCreatedOn = scan.created_on;
      scan.arrived_date = SharedHelpers.stringToLocaleDate(scan.mail.arrival_date);
      scan.created_on = SharedHelpers.stringToLocaleDate(scan.created_on);
      scan.updated_on = SharedHelpers.stringToLocaleDate(scan.updated_on);
      if (status === 'completed') {
        scan.scanned_on = SharedHelpers.scanUpdateDateFormat((scan.status_transition.find(transition => transition.status === 'COMPLETED') || {}).updated_on);
        scan.requested_on = SharedHelpers.scanUpdateDateFormat((scan.status_transition.find(transition => transition.status === 'IN_PROCESS') || {}).updated_on);
      }
      scan.category_type_name = SharedHelpers.snakeToSentenceCase(scan.category_type).replace('Unboxing', 'Opening');
      scan.request_type_without_text = scan.category_type_name.split(' ')[0].replace('ing', '');
      scan.has_issue = (scan.current_status || '').indexOf('REJECTED') >= 0;
      scan.file_extension = scan.category_type === 'UNBOXING_REQUEST' ? 'jpg' : 'pdf';
      SharedHelpers.setPropertiesForMail(scan.mail);
    });
    if (status === 'pending') {
      scans.forEach(item => {
        item.selected = this.isAllItemsSelected;
        this.scanData.pending_scans.push(item);
      });
      if (scans.length < this.scanData.pending_count_per_page) {
        this.scanData.get_pending_scans_page = false;
      }
      this.scanData.pending_scans_total = data['pending_scans'];
      this.scanData.pending_total = data['total'];
    } else if (status === 'completed') {
      scans.forEach(item => {
        item.selected = this.isAllItemsSelected;
        this.scanData.completed_scans.push(item);
      });
      if (scans.length < this.scanData.completed_count_per_page) {
        this.scanData.get_completed_scans_page = false;
      }
      this.scanData.completed_total = data['total'];
    }
    this.completedApiCall();
    SharedHelpers.detectChanges(this.cdr);
  }

  public cancelRequest(scan) {
    this.startedApiCall();
    this.http.put(this.apiMapping.cancelScan(scan.id), {}).subscribe(
      (data: any) => {
        this.completedApiCall();
        if (data.message.toLowerCase().indexOf('cancelled successfully') >= 0) {
          this.scanDetailsDrawer.close();
          this.reloadCurrentTab();
        } else {
          this.notificationService.showError('Unable to cancel request. Please try again.');
        }
      },
      (error: any) => {
        this.completedApiCall();
        this.notificationService.showError('Unable to cancel request. Please try again.');
      },
    );
  }

  public scanDetailClicked(scan) {
    if (scan?.current_status === 'COMPLETED') {
      this.markRead();
    }
    this.rightNavViewOption = RightNavViewOptions.SCAN;
    this.scanDetailsDrawer.open();
  }

  private markRead() {
    if (!this.currentScan || this.currentScan.is_read) {
      return false;
    }
    const id = this.currentScan.id;
    this.http.put(this.apiMapping.markScanRead(), { scanId: id }).subscribe(
      result => {
        const data = ((this.scanData || {}).scans || []).find(item => item.id === id);
        data.is_read = true;
        this.userDataService.menuCountUpdated$.next({ name: 'scans', count: --this.currentUnreadCount });
        this.cdr.detectChanges();
        this.scanListTable.detectChanges();
      },
      error => {
        console.log(error);
      },
    );
  }

  public scanTypeSelected(newValue) {
    this.selectedScanType = newValue;
    this.setPages();
    this.getPendingScans();
    this.getCompletedScans();
  }

  public confirmCancel(scan) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: `Are you sure you want to cancel this ${scan.category_type_name}?`,
      title: 'Please confirm!',
      cancelText: 'No',
      confirmText: 'Yes, cancel!',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.cancelRequest(scan);
      }
    });
  }

  public reloadCurrentTab() {
    this.clearSelectAll();
    switch (this.selectedTab) {
      case 'pending':
        this.scanData.pending_scans = [];
        this.scanData.pending_scans_page = 0;
        this.scanData.get_pending_scans_page = true;
        this.getPendingScans();
        break;
      case 'completed':
        this.scanData.completed_scans = [];
        this.scanData.completed_scans_page = 0;
        this.scanData.get_completed_scans_page = true;
        this.getCompletedScans();
        break;
    }
  }

  public getNextPage() {
    switch (this.selectedTab) {
      case 'pending':
        if ((this.scanData.pending_scans || []).length === 0) {
          return;
        }
        if (this.scanData.get_pending_scans_page) {
          this.scanData.pending_scans_page++;
          this.getPendingScans();
        } else {
          console.log('All pending pages loaded');
        }
        break;
      case 'completed':
        if ((this.scanData.completed_scans || []).length === 0) {
          return;
        }
        if (this.scanData.get_completed_scans_page) {
          this.scanData.completed_scans_page++;
          this.getCompletedScans();
        } else {
          console.log('All completed pages loaded');
        }
        break;
      default:
        break;
    }
  }

  public infiniteLoadingEnabled() {
    switch (this.selectedTab) {
      case 'pending':
        return this.scanData.get_pending_scans_page;
      case 'completed':
        return this.scanData.get_completed_scans_page;
      default:
        break;
    }
    return false;
  }

  public tabSelected(tab: string) {
    this.initBulkActions(tab);
    this.scansList = this.getScansForTab(tab);
    this.selectedTab = tab;
    this.userDataService.setScreenForAnalytics(`scans-${this.selectedTab}`);
    if (this.pullToRefreshEvent) {
      setTimeout(() => this.pullToRefreshEvent.target.complete(), 2000);
    }
  }

  public getScansForTab(tab: string) {
    this.isVisibleItemsSelected = this.isAllItemsSelected;
    if (tab === 'legacy_scans') {
      return [];
    }
    return tab === 'pending' ? this.scanData.pending_scans : this.scanData.completed_scans;
  }

  public trackItems(index: number, item: any) {
    return item.id;
  }

  public fetchMore(event) {
    setTimeout(() => {
      event.target.complete(); // hide infinite loader
    }, 2000);
    const scans = this.getScansForTab(this.selectedTab);
    if (event.endIndex && event.endIndex !== (scans || []).length - 1) {
      return;
    }
    this.getNextPage();
  }

  public pullToRefresh(event) {
    this.pullToRefreshEvent = event;
    this.reloadCurrentTab();
  }

  public handleItemSlidingOptionsClick(scan, event, slidingItem) {
    slidingItem.close();
    switch (event) {
      case 'download':
        this.startedApiCall();
        this.http.get(this.apiMapping.downloadScan(scan.id), { responseType: 'blob', observe: 'response' }).subscribe(
          result => {
            const contentType = result.headers.get('content-type');
            SharedHelpers.downloadFile(result.body, `${scan.request_type_without_text} #${scan.id}.${contentType.split('/')[1]}`, contentType);
            this.completedApiCall();
            this.notificationService.showWarning('File downloaded.');
            SharedHelpers.detectChanges(this.cdr);
          },
          error => {
            this.completedApiCall();
            this.notificationService.showError('Unable to download file. Please try again.');
          },
        );
        break;
      case 'view-pdf':
        this.dialog.open(PdfViewerDialogComponent, {
          maxWidth: 'calc(100vw - 10px)',
          panelClass: 'no-padding',
          data: { url: this.apiMapping.viewScan(scan.id), fileType: scan.file_extension },
        });
        break;
      case 'cancel':
        this.confirmCancel(scan);
        break;
      case 'report-issue':
        scan.contactSupportEnabled = true;
        this.currentScan = scan;
        this.scanDetailClicked(scan);
        break;
      case 'delete':
        this.confirmAction(
          {
            params: { count: 1 },
            type: 'delete',
          },
          () => {
            this.deleteScan(scan);
          },
        );
        break;
    }
  }
  // @TODO: #refactor move to according service E.g ConfirmationDialog
  public confirmAction(params: TConfirmationDialogParams, callback: () => void) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = getConfirmationDialog(params);
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        callback();
      }
    });
  }

  public deleteScan(scan: any) {
    this.http.put(this.apiMapping.deleteScan(scan.id), {}).subscribe(
      result => {
        this.reloadCurrentTab();

        this.notificationService.showWarning('Scan is deleted successfully');
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.notificationService.showError('Unable to delete the scan');
      },
    );
  }

  public errorMessageForNoItems() {
    return this.selectedTab === 'pending'
      ? `No ${this.selectedScanType.value === 'UNBOXING_REQUEST' ? 'open' : 'scan'} requests!`
      : `No completed ${this.selectedScanType.value === 'UNBOXING_REQUEST' ? 'open' : 'scan'}s!`;
  }

  public async redirectToLegacySystem() {
    if (this.spinnerLoad) {
      return false;
    }
    this.spinnerLoad = true;
    const token: string = await this.userDataService.legacyMigrationGenerateAndReturnToken();
    const legacyURL = `${environment.legacyAppUrl}/membership/redirect/?target_page=scans&sso_jwt=${token}`;
    this.spinnerLoad = false;
    SharedHelpers.detectChanges(this.cdr);
    window.open(legacyURL, '_system');
  }
  public onScanDelete(scan: any) {
    this.confirmAction({ params: { count: 1 }, type: 'delete' }, () => {
      this.deleteScan(scan);
    });
  }

  public toggleVisibleItemsSelection() {
    this.isVisibleItemsSelected = !this.isVisibleItemsSelected;

    this.selectedItems = [];
    this.scansList.forEach(s => {
      s.selected = this.isVisibleItemsSelected;
      if (s.selected) {
        this.selectedItems.push(s.uuid);
      }
    });
    this.scansList = structuredClone(this.scansList);
    this.populateSelectionInfo();
    SharedHelpers.detectChanges(this.cdr);
  }

  public reloadScans() {
    this.reloadCurrentTab();
  }

  public onToggleSelection(scan: any) {
    scan.selected = !scan.selected;
    this.isVisibleItemsSelected = true;
    this.selectedItems = [];
    this.scansList.forEach(s => {
      if (s.id === scan.id) {
        s.selected = !s.selected;
      }
      if (s.selected) {
        this.selectedItems.push(s.uuid);
      }
      this.isVisibleItemsSelected = this.isVisibleItemsSelected && s.selected;
    });
    this.populateSelectionInfo();
    if (!scan.selected) {
      this.isAllItemsSelected = false;
    }
    SharedHelpers.detectChanges(this.cdr);
  }

  private populateSelectionInfo() {
    if (this.isVisibleItemsSelected) {
      const totalItems = this.scanData[`${this.selectedTab}_total`];

      this.selectionInfo = {
        total: totalItems,
        selected: this.selectedItems.length,
        allSelected: this.isAllItemsSelected,
      };
    } else {
      this.selectionInfo = null;
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.cdr.detach();
  }
  public selectAll($event: MouseEvent) {
    $event.preventDefault();
    this.isAllItemsSelected = true;
    this.populateSelectionInfo();
  }
  public clearSelectAll() {
    this.isAllItemsSelected = false;
    this.isVisibleItemsSelected = false;
    this.selectionInfo = null;
    this.selectedItems = [];
    this.scansList = this.scansList.map(s => ({ ...s, selected: false }));
    SharedHelpers.detectChanges(this.cdr);
  }

  // @TODO: #refactor move to according service
  public bulkDelete() {
    this.confirmAction(
      {
        type: 'delete',
        params: this.getMessageTemplateParams(),
      },
      () => {
        this.http
          .post(this.apiMapping.deleteMultipleScans(), {
            scanIds: this.isAllItemsSelected ? 'all' : this.selectedItems,
          })
          .subscribe({
            complete: () => {
              this.reloadCurrentTab();
              this.clearSelectAll();
              this.notificationService.showWarning(ALERT_MESSAGES.DELETE_SUCCESS(this.getMessageTemplateParams()));
              SharedHelpers.detectChanges(this.cdr);
            },
            error: () => {
              this.notificationService.showError(ALERT_MESSAGES.DELETE_FAILED(this.getMessageTemplateParams()));
            },
          });
      },
    );
  }
  public bulkDownload(token, captchaVersion) {
    this.confirmAction(
      {
        type: 'download',
        params: this.getMessageTemplateParams(),
      },
      () => {
        this.http
          .post(this.apiMapping.exportMultipleScans({ captchaVersion, token }), {
            scans: this.isAllItemsSelected ? 'all' : this.selectedItems.join(','),
          })
          .subscribe({
            complete: () => {
              this.clearSelectAll();
              this.notificationService.showWarning(ALERT_MESSAGES.EXPORT_SUCCESS);
              SharedHelpers.detectChanges(this.cdr);
            },
            error: () => {
              this.notificationService.showError(ALERT_MESSAGES.EXPORT_FAILED(this.getMessageTemplateParams()));
            },
          });
      },
    );
  }
  // @TODO: #refactor move to according service
  public initiateBulkDownload() {
    this.downloadIDsFirstCall(this.bulkDownload.bind(this));
  }

  private getMessageTemplateParams() {
    return { count: this.isAllItemsSelected ? undefined : this.selectedItems.length };
  }

  public initBulkActions(tab: string) {
    if (tab === 'pending') {
      this.isBulkActionsEnabled = false;
    } else {
      this.isBulkActionsEnabled = !this.userData.orgParentId && this.userData.orgRole !== 'REGULAR';
    }
  }

  public toggleItemSelection($event: MouseEvent, scan: any) {
    $event.stopPropagation();

    scan.selected = !scan.selected;
    if (!scan.selected && (this.isVisibleItemsSelected || this.isAllItemsSelected)) {
      this.isVisibleItemsSelected = false;
      this.isAllItemsSelected = false;
    }
    this.onToggleSelection(scan);
  }

  public stopEventPropagation(event: MouseEvent) {
    event.stopImmediatePropagation();
  }
}
