import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FileSaverModule } from 'ngx-filesaver';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { PipesModule } from '../pipes';
import { MatExpansionModule } from '@angular/material/expansion';
import { ComponentsModule } from '../components';
import { AddAddressComponent } from './add-address/add-address.component';
import { AddIdsComponent } from './add-ids/add-ids.component';
import { AddNamesComponent } from './add-names/add-names.component';
import { GetNotarizedComponent } from './get-notarized/get-notarized.component';
import { NotarizedPopupComponent } from './notarized-popup/notarized-popup.component';
import { SubmitIdPopupComponent } from './submit-id-popup/submit-id-popup.component';
import { UpgradePlanComponent } from './upgrade-plan/upgrade-plan.component';
import { VerifyAccountComponent } from './verify-account.component';
import { ChildUserIdIdentificationComponent } from './child-user-id-identification/child-user-id-identification.component';
import { UserIdIdentificationComponent } from './user-id-identification/user-id-identification.component';
import { NotarizeFormComponent } from './notarize-form/notarize-form.component';
import { NotarizeWithNotarycamComponent } from './notarize-with-notarycam/notarize-with-notarycam.component';
import { NotarizeWithUsgmComponent } from './notarize-with-usgm/notarize-with-usgm.component';
import { NotarizeOfflineComponent } from './notarize-offline/notarize-offline.component';
import { OnlineNotarizationPopupComponent } from './online-notarization-popup/online-notarization-popup.component';
import { UpgradeFamilyPlanComponent } from './upgrade-family-plan/upgrade-family-plan.component';
import { UpgradeBusinessPlanComponent } from './upgrade-business-plan/upgrade-business-plan.component';
import { VerifyAccountService } from './verify-account.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DirectivesModule } from '../directives';

@NgModule({
  providers: [VerifyAccountService],
  declarations: [
    VerifyAccountComponent,
    AddAddressComponent,
    AddNamesComponent,
    AddIdsComponent,
    SubmitIdPopupComponent,
    GetNotarizedComponent,
    UpgradePlanComponent,
    ChildUserIdIdentificationComponent,
    UserIdIdentificationComponent,
    NotarizedPopupComponent,
    NotarizeFormComponent,
    NotarizeWithNotarycamComponent,
    NotarizeWithUsgmComponent,
    NotarizeOfflineComponent,
    OnlineNotarizationPopupComponent,
    UpgradeFamilyPlanComponent,
    UpgradeBusinessPlanComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    ComponentsModule,
    FileSaverModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    VirtualScrollerModule,
    MatStepperModule,
    MatIconModule,
    MatDatepickerModule,
    PdfViewerModule,
    PinchZoomModule,
    PipesModule,
    MatProgressSpinnerModule,
    DirectivesModule,
    MatExpansionModule,
  ],
  exports: [
    VerifyAccountComponent,
    AddAddressComponent,
    AddNamesComponent,
    AddIdsComponent,
    SubmitIdPopupComponent,
    GetNotarizedComponent,
    NotarizedPopupComponent,
    OnlineNotarizationPopupComponent,
  ],
})
export class VerifyAccountModule {}
