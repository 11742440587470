import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountStatus } from '@usgm/usgm-payloads-library-front';
import * as Services from '../../services';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';
import * as SharedHelpers from '../../utils/helpers';
import { MailFolderEvent } from '../../models/mail-folder-event';
import { MatDialog } from '@angular/material/dialog';
import { LookupPopupComponent } from '../lookup-popup';
import { PdfViewerDialogComponent } from '../../components/pdf-viewer-dialog';
import { SettingNavViewOptions } from '../../models/constants/settings-options.constant';

@Component({
  selector: 'usgm-items-table',
  styleUrls: ['./items-table.component.scss'],
  templateUrl: './items-table.component.html',
})
export class ItemsTableComponent implements OnInit {
  private numHeaders = 9;
  private folders = null;
  public folderId = null;
  public enableUnequalChildrenSizes = true;
  public bufferAmount = 5;
  public userData: any = {};
  @Input() items;
  @Input() tableClass;
  @Input() allSelected;
  @Input() mailsHeight;
  @Output() itemSelectionChanged = new EventEmitter();
  @Output() itemDetailClicked = new EventEmitter();
  @Output() fetchMoreCalled = new EventEmitter();
  @Output() readMailIds = new EventEmitter();
  @Output() scanOptionRequested = new EventEmitter();
  @Output() shipItemDestination = new EventEmitter();
  @Output() mailItemClicked = new EventEmitter();
  @Output() unboxRequested = new EventEmitter();
  @Output() reportIssueRequested = new EventEmitter();
  @Output() itemBookmarked = new EventEmitter();
  @Output() discardItemRequested = new EventEmitter();
  @Output() moveItemsToFolderRequested = new EventEmitter();
  @Output() addAndMoveItemsToFolderRequested = new EventEmitter();
  @Output() depositCheckRequested = new EventEmitter();
  @Output() declareRequested = new EventEmitter();
  @Output() returnItemRequested = new EventEmitter();
  @Output() addOrEditNotesForItem = new EventEmitter();

  constructor(
    protected http: Services.UsgmHttp,
    protected apiMapping: Services.ApiMapping,
    protected userDataService: Services.UserDataService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.fixHeadersWidth();
  }

  public ngOnInit() {
    this.userData = this.userDataService.getDecodedAccessToken(this.userDataService.getAccessToken()) || {};
    this.fixHeadersWidth();
    this.activatedRoute.queryParams.subscribe(params => {
      this.folderId = null;
      if (params['folderId']) {
        this.folderId = parseInt(params['folderId'], 10);
      }
    });
    this.loadFolders();
    this.userDataService.foldersUpdate.subscribe(folders => {
      this.folders = folders;
    });
  }

  public async loadFolders() {
    this.folders = await this.userDataService.getUserFolders(false);
  }

  public fixHeadersWidth() {
    setTimeout(() => {
      for (let i = 1; i <= this.numHeaders; i++) {
        const element = document.querySelectorAll('usgm-items-table .header-' + i)[0];
        if (element) {
          let elementWidth = 150;
          if (i === 1) {
            elementWidth = 60;
          } else if (i === 3) {
            elementWidth = 200;
          } else if (i === 4) {
            elementWidth = 100;
          } else if (i === 6) {
            elementWidth = 100;
          } else if (i === 8) {
            elementWidth = 120;
          } else if (i === this.numHeaders) {
            elementWidth = 3;
          }
          element.setAttribute('width', Math.min(elementWidth, 350) + 'px');
        }
      }
    }, 10);
  }

  public openPopover(event) {
    event.stopPropagation();
    this.dialog.open(LookupPopupComponent, {
      width: '300px',
    });
  }

  public addNotesAgainstMailItem(mailId) {
    this.addOrEditNotesForItem.emit(mailId);
  }

  public toggleItemSelection(event, item: any) {
    event.stopPropagation();
    if (SharedHelpers.actionsDisabledForItem(item)) {
      return;
    }
    item.selected = !item.selected;
    if (!item.selected && this.allSelected) {
      this.allSelected = false;
    }
    this.itemSelectionChanged.emit({});
  }

  public itemClicked(event, item: any) {
    this.itemDetailClicked.emit(item);
  }

  public itemRowClicked(event: any, item: any) {
    this.mailItemClicked.emit(item);
  }

  public scanButtonClicked(event: any) {
    event.stopPropagation();
    this.scanOptionRequested.emit({});
  }

  public reportIssueButtonClicked(event: any) {
    event.stopPropagation();
    this.reportIssueRequested.emit({});
  }

  public unboxButtonClicked(event: any) {
    event.stopPropagation();
    this.unboxRequested.emit({});
  }

  public depositCheckBtnClicked(event: any) {
    event.stopPropagation();
    this.depositCheckRequested.emit({});
  }

  declareBtnClicked(event: any) {
    event.stopPropagation();
    this.declareRequested.emit({});
  }

  public returnBtnClicked(event: any) {
    event.stopPropagation();
    this.returnItemRequested.emit({});
  }

  public shipButtonClicked(event: any) {
    event.stopPropagation();
    this.shipItemDestination.emit({});
  }

  public discardButtonClicked(event: any) {
    event.stopPropagation();
    this.discardItemRequested.emit({});
  }

  public addNamedButtonClicked(event: any) {
    event.stopPropagation();
    this.router.navigate(['/settings'], { queryParams: { page: SettingNavViewOptions.MANAGE_ACCOUNT_NAMES } });
  }

  public moveItemsToFolder(event: MailFolderEvent) {
    this.moveItemsToFolderRequested.emit(event);
  }

  public onAddFolderAndMove(event: MailFolderEvent) {
    this.addAndMoveItemsToFolderRequested.emit(event);
  }

  public trackItems(index, item) {
    return item.id;
  }

  getAllButCurrentFolder() {
    return this.folders ? this.folders.filter(folder => folder.id !== this.folderId) : [];
  }

  public shouldButtonBeDisabled(item): boolean {
    return SharedHelpers.actionsDisabledForItem(item);
  }

  // currently assumes all mails passed to it are unread
  public putReadMail(item_ids: any) {
    this.readMailIds.emit(item_ids);
  }

  private getExtension(scanInfo) {
    return scanInfo.category_type === 'UNBOXING_REQUEST' ? 'jpg' : 'pdf';
  }

  public onScanIndicatorClicked(event: any, item: any) {
    event.stopImmediatePropagation();
    const extension = this.getExtension(item.scan_info);
    this.dialog.open(PdfViewerDialogComponent, {
      maxWidth: 'calc(100vw - 10px)',
      panelClass: 'no-padding',
      data: { url: this.apiMapping.viewScan(item.scan_uuid), fileType: extension },
    });
  }

  public showScanIndicator(item: any) {
    return ['COMPLETED', 'DELETED'].includes(item?.scan_info?.status) && item?.scan_uuid;
  }

  public isScanIndicatorDisabled(item: any) {
    return item?.scan_info?.status === 'DELETED';
  }

  public fetchMore(event: IPageInfo) {
    if (!this.items) {
      return;
    }
    if (event.endIndex !== this.items.length - 1) {
      return;
    }
    this.fetchMoreCalled.emit(true);
  }

  public trackingTooltipForItem(item) {
    return `${item.tracking_number}`;
  }

  public allActionsDisabled() {
    return this.userDataService.getAccountStatus() === AccountStatus.SUSPENDED;
  }
}
