import { AddressType, IAddressAttributes } from '@usgm/usgm-payloads-library-front';
import { MatRadioChange } from '@angular/material/radio';
import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddressValidationResult } from '../../models/address-validation-result';
import { addressAttributesToString } from '../../utils/helpers';
import { AddressService, NotificationService } from '../../services';

@Component({
  selector: 'usgm-suggest-address-dialog',
  templateUrl: './suggest-address-dialog.html',
  styleUrls: ['./suggest-address-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestAddressDialogComponent implements OnInit {
  public labelName = '';

  public addressValidationResult: AddressValidationResult;
  public showLoader = false;
  public addresses: any[];
  public selectedAddressIndex = 1;

  constructor(
    public dialogRef: MatDialogRef<SuggestAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private addressService: AddressService,
    private notificationService: NotificationService,
  ) {
    this.addressValidationResult = data.validationResult;
    if (!this.addressValidationResult.suggestedAddresses.length) {
      return;
    }

    this.addresses = [
      {
        title: 'Original Address',
        note1: `Please be advised address is incorrect.`,
        note2: `Using this address can result in lost or return shipments.`,
        isAddressComplete: this.addressValidationResult.addressComplete,
        hasUnconfirmedComponents: this.addressValidationResult.hasUnconfirmedComponents,
        address: this.addressValidationResult.providedAddress,
        formattedAddress: addressAttributesToString(this.addressValidationResult.providedAddress),
      },
      ...this.addressValidationResult.suggestedAddresses.map(item => ({
        title: 'Suggested Address',
        ...item,
        formattedAddress: addressAttributesToString(item.address),
      })),
    ];
  }

  onSelectedAddressChanged(change: MatRadioChange): void {
    this.selectedAddressIndex = change.value;
  }

  ngOnInit() {}

  onEditClicked(address: IAddressAttributes) {
    this.closeDialog(false, true, address);
  }

  async onSaveInvalidAddress(address: IAddressAttributes) {
    await this.saveAddress(this.addressValidationResult.providedAddress);
  }

  async onSaveClicked() {
    const selectedAddress = this.addresses[this.selectedAddressIndex].address;
    await this.saveAddress(selectedAddress);
  }

  async saveAddress(selectedAddress: IAddressAttributes): Promise<void> {
    try {
      this.showLoader = true;
      if (!selectedAddress.address_type) {
        selectedAddress.address_type = AddressType.SHIPPING;
      }
      (selectedAddress as any).is_validated = true;
      if (this.addressValidationResult.providedAddress.id) {
        await this.addressService.updateAddress({
          ...this.addressValidationResult.providedAddress,
          ...selectedAddress,
          id: this.addressValidationResult.providedAddress.id,
        });
      } else {
        await this.addressService.addAddress(selectedAddress);
      }
    } catch (err) {
      this.notificationService.showError('Error while saving address. Please try again latter.');
      console.error(err);
      this.showLoader = false;
      return;
    }
    this.showLoader = false;
    this.closeDialog(true, false, selectedAddress);
  }

  closeDialog(addressSaved: boolean = false, wantEdit: boolean = false, address: IAddressAttributes = null) {
    this.dialogRef.close({ addressSaved, wantEdit, address, providedAddressId: this.addressValidationResult.providedAddress.id });
  }
}
