import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as SharedHelpers from '../../utils/helpers';
import { ApiMapping, UsgmHttp, NotificationService } from '../../services';
import { DialogComponent } from '../dialog';

@Component({
  selector: 'usgm-issue-detail-popup',
  templateUrl: './issue-detail-popup.component.html',
  styleUrls: ['./issue-detail-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueDetailPopupComponent implements OnInit, OnDestroy {
  public loading = false;

  @Input() issue: any = {};
  @Input() issueType = 'inbox';

  @Output() closeNav = new EventEmitter();
  @Output() closeNavAndUpdatePage = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
    protected http: UsgmHttp,
    protected dialog: MatDialog,
    protected apiMapping: ApiMapping,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {}

  confirmCancel() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: `Are you sure you want to cancel this request?`,
      title: 'Please confirm!',
      cancelText: 'No',
      confirmText: 'Yes, cancel!',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.cancelRequest();
      }
    });
  }

  cancelRequest() {
    this.loading = true;
    SharedHelpers.detectChanges(this.cdr);
    const promise = this.issueType === 'scan' ? this.http.put(this.apiMapping.cancelScan(this.issue.id), {}) : this.http.put(this.apiMapping.cancelShipment(this.issue.id), {});
    promise.subscribe(
      (data: any) => {
        this.loading = false;
        if (data.message.toLowerCase().indexOf('cancelled successfully') >= 0) {
          this.closeNavAndUpdatePage.next({});
        } else {
          this.notificationService.showError('Unable to cancel request. Please try again.');
        }
        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        this.loading = false;
        this.notificationService.showError('Unable to cancel request. Please try again.');
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }
}
