import { IUserAttributes } from '@usgm/usgm-payloads-library-front';

export enum FormErrorSource {
  DOCUMENT_PRIMARY = 'DOCUMENT_PRIMARY',
  DOCUMENT_SECONDARY = 'DOCUMENT_SECONDARY',
  PHONE_NUMBER = 'PHONE_NUMBER',
  COMPANY_ADDRESS = 'COMPANY_ADDRESS',
  BOX_NUMBER = 'BOX_NUMBER',
  USER_SUBSCRIPTION = 'USER_SUBSCRIPTION',
  UNKNOWN = 'UNKNOWN',
}
export enum FormErrorCategory {
  DOCUMENT_EXPIRED = 'DOCUMENT_EXPIRED',
  RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
  DOCUMENT_TYPE_MISMATCH = 'DOCUMENT_TYPE_MISMATCH',
  DOCUMENT_INFO = 'DOCUMENT_INFO',
  UNKNOWN = 'UNKNOWN',
}
export enum FormErrorStatus {
  UNHANDLED = 'UNHANDLED',
  WAITING_FOR_CUSTOMER = 'WAITING_FOR_CUSTOMER',
  RESOLVED_BY_ADMIN = 'RESOLVED_BY_ADMIN',
  RESOLVED_BY_CUSTOMER = 'RESOLVED_BY_CUSTOMER',
  IGNORED = 'IGNORED',
}

export interface IFormErrorMeta {
  title: string;
  subtitle?: string;
  extracting?: boolean;
  saving?: boolean;
  completed?: boolean;
  uploadIcon?: string;
  uploadText?: string;
  uploadAnimationStepIndex?: number;
  showFields?: boolean;
}

export interface IFormError {
  uuid: string;
  error_source: FormErrorSource;
  error_category: FormErrorCategory;
  resolution_message: string;
  createdAt: string;
  meta?: IFormErrorMeta;
  editBody?: any;
  User?: IUserAttributes;
}

export interface IFormToSignMeta {
  title: string;
}

export interface IFormToSign {
  uuid: string;
  user_name: string;
  user_relation: string;
  user_uuid: string;
  meta?: IFormToSignMeta;
}

export interface IFormErrorResolveUploadPrimaryIDPayload {
  document_type: DocumentType;
  document_image: any;
  id_number: string;
  issuing_entity: string;
  expiration_date: string;
  first_name?: string;
  last_name?: string;
  middle_name?: string;
}

export interface IFormErrorResolveUploadSecondaryIDPayload {
  document_type: DocumentType;
  document_image: any;
  name: string;
  address_line: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
}

export interface IFormErrorResolveAddAddressPayload {
  name?: string;
  address_line: string;
  address_line_2?: string;
  address_line_3?: string;
  city: string;
  country: string;
  state: string;
  postal_code: string;
  phone_number: string;
  company_place_of_registration?: string;
  company_type_of_business?: string;
}

export interface ISignFormPayload {
  signature: string;
}
