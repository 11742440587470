import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HttpClientModule } from '@angular/common/http';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { IonicModule } from '@ionic/angular';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { ComponentsModule } from '../components';
import { BillingHistoryComponent } from './billing-history.component';
import { InvoiceDetailsPopupComponent } from './invoice-details-popup/invoice-details-popup.component';
import { CreditDetailsPopupComponent } from './credit-details-popup/credit-details-popup.component';
import { DirectivesModule } from '../directives';

@NgModule({
  declarations: [BillingHistoryComponent, InvoiceDetailsPopupComponent, CreditDetailsPopupComponent],
  imports: [
    BrowserModule,
    CommonModule,
    ComponentsModule,
    DirectivesModule,
    FormsModule,
    HttpClientModule,
    IonicModule,
    MatButtonModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    RouterModule,
    VirtualScrollerModule,
  ],
  exports: [BillingHistoryComponent, InvoiceDetailsPopupComponent, CreditDetailsPopupComponent],
})
export class BillingHistoryModule {}
