import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable, Injector, OnDestroy } from '@angular/core';

@Injectable()
export class UsgmHttp extends HttpClient implements OnDestroy {
  constructor(
    handler: HttpHandler,
    protected injector: Injector,
  ) {
    super(handler);
  }

  http_get(url: string): Promise<any> {
    return this.get(url)
      .toPromise()
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        },
      )
      .catch(error => {
        this.handleError(error);
        return Promise.reject(error);
      });
  }

  http_post(url: string, data): Promise<any> {
    return this.post(url, data)
      .toPromise()
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        },
      )
      .catch(error => {
        this.handleError(error);
        return Promise.reject(error);
      });
  }

  http_put(url: string, data): Promise<any> {
    return this.put(url, data)
      .toPromise()
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        },
      )
      .catch(error => {
        this.handleError(error);
        return Promise.reject(error);
      });
  }

  http_delete(url: string, data): Promise<any> {
    return this.delete(url, data)
      .toPromise()
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        },
      )
      .catch(error => {
        this.handleError(error);
        return Promise.reject(error);
      });
  }

  ngOnDestroy() {}

  public handleError(error: any) {}
}
