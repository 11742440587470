import { CdkStep, STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItems } from '../models/constants/menu-items.constant';
import * as Services from '../services';
import * as Constants from '../utils/constants';
import { takeUntil, retry } from 'rxjs/operators';
import { AddressType, FormType } from '@usgm/usgm-payloads-library-front';
import { Subject } from 'rxjs';
import { VerifyAccountService } from './verify-account.service';
import { AppRoutes } from '../models/constants/app-routes.constant';
import { Relation } from '../models/get-notarized.model';

@Component({
  selector: 'usgm-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class VerifyAccountComponent implements OnInit, OnDestroy, AfterViewInit {
  public isLinear = true;
  public imgSrc = Constants.USGM_LOGO;
  public mobileMode = false;
  public userData: any = {};
  public address_type = AddressType.BILLING;
  public addressesData: any = [];
  public menuItems = JSON.parse(JSON.stringify(MenuItems));
  public selectedStep: number;
  public pageLoaded = false;
  public addressStepperController = true;
  public nameStepperController = false;
  public idsStepperController = false;
  public formStepperController = false;
  private unSubscriber: Subject<any> = new Subject();
  public dataLoaded: boolean = Boolean(false);
  public documentRejectError: boolean = Boolean(false);
  public formRejectError: boolean = Boolean(false);
  public notaryPlanSelected: string;
  public secondStepEditable = true;
  public firstStepEditable = true;
  public thirdStepEditable = true;
  public forthStepEditable = true;
  public isCompanyPresent = false;
  public nameList = [];

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected http: Services.UsgmHttp,
    protected apiMapping: Services.ApiMapping,
    protected userDataService: Services.UserDataService,
    protected notificationService: Services.NotificationService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    protected verifyService: Services.VerifyAccountService,
    protected verifyAccountService: VerifyAccountService,
  ) {
    this.menuItems.forEach(menuItem => {
      menuItem.selected = menuItem.name === 'Verify Account';
      menuItem.icon = (menuItem.name === 'Verify Account' ? menuItem.icon + '-selected' : menuItem.icon) + '.svg';
    });
  }

  public ngOnInit() {
    this.getAddedName();
    this.userData = this.userDataService.getDecodedAccessToken(this.userDataService.getAccessToken()) || {};
    this.getUserNotaryPlan();
    this.setDisplayMode();
    this.getAddressData();

    this.verifyAccountService.update.pipe(takeUntil(this.unSubscriber)).subscribe((value: boolean) => {
      if (value === true) {
        this.getUserAccountStatus();
      }
    });
    this.verifyAccountService.verificationCompleted.pipe(takeUntil(this.unSubscriber)).subscribe((value: boolean) => {
      if (value === true) {
        this.secondStepEditable = false;
        this.thirdStepEditable = false;
        this.cdr.detectChanges();
      }
    });
  }

  public nameUpdate($event) {
    this.getAddedName();
  }

  public getAddedName(): void {
    this.verifyService
      .getName(this.apiMapping.getAccountNamesWithOnlyStatus())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(
        apiResponse => {
          if (apiResponse) {
            this.userData = apiResponse.data.accountNames;
            this.isCompanyPresent = this.userData.find(user => [Relation.COMPANY, Relation.TRUST].includes(user.relation.relationType));
            this.nameList = (this.userData || [])
              .map(item => ({
                name: item.user.name,
                description: item.relation.relationType,
                id: item.user.id,
              }))
              .filter(item => [Relation.COMPANY, Relation.TRUST].includes(item.description));
          } else {
            this.isCompanyPresent = false;
          }
          this.cdr.detectChanges();
        },
        error => {
          console.log(error);
        },
      );
  }

  public ngAfterViewInit(): void {
    this.markStepsAsCompleted();
    setTimeout(() => {
      this.dataLoaded = true;
    }, 50000);
  }

  getUserNotaryPlan() {
    this.verifyService
      .getUserAccountStatus(this.apiMapping.getnotaryPlansByUserId())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(response => {
        if (response && response.planData) {
          this.secondStepEditable = false;
          this.thirdStepEditable = false;
          this.notaryPlanSelected = response.planData.plan_type.toLowerCase();
          if (this.notaryPlanSelected === 'notarycam') {
            this.router.navigate([`/${AppRoutes.inbox}`]);
          } else {
            this.getFormStatuses();
          }
        } else {
          this.getUserAccountStatus();
        }
      });
  }

  public getFormStatuses() {
    this.verifyService
      .getFormList(this.apiMapping.getFormsList(this.userDataService.getUserId()))
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(response => {
        console.log(response);
        if (response && response.form1583 && response.form1583.length) {
          for (let x = 0; x < response.form1583.length; x++) {
            if (response.form1583[x].form1583.form_type === FormType.FORM_1583) {
              this.getUserAccountStatus();
              break;
            }
            if (x === response.form1583.length - 1) {
              this.router.navigate([`/${AppRoutes.inbox}`]);
            }
          }
          this.cdr.detectChanges();
        }
      });
  }

  public getUserAccountStatus(): void {
    this.verifyService
      .getUserAccountStatus(this.apiMapping.getUserAccountStatus())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(
        apiResponse => {
          if (apiResponse.data) {
            // if (apiResponse.data.address) {
            //   this.addressStepperController = true;
            // }
            if (apiResponse.data.names) {
              this.nameStepperController = true;
            }
            if (apiResponse.data.ids.count) {
              if (!apiResponse.data.ids.rejected) {
                this.idsStepperController = true;
              } else {
                this.documentRejectError = true;
              }
            }
            if (apiResponse.data.forms.count) {
              if (!apiResponse.data.forms.rejected) {
                this.formStepperController = true;
              } else {
                this.formRejectError = true;
              }
            }
          }
          if (!apiResponse.data.forms.rejected && !apiResponse.data.ids.rejected && this.notaryPlanSelected) {
            this.moveToStep(2);
          } else {
            this.setStepRoute(apiResponse.data);
          }
          this.cdr.detectChanges();
        },
        error => {},
      );
  }

  completedStep(index): void {
    if (index === 0) {
      this.nameStepperController = true;
    } else if (this.isCompanyPresent) {
      this.idsStepperController = true;
      if (index === 1) {
        this.addressStepperController = true;
      } else if (index === 2) {
        this.idsStepperController = true;
      } else if (index === 3) {
        this.formStepperController = true;
      }
    } else {
      if (index === 1) {
        this.idsStepperController = true;
      } else if (index === 2) {
        this.formStepperController = true;
      }
    }

    this.cdr.detectChanges();
  }

  parentFun() {
    this.getAddressData();
  }

  public getAddressData(): void {
    this.verifyService
      .getAddress(this.apiMapping.verifyGetAddress(this.userDataService.getUserId(), this.address_type))
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(
        apiResponse => {
          this.addressesData = apiResponse.addresses;
          this.cdr.detectChanges();
        },
        error => {},
      );
  }

  public complete() {
    this.stepper.selected.completed = true;
    this.stepper.selected.editable = false;
    this.stepper.next();
  }

  public next() {
    this.stepper.next();
  }

  public setDisplayMode() {
    this.mobileMode = this.userDataService.isMobileMode;
  }

  public logoutUser() {
    this.userDataService.logoutUser();
  }

  public setStepRoute(accountStatuses) {
    const currentStepNumber = this.activatedRoute.snapshot.queryParams['step'];
    if (currentStepNumber) {
      const step = Number(currentStepNumber) >= 1 && Number(currentStepNumber) <= 3 ? Number(currentStepNumber) - 1 : 0;
      if (step === 3) {
        if (this.idsStepperController) {
          this.moveToStep(2);
        } else if (this.nameStepperController) {
          this.moveToStep(1);
          this.moveToStep(0);
        }
      } else if (step === 2) {
        if (this.nameStepperController) {
          this.moveToStep(1);
        } else {
          this.moveToStep(0);
        }
      } else {
        this.moveToStep(0);
      }
    } else {
      if (!accountStatuses.names) {
        this.moveToStep(0);
      } else if (!accountStatuses.ids.count) {
        this.moveToStep(0);
      } else if (accountStatuses.ids.rejected) {
        this.moveToStep(1);
      } else if (!accountStatuses.forms.count || accountStatuses.forms.rejected) {
        this.moveToStep(2);
      } else {
        this.moveToStep(2);
      }
    }
  }

  moveToStep(step) {
    this.selectedStep = step;
    this.verifyService.currentStep$.next(this.selectedStep);
    this.router.navigate([`/verify-account`], { queryParams: { step: step + 1 } });
    this.userDataService.setOnboardingStatus(step + 1);
    this.pageLoaded = true;
    this.cdr.detectChanges();
  }

  selectionChange(event: StepperSelectionEvent): void {
    console.log(event);
    this.selectedStep = event.selectedIndex;
    // if (this.documentRejectError === true) {
    //   this.markStepRejected(1);
    // }
    if (this.formRejectError === true) {
      this.markStepRejected(2);
    }
    this.cdr.detectChanges();
    this.router.navigate([`/verify-account`], { queryParams: { step: event.selectedIndex + 1 } });
  }

  /**
   * Mark previous steps as completed
   */
  markStepsAsCompleted(): void {
    if (this.stepper && this.stepper.steps.length > 0) {
      this.stepper.steps.map((item: CdkStep, index: number) => {
        if (index < this.selectedStep) {
          item.completed = true;
        }
      });
    }
    this.cdr.detectChanges();
  }

  markStepRejected(stepIndex: number) {
    if (this.stepper && this.stepper.steps.length > 0) {
      this.stepper.steps.map((item: CdkStep, index: number) => {
        if (index === stepIndex) {
          item.completed = false;
        }
      });
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }
}
