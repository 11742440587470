import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as Services from '../../services';
import { Animations } from '../../animations/element.animations';
import { AccountStatus } from '@usgm/usgm-payloads-library-front';

@Component({
  selector: 'usgm-discard-details-popup',
  templateUrl: './discard-details-popup.component.html',
  styleUrls: ['./discard-details-popup.component.scss'],
  animations: Animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscardDetailsPopupComponent implements OnInit, OnDestroy {
  public loading = false;
  public showZoomedImage = false;
  private _item;

  get item(): any {
    return this._item;
  }

  @Input() set item(value: any) {
    this._item = value || {};
    this.loading = false;
  }

  @Output() closeNav = new EventEmitter();
  @Output() restoreDiscardClicked = new EventEmitter();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected userDataService: Services.UserDataService,
  ) {}

  ngOnInit() {}

  closeSideNav() {
    this.closeNav.emit({});
  }

  onRestoreItemClick() {
    this.loading = true;
    this.item.selected = true;
    this.restoreDiscardClicked.emit(this.item);
  }

  allActionsDisabled() {
    return this.userDataService.getAccountStatus() === AccountStatus.SUSPENDED;
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
