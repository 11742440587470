import { trigger, state, style, animate, transition } from '@angular/animations';

export const Animations = [
  trigger('showHide', [
    state('1', style({ opacity: '1' })),
    state('0', style({ opacity: '0', height: '10px' })),
    transition('1 => 0', animate('200ms linear')),
    transition('0 => 1', animate('200ms linear')),
  ]),
  trigger('expandCollapse', [
    state('1', style({ height: '*' })),
    state('0', style({ height: '0' })),
    transition('1 => 0', animate('300ms ease-out')),
    transition('0 => 1', animate('300ms ease-out')),
  ]),
  trigger('areaToggle', [
    state('1', style({ transform: 'scale(1)' })),
    state('0', style({ transform: 'scale(0)' })),
    transition('1 => 0', animate('200ms ease-in-out')),
    transition('0 => 1', animate('200ms ease-in-out')),
  ]),
  trigger('slideInOut', [
    state('1', style({ height: '*' })),
    state('0', style({ height: '0px' })),
    transition('1 => 0', animate('400ms ease-in-out')),
    transition('0 => 1', animate('400ms ease-in-out')),
  ]),
];
