import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppRoutes } from '../models/constants/app-routes.constant';
import { UserDataService } from './user-data.service';

@Injectable()
export class RouteVerifyAccountDoneService implements CanActivate {
  constructor(
    private userDataService: UserDataService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(resolve => {
      resolve(this.getUserNotaryPlan());
    });
  }

  getUserNotaryPlan() {
    if (this.userDataService.notaryPlan) {
      this.router.navigate([AppRoutes.inbox]);
      return false;
    }
    return true;
  }
}
