import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpHandler, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { Facebook } from '@ionic-native/facebook/ngx';
// import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { NotificationService } from './notification.service';
import { ApiMapping } from './api-mapping.service';
import { CustomRequestInterceptor } from './custom-request-options.service';
import { PushNotificationsService } from './push-notifications.service';
import { RegistrationDataService } from './registration-data.service';
import { RouteGuardService } from './route-guard.service';
import { SignUpRouteGuardService } from './signup-route-guard.service';
import { UserDataService } from './user-data.service';
import { UsgmHttp } from './usgm-http.service';
import { AddressService } from './address.service';
import { CookieService } from './cookie.service';
import { SubscriptionService } from './subscription.service';
import { VerifyAccountService } from './verify-account.service';
import { RouteVerifyAccountDoneService } from './route-verify-account-done.service';
import { ScriptService } from './scripts/script.service';
import { Form1583Service } from './form1583.service';

export function usgmHttpFactory(handler, injectorService) {
  return new UsgmHttp(handler, injectorService);
}

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, MatSnackBarModule],
  providers: [
    AddressService,
    ApiMapping,
    CookieService,
    Form1583Service,
    // Facebook,
    // GooglePlus,
    NotificationService,
    PushNotificationsService,
    RegistrationDataService,
    SubscriptionService,
    RouteGuardService,
    SignUpRouteGuardService,
    RouteVerifyAccountDoneService,
    UserDataService,
    UsgmHttp,
    VerifyAccountService,
    {
      provide: UsgmHttp,
      deps: [HttpHandler, Injector],
      useFactory: usgmHttpFactory,
    },
    [{ provide: HTTP_INTERCEPTORS, useClass: CustomRequestInterceptor, multi: true }],
    ScriptService,
  ],
  exports: [],
})
export class ServicesModule {}
