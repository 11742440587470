// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 600px;
  height: 100%;
  padding: 10px;
}
:host .folder-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  margin: 0;
}
:host .action-button[first-child] {
  text-align: end;
}
:host .action-button {
  padding: 0px;
  margin: 0px;
  min-width: 40px;
}
:host .action-col {
  width: 150px;
}
:host .manage-folder-container {
  width: 500px;
}
@media (max-width: 767px) {
  :host .manage-folder-container {
    width: calc(100vw - 20px) !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/manage-folder/manage-folder.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;AACF;AAEE;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,SAAA;AAAJ;AAGE;EACE,eAAA;AADJ;AAIE;EACE,YAAA;EACA,WAAA;EACA,eAAA;AAFJ;AAKE;EACE,YAAA;AAHJ;AAME;EACE,YAAA;AAJJ;AAOE;EACE;IACE,oCAAA;EALJ;AACF","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 600px;\n  height: 100%;\n  padding: 10px;\n\n\n  .folder-name {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    max-width: 250px;\n    margin: 0;\n  }\n\n  .action-button[first-child] {\n    text-align: end;\n  }\n\n  .action-button {\n    padding: 0px;\n    margin: 0px;\n    min-width: 40px;\n  }\n\n  .action-col {\n    width: 150px;\n  }\n\n  .manage-folder-container {\n    width: 500px;\n  }\n\n  @media (max-width: 767px) {\n    .manage-folder-container {\n      width: calc(100vw - 20px) !important\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
