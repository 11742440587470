import { IAddressAttributes } from '@usgm/usgm-payloads-library-front';
import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DialogComponent } from '../../components/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as SharedHelpers from '../../utils/helpers';
import { AddressDataModel } from '../../models/address-model';
import { AddressService, ApiMapping, UserDataService, NotificationService, UsgmHttp } from '../../services';
import { Country, State, City, ICountry } from 'country-state-city';
import { DialogWithInputComponent } from '../../components/dialog-with-input';

export const AddressType = [
  { value: 'SHIPPING', viewValue: 'Shipping' },
  { value: 'CHECK_DEPOSIT', viewValue: 'Check Deposit' },
];
import { militaryBases } from '../../utils/constants';
import { SuggestAddressDialogComponent } from '../../inbox/suggest-address-dialog/suggest-address-dialog.component';
import { AddressValidationResult } from '../../models/address-validation-result';

@Component({
  selector: 'usgm-address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressBookComponent implements OnInit, OnDestroy {
  public userAddresses: any;
  public selectedAddress: any;
  public countries: any;
  public states: any;
  public cities: any;
  public savingAddress = false;
  public updatingAddress = false;
  public fetchingAddresses = false;
  public makingDefaultAddress = false;
  public addressCreated = false;
  public loading = false;
  public loadingStates = false;
  public deletingAddress = false;
  public wasDefaultAddress = false;
  public addressIdToSetDefault = 0;
  public addAddressForm: FormGroup;
  public addressType = AddressType;
  public unsubscribeService: Subject<any> = new Subject();

  @Output() closeNav = new EventEmitter();
  @ViewChild('addressBookStepper', { static: true }) addressBookStepper: MatStepper;

  constructor(
    protected formBuilder: FormBuilder,
    protected cdr: ChangeDetectorRef,
    private addressService: AddressService,
    private userDataService: UserDataService,
    protected apiMapping: ApiMapping,
    protected notificationService: NotificationService,
    protected http: UsgmHttp,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.initForm();
    this.getCountries();
    this.getAddresses();
  }

  initForm() {
    this.addAddressForm = this.formBuilder.group({
      address_type: [null, Validators.required],
      name: [null, Validators.required],
      address_line: [null, Validators.required],
      address_line_2: [null, []],
      address_line_3: [null, []],
      city: [null, Validators.required],
      country: [null, Validators.required],
      state: [null, Validators.required],
      postal_code: [null, [Validators.required]],
      phone_number: ['', [Validators.required, Validators.pattern(SharedHelpers.phoneNumberPattern)]],
      is_default: [false, []],
      user_id: [this.userDataService.getUserId(), []],
    });
  }

  public getAddresses() {
    this.fetchingAddresses = true;
    this.http
      .get(this.apiMapping.getUserAddressesByType(this.userDataService.getUserId(), 'CHECK_DEPOSIT,SHIPPING'))
      .pipe(takeUntil(this.unsubscribeService))
      .subscribe(
        (res: AddressDataModel) => {
          if (!!res['addresses'] && res['addresses'].length > 0) {
            this.handleUserAddresses(res.addresses);
            this.userDataService.setUserAllAddresses(res);
          } else {
            this.userAddresses = [];
            this.fetchingAddresses = false;
            SharedHelpers.detectChanges(this.cdr);
          }
        },
        (error: any) => {
          this.fetchingAddresses = false;
          SharedHelpers.detectChanges(this.cdr);
        },
      );
  }

  public handleUserAddresses(addresses: any[]) {
    this.userAddresses = addresses;
    this.userDataService.sortAddresses(this.userAddresses);
    this.fetchingAddresses = false;
    SharedHelpers.detectChanges(this.cdr);
  }

  public addAddress() {
    this.selectedAddress = null;
    this.addressBookStepper.selectedIndex = 1;
    this.getCountries();
    this.getStatesByCountryId(this.addAddressForm.controls['country'].value.Id);
  }

  public async submitAddress() {
    this.validateAllFormFields(this.addAddressForm);
    if (!this.addAddressForm.valid) {
      return;
    }
    const postData = JSON.parse(JSON.stringify(this.addAddressForm.value));
    postData.country = postData.country.Id;
    try {
      const validationResults = await this.addressService.validateAddress(postData);
      if (validationResults.suggestedAddresses.length || !validationResults.addressComplete) {
        this.openSuggestAddressDialog(validationResults);
        return;
      }
    } catch (err) {}
    this.savingAddress = true;
    postData.is_validated = true;
    this.http.post(this.apiMapping.createUserAddress(this.userDataService.getUserId()), postData).subscribe(
      response => {
        this.resetAddressForm();
        this.savingAddress = false;
        this.notificationService.showSuccess('Address created!');
        this.userDataService.clearUserAllAddresses();
        this.getAddresses();
        this.addressBookStepper.selectedIndex = 0;
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.savingAddress = false;
        this.notificationService.showError('Unable to process request. Please try again');
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  public async updateAddress(address) {
    this.validateAllFormFields(this.addAddressForm);
    if (!this.addAddressForm.valid) {
      return;
    }
    const postData = JSON.parse(JSON.stringify(this.addAddressForm.value));
    postData.country = postData.country.Id;
    postData.id = address.id;
    try {
      const validationResults = await this.addressService.validateAddress(postData);
      if (validationResults.suggestedAddresses.length || !validationResults.addressComplete) {
        this.openSuggestAddressDialog(validationResults);
        return;
      }
    } catch (err) {}
    this.updatingAddress = true;
    this.http.put(this.apiMapping.updateUserAddress(this.userDataService.getUserId(), address.id), postData).subscribe(
      response => {
        this.resetAddressForm();
        this.updatingAddress = false;
        this.notificationService.showSuccess('Address updated!');
        this.userDataService.clearUserAllAddresses();
        this.getAddresses();
        this.addressBookStepper.selectedIndex = 0;
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.updatingAddress = false;
        this.notificationService.showError('Unable to process request. Please try again');
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  setDefaultAddress(addressId: number) {
    this.makingDefaultAddress = true;
    this.addressIdToSetDefault = addressId;
    this.http.put(this.apiMapping.setDefaultUserAddress(this.userDataService.getUserId(), addressId), {}).subscribe(
      response => {
        this.userAddresses.forEach(address => {
          address.is_default = address.id === addressId;
        });
        this.addressIdToSetDefault = 0;
        this.makingDefaultAddress = false;
        this.userDataService.sortAddresses(this.userAddresses);
        this.userDataService.clearUserAllAddresses();
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.makingDefaultAddress = false;
        this.notificationService.showError('Unable to process request. Please try again');
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  onPopupClose(saved: boolean, wantEdit: boolean, address: IAddressAttributes = null): void {
    if (wantEdit) {
      this.addAddressForm.patchValue({
        ...address,
        country: { Id: address.country },
      });
      this.cdr.detectChanges();
      return;
    }
    if (!saved) {
      return;
    }
    this.resetAddressForm();
    this.updatingAddress = false;
    this.notificationService.showSuccess('Address updated!');
    this.userDataService.clearUserAllAddresses();
    this.getAddresses();
    this.addressBookStepper.selectedIndex = 0;
    SharedHelpers.detectChanges(this.cdr);
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  public discardSelectedItem(address) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      id: 1,
      title: 'Discard selected address?',
      message: 'Please confirm',
      cancelText: 'No',
      confirmText: 'Yes',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAddress(address);
      }
    });
  }

  public deleteAddress(address) {
    this.deletingAddress = true;
    this.wasDefaultAddress = address.is_default;
    this.http.delete(this.apiMapping.deleteUserAddress(address.id)).subscribe(
      response => {
        this.deletingAddress = false;
        if (this.wasDefaultAddress && this.userAddresses[1]) {
          this.setDefaultAddress(this.userAddresses[1].id);
          this.wasDefaultAddress = false;
        }
        this.userDataService.clearUserAllAddresses();
        this.getAddresses();
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.deletingAddress = false;
        const message = error?.error?.message || 'Error while deleting address. Please try again.';
        this.notificationService.showError(message);
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  public updateSelectedAddress(address) {
    this.selectedAddress = address;
    this.getStatesByCountryId(address.country);
    const possibleStateCode = this.states.find(state => state.isoCode === address.state || state?.name?.toLowerCase() === address?.state?.toLowerCase());
    if (possibleStateCode) {
      address.state = possibleStateCode.isoCode;
    }
    this.cities = City.getCitiesOfState(address.country, address.state) || [];
    const possibleCity = this.cities.find(city => city?.name?.toLowerCase() === address?.city?.toLowerCase());
    if (possibleCity) {
      address.city = possibleCity.name;
    }
    SharedHelpers.detectChanges(this.cdr);
    if (!(this.states || []).find(state => state.isoCode === address.state)) {
      this.states.push({
        isoCode: address.state,
        name: address.state,
      });
    }
    if (!(this.cities || []).find(city => city.name === address.city)) {
      this.cities.push({
        name: address.city,
      });
    }
    this.addressBookStepper.selectedIndex = 1;
    SharedHelpers.detectChanges(this.cdr);
    this.patchFormValues();
  }

  public getCountries() {
    this.countries = Country.getAllCountries().map((country: ICountry) => ({ Id: country.isoCode, Name: country.name }));
    this.addAddressForm.patchValue({ country: { Id: 'US', Name: 'United States' } });
  }

  public getStatesByCountryId(id: string) {
    this.states = State.getStatesOfCountry(id) || [];
    if (id === 'US' || id === 'USA') {
      this.states = [...this.states, ...militaryBases];
    }
  }

  public stateSelected(country: { Id: string; Name: string }, stateCode: string) {
    if (stateCode === 'add_new_option') {
      const dialogRefToAddState = this.dialog.open(DialogWithInputComponent, {
        data: {
          title: 'Add a new State',
          cancelText: 'Cancel',
          confirmText: 'Add',
          addEvent: 'ADD_NEW_STATE',
        },
      });

      dialogRefToAddState?.afterClosed().subscribe(result => {
        if (result.event === 'ADD_NEW_STATE') {
          this.states.push({
            isoCode: result.data,
            name: result.data,
          });
          this.addAddressForm.patchValue({
            state: result.data,
          });
          this.addAddressForm.patchValue({
            city: '',
          });
          this.cities = [];
          SharedHelpers.detectChanges(this.cdr);
        }
      });
    } else {
      this.cities = City.getCitiesOfState(country.Id, stateCode);
    }
  }

  public citySelected() {
    const selectedCity = this.addAddressForm.controls['city'].value;
    if (selectedCity && selectedCity === 'add_new_option') {
      const dialogRefToAddCity = this.dialog.open(DialogWithInputComponent, {
        data: {
          title: 'Add a new city',
          cancelText: 'Cancel',
          confirmText: 'Add',
          addEvent: 'ADD_NEW_CITY',
        },
      });

      dialogRefToAddCity?.afterClosed().subscribe(result => {
        if (result.event === 'ADD_NEW_CITY') {
          this.cities.push({
            isoCode: result.data,
            name: result.data,
          });
          this.addAddressForm.patchValue({
            city: result.data,
          });
          SharedHelpers.detectChanges(this.cdr);
        }
      });
    }
  }

  public getStateFromName(findName: string) {
    for (const state of this.states) {
      if (state.Name === findName) {
        return state;
      }
    }
  }

  public getCountryFromName(findId: string) {
    for (const country of this.countries) {
      if (country.Id === findId) {
        return country;
      }
    }
  }

  public countrySelected(event): void {
    this.states = [];
    this.cities = [];
    this.addAddressForm.patchValue({ state: '' });
    const selectedCountry = this.addAddressForm.get('country').value;
    if (selectedCountry) {
      const Id = selectedCountry.Id;
      if (Id) {
        this.getStatesByCountryId(Id);
      }
    }
  }

  resetAddressForm() {
    this.addAddressForm.reset();
    this.addAddressForm.patchValue({ is_default: false });
    this.addressBookStepper.selectedIndex = 0;
  }

  public closeSideNav() {
    this.closeNav.emit({});
  }

  public patchFormValues() {
    if (this.selectedAddress) {
      this.addAddressForm.get('name').setValue(this.selectedAddress.name);
      this.addAddressForm.get('address_line').setValue(this.selectedAddress.address_line);
      this.addAddressForm.get('address_line_2').setValue(this.selectedAddress.address_line_2);
      this.addAddressForm.get('address_line_3').setValue(this.selectedAddress.address_line_3);
      this.addAddressForm.get('postal_code').setValue(this.selectedAddress.postal_code);
      this.addAddressForm.get('phone_number').setValue(this.selectedAddress.phone_number);
      this.addAddressForm.get('city').setValue(this.selectedAddress.city);
      this.addAddressForm.get('is_default').setValue(this.selectedAddress.is_default);
      this.addAddressForm.get('state').setValue(this.selectedAddress.state);
      this.addAddressForm.get('country').setValue(this.getCountryFromName(this.selectedAddress.country));
      this.addAddressForm.get('address_type').setValue(this.selectedAddress.address_type);
    }
  }

  public openSuggestAddressDialog(validationResult: AddressValidationResult) {
    const dialogRef = this.dialog.open(SuggestAddressDialogComponent, {
      width: '600px',
      data: { validationResult },
    });
    dialogRef.afterClosed().subscribe(({ addressSaved, wantEdit, address } = { addressSaved: false, wantEdit: false }) => {
      this.onPopupClose(addressSaved, wantEdit, address);
    });
  }

  public isFieldInvalid(field: string) {
    return !this.addAddressForm.get(field).valid && this.addAddressForm.get(field).touched;
  }

  public displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldInvalid(field),
      'has-feedback': this.isFieldInvalid(field),
    };
  }

  public compareByID(o1: any, o2: any) {
    return (o1 || {}).Id === (o2 || {}).Id;
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
