import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { FormType, Relation } from '@usgm/usgm-payloads-library-front';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as Services from '../../../services';
import * as SharedHelpers from '../../../utils/helpers';
import { UsgmHttp } from '../../../services';
import { CaptchaBaseComponent } from '../../../captcha-base-component/captcha-base-component.component';
import { MatDialog } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'usgm-upload-notarized-form-mobile',
  templateUrl: './upload-notarized-form.component.html',
  styleUrls: ['./upload-notarized-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsUploadNotarizedFormComponent extends CaptchaBaseComponent implements OnInit {
  public loading = false;
  public userNames = [];
  public firstLoad = true;
  public fileSize = 10 * 1024 * 1024;
  public allowedTypes = ['application/pdf'];
  @Input() selectedUser;
  @Input() selectedNotaryPlan;
  private unSubscriber: Subject<any> = new Subject();
  @Output() goToNextPage = new EventEmitter();
  formList = [];
  constructor(
    public override notificationService: Services.NotificationService,
    protected verifyService: Services.VerifyAccountService,
    protected userDataService: Services.UserDataService,
    protected apiMapping: Services.ApiMapping,
    protected http: UsgmHttp,
    private cdRef: ChangeDetectorRef,
    public override dialog: MatDialog,
    public override recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
  }

  public override ngOnInit() {
    this.loadView();
  }

  downloadAllForms() {}

  onFileDropped(fileEvent: Array<any>, userId) {
    this.validateFile(fileEvent, userId);
  }
  validateFile(fileEvent: File[], userId) {
    if (userId) {
      const userName = this.userNames.find(item => item.id === userId);
      userName.isSubmitted = false;
    }
    const files = fileEvent;
    const file = fileEvent[0];
    if (file) {
      if (!this.allowedTypes.includes(file.type)) {
        this.notificationService.showWarning('Allowed types is pdf only');
        return false;
      } else if (file.size > this.fileSize) {
        this.notificationService.showWarning(`upload file will be less than ${this.fileSize / 1024 / 1024} MB.`);
        return false;
      }
      this.readURL(file, userId);
    }
  }
  readURL(file, userId) {
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        file.data = e.target.result;
        for (const user of this.userNames) {
          if (user.id === userId) {
            user.userFile = file;
            break;
          }
        }
        this.cdRef.detectChanges();
      };
      reader.readAsDataURL(file);
    }
  }

  uploadFormFuntion(userId) {
    const fileData = this.userNames.find(item => item.id === userId);
    if (fileData && fileData.userFile) {
      this.loading = true;
      const formData = new FormData();
      formData.append('form', fileData.userFile);
      formData.append('form_type', FormType.FORM_1583_NOTARIZED);
      this.verifyService
        .uploadPDF(this.apiMapping.uploadPDF(userId), formData)
        .pipe(takeUntil(this.unSubscriber))
        .subscribe(
          apiResponse => {
            if (apiResponse) {
              this.loading = false;
              this.cdRef.detectChanges();
              this.goToNextPage.emit({ page: 'PROCESS_COMPLETED' });
            }
          },
          error => {
            this.loading = false;
            this.cdRef.detectChanges();
            this.notificationService.showError('Error occurred while uploading file. Please try again');
          },
        );
    } else {
      this.notificationService.showWarning('Please upload a pdf file before proceed');
    }
  }

  downloadUserForms(userId) {
    this.downloadIDsFirstCall(this.downloadFormsFromServer.bind(this), userId);
  }

  downloadFormsFromServer(token, captchaVersion, userId) {
    this.http.get(this.apiMapping.getDownloadPdfForm(userId, token, captchaVersion), { responseType: 'arraybuffer' }).subscribe(
      result => {
        SharedHelpers.UnZip(result);
        this.notificationService.showSuccess('File downloaded.');
      },
      error => {
        if (error.status === 422) {
          this.verifyCaptcha(this.downloadUserForms.bind(this), userId);
        } else {
          this.notificationService.showError('Unable to download file. Please try again.');
        }
      },
    );
  }

  async loadView() {
    try {
      const forms = await this.verifyService.getFormListPromise(this.apiMapping.getFormsList(this.userDataService.getUserId()));
      this.formList = forms.form1583 || [];
      const apiResponse = await this.verifyService.getNamePromise(this.apiMapping.getAccountNamesWithOnlyStatus());
      if (apiResponse) {
        let userNameList = [];

        for (const user of apiResponse.data.accountNames) {
          if (user.is_from_account_verification && user.relation.relationType === Relation.MINOR_CHILD) {
            userNameList = userNameList.map(item => {
              if (item.relation === Relation.PRIMARY) {
                item.name = item.name + `, ${user.user.name} (${user.relation.relationType})`;
              }
              return item;
            });
          } else {
            const userForm = this.formList.find(item => item.user_id === user.user.uuid);
            if (userForm && userForm.form1583) {
              userNameList.push({
                name: user.user.name + ` (${user.relation.relationType})`,
                id: user.user.uuid,
                relation: user.relation.relationType,
              });
            }
          }
        }
        this.userNames = userNameList.filter(user => user.id === this.selectedUser);
      }
      this.firstLoad = false;
      this.cdRef.detectChanges();
    } catch (error) {
      this.firstLoad = false;
      console.log(error);
    }
  }
}
