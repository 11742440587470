import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Services from '../../services';

@Component({
  selector: 'usgm-select-region',
  templateUrl: './select-region.component.html',
  styleUrls: ['./select-region.component.scss'],
})
export class SelectRegionComponent implements OnInit {
  public loading = false;
  public regionData: any = {};
  public dropdownOpen: Boolean = false;
  public selectedRegion: any = {};

  constructor(
    private router: Router,
    private http: Services.UsgmHttp,
    private apiMapping: Services.ApiMapping,
    private registerDataService: Services.RegistrationDataService,
    private notificationService: Services.NotificationService,
  ) {}

  public ngOnInit() {
    if (this.registerDataService.getRegions()) {
      this.regionData = this.registerDataService.getRegions();
      this.setSelectedRegionFromPreference();
    } else {
      this.getRegions();
    }
  }

  public regionClicked(region) {
    this.dropdownOpen = false;
    this.selectedRegion = region;
    this.updateSelectedRegion();
  }

  public setSelectedRegionFromPreference() {
    const selectedRegionId = this.registerDataService.getUserPlanSelections()['region'];
    for (let i = 0; i < this.regionData.selectAddress.length; i++) {
      if (selectedRegionId === this.regionData.selectAddress[i].id) {
        this.selectedRegion = this.regionData.selectAddress[i];
        break;
      }
    }
  }

  public getRegions() {
    this.loading = true;
    this.http
      .http_get(this.apiMapping.selectAddress())
      .then(
        (data: any) => {
          this.regionData = data;
          this.selectedRegion = this.regionData.selectAddress[0];
          this.registerDataService.setRegions(this.regionData);
          this.updateSelectedRegion();
          this.loading = false;
        },
        (error: any) => {
          this.loading = false;
          this.notificationService.showError('Unable to fetch regions. Please try again.');
        },
      )
      .catch((exception: any) => {
        this.loading = false;
        this.notificationService.showError('Unable to fetch regions. Please try again.');
        console.log('error fetching data', exception);
      });
  }

  public updateSelectedRegion() {
    this.registerDataService.updateUserPlanSelections('region', this.selectedRegion['id']);
    localStorage.setItem('selectedRegionId', this.selectedRegion['id']);
  }

  public regionSelected() {
    this.router.navigate(['/signup']);
    this.updateSelectedRegion();
  }
}
