// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host virtual-scroller {
  height: 600px;
}
:host .selection-control {
  cursor: pointer;
}
:host tbody {
  border-top: 1px solid #e0e0e0;
}
:host td {
  position: relative;
}
:host div.row-mark {
  position: absolute !important;
  right: 0px;
  top: 0px;
}
:host .issue-indicator {
  position: absolute;
  top: calc(50% - 10px);
  left: 10px;
}
:host .table-image {
  padding: 10px 20px;
  width: 100%;
}
:host .margin-left-0 {
  margin-left: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/scans-list/scans-list-table/scans-list-table.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,6BAAA;AADJ;AAIE;EACE,kBAAA;AAFJ;AAKE;EACE,6BAAA;EACA,UAAA;EACA,QAAA;AAHJ;AAME;EACE,kBAAA;EACA,qBAAA;EACA,UAAA;AAJJ;AAOE;EACE,kBAAA;EACA,WAAA;AALJ;AAQE;EACE,2BAAA;AANJ","sourcesContent":[":host {\n  display: block;\n\n  virtual-scroller {\n    height: 600px;\n  }\n\n  .selection-control {\n    cursor: pointer;\n  }\n\n  tbody {\n    border-top: 1px solid #e0e0e0;\n  }\n\n  td {\n    position: relative;\n  }\n\n  div.row-mark {\n    position: absolute !important;\n    right: 0px;\n    top: 0px;\n  }\n\n  .issue-indicator {\n    position: absolute;\n    top: calc(50% - 10px);\n    left: 10px;\n  }\n\n  .table-image {\n    padding: 10px 20px;\n    width: 100%;\n  }\n\n  .margin-left-0 {\n    margin-left: 0px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
