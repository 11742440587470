import { Component, Inject, ChangeDetectionStrategy, Injector, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserDataService } from '../../services';
import { AppRoutes } from '../../models/constants/app-routes.constant';
import { Router } from '@angular/router';

@Component({
  selector: 'usgm-account-suspended-dialog',
  templateUrl: './account-suspended-dialog.component.html',
  styleUrls: ['./account-suspended-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSuspendedDialogComponent implements OnInit {
  public suggestPayInvoices = false;
  public CONTACT_SUPPORT_URL = 'https://www.usglobalmail.com/contact-us/';

  constructor(
    protected cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AccountSuspendedDialogComponent>,
    protected userDataService: UserDataService,
    protected injector: Injector,
    private router: Router,
  ) {}

  ngOnInit() {
    this.suggestPayInvoices = !this.data.revoked;
  }

  navigateToInvoices() {
    this.router.navigate([AppRoutes.billingHistory]);
    this.dialogRef.close();
  }
}
