import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ENCRYPTED_HEADER, GLOBAL_FLAG_REGEX, PLUS_REGEX, PLUS, REPLACE_VALUE, REGEX } from '../utils/constants';
import { UserDataService } from './user-data.service';

const EXCLUDE_ROUTES = ['/environment', '/v1/download/form', '/v1/download/document', '/v1/download/scan', '/v1/view/scan'];

@Injectable()
export class CustomRequestInterceptor implements HttpInterceptor {
  constructor(private userDataService: UserDataService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authHeaders = {
      'x-access-token': this.userDataService.getAccessToken(),
      'x-refresh-token': this.userDataService.getRefreshToken(),
    };
    const URL = req.url;
    let nextReq;
    if (
      environment['encryptionKey'] &&
      !EXCLUDE_ROUTES.find(route => req.url.indexOf(route) >= 0) &&
      !(req.body instanceof FormData) &&
      this.checkEncryptionServicesUsingURL(URL)
    ) {
      const encryptedHeaders = crypto.AES.encrypt(JSON.stringify(authHeaders), environment['encryptionKey'])
        .toString()
        .replace(new RegExp(PLUS_REGEX, GLOBAL_FLAG_REGEX), REPLACE_VALUE);
      nextReq = req.clone({
        headers: req.headers
          .set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
          .set('If-Modified-Since', '0')
          .set('Access-Control-Allow-Origin', '*')
          .set('Content-Type', 'text/plain')
          .set('Accept', 'text/plain')
          .set(ENCRYPTED_HEADER, encryptedHeaders),
        responseType: 'text',
        body: crypto.AES.encrypt(JSON.stringify(req.body), environment['encryptionKey']).toString().replace(new RegExp(PLUS_REGEX, GLOBAL_FLAG_REGEX), REPLACE_VALUE),
      });
    } else if (environment['encryptionKey'] && req.body instanceof FormData) {
      const encryptedHeaders = crypto.AES.encrypt(JSON.stringify(authHeaders), environment['encryptionKey'])
        .toString()
        .replace(new RegExp(PLUS_REGEX, GLOBAL_FLAG_REGEX), REPLACE_VALUE);
      nextReq = req.clone({
        headers: req.headers
          .set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
          .set('If-Modified-Since', '0')
          .set('Access-Control-Allow-Origin', '*')
          .set('Accept', 'text/plain')
          .set(ENCRYPTED_HEADER, encryptedHeaders),
        responseType: 'text',
      });
    } else {
      nextReq = req.clone({
        headers: req.headers
          .set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
          .set('If-Modified-Since', '0')
          .set('Access-Control-Allow-Origin', '*')
          .set('x-access-token', this.userDataService.getAccessToken())
          .set('x-refresh-token', this.userDataService.getRefreshToken()),
      });
    }
    return next.handle(nextReq).pipe(
      map(resp => {
        if (
          resp instanceof HttpResponse &&
          resp.body !== '' &&
          typeof resp.body !== 'object' &&
          !EXCLUDE_ROUTES.find(route => req.url.indexOf(route) >= 0) &&
          environment['encryptionKey'] &&
          this.checkEncryptionServicesUsingURL(URL)
        ) {
          let decryptedBody = crypto.AES.decrypt(resp.body.replace(REGEX, PLUS), environment['encryptionKey']).toString(crypto.enc.Utf8);
          decryptedBody = JSON.parse(decryptedBody);
          return resp.clone({ body: decryptedBody }); // do not decrypt for get environment api
        }
        return resp;
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 503 && err.error.maintenance === true) {
            this.userDataService.setMaintenanceMode(true);
            return;
          }
          if (err.status === 403 && !this.userDataService.isRefreshingSession) {
            this.userDataService.refreshSession();
          }
          if (
            typeof err.error !== 'object' &&
            !EXCLUDE_ROUTES.find(route => req.url.indexOf(route) >= 0) &&
            environment['encryptionKey'] &&
            this.checkEncryptionServicesUsingURL(URL)
          ) {
            let decryptedBody = crypto.AES.decrypt(err.error.replace(REGEX, PLUS), environment['encryptionKey']).toString(crypto.enc.Utf8);
            decryptedBody = JSON.parse(decryptedBody);
            return throwError({
              error: decryptedBody,
              status: err.status,
            });
          }
        }
        return throwError(err);
      }),
    );
  }

  checkEncryptionServicesUsingURL(URL: string): boolean {
    return (
      URL.indexOf('usgm-accounts') > 0 ||
      URL.indexOf('usgm-payments') > 0 ||
      URL.indexOf('usgm-mails') > 0 ||
      URL.indexOf('usgm-notifications') > 0 ||
      URL.indexOf('reset-password-polling') > 0
    );
  }
}
