// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 480px;
}
:host .code-input-container {
  width: 270px;
  margin: 0 auto;
}
:host .card-box {
  overflow: hidden;
}
:host .disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
:host .resend-link.disabled {
  pointer-events: none;
  opacity: 0.6;
}
:host .buttons-container {
  padding: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/enable-mfa-popup/enable-mfa-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;AACF;AACE;EACE,YAAA;EACA,cAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;AAGE;EACE,YAAA;EACA,mBAAA;AADJ;AAKI;EACE,oBAAA;EACA,YAAA;AAHN;AAOE;EACE,eAAA;AALJ","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 480px;\n\n  .code-input-container {\n    width: 270px;\n    margin: 0 auto;\n  }\n\n  .card-box {\n    overflow: hidden;\n  }\n\n  .disabled {\n    opacity: 0.6;\n    cursor: not-allowed;\n  }\n\n  .resend-link {\n    &.disabled {\n      pointer-events: none;\n      opacity: 0.6;\n    }\n  }\n\n  .buttons-container {\n    padding: 0 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
