import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[usgmSwipeGesture]',
})
export class SwipeGestureDirective {
  private touch = { x: 0, y: 0, time: 0 };

  /**
   * Swipe began, can be used to do some pre-processing before swipe recognized
   */
  @Output() swipeBegan = new EventEmitter();

  /**
   * Swipe direction values can be: up/down/left/right
   */
  @Output() swipeRecognized = new EventEmitter();

  /**
   * Swipe cancelled, can be used to revert swipeBegan changes
   */
  @Output() swipeLeft = new EventEmitter();
  @Output() swipeRight = new EventEmitter();
  @Output() swipeTop = new EventEmitter();
  @Output() swipeBottom = new EventEmitter();

  /**
   * Swipe cancelled, can be used to revert swipeBegan changes
   */
  @Output() swipeCancelled = new EventEmitter();

  constructor() {}

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  handleTouch(ev: any) {
    const touch = ev.touches[0] || ev.changedTouches[0];
    if (ev.type === 'touchstart') {
      this.touch.x = touch.pageX;
      this.touch.y = touch.pageY;
      this.touch.time = ev.timeStamp;
      this.swipeBegan.emit();
    } else if (ev.type === 'touchend') {
      const dx = touch.pageX - this.touch.x;
      const dy = touch.pageY - this.touch.y;
      const dt = ev.timeStamp - this.touch.time;
      if (dt < 500) {
        // swipe lasted less than 500 ms
        if (Math.abs(dx) > 120) {
          // delta x is at least 120 pixels
          if (dx < 0) {
            this.swipeRecognized.emit('right');
            this.swipeLeft.emit(true);
          } else {
            this.swipeRecognized.emit('left');
            this.swipeRight.emit(true);
          }
        }
        if (Math.abs(dy) > 120) {
          // delta y is at least 120 pixels
          if (dy < 0) {
            this.swipeRecognized.emit('up');
            this.swipeTop.emit(true);
          } else {
            this.swipeRecognized.emit('down');
            this.swipeBottom.emit(true);
          }
        }
      }
    } else if (ev.type === 'touchcancel') {
      this.swipeCancelled.emit();
    }
  }
}
