import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ComponentsModule } from '../components';
import { ResetPasswordComponent } from './reset-password.component';
import { ResetPasswordConfirmationComponent } from './reset-password-confirmation/reset-password-confirmation.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { NewPasswordConfirmationComponent } from './new-password-confirmation/new-password-confirmation.component';

@NgModule({
  declarations: [ResetPasswordComponent, ResetPasswordConfirmationComponent, NewPasswordComponent, NewPasswordConfirmationComponent],
  imports: [BrowserModule, CommonModule, ComponentsModule, FormsModule, MatCheckboxModule, ReactiveFormsModule, RouterModule],
  exports: [ResetPasswordComponent, ResetPasswordConfirmationComponent, NewPasswordComponent, NewPasswordConfirmationComponent],
})
export class ResetPasswordModule {}
