// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 480px;
  width: 100vw;
  height: 100%;
  background: #f2f6f7;
}
:host .deposit-nav-bar {
  padding: 20px 10px;
  font-size: 171%;
  line-height: 50px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}
:host .deposit-nav-bar button span {
  font-size: 171%;
}
:host .deposit-item-container h4 {
  font-weight: 600;
  font-size: 130%;
  color: #113151;
}
:host .address-delete {
  position: absolute;
  right: 5px;
  top: -40px;
  line-height: 20px;
  color: red !important;
}
:host .deposit-item-container {
  margin: 10px;
}
:host .submitted-view {
  padding: 40px;
  background: white;
}
:host .info {
  position: absolute;
  z-index: 9;
  left: 110px;
  top: 16px;
}
:host .dropdown-options-container {
  left: 0;
  width: 100% !important;
}
:host ::ng-deep .mat-horizontal-stepper-content {
  padding: 0 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/inbox/deposit-check/deposit-check.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;AACJ;AACI;EACE,eAAA;AACN;AAGE;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AADJ;AAIE;EACE,kBAAA;EACA,UAAA;EACA,UAAA;EACA,iBAAA;EACA,qBAAA;AAFJ;AAKE;EACE,YAAA;AAHJ;AAME;EACE,aAAA;EACA,iBAAA;AAJJ;AAOE;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,SAAA;AALJ;AAQE;EACE,OAAA;EACA,sBAAA;AANJ;AAUI;EACE,eAAA;AARN","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 480px;\n  width: 100vw;\n  height: 100%;\n  background: #f2f6f7;\n\n  .deposit-nav-bar {\n    padding: 20px 10px;\n    font-size: 171%;\n    line-height: 50px;\n    position: absolute;\n    top: 0px;\n    left: 0px;\n    width: 100%;\n\n    button span {\n      font-size: 171%;\n    }\n  }\n\n  .deposit-item-container h4 {\n    font-weight: 600;\n    font-size: 130%;\n    color: #113151;\n  }\n\n  .address-delete {\n    position: absolute;\n    right: 5px;\n    top: -40px;\n    line-height: 20px;\n    color: red !important;\n  }\n\n  .deposit-item-container {\n    margin: 10px;\n  }\n\n  .submitted-view {\n    padding: 40px;\n    background: white;\n  }\n\n  .info {\n    position: absolute;\n    z-index: 9;\n    left: 110px;\n    top: 16px;\n  }\n\n  .dropdown-options-container {\n    left: 0;\n    width: 100% !important;\n  }\n\n  ::ng-deep {\n    .mat-horizontal-stepper-content {\n      padding: 0 15px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
