import { Component, OnInit, Output, EventEmitter, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as Constants from '../../utils/constants';
import { AnnouncementsDialogComponent } from './announcements-dialog.component';
import { NotificationService, UserDataService } from '../../services';
import { AppRoutes } from '../../models/constants/app-routes.constant';
import * as SettingsConstants from '../../models/constants/settings-options.constant';
import { UsgmHttp, ApiMapping } from '../../services';
import { BaseHeaderComponent } from './base-header.component';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import * as SharedHelpers from '../../utils/helpers';
import { ScheduledAccountClosureDialogComponent } from '../scheduled-account-closure-dialog';
import * as moment from 'moment/moment';
import { ScheduledAccountCloseStatus } from '../../utils/constants';

@Component({
  selector: 'usgm-header',
  templateUrl: './header.component.html',
  styleUrls: ['header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends BaseHeaderComponent implements OnInit, OnDestroy {
  public override imgSrc = Constants.USGM_LOGO;
  public userData: any = {};
  public override userAnnouncements: any[] = [];
  public override unreadAnnouncementsCount = 0;
  public override userAnnouncementsPage = 0;
  public override mobileMode = false;
  public override settingsPath = `/${AppRoutes.settings}`;
  public override SettingsNavOptions = SettingsConstants.SettingNavViewOptions;
  public scheduledAccountClosureDate = null;

  @Output() openSidebar = new EventEmitter();

  protected override http: UsgmHttp;
  protected override apiMapping: ApiMapping;
  protected override dialog: MatDialog;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDisplayMode();
  }

  constructor(
    protected override cdr: ChangeDetectorRef,
    protected override userDataService: UserDataService,
    protected override injector: Injector,
    protected router: Router,
    protected override _sanitizer: DomSanitizer,
    protected notificationService: NotificationService,
  ) {
    super(cdr, userDataService, injector, _sanitizer);
  }

  public ngOnInit() {
    this.userData = this.userDataService.getDecodedAccessToken(this.userDataService.getAccessToken()) || {};
    this.setDisplayMode();
    if (!this.userDataService.getAnnouncementData()) {
      this.getUserAnnouncementsForPage();
    } else {
      this.setupAnnouncements();
      this.userAnnouncementsPage = this.getUserAnnouncementsPage();
    }
    this.getUserImportantDates();
  }

  public getUserImportantDates() {
    if (!this.userDataService.getUserId() || this.userData?.orgParentId) {
      return;
    }
    this.userDataService.getCustomerImportantDates(this.userDataService.getUserId()).subscribe(
      res => {
        if (res) {
          this.scheduledAccountClosureDate = res['scheduledAccountClosureDate'];
          return SharedHelpers.detectChanges(this.cdr);
        }
        this.scheduledAccountClosureDate = null;
      },
      (error: any) => {
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  public openCancelScheduledClosurePopup() {
    if (!this.scheduledAccountClosureDate) {
      return;
    }
    const dialogRef = this.dialog.open(ScheduledAccountClosureDialogComponent, {
      data: {
        title: `Your account is set to close on\n${moment(this.scheduledAccountClosureDate).format('DD MMM YYYY')}`,
        message: 'Closed in error? Click below to cancel closing',
        buttonText: 'DO NOT CLOSE MY ACCOUNT',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.cancelScheduledAccountClosure();
      }
    });
  }

  private cancelScheduledAccountClosure() {
    this.http
      .http_post(this.apiMapping.scheduledCloseUserAccount(), {
        status: ScheduledAccountCloseStatus.CANCELLED,
      })
      .then(response => {
        this.scheduledAccountClosureDate = null;
        this.notificationService.showSuccess('Account closing is cancelled successfully');
        this.getUserImportantDates();
      })
      .catch(error => {
        this.notificationService.showError(error?.error?.message || error?.message);
      });
  }

  public setDisplayMode() {
    this.mobileMode = this.userDataService.isMobileMode;
  }

  public openAnnouncementsPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '500px';
    this.dialog.open(AnnouncementsDialogComponent, dialogConfig);
  }

  public logoutUser() {
    this.userDataService.logoutUser();
  }

  public onNavigateInbox() {
    this.router.navigate([AppRoutes.inbox]);
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
