import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import * as SharedHelpers from '../utils/helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService, UserDataService } from '../services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Status } from '@usgm/usgm-payloads-library-front';
import * as Services from '../services';
import { AccountStatus } from '@usgm/usgm-payloads-library-front/Enums';
import { AppRoutes } from '../models/constants/app-routes.constant';
import { CaptchaBaseComponent } from '../captcha-base-component/captcha-base-component.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'usgm-accept-org-invite',
  templateUrl: './accept-org-invite.component.html',
  styleUrls: ['./accept-org-invite.component.scss'],
})
export class AcceptOrgInviteComponent extends CaptchaBaseComponent implements OnInit {
  public loading: boolean;
  public invitedUser;
  public acceptInviteForm: FormGroup;
  public isMobileView = false;
  public passwordFieldInputType = 'password';

  private token: string;
  private unsubscribeService: Subject<any> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private ref: ElementRef,
    private http: Services.UsgmHttp,
    private router: Router,
    private apiMapping: Services.ApiMapping,
    private userDataService: UserDataService,
    public override notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    public override recaptchaV3Service: ReCaptchaV3Service,
    public override dialog: MatDialog,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
  }

  public override ngOnInit() {
    this.acceptInviteForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(4)]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['token']) {
        this.token = params['token'];
        this.loadInviteDetails();
      }
    });
    if (!this.ref.nativeElement.offsetWidth || this.ref.nativeElement.offsetWidth < 900) {
      // null offsetWidth means nativescript
      this.isMobileView = true;
    }
  }

  public loadInviteDetails() {
    this.loading = true;
    this.invitedUser = null;
    this.userDataService
      .getOrgUserInviteDetails(this.token)
      .pipe(takeUntil(this.unsubscribeService))
      .subscribe(
        (resp: any) => {
          this.loading = false;
          this.invitedUser = resp;
          if (this.invitedUser?.name) {
            const parts = this.invitedUser.name.split(' ');
            this.acceptInviteForm.controls['firstName'].setValue(parts.shift());
            if (parts.length) {
              this.acceptInviteForm.controls['lastName'].setValue(parts.join(' '));
            }
          }
          SharedHelpers.detectChanges(this.cdr);
        },
        (error: any) => {
          this.loading = false;
          this.notificationService.showError(this.extractErrorMessage(error, 'Unable to get invitation details.'));
          SharedHelpers.detectChanges(this.cdr);
        },
      );
  }

  public submitAcceptInvite() {
    console.log(this.acceptInviteForm.getRawValue());
    Object.keys(this.acceptInviteForm.controls).forEach(key => {
      this.acceptInviteForm.get(key).markAsDirty();
    });
    if (this.acceptInviteForm.invalid) {
      return this.notificationService.showError('Kindly ensure that you have completed all necessary fields correctly prior to submitting.');
    }
    const payload = {
      name: this.acceptInviteForm.get('firstName').value + ' ' + this.acceptInviteForm.get('lastName').value,
      password: this.acceptInviteForm.get('password').value,
      token: this.token,
    };
    this.loading = true;
    this.userDataService.acceptOrgInvite(payload).subscribe(
      (resp: any) => {
        this.loading = false;
        if (resp?.status === Status.SUCCESS) {
          this.notificationService.showSuccessForDuration(
            'Your acceptance of the invitation has been successfully processed. Your account has been established and the registration process is finished. You have now logged into the system.',
            15000,
          );
          this.downloadFormsFirstCall(this.loginCall.bind(this));
        }
        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        this.loading = false;
        this.notificationService.showError(this.extractErrorMessage(error, 'Unable to accept invitation. Please contact support'));
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  private loginCall(token, capVersion) {
    this.http
      .http_post(this.apiMapping.loginUser(token, capVersion), {
        email: this.invitedUser.email,
        password: this.acceptInviteForm.get('password').value,
      })
      .then(
        (data: any) => {
          if (data['error']) {
            this.notificationService.showError('Unable to login. Please try again.');
            this.loading = false;
          } else if (data['token']) {
            this.onAfterLoginOrMFASubmit(data);
          }
        },
        (error: any) => {
          this.loading = false;
          if (error.status === 422) {
            this.verifyCaptcha(this.loginCall.bind(this));
          } else {
            if (error.status === 401) {
              this.notificationService.showError('Unable to login. ' + ((error.error || {})['message'] || 'Please try again.'));
            } else {
              this.notificationService.showError('Unable to login. Please try again.');
            }
          }
        },
      );
  }

  private onAfterLoginOrMFASubmit(data) {
    this.userDataService.removeTemporaryUserDetails();
    this.handleLoginSuccessWithSessionData(data);
  }

  private async handleLoginSuccessWithSessionData(userData: any) {
    // check account status
    if (this.userDataService.isStatusNew(userData)) {
      this.loading = false;
      return;
    }
    await this.userDataService.setUserSession(userData);
    await this.userDataService.getSubscription();
    await this.userDataService.getUserNotaryPlan();
    this.userDataService.setSocialLoginFlag(false);
    await this.userDataService.setLegacyData(userData);
    this.loading = false;
    this.userDataService.hideCaptcha();
    const accountStatus: AccountStatus = this.userDataService.getAccountStatus();
    this.router.navigate([AppRoutes.inbox]);
  }

  private extractErrorMessage(error, fallback) {
    return error?.error?.message || error?.statusText || error?.message || fallback;
  }

  public fieldEdited(fieldName: any): boolean {
    return this.acceptInviteForm.controls[fieldName].dirty && this.acceptInviteForm.controls[fieldName].touched;
  }

  public hasErrors(fieldName: any): boolean {
    return !!this.acceptInviteForm.controls[fieldName].errors;
  }

  public hasError(fieldName: any, errorName: any): boolean {
    return this.acceptInviteForm.controls[fieldName].hasError(errorName);
  }

  onHiddenFocus(event, fieldName) {
    if (event.target.value) {
      this.acceptInviteForm.controls[fieldName].setValue(event.target.value);
    }
  }
}
