import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AddressService, NotificationService } from '../../services';
import * as SharedHelpers from '../../utils/helpers';
import { environment } from '../../../environments/environment';
import { CardsData } from './mock-cards-data';
import { Country, State, City, ICountry, IState, ICity } from 'country-state-city';
import { DialogWithInputComponent } from '../../components/dialog-with-input';
import { MatDialog } from '@angular/material/dialog';
import { militaryBases } from '../../utils/constants';

@Component({
  selector: 'usgm-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  private stripe: any;
  private cardNumber: any;
  private cardExpiry: any;
  private cardCvc: any;
  private cardHandler = this.onChange.bind(this);
  public addCardForm: FormGroup = this.formBuilder.group({
    address: ['', Validators.required],
    country: ['', Validators.required],
    city: ['', Validators.required],
    state: ['', Validators.required],
    zip: ['', Validators.required],
    cardHolderName: ['', [Validators.compose([Validators.required, Validators.minLength(2)])]],
  });
  public userCards = JSON.parse(JSON.stringify(CardsData));
  public editMode = false;
  public stripeCardNumberError: any;
  public stripeCardExpiryError: any;
  public stripeCardCvcError: any;
  public countries: any;
  public states: any;
  public cities: any;

  @Output() closeNav = new EventEmitter();
  @ViewChild('billingPagesStepper', { static: true }) billingPagesStepper: MatStepper;

  constructor(
    protected formBuilder: FormBuilder,
    protected cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private addressService: AddressService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.getCountries();
  }

  ngAfterViewInit() {
    this.stripe = (<any>window).Stripe(environment['stripePublishKey']);
    const elements = this.stripe.elements();
    this.cardNumber = elements.create('cardNumber');
    this.cardNumber.mount('#card-number');
    this.cardExpiry = elements.create('cardExpiry');
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = elements.create('cardCvc');
    this.cardCvc.mount('#card-cvc');
    this.cardNumber.addEventListener('change', this.cardHandler);
    this.cardExpiry.addEventListener('change', this.cardHandler);
    this.cardCvc.addEventListener('change', this.cardHandler);
  }

  public submitNewCard() {
    console.log('submitNewCard');
  }

  public editCard(card) {
    this.editMode = true;
    this.addCardForm.patchValue({
      address: card.address,
      city: card.city,
      country: { GeoNameId: 6252001, Id: 'US', Name: 'United States' },
      state: { GeoNameId: 5332921, Name: 'California', adminCode1: 'CA' },
      zip: card.zip,
      cardHolderName: card.card_holder_name,
    });
    this.addCardForm.patchValue({ zip: card.zip });
    this.countrySelected();
    this.billingPagesStepper.selectedIndex = 1;
    this.notificationService.showWarningForDuration('Card details are empty for security purposes. Please fill again before updating card details', 10000);
  }

  public cardSelected(card) {
    this.userCards.forEach(userCard => {
      userCard.is_default = userCard === card;
    });
  }

  public getCountries() {
    this.countries = Country.getAllCountries().map((country: ICountry) => ({ Id: country.isoCode, Name: country.name }));
    this.addCardForm.patchValue({ country: { Id: 'US', Name: 'United States' } });
    this.countrySelected();
  }

  public getStatesByCountryId(id: string) {
    this.states = State.getStatesOfCountry(id);
    if (id === 'US' || id === 'USA') {
      this.states = [...this.states, ...militaryBases];
    }
  }

  public stateSelected(country: { Id: string; Name: string }, stateCode: string) {
    if (stateCode === 'add_new_option') {
      const dialogRefToAddState = this.dialog.open(DialogWithInputComponent, {
        data: {
          title: 'Add a new State',
          cancelText: 'Cancel',
          confirmText: 'Add',
          addEvent: 'ADD_NEW_STATE',
        },
      });

      dialogRefToAddState?.afterClosed().subscribe(result => {
        if (result.event === 'ADD_NEW_STATE') {
          this.states.push({
            isoCode: result.data,
            name: result.data,
          });
          this.addCardForm.patchValue({
            state: result.data,
          });
          this.addCardForm.patchValue({
            city: '',
          });
          this.cities = [];
          this.addCardForm.get('postal_code').reset();
          SharedHelpers.detectChanges(this.cdr);
        }
      });
    } else {
      this.cities = City.getCitiesOfState(country.Id, stateCode);
    }
  }

  public citySelected() {
    const selectedCity = this.addCardForm.controls['city'].value;
    if (selectedCity && selectedCity === 'add_new_option') {
      const dialogRefToAddCity = this.dialog.open(DialogWithInputComponent, {
        data: {
          title: 'Add a new city',
          cancelText: 'Cancel',
          confirmText: 'Add',
          addEvent: 'ADD_NEW_CITY',
        },
      });

      dialogRefToAddCity?.afterClosed().subscribe(result => {
        if (result.event === 'ADD_NEW_CITY') {
          this.cities.push({
            isoCode: result.data,
            name: result.data,
          });
          this.addCardForm.patchValue({
            city: result.data,
          });
          SharedHelpers.detectChanges(this.cdr);
        }
      });
    }
  }

  public countrySelected(): void {
    this.states = [];
    this.cities = [];
    this.addCardForm.get('state').reset();
    this.addCardForm.get('city').reset();
    this.addCardForm.get('zip').reset();
    const selectedCountry = this.addCardForm.get('country').value;
    if (selectedCountry) {
      const Id = selectedCountry.Id;
      if (Id) {
        this.getStatesByCountryId(Id);
      }
    }
  }

  public addCreditCard() {
    this.editMode = false;
    this.addCardForm.reset();
    this.billingPagesStepper.selectedIndex = 1;
  }

  public closeSideNav() {
    this.closeNav.emit({});
  }

  public onChange(error) {
    this.stripeCardNumberError = error.error && error.elementType === 'cardNumber' ? error.error.message : null;
    this.stripeCardExpiryError = error.error && error.elementType === 'cardExpiry' ? error.error.message : null;
    this.stripeCardCvcError = error.error && error.elementType === 'cardCvc' ? error.error.message : null;
    SharedHelpers.detectChanges(this.cdr);
  }

  public isFieldInvalid(field: string) {
    return !this.addCardForm.get(field).valid && this.addCardForm.get(field).touched;
  }

  public displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldInvalid(field),
      'has-feedback': this.isFieldInvalid(field),
    };
  }

  public compareByID(o1: any, o2: any) {
    return (o1 || {}).Id === (o2 || {}).Id;
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
