// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

.red-color {
  color: red;
  font-size: 14px;
}

.mobile-form {
  display: block !important;
}

.close-error-box {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.5490196078);
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/onboarding/add-new-names/add-new-names.component.scss"],"names":[],"mappings":"AAAA;EACE;IAAI,yBAAA;EAEJ;AACF;AAAA;EACE,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,yBAAA;EACA,uCAAA;AAEF;;AAAA;EACG,UAAA;EACA,eAAA;AAGH;;AADA;EACE,yBAAA;AAIF;;AAFA;EACI,kBAAA;EACA,QAAA;EACA,MAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,UAAA;EACA,eAAA;AAKJ;;AAHA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,6CAAA;AAMF","sourcesContent":["@keyframes spinner {\n  to {transform: rotate(360deg);}\n}\n \n.spinner:before {\n  content: '';\n  box-sizing: border-box;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 20px;\n  height: 20px;\n  margin-top: -10px;\n  margin-left: -10px;\n  border-radius: 50%;\n  border: 2px solid #ffffff;\n  border-top-color: #000000;\n  animation: spinner .8s linear infinite;\n}\n.red-color {\n   color: red;\n   font-size: 14px;\n}\n.mobile-form {\n  display: block !important;\n}\n.close-error-box {\n    position: absolute;\n    right: 0;\n    top: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    z-index: 2;\n    cursor: pointer;\n}\n.overlay {\n  position: absolute;\n  left:0;\n  top:0;\n  right:0;\n  bottom:0;\n  z-index:2;\n  background: #ffffff8c;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
