import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'creditCardDisplay' })
export class CreditCardDisplayPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    return value.replace(/.(?=.{4})/g, 'x').substr(value.length - 8);
  }
}
