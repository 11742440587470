import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import * as SharedHelpers from '../../../../utils/helpers';
import * as Services from '../../../../services';

const HOUSTON_PLANS = ['BASIC', 'PLUS', 'BUSINESS'];

@Component({
  selector: 'usgm-select-plan-step',
  templateUrl: './select-plan-step.component.html',
  styleUrls: ['./select-plan-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPlanStepComponent implements OnDestroy {
  public loading = true;
  public selectedWarehouseId;
  public planData: any = [];

  @Output() planSelected = new EventEmitter();
  @Input() set warehouseId(value) {
    if (value && value !== this.selectedWarehouseId) {
      this.planSelected.emit({ plan: { id: 0 }, term: { term_id: 0 } });
      this.selectedWarehouseId = value;
      this.planData = [];
      this.loadPlans();
      SharedHelpers.detectChanges(this.cdr);
    }
    this.selectedWarehouseId = value;
  }

  constructor(
    protected cdr: ChangeDetectorRef,
    private http: Services.UsgmHttp,
    private apiMapping: Services.ApiMapping,
    private notificationService: Services.NotificationService,
  ) {}

  private loadPlans() {
    console.log('attempting to load plans: ' + this.selectedWarehouseId);
    this.loading = true;
    this.http
      .http_get(this.apiMapping.getPlansByWarehouse(this.selectedWarehouseId))
      .then(
        (data: any) => {
          this.planData = +this.selectedWarehouseId === 1 ? data.filter(plan => HOUSTON_PLANS.includes(plan?.name.toUpperCase())) : data;
          this.loading = false;
          this.cdr.detectChanges();
        },
        (error: any) => {
          this.loading = false;
          this.notificationService.showError('Unable to fetch plans. Please try again.');
        },
      )
      .catch((exception: any) => {
        this.loading = false;
        this.notificationService.showError('Unable to fetch plans. Please try again.');
        console.log('error fetching data', exception);
      });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  selectPlan(plan, term) {
    this.planSelected.emit({ plan, term });
  }
}
