// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-row span {
  font-weight: bold;
}

.warning-text {
  color: red;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/change-warehouse/steps/confirm-step/confirm-step.component.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;;AAIA;EACE,UAAA;EACA,gBAAA;AADF","sourcesContent":[".confirm-row {\n  span {\n    font-weight: bold;\n  }\n}\n\n.warning-text {\n  color: red;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
