import { ChangeDetectorRef, Injector, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as Constants from '../../utils/constants';
import { UserDataService } from '../../services';
import { AppRoutes } from '../../models/constants/app-routes.constant';
import * as SettingsConstants from '../../models/constants/settings-options.constant';
import { UsgmHttp, ApiMapping } from '../../services';
import * as SharedHelpers from '../../utils/helpers';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  template: ``,
})
export class BaseHeaderComponent {
  public imgSrc = Constants.USGM_LOGO;
  public fetching = false;
  public userAnnouncements: any[] = [];
  public unreadAnnouncementsCount = 0;
  public userAnnouncementsPage = 0;
  public userAnnouncementsPerPage = 10;
  public userAnnouncementsTotal = 0;
  public mobileMode = false;
  public settingsPath = `/${AppRoutes.settings}`;
  public SettingsNavOptions = SettingsConstants.SettingNavViewOptions;

  protected http: UsgmHttp;
  protected apiMapping: ApiMapping;
  protected dialog: MatDialog;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected userDataService: UserDataService,
    protected injector: Injector,
    protected _sanitizer: DomSanitizer,
  ) {
    this.http = this.injector.get(UsgmHttp);
    this.apiMapping = this.injector.get(ApiMapping);
    this.dialog = this.injector.get(MatDialog);
    this.userDataService.announcementsUpdated.subscribe(resp => {
      this.setupAnnouncements();
    });
  }

  public getUserAnnouncementsForPage(showLoader = true) {
    this.fetching = showLoader;
    this.http.get(this.apiMapping.getUserAnnouncements(this.userAnnouncementsPage)).subscribe(response => {
      response['page'] = this.userAnnouncementsPage;
      (response['announcements'] || []).forEach(announcement => {
        announcement.start_date_formatted = SharedHelpers.stringToTimeSinceDate(announcement.start_date);
        announcement.body = this._sanitizer.bypassSecurityTrustHtml(announcement.body);
        (this.userAnnouncements || []).forEach(oldAnnouncement => {
          if (oldAnnouncement.id === announcement.id) {
            announcement.is_expanded = oldAnnouncement.is_expanded;
          }
        });
      });
      this.fetching = false;
      this.userDataService.setAnnouncementData(response);
    });
  }

  public setupAnnouncements() {
    this.unreadAnnouncementsCount = (this.userDataService.getAnnouncementData() || {}).unread_count || 0;
    this.userAnnouncements = (this.userDataService.getAnnouncementData() || {}).announcements || [];
    this.userAnnouncementsTotal = (this.userDataService.getAnnouncementData() || {}).count || [];
    this.userAnnouncementsPerPage = (this.userDataService.getAnnouncementData() || {}).count_per_page || [];
    SharedHelpers.detectChanges(this.cdr);
  }

  public getUserAnnouncementsPage() {
    return (this.userDataService.getAnnouncementData() || {}).page || 0;
  }
}
