interface ApiCallMixin {
  loading: boolean;
  activeApiCalls: number;
  startedApiCall(): void;
  completedApiCall(): void;
}

export function ApiCallMixin<T extends { new (...args: any[]): {} }>(Base: T) {
  return class extends Base implements ApiCallMixin {
    public loading = false;
    public activeApiCalls = 0;

    startedApiCall() {
      this.loading = true;
      this.activeApiCalls++;
    }

    completedApiCall() {
      this.activeApiCalls--;
      this.activeApiCalls = Math.max(0, this.activeApiCalls);
      if (this.activeApiCalls === 0) {
        this.loading = false;
      }
    }
  };
}
