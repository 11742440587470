import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, retry } from 'rxjs/operators';
import * as Services from '../../services';
import { HttpErrorResponse } from '@angular/common/http';
import * as SharedHelpers from '../../utils/helpers';
import { FormType, IAddressAttributes } from '@usgm/usgm-payloads-library-front';
import { Relation } from '../../models/get-notarized.model';
import { VerifyAccountService } from '../verify-account.service';
import { Router } from '@angular/router';
import { AppRoutes } from '../../models/constants/app-routes.constant';

@Component({
  selector: 'usgm-notarize-offline',
  templateUrl: './notarize-offline.component.html',
  styleUrls: ['./notarize-offline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotarizeOfflineComponent implements OnInit {
  private unSubscriber: Subject<any> = new Subject();
  public notaryAddress: IAddressAttributes;
  public uploadFormNotarizedComplete = false;
  public userNames = [];
  public uploadForm = false;
  public uploadFormSectionOpen = false;
  public fileSize = 10 * 1024 * 1024;
  public allowedTypes = ['application/pdf'];
  public notaryAddressLoading = true;
  public formList = [];
  public selectedId: string;
  public settingPageLoad: boolean;
  public usersFromAccountVerificationsIds = [];

  @Input() data;
  @Input() downLoadingPdf;
  @Output() clearForm = new EventEmitter<string>();
  @Output() navigateBackToForm = new EventEmitter<any>();
  @Output() downloadZipForm = new EventEmitter<any>();

  constructor(
    private cdRef: ChangeDetectorRef,
    protected verifyService: Services.VerifyAccountService,
    protected apiMapping: Services.ApiMapping,
    protected notificationService: Services.NotificationService,
    protected userDataService: Services.UserDataService,
    protected verifyAccountService: VerifyAccountService,
    private router: Router,
  ) {}

  @Input() set isNotarizedSelected(value: boolean) {
    if (value) {
      this.getFormStatuses();
    } else {
      this.uploadForm = true;
    }
  }

  public ngOnInit() {
    this.getNotaryPhysicalNotary();
    this.getAddedName();
    this.verifyService.settingsNameId.subscribe(value => {
      if (value) {
        this.settingPageLoad = value.isSettings;
        this.selectedId = value.id;
      } else {
        this.settingPageLoad = false;
        this.selectedId = null;
      }
      SharedHelpers.detectChanges(this.cdRef);
    });
  }

  public getFormStatuses() {
    this.verifyService
      .getFormList(this.apiMapping.getFormsList(this.userDataService.getUserId()))
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(response => {
        if (response && response.form1583 && response.form1583.length) {
          this.formList = response.form1583;
          for (let x = 0; x < response.form1583.length; x++) {
            if (response.form1583[x].form1583.form_type === FormType.FORM_1583) {
              this.uploadFormSectionOpen = true;
              break;
            }
            if (x === response.form1583.length - 1) {
              this.uploadFormSectionOpen = false;
              this.uploadFormNotarizedComplete = true;
              this.userDataService.setNotaryPlan(`offline`);
            }
          }
          if (!this.userNames?.length) {
            this.getAddedName();
          }
          this.setUsersSubmissionStatus();
          this.cdRef.detectChanges();
        }
      });
  }

  public removeVerifyTabFromList() {
    this.router.navigate([`/${AppRoutes.inbox}`]);
  }

  public getAddedName(): void {
    this.verifyService
      .getName(this.apiMapping.getAccountNamesWithOnlyStatus())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(apiResponse => {
        if (apiResponse) {
          this.usersFromAccountVerificationsIds = apiResponse.data.accountNames.filter(user => user.is_from_account_verification).map(user => user.user.uuid);
          for (const user of apiResponse.data.accountNames) {
            if (user.is_from_account_verification && user.relation.relationType === Relation.MINOR_CHILD) {
              this.userNames = this.userNames.map(item => {
                if (item.relation === Relation.PRIMARY) {
                  item.name = item.name + `, ${user.user.name} (${user.relation.relationType})`;
                }
                return item;
              });
            } else {
              const userForm = this.formList.find(item => item.user_id === user.user.uuid);
              if (userForm && userForm.form1583) {
                this.userNames.push({
                  name: user.user.name + ` (${user.relation.relationType})`,
                  id: user.user.uuid,
                  relation: user.relation.relationType,
                });
              }
            }
          }
          this.setUsersSubmissionStatus();
          this.cdRef.detectChanges();
        }
      });
  }

  onlineNotarizationPopup(): void {
    this.sendNotaryRequest();
  }

  uploadFormFuntion(userId) {
    const fileData = this.userNames.find(item => item.id === userId);
    if (fileData && fileData.userFile) {
      this.downLoadingPdf = true;
      const formData = new FormData();
      formData.append('form', fileData.userFile);
      formData.append('form_type', FormType.FORM_1583_NOTARIZED);
      this.verifyService
        .uploadPDF(this.apiMapping.uploadPDF(userId), formData)
        .pipe(takeUntil(this.unSubscriber))
        .subscribe(
          apiResponse => {
            if (apiResponse) {
              this.getFormStatuses();
              this.downLoadingPdf = false;
              this.cdRef.detectChanges();
            }
          },
          error => {
            this.downLoadingPdf = false;
            this.cdRef.detectChanges();
            this.notificationService.showError('Error occurred while uploading file. Please try again');
          },
        );
    } else {
      this.notificationService.showWarning('Please upload a pdf file before proceed');
    }
  }

  public sendNotaryRequest(): void {
    this.downLoadingPdf = true;
    if (!this.usersFromAccountVerificationsIds.length) {
      return;
    }
    this.verifyService
      .saveNotaryRequest(this.apiMapping.saveNotaryRequest(this.data.uuid), { notaryRequestForUsers: this.usersFromAccountVerificationsIds })
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        apiResponse => {
          this.verifyAccountService.verificationCompleteEvent(true);
          this.uploadForm = false;
          this.uploadFormSectionOpen = true;
          this.downLoadingPdf = false;
          this.getFormStatuses();
          this.cdRef.detectChanges();
        },
        error => {
          this.downLoadingPdf = false;
          this.cdRef.detectChanges();
          this.notificationService.showError(error.message);
        },
      );
  }

  scrollToTop(): void {
    const stepperElement = document.getElementById('stepper');
    if (stepperElement) {
      stepperElement.scrollIntoView();
    }
  }

  goBack() {
    for (const user of this.userNames) {
      user.userFile = null;
    }
    this.uploadForm = false;
    this.scrollToTop();
  }

  onFileDropped(fileEvent: Array<any>, userId) {
    this.validateFile(fileEvent, userId);
  }

  validateFile(fileEvent: File[], userId) {
    if (userId) {
      const userName = this.userNames.find(item => item.id === userId);
      userName.isSubmitted = false;
    }
    const file = fileEvent[0];
    if (file) {
      if (!this.allowedTypes.includes(file.type)) {
        this.notificationService.showWarning('Allowed types is pdf only');
        return false;
      } else if (file.size > this.fileSize) {
        this.notificationService.showWarning(`upload file will be less than ${this.fileSize / 1024 / 1024} MB.`);
        return false;
      }
      this.readURL(file, userId);
    }
  }

  readURL(file, userId) {
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        file.data = e.target.result;
        for (const user of this.userNames) {
          if (user.id === userId) {
            user.userFile = file;
            break;
          }
        }
        this.cdRef.detectChanges();
      };
      reader.readAsDataURL(file);
    }
  }

  public setUsersSubmissionStatus() {
    if (this.formList && this.formList.length && this.userNames && this.userNames.length) {
      this.userNames = this.userNames.map(userName => {
        const form1583Object = this.formList.find(value => value.user_id === userName.id);
        if (form1583Object && form1583Object.form1583.form_type === FormType.FORM_1583_NOTARIZED) {
          return {
            ...userName,
            isSubmitted: true,
          };
        } else {
          return {
            ...userName,
            isSubmitted: false,
          };
        }
      });
    }
  }

  downloadAllForms() {
    if (this.settingPageLoad && this.selectedId) {
      this.downloadZipForm.emit(this.selectedId);
    } else {
      this.downloadZipForm.emit();
    }
  }

  downloadUserForms(userId) {
    this.downloadZipForm.emit(userId);
  }

  clearFormValue(): void {
    this.navigateBackToForm.emit();
  }

  public getNotaryPhysicalNotary(): void {
    this.verifyService
      .getNotaryPhysicalAddress(this.apiMapping.getNotaryPhysicalAddress())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(
        apiResponse => {
          this.notaryAddress = apiResponse.address;
          this.notaryAddressLoading = false;
          SharedHelpers.detectChanges(this.cdRef);
        },
        (error: HttpErrorResponse) => {
          this.notaryAddressLoading = false;
          SharedHelpers.detectChanges(this.cdRef);
        },
      );
  }
}
