import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'usgm-mobile-item-detail',
  templateUrl: './mobile-item-detail.component.html',
  styleUrls: ['./mobile-item-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileItemDetailComponent implements OnInit, OnDestroy {
  public item: any = {};

  constructor(
    public dialogRef: MatDialogRef<MobileItemDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
  ) {
    this.item = data.item || {};
  }

  ngOnInit() {}

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  scanClicked() {
    this.item.selected = true;
    this.dialogRef.close({ scanItem: true });
  }

  shipClicked() {
    this.item.selected = true;
    this.dialogRef.close({ shipItem: true });
  }

  doneBtnPressed() {
    this.dialogRef.close({ data: '' });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
