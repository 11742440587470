// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-yes {
  color: white;
  background-color: #f87e2b;
}

.button-no {
  color: #377bb5;
}

.button-ok {
  flex: none;
  background-color: #f87e2b;
  color: white;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dialog-with-input/dialog.component.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,yBAAA;AACD;;AAEA;EACC,cAAA;AACD;;AAEA;EACC,UAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;AACD","sourcesContent":[".button-yes {\n\tcolor: white;\n\tbackground-color: #f87e2b;\n}\n\n.button-no {\n\tcolor: #377bb5;\n}\n\n.button-ok {\n\tflex: none;\n\tbackground-color: #f87e2b;\n\tcolor: white;\n\tmargin: auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
