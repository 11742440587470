import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';

@Component({
  selector: 'usgm-payments-table',
  templateUrl: './payments-table.component.html',
  styleUrls: ['./payments-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentsTableComponent implements OnInit {
  private _numHeaders = 7;
  @Input() mobileMode = false;
  @Input() selectedPaymentId = 0;
  @Input() payments = [];
  @Input() rowHeight = 40;
  @Output() paymentDetailClicked = new EventEmitter();
  @Output() fetchMoreCalled = new EventEmitter();

  constructor() {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.fixHeadersWidth();
  }

  public ngOnInit() {
    this.fixHeadersWidth();
  }

  public fixHeadersWidth() {
    setTimeout(() => {
      for (let i = 1; i <= (this.mobileMode ? 3 : this._numHeaders); i++) {
        const element = document.querySelectorAll('usgm-payments-table .header-' + i)[0];
        if (element) {
          element.removeAttribute('width');
        }
      }
    }, 10);
    setTimeout(() => {
      for (let i = 1; i <= (this.mobileMode ? 3 : this._numHeaders); i++) {
        const element = document.querySelectorAll('usgm-payments-table .header-' + i)[0];
        if (element) {
          element.setAttribute('width', Math.min(element.clientWidth, 350) + 'px');
        }
      }
    }, 100);
  }

  public paymentClicked(payment: any) {
    this.paymentDetailClicked.emit(payment);
  }

  public trackItems(index, item) {
    return item.id;
  }

  public fetchMore(event: IPageInfo) {
    if (!this.payments) {
      return;
    }
    if (event.endIndex !== this.payments.length - 1) {
      return;
    }
    this.fetchMoreCalled.emit(true);
  }
}
