import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiMapping, NotificationService, UsgmHttp } from 'src/app/services';
import { DtoScanExportItem, DtoScanExportsResults } from './types';

@Component({
  selector: 'usgm-scans-exports-list',
  templateUrl: './scans-exports-list.component.html',
  styleUrls: ['./scans-exports-list.component.scss'],
})
export class ScansExportsListComponent implements OnInit {
  public exportsList: DtoScanExportItem[] = [];
  public isLoading: boolean = false;
  @Output() closeNav = new EventEmitter();

  constructor(
    private http: UsgmHttp,
    private apiMapping: ApiMapping,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.fetchScans();
  }
  closeSideNav() {
    this.closeNav.emit({});
  }
  public fetchScans() {
    this.isLoading = true;
    this.http.get(this.apiMapping.getExportedScans()).subscribe(
      (data: DtoScanExportsResults) => {
        this.isLoading = false;
        this.exportsList = data.result;
      },
      error => {
        this.isLoading = false;
        this.notificationService.showError('Unable to load scan exports. Please try again later.');
      },
    );
  }
}
