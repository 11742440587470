import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FamilyNameModel } from '../../../models/address-model';
import * as Services from '../../../services';
import { VerifyAccountService } from '../../../verify-account/verify-account.service';
import { Relation } from '../../../models/get-notarized.model';
import { takeUntil } from 'rxjs/operators';
import * as SharedHelpers from '../../../utils/helpers';
import { Subject } from 'rxjs';
import { AccountNamesModel } from '../../../models/account-names-model';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { renderRelation } from '../../../utils/helpers';
@Component({
  selector: 'usgm-add-new-names',
  templateUrl: './add-new-names.component.html',
  styleUrls: ['./add-new-names.component.scss'],
})
export class SettingsOnboardingAddNewNameComponent implements OnInit {
  protected readonly renderRelation = renderRelation;

  @Input() selectedUser;
  public fromSettings = true;
  public submitted = false;
  public userData: any[] = [];
  public relationTypes: any[] = [];
  public dialogRef: any;
  public loading = false;
  public firstLoad = true;
  public addNameType: 'family' | 'business';
  public isAddNameConditionsLoaded = true;
  public headerMessages = {
    family: `Complete this step to receive mail in your name and your\nfamily member's names.`,
    business: `Complete this step to receive mail in your name and your\ncompany's names.`,
  };
  public familyNameList: FamilyNameModel[] = [];
  public plansList = [];
  public message: string;
  public settingPageLoad = false;
  public maxNames = 0;
  public selectedPlanId: number;
  public subscriptionValue = false;
  private unSubscriber: Subject<any> = new Subject();
  public originalValue = null;
  @Output() goToNextPage = new EventEmitter<any>();
  public holdTillStateLoading = true;

  constructor(
    protected verifyService: Services.VerifyAccountService,
    protected apiMapping: Services.ApiMapping,
    protected userDataService: Services.UserDataService,
    protected notificationService: Services.NotificationService,
    protected verifyAccountService: VerifyAccountService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.relationTypes.push(Relation.ADULT, Relation.MINOR_CHILD, Relation.DECEASED);
  }

  @Input()
  set loadingStatus(val: any) {
    if (val !== this.holdTillStateLoading) {
      this.holdTillStateLoading = val;
      this.cdRef.detectChanges();
    }
  }

  public ngOnInit() {
    this.familyNameList = [new FamilyNameModel()];
    this.familyNameList[0].name = '';
    if (this.selectedUser) {
      this.getAccountNameById();
    } else {
      this.firstLoad = false;
    }
    this.getPlansList();
  }

  public getAccountNameById() {
    this.userDataService
      .getSettingsAccountNames(this.apiMapping.getSettingsAccountNamesOnlyStatus(this.userDataService.getUserId()))
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        (apiResponse: AccountNamesModel[]) => {
          const accountNames = apiResponse['data']['accountNames'];
          const selectedUserDetails = accountNames.find(name => name.user.uuid === this.selectedUser);
          if (selectedUserDetails) {
            this.familyNameList[0].name = selectedUserDetails.user.name;
            this.familyNameList[0].description = selectedUserDetails.relation.relationType;
            this.familyNameList[0].id = selectedUserDetails.user.uuid;
            this.originalValue = _.cloneDeep(this.familyNameList[0]);
          }
          this.firstLoad = false;
          SharedHelpers.detectChanges(this.cdRef);
        },
        (error: HttpErrorResponse) => {
          this.firstLoad = false;
          this.notificationService.showError(error.message);
        },
      );
  }

  public getPlansList(): void {
    this.verifyService
      .getPlans(this.apiMapping.getPlans())
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        apiResponse => {
          this.getSubscriptions();
          this.addNameType = `family`;
          this.message = this.headerMessages[`family`];
          this.plansList = apiResponse;
          this.userDataService.plansList = SharedHelpers.clone(this.plansList);
          this.cdRef.detectChanges();
        },
        error => {
          console.log(error);
        },
      );
  }

  getSubscriptions() {
    this.userDataService
      .getUserSubscription()
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        response => {
          if (response && response.length) {
            const subscription = response.find(item => this.userDataService.isActiveSubscription(item));
            if (subscription) {
              this.selectedPlanId = subscription.plan_id;
              this.getSelectedPlan();
            } else {
              this.subscriptionValue = true;
              this.isAddNameConditionsLoaded = false;
              SharedHelpers.detectChanges(this.cdRef);
            }
          } else {
            this.subscriptionValue = true;
            this.isAddNameConditionsLoaded = false;
            SharedHelpers.detectChanges(this.cdRef);
          }
        },
        error => {
          this.isAddNameConditionsLoaded = false;
          SharedHelpers.detectChanges(this.cdRef);
        },
      );
  }

  async getSelectedPlan() {
    this.userDataService
      .getUserPlanById(this.selectedPlanId)
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        response => {
          if (response?.name?.toLowerCase() === 'business' || response?.name?.toLowerCase() === 'pay_as_you_go') {
            this.relationTypes.push(Relation.COMPANY, Relation.TRUST);
          }
          if (response && response.account_names_plan && response.account_names_plan.max_names) {
            this.maxNames = SharedHelpers.maxNamesForPlan(response);
          }
          this.isAddNameConditionsLoaded = false;
          SharedHelpers.detectChanges(this.cdRef);
        },
        error => {
          this.isAddNameConditionsLoaded = false;
          SharedHelpers.detectChanges(this.cdRef);
        },
      );
  }

  relationType(rel) {
    if (rel.toLowerCase() === `child (18+)`) {
      rel = `child`;
    }
    return rel.toUpperCase().split(' ').join('_');
  }

  @Input() set planType(value) {
    if (value === 'business' || value === 'pay_as_you_go') {
      this.relationTypes.push(Relation.COMPANY, Relation.TRUST);
    }
  }

  onAddingNames(): void {
    if (this.loading) {
      return;
    }
    const payload = [];
    if (this.familyNameList[0] && (!this.familyNameList[0].name || !this.familyNameList[0].description)) {
      this.notificationService.showWarning(!this.familyNameList[0].name ? `Please add name for user number 1 !!` : `Please select description for user number 1 !!`);
      return;
    }
    if (_.isEqual(this.originalValue, this.familyNameList[0])) {
      this.goToNextPage.emit({ page: [Relation.COMPANY, Relation.TRUST].includes(this.familyNameList[0].description as any) ? 'ADD_ADDRESS' : 'ADD_IDS' });
      return;
    }
    payload.push({
      childId: this.familyNameList[0].id,
      relation: this.relationType(this.familyNameList[0].description),
      notes: null,
      name: this.familyNameList[0].name,
      is_from_account_verification: !this.fromSettings,
      is_added_before_account_verification_completion: !this.userDataService.onboardingCompleted,
    });
    if (payload.length) {
      this.loading = true;
      this.addNameAPI(payload);
    }
  }

  async addNameAPI(payload) {
    this.verifyService.addName(this.apiMapping.saveAccountNames(), payload).subscribe(
      apiResponse => {
        if (apiResponse) {
          this.goToNextPage.emit({
            userId: apiResponse.userIds[0],
            page: [Relation.COMPANY, Relation.TRUST].includes(this.familyNameList[0].description as any) ? 'ADD_ADDRESS' : 'ADD_IDS',
            removeFromTab: [Relation.MINOR_CHILD].includes(this.familyNameList[0].description as any),
            addFormTab: ![Relation.MINOR_CHILD].includes(this.familyNameList[0].description as any),
            addAddressTab: [Relation.COMPANY, Relation.TRUST].includes(this.familyNameList[0].description as any),
          });
          this.loading = false;
          this.cdRef.detectChanges();
        }
      },
      error => {
        this.loading = false;
        this.cdRef.detectChanges();
        this.notificationService.showError(error?.error?.message || 'Unable to add the account name !!');
      },
    );
  }
}
