// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 480px;
  width: 100vw;
  height: 100%;
  background: #f2f6f7;
}
:host .item-details-container {
  margin: 10px;
  background: white;
  padding: 10px;
}
:host .notes-input-conatiner {
  display: flex;
  padding: 4px;
  align-items: center;
}
:host .notes-container {
  padding: 12px;
  margin: 12px;
  background: #fff;
}
:host .note-owner-name {
  color: #4886bb;
  font-size: 14px;
}
:host .note-creation-date {
  color: #f97e2b;
  font-size: 12px;
}
:host .item_notes {
  background: #fff;
}
:host .add-note-container {
  padding-top: 12px;
}
:host .note-text {
  font-size: 16px;
  word-wrap: break-word;
}
:host .button-icon-color {
  color: #4886bb;
}
:host .notes-container div.label {
  font-weight: bold;
}
:host .usgm-green {
  font-weight: bold;
}
:host .image {
  max-width: 100%;
}
:host .data-row {
  display: flex;
}
:host .time-frame {
  color: #329a32;
}`, "",{"version":3,"sources":["webpack://./src/app/inbox/items-table/item-details-popup/item-details-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;EACA,iBAAA;EACA,aAAA;AACJ;AAEE;EACE,aAAA;EACA,YAAA;EACA,mBAAA;AAAJ;AAGE;EACE,aAAA;EACA,YAAA;EACA,gBAAA;AADJ;AAIE;EACE,cAAA;EACA,eAAA;AAFJ;AAKE;EACE,cAAA;EACA,eAAA;AAHJ;AAME;EACE,gBAAA;AAJJ;AAQE;EACE,iBAAA;AANJ;AASE;EACE,eAAA;EACA,qBAAA;AAPJ;AAUE;EACE,cAAA;AARJ;AAWE;EACE,iBAAA;AATJ;AAYE;EACE,iBAAA;AAVJ;AAaE;EACE,eAAA;AAXJ;AAcE;EACE,aAAA;AAZJ;AAeE;EACE,cAAA;AAbJ","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 480px;\n  width: 100vw;\n  height: 100%;\n  background: #f2f6f7;\n\n  .item-details-container {\n    margin: 10px;\n    background: white;\n    padding: 10px;\n  }\n\n  .notes-input-conatiner {\n    display: flex;\n    padding: 4px;\n    align-items: center;\n  }\n\n  .notes-container {\n    padding: 12px;\n    margin: 12px;\n    background: #fff;\n  }\n\n  .note-owner-name {\n    color: #4886bb;\n    font-size: 14px\n  }\n\n  .note-creation-date {\n    color: #f97e2b;\n    font-size: 12px\n  }\n\n  .item_notes {\n    background: #fff;\n\n  }\n\n  .add-note-container {\n    padding-top: 12px\n  }\n\n  .note-text {\n    font-size: 16px;\n    word-wrap: break-word\n  }\n\n  .button-icon-color {\n    color: #4886bb\n  }\n\n  .notes-container div.label {\n    font-weight: bold;\n  }\n\n  .usgm-green {\n    font-weight: bold;\n  }\n\n  .image {\n    max-width: 100%;\n  }\n\n  .data-row {\n    display: flex;\n  }\n\n  .time-frame {\n    color: #329a32;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
