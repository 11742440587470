import { Component, OnInit, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy, HostListener, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import * as Constants from '../utils/constants';
import * as Services from '../services';
import * as SharedHelpers from '../utils/helpers';
import { environment } from '../../environments/environment';
import { AccountStatus } from '@usgm/usgm-payloads-library-front/Enums';
import { AppRoutes } from '../models/constants/app-routes.constant';

@Component({
  selector: 'usgm-billing-history',
  templateUrl: './billing-history.component.html',
  styleUrls: ['./billing-history.component.scss', '../inbox/inbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingHistoryComponent implements OnInit, OnDestroy {
  private pageNum = 0;
  private pullToRefreshEvent: any;
  public loading = false;
  public mobileMode = false;
  public rowsHeight = 591;
  public invoices: any[] = [];
  public credits: any[] = [];
  public unbilledCharges: any[] = [];
  public currentInvoice: any = {};
  public currentInvoiceDetails: any = {};
  public showInvoiceDetail = false;
  public currentCredit: any = {};
  public showCreditDetail = false;
  public spinnerLoad = false;
  public selectedTab = 'invoices';
  public legacyInvoicesDate;
  public legacyInvoicesDeactivationDate = Constants.LEGACY_DEACTIVATION_DATE;
  public isLegacy = false;
  public shipmentId = null;

  @ViewChild('invoiceDetailsDrawer') invoiceDetailsDrawer: MatSidenav;

  constructor(
    private cdr: ChangeDetectorRef,
    private http: Services.UsgmHttp,
    private userDataService: Services.UserDataService,
    private notificationService: Services.NotificationService,
    private apiMapping: Services.ApiMapping,
  ) {
    this.isLegacy = this.userDataService.isLegacyUser();
    this.legacyInvoicesDate = this.userDataService.getLegacyMigrationDate();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDisplayMode();
  }

  ngOnInit() {
    this.setDisplayMode();
    this.getInvoices();
    this.getCredits();
    this.getUnbilledCharges();
  }

  public getCredits() {
    this.loading = true;
    this.http.http_get(this.apiMapping.getUserCreditNotes(this.pageNum)).then(
      (data: any) => {
        this.credits = [];
        data.list.forEach(credit => {
          this.credits.push(this.setupCreditData(credit));
        });
        this.loading = false;
        if (this.pullToRefreshEvent) {
          this.pullToRefreshEvent.target.complete();
        }
        this.setDisplayMode();
        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        this.loading = false;
        this.notificationService.showError('Unable to fetch credit notes. Please try again.');
      },
    );
  }

  public getInvoices() {
    this.loading = true;
    this.http.http_get(this.apiMapping.getUserInvoices(this.pageNum)).then(
      (data: any) => {
        this.invoices = [];
        data.list.forEach(invoiceObj => {
          this.invoices.push(this.setupInvoiceData(invoiceObj));
        });
        this.loading = false;
        if (this.pullToRefreshEvent) {
          this.pullToRefreshEvent.target.complete();
        }
        this.setDisplayMode();

        // User paid all unpaid invoices, wait for some time until Chargebee payment_succeeded webhook event will be reached to usgm-payments microservice, and the
        // usgm-payments microservice will unsuspend the customer if it's suspended. After some delay, try to refresh the token so we get approved status in the token if the
        // customer was  unsuspended
        if (!this.hasUnpaidInvoice() && [AccountStatus.SUSPENDED, AccountStatus.SUSPENDED].includes(this.userDataService.getAccountStatus())) {
          setTimeout(() => this.userDataService.refreshSession(), 10000);
        }

        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        this.loading = false;
        this.notificationService.showError('Unable to fetch invoices. Please try again.');
      },
    );
  }

  public hasUnpaidInvoice() {
    return this.invoices.some(i => ['not_paid', 'payment_due'].includes(i.status));
  }

  public getUnbilledCharges() {
    this.http.http_get(this.apiMapping.getUserUnbilledCharges(this.pageNum)).then((data: any) => {
      this.unbilledCharges = [];
      data.list.forEach(chargeItem => {
        this.unbilledCharges.push(this.setupUnbilledChargeData(chargeItem));
      });
      this.setDisplayMode();
      SharedHelpers.detectChanges(this.cdr);
    });
  }

  public downloadInvoicePdfUrl(invoiceId: string) {
    if (this.mobileMode) {
      this.notificationService.showWarning('Opening pdf in new tab. Please save in desired location');
    }
    this.loading = true;
    this.http.http_get(this.apiMapping.getInvoicePdfUrl(invoiceId)).then(
      result => {
        window.open(result.download_url, '_system');
        this.loading = false;
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.loading = false;
        this.notificationService.showError('Unable to download invoice. Please try again.');
      },
    );
  }

  public downloadCreditPdfUrl(invoiceId: string, creditId: string) {
    if (this.mobileMode) {
      this.notificationService.showWarning('Opening pdf in new tab. Please save in desired location');
    }
    this.loading = true;
    this.http.http_get(this.apiMapping.getCreditPdfUrl(creditId, invoiceId)).then(
      result => {
        window.open(result.download_url, '_system');
        this.loading = false;
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.loading = false;
        this.notificationService.showError('Unable to download credit note. Please try again.');
      },
    );
  }

  public setupCreditData(creditObj) {
    const credit = creditObj.credit_note;
    credit.total = credit.total / 100.0;
    credit.date = SharedHelpers.stringToLocaleDate(new Date(credit.date * 1000).toISOString());
    return credit;
  }

  public setupInvoiceData(invoiceObj) {
    const invoice = invoiceObj.invoice;
    invoice.total = invoice.total / 100.0;
    invoice.amount_due = invoice.amount_due / 100.0;
    invoice.amount_paid = invoice.amount_paid / 100.0;
    if (invoice.credits_applied) {
      invoice.credits_applied = invoice.credits_applied / 100.0;
    }
    invoice.sub_total = invoice.sub_total / 100.0;
    invoice.tax = invoice.tax / 100.0;
    invoice.line_items.forEach(lineItem => {
      lineItem.amount = lineItem.amount / 100.0;
      lineItem.entity_type = lineItem.entity_type === 'plan' ? 'Subscription Charges' : 'One Time Charge';
    });
    invoice.discounts = invoice.discounts || [];
    invoice.discounts.forEach(discount => {
      discount.amount = discount.amount / 100.0;
    });
    invoice.due_date = SharedHelpers.stringToLocaleDate(new Date(invoice.due_date * 1000).toISOString());
    if (invoice.paid_at) {
      invoice.paid_at = SharedHelpers.stringToLocaleDate(new Date(invoice.paid_at * 1000).toISOString());
    }
    return invoice;
  }

  public setupUnbilledChargeData(chargeItemObj) {
    const chargeItem = chargeItemObj.unbilled_charge;
    chargeItem.amount = chargeItem.amount / 100.0;
    chargeItem.created_at = SharedHelpers.stringToLocaleDate(new Date(chargeItem.date_from * 1000).toISOString());
    chargeItem.unit_amount = chargeItem.unit_amount / 100.0;
    (chargeItem.tiers || []).forEach(tier => {
      tier.unit_amount = tier.unit_amount / 100.0;
    });
    return chargeItem;
  }

  public setDisplayMode() {
    this.mobileMode = this.userDataService.isMobileMode;
    const innerHeight = (document.getElementsByClassName('history-container')[0] || {})['offsetHeight'] || window.innerHeight;
    if (this.mobileMode) {
      this.rowsHeight = this.userDataService.getScreenHeight() - 167;
    } else {
      this.rowsHeight = innerHeight - 100;
    }
  }

  public tabSelected(tab) {
    this.selectedTab = tab;
    if (this.pullToRefreshEvent) {
      setTimeout(() => this.pullToRefreshEvent.target.complete(), 2000);
    }
  }

  public invoiceClicked(invoice) {
    this.currentInvoice = invoice;
    if (invoice.line_items[0]?.description.includes(`Shipping:`)) {
      this.getShipmentId(invoice.id);
    }
    this.showCreditDetail = false;
    this.showInvoiceDetail = true;
    this.invoiceDetailsDrawer.open();
  }

  public creditClicked(credit) {
    this.currentCredit = credit;
    this.showInvoiceDetail = false;
    this.showCreditDetail = true;
    this.invoiceDetailsDrawer.open();
  }

  private getShipmentId(id) {
    this.loading = true;
    this.shipmentId = null;
    this.currentInvoiceDetails = null;
    this.http.http_get(this.apiMapping.getInvoiceDetails(id)).then(
      (data: any) => {
        this.loading = false;
        this.shipmentId = data?.invoiceLineItems[0]?.request_id || null;
        this.currentInvoiceDetails = data;
        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        this.loading = false;
        SharedHelpers.detectChanges(this.cdr);
        this.notificationService.showError('Unable to fetch shipment details. Please try again.');
      },
    );
  }

  public statusFormatted(statusStr) {
    return statusStr.split('_').join(' ');
  }

  public pullToRefresh(event) {
    this.pullToRefreshEvent = event;
    this.getInvoices();
  }

  public async redirectToLegacySystem() {
    if (this.spinnerLoad) {
      return false;
    }
    this.spinnerLoad = true;
    const token: string = await this.userDataService.legacyMigrationGenerateAndReturnToken();
    const legacyURL = `${environment.legacyAppUrl}/membership/redirect/?target_page=invoices&sso_jwt=${token}`;
    this.spinnerLoad = false;
    SharedHelpers.detectChanges(this.cdr);
    window.open(legacyURL, '_system');
  }

  public getUnitLabelForChargeDescription(description: string, quantity: number) {
    if (description.toLowerCase().indexOf('scan') >= 0) {
      return `Page${quantity > 1 ? 's' : ''}`;
    } else if (description.toLowerCase().indexOf('storage') >= 0) {
      return `Day${quantity > 1 ? 's' : ''}`;
    }
    return `Item${quantity > 1 ? 's' : ''}`;
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
