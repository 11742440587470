// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-yes {
  background-color: #f87e2b;
  color: white;
}

.button-no {
  color: #377bb5;
}

.button-ok {
  flex: none;
  background-color: #f87e2b;
  color: white;
  margin: auto;
}

.close-button {
  position: absolute;
  right: -16px;
  top: -22px;
  background-color: #fefefe;
  color: #000;
}

.green-color {
  color: #00B03D !important;
}

.width-100 {
  width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dialog/dialog.component.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,YAAA;AACD;;AAEA;EACC,cAAA;AACD;;AAEA;EACC,UAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;AACD;;AAEA;EACC,kBAAA;EACA,YAAA;EACA,UAAA;EACA,yBAAA;EACA,WAAA;AACD;;AAEA;EACC,yBAAA;AACD;;AAEA;EACC,YAAA;AACD","sourcesContent":[".button-yes {\n\tbackground-color: #f87e2b;\n\tcolor: white;\n}\n\n.button-no {\n\tcolor: #377bb5;\n}\n\n.button-ok {\n\tflex: none;\n\tbackground-color: #f87e2b;\n\tcolor: white;\n\tmargin: auto;\n}\n\n.close-button {\n\tposition: absolute;\n\tright: -16px;\n\ttop: -22px;\n\tbackground-color: #fefefe;\n\tcolor: #000;\n}\n\n.green-color {\n\tcolor: #00B03D !important;\n}\n\n.width-100 {\n\twidth: 100px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
