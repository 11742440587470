import { Injectable } from '@angular/core';
import { ApiMapping } from './api-mapping.service';
import { UsgmHttp } from './usgm-http.service';
import { IFormErrorResolveAddAddressPayload, ISignFormPayload } from '../models/form-error';

@Injectable()
export class Form1583Service {
  constructor(
    private apiMapping: ApiMapping,
    private http: UsgmHttp,
  ) {}

  public resolveFormErrorUploadPrimaryDocument(formErrorUUID: string, payload: FormData) {
    return this.http.post(this.apiMapping.resolveFormErrorUploadPrimaryDocument(formErrorUUID), payload);
  }

  public resolveFormErrorUploadSecondaryDocument(formErrorUUID: string, payload: FormData) {
    return this.http.post(this.apiMapping.resolveFormErrorUploadSecondaryDocument(formErrorUUID), payload);
  }

  public resolveFormErrorAddCompanyAddress(formErrorUUID: string, payload: IFormErrorResolveAddAddressPayload) {
    return this.http.post(this.apiMapping.resolveFormErrorAddCompanyAddress(formErrorUUID), payload);
  }

  public resolveFormErrorAddBillingAddress(formErrorUUID: string, payload: IFormErrorResolveAddAddressPayload) {
    return this.http.post(this.apiMapping.resolveFormErrorAddBillingAddress(formErrorUUID), payload);
  }

  public submitFormSignature(formErrorUUID: string, payload: ISignFormPayload) {
    return this.http.post(this.apiMapping.submitFormSignature(formErrorUUID), payload);
  }
}
