import { Component, HostListener, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Platform } from '@ionic/angular';
import { OneSignal } from 'onesignal-ngx';
import { TitledSnackBarComponent } from './components/titled-snack-bar';
import { ApiMapping, NotificationService, UserDataService, UsgmHttp } from './services';
import * as Constants from './utils/constants';
import { AppRoutes } from './models/constants/app-routes.constant';
import * as crypto from 'crypto-js';
import { environment } from '../environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AccountStatus } from '@usgm/usgm-payloads-library-front/Enums';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AccountSuspendedDialogComponent } from './dialogs';

export enum AppState {
  UNKNOWN = 0,
  SESSION_FETCHING = 1, // get session on load
  SESSION_FETCHED = 2, // active session fetched, navigate to home
}

@Component({
  selector: 'usgm-app',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  public name = 'USGM Inbox';
  public appState = AppState.SESSION_FETCHING;
  public appStates = AppState;
  public imgSrc = Constants.USGM_LOGO;

  private dialog: MatDialog;

  constructor(
    public userDataService: UserDataService,
    private notificationService: NotificationService,
    private router: Router,
    private http: UsgmHttp,
    private apiMapping: ApiMapping,
    private platform: Platform,
    private snackBar: MatSnackBar,
    private gtmService: GoogleTagManagerService,
    private injector: Injector,
    private oneSignal: OneSignal,
  ) {
    this.dialog = this.injector.get(MatDialog);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.userDataService.setupPlatform();
  }

  public ngOnInit() {
    this.platform.ready().then(async () => {
      const URL = window.location.href;
      //    this.splashScreen.hide();
      this.userDataService.setupPlatform();
      if (this.platform.is('android')) {
        this.platform.backButton.subscribeWithPriority(9999, () => navigator['app'].exitApp());
        document.addEventListener('backbutton', e => navigator['app'].exitApp(), false);
      } else if (this.platform.is('ios')) {
        // this.statusBar.overlaysWebView(false);
      }
      // this.statusBar.styleDefault();
      this.userDataService.heightUpdated$.next({});
      //  this.mobileAccessibility.usePreferredTextZoom(true);
      await this.userDataService.fetchEnvironment();
      if (URL.includes('/unsubscribe')) {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const data = params.get('d');
        this.router.navigate([AppRoutes.unsubscribe], { queryParams: { d: data } });
        this.appState = AppState.SESSION_FETCHED;
        return false;
      }
      if (URL.includes('/recover')) {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        if (params.get('legacy_username')) {
          localStorage.removeItem('userData');
          sessionStorage.setItem('legacy_username', params.get('legacy_username'));
          sessionStorage.setItem('disable_social_logins', 'true');
        }
        this.resetPasswordTrigger(URL);
      } else if (URL.includes('legacy_username')) {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        await this.userDataService.redirectBasedOnUserName(params.get('legacy_username'));
        this.appState = AppState.SESSION_FETCHED;
      } else if (this.userDataService.getRefreshToken()) {
        if (localStorage.getItem('userData')) {
          const userData = JSON.parse(localStorage.getItem('userData'));
          if (userData.account_status === AccountStatus.SUSPENDED) {
            this.openAccountSuspendedPopup();
          } else if ([AccountStatus.CLOSED, 'REVOKED'].includes(userData.account_status)) {
            this.openAccountSuspendedPopup(true);
          }
          await this.userDataService.setUserSession(userData);
        }
        await this.handleCurrentSession();
      } else if (URL.includes('/signup')) {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const warehouse_id = params.get('warehouse_id');
        const email = params.get('email');
        const plan_id = params.get('plan_id');
        const term_id = params.get('term_id');

        this.router.navigate([AppRoutes.signup], { queryParams: { warehouse_id, email, plan_id, term_id } });
        this.appState = AppState.SESSION_FETCHED;
      } else if (URL.includes('/select-plan')) {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const warehouse_id = params.get('warehouse_id');
        const email = params.get('email');
        this.router.navigate([AppRoutes.selectPlan], { queryParams: { warehouse_id, email } });
        this.appState = AppState.SESSION_FETCHED;
      } else if (URL.includes(`/${AppRoutes.selectWarehouseByAddress}`)) {
        this.router.navigate([AppRoutes.selectWarehouseByAddress]);
        this.appState = AppState.SESSION_FETCHED;
      } else if (URL.includes(`/${AppRoutes.resetPassword}`)) {
        this.router.navigate([AppRoutes.resetPassword]);
        this.appState = AppState.SESSION_FETCHED;
      } else if (URL.includes(`/${AppRoutes.login}`)) {
        this.router.navigate([AppRoutes.login], { queryParamsHandling: 'preserve' } as any);
        this.appState = AppState.SESSION_FETCHED;
      } else if (URL.includes(`/${AppRoutes.acceptOrgInvite}`)) {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const token = params.get('token');
        this.router.navigate([AppRoutes.acceptOrgInvite], { queryParams: { token } });
        this.appState = AppState.SESSION_FETCHED;
      } else {
        this.router.navigate([AppRoutes.login]);
        this.appState = AppState.SESSION_FETCHED;
      }
    });
    console.log('Initialized App');
    console.log(`subscribing to notificationService.showMessageSubject`);
    this.notificationService.showMessageSubject.subscribe(data => {
      console.log(`received event for showing message: ${JSON.stringify(data)}`);
      const config = new MatSnackBarConfig();
      config.duration = data.duration;
      config.panelClass = [data.panelClass];
      if (data.title) {
        config.data = data;
        config.horizontalPosition = data.horizontalPosition;
        console.log(`opening the snack bar from component: ${JSON.stringify(config)}`);
        this.snackBar.openFromComponent(TitledSnackBarComponent, config);
        return;
      }
      console.log(`opening the snack bar: ${data.message}, ${data.action}, ${JSON.stringify(config)}`);
      this.snackBar.open(data.message, data.action, config);
    });
    if (window.location.href.includes('/login?isOTP')) {
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const isOTPReq = params.get('isOTP');
      let data = params.get('data');
      if (isOTPReq && data) {
        data = crypto.AES.decrypt(data.replace(/xMl3Jk/g, '+'), environment['loginEncryptionKey']).toString(crypto.enc.Utf8);
        data = JSON.parse(data);
        this.userDataService.setTemporaryUserDetails({ email: data[`email`], token: data[`token`] });
        this.userDataService.logoutUser();
        return false;
      }
    }

    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        if (environment.gaConfigCode && [AppRoutes.signup, AppRoutes.paymentComplete].includes(evt.urlAfterRedirects.slice(1))) {
          this.gtmService.pushTag({
            event: 'page',
            pageName: evt.urlAfterRedirects,
          });
        }
        if (evt.urlAfterRedirects.slice(1) === AppRoutes.paymentInfo) {
          // Since we have route guard to redirect to the /payment-info page very frequently, this causes incorrectly huge pageview events in GA for payment-info.
          // As a workaround, we can send pageview tag for the payment info page only when the user stayed in the page for 10 seconds.
          setTimeout(() => {
            const currentUrl = this.router.url.replace('/', '');
            if (currentUrl === AppRoutes.paymentInfo) {
              this.gtmService.pushTag({
                event: 'page',
                pageName: evt.urlAfterRedirects,
              });
            }
          }, 10000); // 10 seconds
        }
        window.scrollTo(0, 0);
        this.userDataService.setScreenForAnalytics(evt.url.replace('/', ''));
      }
    });
    this.userDataService.refreshSessionSubject.subscribe(data => {
      this.notificationService.showWarning('Trying to log back in ...');
      this.refreshSession();
    });
  }

  public resetPasswordTrigger(redirectURL) {
    const url = new URL(redirectURL);
    const token = url?.search?.split('token=')[1];
    if (token) {
      this.http
        .get(this.apiMapping.verifyTokenPasswordUpdate(), {
          observe: 'response',
          headers: { Authorization: token },
        })
        .toPromise()
        .then(
          (data: any) => {
            sessionStorage.setItem('token', data.headers.get('Authorization'));
            const { search } = window.location;
            const params = new URLSearchParams(search);
            if (params.get('legacy_username')) {
              sessionStorage.setItem('legacy_username', params.get('legacy_username'));
              sessionStorage.setItem('disable_social_logins', 'true');
            }
            this.router.navigate([AppRoutes.newPassword]);
            this.appState = AppState.SESSION_FETCHED;
          },
          (error: any) => {
            this.resetPasswordTokenError();
          },
        );
    } else {
      this.resetPasswordTokenError();
    }
  }

  public refreshSession() {
    this.userDataService.isRefreshingSession = true;
    this.appState = AppState.SESSION_FETCHING;
    this.http.http_post(this.apiMapping.refreshSession(), {}).then(
      async userData => {
        await this.userDataService.setUserSession(userData);
        await this.handleCurrentSession();
        this.userDataService.isRefreshingSession = false;
        if (userData.account_status === AccountStatus.SUSPENDED) {
          this.openAccountSuspendedPopup();
        } else if ([AccountStatus.CLOSED, 'REVOKED'].includes(userData.account_status)) {
          this.openAccountSuspendedPopup(true);
        }
      },
      err => {
        this.appState = AppState.SESSION_FETCHED;
        this.userDataService.logoutUser();
        this.userDataService.isRefreshingSession = false;
      },
    );
  }

  private openAccountSuspendedPopup(revoked?: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '500px';
    dialogConfig.data = { revoked };
    if (revoked) {
      this.userDataService.logoutUser();
    }
    this.dialog.closeAll();
    this.dialog.open(AccountSuspendedDialogComponent, dialogConfig);
  }

  public async handleCurrentSession() {
    try {
      await this.userDataService.getSubscription();
      await this.userDataService.getUserNotaryPlan();
      if (this.userDataService.getUserId()) {
        this.oneSignal
          .init({
            appId: '7431a8a8-df06-436e-a140-ea88e8b6d2c1',
            notifyButton: {
              enable: true,
            },
          })
          .then(() => {
            this.oneSignal.setExternalUserId(this.userDataService.getUserId().toString());
          });
      }
    } catch (error) {}
    this.appState = AppState.SESSION_FETCHED;
    this.router.navigateByUrl(this.userDataService.redirectTo); // continue to required path
  }

  private resetPasswordTokenError() {
    this.router.navigate([AppRoutes.login]);
    this.notificationService.showError('Unable to reset password. Please try resetting again.');
    this.appState = AppState.SESSION_FETCHED;
  }
}
