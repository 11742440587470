// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-container {
  max-width: 500px;
}

.current-warehouse {
  padding: 0 20px;
  margin-bottom: 20px;
}

.page-info {
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/change-warehouse/change-warehouse.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".content-container {\n  max-width: 500px;\n}\n\n.current-warehouse {\n  padding: 0 20px;\n  margin-bottom: 20px;\n}\n\n.page-info {\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
