// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host .history-container {
  height: calc(100vh - 62px);
}
:host .history-container.mobile {
  height: calc(100vh - 96px);
  overflow-y: auto;
}
:host .history-container .unbilled-btn-container {
  margin: 10px 20px 0;
}
:host .history-container virtual-scroller {
  border-top: none !important;
}
:host ion-content {
  border-top: 1px solid #ddd;
}
:host .data-table th {
  padding: 20px 10px;
  border-bottom: 1px solid #ddd;
}
:host .data-table td {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/billing-history/billing-history.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,0BAAA;AACJ;AACI;EACE,0BAAA;EACA,gBAAA;AACN;AAEI;EACE,mBAAA;AAAN;AAGI;EACE,2BAAA;AADN;AAKE;EACE,0BAAA;AAHJ;AAOI;EACE,kBAAA;EACA,6BAAA;AALN;AAQI;EACE,aAAA;AANN","sourcesContent":[":host {\n  display: block;\n\n  .history-container {\n    height: calc(100vh - 62px);\n\n    &.mobile {\n      height: calc(100vh - 96px);\n      overflow-y: auto;\n    }\n\n    .unbilled-btn-container {\n      margin: 10px 20px 0;\n    }\n\n    virtual-scroller {\n      border-top: none !important;\n    }\n  }\n\n  ion-content {\n    border-top: 1px solid #ddd;\n  }\n\n  .data-table {\n    th {\n      padding: 20px 10px;\n      border-bottom: 1px solid #ddd;\n    }\n\n    td {\n      padding: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
