import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as Services from '../../services';
import * as SharedHelpers from '../../utils/helpers';

const DOUBLE_CLICK_INTERVAL_THRESHOLD = 800; // milliseconds
const ZOOM_STEP = 1;
const ZOOM_MAX_THRESHOLD = 4;

@Component({
  selector: 'usgm-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfViewerDialogComponent implements OnInit, OnDestroy {
  private touchTime = 0;

  public loading = true;
  public dataBlob;
  public pdfZoomLevel = 1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PdfViewerDialogComponent>,
    protected cdr: ChangeDetectorRef,
    protected http: Services.UsgmHttp,
    protected apiMapping: Services.ApiMapping,
    protected notificationService: Services.NotificationService,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.http.get(this.data.url, { responseType: 'blob', observe: 'response' }).subscribe(
      response => {
        const contentType = response.headers.get('content-type');
        if (contentType === 'application/pdf') {
          this.data.fileType = 'pdf';
          const blob = new Blob([response.body], { type: 'application/pdf' });
          this.dataBlob = URL.createObjectURL(blob);
        } else {
          this.data.fileType = contentType.split('/')[1];
          const reader = new FileReader();
          reader.addEventListener('load', () => (this.dataBlob = reader.result), false);
          if (response) {
            reader.readAsDataURL(response.body);
          }
        }
        setTimeout(() => {
          this.loading = false;
          SharedHelpers.detectChanges(this.cdr);
        }, 3000);
      },
      error => {
        if (error.status === 422) {
          this.dialogRef.close();
          this.data.v2CaptchaCallback();
        } else {
          this.dialogRef.close();
          this.notificationService.showError('Unable to download file. Please try again.');
        }
      },
    );
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  private zoomPdfInOrOut() {
    // increment by step with Round Robin algorithm
    this.pdfZoomLevel = Math.max(1, (this.pdfZoomLevel + ZOOM_STEP) % ZOOM_MAX_THRESHOLD);
  }

  handlePdfClick() {
    if (this.touchTime === 0) {
      // set first click
      this.touchTime = new Date().getTime();
    } else {
      // compare first click to this click and see if they occurred within double click threshold
      if (new Date().getTime() - this.touchTime < DOUBLE_CLICK_INTERVAL_THRESHOLD) {
        // double click occurred
        this.zoomPdfInOrOut();
        this.touchTime = 0;
      } else {
        // not a double click so set as a new first click
        this.touchTime = new Date().getTime();
      }
    }
  }
}
