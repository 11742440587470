// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-row button {
  margin: 8px 8px 8px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/enter-declarations/enter-declarations-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF","sourcesContent":[".button-row button {\n  margin: 8px 8px 8px 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
