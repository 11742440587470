import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'usgm-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradePlanComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<UpgradePlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {}
}
