import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressType } from '@usgm/usgm-payloads-library-front';
import { Subject } from 'rxjs';
import * as Services from '../../services';
import { MatStepper } from '@angular/material/stepper';
import * as SharedHelpers from '../../utils/helpers';
import { MatDialog } from '@angular/material/dialog';
import { renderRelation } from '../../utils/helpers';

@Component({
  selector: 'usgm-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddAddressComponent implements OnInit, OnDestroy {
  protected readonly renderRelation = renderRelation;

  @Input() item;
  @Output() completeStep: EventEmitter<number> = new EventEmitter();
  addressForm: FormGroup;
  public submitted = false;
  loading = false;
  public names = [];
  public addressAdded = false;
  private unSubscriber: Subject<any> = new Subject();
  public address_type = AddressType.BILLING;
  public userData: any;
  public warehouseAddress: any;
  public nameList: any[] = [];
  public activeIndex = 0;
  @Output() parentFun: EventEmitter<number> = new EventEmitter();

  constructor(
    private _formBuilder: FormBuilder,
    protected matStepper: MatStepper,
    protected verifyService: Services.VerifyAccountService,
    protected apiMapping: Services.ApiMapping,
    protected notificationService: Services.NotificationService,
    protected cdr: ChangeDetectorRef,
    protected userDataService: Services.UserDataService,
    public dialog: MatDialog,
  ) {
    this.addressForm = this._formBuilder.group({
      addresses: this._formBuilder.array([]),
    });
  }

  public newAddressFrom(id: string, savedAddress?: any): FormGroup {
    return this._formBuilder.group({
      customer_id: [id, [Validators.required]],
      address_1: [savedAddress?.address_line || '', [Validators.required]],
      address_2: [savedAddress?.address_line_2 || '', []],
      address_3: [savedAddress?.address_line_3 || '', []],
      country: [savedAddress?.country || '', [Validators.required]],
      city: [savedAddress?.city || null, [Validators.required]],
      state: [savedAddress?.state || null, [Validators.required]],
      postal_code: [savedAddress?.postal_code || '', [Validators.required]],
      phone_number: [savedAddress?.phone_number || '', [Validators.required]],
      company_place_of_registration: [savedAddress?.company_place_of_registration || '', [Validators.required]],
      company_type_of_business: [savedAddress?.company_type_of_business || '', [Validators.required]],
      is_same_as_first_address: [savedAddress?.is_same_as_first_address || false],
    });
  }

  get addresses() {
    return this.addressForm.get('addresses') as FormArray;
  }

  public saveSameAddress(flag, index) {
    if (flag) {
      this.addresses.at(index).patchValue({
        address_1: this.addresses.at(0).value['address_1'],
        address_2: this.addresses.at(0).value['address_2'],
        address_3: this.addresses.at(0).value['address_3'],
        country: this.addresses.at(0).value['country'],
        city: this.addresses.at(0).value['city'],
        state: this.addresses.at(0).value['state'],
        postal_code: this.addresses.at(0).value['postal_code'],
        phone_number: this.addresses.at(0).value['phone_number'],
        company_place_of_registration: this.addresses.at(0).value['company_place_of_registration'],
        company_type_of_business: this.addresses.at(0).value['company_type_of_business'],
      });
    } else {
      this.addresses.at(index).patchValue({
        address_1: '',
        address_2: '',
        address_3: '',
        country: '',
        city: null,
        state: null,
        postal_code: '',
        phone_number: '',
        company_place_of_registration: '',
        company_type_of_business: '',
      });
    }
  }

  @Input() set companyNamesList(value) {
    this.nameList = value || [];
    this.getNames();
  }

  public ngOnInit() {}

  public async getNames() {
    this.addresses.clear();
    for (const name of this.nameList) {
      const relatedAddress = await this.userDataService.getCompanyAddress(name.id);
      this.addresses.push(this.newAddressFrom(name.id, relatedAddress['response']));
    }
    SharedHelpers.detectChanges(this.cdr);
  }

  public isFieldInvalid(field: string, index: number) {
    return !this.addresses.at(index)?.get(field)?.valid && this.addresses.at(index)?.get(field)?.touched;
  }

  public displayFieldCss(field: string, index: number) {
    return {
      'has-error': this.isFieldInvalid(field, index),
      'has-feedback': this.isFieldInvalid(field, index),
    };
  }

  public compareByID(o1: any, o2: any) {
    return (o1 || {}).Id === (o2 || {}).Id;
  }

  public selectAddressAtIndex(index): void {
    this.activeIndex = index;
    const stepperElement = document.getElementById('stepper');
    if (stepperElement) {
      stepperElement.scrollIntoView();
    }
    SharedHelpers.detectChanges(this.cdr);
  }

  private mapAddressAttributes(formValues: any) {
    return {
      company_place_of_registration: formValues.company_place_of_registration,
      company_type_of_business: formValues.company_type_of_business,
      name: this.nameList[this.activeIndex].name,
      address_line: formValues.address_1,
      address_line_2: formValues.address_2,
      address_line_3: formValues.address_3,
      city: formValues.city,
      state: formValues.state,
      country: formValues.country,
      postal_code: formValues.postal_code,
      phone_number: formValues.phone_number,
    };
  }

  public onSubmit(ind: number): void {
    if (!this.addresses.at(ind).valid) {
      this.notificationService.showError('Please fill all required fields');
      return;
    }
    this.userDataService.saveCompanyAddress(this.nameList[this.activeIndex].id, this.mapAddressAttributes(this.addresses.at(ind).value)).subscribe(
      res => {
        if (res) {
          this.notificationService.showSuccess('Address saved successfully');
          if (this.activeIndex < this.nameList.length - 1) {
            this.activeIndex++;
            document.querySelector('.inbox-container').scrollTop = 0;
            SharedHelpers.detectChanges(this.cdr);
          } else {
            this.completeStep.emit(1);
            setTimeout(() => {
              document.querySelector('.inbox-container').scrollTop = 0;
              this.parentFun.emit();
              this.addressAdded = false;
              this.activeIndex = 0;
              SharedHelpers.detectChanges(this.cdr);
              this.matStepper.next();
            }, 300);
          }
        }
      },
      (error: any) => {
        this.notificationService.showError(error.error?.message || error.message || 'Failed saving address, please try again');
      },
    );
  }

  ngOnDestroy(): void {
    this.unSubscriber.next({});
    this.unSubscriber.complete();
  }
}
