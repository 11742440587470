import { Injectable } from '@angular/core';
import { UsgmHttp } from './usgm-http.service';
import { MailFolder } from '../models/mail-folder';
import { ListOptions } from '../models/list-options';
import { BaseService } from './base.service';
import { ApiMapping } from './api-mapping.service';
import { MicroserviceType } from '../models/enums/microservices';

@Injectable({ providedIn: 'root' })
export class MailFolderService extends BaseService<MailFolder> {
  constructor(httpClient: UsgmHttp, apiMapping: ApiMapping) {
    super(ApiMapping.MAIL_FOLDERS_ROUTE, httpClient, apiMapping, MicroserviceType.MAIL);
  }

  async getFolders(): Promise<MailFolder[]> {
    const lo = new ListOptions();
    lo.limit = 550;
    const results = await this.getList(lo).toPromise();
    return results.results || [];
  }

  async moveItemsToFolder(ids: number[], folderId: number) {
    const url = this.apiMapping.getMoveToFolder(folderId, this.serviceType);
    return this.httpClient
      .patch(
        url,
        {
          mailIds: ids,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .toPromise();
  }
}
