import { IPaymentVendorBillingAddress, IStripeToken } from '@usgm/usgm-payloads-library-front';

export const USGM_LOGO = './assets/images/logo.svg';
export const USGM_MINI_LOGO = './assets/images/logo-mini.png';
export const FACEBOOK_GRAPH_API_URL = 'https://graph.facebook.com/v3.2';
export const nonTrackableMailTypeUSPSDomestic = ['LARGELETTER', 'LETTER'];
export const nonTrackableMailTypeUSPSInternationl = ['LARGELETTER', 'LETTER', 'PACKAGE'];
export const nonTrackableServiceType = [`endicia_first_class_mail_international`, `endicia_first_class_mail`];
export const REGEX = /xMl3Jk/g;
export const PLUS = '+';
export const REPLACE_VALUE = 'xMl3Jk';
export const PLUS_REGEX = '\\+';
export const GLOBAL_FLAG_REGEX = 'g';
export const ENCRYPTED_HEADER = 'x-headers';
export const ROWS_PER_PAGE = 10;
export const LEGACY_DEACTIVATION_DATE = '1/1/2022';

// TODO: move this to payload library (done with version 1.0.97) , but updating to 1.0.97 breaks the app while deploying app
export enum IssueReportedRequestType {
  SCAN_REQUEST = 'SCAN_REQUEST',
  SHIPMENT_REQUEST = 'SHIPMENT_REQUEST',
  MAIL_REQUEST = 'MAIL_REQUEST',
}

export enum ScheduledAccountCloseStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
}

export interface IPaymentVendorCustomer {
  warehouse_id: number;
  first_name: string;
  last_name: string;
  email: string;
  billing_address?: IPaymentVendorBillingAddress;
  payment_method?: IStripeToken;
}

export interface IPaymentVendorSubscription {
  customer: IPaymentVendorCustomer;
  vendor_customer_id?: string;
  vendor_payment_source_id?: string;
  plan_id?: number;
  term_id?: number;
  plan_unit_price?: number;
  billing_cycles?: any;
  start_date?: number;
  coupon: string;
  addons: any[];
  referrer_code: string;
  wantFreeMonth?: boolean;
}
export const militaryBases: { isoCode: string; name: string }[] = [
  {
    isoCode: 'AP',
    name: 'AP',
  },
  {
    isoCode: 'AE',
    name: 'AE',
  },
  {
    isoCode: 'AA',
    name: 'AA',
  },
];
export const PICKUP_SERVICE_TYPE = 'pickup_warehouse';
export const DEPOSIT_CHECK_NON_TRACKABLE_SERVICE = ['endicia_first_class_mail', 'endicia_first_class_mail_international'];
export const MAX_ATTEMPTS = 3;
export const CAPTCHA_VERSION_V3 = 3;
export const CAPTCHA_VERSION_V2 = 2;
export enum CaptchaType {
  FORM_DOWNLOAD = 'form_download',
  IDS_DOWNLOAD = 'ids_download',
  EXTRACT_DOCUMENT_INFO = 'extract_document_info',
}
export const ACCOUNT_NAMES_HARD_LIMIT = 20;
