// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-yes {
  background-color: #f87e2b;
  color: white;
}

.button-no {
  color: #377bb5;
}`, "",{"version":3,"sources":["webpack://./src/app/inbox/multiple-scan-dialog/multiple-scan-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".button-yes {\n  background-color: #f87e2b;\n  color: white;\n}\n\n.button-no {\n  color: #377bb5;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
