import { MatDialog } from '@angular/material/dialog';
import { IAddressAttributes, UserType } from '@usgm/usgm-payloads-library-front';
import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, retry } from 'rxjs/operators';
import * as Services from '../../../services';
import * as SharedHelpers from '../../../utils/helpers';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OnboardFormResponse, FormDetails, AllForm } from '../../../models/get-notarized.model';
import { AccountStatus, RecordStatus, AddressType } from '@usgm/usgm-payloads-library-front/Enums';
import { UsgmHttp } from '../../../services';
import { PdfViewerDialogComponent } from '../../../components/pdf-viewer-dialog';
import { Relation } from '../../../models/get-notarized.model';
import { CaptchaBaseComponent } from '../../../captcha-base-component/captcha-base-component.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import * as moment from 'moment-timezone';
import { renderRelation } from '../../../utils/helpers';

const DATE_FORMAT = 'MMM DD yyyy';

@Component({
  selector: 'usgm-show-form-1583',
  templateUrl: './show-form-1583.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsOnboardingShowFormComponent extends CaptchaBaseComponent implements OnInit {
  private pmbOpenedDate = null;
  private primaryUserEmail = null;
  public downLoadingPdf = false;
  public typeBusiness = false;
  public typeResidential = false;
  public userName: string;
  public userForm: FormGroup;
  public submitted: Boolean = Boolean(false);
  public loading: Boolean = Boolean(false);
  public cities: any;
  public states: any[] = [];
  public countries: any;
  public warehouseAddress: IAddressAttributes;
  public notarization: Boolean = Boolean(false);
  public currentAddressData: IAddressAttributes;
  public allAddresses: any[] = [];
  public userNamesData: any[] = [];
  public currentNameIndex: number;
  public currentFormData: FormDetails;
  public account_name_on_form: string;
  public applicant_account_name_on_form: string;
  public allDocuments: any[] = [];
  public accountStatus = AccountStatus;
  public allRelativeNames: any[] = [];
  public userDocuments: {
    title: string;
    documents: {
      title: string;
      document_url: string;
      document_type: 'image' | 'pdf';
    }[];
  }[] = [];
  public allForm1583List: AllForm[] = [];
  public boxNumberValue: string;
  public isBusinessPlan: Boolean = Boolean(false);
  public mobileMode: Boolean = Boolean(false);
  public userPDFDocuments: any;
  public spinnerLoad: Boolean = Boolean(false);
  public planType: string;
  public documentLoaded = false;
  public businessDataLoaded = false;
  public allAccountNames: any[] = [];
  @Input() selectedId: number;
  public settingPageLoad = false;
  public firstLoad = true;
  private unSubscriber: Subject<any> = new Subject();
  public primaryDocumentType = null;
  public secondaryDocumentType = null;
  @Input() item;
  @Output() goToNextPage = new EventEmitter();
  @Output() goBackToIdsScreen = new EventEmitter();
  constructor(
    private matDialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    protected verifyService: Services.VerifyAccountService,
    protected apiMapping: Services.ApiMapping,
    public override notificationService: Services.NotificationService,
    protected userDataService: Services.UserDataService,
    private formBuilder: FormBuilder,
    private _addressService: Services.AddressService,
    private http: UsgmHttp,
    public override dialog: MatDialog,
    public override recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
    this.initForm();
  }

  public override ngOnInit() {
    this.isBusinessPlan = true;
    this.loadData();
  }

  async loadData() {
    try {
      await this.getAccountNames();
      await this.getAllForms();
      await this.getMailingAddress();
      await this.getWarehouseAddress();
      await this.getUserFormDetails();
      const subscription: any = await this.getSubscriptions();
      await this.getSelectedPlan(subscription['plan_id']);
      this.pmbOpenedDate = moment(subscription.createdAt).format(DATE_FORMAT) || '';
      this.getAddressData();
      this.setDeliveryAddress();
      await this.getUserBoxNumber();
      await this.setDefaultData();
      await this.displayDocuments();
      this.downloadIDsFirstCall(this.getDocuments.bind(this));
      this.firstLoad = false;
    } catch (error) {
      this.firstLoad = false;
      console.log(error);
    }
  }

  getSubscriptions() {
    return new Promise((resolve, reject) => {
      this.userDataService
        .getUserSubscription()
        .pipe(takeUntil(this.unSubscriber))
        .subscribe(
          response => {
            if (response && response.length) {
              const subscription = response.find(item => this.userDataService.isActiveSubscription(item));
              return resolve(subscription);
            }
          },
          error => {
            return reject(error);
          },
        );
    });
  }

  getSelectedPlan(planId) {
    return new Promise<void>((resolve, reject) => {
      this.userDataService
        .getUserPlanById(planId)
        .pipe(takeUntil(this.unSubscriber))
        .subscribe(
          response => {
            if (response && response.account_names_plan) {
              this.planType = response.account_names_plan.max_names > 0 && response.options.is_business ? `business` : `other`;
            }
            SharedHelpers.detectChanges(this.cdRef);
            return resolve();
          },
          error => {
            return reject(error);
          },
        );
    });
  }

  public downloadPdf(token, captchaVersion, selectedId = null) {
    if (this.downLoadingPdf) {
      return false;
    }
    this.downLoadingPdf = true;
    this.http.get(this.apiMapping.getDownloadPdfForm(selectedId, token, captchaVersion), { responseType: 'arraybuffer' }).subscribe(
      result => {
        SharedHelpers.UnZip(result);
        this.notificationService.showWarning('File downloaded.');
        this.downLoadingPdf = false;
        this.cdRef.detectChanges();
      },
      error => {
        if (error.status === 422) {
          this.verifyCaptcha(this.downloadPdf.bind(this), selectedId);
        } else {
          this.downLoadingPdf = false;
          this.cdRef.detectChanges();
          this.notificationService.showError('Unable to download file. Please try again.');
        }
      },
    );
  }

  initForm() {
    this.userForm = this.formBuilder.group({
      pmb_open_date: [null],
      pmb_close_date: [null],
      delivery_address_line: [null],
      delivery_pmb_number: [null],
      delivery_city: [null],
      delivery_state: [null],
      delivery_postal_code: [null],
      applicant_last_name: [null],
      applicant_first_name: [null],
      applicant_middle_name: [null],
      applicant_phone_number: [null],
      applicant_email: [null],
      applicant_address_line: [null],
      applicant_city: [null],
      applicant_state: [null],
      applicant_postal_code: [null],
      applicant_country: [null],
      company_name: [null],
      company_type_of_business: [null],
      company_address_line: [null],
      company_city: [null],
      company_state: [null],
      company_postal_code: [null],
      company_country: [null],
      company_phone_number: [null],
      company_place_of_registration: [null],
      document_primary_full_name: [null],
      document_primary_id_number: [null],
      document_primary_issuing_entity: [null],
      document_primary_expiration_date: [null],
      document_primary_type: [null],
      document_secondary_full_name: [null],
      document_secondary_address_line: [null],
      document_secondary_city: [null],
      document_secondary_state: [null],
      document_secondary_postal_code: [null],
      document_secondary_country: [null],
      document_secondary_type: [null],
      additional_names_in_form: [null],
      usage_type: [null],
    });
  }

  // convenience getter for easy access to form fields
  get userFormData() {
    return this.userForm.controls;
  }

  async updateName(sendToNextScreen = false) {
    if (!this.allRelativeNames[this.currentNameIndex]) {
      return false;
    }
    if (!this.boxNumberValue) {
      this.notificationService.showError('You are not assigned a box number. Please contact the admin to resolve this issue to proceed further.');
      return false;
    }
    this.submitted = true;
    // stop here if form is invalid
    if (this.userForm.invalid) {
      this.cdRef.detectChanges();
      this.scrollToTop();
      return;
    }
    if (sendToNextScreen) {
      this.loading = true;
    }
    const params = { form_1583_fields: this.userForm.value };

    this.spinnerLoad = true;

    this.verifyService
      .setUserFormData(this.apiMapping.setUserOnboardForm(this.selectedId), params)
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        resp => {
          this.submitted = false;
          this.loading = false;
          this.spinnerLoad = false;
          if (sendToNextScreen) {
            // this.goToNextPage.emit({ page: 'NOTARY_OPTIONS_LISTING' });
            this.goToNextPage.emit({ page: 'NOTARYUSGM' });
          }
          this.cdRef.detectChanges();
        },
        error => {
          this.loading = false;
          this.submitted = false;
          this.notificationService.showError('Unable to save form 1583');
          SharedHelpers.detectChanges(this.cdRef);
        },
      );
  }

  onBack(): void {
    this.goBackToIdsScreen.emit({ page: 'ADD_IDS' });
  }

  scrollToTop(): void {
    const stepperElement = document.getElementById('stepper');
    if (stepperElement) {
      stepperElement.scrollIntoView();
    }
  }

  getMailingAddress() {
    return new Promise<void>((resolve, reject) => {
      this.userDataService
        .getUserMailingAddressById(this.userDataService.getUserId())
        .pipe(takeUntil(this.unSubscriber), retry(3))
        .subscribe(
          async apiResponse => {
            if (apiResponse) {
              this.allAddresses = apiResponse['addresses'];
              this.getAddressData();
              return resolve();
            }
          },
          error => {
            return reject(error);
          },
        );
    });
  }

  getWarehouseAddress() {
    return new Promise<void>((resolve, reject) => {
      if (this.userDataService.getWarehouseAddress()) {
        this.warehouseAddress = this.userDataService.getWarehouseAddress() || {};
      }
      if (!this.warehouseAddress) {
        this.http
          .get(this.apiMapping.getUserAddressesByType(this.userDataService.getUserId(), AddressType.WAREHOUSE))
          .pipe(retry(3))
          .subscribe(
            (data: any) => {
              this.warehouseAddress = data.addresses[0] || {};
              this.userDataService.setWarehouseAddress(this.warehouseAddress);
              return resolve();
            },
            error => {
              return reject(error);
            },
          );
      } else {
        return resolve();
      }
    });
  }

  async getUserBoxNumber(counter = 0) {
    const warehouseBoxNumber = this.userDataService.getWarehouseBoxNumber();
    if (warehouseBoxNumber) {
      this.boxNumberValue = warehouseBoxNumber;
      this.cdRef.detectChanges();
      this.setDeliveryAddress();
    } else {
      if (counter < 3) {
        await this.userDataService.getBoxNumber();
        this.getUserBoxNumber(counter++);
      }
    }
  }

  setDeliveryAddress() {
    if (!this.warehouseAddress?.address_line) {
      return false;
    }
    this.userFormData.pmb_open_date.setValue(this.pmbOpenedDate || '');
    this.userFormData.delivery_address_line.setValue(
      `${this.warehouseAddress.address_line} ${this.warehouseAddress.address_line_2 || ''} ${this.warehouseAddress.address_line_3 || ''}`,
    );
    this.userFormData.delivery_pmb_number.setValue(this.boxNumberValue || '');
    this.userFormData.delivery_city.setValue(this.warehouseAddress.city?.trim() || '');
    this.userFormData.delivery_state.setValue(this.warehouseAddress.state?.trim() || '');
    this.userFormData.delivery_postal_code.setValue(this.warehouseAddress.postal_code?.trim() || '');
    SharedHelpers.detectChanges(this.cdRef);
  }

  getAddressData() {
    const allAddressData = this.allAddresses;
    const maxValueOfId = Math.max(...allAddressData.map(recentRecord => recentRecord.id), 0);
    if (isNaN(maxValueOfId)) {
      this.currentAddressData = allAddressData[allAddressData.length - 1];
    } else {
      this.currentAddressData = allAddressData.find(data => {
        if (data.id === maxValueOfId) {
          return data;
        }
      });
    }
    // if (this.currentAddressData) {
    //   this.setCurrentAddress();
    // }
  }

  async setCurrentAddress() {
    const selectedUser = this.allRelativeNames[this.currentNameIndex];
    let documentsUserId = selectedUser.user.id;
    if ([Relation.COMPANY, Relation.TRUST, Relation.DECEASED].includes(selectedUser.relation?.relationType)) {
      documentsUserId = this.allRelativeNames.find(it => it.relation?.relationType === Relation.PRIMARY)?.user?.id;
    }
    const userDocuments = this.allDocuments.find(it => it.user_id === documentsUserId);
    const primaryDocument = userDocuments?.documents.find(it => it.user_type === UserType.PRIMARY);
    const secondaryDocument = userDocuments?.documents.find(it => it.user_type === UserType.SECONDARY);
    const nameParts = primaryDocument?.document_fields?.name?.trim() ? primaryDocument.document_fields.name.trim().split(' ') : this.currentAddressData.name.split(' ');
    this.userFormData.applicant_first_name.setValue(primaryDocument?.document_fields?.first_name || (nameParts?.length ? nameParts[0] : ''));
    this.userFormData.applicant_middle_name.setValue(primaryDocument?.document_fields?.middle_name || '');
    this.userFormData.applicant_last_name.setValue(primaryDocument?.document_fields?.last_name || (nameParts?.length > 1 ? nameParts.slice(1).join(' ') : ''));
    this.userFormData.applicant_phone_number.setValue(this.currentAddressData?.phone_number?.trim() || '');
    this.userFormData.applicant_email.setValue(this.primaryUserEmail || '');
    this.userFormData.applicant_address_line.setValue(secondaryDocument?.document_address?.address_line?.trim() || this.currentAddressData.address_line);
    this.userFormData.applicant_city.setValue(secondaryDocument?.document_address?.city?.trim() || this.currentAddressData.city);
    this.userFormData.applicant_state.setValue(secondaryDocument?.document_address?.state?.trim() || this.currentAddressData.state);
    this.userFormData.applicant_postal_code.setValue(secondaryDocument?.document_address?.postal_code?.trim() || this.currentAddressData.postal_code);
    this.userFormData.applicant_country.setValue(secondaryDocument?.document_address?.country?.trim() || this.currentAddressData.country);
    this.typeBusiness = [Relation.COMPANY, Relation.TRUST].includes(selectedUser.relation?.relationType);
    this.typeResidential = !this.typeBusiness;
    this.userFormData.usage_type.setValue(this.typeBusiness ? 'business' : 'residential');

    if (this.typeBusiness) {
      const selectUserCompanyAddressResponse = await this.userDataService.getCompanyAddress(selectedUser.user.uuid);
      const selectUserCompanyAddress = selectUserCompanyAddressResponse['response'];
      if (selectUserCompanyAddress) {
        this.userFormData.company_name.setValue(selectedUser.user.name);
        this.userFormData.company_type_of_business.setValue(selectUserCompanyAddress.company_type_of_business);
        this.userFormData.company_address_line.setValue(
          `${selectUserCompanyAddress.address_line} ${selectUserCompanyAddress.address_line_2 || ''} ${selectUserCompanyAddress.address_line_3 || ''}`,
        );
        this.userFormData.company_city.setValue(selectUserCompanyAddress.city);
        this.userFormData.company_state.setValue(selectUserCompanyAddress.state);
        this.userFormData.company_postal_code.setValue(selectUserCompanyAddress.postal_code);
        this.userFormData.company_country.setValue(selectUserCompanyAddress.country);
        this.userFormData.company_phone_number.setValue(selectUserCompanyAddress.phone_number);
        this.userFormData.company_place_of_registration.setValue(selectUserCompanyAddress.company_place_of_registration);
      }
    } else if (selectedUser.relation?.relationType === Relation.DECEASED) {
      this.userFormData.company_name.setValue(selectedUser.user.name);
      this.userFormData.company_type_of_business.setValue('N/A');
      this.userFormData.company_address_line.setValue(secondaryDocument?.document_address?.address_line?.trim() || this.currentAddressData.address_line);
      this.userFormData.company_city.setValue(secondaryDocument?.document_address?.city?.trim() || this.currentAddressData.city);
      this.userFormData.company_state.setValue(secondaryDocument?.document_address?.state?.trim() || this.currentAddressData.state);
      this.userFormData.company_postal_code.setValue(secondaryDocument?.document_address?.postal_code?.trim() || this.currentAddressData.postal_code);
      this.userFormData.company_country.setValue(secondaryDocument?.document_address?.country?.trim() || this.currentAddressData.country);
      this.userFormData.company_phone_number.setValue(this.currentAddressData?.phone_number?.trim() || '');
      this.userFormData.company_place_of_registration.setValue('N/A');
    }
    SharedHelpers.detectChanges(this.cdRef);
    return Promise.resolve();
  }

  getFormTitleName() {
    let selectedUser = this.allRelativeNames[this.currentNameIndex];
    if ([Relation.COMPANY, Relation.TRUST, Relation.DECEASED].includes(selectedUser.relation.relationType)) {
      selectedUser = this.allRelativeNames.find(item => item.relation.relationType === Relation.PRIMARY || item.relation.relationType === Relation.USER);
    }
    return selectedUser;
  }

  getAccountNames() {
    return new Promise<void>((resolve, reject) => {
      this.verifyService
        .getName(this.apiMapping.getAccountNamesWithOnlyStatus())
        .pipe(takeUntil(this.unSubscriber), retry(3))
        .subscribe(
          apiResponse => {
            if (apiResponse) {
              this.allAccountNames = apiResponse.data.accountNames;
              this.sortNamesByRelation(apiResponse.data.accountNames);
              const primaryUser = this.allAccountNames?.find(it => it.relation?.relationType === Relation.PRIMARY);
              this.primaryUserEmail = primaryUser?.user?.email;
              if (this.selectedId) {
                const index = this.userNamesData.findIndex(user => user.user.uuid === this.selectedId);
                if (index !== -1) {
                  this.setNames();
                  SharedHelpers.detectChanges(this.cdRef);
                }
              }
            }
            return resolve();
          },
          error => {
            return reject(error);
          },
        );
    });
  }

  setNames() {
    this.applicant_account_name_on_form = '';
    this.account_name_on_form = '';
    const selectedUser = this.allRelativeNames[this.currentNameIndex];
    this.applicant_account_name_on_form += this.getFormTitleName()?.user?.name;
    this.account_name_on_form += selectedUser?.usertitle;

    const names = [];
    this.userNamesData.filter(name => {
      if (name.priority === selectedUser.priority) {
        if (selectedUser.priority === 1 && name.user.id !== selectedUser.user.id) {
          names.push(name.user.name);
        }
      }
    });
    this.userFormData.additional_names_in_form.setValue(names.join('\n'));

    let documentsUserId = selectedUser.user.id;
    if ([Relation.COMPANY, Relation.TRUST, Relation.DECEASED].includes(selectedUser.relation?.relationType)) {
      documentsUserId = this.allRelativeNames.find(it => it.relation?.relationType === Relation.PRIMARY)?.user?.id;
    }
    const userDocuments = this.allDocuments.find(it => it.user_id === documentsUserId);
    const primaryDocument = userDocuments?.documents.find(it => it.user_type === UserType.PRIMARY);
    const secondaryDocument = userDocuments?.documents.find(it => it.user_type === UserType.SECONDARY);
    this.userFormData.document_primary_full_name.setValue(primaryDocument?.document_fields?.name?.trim() || '');
    this.userFormData.document_primary_id_number.setValue(primaryDocument?.document_fields?.id_number?.trim() || '');
    this.userFormData.document_primary_issuing_entity.setValue(primaryDocument?.document_fields?.issuing_entity?.trim() || '');
    this.userFormData.document_primary_expiration_date.setValue(primaryDocument?.document_fields?.expiration_date?.trim() || '');
    this.userFormData.document_primary_type.setValue(primaryDocument?.document_type);
    this.primaryDocumentType = primaryDocument?.document_type;
    this.userFormData.document_secondary_full_name.setValue(secondaryDocument?.document_address?.name?.trim() || '');
    this.userFormData.document_secondary_address_line.setValue(secondaryDocument?.document_address?.address_line?.trim() || '');
    this.userFormData.document_secondary_city.setValue(secondaryDocument?.document_address?.city?.trim() || '');
    this.userFormData.document_secondary_state.setValue(secondaryDocument?.document_address?.state?.trim() || '');
    this.userFormData.document_secondary_postal_code.setValue(secondaryDocument?.document_address?.postal_code?.trim() || '');
    this.userFormData.document_secondary_country.setValue(secondaryDocument?.document_address?.country?.trim() || '');
    this.userFormData.document_secondary_type.setValue(secondaryDocument?.document_type);
    this.secondaryDocumentType = secondaryDocument?.document_type;

    // this.setCurrentAddress();
    SharedHelpers.detectChanges(this.cdRef);
  }

  async setDefaultData(mobileSubmit = false) {
    const selectedUser = this.allRelativeNames[this.currentNameIndex];
    this.userName = selectedUser.user.name;
    // this.userFormData['applicant_name_on_mails'].setValue(this.applicant_account_name_on_form);
    // this.userFormData['applicant_name'].setValue(this.userName);
    SharedHelpers.detectChanges(this.cdRef);
  }

  async getUserFormDetails() {
    return new Promise<void>((resolve, reject) => {
      this.verifyService
        .getUserFormData(this.apiMapping.getUserOnboardForm(this.selectedId))
        .pipe(takeUntil(this.unSubscriber))
        .subscribe(
          (apiResponse: OnboardFormResponse) => {
            if (apiResponse && apiResponse.form && apiResponse.form.form_1583_fields) {
              this.currentFormData = apiResponse.form.form_1583_fields;
              this.setFormUserDetails();
              this.setFormDetails();
            } else {
              this.businessDataLoaded = true;
              SharedHelpers.detectChanges(this.cdRef);
            }
            SharedHelpers.detectChanges(this.cdRef);
            return resolve();
          },
          _error => {
            this.spinnerLoad = false;
            SharedHelpers.detectChanges(this.cdRef);
            return reject(_error);
          },
        );
    });
  }

  setFormUserDetails() {
    // this.userFormData['applicant_name_on_mails'].setValue(this.currentFormData.applicant_name_on_mails);
    // this.userFormData['applicant_home_address'].setValue(this.currentFormData.applicant_home_address);
    // this.userFormData['applicant_home_city'].setValue(this.currentFormData.applicant_home_city);
    // this.userFormData['applicant_home_zip_code'].setValue(this.currentFormData.applicant_home_zip_code);
    // this.userFormData['applicant_name'].setValue(this.currentFormData.applicant_name);
    // this.userFormData['applicant_phone_number'].setValue(this.currentFormData.applicant_phone_number);
    // this.userFormData['applicant_firm_name'].setValue(this.currentFormData.applicant_firm_name);
    // this.userFormData['delivery_address'].setValue(this.currentFormData.delivery_address);
    // this.userFormData['delivery_address_city'].setValue(this.currentFormData.delivery_address_city);
    // this.userFormData['delivery_address_zip_code'].setValue(this.currentFormData.delivery_address_zip_code);
    // this.userFormData['new_authorized_delivery_name'].setValue(this.currentFormData.new_authorized_delivery_name);
    // this.userFormData['new_authorized_delivery_address'].setValue(this.currentFormData.new_authorized_delivery_address);
    // this.userFormData['new_authorized_delivery_address_city'].setValue(this.currentFormData.new_authorized_delivery_address_city);
    // this.userFormData['new_authorized_delivery_address_zip_code'].setValue(this.currentFormData.new_authorized_delivery_address_zip_code);
    // this.userFormData['business_address'].setValue(this.currentFormData.business_address);
    // this.userFormData['business_city'].setValue(this.currentFormData.business_city);
    // this.userFormData['business_zip_code'].setValue(this.currentFormData.business_zip_code);
    //
    // this.userFormData['signature_of_applicant'].setValue(this.currentFormData.signature_of_applicant);
    // this.userFormData['signature_of_notary_agent'].setValue(this.currentFormData.signature_of_notary_agent);
    //
    // if (this.states.length > 0) {
    //   this.states.filter((statedata: any) => {
    //     if (statedata.Name === this.currentFormData.applicant_home_state) {
    //       this.userFormData['applicant_home_state'].setValue(statedata);
    //     }
    //     if (statedata.Name === this.currentFormData.delivery_address_state) {
    //       this.userFormData['delivery_address_state'].setValue(statedata);
    //     }
    //     if (statedata.Name === this.currentFormData.new_authorized_delivery_address_state) {
    //       this.userFormData['new_authorized_delivery_address_state'].setValue(statedata);
    //     }
    //     if (statedata.Name === this.currentFormData.business_state) {
    //       this.userFormData['business_state'].setValue(statedata);
    //     }
    //   });
    // }
    // this.displayDocuments();
    // SharedHelpers.detectChanges(this.cdRef);
  }

  setFormDetails() {
    // this.userFormData['form_notarize_date'].setValue(this.currentFormData.form_notarize_date);
    // this.userFormData['business_phone_number'].setValue(this.currentFormData.business_phone_number);
    // this.userFormData['business_type'].setValue(this.currentFormData.business_type);
    // this.userFormData['applicant_firm_member_names'].setValue(this.currentFormData.applicant_firm_member_names);
    // this.userFormData['corporation_officers_names_address'].setValue(this.currentFormData.corporation_officers_names_address);
    // this.userFormData['corporation_business_name'].setValue(this.currentFormData.corporation_business_name);
    // this.businessDataLoaded = true;
    // SharedHelpers.detectChanges(this.cdRef);
  }

  sortNamesByRelation(names: any[]) {
    names.map(item => {
      const relation = item.relation.relationType || '';
      if (relation === Relation.PRIMARY || relation === Relation.USER) {
        item.priority = 1;
        item.secondaryPriority = 1;
        item.relation.relationType = Relation.PRIMARY;
      } else if (relation === Relation.MINOR_CHILD && item.is_from_account_verification) {
        item.priority = 1;
        item.secondaryPriority = 2;
      } else {
        item.priority = 2;
        item.secondaryPriority = 4;
      }
    });
    names.sort((a, b) => {
      return a.priority < b.priority ? -1 : a.priority > b.priority ? 1 : a.secondaryPriority < b.secondaryPriority ? -1 : a.secondaryPriority > b.secondaryPriority ? 1 : 0;
    });
    this.userNamesData = names;
    this.validateNames();
  }

  validateNames() {
    const allData = [];
    this.userNamesData.forEach(item => {
      item.title = item.user.name + ' (' + renderRelation(item.relation.relationType) + ')';
      item.usertitle = item.user.name;
      if (item.priority === 1) {
        if (allData.length > 0) {
          const index = allData.findIndex(user => user.priority === 1);
          if (index !== -1) {
            if (allData[index].title !== undefined && allData[index].title !== '') {
              allData[index].title += ', ' + item.user.name + ' (' + item.relation.relationType + ')';
              allData[index].usertitle += ', ' + item.user.name;
            } else {
              allData[index].title = item.user.name + ' (' + renderRelation(item.relation.relationType) + ')';
              allData[index].usertitle = item.user.name;
            }
          } else {
            allData.push(item);
          }
        } else {
          allData.push(item);
        }
      } else {
        allData.push(item);
      }
    });
    if (this.allForm1583List.length > 0) {
      allData.filter((record, index) => {
        const form = this.allForm1583List.find(form1583 => form1583.user_id === record.user.uuid);
        if (form !== undefined && form.form1583 && form.form1583.form_1583_fields) {
          const status = form.form1583.status;
          if (status === RecordStatus.IN_REVIEW) {
            allData[index].status = AccountStatus.FORM_1583_ADDED;
          } else if (status === RecordStatus.APPROVED) {
            allData[index].status = AccountStatus.FORM_1583_APPROVED;
          } else if (status === RecordStatus.REJECTED) {
            allData[index].status = AccountStatus.FORM_1583_REJECTED;
            allData[index].notes = form.form1583.notes;
          }
        }
      });
    }
    this.allRelativeNames = allData;
    this.currentNameIndex = allData.findIndex(it => it.user.id === this.selectedId);
    SharedHelpers.detectChanges(this.cdRef);
  }

  getDocuments(token, captchaVersion) {
    return new Promise<void>((resolve, reject) => {
      this.verifyService
        .getName(this.apiMapping.getIDsDocumentsOfUser(undefined, token, captchaVersion))
        .pipe(takeUntil(this.unSubscriber))
        .subscribe(
          async response => {
            if (response && response.documents) {
              this.allDocuments = response.documents;
              this.setNames();
            }
            await this.setCurrentAddress();
            await this.updateName();
            return resolve();
          },
          error => {
            if (error.status === 422) {
              this.verifyCaptcha(this.getDocuments.bind(this));
            } else {
              return reject(error);
            }
          },
        );
    });
  }

  selectName(index: number, isMobile = false) {
    this.downloadForm1583(this.selectedId.toString(), false);
    SharedHelpers.detectChanges(this.cdRef);
  }

  async displayDocuments() {
    let displayDocs = [];
    let pdfDocs = [];
    if (this.allDocuments.length > 0) {
      const selectedUser = this.allRelativeNames[this.currentNameIndex];
      if (selectedUser && selectedUser.priority && selectedUser.priority === 2) {
        displayDocs = [
          {
            title: selectedUser.title,
            usertitle: selectedUser.usertitle,
            documents: [],
          },
        ];
        pdfDocs = [
          {
            documents: [],
          },
        ];
        const selected = this.allDocuments.find(doc => doc.user_id === selectedUser.user.id);
        if (selected !== undefined) {
          const selectedDocument = selected.documents;
          const userDocs: any[] = selectedDocument.sort((a: any, b: any) => (a.user_type < b.user_type ? -1 : a.user_type > b.user_type ? 1 : 0));
          userDocs.forEach((item, index) => {
            if (item.document_url) {
              const safeUrl = item.document_url;
              const type = SharedHelpers.getFileType(item.document_url);
              displayDocs[0].documents.push({
                document_id: item.id,
                title: index + 1 + '. ',
                document_url: type === 'image' ? safeUrl : item.document_url,
                document_type: type,
                id_type: item.document_type,
                document_category: item.user_type,
              });
              pdfDocs[0].documents.push({
                document_id: item.id,
                document_url: type === 'image' ? safeUrl : item.document_url,
                document_type: type,
                id_type: item.document_type,
                document_category: item.user_type,
              });
            }
          });
        }
      } else {
        const primaryUsers = this.userNamesData.filter(name => name.priority === 1);
        primaryUsers.forEach((user, i) => {
          displayDocs.push({
            title: user.user.name + ' (' + user.relation.relationType + ')',
            usertitle: user.user.name,
            documents: [],
          });
          pdfDocs.push({
            documents: [],
          });
          const docData = this.allDocuments.find(doc => doc.user_id === user.user.id);
          if (docData && docData.documents.length > 0) {
            const userDocs: any[] = docData.documents.sort((a: any, b: any) => (a.user_type < b.user_type ? -1 : a.user_type > b.user_type ? 1 : 0));
            userDocs.forEach((item, index) => {
              if (item.document_url) {
                // const safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(item.document_url);
                const safeUrl = item.document_url;
                const type = SharedHelpers.getFileType(item.document_url);
                displayDocs[i].documents.push({
                  document_id: item.id,
                  title: index + 1 + '. ',
                  usertitle: index + 1 + '. ',
                  document_url: type === 'image' ? safeUrl : item.document_url,
                  document_type: type,
                  id_type: item.document_type,
                  document_category: item.user_type,
                });
                pdfDocs[i].documents.push({
                  document_id: item.id,
                  document_url: type === 'image' ? safeUrl : item.document_url,
                  document_type: type,
                  id_type: item.document_type,
                  document_category: item.user_type,
                });
              }
            });
          }
        });
      }
    }
    this.userDocuments = displayDocs;
    this.userPDFDocuments = pdfDocs;
    this.documentLoaded = true;
    SharedHelpers.detectChanges(this.cdRef);
  }

  downloadIDsFromServer(token, captchaVersion, id) {
    this.dialog.open(PdfViewerDialogComponent, {
      maxWidth: 'calc(100vw - 10px)',
      panelClass: 'no-padding',
      data: {
        url: this.apiMapping.downloadIds(id, token, captchaVersion),
        fileType: `pdf`,
        v2CaptchaCallback: () => this.verifyCaptcha(this.downloadIDsFromServer.bind(this), id),
      },
    });
  }

  getAllForms() {
    return new Promise<void>((resolve, reject) => {
      const userId = this.userDataService.getUserId();
      this.verifyService
        .getUserAllForms(this.apiMapping.getUserAllForm1583(userId))
        .pipe(takeUntil(this.unSubscriber), retry(3))
        .subscribe(
          response => {
            if (response && response.form1583 && response.form1583.length > 0) {
              this.allForm1583List = response.form1583;
            }
            return resolve();
          },
          error => {
            return reject(error);
          },
        );
    });
  }

  downloadForm1583(id: string, downloadOffline = false) {
    this.spinnerLoad = true;
    this.verifyService
      .getUserFormData(this.apiMapping.getUserOnboardForm(id))
      .pipe(takeUntil(this.unSubscriber), retry(2))
      .subscribe(
        (response: OnboardFormResponse) => {
          this.spinnerLoad = false;
          if (response && response.form && response.form.form_url) {
            if (downloadOffline) {
              this.downloadFormsFirstCall(this.downLoadForm.bind(this), response);
            } else {
              this.downloadFormsFirstCall(this.openFormPDF.bind(this), response.form.id);
            }
          }
          this.cdRef.detectChanges();
        },
        _error => {
          this.spinnerLoad = false;
          this.cdRef.detectChanges();
          this.notificationService.showError('Unable to view file.');
        },
      );
  }

  downLoadForm(token, captchaVersion, response) {
    this.http.get(this.apiMapping.downloadForms(response.form.id, token, captchaVersion), { responseType: 'blob' }).subscribe(
      result => {
        const fileName = response.form.form_url.split('/').pop().split('#')[0].split('?')[0];
        SharedHelpers.downloadFile(result, fileName);
        this.notificationService.showWarning('File downloaded.');
        SharedHelpers.detectChanges(this.cdRef);
      },
      error => {
        if (error.status === 422) {
          this.verifyCaptcha(this.downLoadForm.bind(this), response);
        } else {
          this.notificationService.showError('Unable to downloaded form.');
        }
      },
    );
  }

  openFormPDF(token, captchaVersion, id: number) {
    this.spinnerLoad = false;
    this.matDialog.open(PdfViewerDialogComponent, {
      maxWidth: 'calc(100vw - 10px)',
      panelClass: 'no-padding',
      data: { url: this.apiMapping.downloadForms(id, token, captchaVersion), fileType: `pdf`, v2CaptchaCallback: () => this.verifyCaptcha(this.openFormPDF.bind(this), id) },
    });
  }
}
