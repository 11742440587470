import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { RightNavViewOptions } from '../models/constants/shipments-right-nav-options.constant';
import * as Constants from '../utils/constants';
import * as Services from '../services';
import * as SharedHelpers from '../utils/helpers';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../components/dialog';
import { BaseComponent } from '../components/base.component';
import * as Helpers from '../utils/helpers';
import { environment } from '../../environments/environment';
import * as moment from 'moment-timezone';

export enum ShipmentCategoryType {
  SHIPMENT_REQUEST = 'SHIPMENT_REQUEST',
  CHECK_DEPOSIT_REQUEST = 'CHECK_DEPOSIT_REQUEST',
  RETURN_TO_SENDER_REQUEST = 'RETURN_TO_SENDER_REQUEST',
  CUSTOMER_PICKUP_REQUEST = 'CUSTOMER_PICKUP_REQUEST',
}

@Component({
  selector: 'usgm-shipments',
  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.scss', '../inbox/inbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentsComponent extends BaseComponent implements OnInit, OnDestroy {
  private innerHeight = 0;
  private pageSize = Constants.ROWS_PER_PAGE;
  private pullToRefreshEvent: any;
  public shipmentsData: any = {};
  public currentShipment: any = {};
  public selectedTab = 'pending';
  public mobileMode = false;
  public rowsHeight = 591;
  public NavViewOptions = RightNavViewOptions;
  public spinnerLoad = false;
  public rightNavViewOption: any = RightNavViewOptions.DETAILS_PAGE;
  public shipmentTypes = [
    { name: 'ALL', value: '' },
    { name: 'SHIPMENTS', value: 'SHIPMENT_REQUEST' },
    { name: 'CHECK DEPOSIT', value: 'CHECK_DEPOSIT_REQUEST' },
    { name: 'RETURN TO SENDER', value: 'RETURN_TO_SENDER_REQUEST' },
    { name: 'PICKUP AT WAREHOUSE', value: 'CUSTOMER_PICKUP_REQUEST' },
  ];
  public selectedShipmentType = this.shipmentTypes[0];
  public isLegacy = false;
  public legacyShipmentsDate;
  public legacyShipmentsDeactivationDate = Constants.LEGACY_DEACTIVATION_DATE;

  @ViewChild('shipmentDetailsDrawer') shipmentDetailsDrawer: MatSidenav;

  constructor(
    private cdr: ChangeDetectorRef,
    private http: Services.UsgmHttp,
    private apiMapping: Services.ApiMapping,
    private userDataService: Services.UserDataService,
    protected dialog: MatDialog,
    private router: Router,
    private notificationService: Services.NotificationService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
    this.isLegacy = this.userDataService.isLegacyUser();
    this.legacyShipmentsDate = this.userDataService.getLegacyMigrationDate();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDisplayMode();
    setTimeout(() => {
      this.setItemsHeight();
      SharedHelpers.detectChanges(this.cdr);
    }, 500);
  }

  public ngOnInit() {
    this.setPages();
    this.getPendingShipments();
    this.getCompletedShipments();
    this.onResize({});
    this.userDataService.heightUpdated$.subscribe(() => {
      this.onResize({});
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && event.url.indexOf('/shipments?') >= 0) {
        this.shipmentDetailsDrawer.close();
        this.reloadCurrentTab();
      }
    });
  }

  public setupSelectedShipment() {
    if (!this.activatedRoute.snapshot.queryParamMap.get('id')) {
      return;
    }
    const selectedShipmentId = this.activatedRoute.snapshot.queryParamMap.get('id');
    const row = document.getElementById((this.mobileMode ? 'mobile-pending-shipment-' : 'pending-shipment-') + selectedShipmentId);

    if (row) {
      row.click();
      this.router.navigate([this.router.url.split('?')[0]], { queryParams: {} });
    } else {
      if (selectedShipmentId) {
        this.getShipmentById(selectedShipmentId);
      }
    }
  }

  public getShipmentById(id) {
    this.loading = true;
    this.http.get(this.apiMapping.getShipmentById(id)).subscribe(
      (data: any) => {
        this.loading = false;
        this.currentShipment = data.shipments;
        this.shipmentDetailClicked();
        this.router.navigate([this.router.url.split('?')[0]], { queryParams: {} });
        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        this.loading = false;
        this.router.navigate([this.router.url.split('?')[0]], { queryParams: {} });
        SharedHelpers.detectChanges(this.cdr);
        if ((error.status = 400)) {
          this.notificationService.showError(`Requested shipment does not exist`);
        } else {
          this.notificationService.showError('Unable to fetch shipment details. Please try again.');
        }
      },
    );
  }

  public setItemsHeight() {
    this.innerHeight = (document.getElementsByClassName('shipment-drawer')[0] || {})['offsetHeight'];
    if (this.mobileMode) {
      document.querySelector('mat-drawer-container.inbox-drawer-container')['style'].height = this.userDataService.getScreenHeight() + 'px';
      this.rowsHeight = this.userDataService.getScreenHeight() - 194;
    } else {
      this.rowsHeight = this.innerHeight - 184;
    }
    this.rowsHeight -= this.isLegacy ? 22 : 0;
  }

  public setDisplayMode() {
    this.mobileMode = this.userDataService.isMobileMode;
  }

  public setPages() {
    this.shipmentsData.pending_shipments_page = 0;
    this.shipmentsData.get_pending_shipments_page = true;
    this.shipmentsData.pending_shipments = [];
    this.shipmentsData.pending_count_per_page = this.pageSize;
    this.shipmentsData.completed_shipments_page = 0;
    this.shipmentsData.get_completed_shipments_page = true;
    this.shipmentsData.completed_shipments = [];
    this.shipmentsData.completed_count_per_page = this.pageSize;
    this.shipmentsData.shipments = this.shipmentsData.pending_shipments;
  }

  public getPendingShipments() {
    this.startedApiCall();
    this.http.get(this.apiMapping.getPendingShipments(this.shipmentsData.pending_shipments_page, this.pageSize, this.selectedShipmentType.value)).subscribe(
      (data: any) => {
        this.setupShipmentData(data, 'pending');
        this.tabSelected(this.selectedTab);
        SharedHelpers.detectChanges(this.cdr);
        setTimeout(() => {
          this.setupSelectedShipment();
        }, 1000);
      },
      (error: any) => {
        this.completedApiCall();
        if (error.status !== 401 && error.status !== 403) {
          this.notificationService.showError('Unable to fetch shipments. Please try again.');
        }
      },
    );
  }

  public getCompletedShipments() {
    this.startedApiCall();
    this.http.get(this.apiMapping.getCompletedShipments(this.shipmentsData.completed_shipments_page, this.pageSize, this.selectedShipmentType.value)).subscribe(
      (data: any) => {
        this.setupShipmentData(data, 'completed', data.mails_picked_from_warehouse);
        this.tabSelected(this.selectedTab);
        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        this.completedApiCall();
        if (error.status !== 401 && error.status !== 403) {
          this.notificationService.showError('Unable to fetch shipments. Please try again.');
        }
      },
    );
  }

  redirectBasedOnService(serviceName, trackingNumber = null) {
    Helpers.redirectBasedOnService(serviceName, trackingNumber);
  }

  setupShipmentData(data: any, status: string, pickedFromWarehouse: any[] = []) {
    let shipments: any[] = data.shipments;
    shipments = shipments || [];
    shipments.forEach(shipment => {
      const mailTypes = shipment.declaration_data.map(item => item.mail_type);
      const shipmentService = shipment?.service?.service_type || '';
      shipment.trackable = SharedHelpers.checkIfTrackable(shipment?.destination_address?.country || '', mailTypes, shipmentService, shipment?.parcel_info);
      shipment.isoCreatedOn = shipment.created_on;
      shipment.created_on = SharedHelpers.stringToLocaleDate(shipment.created_on);
      shipment.updated_on = SharedHelpers.stringToLocaleDate(shipment.updated_on);
      shipment.requested_ship_date = SharedHelpers.stringToLocaleDate(shipment.requested_ship_date);
      // tslint:disable-next-line: max-line-length
      shipment.is_edit_allowed =
        status === 'pending' &&
        (shipment.category_type === ShipmentCategoryType.SHIPMENT_REQUEST || shipment.category_type === ShipmentCategoryType.CHECK_DEPOSIT_REQUEST) &&
        shipment.isEditable;
      // tslint:disable-next-line: max-line-length
      shipment.has_issue = (shipment.shipment_status || '').indexOf('REJECTED') >= 0;
      shipment.is_cancel_allowed = status === 'pending' && shipment.isEditable && shipment.shipment_status !== 'REJECTED_DECLARATIONS';
      switch (shipment.category_type) {
        case ShipmentCategoryType.RETURN_TO_SENDER_REQUEST:
          shipment.category_type_name = 'Return Request';
          break;
        case ShipmentCategoryType.CHECK_DEPOSIT_REQUEST:
          shipment.category_type_name = 'Deposit Check Request';
          break;
        case ShipmentCategoryType.CUSTOMER_PICKUP_REQUEST:
          shipment.category_type_name = 'Customer Pickup Request';
          break;
        default:
          shipment.category_type_name = 'Shipment';
          break;
      }
      const processedTime = shipment.shipment_status_transition?.find(it => it.status === 'PROCESSED')?.updated_on;
      if (processedTime) {
        shipment.processed_on = processedTime.slice(0, -1) + 'Z';
      }

      shipment.full_address = [
        (shipment.destination_address || {}).address_line,
        (shipment.destination_address || {}).address_line_2,
        (shipment.destination_address || {}).address_line_3,
        (shipment.destination_address || {}).city,
        (shipment.destination_address || {}).state,
        (shipment.destination_address || {}).country,
        (shipment.destination_address || {}).postal_code,
      ]
        .filter(el => !!el)
        .join(', ');
    });
    if (status === 'pending') {
      shipments.forEach(item => {
        this.shipmentsData.pending_shipments.push(item);
      });
      if (shipments.length < this.shipmentsData.pending_count_per_page) {
        this.shipmentsData.get_pending_shipments_page = false;
      }
      let shipmentIssueCount = 0;
      this.shipmentsData.pending_shipments.forEach(shipment => (shipmentIssueCount += shipment.has_issue ? 1 : 0));
      this.userDataService.menuCountUpdated$.next({ name: 'shipments', count: shipmentIssueCount });
    } else if (status === 'completed') {
      shipments.forEach(item => {
        this.shipmentsData.completed_shipments.push(item);
      });
      if (shipments.length < this.shipmentsData.completed_count_per_page) {
        this.shipmentsData.get_completed_shipments_page = false;
      }
    }
    if (pickedFromWarehouse.length) {
      pickedFromWarehouse = pickedFromWarehouse.map(item => {
        return { ...item, pickedFromWarehouse: true };
      });
      this.shipmentsData.completed_shipments = [...this.shipmentsData.completed_shipments, ...pickedFromWarehouse];
    }
    this.shipmentsData.completed_shipments.sort((a, b) => new Date(b.updated_on).getMilliseconds() - new Date(a.updated_on).getMilliseconds());
    this.tabSelected(this.selectedTab);
    this.completedApiCall();
    SharedHelpers.detectChanges(this.cdr);
  }

  public reloadCurrentTab() {
    switch (this.selectedTab) {
      case 'pending':
        this.shipmentsData.pending_shipments = [];
        this.shipmentsData.pending_shipments_page = 0;
        this.shipmentsData.get_pending_shipments_page = true;
        this.getPendingShipments();
        break;
      case 'completed':
        this.shipmentsData.completed_shipments = [];
        this.shipmentsData.completed_shipments_page = 0;
        this.shipmentsData.get_completed_shipments_page = true;
        this.getCompletedShipments();
        break;
    }
  }

  shipmentTypeSelected(newValue) {
    this.selectedShipmentType = newValue;
    this.setPages();
    this.getPendingShipments();
    this.getCompletedShipments();
  }

  public getNextPage() {
    switch (this.selectedTab) {
      case 'pending':
        if ((this.shipmentsData.pending_shipments || []).length === 0) {
          return;
        }
        if (this.shipmentsData.get_pending_shipments_page) {
          this.shipmentsData.pending_shipments_page++;
          this.getPendingShipments();
        } else {
          console.log('All pending pages loaded');
        }
        break;
      case 'completed':
        if ((this.shipmentsData.completed_shipments || []).length === 0) {
          return;
        }
        if (this.shipmentsData.get_completed_shipments_page) {
          this.shipmentsData.completed_shipments_page++;
          this.getCompletedShipments();
        } else {
          console.log('All completed pages loaded');
        }
        break;
      default:
        break;
    }
  }

  public infiniteLoadingEnabled() {
    switch (this.selectedTab) {
      case 'pending':
        return this.shipmentsData.get_pending_shipments_page;
      case 'completed':
        return this.shipmentsData.get_completed_shipments_page;
      default:
        break;
    }
    return false;
  }

  public shipmentDetailClicked() {
    this.rightNavViewOption = RightNavViewOptions.DETAILS_PAGE;
    this.shipmentDetailsDrawer.open();
  }

  public tabSelected(tab: string) {
    this.selectedTab = tab;
    this.shipmentsData.shipments = this.getShipmentsForTab(tab);
    this.shipmentsData.shipments = SharedHelpers.clone(this.shipmentsData.shipments);
    this.userDataService.setScreenForAnalytics(`shipments-${this.selectedTab}`);
    if (this.pullToRefreshEvent) {
      setTimeout(() => this.pullToRefreshEvent.target.complete(), 2000);
    }
  }

  public getShipmentsForTab(tab: string) {
    if (tab === 'legacy_shipments') {
      return [];
    }
    return tab === 'pending' ? this.shipmentsData.pending_shipments : this.shipmentsData.completed_shipments;
  }

  public trackItems(index: number, item: any = {}) {
    return item.id;
  }

  public fetchMore(event) {
    setTimeout(() => {
      event.target.complete(); // hide infinite loader
    }, 2000);
    const shipments = this.getShipmentsForTab(this.selectedTab);
    if (event.endIndex && event.endIndex !== (shipments || []).length - 1) {
      return;
    }
    this.getNextPage();
  }

  public editShipment(event) {
    this.rightNavViewOption = event.category_type === 'CHECK_DEPOSIT_REQUEST' ? RightNavViewOptions.EDIT_DEPOSIT_CHECK_PAGE : RightNavViewOptions.EDIT_SHIPMENT_PAGE;
    this.shipmentDetailsDrawer.open();
  }

  public cancelRequest(shipment) {
    this.startedApiCall();
    this.http.put(this.apiMapping.cancelShipment(shipment.id), {}).subscribe(
      (data: any) => {
        if (data.message.indexOf('cancelled successfully') >= 0) {
          this.notificationService.showSuccess('Request cancelled successfully');
          this.rightNavViewOption = 0;
          this.shipmentDetailsDrawer.close();
          this.reloadCurrentTab();
        } else {
          this.notificationService.showError('Unable to cancel request. Please try again.');
        }
        this.completedApiCall();
        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        this.completedApiCall();
        SharedHelpers.detectChanges(this.cdr);
        this.notificationService.showError(error?.error?.message || 'Unable to cancel request. Please try again.');
      },
    );
  }

  public confirmCancel() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: 'Are you sure you want to cancel this request?',
      title: 'Please confirm!',
      cancelText: 'No',
      confirmText: 'Yes, cancel !',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.shipmentDetailsDrawer.close();
        SharedHelpers.detectChanges(this.cdr);
        this.cancelRequest(this.currentShipment);
      }
    });
  }

  public pullToRefresh(event) {
    this.pullToRefreshEvent = event;
    this.reloadCurrentTab();
  }

  public handleItemSlidingOptionsClick(shipment, event, slidingItem) {
    slidingItem.close();
    this.currentShipment = shipment;
    switch (event) {
      case 'edit':
        this.editShipment(shipment);
        break;
      case 'cancel':
        this.confirmCancel();
        break;
    }
  }

  public async redirectToLegacySystem() {
    if (this.spinnerLoad) {
      return false;
    }
    this.spinnerLoad = true;
    const token: string = await this.userDataService.legacyMigrationGenerateAndReturnToken();
    const legacyURL = `${environment.legacyAppUrl}/membership/redirect/?target_page=shipments&sso_jwt=${token}`;
    this.spinnerLoad = false;
    SharedHelpers.detectChanges(this.cdr);
    window.open(legacyURL, '_system');
  }

  public errorMessageForNoItems() {
    return this.selectedTab === 'pending'
      ? `No ${
          this.selectedShipmentType.value === 'CHECK_DEPOSIT_REQUEST'
            ? 'check deposit'
            : this.selectedShipmentType.value === 'RETURN_TO_SENDER_REQUEST'
              ? 'return to sender'
              : 'shipment'
        } requests!`
      : `No completed ${
          this.selectedShipmentType.value === 'CHECK_DEPOSIT_REQUEST' ? 'check deposit' : this.selectedShipmentType.value === 'RETURN_TO_SENDER_REQUEST' ? 'return' : 'shipment'
        }s!`;
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }
}
