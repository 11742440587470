import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'usgm-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  title: string;
  message: string;
  cancelText: string;
  confirmText: string;
  imageUrl: string;
  okText: string;
  showCloseIcon = false;
  showGreenTitle = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.message = data.message || '';
    this.cancelText = data.cancelText;
    this.confirmText = data.confirmText;
    this.okText = data.okText;
    this.imageUrl = data.imageUrl || '';
    this.showCloseIcon = data.showCloseIcon || false;
    this.showGreenTitle = data.showGreenTitle || false;
  }
}
