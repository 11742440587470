import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { ComponentsModule } from '../components';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';

import { PaymentPageComponent } from './payment-page.component';
import { PaymentsTableComponent } from './payments-table/payments-table.component';
import { PaymentDetailsPopupComponent } from './payment-details-popup/payment-details-popup.component';
import { DirectivesModule } from '../directives';

@NgModule({
  declarations: [PaymentDetailsPopupComponent, PaymentPageComponent, PaymentsTableComponent],
  imports: [
    BrowserModule,
    CommonModule,
    ComponentsModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    VirtualScrollerModule,
    DirectivesModule,
  ],
  exports: [PaymentDetailsPopupComponent, PaymentPageComponent, PaymentsTableComponent],
})
export class PaymentPageModule {}
