import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import * as moment from 'moment';
import * as Services from '../../services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog';
import { mapUserScanBundle } from '../../utils/helpers';
import * as SharedHelpers from '../../utils/helpers';
import { ChangePlanPopupComponent } from '../change-plan-popup/change-plan-popup.component';
import { UserFreeScan } from '../../inbox/scan-options/scan-options.component';

export interface ScanBundle {
  id: number;
  pageCount: number;
  price: number;
  planType: string;
  description?: string;
  usedPageCount?: number;
  expiryDate?: Date | string;
  subscription_status?: string;
}

@Component({
  selector: 'usgm-manage-scan-bundles',
  templateUrl: './manage-scan-bundles.component.html',
  styleUrls: ['./manage-scan-bundles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageScanBundlesComponent implements OnInit, OnDestroy {
  @Output() closeNav = new EventEmitter();
  @Input() scanBundleSupported: boolean;

  public scanBundles: ScanBundle[] = [];
  public userSelectedScanBundle: ScanBundle = null;
  public pageLoading = false;
  public userId: string;
  public cancelScanLoading = false;
  public disableChooseButton = false;
  public scanBundlesNotSupported = false;
  public userFreeScans: UserFreeScan = null;

  constructor(
    private http: Services.UsgmHttp,
    private apiMapping: Services.ApiMapping,
    private userDataService: Services.UserDataService,
    private notificationService: Services.NotificationService,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    this.userId = this.userDataService.getUserId();
    const userFreeScans: any = await this.http.get(this.apiMapping.getUserFreeScans()).toPromise();
    this.userFreeScans = userFreeScans.data?.num_pages
      ? {
          numPages: userFreeScans.data.num_pages,
          validTill: moment(userFreeScans.data.refilled_at).add(1, 'month').format('MM/DD/yyyy'),
        }
      : null;
    const subscriptions = await this.userDataService.getUserSubscription().toPromise();
    const activeSubscription = subscriptions?.find(item => this.userDataService.isActiveSubscription(item));
    if (!activeSubscription) {
      this.notificationService.showError('Can not load subscription details, please reload the page');
      return;
    }
    const plan = await this.userDataService.getUserPlanById(activeSubscription.plan_id).toPromise();
    if (!plan?.other_options?.is_scan_bundles_supported) {
      this.scanBundlesNotSupported = true;
      SharedHelpers.detectChanges(this.cdRef);
    }
    this.getUserScanData();
  }

  async getUserScanData() {
    try {
      this.pageLoading = true;
      const userScanBundle: any = await this.http.get(this.apiMapping.getUserScanBundle()).toPromise();
      this.userSelectedScanBundle = mapUserScanBundle(userScanBundle);
      if (this.scanBundleSupported) {
        const allScanBundles: any = await this.http.get(this.apiMapping.getAllScanBundlePlans()).toPromise();
        const everyScanBundles = (allScanBundles.results || []).map(item => ({
          id: item.id,
          pageCount: item.num_pages,
          price: item.price,
          planType: item.lifetime_days === 365 ? 'year' : 'month',
          description: item.description || `Good way to get started and test out mail scan bundles.`,
        }));

        this.scanBundles = everyScanBundles.filter(item => item.id !== this.userSelectedScanBundle?.id);
      }
      this.pageLoading = false;
      this.cdRef.detectChanges();
    } catch (error) {
      this.pageLoading = false;
      this.notificationService.showError(error?.message || 'Error occured. Please try after sometime');
      this.cdRef.detectChanges();
    }
  }

  public switchToBusinessPlanClicked() {
    this.dialog.open(ChangePlanPopupComponent, {
      width: '500px',
    });
  }

  openBuyConfirmationModal(scanBundle) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      title: `Confirm Scan Bundle Addition`,
      message: `Add ${scanBundle.pageCount} page scan bundle`,
      imageUrl: 'assets/images/Check in circle light.png',
      confirmText: 'OK',
      showCloseIcon: true,
      showGreenTitle: true,
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.buyScanBundle(scanBundle.id);
      }
    });
  }

  openCancelConfirmationModal(scanBundle) {
    if (this.cancelScanLoading) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      title: `Confirm Scan Bundle Cancellation`,
      message: `Bundle will not auto-renew after unused scans are consumed.`,
      imageUrl: 'assets/images/Check in circle light.png',
      confirmText: 'OK',
      showCloseIcon: true,
      showGreenTitle: true,
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.cancelScanBundle(scanBundle.scanBundleId);
      }
    });
  }

  async cancelScanBundle(scanBundleId: number) {
    try {
      this.cancelScanLoading = true;
      this.cdRef.detectChanges();
      await this.http.post(this.apiMapping.cancelScanBundle(scanBundleId), {}).toPromise();
      const userScanBundle: any = await this.http.get(this.apiMapping.getUserScanBundle()).toPromise();
      this.userSelectedScanBundle = mapUserScanBundle(userScanBundle);
      this.cancelScanLoading = false;
      this.notificationService.showSuccess(
        `The Scan Bundle is successfully canceled. You can use the remaining pages till ${this.userSelectedScanBundle.expiryDate}, after which the Scan Bundle will not be auto renewed`,
      );
      this.cdRef.detectChanges();
    } catch (error) {
      this.cancelScanLoading = false;
      this.notificationService.showError(error?.error?.message || 'Error occured. Please try after sometime');
      this.cdRef.detectChanges();
    }
  }

  async buyScanBundle(scanBundleId: number) {
    try {
      this.disableChooseButton = true;
      this.cdRef.detectChanges();
      await this.http.post(this.apiMapping.buyScanBundle(scanBundleId), {}).toPromise();
      const userScanBundle: any = await this.http.get(this.apiMapping.getUserScanBundle()).toPromise();
      this.userSelectedScanBundle = mapUserScanBundle(userScanBundle);
      this.disableChooseButton = false;
      this.notificationService.showSuccess('The Scan Bundle is successfully activated');
      this.cdRef.detectChanges();
    } catch (error) {
      this.disableChooseButton = false;
      this.notificationService.showError(error?.error?.message || 'Error occured. Please try after sometime');
      this.cdRef.detectChanges();
    }
  }

  public closeSideNav() {
    this.closeNav.emit({});
  }

  ngOnDestroy(): void {}
}
