// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  height: 100%;
  position: relative;
}
:host .card-box {
  overflow-y: hidden;
}
:host .close-button {
  position: absolute;
  top: -7px;
  right: 0;
  background-color: #fefefe;
  color: #000;
}
:host .pdf-container {
  width: 100%;
  height: calc(100% - 150px);
  overflow: auto;
  overflow-x: hidden;
  position: relative;
}
:host .pdf-container .show {
  height: 100%;
}
:host .pdf-container .loading {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 58px);
}
:host .footer-bar {
  text-align: center;
  height: 100px;
}
:host .footer-bar button {
  max-width: 250px;
  margin-top: 50px;
}
:host .f-h {
  height: 100%;
}
:host .disabled {
  cursor: none;
  opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/app/resolve-form-error/sign-form-popup/sign-form-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,yBAAA;EACA,WAAA;AAAJ;AAGE;EACE,WAAA;EACA,0BAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;AADJ;AAGI;EACE,YAAA;AADN;AAII;EACE,kBAAA;EACA,qBAAA;EACA,sBAAA;AAFN;AAME;EACE,kBAAA;EACA,aAAA;AAJJ;AAMI;EACE,gBAAA;EACA,gBAAA;AAJN;AAQE;EACE,YAAA;AANJ;AASE;EACE,YAAA;EACA,YAAA;AAPJ","sourcesContent":[":host {\n  display: block;\n  height: 100%;\n  position: relative;\n\n  .card-box {\n    overflow-y: hidden;\n  }\n\n  .close-button {\n    position: absolute;\n    top: -7px;\n    right: 0;\n    background-color: #fefefe;\n    color: #000;\n  }\n\n  .pdf-container {\n    width: 100%;\n    height: calc(100% - 150px);\n    overflow: auto;\n    overflow-x: hidden;\n    position: relative;\n\n    .show {\n      height: 100%;\n    }\n\n    .loading {\n      position: absolute;\n      top: calc(50% - 10px);\n      left: calc(50% - 58px);\n    }\n  }\n\n  .footer-bar {\n    text-align: center;\n    height: 100px;\n\n    button {\n      max-width: 250px;\n      margin-top: 50px;\n    }\n  }\n\n  .f-h {\n    height: 100%;\n  }\n\n  .disabled {\n    cursor: none;\n    opacity: 0.6;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
