import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import * as Services from '../../../services';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'usgm-add-note-popup',
  templateUrl: './add-note-popup.component.html',
  styleUrls: ['./add-note-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNotesPopupComponent implements OnInit, OnDestroy {
  private unSubscriber: Subject<any> = new Subject();
  public loading = true;
  public showErrors = false;
  public notes = '';
  public selectedNoteId = null;
  public notesArray = [];

  @Output() closeNav = new EventEmitter();
  @Output() closeNavAndReloadItems = new EventEmitter();

  private mailId;
  public userId;

  @Input() set items(value: any[]) {
    this.mailId = value;
    this.getNotesForMailItem();
  }

  constructor(
    protected cdr: ChangeDetectorRef,
    protected http: Services.UsgmHttp,
    protected apiMapping: Services.ApiMapping,
    protected notificationService: Services.NotificationService,
    protected userDataService: Services.UserDataService,
  ) {
    this.userId = this.userDataService.getUserId();
  }

  ngOnInit() {}

  public closeSideNavAndReload() {
    this.closeNavAndReloadItems.emit({});
  }

  public closeSideNav() {
    this.closeNav.emit({});
  }

  public selectNoteToEdit(note) {
    this.selectedNoteId = note.id;
    this.notes = note.note;
    this.cdr.detectChanges();
  }

  public clearNotes() {
    this.selectedNoteId = null;
    this.notes = '';
    this.cdr.detectChanges();
  }

  public deleteNote(note) {
    this.loading = true;
    this.cdr.detectChanges();
    this.http.delete(this.apiMapping.DeleteNote(note.id)).subscribe(
      response => {
        this.notificationService.showSuccess(response['message']);
        this.getNotesForMailItem();
        this.loading = false;
        this.cdr.detectChanges();
      },
      error => {
        this.loading = false;
        this.cdr.detectChanges();
        this.notificationService.showError(error?.error?.message || error?.message);
      },
    );
  }

  private addNewNotesForMailItem() {
    this.loading = true;
    this.cdr.detectChanges();
    this.http
      .post(this.apiMapping.AddMailNotes(this.mailId), {
        note: this.notes,
      })
      .subscribe(
        response => {
          this.notificationService.showSuccess(response['message']);
          this.clearNotes();
          this.getNotesForMailItem();
          this.loading = false;
          this.cdr.detectChanges();
        },
        error => {
          this.loading = false;
          this.cdr.detectChanges();
          this.notificationService.showError(error?.error?.message || error?.message);
        },
      );
  }

  private updateNotesForMailItem() {
    this.loading = true;
    this.cdr.detectChanges();
    this.http
      .put(this.apiMapping.EditMailNotes(this.selectedNoteId), {
        notes: this.notes,
      })
      .subscribe(
        response => {
          this.notificationService.showSuccess(response['message']);
          this.clearNotes();
          this.getNotesForMailItem();
          this.loading = false;
          this.cdr.detectChanges();
        },
        error => {
          this.loading = false;
          this.cdr.detectChanges();
          this.notificationService.showError(error?.error?.message || error?.message);
        },
      );
  }

  private getNotesForMailItem() {
    this.loading = true;
    this.cdr.detectChanges();
    this.http
      .get(this.apiMapping.GetMailNotesByMailId(this.mailId, true))
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        response => {
          this.notesArray = (response['data'] || []).map(i => ({ ...i, created_on: moment(i.created_on).format('MMM DD YYYY hh:mm A') }));
          this.loading = false;
          this.cdr.detectChanges();
        },
        error => {
          this.loading = false;
          this.cdr.detectChanges();
          this.notificationService.showError(error?.error?.message || error?.message);
        },
      );
  }

  public submitNotes() {
    if (this.loading || !this.notes) {
      return false;
    }
    if (this.selectedNoteId) {
      this.updateNotesForMailItem();
    } else {
      this.addNewNotesForMailItem();
    }
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.unSubscriber.next({});
    this.unSubscriber.complete();
  }
}
