import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { IonicModule } from '@ionic/angular';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { PdfViewerDialogComponent } from './pdf-viewer-dialog.component';

@NgModule({
  declarations: [PdfViewerDialogComponent],
  imports: [CommonModule, IonicModule, MatDialogModule, MatIconModule, MatInputModule, MatProgressBarModule, PdfViewerModule, PinchZoomModule],
  exports: [PdfViewerDialogComponent],
})
export class PdfViewerDialogModule {}
