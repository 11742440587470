// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host .ta-centered {
  text-align: center;
}
:host .save-button {
  width: 100%;
  background-color: #FB7E16;
  color: white;
  height: 60px;
}
:host .close {
  font-size: 54px;
  margin-right: -14px;
  margin-top: -35px;
  font-weight: 200;
}`, "",{"version":3,"sources":["webpack://./src/app/components/sidebar/edit-label-dialog/edit-label-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,WAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;AAAJ;AAGE;EACE,eAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;AADJ","sourcesContent":[":host {\n  display: block;\n\n  .ta-centered {\n    text-align: center;\n  }\n\n  .save-button {\n    width: 100%;\n    background-color: #FB7E16;\n    color: white;\n    height: 60px;\n  }\n\n  .close {\n    font-size: 54px;\n    margin-right: -14px;\n    margin-top: -35px;\n    font-weight: 200;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
