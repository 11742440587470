/// <reference types="@types/google.maps" />
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as Services from '../../services';
import { AppRoutes } from '../../models/constants/app-routes.constant';
import * as SharedHelpers from '../../utils/helpers';
import { haversineDistance } from '../../utils/helpers';
import { environment } from '../../../environments/environment';
import { ExternalScripts } from '../../services/scripts/script.store';

declare let google: any;

const APPROVED_STATUS = 'APPROVED';
const DEFAULT_SEARCH_RADIUS = 100; // miles
const MAX_SEARCH_RADIUS = 10000; // miles
const WAREHOUSE_MARKER_ICON = '/assets/images/warehouse-marker-icon.svg';
const USER_MARKER_ICON = '/assets/images/map-user-marker-icon.svg';

const COMING_SOON_MESSAGE = 'This address will be available in 2 weeks. We will notify you via email with a coupon for a 10% discount!';
const COMING_SOON_TITLE = 'Request sent!';

@Component({
  selector: 'usgm-select-warehouse-by-address',
  templateUrl: './select-warehouse-by-address.component.html',
  styleUrls: ['./select-warehouse-by-address.component.scss'],
})
export class SelectWarehouseByAddressComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  private window = window;
  public loading = true;
  public noMoreWarehousesToLoad = true;
  public filteredWarehouses: any[] = [];
  public defaultWarehouseAddresses: any = [];
  public defaultWarehousesPlaceholder = [1, 2, 3];
  public reviews = [
    {
      text: 'We lived in China & South Korea, and after we relocated back to the US, we continued using US Global Mail, just because of the convenience & the wonderful service.',
      name: 'Kenneth L',
      time: '8 years',
    },
    {
      text: 'We have had some stumbling blocks in getting our mail and they’ve been there to solve every problem, every time. We couldn’t ask for a better service for our mail and our home address',
      name: 'Expats in Ecuador',
      time: '8 years',
    },
    {
      text: '2 years ago I moved to Bahrain from Houston, TX and needed a mailing service. I chose US Global Mail and they have been absolutely fantastic. Thanks US Global Mail!',
      name: 'Tara',
      time: '8 years',
    },
    {
      text:
        'It’s nice to know when we’re overseas that everything is taken care of and we don’t have to worry about it. I would definitely recommend US Global Mail to anyone looking for a service' +
        'like this.',
      name: 'Emy',
      time: '8 years',
    },
    {
      text: 'I like the fact that they scan the mail and I can see what’s in the mailbox and toss what we don’t want. It’s a great service and very reliable.',
      name: 'Mark',
      time: '8 years',
    },
  ];
  public faqArray = [
    {
      question: `I don't see the city I want my address in`,
      answer:
        'US Global Mail is expanding & updating its address availability every week. We prioritize the cities based on your needs & recommendations. Please let us know' +
        ' <a href="https://www.usglobalmail.com/contact-us/">here</a>, if you don’t see a state/city where you would you like an address in.',
    },
    {
      question: 'Will having a virtual address in a state affect my taxes and residency',
      answer: 'No. Registering a virtual address does not affect your residency and related tax laws.',
    },
    {
      question: 'Can I get multiple addresses',
      answer:
        'Yes, you can sign up for as many addresses as you would like. If you are interested in adding multiple businesses under a single virtual mailbox, check out our business plan on the' +
        ' next page, after you pick an address.',
    },
    {
      question: 'I am state agnostic. Which address should I choose',
      answer:
        'If you don’t care about the state your address is in, we would recommend you select either our Texas or California location. These are processing facilities where we can guarantee a' +
        ' same day to a max of 24 hour turnaround on requests.',
    },
  ];
  private geocoder;
  private warehouses: any[] = [];
  private map: google.maps.Map;
  private lat = 29.749907;
  private lng = -95.358421;
  private mapOptions: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    center: new google.maps.LatLng(this.lat, this.lng),
    zoom: 5,
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT,
    },
  };
  private markerInfoWindow: any;
  private markers = [];
  private searchBox: google.maps.places.SearchBox;
  private searchedPlace: any;
  private searchRadius = DEFAULT_SEARCH_RADIUS;
  private minSearchResultsToDisplay = 0;
  private showSearchedPlaceMarker = false;
  public selectedWarehouse = null;

  constructor(
    private cdr: ChangeDetectorRef,
    public apiMapping: Services.ApiMapping,
    public subscriptionService: Services.SubscriptionService,
    public userDataService: Services.UserDataService,
    public notificationService: Services.NotificationService,
    private scriptService: Services.ScriptService,
    private router: Router,
  ) {}

  public ngOnInit() {
    this.scriptService
      .load(ExternalScripts.ZENDESK)
      .then(data => {
        console.log('Zendesk script dynamically loaded', data);
      })
      .catch(error => console.log(error));

    const target = document.querySelector('#video-container');
    target.addEventListener('click', this.playAndPauseVideo.bind(this), false);
    this.geocoder = new google.maps.Geocoder();
    this.getDefaultWarehouses();
    this.loadWarehouses();
  }

  public ngAfterViewInit() {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.searchBox = new google.maps.places.SearchBox(this.searchInput.nativeElement);
    this.map.addListener('bounds_changed', () => {
      this.searchBox.setBounds(this.map.getBounds() as google.maps.LatLngBounds);
    });
    this.searchBox.addListener('places_changed', () => {
      const places = this.searchBox.getPlaces();
      if (places.length === 0) {
        return;
      }
      this.showSearchedPlaceMarker = false;
      this.startSearchByPlace(places[0]);
    });
  }

  public ngOnDestroy() {
    this.cdr.detach();
    if (document.getElementById('launcher')) {
      document.getElementById('launcher').style.display = 'none';
    }
  }

  private playAndPauseVideo(event) {
    const playButton = document.getElementById('playpause');
    const video = <HTMLVideoElement>document.getElementById('testimonials-video');
    if (playButton.style.display === 'none') {
      playButton.style.display = 'block';
      video.pause();
    } else {
      playButton.style.display = 'none';
      video.play();
    }
  }

  isWarehouseComingSoon(warehouse) {
    const status = warehouse.WarehouseStatus?.status;
    return status !== APPROVED_STATUS;
  }

  private getDefaultWarehouses() {
    this.userDataService
      .getDefaultWarehouses()
      .then(response => {
        this.defaultWarehouseAddresses = response || [];
      })
      .catch(error => {
        this.notificationService.showError(error.message);
      });
  }

  private getWareHouseAddressString(address) {
    let addressString = '';
    addressString = this.appendStringToAddress(addressString, address?.address_line, true);
    addressString = this.appendStringToAddress(addressString, address?.city);
    addressString = this.appendStringToAddress(addressString, address?.state, true);
    addressString = this.appendStringToAddress(addressString, address?.country);
    addressString = this.appendStringToAddress(addressString, address?.postal_code);
    return addressString;
  }

  private appendStringToAddress(addressString, addressProperty, breakLine: boolean = false) {
    let result = addressString;
    if (addressProperty && addressProperty !== '') {
      result = `${addressString}${addressString.length && addressString.substring(addressString.length - 5) !== '<br/>' ? ', ' : ''}${addressProperty}`;
    }
    if (breakLine) {
      result += '<br/>';
    }
    return result;
  }

  private async loadWarehouses() {
    try {
      let warehouses: any = await this.userDataService.getWarehousesByState('');
      warehouses = warehouses?.results || [];
      for (const warehouse of warehouses) {
        warehouse.addressString = this.getWareHouseAddressString(warehouse.Address);
        warehouse.hasAvailableBoxNumbers = !warehouse.occupiedBoxNumbersCount || warehouse.occupiedBoxNumbersCount < warehouse.capacity;
      }
      const warehousesApproved = warehouses
        .filter(warehouse => warehouse.WarehouseStatus?.status === APPROVED_STATUS)
        .sort((a, b) => (a.Address?.name || '').localeCompare(b.Address?.name));
      const warehousesNotApproved = warehouses
        .filter(warehouse => warehouse.WarehouseStatus?.status !== APPROVED_STATUS)
        .sort((a, b) => (a.Address?.name || '').localeCompare(b.Address?.name));
      warehouses = warehousesApproved.concat(warehousesNotApproved);
      this.warehouses = warehouses;
      this.loading = false;
      this.filterWarehouses();
    } catch (error) {
      this.loading = false;
      this.notificationService.showError(`Unable to fetch warehouses' list. Please try again after sometime.`);
    }
  }

  private filterWarehouses() {
    const bounds = new google.maps.LatLngBounds();
    if (!this.searchedPlace) {
      this.filteredWarehouses = [...this.warehouses];
      for (const w of this.filteredWarehouses) {
        w.distance = undefined;
      }
    } else {
      const place = this.searchedPlace;
      const placeLat = place.geometry.location.lat;
      const placeLng = place.geometry.location.lng;
      this.filteredWarehouses = this.warehouses.filter(w => {
        const distanceFromCenter = haversineDistance(
          {
            lat: typeof placeLat === 'function' ? placeLat() : placeLat,
            lng: typeof placeLng === 'function' ? placeLng() : placeLng,
          },
          w.location || {},
        );
        w.distance = Math.ceil(distanceFromCenter);
        return distanceFromCenter <= this.searchRadius;
      });
      this.filteredWarehouses.sort((a, b) => a.distance - b.distance);
      this.noMoreWarehousesToLoad = this.filteredWarehouses.length === this.warehouses.length;
      if (!this.filteredWarehouses.length || this.filteredWarehouses.length < this.minSearchResultsToDisplay) {
        if (this.searchRadius < MAX_SEARCH_RADIUS) {
          return this.loadMoreSearchResults();
        }
        SharedHelpers.detectChanges(this.cdr);
        return;
      }
      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    }
    if (this.filteredWarehouses.length) {
      for (const w of this.filteredWarehouses) {
        bounds.extend(w.location);
      }
    }
    this.map.fitBounds(bounds);
    this.loadMapMarkers();
    SharedHelpers.detectChanges(this.cdr);
  }

  private loadMoreSearchResults() {
    this.searchRadius += DEFAULT_SEARCH_RADIUS;
    this.filterWarehouses();
  }

  private loadMapMarkers() {
    for (const marker of this.markers) {
      marker.setMap(null);
    }
    this.markers = [];
    for (const warehouse of this.filteredWarehouses) {
      if (warehouse?.location?.lat && warehouse?.location?.lng) {
        const onlineMarker = new google.maps.Marker({
          icon: WAREHOUSE_MARKER_ICON,
          position: {
            lat: parseFloat(warehouse.location.lat),
            lng: parseFloat(warehouse?.location?.lng),
          },
        });
        const markInfo = new google.maps.InfoWindow();
        google.maps.event.addListener(
          onlineMarker,
          'click',
          (mk => {
            return () => {
              const content = `<div><div><b>${warehouse.name}, ${warehouse?.Address?.state}</b></div><div>${warehouse?.addressString}</div></div>`;
              markInfo.setContent(content);
              if (this.markerInfoWindow) {
                this.markerInfoWindow.close();
              }
              this.markerInfoWindow = markInfo;
              markInfo.open(this.map, mk);
              this.selectedWarehouse = warehouse;
              const scrollTop = window.scrollY;
              SharedHelpers.detectChanges(this.cdr);
              const element = document.getElementsByClassName('selected-location')[0];
              element.scrollIntoView();
              window.scrollTo({ top: scrollTop });
            };
          })(onlineMarker),
        );
        onlineMarker.setMap(this.map);
        this.markers.push(onlineMarker);
      }
    }

    if (this.searchedPlace && this.showSearchedPlaceMarker) {
      const placeLat = this.searchedPlace.geometry.location.lat;
      const placeLng = this.searchedPlace.geometry.location.lng;
      const searchedPlaceMarker = new google.maps.Marker({
        icon: USER_MARKER_ICON,
        position: {
          lat: parseFloat(typeof placeLat === 'function' ? placeLat() : placeLat),
          lng: parseFloat(typeof placeLng === 'function' ? placeLng() : placeLng),
        },
      });
      searchedPlaceMarker.setMap(this.map);
      this.markers.push(searchedPlaceMarker);
    }
  }

  async selectWarehouse(warehouse, omitValidation: boolean = false) {
    if (!omitValidation && (!warehouse.userEmail || warehouse.error)) {
      warehouse.error = 'Email is required';
      return;
    }

    if (this.isWarehouseComingSoon(warehouse)) {
      try {
        await this.subscriptionService.addSubscriberToComingSoonList(warehouse.userEmail, warehouse.uuid);
        this.notificationService.showTitledMessage(COMING_SOON_TITLE, COMING_SOON_MESSAGE, 10000, 'start');
      } catch (err) {
        this.notificationService.showError(`${COMING_SOON_TITLE}\n${err.message}`);
      }
      return;
    }

    this.router.navigate([AppRoutes.selectPlan], { queryParams: { warehouse_id: warehouse.id, email: warehouse.userEmail } });
  }

  private useCurrentLocation(position) {
    this.showSearchedPlaceMarker = true;
    this.startSearchByPlace({
      geometry: {
        location: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      },
    });
  }

  private startSearchByPlace(place) {
    this.searchedPlace = place;
    this.searchRadius = DEFAULT_SEARCH_RADIUS;
    this.minSearchResultsToDisplay = 0;
    this.filterWarehouses();
  }

  public showError(error) {
    let locationNotAvailableMessage = '';
    switch (error.code) {
      case error.PERMISSION_DENIED:
        locationNotAvailableMessage = 'Geolocation is currently disabled on this machine. Enable geolocation in your settings or use search bar.';
        break;
      case error.POSITION_UNAVAILABLE:
        locationNotAvailableMessage = 'Location information is unavailable.';
        break;
      case error.TIMEOUT:
        locationNotAvailableMessage = 'The request to get user location timed out.';
        break;
      case error.UNKNOWN_ERROR:
        locationNotAvailableMessage = 'An unknown error occurred.';
        break;
    }
    alert(locationNotAvailableMessage);
  }

  public checkAndResetSearchValue(event) {
    if (event.target.value === '') {
      this.resetSearch();
    }
  }

  public resetSearch() {
    this.showSearchedPlaceMarker = false;
    this.searchInput.nativeElement.value = '';
    this.searchedPlace = null;
    this.searchInput.nativeElement.focus();
    this.searchRadius = DEFAULT_SEARCH_RADIUS;
    this.minSearchResultsToDisplay = 0;
    this.filterWarehouses();
  }

  onClickNearBy() {
    this.getUserLocation();
    const stepperElement = document.getElementById('addressesNearYouSection');
    if (stepperElement) {
      stepperElement.scrollIntoView();
    }
  }

  public getUserLocation() {
    const navigatorAPI = this.getNavigator();
    if (navigatorAPI.geolocation) {
      navigatorAPI.geolocation.getCurrentPosition(this.useCurrentLocation.bind(this), this.showError.bind(this), { enableHighAccuracy: true, timeout: 60000 });
    } else {
      this.showError('Geolocation is not supported by this browser.');
    }
  }

  public getNavigator() {
    return navigator;
  }

  public validateEmail(warehouse) {
    warehouse.error = SharedHelpers.emailPattern.test(warehouse.userEmail) ? null : 'Please enter a valid email';
  }

  public selectDefaultAddress(warehouse) {
    if (warehouse.id === 1) {
      window.location.href = `${environment.usglobalMailWordPress}/pricing`;
    } else {
      this.router.navigate([AppRoutes.selectPlan], { queryParams: { warehouse_id: warehouse.id } });
    }
  }

  public loadMoreClickHandler() {
    this.minSearchResultsToDisplay = this.filteredWarehouses.length + 1;
    this.loadMoreSearchResults();
  }
}
