import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import * as Services from '../../services';
import { AppRoutes } from '../../models/constants/app-routes.constant';
import * as SharedHelpers from '../../utils/helpers';
import { environment } from '../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ReferrerModel } from '../../models/referrer-model';
import { SettingNavViewOptions } from '../../models/constants/settings-options.constant';

@Component({
  selector: 'usgm-payment-complete',
  templateUrl: './payment-complete.component.html',
  styleUrls: ['./payment-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCompleteComponent implements OnInit {
  private referrerData: any;
  private hasRetried = false;
  private fetchingReferrerData = false;
  private unsubscribeService: Subject<any> = new Subject();
  public submittingAnswer = false;
  public userBoxNumber: any = '';
  public response = '';
  public warehouseAddress: any;
  public referralCode: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private http: Services.UsgmHttp,
    private router: Router,
    private apiMapping: Services.ApiMapping,
    private userDataService: Services.UserDataService,
    private notificationService: Services.NotificationService,
  ) {}

  public ngOnInit() {
    this.getBoxNumber();
    this.getUserReferrerData();
    this.warehouseAddress = this.userDataService.getWarehouseAddress() || {};
    this.pushGTMEvent();
  }

  public copiedToClipboard(event) {
    this.notificationService.showSuccess('Info copied to clipboard');
  }

  public getAddressText() {
    return `${this.warehouseAddress.address_line} PMB ${this.userBoxNumber},
     ${this.warehouseAddress.city}, ${this.warehouseAddress.state},
     ${this.warehouseAddress.postal_code}${this.warehouseAddress.extended_zip_code ? '-' + this.warehouseAddress.extended_zip_code : ''}`;
  }

  private async pushGTMEvent() {
    const purchaseData = JSON.parse(localStorage.getItem('gtmPurchaseData'));
    const dataLayer = window['dataLayer'];
    dataLayer.push({ ecommerce: null });
    const subscriptions = await this.userDataService.getUserSubscription().toPromise();
    const subscription = subscriptions?.find(it => it.subscription_status === 'active');
    const plan = subscription?.Plan;
    const term = plan?.membership?.items?.find(it => it.term_id === subscription.term_id);
    const price = Number((term?.price * term?.months).toFixed(2));
    const eventPayload: any = {
      event: 'payment_complete',
      ecommerce: {
        currency: 'USD',
        transaction_id: subscription?.vendor_subscription_id,
        value: price,
        coupon: purchaseData?.coupon,
        items: [
          {
            item_name: `${SharedHelpers.snakeToSentenceCase(plan?.name) || 'N/A'} plan`,
            item_brand: purchaseData?.warehouseAddress?.city || 'N/A',
            item_category: purchaseData?.warehouseAddress?.state || 'N/A',
            item_variant: term?.months === 1 ? 'Monthly' : term?.months === 12 ? 'Annual' : `${term?.months} months`,
            price,
            quantity: 1,
          },
        ],
      },
    };
    dataLayer.push(eventPayload);
    console.log(`pushed event ${JSON.stringify(eventPayload)}`);
    localStorage.removeItem('gtmPurchaseData');
  }

  getUserReferrerData() {
    if (this.userDataService.hasUserReferrerData()) {
      this.referrerData = this.userDataService.getUserReferrerData();
      this.setReferralCode();
    } else {
      this.fetchingReferrerData = true;
      this.http
        .get(this.apiMapping.getReferrerByUserId(this.userDataService.getUserId()))
        .pipe(takeUntil(this.unsubscribeService))
        .subscribe(
          (res: ReferrerModel) => {
            if (res) {
              this.referrerData = res;
              this.fetchingReferrerData = false;
              this.userDataService.setUserReferrerData(res);
              this.setReferralCode();
              SharedHelpers.detectChanges(this.cdr);
            }
          },
          (error: any) => {
            this.fetchingReferrerData = false;
            SharedHelpers.detectChanges(this.cdr);
          },
        );
    }
  }

  setReferralCode() {
    if (this.referrerData && this.referrerData.referrer) {
      this.referralCode = this.referrerData.referrer.referral_code;
    }
  }

  public onSubmit() {
    this.submittingAnswer = true;
    this.http.http_post(this.apiMapping.aboutReferrals(), { text: this.response }).then(
      (data: any) => {
        this.submittingAnswer = false;
        // this.notificationService.showSuccess('Welcome to USGM!');
        this.router.navigate([`/${AppRoutes.welcome}`]);
      },
      (error: any) => {
        this.submittingAnswer = false;
        this.notificationService.showError('Unable to submit your answer. Please try again.');
      },
    );
  }

  public getBoxNumber() {
    this.userDataService.getBoxNumber().then(
      data => {
        this.userBoxNumber = this.userDataService.getWarehouseBoxNumber();
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        if (!this.hasRetried) {
          this.getBoxNumber();
          this.hasRetried = true;
        }
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  handleFacebookShare() {
    const sharerUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.getStartedLink()}&quote=${this.shareText()}`;
    window.open(sharerUrl, '_system');
  }

  handleWhatsappShare() {
    const url = this.userDataService.isMobileMode ? 'whatsapp://' : 'https://web.whatsapp.com/';
    const sharerUrl = `${url}send?text=${this.shareText()} ${this.getStartedLink()}`;
    window.open(sharerUrl, '_system');
  }

  shareText() {
    return `US Global Mail makes your postal mail virtual and accessible online 24/7! \nSign up for US Global Mail's Smart Mailbox today and we both will get a complimentary month of service. Use this code at checkout: ${this.referralCode} \nGet started: `;
  }

  handleTwitterShare() {
    const url = this.userDataService.isMobileMode ? 'twitter://' : 'https://twitter.com/';
    const sharerUrl = `${url}share?text=${this.shareText()}&url=${this.getStartedLink()}`;
    window.open(sharerUrl, '_system');
  }

  getEmailLink() {
    return `mailto:?subject=Refferal code&body=${encodeURIComponent(this.shareText() + this.getStartedLink())}`;
  }

  getStartedLink() {
    return `${environment.appUrl}/${AppRoutes.signup}`;
  }

  settingsLink() {
    return `/${AppRoutes.settings}`;
  }

  settingsParams() {
    return { page: SettingNavViewOptions.CHANGE_WAREHOUSE };
  }
}
