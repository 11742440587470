import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as Services from '../../services';
import * as SharedHelpers from '../../utils/helpers';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog';
import { EnableMfaPopupComponent } from '../enable-mfa-popup/enable-mfa-popup.component';
import { CloseAccountPopupComponent } from '../close-account-popup/close-account-popup.component';
import * as moment from 'moment';
import { ScheduledAccountCloseStatus } from '../../utils/constants';
import { ScheduledAccountClosureDialogComponent } from '../../components/scheduled-account-closure-dialog';

@Component({
  selector: 'usgm-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountDetailsComponent implements OnInit, OnDestroy {
  public userData: any = {};
  public loading = false;
  public newEmailAddress = '';
  public oldPassword = '';
  public newPassword = '';
  public newPasswordConfirmation = '';
  public cancelRequest = { reason: '', option: 1, email_option: 1, forward_address: '' };
  public isSocialLogin;
  public addressData: any = {};
  public userPlanInfo: any = {};
  public importantDatesLoading = true;
  public assignedOn: any;
  public paidThrough: any;
  public scheduleClosed: any;
  public boxNumberValue: string;
  public mfaStatus: any = 'loading';
  public accountType = '';
  public scheduledAccountClosureDate = null;

  @Output() planDetailButtonClicked = new EventEmitter();
  @Output() changePlanButtonClicked = new EventEmitter();
  @Output() accountScheduledToBeClosed = new EventEmitter();
  @Output() accountCloseScheduleCancelled = new EventEmitter();
  @Output() closeNav = new EventEmitter();
  @ViewChild('accountOptionsStepper', { static: true }) accountOptionsStepper: MatStepper;

  // private
  private unSubscriber: Subject<any> = new Subject();

  constructor(
    private apiMapping: Services.ApiMapping,
    protected cdr: ChangeDetectorRef,
    private userDataService: Services.UserDataService,
    private notificationService: Services.NotificationService,
    public dialog: MatDialog,
    private http: Services.UsgmHttp,
  ) {
    this.userData = this.userDataService.getDecodedAccessToken(this.userDataService.getAccessToken()) || {};
    this.isSocialLogin = this.userDataService.isSocialLogin();
  }

  ngOnInit() {
    this.addressData = this.userDataService.getWarehouseAddress();
    this.getCustomerImportantDates();
    this.getUserBoxNumber();
    this.fetchMFAStatus();
  }

  fetchMFAStatus() {
    this.userDataService.getAccountDetails().subscribe((resp: any) => {
      this.mfaStatus = (resp && resp['data'] && resp['data']['mfaStatus']) || 'disabled';
      this.accountType = resp && resp['data'] && resp['data']['accountType'] ? resp['data']['accountType'] : '';
      SharedHelpers.detectChanges(this.cdr);
    });
  }

  getUserBoxNumber() {
    const warehouseBoxNumber = this.userDataService.getWarehouseBoxNumber();
    if (warehouseBoxNumber) {
      this.boxNumberValue = warehouseBoxNumber;
    }
  }

  getCustomerImportantDates() {
    const userData = this.userDataService.getDecodedAccessToken(this.userDataService.getAccessToken()) || {};
    if (userData.orgParentId) {
      this.importantDatesLoading = false;
      SharedHelpers.detectChanges(this.cdr);
      return;
    }
    this.userDataService.getCustomerImportantDates(this.userDataService.getUserId()).subscribe(
      res => {
        if (res) {
          this.assignedOn = res['subscriptionActivationDate'];
          this.paidThrough = res['subscriptionEndDate'];
          this.scheduleClosed = res['scheduledCloseDate'];
          this.scheduledAccountClosureDate = res['scheduledAccountClosureDate'];
          this.importantDatesLoading = false;
          SharedHelpers.detectChanges(this.cdr);
        }
      },
      (error: any) => {
        this.importantDatesLoading = false;
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  submitNewEmail() {
    if (this.newEmailAddress === '' || !this.newEmailAddress.match(SharedHelpers.emailPattern)) {
      this.notificationService.showError('Please enter the email address.');
    } else {
      const formDataValue = {
        email: this.newEmailAddress,
        name: this.userData['name'],
        account_claimed_by: this.userDataService.getUserId(),
      };
      this.submitAccountDetails(formDataValue, 'Email');
    }
  }

  submitNewPassword() {
    if (this.oldPassword === '') {
      this.notificationService.showError('Please enter the old password.');
    } else if (this.oldPassword === '' || this.newPassword === '' || this.newPasswordConfirmation === '') {
      this.notificationService.showError('Please enter the password.');
    } else if (this.newPassword.length < 4) {
      this.notificationService.showError('Password must be at least 4 characters');
    } else if (this.newPassword !== this.newPasswordConfirmation) {
      this.notificationService.showError('Confirm password same as password.');
    } else {
      const formDataValue = {
        old_password: this.oldPassword,
        password: this.newPassword,
        email: this.userData['email'],
        name: this.userData['name'],
        account_claimed_by: this.userDataService.getUserId(),
      };
      this.submitAccountDetails(formDataValue, 'Password');
    }
  }

  submitAccountDetails(formDataValue: any, type: string) {
    this.loading = true;
    this.userDataService
      .submitAccountDetails(this.apiMapping.submitAccountDetails(this.userDataService.getUserId()), formDataValue)
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        apiResponse => {
          this.loading = false;
          if (type === 'Email') {
            this.notificationService.showSuccess('Email address is updated successfully');
            this.userData['email'] = this.newEmailAddress;
          } else {
            this.notificationService.showSuccess('Password is updated successfully');
          }
          this.userDataService.refreshSession();
          this.accountOptionsStepper.selectedIndex = 0;
          SharedHelpers.detectChanges(this.cdr);
        },
        (error: any) => {
          this.loading = false;
          this.notificationService.showError(error.error.message);
          SharedHelpers.detectChanges(this.cdr);
        },
      );
  }

  changeUserEmail() {
    this.newEmailAddress = '';
    this.accountOptionsStepper.selectedIndex = 1;
  }

  changeUserPassword() {
    this.newPassword = '';
    this.newPasswordConfirmation = '';
    this.accountOptionsStepper.selectedIndex = 2;
  }

  upgradeAccount() {
    this.accountOptionsStepper.selectedIndex = 3;
  }

  public planDetailClicked() {
    this.planDetailButtonClicked.emit();
  }

  public planChangeClicked() {
    this.changePlanButtonClicked.emit();
  }

  public closeAccountClicked() {
    if (this.scheduledAccountClosureDate) {
      this.openCancelScheduledClosurePopup(this.scheduledAccountClosureDate);
    } else {
      const dialogRef = this.dialog.open(CloseAccountPopupComponent, {
        width: '500px',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.date) {
          this.scheduledAccountClosureDate = result.date;
          this.openCancelScheduledClosurePopup(result.date);
          this.accountScheduledToBeClosed.emit();
        }
      });
    }
  }

  private openCancelScheduledClosurePopup(date) {
    const dialogRef = this.dialog.open(ScheduledAccountClosureDialogComponent, {
      data: {
        title: `Your account is set to close on\n${moment(date).format('DD MMM YYYY')}`,
        message: 'Closed in error? Click below to cancel closing',
        buttonText: 'DO NOT CLOSE MY ACCOUNT',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.cancelScheduledAccountClosure();
      }
    });
  }

  private cancelScheduledAccountClosure() {
    this.loading = true;
    this.http
      .http_post(this.apiMapping.scheduledCloseUserAccount(), {
        status: ScheduledAccountCloseStatus.CANCELLED,
      })
      .then(response => {
        this.loading = false;
        this.scheduledAccountClosureDate = null;
        this.notificationService.showSuccess('Account closing is cancelled successfully');
        this.accountCloseScheduleCancelled.emit();
      })
      .catch(error => {
        this.loading = false;
        this.notificationService.showError(error?.error?.message || error?.message);
      });
  }

  public mfaEnableClicked() {
    this.mfaStatus = 'loading';
    SharedHelpers.detectChanges(this.cdr);
    this.userDataService
      .generateAndSendMFACode(this.userDataService.getUserId())
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        apiResponse => {
          if (apiResponse && apiResponse['data']) {
            const mfaData = this.userDataService.getMFAData();
            this.userDataService.setMFAData({
              ...mfaData,
              mfa_temp_token: apiResponse['data'],
            });
            const dialogRef = this.dialog.open(EnableMfaPopupComponent, {
              width: '500px',
            });
            dialogRef.afterClosed().subscribe(result => {
              this.mfaStatus = result ? 'enabled' : 'disabled';
              SharedHelpers.detectChanges(this.cdr);
            });
            setTimeout(() => this.notificationService.showSuccess('Verification code has been sent to you over email'), 100);
          } else {
            this.notificationService.showError('Something went wrong in generating and sending a verification code');
          }
          SharedHelpers.detectChanges(this.cdr);
        },
        (error: any) => {
          this.mfaStatus = 'disabled';
          this.notificationService.showError(error.error.message);
          SharedHelpers.detectChanges(this.cdr);
        },
      );
  }

  public mfaDisableClicked() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: `You are about to disable 2-Step Authentication. This means that we will not ask for verification codes when you log into your US Global Mail account. Disabling
      2-Step Verification will make your account less secure and is not recommended. Are you sure you want to proceed?`,
      title: 'Turn off 2-Step Verification',
      cancelText: 'No, keep it on',
      confirmText: 'Yes, Disable!',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const oldStatus = this.mfaStatus;
        this.mfaStatus = 'loading';
        SharedHelpers.detectChanges(this.cdr);
        this.userDataService
          .disableMFA()
          .pipe(takeUntil(this.unSubscriber))
          .subscribe(
            apiResponse => {
              this.notificationService.showSuccess('2-Step Verification disabled');
              this.mfaStatus = 'disabled';
              SharedHelpers.detectChanges(this.cdr);
            },
            (error: any) => {
              this.mfaStatus = oldStatus;
              this.notificationService.showError(error.error.message);
              SharedHelpers.detectChanges(this.cdr);
            },
          );
      }
    });
  }

  closeAccount() {
    this.accountOptionsStepper.selectedIndex = 4;
  }

  submitCancelRequest() {
    console.log('submitCancelRequest');
  }

  closeSideNav() {
    this.closeNav.emit({});
  }

  ngOnDestroy() {
    this.unSubscriber.next({});
    this.unSubscriber.complete();
    this.cdr.detach();
  }
}
