import { Injectable } from '@angular/core';
import { UsgmHttp } from './usgm-http.service';
import { ApiMapping } from './api-mapping.service';

@Injectable()
export class SubscriptionService {
  constructor(
    private http: UsgmHttp,
    private apiMapping: ApiMapping,
  ) {}

  addSubscriberToComingSoonList(email: string, warehouseId: number) {
    return this.http.post(this.apiMapping.warehouseSubscription(warehouseId), { email }).toPromise();
  }
}
