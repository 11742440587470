import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountStatus, IPlanAttributes, IPlanItem, IUserSubscriptionAttributes } from '@usgm/usgm-payloads-library-front';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Relation } from '../../models/get-notarized.model';
import { AccountNamesModel } from '../../models/account-names-model';
import * as Services from '../../services';
import * as SharedHelpers from '../../utils/helpers';
import { Animations } from '../../animations/element.animations';
import { renderRelation } from '../../utils/helpers';

const COMMON_PAYMENT_ERROR_MESSAGE = 'Failed to update subscription. Payment failed! Please update the payment method and try again.';

interface PlanSelectionOption {
  id: string;
  name: string;
  plan_id: string;
  term_id: number;
}

@Component({
  selector: 'usgm-app-change-plan-popup',
  templateUrl: './change-plan-popup.component.html',
  styleUrls: ['./change-plan-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: Animations,
})
export class ChangePlanPopupComponent implements OnInit, OnDestroy {
  protected readonly renderRelation = renderRelation;
  private maxNames = 1;
  private currentPlanId: string;
  private currentTermId: number;
  private currentPlan: IPlanAttributes;
  private plans: IPlanAttributes[];
  private unSubscriber: Subject<any> = new Subject();
  public loading = true;
  public shouldManageNames = false;
  public shouldRemoveCompanyName = false;
  public showingManageNames = false;
  public accountNames: AccountNamesModel[];
  public currentTerm: IPlanItem;
  public selectedPlanOptionId = '';
  public selectedPlan: IPlanAttributes;
  public selectedTerm: IPlanItem;
  public selectedAccountNames = {};
  public planSelectionOptions: PlanSelectionOption[] = [];
  public currentWarehouseId;
  public hasActiveScanBundle: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ChangePlanPopupComponent>,
    private userDataService: Services.UserDataService,
    private notificationService: Services.NotificationService,
    private apiMapping: Services.ApiMapping,
    private http: Services.UsgmHttp,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.getSubscriptions();
    this.getSettingsAccountNames();
    this.getUserScanBundles();
    const decodedToken = this.userDataService.getDecodedAccessToken(this.userDataService.getAccessToken()) || {};
    this.currentWarehouseId = decodedToken.warehouseId;
  }

  public getSubscriptions() {
    this.userDataService
      .getUserSubscription()
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        response => {
          if (response && response.length) {
            const subscription: IUserSubscriptionAttributes = response.find(item => this.userDataService.isActiveSubscription(item));
            if (subscription) {
              this.currentPlanId = subscription.plan_id as unknown as string;
              this.currentTermId = subscription.term_id;
              this.getPlans();
            } else {
              this.loading = false;
              SharedHelpers.detectChanges(this.cdr);
            }
          } else {
            this.loading = false;
            SharedHelpers.detectChanges(this.cdr);
          }
        },
        error => {
          this.loading = false;
          SharedHelpers.detectChanges(this.cdr);
        },
      );
  }

  public getPlans() {
    this.http.http_get(this.apiMapping.getPlans(true)).then(
      (data: any) => {
        this.plans = data;
        this.planSelectionOptions = [];
        data.forEach((plan: IPlanAttributes) => {
          if ((plan as any).id === this.currentPlanId) {
            this.currentPlan = plan;
            this.setShouldManageNames();
          }
          plan.membership.items.forEach((membership: IPlanItem) => {
            if ((plan as any).id === this.currentPlanId && membership.term_id === this.currentTermId) {
              this.currentTerm = membership;
            } else {
              this.planSelectionOptions.push({
                id: `${plan.id}-${membership.term_id}`,
                name: this.nameForPlanAndTerm(plan, membership),
                plan_id: plan.id as unknown as string,
                term_id: membership.term_id,
              });
            }
          });
        });
        if (this.currentPlan && this.currentPlan.account_names_plan && this.currentPlan.account_names_plan.max_names) {
          this.maxNames = this.currentPlan.account_names_plan.max_names;
        }
        this.loading = false;
        SharedHelpers.detectChanges(this.cdr);
      },
      (errorJson: any) => {
        console.log(errorJson);
        this.notificationService.showError('Unable to load plan options. Please reload this page and try again');
      },
    );
  }

  public getSettingsAccountNames(clearLoading = false): void {
    this.userDataService
      .getSettingsAccountNames(this.apiMapping.getSettingsAccountNamesOnlyStatus(this.userDataService.getUserId()))
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        (apiResponse: AccountNamesModel[]) => {
          this.accountNames = apiResponse['data']['accountNames'];
          this.userDataService.setAccountNames(this.accountNames);
          if (clearLoading) {
            this.loading = false;
          }
          this.setShouldManageNames();
          this.showingManageNames = this.shouldManageNames;
          SharedHelpers.detectChanges(this.cdr);
        },
        error => {
          this.notificationService.showError(error.message);
        },
      );
  }

  private async getUserScanBundles() {
    const userScanBundle: any = await this.http.get(this.apiMapping.getUserScanBundle()).toPromise();
    if (userScanBundle?.results?.find(it => it.subscription_status === 'ACTIVE')) {
      this.hasActiveScanBundle = true;
      SharedHelpers.detectChanges(this.cdr);
    }
  }

  public removeSelectedChildren() {
    const idsToDelete = [];
    this.accountNames.forEach(accountName => {
      if (this.selectedAccountNames[accountName.user.uuid]) {
        idsToDelete.push(accountName.user.uuid);
      }
    });
    this.loading = true;
    this.http.http_post(this.apiMapping.updateChildUsers(), { delete_child_ids: idsToDelete }).then(
      response => {
        this.getSettingsAccountNames(true);
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.loading = false;
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  public confirmPlanChange() {
    this.loading = true;
    this.http.http_post(this.apiMapping.updateSubscription(), { plan_id: this.selectedPlan.id, term_id: this.selectedTerm.term_id }).then(
      response => {
        this.http.http_post(this.apiMapping.updateChildUsers(), {}).then(
          rsp => {
            this.notificationService.showSuccess('Subscription updated!');
            this.dialogRef.close();
            SharedHelpers.detectChanges(this.cdr);
            window.location.reload();
          },
          error => {
            this.loading = false;
            this.notificationService.showError(error?.error?.message || COMMON_PAYMENT_ERROR_MESSAGE);
            SharedHelpers.detectChanges(this.cdr);
          },
        );
      },
      error => {
        this.loading = false;
        this.notificationService.showError(error?.error?.message || COMMON_PAYMENT_ERROR_MESSAGE);
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  public planSelected(event: any) {
    this.selectedPlan = null;
    this.selectedTerm = null;
    this.planSelectionOptions.forEach(planSelectionOption => {
      if (planSelectionOption.id === this.selectedPlanOptionId) {
        this.plans.forEach(plan => {
          if ((plan as any).uuid === planSelectionOption.plan_id) {
            this.selectedPlan = plan;
            this.selectedPlan.membership.items.forEach(term => {
              if (term.term_id === planSelectionOption.term_id) {
                this.selectedTerm = term;
              }
            });
          }
        });
      }
    });
    this.setShouldManageNames();
  }

  public setShouldManageNames() {
    if (this.selectedPlan) {
      this.shouldManageNames =
        this.accountNames.length > this.selectedPlan.account_names_plan.max_names &&
        this.currentPlan.id !== this.selectedPlan.id &&
        this.selectedPlan.account_names_plan.max_names > 0;
      this.shouldRemoveCompanyName =
        this.accountNames.find(an => [Relation.COMPANY, Relation.TRUST].includes(an.relation?.relationType as Relation)) && !this.selectedPlan.options.is_business;
    } else {
      this.shouldManageNames = false;
    }
    SharedHelpers.detectChanges(this.cdr);
  }

  public statusFromName(accountNames: AccountNamesModel[], accountName: AccountNamesModel) {
    return SharedHelpers.statusFromName(accountNames, accountName);
  }

  public showNameCheckbox(accountName) {
    if (accountName.relation && accountName.relation.relationType === Relation.PRIMARY) {
      return false;
    } else if (accountName.relation && [Relation.COMPANY, Relation.TRUST].includes(accountName.relation.relationType)) {
      return accountName.accountStatus !== AccountStatus.DOCUMENT_PRIMARY_APPROVED;
    } else {
      return accountName.accountStatus !== AccountStatus.FORM_1583_APPROVED;
    }
  }

  public checkIfRejected(status: string) {
    return status && status.toLowerCase().includes('rejected');
  }

  public nameForSelectedPlan() {
    if (this.currentPlan) {
      const selectedTerm = this.currentPlan.membership.items.filter(membership => membership.term_id === this.currentTermId)[0];
      return `${this.nameForPlan(this.currentPlan)} (${this.nameForTerm(selectedTerm)})`;
    }
    return '';
  }

  public pricingForTerm(term: IPlanItem) {
    if (term) {
      return `${term.price}/mo billed ${this.nameForTerm(term, true)}`;
    }
    return '';
  }

  public allowedNamesForPlan() {
    if (this.selectedPlan) {
      const maxNames = this.selectedPlan.account_names_plan.max_names;
      return `${maxNames} name${maxNames > 1 ? 's' : ''}`;
    }
    return '';
  }

  public extraNamesMessageForPlan() {
    if (this.selectedPlan) {
      const extraNames = this.accountNames.length - this.selectedPlan.account_names_plan.max_names;
      if (this.selectedPlan.options['is_pay_as_you_go']) {
        return `Please remove the ${extraNames} extra name${extraNames > 1 ? 's' : ''} on your account, or you will be charged $1/name/month and $5/company/month.`;
      } else {
        return `Please remove the ${extraNames} extra name${extraNames > 1 ? 's' : ''} on your account before switching plan.`;
      }
    }
    return '';
  }

  public keepNames() {
    this.showingManageNames = false;
    this.shouldManageNames = false;
  }

  public isPayAsYouGoPlan() {
    return this.selectedPlan && this.selectedPlan.options['is_pay_as_you_go'];
  }

  public nameForPlanAndTerm(plan: IPlanAttributes, term: IPlanItem) {
    return `${this.nameForPlan(plan)} (${this.nameForTerm(term)})`;
  }

  public nameForPlan(plan: IPlanAttributes) {
    if (plan) {
      return SharedHelpers.snakeToSentenceCase(plan.name);
    }
    return '';
  }

  public nameForTerm(term: IPlanItem, longer = false) {
    if (term) {
      return term.months === 12 ? `Annual${longer ? 'ly' : ''}` : 'Monthly';
    }
    return '';
  }

  public totalChargeForSelectedPlan() {
    if (this.selectedTerm) {
      return `${(this.selectedTerm.price * this.selectedTerm.months).toFixed(2)}`;
    }
    return '';
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.unSubscriber.next({});
    this.unSubscriber.complete();
  }
}
