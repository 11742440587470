// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 480px;
  width: 100vw;
  height: 100%;
  background: #f2f6f7;
}

.blue-text {
  color: #077ABE;
  font-weight: bold;
}

.grey-icon {
  color: #999;
}

.font-size-14 {
  font-size: 14px;
}

.instruction-link {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #077ABE;
}

.cursor-pointer {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/inbox/scan-options/scan-options.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,iBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 480px;\n  width: 100vw;\n  height: 100%;\n  background: #f2f6f7;\n}\n\n.blue-text {\n  color: #077ABE;\n  font-weight: bold;\n}\n\n.grey-icon {\n  color: #999;\n}\n\n.font-size-14 {\n  font-size: 14px;\n}\n\n.instruction-link {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  color: #077ABE;\n}\n\n.cursor-pointer {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
