import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import * as Services from '../services';
import * as SharedHelpers from '../utils/helpers';
import * as Constants from '../utils/constants';
import { Animations } from '../animations/element.animations';
import { AccountStatus } from '@usgm/usgm-payloads-library-front';

@Component({
  selector: 'usgm-discard',
  templateUrl: './discard.component.html',
  styleUrls: ['../inbox/inbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: Animations,
})
export class DiscardComponent implements OnInit, OnDestroy {
  private innerHeight = 0;
  private pageSize = Constants.ROWS_PER_PAGE;
  private pullToRefreshEvent: any;
  private isFetchingAll = false;
  private selectedDiscards = [];
  public loading = false;
  public discardData: any = {};
  public currentItem: any;
  public allSelected = false;
  public mobileMode = false;
  public rowsHeight = 591;

  @ViewChild('rowTable') rowsElement: ElementRef;
  @ViewChild('discardOptionsDrawer') discardOptionsDrawer: MatSidenav;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDisplayMode();
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private http: Services.UsgmHttp,
    private apiMapping: Services.ApiMapping,
    private userDataService: Services.UserDataService,
    private notificationService: Services.NotificationService,
  ) {}

  public setDisplayMode() {
    this.mobileMode = this.userDataService.isMobileMode;
  }

  public setPages() {
    this.discardData.discards_page = 0;
    this.discardData.get_discard_page = true;
    this.discardData.discards = [];
    this.discardData.discards_total = 0;
    this.discardData.discards_count_per_page = this.pageSize;
  }

  public setMailsHeight() {
    this.innerHeight = (document.getElementsByClassName('scan-drawer')[0] || {})['offsetHeight'] || window.innerHeight;
    if (this.mobileMode) {
      document.querySelector('mat-drawer-container.inbox-drawer-container')['style'].height = this.userDataService.getScreenHeight() + 'px';
      this.rowsHeight = this.userDataService.getScreenHeight() - 160;
    } else {
      this.rowsHeight = this.innerHeight - 117;
    }
    SharedHelpers.detectChanges(this.cdr);
  }

  public ngOnInit() {
    this.setDisplayMode();
    this.setPages();
    this.getDiscardData();
  }

  public getDiscardData() {
    this.loading = true;
    const page = this.isFetchingAll ? 0 : this.discardData.discards_page;
    const pageSize = this.isFetchingAll ? this.discardData.discards_total : this.pageSize;
    this.http.get(this.apiMapping.getDiscards(page, pageSize)).subscribe(
      (data: any) => {
        this.discardData.discards = this.isFetchingAll ? [] : this.discardData.discards;
        this.setupItemsObjectForFolder(data);
        this.loading = false;
        this.isFetchingAll = false;
        setTimeout(() => this.setMailsHeight(), 100);
        this.retrieveSelectedDiscards();
        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        this.loading = false;
        this.notificationService.showError('Unable to fetch discard items. Please try again.');
      },
    );
  }

  setupItemsObjectForFolder(data: any) {
    this.setPropertiesForMail(data.mails);
    for (let i = 0; i < (data.mails || []).length; i++) {
      this.discardData.discards.push(data.mails[i]);
    }
    this.discardData.discards_count_per_page = data['count_per_page'];
    if ((data.mails || []).length < this.discardData.discards_count_per_page) {
      this.discardData.get_discard_page = false;
    }
    this.discardData.discards = SharedHelpers.clone(this.discardData.discards);
    this.userDataService.menuCountUpdated$.next({ name: 'discard', count: this.discardData.discards.length });
    this.discardData.discards_total = parseInt(data['total_mails'], 10);
    if (this.pullToRefreshEvent) {
      setTimeout(() => this.pullToRefreshEvent.target.complete(), 2000);
    }
  }

  restoreSelectedDiscards() {
    this.restoreDiscardedItem();
  }

  public handleItemSlidingOptionsClick(item, slidingItem) {
    slidingItem.close();
    item.selected = true;
    this.restoreDiscardedItem();
  }

  pullToRefresh(event) {
    this.pullToRefreshEvent = event;
    this.reloadDiscard();
  }

  restoreDiscardedItem() {
    const itemsToDiscardPostData = { requests: [] };
    (this.discardData.discards || []).forEach(item => {
      if (item['selected']) {
        itemsToDiscardPostData.requests.push({ mail_id: item.id, mail_status: 'DISCARD_MISTAKE' });
      }
    });
    this.loading = true;
    this.http.patch(this.apiMapping.discardMailRequest(), itemsToDiscardPostData).subscribe(
      (data: any) => {
        this.loading = false;
        if (data.message.indexOf('successfully') >= 0) {
          this.notificationService.showSuccess('Restored items!');
        } else {
          this.notificationService.showError(data.message);
        }
        this.reloadDiscard();
        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        console.log(error);
        this.loading = false;
      },
    );
  }

  reloadDiscard() {
    this.closeRightNav();
    this.setPages();
    this.discardData.discards = [];
    this.getDiscardData();
  }

  handleItemClick(item: any) {
    this.currentItem = item;
    this.discardOptionsDrawer.open();
  }

  public getNextPage() {
    const items = this.discardData.discards;
    if ((items || []).length === 0) {
      return;
    }
    if (this.discardData.get_discard_page) {
      this.discardData.discards_page++;
      this.getDiscardData();
    } else {
      console.log('All discard pages loaded');
    }
  }

  public setPropertiesForMail(items: any) {
    (items || []).forEach((mail: any) => {
      SharedHelpers.setPropertiesForMail(mail);
    });
  }

  public handleAllItemSelectionEvent() {
    if (this.isFetchingAll) {
      return;
    }
    const allItemFetchRequired = this.discardData.get_discard_page;
    if (this.allSelected && allItemFetchRequired) {
      this.isFetchingAll = true;
      this.getDiscardData();
    }
  }

  public shouldShowRestoreButton(): boolean {
    if (this.isFetchingAll) {
      return false;
    }
    let shouldShow = false;
    ((this.discardData || { discards: [] }).discards || []).forEach(item => {
      shouldShow = shouldShow || item.selected;
    });
    return shouldShow;
  }

  public itemDetailClicked(item) {
    this.currentItem = item;
  }

  public itemLongPress(item) {
    item.selected = true;
  }

  public toggleAllSelection() {
    this.allSelected = !this.allSelected;
    ((this.discardData || { discards: [] }).discards || []).forEach(item => {
      item.selected = this.allSelected;
    });
    this.saveSelelectedDiscards();
    this.handleAllItemSelectionEvent();
    SharedHelpers.detectChanges(this.cdr);
  }

  public toggleItemSelection(item: any) {
    item.selected = !item.selected;
    this.allSelected = true;
    ((this.discardData || { discards: [] }).discards || []).forEach(currItem => {
      this.allSelected = this.allSelected && currItem['selected'];
    });
    SharedHelpers.detectChanges(this.cdr);
  }

  public trackItems(index, item) {
    return item.id;
  }

  public fetchMore(event) {
    setTimeout(() => {
      event.target.complete(); // hide infinite loader
    }, 2000);
    const items = this.discardData.discards;
    if (event.endIndex && event.endIndex !== (items || []).length - 1) {
      return;
    }
    this.getNextPage();
  }

  public infiniteLoadingEnabled() {
    return this.discardData.get_discard_page;
  }

  public closeRightNav() {
    (this.discardData.items || []).forEach(item => {
      item.selected = false;
    });
    this.allSelected = false;
    this.discardOptionsDrawer.close();
  }

  public saveSelelectedDiscards() {
    this.selectedDiscards = [];
    this.discardData?.discards.filter(item => item.selected).forEach(itemFiltered => this.selectedDiscards.push(itemFiltered.id));
  }

  public retrieveSelectedDiscards() {
    const self = this;
    if (self.selectedDiscards.length && self.discardData?.discards) {
      // If selected items exist
      self.discardData?.discards
        .filter(item => self.selectedDiscards.includes(item.id)) // filter those that are in selected list
        .forEach(x => (x.selected = true)); // set selected
    }
    self.selectedDiscards = [];
  }

  public allActionsDisabled() {
    return this.userDataService.getAccountStatus() === AccountStatus.SUSPENDED;
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }
}
