// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 480px;
  width: 100vw;
  height: 100%;
  background: #f2f6f7;
}
:host .issue-details-container {
  margin: 20px 10px;
  background: white;
  padding: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/issue-detail-popup/issue-detail-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;EACA,iBAAA;EACA,eAAA;AACJ","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 480px;\n  width: 100vw;\n  height: 100%;\n  background: #f2f6f7;\n\n  .issue-details-container {\n    margin: 20px 10px;\n    background: white;\n    padding: 10px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
