import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { IssueRowComponent } from './issue-row.component';

@NgModule({
  declarations: [IssueRowComponent],
  imports: [CommonModule, MatInputModule],
  exports: [IssueRowComponent],
})
export class IssueRowModule {}
