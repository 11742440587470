import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import * as Services from '../../services';
import { CaptchaBaseComponent } from '../../captcha-base-component/captcha-base-component.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { IFormToSign } from '../../models/form-error';
import { SignaturePadPopupComponent } from './signature-pad-popup/signature-pad-popup.component';

@Component({
  selector: 'usgm-sign-form-popup',
  templateUrl: './sign-form-popup.component.html',
  styleUrls: ['./sign-form-popup.component.scss'],
})
export class SignFormPopupComponent extends CaptchaBaseComponent implements OnInit, OnDestroy {
  private unSubscriber: Subject<any> = new Subject();

  public form: IFormToSign;
  public loadingForm = false;
  public submittingSignature = false;
  public formPdfBlob = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    protected cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<SignFormPopupComponent>,
    private form1583Service: Services.Form1583Service,
    public override notificationService: Services.NotificationService,
    private apiMapping: Services.ApiMapping,
    private http: Services.UsgmHttp,
    public override dialog: MatDialog,
    public override recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
    this.form = this.data.form;
  }

  override ngOnInit() {
    this.startLoadingForm();
  }

  private startLoadingForm() {
    this.formPdfBlob = null;
    this.loadingForm = true;
    this.cdRef.detectChanges();
    this.downloadFormsFirstCall(this.downloadFormPDF.bind(this));
  }

  private downloadFormPDF(token, captchaVersion) {
    this.formPdfBlob = null;
    this.loadingForm = true;
    this.cdRef.detectChanges();
    this.http.get(this.apiMapping.downloadForms(this.form.uuid, token, captchaVersion), { responseType: 'blob', observe: 'response' }).subscribe(
      response => {
        const blob = new Blob([response.body], { type: 'application/pdf' });
        this.formPdfBlob = URL.createObjectURL(blob);
        this.loadingForm = false;
        this.cdRef.detectChanges();
      },
      error => {
        this.notificationService.showError('Error downloading Form 1583 PDF file');
        this.loadingForm = false;
        this.cdRef.detectChanges();
      },
    );
  }

  public openSignaturePadPopup() {
    const dialogRef = this.dialog.open(SignaturePadPopupComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result?.signature) {
        this.submittingSignature = true;
        this.dialogRef.disableClose = true;
        this.cdRef.detectChanges();
        this.form1583Service.submitFormSignature(this.form.uuid, { signature: result.signature }).subscribe(
          response => {
            this.dialogRef.close({ signed: true });
            this.notificationService.showSuccess('Form 1583 signature saved')
          },
          error => {
            this.submittingSignature = false;
            this.dialogRef.disableClose = false;
            this.cdRef.detectChanges();
            const defaultErrorMsg = 'Error signing form 1583, please try again or contact us';
            this.notificationService.showError(error?.error?.errors?.[0]?.msg || error?.error?.[0] || error?.error?.message || defaultErrorMsg)
          },
        );
      }
    });
  }

  override ngOnDestroy() {
    this.cdRef.detach();
    this.unSubscriber.next({});
    this.unSubscriber.complete();
  }
}
