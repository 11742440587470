import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ReplaySubject, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateLabelDialogComponent } from './create-label-dialog/create-label-dialog.component';
import { DialogComponent } from '../components/dialog';
import { ApiMapping, UsgmHttp, NotificationService, UserDataService } from '../services';
import * as SharedHelpers from '../utils/helpers';
import * as Constants from '../utils/constants';
import { RightNavViewOptions } from '../models/constants/inbox-right-nav-view-options.constant';
import { MobileItemDetailComponent } from './mobile-item-detail/mobile-item-detail.component';
import { MultipleScanDialogComponent } from './multiple-scan-dialog/multiple-scan-dialog.component';
import { Animations } from '../animations/element.animations';
import { BaseComponent } from '../components/base.component';
import { AccountStatus } from '@usgm/usgm-payloads-library-front/Enums';
import { MailFolderService } from '../services/mail-folders.service';
import { EnterDeclarationsDialogComponent } from '../components/enter-declarations/enter-declarations-dialog.component';
import { EditLabelDialogComponent } from '../components/sidebar';
import { MailFolderEvent } from '../models/mail-folder-event';
import { AppRoutes } from '../models/constants/app-routes.constant';
import { AcceptLookupComponent } from './lookup-dialog/dialog.component';
import { QuarantinePopupComponent } from './quarantine-popup';
import { PdfViewerDialogComponent } from '../components/pdf-viewer-dialog';

@Component({
  selector: 'usgm-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
  animations: Animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxComponent extends BaseComponent implements OnInit, OnDestroy {
  public readonly ROW_HEIGHT = 110;

  public inboxData: any = {};
  public selectedTab = 'inbox';
  public currentItem: any;
  public allSelected = false;
  public imgSrc = Constants.USGM_LOGO;
  public mobileMode = false;
  public userData: any = {};
  public rowsHeight = 591;
  public innerHeight = 0;
  public pageSize = Constants.ROWS_PER_PAGE;
  public selectedItemsForScan: any[] = [];
  public scanPricingDetails: any;
  public selectedItemsForShipping: any[] = [];
  public selectedItemForReportIssue: any[] = [];
  public selectedItemsForUnbox: any[] = [];
  public selectedItemsForDepositCheck: any[] = [];
  public rightNavViewOption = RightNavViewOptions.MAIL;
  public NavViewOptions = RightNavViewOptions;
  public inboxLabels: any[] = [];
  public labelMultiCtrl: FormControl = new FormControl();
  public filteredInboxLabels: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public labelMultiFilterCtrl: FormControl = new FormControl();
  public longPressEventFired = false;
  public tapToSelectMode = false;
  public pullToRefreshEvent: any;
  public itemToBeDiscarded;
  public isFetchingAll = false;
  public folders = [];
  public folderId: number = null;
  public regularSubUserHasNoFolders = false;
  public itemSelectedForNotes = null;
  private _onDestroy = new Subject<void>();
  private queryParams = {};

  @ViewChild('inboxOptionsDrawer') inboxOptionsDrawer: MatSidenav;
  selectedItemId: number[];

  constructor(
    private cdr: ChangeDetectorRef,
    private http: UsgmHttp,
    private apiMapping: ApiMapping,
    private userDataService: UserDataService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private mailFolderService: MailFolderService,
    private router: Router,
  ) {
    super();
    this.inboxLabels = [];
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDisplayMode();
    setTimeout(() => {
      this.setMailsHeight();
      SharedHelpers.detectChanges(this.cdr);
    }, 500);
  }

  public setDisplayMode() {
    this.mobileMode = this.userDataService.isMobileMode;
  }

  public setPages() {
    this.inboxData.inbox = [];
    this.inboxData.inbox_total = 0;
    this.inboxData.inbox_page = 0;
    this.inboxData.inbox_unread_ids = [];
    this.inboxData.inbox_count_per_page = this.pageSize;
    this.inboxData.get_inbox_page = true;
    this.inboxData.quarantine_items = [];
    this.inboxData.quarantine_total = 0;
    this.inboxData.quarantine_page = 0;
    this.inboxData.quarantine_unread_ids = [];
    this.inboxData.quarantine_count_per_page = this.pageSize;
    this.inboxData.get_quarantine_page = true;
  }

  public openQuarantinePopup(event) {
    event.stopPropagation();
    this.dialog.open(QuarantinePopupComponent);
  }

  public setMailsHeight() {
    this.innerHeight = (document.getElementsByClassName('scan-drawer')[0] || {})['offsetHeight'] || window.innerHeight;
    if (this.mobileMode) {
      document.querySelector('mat-drawer-container.inbox-drawer-container')['style'].height = this.userDataService.getScreenHeight() + 'px';
      this.rowsHeight = this.userDataService.getScreenHeight() - 206;
      if (document.querySelector('.mobile-weight-text')) {
        this.rowsHeight -= document.querySelector('.mobile-weight-text').clientHeight;
      } else {
        this.rowsHeight -= 42;
      }
    } else {
      this.rowsHeight = this.innerHeight - 160;
    }
  }

  public async ngOnInit() {
    this.userData = this.userDataService.getDecodedAccessToken(this.userDataService.getAccessToken()) || {};
    this.onResize({});
    this.userDataService.heightUpdated$.subscribe(() => {
      this.onResize({});
    });
    this.setupMultiSearch();
    this.activatedRoute.queryParams.subscribe(async params => {
      this.folderId = null;
      if (params['folderId']) {
        this.folderId = parseInt(params['folderId'], 10);
      }
      if (!this.folderId && this.userData?.orgRole === 'REGULAR') {
        const folders = await this.userDataService.getUserFolders(false);
        if (folders?.length && folders[0]) {
          return this.router.navigate([AppRoutes.inbox], { queryParams: { folderId: folders[0]['id'] } });
        } else {
          this.regularSubUserHasNoFolders = true;
        }
      }
      SharedHelpers.detectChanges(this.cdr);
      this.reloadInbox();
    });
    this.getScanPricingDetails();
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['inboxId'] || params['tab'] || params['quarantineId']) {
        this.queryParams = params;
        this.handleQueryParams();
      }
    });

    this.updateFolders(false);
    this.userDataService.foldersUpdate.subscribe(folders => {
      this.folders = folders;
    });
  }

  public async updateFolders(force: boolean = true) {
    this.folders = await this.userDataService.getUserFolders(force);
  }

  getAllButCurrentFolder() {
    return this.folders.filter(folder => folder.id !== this.folderId);
  }

  public async onAddFolderAndMove({ mailsIds }: MailFolderEvent = { mailsIds: null }) {
    const dialogRef = this.dialog.open(EditLabelDialogComponent, {
      width: '400px',
      data: { data: {}, calloutOption: {} },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.item && result.item.id) {
        this.updateFolders(true);
        this.moveItemsToFolder(result.item.id, mailsIds);
      }
    });
  }

  public async moveItemsToFolder(folderId: number, mailsIds: number[] = null) {
    let ids = mailsIds;
    if (!ids) {
      ids = (this.inboxData.items || []).filter(item => item['selected']).map(item => item['id']);
    }
    if (!ids?.length) {
      return this.notificationService.showError('Please select mail items first');
    }
    await this.mailFolderService.moveItemsToFolder(ids, folderId || -1);
    this.selectedItemsForShipping = [];
    const dialogConfig = new MatDialogConfig();
    const itemStr = ids.length > 1 ? 'Items' : 'Item';
    let title;
    let message = '';
    if (folderId) {
      const folderName = this.folders.find(folder => folder.id === folderId).name;
      title = `${itemStr} moved to ${folderName} folder`;
    } else {
      const folderName = this.folders.find(folder => this.folderId === folder.id).name;
      title = `${itemStr} removed from ${folderName} folder`;
      message = 'Moved back to Inbox';
    }
    dialogConfig.data = {
      id: 3,
      title,
      message: message,
      okText: 'OK',
      imageUrl: 'assets/images/Check in circle light.png',
    };
    this.dialog.open(DialogComponent, dialogConfig);

    this.setPages();
    SharedHelpers.detectChanges(this.cdr);
    if (this.selectedTab === 'inbox') {
      this.getInboxData();
    } else {
      this.getQuarantineData();
    }
    SharedHelpers.detectChanges(this.cdr);
  }

  public setupMultiSearch() {
    this.filteredInboxLabels.next(this.inboxLabels.slice());
    this.labelMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterLabels();
    });
  }

  public getInboxData() {
    this.startedApiCall();
    this.http
      .get(
        this.apiMapping.getInbox(
          this.isFetchingAll ? 0 : this.inboxData.inbox_page,
          this.isFetchingAll ? this.inboxData.inbox_total : this.pageSize,
          this.folderId ? this.folderId : -1,
        ),
      )
      .subscribe(
        (data: any) => {
          this.inboxData.inbox = this.isFetchingAll ? [] : this.inboxData.inbox;
          this.setupItemsObjectForFolder(data, true);
          this.selectTab(this.selectedTab);
          this.isFetchingAll = false;
          SharedHelpers.detectChanges(this.cdr);
          this.retrieveSelected();
        },
        (error: any) => {
          this.completedApiCall();
          this.isFetchingAll = false;
          if (error.status !== 401 && error.status !== 403) {
            this.notificationService.showError('Unable to fetch inbox. Please try again.');
          }
        },
      );
  }

  public getQuarantineData() {
    this.startedApiCall();
    const page = this.isFetchingAll ? 0 : this.inboxData.quarantine_page;
    const count = this.isFetchingAll ? this.inboxData.quarantine_total : this.pageSize;
    this.http.get(this.apiMapping.getQuarantine(page, count)).subscribe(
      (data: any) => {
        this.inboxData.quarantine_items = this.isFetchingAll ? [] : this.inboxData.quarantine_items;
        this.setupItemsObjectForFolder(data, false);
        this.selectTab(this.selectedTab);
        this.isFetchingAll = false;
        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        this.completedApiCall();
        this.isFetchingAll = false;
        if (error.status !== 401 && error.status !== 403) {
          this.notificationService.showError('Unable to fetch quarantine. Please try again.');
        }
      },
    );
  }

  public getScanPricingDetails() {
    this.startedApiCall();
    this.http.get(this.apiMapping.getScanPricing()).subscribe(
      (data: any) => {
        this.completedApiCall();
        this.scanPricingDetails = data['pricing'];
        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        this.completedApiCall();
        console.log(error);
      },
    );
  }

  public confirmSelectedItemsDiscard() {
    const itemsToDiscardPostData = { requests: [] };
    (this.inboxData.items || []).forEach(item => {
      if (item['selected'] || (this.itemToBeDiscarded && this.itemToBeDiscarded.id === item.id)) {
        itemsToDiscardPostData.requests.push({ mail_id: item.id, mail_status: 'DISCARD_REQUEST' });
      }
    });
    this.startedApiCall();
    SharedHelpers.detectChanges(this.cdr);
    this.http.patch(this.apiMapping.discardMailRequest(), itemsToDiscardPostData).subscribe(
      (data: any) => {
        for (let i = 0; i < this.inboxData.items.length; i++) {
          const item = this.inboxData.items[i];
          if (item['selected'] || (this.itemToBeDiscarded && this.itemToBeDiscarded.id === item.id)) {
            if (!item.is_read) {
              this.inboxData.inbox_unread_count = parseInt(`${this.inboxData.inbox_unread_count}`, 10) - 1;
              this.userDataService.menuCountUpdated$.next({ name: 'inbox', count: this.inboxData.inbox_unread_count });
            }
            this.inboxData[this.selectedTab === 'inbox' ? 'inbox' : 'quarantine_items'].splice(i, 1);
            i--;
          }
        }
        this.tabSelected(this.selectedTab);
        this.completedApiCall();
        this.notificationService.showSuccess('Discarded selected items!');
        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        this.completedApiCall();
        SharedHelpers.detectChanges(this.cdr);
        this.notificationService.showError('Unable to discard selected items. Please try again.');
      },
    );
  }

  public setLabelsForSelectedItems() {
    console.log('setLabelsForSelectedItems');
  }

  public setupItemsObjectForFolder(data: any, isInbox: boolean) {
    this.setPropertiesForMail(data.mails);
    if (isInbox) {
      for (let i = 0; i < (data.mails || []).length; i++) {
        this.inboxData.inbox.push(data.mails[i]);
        if (!data.mails[i]['is_read']) {
          this.inboxData.inbox_unread_ids.push(data.mails[i]['id']);
        }
      }
      this.inboxData.inbox_unread_count = data['unread_count'];
      this.inboxData.quarantine_unread_count = data['quarantine_unread_count'];
      this.userDataService.menuCountUpdated$.next({ name: 'inbox', count: data['unread_count'] });
      this.inboxData.inbox_count_per_page = data['count_per_page'];
      if ((data.mails || []).length < this.inboxData.inbox_count_per_page) {
        this.inboxData.get_inbox_page = false;
      }
      if (this.isFetchingAll) {
        this.inboxData.get_inbox_page = false;
      }
      this.inboxData.inbox_total = parseInt(`${data['all_mails_count'] || 0}`, 10);
      setTimeout(() => {
        this.setMailsHeight();
        SharedHelpers.detectChanges(this.cdr);
      }, 10);
    } else {
      for (let i = 0; i < (data.mails || []).length; i++) {
        this.inboxData.quarantine_items.push(data.mails[i]);
        if (!data.mails[i]['is_read']) {
          this.inboxData.quarantine_unread_ids.push(data.mails[i]['id']);
        }
      }
      this.inboxData.quarantine_unread_count = data['unread_count'];
      this.inboxData.quarantine_count_per_page = data['count_per_page'];
      if ((data.mails || []).length < this.inboxData.quarantine_count_per_page) {
        this.inboxData.get_quarantine_page = false;
      }
      if (this.isFetchingAll) {
        this.inboxData.get_quarantine_page = false;
      }
      this.inboxData.quarantine_total = parseInt(`${data['all_mails_count'] || data['mail_count']}`, 10);
    }
    this.handleQueryParams();
  }

  handleQueryParams() {
    if (!Object.keys(this.queryParams).length) {
      this.completedApiCall();
      SharedHelpers.detectChanges(this.cdr);
      return;
    }

    if (this.queryParams['tab'] === 'quarantine') {
      this.queryParams = {};
      this.tabSelected('quarantine');
      this.completedApiCall();
      SharedHelpers.detectChanges(this.cdr);
      return;
    }

    let item;
    if (this.queryParams['inboxId']) {
      (this.inboxData.items || []).forEach(child => {
        if (child.id + '' === this.queryParams['inboxId']) {
          item = child;
        }
      });
    } else if (this.queryParams['quarantineId']) {
      this.tabSelected('quarantine');
      (this.inboxData.items || []).forEach(child => {
        if (child.id + '' === this.queryParams['quarantineId']) {
          item = child;
        }
      });
    }

    if (item) {
      this.mobileMode ? this.handleMobileRowClick(item) : this.mailItemClick(item);
      this.queryParams = {}; // clear query params
    } else {
      this.reloadInbox();
    }
    this.completedApiCall();
    SharedHelpers.detectChanges(this.cdr);
  }

  pullToRefresh(event) {
    this.pullToRefreshEvent = event;
    this.reloadCurrentTab();
  }

  reloadInbox() {
    this.saveSelected();
    this.setPages();
    if (this.regularSubUserHasNoFolders) {
      return;
    }

    this.innerHeight = (document.getElementsByClassName('scan-drawer')[0] || {})['offsetHeight'] || window.innerHeight;
    this.pageSize = this.innerHeight < this.ROW_HEIGHT * Constants.ROWS_PER_PAGE ? Constants.ROWS_PER_PAGE : Constants.ROWS_PER_PAGE * 2;
    if (this.selectedTab === 'inbox') {
      this.getInboxData();
    } else {
      this.getQuarantineData();
    }
  }

  reloadCurrentTab() {
    switch (this.selectedTab) {
      case 'inbox':
        this.inboxData.inbox = [];
        this.inboxData.inbox_page = 0;
        this.inboxData.get_inbox_page = true;
        this.inboxData.inbox_unread_ids = [];
        this.getInboxData();
        break;
      case 'quarantine':
        this.inboxData.quarantine_items = [];
        this.inboxData.quarantine_page = 0;
        this.inboxData.get_quarantine_page = true;
        this.inboxData.quarantine_unread_ids = [];
        this.getQuarantineData();
        break;
    }
  }

  updateInbox() {
    this.reloadCurrentTab();
  }

  public getNextPage() {
    switch (this.selectedTab) {
      case 'inbox':
        if ((this.inboxData.inbox || []).length === 0) {
          return;
        }
        if (this.inboxData.get_inbox_page) {
          this.inboxData.inbox_page++;
          this.getInboxData();
        }
        break;
      case 'quarantine':
        if ((this.inboxData.quarantine_items || []).length === 0) {
          return;
        }
        if (this.inboxData.get_quarantine_page) {
          this.inboxData.quarantine_page++;
          this.getQuarantineData();
        }
        break;
    }
  }

  public infiniteLoadingEnabled() {
    switch (this.selectedTab) {
      case 'inbox':
        return this.inboxData.get_inbox_page;
      case 'quarantine':
        return this.inboxData.get_quarantine_page;
      default:
        break;
    }
    return false;
  }

  public setPropertiesForMail(items: any) {
    (items || []).forEach((item: any) => {
      SharedHelpers.setPropertiesForMail(item);
      if (!SharedHelpers.actionsDisabledForItem(item)) {
        item.selected = this.isFetchingAll;
      }
    });
  }

  public shouldShowLabelsButton(): boolean {
    if (this.isFetchingAll) {
      return false;
    }
    let shouldShow = false;
    ((this.inboxData || {}).items || []).forEach(item => {
      shouldShow = shouldShow || item.selected;
    });
    return false; // shouldShow;
  }

  public shouldShowDiscardButton(): boolean {
    if (this.isFetchingAll) {
      return false;
    }
    let shouldShow = false;
    ((this.inboxData || {}).items || []).forEach(item => {
      shouldShow = shouldShow || (item.selected && item.is_discard_allowed_for_item);
    });
    return shouldShow;
  }

  public shouldShowMoreButton(): boolean {
    if (this.isFetchingAll) {
      return false;
    }
    let shouldShow = false;
    ((this.inboxData || {}).items || []).forEach(item => {
      shouldShow = shouldShow || (item.selected && item.more_options_allowed_for_item);
    });
    return shouldShow;
  }

  public shouldShowScanOptions(): boolean {
    if (this.selectedTab !== 'inbox' || this.isFetchingAll) {
      return false;
    }
    let shouldShow = false;
    ((this.inboxData || {}).items || []).forEach(item => {
      shouldShow = shouldShow || (item.selected && item.is_scan_allowed_for_item);
    });
    return shouldShow;
  }

  public shouldShowShipOptions(): boolean {
    if (this.selectedTab !== 'inbox' || this.isFetchingAll) {
      return false;
    }
    let shouldShow = false;
    ((this.inboxData || {}).items || []).forEach(item => {
      shouldShow = shouldShow || (item.selected && item.is_ship_allowed_for_item);
    });
    return shouldShow;
  }

  public shouldShowUnboxButton() {
    if (this.selectedTab !== 'inbox' || this.isFetchingAll) {
      return false;
    }
    let shouldShow = false;
    ((this.inboxData || {}).items || []).forEach(item => {
      shouldShow = shouldShow || (item.selected && item.is_unbox_allowed_for_item);
    });
    return shouldShow;
  }

  public shouldShowReportIssueButton() {
    if (this.selectedTab !== 'inbox' || this.isFetchingAll) {
      return false;
    }
    let shouldShow = false;
    ((this.inboxData || {}).items || []).forEach(item => {
      shouldShow = shouldShow || (item.selected && !item.is_issue_reported);
    });
    return shouldShow;
  }

  public shouldShowFolderButton() {
    if (this.userData.orgRole === 'REGULAR' || this.selectedTab !== 'inbox' || this.isFetchingAll) {
      return false;
    }
    return true;
  }

  public showShowRemoveFolderButton() {
    if (this.userData.orgRole === 'REGULAR' || this.selectedTab !== 'inbox' || this.isFetchingAll || !this.folderId) {
      return false;
    }
    return true;
  }

  public shouldShowDepositCheckButton() {
    if (this.selectedTab !== 'inbox' || this.isFetchingAll) {
      return false;
    }
    let shouldShow = false;
    ((this.inboxData || {}).items || []).forEach(item => {
      shouldShow = shouldShow || (item.selected && item.is_deposit_check_allowed);
    });
    return shouldShow;
  }

  public shouldShowDecalareButton() {
    if (this.selectedTab !== 'inbox' || this.isFetchingAll) {
      return false;
    }
    let shouldShow = false;
    ((this.inboxData || {}).items || []).forEach(item => {
      shouldShow = shouldShow || (item.selected && item.is_declare_allowed);
    });
    return shouldShow;
  }

  public shouldShowReturnButton() {
    let shouldShow = false;
    ((this.inboxData || {}).items || []).forEach(item => {
      shouldShow = shouldShow || (item.selected && item.is_return_allowed_for_item);
    });
    return shouldShow;
  }

  public shouldShowAddNameButton() {
    return false;
    // if (this.selectedTab !== 'quarantine') {
    //   return false;
    // }
    // let shouldShow = false;
    // ((this.inboxData || {}).items || []).forEach(item => {
    //   shouldShow = shouldShow || item.selected;
    // });
    // return shouldShow;
  }

  public shouldShowSubmit1583Button() {
    return false;
    // if (this.selectedTab !== 'quarantine') {
    //   return false;
    // }
    // let shouldShow = false;
    // ((this.inboxData || {}).items || []).forEach(item => {
    //   shouldShow = shouldShow || item.selected;
    // });
    // return shouldShow;
  }

  public selectedItemlabelsChanged() {
    console.log(this.labelMultiCtrl.value);
  }

  public filterLabels() {
    if (!this.inboxLabels) {
      return;
    }
    // get the search keyword
    let search = this.labelMultiFilterCtrl.value;
    if (!search) {
      this.filteredInboxLabels.next(this.inboxLabels.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredInboxLabels.next(this.inboxLabels.filter(bank => bank.name.toLowerCase().indexOf(search) > -1));
    SharedHelpers.detectChanges(this.cdr);
  }

  public discardSelectedItems() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      id: 1,
      title: 'Discard selected items?',
      message: 'Please confirm',
      cancelText: 'No',
      confirmText: 'Yes',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmSelectedItemsDiscard();
      }
    });
  }

  public tabSelected(tab) {
    this.selectTab(tab);
    SharedHelpers.detectChanges(this.cdr);
    this.updateInbox();
  }

  public selectTab(tab) {
    this.inboxData.items = tab === 'inbox' ? this.inboxData.inbox : this.inboxData.quarantine_items;
    this.selectedTab = tab;
    this.userDataService.setScreenForAnalytics(`${this.selectedTab}`);
    if (this.pullToRefreshEvent) {
      setTimeout(() => this.pullToRefreshEvent.target.complete(), 2000);
    }
  }

  public readAllViewedMails(readInbox: boolean) {
    if (readInbox) {
      this.putReadMail(this.inboxData.inbox_unread_ids);
      this.inboxData.inbox_unread_count = 0;
      this.userDataService.menuCountUpdated$.next({ name: 'inbox', count: 0 });
    } else {
      this.putReadMail(this.inboxData.quarantine_unread_ids);
      this.inboxData.quarantine_unread_count = 0;
    }
  }

  public setItemAsRead(item) {
    if (!item.is_read) {
      this.putReadMail([this.currentItem.id]);
    }
  }

  public itemDetailClicked(item) {
    this.currentItem = item;
    this.setItemAsRead(this.currentItem);
  }

  public openItemBookmarkModal(event) {
    event.stopPropagation();
    event.stopPropagation();
    this.dialog.open(AcceptLookupComponent, {
      width: '300px',
    });
  }

  public toggleAllSelection() {
    this.allSelected = !this.allSelected;
    this.tapToSelectMode = this.allSelected;
    ((this.inboxData || {}).items || []).forEach(item => {
      if (!SharedHelpers.actionsDisabledForItem(item)) {
        item.selected = this.allSelected;
      }
    });
    this.handleAllItemSelectionEvent();
    SharedHelpers.detectChanges(this.cdr);
  }

  public handleAllItemSelectionEvent() {
    if (this.isFetchingAll) {
      return;
    }
    const isInbox = this.selectedTab === 'inbox';
    const allItemFetchRequired = isInbox ? this.inboxData.get_inbox_page : this.inboxData.get_quarantine_page;
    if (this.allSelected && allItemFetchRequired) {
      this.isFetchingAll = true;
      isInbox ? this.getInboxData() : this.getQuarantineData();
    }
  }

  public itemLongPress(item) {
    item.selected = true;
    this.tapToSelectMode = true;
    this.longPressEventFired = true;
    setTimeout(() => {
      this.longPressEventFired = false;
    }, 500);
  }

  public toggleImageClickSelection(event, item) {
    item.selected = !item.selected;
    event.stopPropagation();
  }

  public handleItemSlidingOptionsClick(item, event, slidingItem) {
    slidingItem.close();
    item.selected = true;
    switch (event) {
      case 'ship':
        this.setupSelectedItemsForShipping();
        break;
      case 'scan':
        this.setupSelectedItemsForScan();
        break;
      case 'discard':
        this.discardSelectedItems();
        break;
      case 'unbox':
        this.setupSelectedItemsForUnbox();
        break;
      case 'deposit_check':
        this.setupSelectedItemsForDepositCheck();
        break;
      case 'declare':
        this.setupSelectedItemsForDecalareCheck();
        break;
      case 'return':
        this.returnSubmitClicked();
        break;
      case 'report_issue':
        this.setupSelectedItemsForReportIssue();
        break;
    }
  }

  public toggleItemSelection(item: any) {
    item.selected = !item.selected;
    this.allSelected = true;
    this.tapToSelectMode = false;
    ((this.inboxData || {}).items || []).forEach(currItem => {
      this.allSelected = this.allSelected && currItem['selected'];
      this.tapToSelectMode = this.tapToSelectMode || currItem['selected'];
    });
    this.handleAllItemSelectionEvent();
    SharedHelpers.detectChanges(this.cdr);
  }

  public trackItems(index, item) {
    return item.id;
  }

  public putReadMail(item_ids: any) {
    if ((item_ids || []).length === 0) {
      return;
    }
    const csvStr = item_ids.join(',');
    const isInbox = this.selectedTab === 'inbox';
    const url = isInbox ? this.apiMapping.putInboxRead() : this.apiMapping.putQuarantineRead();
    this.http.http_put(url, { mail_ids: csvStr }).then(
      (data: any) => {},
      (error: any) => {
        if (error.status === 500) {
          console.log('At least one invalid item id');
        }
      },
    );
    if (isInbox) {
      this.inboxData.inbox_unread_count = this.inboxData.inbox_unread_count - item_ids.length;
      if (this.inboxData.inbox_unread_count < 0) {
        this.inboxData.inbox_unread_count = 0;
      }
      this.userDataService.menuCountUpdated$.next({ name: 'inbox', count: this.inboxData.inbox_unread_count });
    } else {
      this.inboxData.quarantine_unread_count = this.inboxData.quarantine_unread_count - item_ids.length;
      if (this.inboxData.quarantine_unread_count < 0) {
        this.inboxData.quarantine_unread_count = 0;
      }
      this.userDataService.menuCountUpdated$.next({ name: 'quarantine', count: this.inboxData.quarantine_unread_count });
    }
    // then update local values
    for (let i = 0; i < (item_ids || []).length; i++) {
      const itemToChange = this.inboxData.items.find(x => x.id === item_ids[i]) || {};
      itemToChange.is_read = true;
    }
  }

  setupSelectedItemsForScanWithDelay(item) {
    this.preItemSetup(item);
    setTimeout(() => {
      this.setupSelectedItemsForScan();
    }, 50);
  }

  onMoveItemsToFolder({ folderId, mailsIds }: MailFolderEvent) {
    this.moveItemsToFolder(folderId, mailsIds);
  }

  setupSelectedItemsForShippingWithDelay(item) {
    this.preItemSetup(item);
    setTimeout(() => {
      this.setupSelectedItemsForShipping();
    }, 50);
  }

  setupItemsForDepositCheckWithDelay(item) {
    this.preItemSetup(item);
    setTimeout(() => {
      this.setupSelectedItemsForDepositCheck();
    }, 50);
  }

  discardSelectedItemsWithDelay(item) {
    this.preItemSetup(item);
    this.itemToBeDiscarded = item;
    setTimeout(() => {
      this.discardSelectedItems();
    }, 50);
  }

  returnItemWithDelay(item) {
    this.preItemSetup(item);
    setTimeout(() => {
      this.returnSubmitClicked();
    }, 50);
  }

  reportIssueWithDelay(item) {
    this.preItemSetup(item);
    setTimeout(() => {
      this.setupSelectedItemsForReportIssue();
    }, 50);
  }

  issueReportedSuccessfully() {
    this.closeRightNav();
    this.reloadCurrentTab();
  }

  preItemSetup(item) {
    item.selected = true;
    this.inboxOptionsDrawer.close();
  }

  setupSelectedItemsForScan() {
    this.rightNavViewOption = RightNavViewOptions.SCAN;
    this.selectedItemsForScan = [];
    this.selectedItemsForUnbox = [];
    const selectedItems = [];
    (this.inboxData.items || []).forEach(item => {
      if (item.selected) {
        selectedItems.push(item);
      }
    });
    (this.inboxData.items || []).forEach(item => {
      if (item.selected && item.is_scan_allowed_for_item) {
        this.selectedItemsForScan.push(item);
      }
      if (item.selected && item.is_unbox_allowed_for_item) {
        this.selectedItemsForUnbox.push(item);
      }
    });
    if (selectedItems.length === this.selectedItemsForScan.length) {
      this.inboxOptionsDrawer.open();
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = '550px';
      dialogConfig.height = '470px';
      dialogConfig.autoFocus = true;
      dialogConfig.closeOnNavigation = true;
      dialogConfig.data = {
        id: 1,
        message: 'One of your items is a package. Scan is not available for package but you can open your package instead.',
        imageUrl: '/assets/images/no-results.svg',
        scanText: this.selectedItemsForScan.length > 1 ? `SCAN LETTERS (${this.selectedItemsForScan.length})` : `SCAN LETTER`,
        unboxText: this.selectedItemsForUnbox.length > 1 ? `OPEN PACKAGES (${this.selectedItemsForUnbox.length})` : `OPEN PACKAGE`,
      };
      const dialogRef = this.dialog.open(MultipleScanDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          (this.inboxData.items || []).forEach(item => {
            if (item.selected && item.is_unbox_allowed_for_item) {
              item.selected = false;
            }
          });
          this.inboxOptionsDrawer.open();
        } else {
          this.setupSelectedItemsForUnbox();
        }
        SharedHelpers.detectChanges(this.cdr);
      });
    }
  }

  setupSelectedItemsForShipping() {
    this.rightNavViewOption = RightNavViewOptions.SHIP;
    this.selectedItemsForShipping = [];
    (this.inboxData.items || []).forEach(item => {
      if (item.selected && item.is_ship_allowed_for_item) {
        this.selectedItemsForShipping.push(item);
      }
    });
    if (this.selectedItemsForShipping.length > 0) {
      this.inboxOptionsDrawer.open();
    }
  }

  setupSelectedItemsForUnbox() {
    this.rightNavViewOption = RightNavViewOptions.UNBOX;
    this.selectedItemsForUnbox = [];
    (this.inboxData.items || []).forEach(item => {
      if (item.selected && item.is_unbox_allowed_for_item) {
        this.selectedItemsForUnbox.push(item);
      } else {
        item.selected = false;
      }
    });
    if (this.selectedItemsForUnbox.length > 0) {
      this.inboxOptionsDrawer.open();
    }
  }

  setupSelectedItemsForReportIssue() {
    this.rightNavViewOption = RightNavViewOptions.REPORT_ISSUE;
    this.selectedItemForReportIssue = [];
    (this.inboxData.items || []).forEach(item => {
      if (item.selected && !item.is_issue_reported) {
        this.selectedItemForReportIssue.push(item);
      }
    });
    if (this.selectedItemForReportIssue.length > 0) {
      this.inboxOptionsDrawer.open();
    }
  }

  addOrEditNotesForItem(event) {
    this.itemSelectedForNotes = event;
    this.rightNavViewOption = RightNavViewOptions.ADD_NOTES;
    this.inboxOptionsDrawer.open();
  }

  setupSelectedItemsForDepositCheck() {
    this.rightNavViewOption = RightNavViewOptions.DEPOSIT_CHECK;
    this.selectedItemsForDepositCheck = [];
    (this.inboxData.items || []).forEach(item => {
      if (item.selected) {
        this.selectedItemsForDepositCheck.push(item);
      }
    });
    if (this.selectedItemsForDepositCheck.length > 0) {
      this.inboxOptionsDrawer.open();
    }
  }

  setupSelectedItemsForDecalareCheck() {
    this.selectedItemsForDepositCheck = [];
    (this.inboxData.items || []).forEach(item => {
      if (item.selected) {
        this.selectedItemsForDepositCheck.push(item);
      }
    });
    if (this.selectedItemsForDepositCheck.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.closeOnNavigation = true;
      dialogConfig.autoFocus = false;
      dialogConfig.width = '500px';
      dialogConfig.data = { items: this.selectedItemsForDepositCheck };
      this.dialog.open(EnterDeclarationsDialogComponent, dialogConfig);
    }
  }

  handleMobileRowClick(item) {
    if (this.longPressEventFired) {
      return;
    } else if (this.tapToSelectMode) {
      item.selected = !item.selected;
      let anyItemSelected = false;
      for (let i = 0; i < ((this.inboxData || {}).items || []).length; i++) {
        anyItemSelected = anyItemSelected || this.inboxData.items[i]['selected'];
      }
      if (!anyItemSelected) {
        this.tapToSelectMode = false;
      }
      return;
    }
    this.currentItem = item;
    this.setItemAsRead(this.currentItem);
    this.rightNavViewOption = this.NavViewOptions.MAIL;
    this.inboxOptionsDrawer.toggle();
  }

  handleMobileItemClick(item: any) {
    this.currentItem = item;
    this.setItemAsRead(this.currentItem);
    const dialogRef = this.dialog.open(MobileItemDetailComponent, {
      width: '500px',
      height: '500px',
      data: { item, itemType: this.selectedTab },
    });
    dialogRef.afterClosed().subscribe(result => {
      result = result || {};
      if (result.scanItem) {
        setTimeout(() => {
          this.setupSelectedItemsForScan();
          SharedHelpers.detectChanges(this.cdr);
        }, 500);
      } else if (result.shipItem) {
        setTimeout(() => {
          this.setupSelectedItemsForShipping();
          SharedHelpers.detectChanges(this.cdr);
        }, 500);
      }
    });
  }

  showIssueDetails(issue) {
    console.log(issue);
  }

  public fetchMore(event) {
    setTimeout(() => {
      event.target.complete(); // hide infinite loader
    }, 2000);
    const items = this.selectedTab === 'inbox' ? this.inboxData.inbox : this.selectedTab === 'quarantine' ? this.inboxData.quarantine_items : this.inboxData.issues;
    if (event.endIndex && event.endIndex !== (items || []).length - 1) {
      return;
    }
    this.getNextPage();
  }

  public mailItemClick(item) {
    this.currentItem = item;
    this.setItemAsRead(this.currentItem);
    this.rightNavViewOption = this.NavViewOptions.MAIL;
    this.inboxOptionsDrawer.toggle();
  }

  public onUnbox(item) {
    this.selectedItemsForUnbox = [];
    this.selectedItemsForUnbox.push(item);
    this.inboxOptionsDrawer.toggle();
    this.rightNavViewOption = this.NavViewOptions.UNBOX;
    this.inboxOptionsDrawer.toggle();
  }

  public returnSubmitClicked() {
    this.inboxOptionsDrawer.close();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      id: 2,
      message: 'Return this item to sender?',
      cancelText: 'No',
      confirmText: 'Yes',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmReturnItem();
      }
    });
  }

  public confirmReturnItem() {
    this.startedApiCall();
    this.http.post(this.apiMapping.submitShippingRequest(), this.returnToSenderRequestPostData()).subscribe(
      response => {
        this.completedApiCall();
        this.reloadCurrentTab();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          id: 3,
          title: 'This item will be returned to sender',
          okText: 'OK',
          imageUrl: 'assets/images/Check in circle light.png',
        };
        this.dialog.open(DialogComponent, dialogConfig);
      },
      error => {
        this.completedApiCall();
        this.notificationService.showError('Unable to create return request. Please try again');
      },
    );
  }

  returnToSenderRequestPostData() {
    const itemsToReturn = [];
    (this.inboxData.items || []).forEach(item => {
      if (item.selected) {
        itemsToReturn.push(item);
      }
    });
    const postData = {
      shipment_category_type: 'RETURN_TO_SENDER_REQUEST',
      shipment_detail: { packing_instructions: 'Return to sender' },
      shipper: {
        name: this.inboxData.items[0].tracking.vendor || this.inboxData.items[0].tracking.id,
      },
      items: [],
    };
    itemsToReturn.forEach(item => {
      postData.items.push({ mail_id: item.id, declarations: [] });
    });
    return postData;
  }

  public openCreateLabelDialog() {
    const dialogRef = this.dialog.open(CreateLabelDialogComponent, {
      width: '500px',
      data: {},
    });
    dialogRef.afterClosed().subscribe(result => {
      result = result || {};
      if (result.labelName) {
        this.createLabel(result.labelName);
      }
    });
  }

  public createLabel(labelName: string) {
    this.startedApiCall();
    this.http.post(this.apiMapping.createLabel(), { name: labelName }).subscribe(
      response => {
        this.completedApiCall();
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.completedApiCall();
        this.notificationService.showError('Unable to create label. Please try again');
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  public updateItemList() {
    (this.inboxData.items || []).forEach(item => {
      if (item.selected && (this.rightNavViewOption === this.NavViewOptions.SCAN || this.rightNavViewOption === this.NavViewOptions.UNBOX)) {
        item.scan_info = {};
        item.selected = false;
        item.scan_info['status'] = 'IN_PROCESS';
        if (item.is_unbox_allowed_for_item) {
          item.is_unbox_allowed_for_item = false;
        } else if (item.is_scan_allowed_for_item) {
          item.is_scan_allowed_for_item = false;
        }
      }
    });
    SharedHelpers.detectChanges(this.cdr);
  }

  public closeRightNav() {
    // (this.inboxData.items || []).forEach(item => {
    //   item.selected = false;
    // });
    // this.allSelected = false; // Keep the selection state as is
    this.inboxOptionsDrawer.close();
    this.rightNavViewOption = -1 as any;
    SharedHelpers.detectChanges(this.cdr);
  }

  public allActionsDisabled() {
    return this.userDataService.getAccountStatus() === AccountStatus.SUSPENDED;
  }

  saveSelected() {
    this.selectedItemId = [];
    (this.inboxData.items || []).forEach(item => {
      if (item.selected) {
        // push ids of selected items
        this.selectedItemId.push(item.id);
      }
    });
  }

  retrieveSelected() {
    if (this.selectedItemId.length && this.inboxData?.inbox) {
      // If selected items exist
      this.inboxData.inbox
        .filter(item => this.selectedItemId.includes(item.id)) // filter those that are in selected list
        .forEach(x => (x.selected = true)); // set selected
    }
    this.selectedItemId = [];
  }

  private getExtension(scanInfo) {
    return scanInfo.category_type === 'UNBOXING_REQUEST' ? 'jpg' : 'pdf';
  }

  public onScanIndicatorClicked(event: any, item: any) {
    event.stopImmediatePropagation();
    const extension = this.getExtension(item.scan_info);
    this.dialog.open(PdfViewerDialogComponent, {
      maxWidth: 'calc(100vw - 10px)',
      panelClass: 'no-padding',
      data: { url: this.apiMapping.viewScan(item.scan_uuid), fileType: extension },
    });
  }

  public showScanIndicator(item: any) {
    return ['COMPLETED', 'DELETED'].includes(item?.scan_info?.status) && item?.scan_uuid;
  }

  public isScanIndicatorDisabled(item: any) {
    return item?.scan_info?.status === 'DELETED';
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.cdr.detach();
  }
}
