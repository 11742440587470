// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  min-height: 100vh;
}
:host .row {
  justify-content: center;
}
:host .row .plan-card {
  max-width: 380px;
}
:host .row .plan-image {
  width: 90px;
  margin: auto;
  display: block;
}
:host .row .price-features {
  margin-left: 1rem;
}
:host .row .price-features .feature {
  min-height: 21px;
}
:host .row .price-text {
  line-height: 1;
}
:host .row .price-text .currency {
  font-weight: bold;
  position: relative;
  top: -1.4em;
}
:host .row .price-text .value {
  font-size: 357%;
  font-weight: bold;
}
:host .row .expand-features-btn {
  width: 100%;
  height: 60px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}
:host .row .feature-detail {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0px;
  border-radius: 0px;
}
:host .row .page-feature-card {
  max-width: 766px;
}`, "",{"version":3,"sources":["webpack://./src/app/register/select-option/select-option.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;AACF;AACE;EACE,uBAAA;AACJ;AACI;EACE,gBAAA;AACN;AAEI;EACE,WAAA;EACA,YAAA;EACA,cAAA;AAAN;AAGI;EACE,iBAAA;AADN;AAGM;EACE,gBAAA;AADR;AAKI;EACE,cAAA;AAHN;AAKM;EACE,iBAAA;EACA,kBAAA;EACA,WAAA;AAHR;AAMM;EACE,eAAA;EACA,iBAAA;AAJR;AAQI;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,oCAAA;EACA,kBAAA;AANN;AASI;EACE,oCAAA;EACA,eAAA;EACA,kBAAA;AAPN;AAUI;EACE,gBAAA;AARN","sourcesContent":[":host {\n  display: block;\n  min-height: 100vh;\n\n  .row {\n    justify-content: center;\n\n    .plan-card {\n      max-width: 380px;\n    }\n\n    .plan-image {\n      width: 90px;\n      margin: auto;\n      display: block;\n    }\n\n    .price-features {\n      margin-left: 1rem;\n\n      .feature {\n        min-height: 21px;\n      }\n    }\n\n    .price-text {\n      line-height: 1;\n\n      .currency {\n        font-weight: bold;\n        position: relative;\n        top: -1.4em;\n      }\n\n      .value {\n        font-size: 357%;\n        font-weight: bold;\n      }\n    }\n\n    .expand-features-btn {\n      width: 100%;\n      height: 60px;\n      background: white;\n      border: 1px solid rgba(0, 0, 0, 0.1);\n      border-radius: 0px;\n    }\n\n    .feature-detail {\n      border: 1px solid rgba(0, 0, 0, 0.1);\n      border-top: 0px;\n      border-radius: 0px;\n    }\n\n    .page-feature-card {\n      max-width: 766px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
