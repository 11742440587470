import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'usgm-submit-id-popup',
  templateUrl: './submit-id-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitIdPopupComponent implements OnInit {
  @Input() item;

  constructor(public dialogRef: MatDialogRef<SubmitIdPopupComponent>) {}

  public ngOnInit() {}
}
