// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host .usgm-header-content {
  width: calc(100% - 300px);
}
:host .navbar-nav {
  min-width: 50%;
}
:host .fa-bell {
  position: relative;
}
:host .badge {
  position: absolute;
  min-width: 16px;
  left: 50%;
  top: 0;
  font-family: "FiraSans";
  font-weight: bold;
  color: white;
  border-radius: 50%;
  background-color: #fb7e16;
}

::ng-deep .cdk-overlay-pane {
  max-width: 90vw !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header.component.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;AAGE;EACE,yBAAA;AADJ;AAIE;EACE,cAAA;AAFJ;AAKE;EACE,kBAAA;AAHJ;AAME;EACE,kBAAA;EACA,eAAA;EACA,SAAA;EACA,MAAA;EACA,uBAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,yBC1BW;ADsBf;;AASE;EACE,0BAAA;AANJ","sourcesContent":["@import '../../../styles/variables';\n\n:host {\n  display: block;\n\n  .usgm-header-content {\n    width: calc(100% - 300px);\n  }\n\n  .navbar-nav {\n    min-width: 50%;\n  }\n\n  .fa-bell {\n    position: relative;\n  }\n\n  .badge {\n    position: absolute;\n    min-width: 16px;\n    left: 50%;\n    top: 0;\n    font-family: 'FiraSans';\n    font-weight: bold;\n    color: white;\n    border-radius: 50%;\n    background-color: $usgm-primary;\n  }\n}\n\n::ng-deep {\n  .cdk-overlay-pane {\n    max-width: 90vw !important;\n  }\n}\n","$usgm-primary: #fb7e16;\n$usgm-primary-hover: #e67212;\n$usgm-secondary: #077abe;\n$usgm-secondary-hover: #0565b3;\n$usgm-default-hover: #9ccae5;\n$nav-button-color: #00838f;\n$usgm-green: #35b54f;\n$usgm-red: #e00202;\n$usgm-orange: #fb7e16;\n$fontFira: 'FiraSans', sans-serif;\n$greyColor: #e5e5e5;\n$darkgrey: #21242c;\n$greyColor1: #4c4c4c;\n$white: #fff;\n$lightGrey: #dbe2eb;\n$greyColor2: rgba(76, 76, 76, 0.4);\n$greyColor3: #fffbfb;\n$greyColor4: #f4f4f4;\n$darkgrey1: #1c335d;\n$blueColor: #4386fc;\n$greenColor: #78be43;\n$black: #000;\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
