import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as Services from '../services';
import { Form1583Service, NotificationService, UserDataService } from '../services';
import {
  FormErrorSource,
  IFormError,
  IFormErrorMeta,
  IFormErrorResolveAddAddressPayload,
  IFormErrorResolveUploadPrimaryIDPayload,
  IFormErrorResolveUploadSecondaryIDPayload, IFormToSign, IFormToSignMeta,
} from '../models/form-error';
import { CaptchaBaseComponent } from '../captcha-base-component/captcha-base-component.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { normalizeFullName } from '../utils/helpers';
import * as moment from 'moment';
import { DialogComponent } from '../components/dialog';
import { environment } from '../../environments/environment';
import { ImageDialogComponent } from '../components/image-dialog';
import { Animations } from '../animations/element.animations';
import { AppRoutes } from '../models/constants/app-routes.constant';
import { Subscription } from 'rxjs';
import { SignFormPopupComponent } from './sign-form-popup/sign-form-popup.component';

const ErrorSourceToCardTitleDict = {
  [FormErrorSource.DOCUMENT_PRIMARY]: 'Primary ID',
  [FormErrorSource.DOCUMENT_SECONDARY]: 'Secondary ID',
  [FormErrorSource.COMPANY_ADDRESS]: 'Company Address',
  [FormErrorSource.PHONE_NUMBER]: 'Phone Number',
};
const SubTitleDict = {
  [FormErrorSource.DOCUMENT_PRIMARY]: 'There is an issue with the primary document of this account.',
  [FormErrorSource.DOCUMENT_SECONDARY]: 'There is an issue with the secondary document of this account.',
  [FormErrorSource.COMPANY_ADDRESS]: 'There is an issue with the company address of this account.',
  [FormErrorSource.PHONE_NUMBER]: 'There is an issue with your billing address.',
};
const PRIMARY_DOCUMENT_TYPES = [
  { value: 'DRIVER_LICENSE', label: 'Driver Licence' },
  { value: 'UNIFORMED_SERVICE_ID', label: 'Uniformed Service ID' },
  { value: 'US_ACCESS_CARD', label: 'US Access Card' },
  { value: 'US_UNIVERSITY_ID_CARD', label: 'US University ID Card' },
  { value: 'PASSPORT', label: 'Passport' },
  { value: 'MATRICULA_CONSULAR', label: 'Matricula Consular' },
  { value: 'NEXUS_CARD', label: 'NEXUS Card' },
  { value: 'CERTIFICATE_OF_NATURALIZATION', label: 'Certificate of Naturalization' },
  { value: 'US_PERMANENT_RESIDENT_CARD', label: 'US Permanent Resident Card' },
];
const SECONDARY_DOCUMENT_TYPES = [
  { value: 'DRIVER_LICENSE', label: 'Driver Licence' },
  { value: 'CURRENT_LEASE', label: 'Current Lease' },
  { value: 'MORTGAGE_OR_DEED_OF_TRUST', label: 'Mortgage Statement' },
  { value: 'HOME_OR_VEHICLE_INSURANCE_POLICY', label: 'Home (or Vehicle) Insurance Policy' },
  { value: 'VEHICLE_REGISTRATION_CARD', label: 'Vehicle Registration Card' },
  { value: 'VOTER_CARD', label: 'Voter Card' },
];
const UPLOAD_ANIMATION_TIMELINE = [
  { text: 'Uploading', icon: 'file_upload', keep: 3 },
  { text: 'Analyzing image', icon: 'memory', keep: 5 },
  { text: 'Extracting data', icon: 'person' },
];
const FIELD_RULES = {
  [FormErrorSource.DOCUMENT_PRIMARY]: {
    type: { msg: 'Please select document type', required: true },
    first_name: { msg: 'First Name is required', required: true },
    last_name: { msg: 'Last Name is required', required: true },
    id_number: { msg: 'ID Number is required', required: true },
    issuing_entity: { msg: 'Issuing Entity is required', required: true },
  },
  [FormErrorSource.DOCUMENT_SECONDARY]: {
    type: { msg: 'Please select document type', required: true },
    name: { msg: 'Name is required', required: true },
    address_line: { msg: 'Address Line is required', required: true },
    city: { msg: 'City is required', required: true },
    state: { msg: 'State is required', required: true },
    country: { msg: 'Country is required', required: true },
    postal_code: { msg: 'Postal Code is required', required: true },
  },
  [FormErrorSource.COMPANY_ADDRESS]: {
    address_line: { msg: 'Address Line is required', required: true },
    city: { msg: 'City is required', required: true },
    state: { msg: 'State is required', required: true },
    country: { msg: 'Country is required', required: true },
    postal_code: { msg: 'Postal Code is required', required: true },
    phone_number: { msg: 'Phone Number is required and should contain only digits', required: true, phone: true },
    company_place_of_registration: { msg: 'Company Place of Registration is required', required: true },
    company_type_of_business: { msg: 'Company Type of Business is required', required: true },
  },
  [FormErrorSource.PHONE_NUMBER]: {
    name: { msg: 'Name is required', required: true },
    address_line: { msg: 'Address Line is required', required: true },
    city: { msg: 'City is required', required: true },
    state: { msg: 'State is required', required: true },
    country: { msg: 'Country is required', required: true },
    postal_code: { msg: 'Postal Code is required', required: true },
    phone_number: { msg: 'Phone Number is required and should contain only digits', required: true, phone: true },
  },
};

@Component({
  selector: 'usgm-resolve-form-error',
  templateUrl: './resolve-form-error.component.html',
  styleUrls: ['./resolve-form-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: Animations,
})
export class ResolveFormErrorComponent extends CaptchaBaseComponent implements OnInit, OnDestroy {
  private rxSubscriptions: Subscription[] = [];

  public loading: boolean;
  public formErrors: IFormError[];
  public formsToSign: IFormToSign[];
  public allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'image/tiff', 'image/tif'];
  public fileSize = 10 * 1024 * 1024;
  public currentlyExpandedFormUUID: string;
  public mobileMode = false;
  public readonly primaryDocumentTypes = PRIMARY_DOCUMENT_TYPES;
  public readonly secondaryDocumentTypes = SECONDARY_DOCUMENT_TYPES;

  @ViewChild('primaryId') primaryId: ElementRef;
  @ViewChild('secondaryId') secondaryId: ElementRef;

  constructor(
    private cdRef: ChangeDetectorRef,
    private http: Services.UsgmHttp,
    private router: Router,
    private apiMapping: Services.ApiMapping,
    private userDataService: UserDataService,
    private form1583Service: Form1583Service,
    public override notificationService: NotificationService,
    public override dialog: MatDialog,
    public override recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobileMode = this.userDataService.isMobileMode;
  }

  public override ngOnInit() {
    this.updateFormErrors();
    this.updateFormsToSign();
    this.registerSubscription(this.userDataService.requestedFormErrorsFetched.subscribe(data => this.updateFormErrors()));
    this.registerSubscription(this.userDataService.requestedFormsToSignFetched.subscribe(data => this.updateFormsToSign()));
    this.mobileMode = this.userDataService.isMobileMode;
  }

  private updateFormErrors() {
    this.formErrors = this.userDataService.getRequestedFormErrors();
    this.formErrors?.forEach(formError => {
      formError.meta = this.generateErrorMeta(formError);
      if ([FormErrorSource.DOCUMENT_PRIMARY, FormErrorSource.DOCUMENT_SECONDARY].includes(formError.error_source)) {
        formError.editBody = { type: '' };
      } else {
        formError.editBody = { pristine: true };
      }
    });
    this.currentlyExpandedFormUUID = this.formErrors[0]?.uuid;
  }

  private updateFormsToSign() {
    this.formsToSign = this.userDataService.getFormsWaitingToSign();
    this.formsToSign?.forEach(formToSign => {
      formToSign.meta = this.generateFormToSignMeta(formToSign);
    });
    this.cdRef.detectChanges();
  }

  public onFileDropped(fileEvent: Array<any>, formError: IFormError, documentType: string) {
    this.validateFile(fileEvent, formError, documentType);
  }

  public validateFile(fileEvent: File[], formError: IFormError, documentType: string) {
    const file = fileEvent[0];
    if (file) {
      if (!this.allowedTypes.includes(file.type)) {
        this.notificationService.showWarning('Allowed types are png, jpg, jpeg, pdf, tiff, tif');
        if (documentType === 'secondary') {
          this.secondaryId.nativeElement.value = '';
        } else {
          this.primaryId.nativeElement.value = '';
        }
        return false;
      } else if (file.size > this.fileSize) {
        this.notificationService.showWarning(`uploaded file should be of size less than ${this.fileSize / (1024 * 1024)} MB.`);
        if (documentType === 'secondary') {
          this.secondaryId.nativeElement.value = '';
        } else {
          this.primaryId.nativeElement.value = '';
        }
        return false;
      }
      this.readURL(file, formError, documentType);
    }
  }

  private readURL(file, formError: IFormError, target: string) {
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        file.data = e.target.result;
        formError.editBody.file = file;
        this.cdRef.detectChanges();
        this.runCaptchaVerificationForKYC(target, file, formError);
      };
      reader.readAsDataURL(file);
    }
  }

  private runCaptchaVerificationForKYC(userType, file, formError) {
    this.extractDocumentInfoFirstCall(this.requestKYCInformation.bind(this), [userType, file, formError]);
  }

  private requestKYCInformation(token, captchaVersion, [userType, file, formError]) {
    const formData = new FormData();
    formData.append('document_type', formError.editBody.type);
    formData.append('user_type', userType.toUpperCase());
    formData.append('document_image', file);
    formError.meta.extracting = true;
    formError.meta.uploadText = 'Uploading';
    this.cdRef.detectChanges();
    this.startUploadAnimation(formError.meta);
    this.http.http_post(this.apiMapping.extractDocumentInfo(token, captchaVersion), formData).then(
      (data: any) => {
        const entityMap = {};
        data?.data?.entities?.forEach(it => {
          if (it.confidence > 0.3 && !entityMap[it.entityKey]) {
            entityMap[it.entityKey] = it.entityValue;
          }
        });
        if (userType === 'primary') {
          formError.editBody.first_name = entityMap['first_name'] || '';
          formError.editBody.middle_name = entityMap['middle_name'] || '';
          formError.editBody.last_name = entityMap['last_name'] || '';
          formError.editBody.name = normalizeFullName(entityMap);
          formError.editBody.id_number = entityMap['id_number'] || 'N/A';
          formError.editBody.issuing_entity = entityMap['issuing_entity'] || 'N/A';
          formError.meta.showFields = true;
          formError.meta.extracting = false;
          const expirationDate = moment(entityMap['expiration_date']);
          if (entityMap['expiration_date'] && expirationDate.isValid()) {
            const diffDays = moment().diff(expirationDate, 'days');
            if (diffDays >= 0) {
              this.deleteFile(formError);
              this.openExpiredDocumentDialog();
            } else {
              formError.editBody.expiration_date = expirationDate.format('YYYY-MM-DD');
            }
          } else {
            formError.editBody.expiration_date = 'N/A';
          }
        } else if (userType === 'secondary') {
          formError.editBody.name = normalizeFullName(entityMap);
          formError.editBody.address_line = entityMap['street_address'] || '';
          formError.editBody.city = entityMap['city'] || '';
          formError.editBody.state = entityMap['state'] || '';
          formError.editBody.postal_code = entityMap['postal_code'] || '';
          formError.editBody.country = 'US';
          formError.meta.showFields = true;
          formError.meta.extracting = false;
        }
        this.cdRef.detectChanges();
      },
      (error: any) => {
        this.loading = false;
        if (error.status === 422) {
          this.verifyCaptcha(this.requestKYCInformation.bind(this), [userType, file]);
        }
        formError.meta.showFields = true;
        formError.meta.extracting = false;
        formError.editBody = {
          file: formError.editBody.file,
          type: formError.editBody.type,
        };
        this.cdRef.detectChanges();
      },
    );
  }

  private startUploadAnimation(formErrorMeta: IFormErrorMeta) {
    formErrorMeta.uploadAnimationStepIndex = 0;
    this.uploadAnimationTickHandler(formErrorMeta);
  }

  private uploadAnimationTickHandler(formErrorMeta: IFormErrorMeta, increment = false) {
    let keep = 0;
    if (increment) {
      formErrorMeta.uploadAnimationStepIndex++;
    }
    formErrorMeta.uploadText = UPLOAD_ANIMATION_TIMELINE[formErrorMeta.uploadAnimationStepIndex].text;
    formErrorMeta.uploadIcon = UPLOAD_ANIMATION_TIMELINE[formErrorMeta.uploadAnimationStepIndex].icon;
    keep = UPLOAD_ANIMATION_TIMELINE[formErrorMeta.uploadAnimationStepIndex].keep;
    if (keep) {
      setTimeout(() => this.uploadAnimationTickHandler(formErrorMeta, true), keep * 1000);
    }
    this.cdRef.detectChanges();
  }

  public deleteFile(formError: IFormError) {
    if (formError.editBody.file) {
      formError.editBody = {
        file: null,
        type: formError.editBody.type,
      };
      formError.meta.showFields = false;
    }
    this.cdRef.detectChanges();
  }

  private openExpiredDocumentDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: `We regret to inform you that the primary document you have submitted appears to be expired, according to our system's records.
      To proceed, we kindly ask you to provide a current and valid document. If you believe this is a mistake and your document is indeed valid, please do not hesitate to reach out to us for
      assistance. We are committed to resolving any issues promptly. Should you need to discuss this further, please click Contact Us below or call us at +1 281-596-8965`,
      title: 'Document is expired',
      cancelText: 'Ok, got it',
      confirmText: 'Contact Us',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        window.open(`${environment.usglobalMailWordPress}/contact-us`, '_blank');
      }
    });
  }

  public openImageFullScreen(src) {
    this.dialog.open(ImageDialogComponent, {
      minWidth: 'calc(100vw - 10px)',
      minHeight: 'calc(100vh - 10px)',
      data: { imgSrc: src },
      panelClass: 'post-dialog-container',
    });
  }

  private generateErrorMeta(formError: IFormError): IFormErrorMeta {
    return {
      subtitle: this.generateErrorSubTitle(formError),
      title: this.generateErrorTitle(formError),
      extracting: false,
      saving: false,
      showFields: false,
    };
  }

  private generateFormToSignMeta(formToSign: IFormToSign): IFormToSignMeta {
    return {
      title: this.generateFormToSignTitle(formToSign),
    };
  }

  private generateErrorTitle(formError: IFormError): string {
    return `${ErrorSourceToCardTitleDict[formError.error_source] || 'Unknown'} (${formError.User?.name || ''})`;
  }

  private generateFormToSignTitle(formToSign: IFormToSign): string {
    return `Signature needed for ${formToSign?.user_name}`;
  }

  private generateErrorSubTitle(formError: IFormError): string {
    return SubTitleDict[formError.error_source] || '';
  }

  public hasErrors(formError: IFormError, field: string) {
    if (formError.editBody.pristine) return false;
    return !this.validateField(formError, field);
  }

  private validateField(formError: IFormError, field: string) {
    const rule = FIELD_RULES[formError.error_source]?.[field];
    if (!rule) return true;
    if (rule.required && !formError.editBody[field]?.trim()?.length) return false;
    if (rule.phone && !/^\+*[0-9\s]+$/.test(formError.editBody[field])) return false;
    return true;
  }

  private validateForm(formError: IFormError): boolean {
    const keys = Object.keys(FIELD_RULES[formError.error_source]);
    for (let i = 0; i < keys?.length; i++) {
      if (!this.validateField(formError, keys[i])) {
        this.notificationService.showError(FIELD_RULES[formError.error_source][keys[i]]['msg']);
        return false;
      }
    }
    return true;
  }

  public submitPrimaryDocument(formError: IFormError) {
    if (!this.validateForm(formError)) return;

    formError.meta.saving = true;
    this.cdRef.detectChanges();
    const payload: IFormErrorResolveUploadPrimaryIDPayload = {
      document_type: formError.editBody.type,
      expiration_date: formError.editBody.expiration_date,
      first_name: formError.editBody.first_name,
      last_name: formError.editBody.last_name,
      middle_name: formError.editBody.middle_name,
      id_number: formError.editBody.id_number,
      issuing_entity: formError.editBody.issuing_entity,
      document_image: formError.editBody.file,
    };
    const formData = this.objectToFormData(payload);
    this.form1583Service.resolveFormErrorUploadPrimaryDocument(formError.uuid, formData).subscribe(
      response => this.handleSubmitSuccess(formError, 'Primary document saved'),
      error => this.handleSubmitError(error, formError, 'Error saving document, please try again or contact us'),
    );
  }

  public submitSecondaryDocument(formError: IFormError) {
    if (!this.validateForm(formError)) return;
    formError.meta.saving = true;
    this.cdRef.detectChanges();
    const payload: IFormErrorResolveUploadSecondaryIDPayload = {
      document_type: formError.editBody.type,
      name: formError.editBody.name,
      address_line: formError.editBody.address_line,
      city: formError.editBody.city,
      state: formError.editBody.state,
      country: formError.editBody.country,
      postal_code: formError.editBody.postal_code,
      document_image: formError.editBody.file,
    };
    const formData = this.objectToFormData(payload);
    this.form1583Service.resolveFormErrorUploadSecondaryDocument(formError.uuid, formData).subscribe(
      response => this.handleSubmitSuccess(formError, 'Secondary document saved'),
      error => this.handleSubmitError(error, formError, 'Error saving document, please try again or contact us'),
    );
  }

  public submitCompanyAddress(formError: IFormError) {
    formError.editBody.pristine = false;
    if (!this.validateForm(formError)) return;
    formError.meta.saving = true;
    this.cdRef.detectChanges();
    const payload: IFormErrorResolveAddAddressPayload = {
      address_line: formError.editBody.address_line,
      address_line_2: formError.editBody.address_line_2,
      address_line_3: formError.editBody.address_line_3,
      city: formError.editBody.city,
      state: formError.editBody.state,
      country: formError.editBody.country,
      postal_code: formError.editBody.postal_code,
      phone_number: formError.editBody.phone_number,
      company_place_of_registration: formError.editBody.company_place_of_registration,
      company_type_of_business: formError.editBody.company_type_of_business,
    };
    this.form1583Service.resolveFormErrorAddCompanyAddress(formError.uuid, payload).subscribe(
      response => this.handleSubmitSuccess(formError, 'Company address saved'),
      error => this.handleSubmitError(error, formError, 'Error saving address, please try again or contact us'),
    );
  }

  public submitBillingAddress(formError: IFormError) {
    formError.editBody.pristine = false;
    if (!this.validateForm(formError)) return;
    formError.meta.saving = true;
    this.cdRef.detectChanges();
    const payload: IFormErrorResolveAddAddressPayload = {
      name: formError.editBody.name,
      address_line: formError.editBody.address_line,
      address_line_2: formError.editBody.address_line_2,
      address_line_3: formError.editBody.address_line_3,
      city: formError.editBody.city,
      state: formError.editBody.state,
      country: formError.editBody.country,
      postal_code: formError.editBody.postal_code,
      phone_number: formError.editBody.phone_number,
    };
    this.form1583Service.resolveFormErrorAddBillingAddress(formError.uuid, payload).subscribe(
      response => this.handleSubmitSuccess(formError, 'Billing address saved'),
      error => this.handleSubmitError(error, formError, 'Error saving address, please try again or contact us'),
    );
  }

  private handleSubmitSuccess(formError: IFormError, msg: string) {
    this.notificationService.showSuccess(msg);
    this.markAsCompleted(formError);
    formError.meta.saving = false;
    this.cdRef.detectChanges();
  }

  private handleSubmitError(error: any, formError: IFormError, defaultMsg: string) {
    this.notificationService.showError(error?.error?.errors?.[0]?.msg || error?.error?.[0] || error?.error?.message || defaultMsg);
    formError.meta.saving = false;
    this.cdRef.detectChanges();
  }

  private markAsCompleted(formError: IFormError) {
    this.formErrors = this.formErrors.filter(it => it.uuid !== formError.uuid);
    this.currentlyExpandedFormUUID = this.formErrors[0]?.uuid;
    this.userDataService.setRequestedFormErrors(this.formErrors);
    this.cdRef.detectChanges();
    this.checkForCompletionAndRedirect();
  }

  public showUploadPrimaryMode(formError: IFormError): boolean {
    return formError.error_source === FormErrorSource.DOCUMENT_PRIMARY;
  }

  public showUploadSecondaryMode(formError: IFormError): boolean {
    return formError.error_source === FormErrorSource.DOCUMENT_SECONDARY;
  }

  public showAddCompanyAddressMode(formError: IFormError): boolean {
    return formError.error_source === FormErrorSource.COMPANY_ADDRESS;
  }

  public showAddBillingAddressMode(formError: IFormError): boolean {
    return formError.error_source === FormErrorSource.PHONE_NUMBER;
  }

  public openSignFormPopup(form: IFormToSign) {
    const dialogRef = this.dialog.open(SignFormPopupComponent, {
      width: 'calc(100% - 100px)',
      height: '95%',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: { form },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result?.signed) {
        this.formsToSign = this.formsToSign.filter(it => it.uuid !== form.uuid);
        this.userDataService.setFormsWaitingToSign(this.formsToSign);
        this.cdRef.detectChanges();
        this.checkForCompletionAndRedirect();
      }
    });
  }

  private objectToFormData(obj: any): FormData {
    const formData = new FormData();
    Object.keys(obj).forEach(key => {
      const val = obj[key];
      if (val !== undefined) {
        formData.append(key, obj[key]);
      }
    });
    return formData;
  }

  private checkForCompletionAndRedirect() {
    if (!this.formErrors.length && !this.formsToSign.length) {
      this.router.navigate([AppRoutes.inbox]);
    }
  }

  private registerSubscription(subscription: Subscription): void {
    this.rxSubscriptions.push(subscription);
  }

  private removeSubscriptions(): void {
    this.rxSubscriptions.forEach(s => s?.unsubscribe());
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.cdRef.detach();
    this.removeSubscriptions();
  }
}
