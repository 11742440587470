import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from 'ngx-clipboard';
import { ShareModule } from 'ngx-sharebuttons';
import { ComponentsModule } from '../components';
import { DirectivesModule } from '../directives';
import { InboxModule } from '../inbox';
import { PipesModule } from '../pipes';
import { ScansExportsListComponent } from './scans-exports-list/scans-exports-list.component';
import { VerifyAccountModule } from '../verify-account';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { AddressBookComponent } from './address-book/address-book.component';
import { AutomaticForwardingComponent } from './automatic-forwarding/automatic-forwarding.component';
import { BillingDetailsComponent } from './billing-details/billing-details.component';
import { ChangePlanPopupComponent } from './change-plan-popup/change-plan-popup.component';
import { ChangeWarehouseComponent } from './change-warehouse/change-warehouse.component';
import { ConfirmStepComponent } from './change-warehouse/steps/confirm-step/confirm-step.component';
import { SelectPlanStepComponent } from './change-warehouse/steps/select-plan-step/select-plan-step.component';
import { SelectWarehouseStepComponent } from './change-warehouse/steps/select-warehouse-step/select-warehouse-step.component';
import { CloseAccountPopupComponent } from './close-account-popup/close-account-popup.component';
import { EnableMfaPopupComponent } from './enable-mfa-popup/enable-mfa-popup.component';
import { FormRejectionResolveComponent } from './form-rejection-resolve/form-rejection-resolve.component';
import { GetHelpComponent } from './get-help/get-help.component';
import { IdRejectionResolveComponent } from './id-rejection-resolve/id-rejection-resolve.component';
import { ManageFolderComponent } from './manage-folder/manage-folder.component';
import { ManageLabelRulesComponent } from './manage-label-rules/manage-label-rules.component';
import { ManageScanBundlesComponent } from './manage-scan-bundles/manage-scan-bundles.component';
import { ManageTeamMembersComponent } from './manage-team-members/manage-team-members.component';
import { SettingsAddAddressComponent } from './onboarding/add-address/add-address.component';
import { SettingsOnboardingAddIdsComponent } from './onboarding/add-identifications/user-id-identification.component';
import { SettingsOnboardingAddNewNameComponent } from './onboarding/add-new-names/add-new-names.component';
import { ListNotarizeOptionsComponent } from './onboarding/list-notarize-options/list-notarize-options.component';
import { ManageAccountNamesComponent } from './onboarding/manage-account-names/manage-account-names.component';
import { NotarizeCompletedMobileComponent } from './onboarding/notarization-completed/notarization-completed.component';
import { NotarizeOfflineMobileComponent } from './onboarding/notarize-offline/notarize-offline-mobile.component';
import { NotarizeWithNotarycamMobileComponent } from './onboarding/notarize-with-notarycam/notarize-with-notarycam.component';
import { NotarizeWithUsgmMobileComponent } from './onboarding/notarize-with-usgm/notarize-with-usgm.component';
import { SettingsOnBoardingProcessComponent } from './onboarding/process-container/process-container.component';
import { SettingsOnboardingProgressLineComponent } from './onboarding/progress-line/progress-line.component';
import { SettingsOnboardingShowFormComponent } from './onboarding/show-form-1583/show-form-1583.component';
import { SettingsUploadNotarizedFormComponent } from './onboarding/upload-notarized-form/upload-notarized-form.component';
import { ReferFriendComponent } from './refer-friend/refer-friend.component';
import { ScanningOptionsComponent } from './scanning-options/scanning-options.component';
import { SettingsPageComponent } from './settings-page.component';

@NgModule({
  declarations: [
    AccountDetailsComponent,
    AddressBookComponent,
    AutomaticForwardingComponent,
    BillingDetailsComponent,
    ManageAccountNamesComponent,
    ManageLabelRulesComponent,
    ChangePlanPopupComponent,
    CloseAccountPopupComponent,
    EnableMfaPopupComponent,
    ReferFriendComponent,
    SettingsPageComponent,
    IdRejectionResolveComponent,
    FormRejectionResolveComponent,
    GetHelpComponent,
    SettingsOnBoardingProcessComponent,
    SettingsOnboardingAddNewNameComponent,
    SettingsOnboardingProgressLineComponent,
    SettingsOnboardingAddIdsComponent,
    SettingsOnboardingShowFormComponent,
    ListNotarizeOptionsComponent,
    NotarizeOfflineMobileComponent,
    NotarizeWithNotarycamMobileComponent,
    NotarizeWithUsgmMobileComponent,
    SettingsUploadNotarizedFormComponent,
    NotarizeCompletedMobileComponent,
    ManageFolderComponent,
    ManageTeamMembersComponent,
    ChangeWarehouseComponent,
    SelectWarehouseStepComponent,
    SelectPlanStepComponent,
    ConfirmStepComponent,
    ManageScanBundlesComponent,
    ScanningOptionsComponent,
    SettingsAddAddressComponent,
    ScansExportsListComponent,
  ],
  imports: [
    ShareModule,
    BrowserModule,
    CommonModule,
    ComponentsModule,
    ClipboardModule,
    DirectivesModule,
    FormsModule,
    InboxModule,
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatStepperModule,
    MatTooltipModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
    VerifyAccountModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatInputModule,
  ],
  exports: [
    AccountDetailsComponent,
    AddressBookComponent,
    AutomaticForwardingComponent,
    BillingDetailsComponent,
    ManageAccountNamesComponent,
    ManageLabelRulesComponent,
    ChangePlanPopupComponent,
    ReferFriendComponent,
    CloseAccountPopupComponent,
    EnableMfaPopupComponent,
    SettingsPageComponent,
    SettingsOnBoardingProcessComponent,
    SettingsOnboardingAddNewNameComponent,
    SettingsOnboardingProgressLineComponent,
    SettingsOnboardingAddIdsComponent,
    SettingsOnboardingShowFormComponent,
    ListNotarizeOptionsComponent,
    NotarizeOfflineMobileComponent,
    ManageFolderComponent,
    ManageTeamMembersComponent,
    NotarizeWithNotarycamMobileComponent,
    NotarizeWithUsgmMobileComponent,
    SettingsUploadNotarizedFormComponent,
    NotarizeCompletedMobileComponent,
    SettingsAddAddressComponent,
  ],
})
export class SettingsModule {}
