// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-items-wrapper {
  overflow: hidden;
  width: 100%;
  max-width: 480px;
}
.carousel-items-wrapper .carousel {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 200%;
  display: flex;
}
.carousel-items-wrapper .carousel .carousel-item-view {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/carousel/carousel.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,gBAAA;AACF;AACE;EACE,gBAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,aAAA;AACJ;AACI;EACE,WAAA;AACN","sourcesContent":[".carousel-items-wrapper {\n  overflow: hidden;\n  width: 100%;\n  max-width: 480px;\n\n  .carousel {\n    list-style: none;\n    margin: 0;\n    padding: 0;\n    width: 200%;\n    display: flex;\n\n    .carousel-item-view {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
