import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AcceptOrgInviteComponent } from './accept-org-invite.component';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '../components';

@NgModule({
  imports: [CommonModule, MatCheckboxModule, FormsModule, ReactiveFormsModule, RouterModule, ComponentsModule],
  providers: [],
  declarations: [AcceptOrgInviteComponent],
})
export class AcceptOrgInviteModule {}
