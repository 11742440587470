import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import * as Services from '../services';
import * as crypto from 'crypto-js';
import { Platform } from '@ionic/angular';
import * as Constants from '../utils/constants';

@Component({
  selector: 'usgm-app-email-unsubscribe',
  templateUrl: './email-unsubscribe.component.html',
  styleUrls: ['./email-unsubscribe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailUnsubscribeComponent implements OnInit {
  public isValidRequest = true;
  public imgSrc = Constants.USGM_LOGO;
  public isWeb = true;
  public isMobileView = false;
  public isLoading = true;
  public switchView = false;
  public unsubscribeLoading = false;
  public subscribeLoading = false;
  public unsubscribeData: {
    customerId: number;
    emailGroupName: string;
    email: string;
  };

  constructor(
    private http: Services.UsgmHttp,
    private apiMapping: Services.ApiMapping,
    private activatedRoute: ActivatedRoute,
    private plt: Platform,
    private notificationService: Services.NotificationService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    // if (this.plt.platforms().indexOf('cordova') >= 0) {
    //   this.imgSrc = Constants.USGM_MINI_LOGO;
    //   this.isMobileView = true;
    //   this.cdr.detectChanges();
    // }
    this.activatedRoute.queryParams.subscribe(() => {
      if (this.activatedRoute.snapshot.queryParamMap.has('d')) {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const data: string = params.get('d');
        if (data) {
          try {
            const unsubscribeDataString = crypto.AES.decrypt(data.replace(/xMl3Jk/g, '+'), environment['loginEncryptionKey']).toString(crypto.enc.Utf8);
            if (unsubscribeDataString) {
              this.unsubscribeData = JSON.parse(unsubscribeDataString);
              this.isValidRequest = true;
              this.isLoading = false;
            } else {
              this.isValidRequest = false;
              this.isLoading = false;
            }
          } catch (error) {
            this.isValidRequest = false;
            this.isLoading = false;
          }
        } else {
          this.isValidRequest = false;
          this.isLoading = false;
        }
      } else {
        this.isValidRequest = false;
        this.isLoading = false;
      }
      this.cdr.detectChanges();
    });
    localStorage.setItem('redirectTo', '/inbox');
  }

  public unsubscribeRequest() {
    this.unsubscribeLoading = true;
    this.cdr.detectChanges();
    if (this.unsubscribeData && this.unsubscribeData.customerId && this.unsubscribeData.emailGroupName) {
      this.http
        .put(this.apiMapping.unsubscribeEmail(), {
          userId: this.unsubscribeData.customerId,
          emailGroupName: this.unsubscribeData.emailGroupName,
        })
        .subscribe(
          () => {
            this.switchView = true;
            this.unsubscribeLoading = false;
            this.cdr.detectChanges();
          },
          error => {
            this.notificationService.showError(error?.error?.message || error.message);
            this.unsubscribeLoading = false;
            this.cdr.detectChanges();
          },
        );
    } else {
      this.isValidRequest = false;
      this.isLoading = false;
      this.cdr.detectChanges();
    }
  }

  public subscribeRequest() {
    this.subscribeLoading = true;
    this.cdr.detectChanges();
    if (this.unsubscribeData && this.unsubscribeData.customerId && this.unsubscribeData.emailGroupName) {
      this.http
        .put(this.apiMapping.subscribeEmail(), {
          userId: this.unsubscribeData.customerId,
          emailGroupName: this.unsubscribeData.emailGroupName,
        })
        .subscribe(
          () => {
            this.switchView = false;
            this.subscribeLoading = false;
            this.cdr.detectChanges();
          },
          error => {
            this.notificationService.showError(error?.error?.message || error.message);
            this.subscribeLoading = false;
            this.cdr.detectChanges();
          },
        );
    } else {
      this.isValidRequest = false;
      this.subscribeLoading = false;
      this.cdr.detectChanges();
    }
  }
}
