import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MAX_ATTEMPTS, CAPTCHA_VERSION_V3, CAPTCHA_VERSION_V2, CaptchaType } from '../utils/constants';
import { RecaptchaDialogComponent } from '../../app/components/recaptcha-dialog';
import { MatDialog } from '@angular/material/dialog';
import * as Services from '../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'usgm-captcha-component',
  template: ``,
  styles: [],
})
export class CaptchaBaseComponent implements OnInit, OnDestroy {
  public numAttempts = 1;
  private recaptchaSubscription: Subscription;
  constructor(
    public dialog: MatDialog,
    public notificationService: Services.NotificationService,
    public recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  ngOnInit(): void {}

  public verifyCaptcha(callback, args?: any) {
    if (this.numAttempts >= MAX_ATTEMPTS) {
      return false;
    }
    this.numAttempts++;
    const recaptchaDialog = this.dialog.open(RecaptchaDialogComponent, {});
    recaptchaDialog.afterClosed().subscribe(data => {
      if (data.event === 'resolved') {
        callback(data.token, CAPTCHA_VERSION_V2, args);
      } else if (data.event === 'errored') {
        this.notificationService.showError(data.error.message);
      }
    });
  }

  public downloadIDsFirstCall(callback, args?: any) {
    if (this.recaptchaSubscription) {
      this.recaptchaSubscription.unsubscribe();
    }
    this.recaptchaSubscription = this.recaptchaV3Service.execute(CaptchaType.IDS_DOWNLOAD).subscribe(
      token => {
        callback(token, CAPTCHA_VERSION_V3, args);
      },
      error => {
        this.notificationService.showError(error.message);
      },
    );
  }

  public downloadFormsFirstCall(callback, args?: any) {
    if (this.recaptchaSubscription) {
      this.recaptchaSubscription.unsubscribe();
    }
    this.recaptchaSubscription = this.recaptchaV3Service.execute(CaptchaType.FORM_DOWNLOAD).subscribe(
      token => {
        callback(token, CAPTCHA_VERSION_V3, args);
      },
      error => {
        this.notificationService.showError(error.message);
      },
    );
  }

  public extractDocumentInfoFirstCall(callback, args?: any) {
    if (this.recaptchaSubscription) {
      this.recaptchaSubscription.unsubscribe();
    }
    this.recaptchaSubscription = this.recaptchaV3Service.execute(CaptchaType.EXTRACT_DOCUMENT_INFO).subscribe(
      token => {
        callback(token, CAPTCHA_VERSION_V3, args);
      },
      error => {
        this.notificationService.showError(error.message);
      },
    );
  }

  ngOnDestroy(): void {
    if (this.recaptchaSubscription) {
      this.recaptchaSubscription.unsubscribe();
    }
  }
}
