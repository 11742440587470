import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import * as Services from '../../../services';
import * as SharedHelpers from '../../../utils/helpers';

@Component({
  selector: 'usgm-unbox-popup',
  templateUrl: './unbox-popup.component.html',
  styleUrls: ['./unbox-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnboxPopupComponent implements OnInit, OnDestroy {
  public loading = false;
  public unboxRequestSubmitted = false;

  @Input() items: any = [];
  @Output() closeNav = new EventEmitter();
  @Output() closeNavAndReloadItems = new EventEmitter();
  @Output() updateItemList = new EventEmitter();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected http: Services.UsgmHttp,
    protected apiMapping: Services.ApiMapping,
    protected notificationService: Services.NotificationService,
    protected userDataService: Services.UserDataService,
  ) {}

  ngOnInit() {
    if (!this.userDataService.warehouseConfiguration) {
      this.userDataService.fetchWarehouseConfigs();
    }
  }

  closeSideNavAndReloadScans() {
    this.closeNavAndReloadItems.emit({});
  }

  closeSideNav() {
    this.closeNav.emit({});
  }

  submitUnboxRequest() {
    this.loading = true;
    this.http.post(this.apiMapping.confirmScan(), this.unboxPostBody()).subscribe(
      response => {
        this.loading = false;
        this.unboxRequestSubmitted = true;
        this.updateItemList.emit();
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.loading = false;
        this.notificationService.showError('Unable to submit request. Please try again');
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  unboxPostBody() {
    const scanPostBody = [];
    this.items.forEach(item => {
      scanPostBody.push({
        id: item.id,
        instruction: item.scanInstructions,
        is_expedite: item.shouldExpedite,
        category_type: 'UNBOXING_REQUEST',
      });
    });
    return scanPostBody;
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
