import { Component, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import * as Services from '../../../../services';
import { takeUntil } from 'rxjs/operators';
import { UserDataService } from '../../../../services';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../../../../components/dialog';

@Component({
  selector: 'usgm-confirm-warehouse-change-step',
  templateUrl: './confirm-step.component.html',
  styleUrls: ['./confirm-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmStepComponent implements OnDestroy {
  private unSubscriber: Subject<any> = new Subject();

  public loading = false;

  @Output() confirmationCompleted = new EventEmitter();
  @Input()
  public warehouse;
  @Input()
  public plan;
  @Input()
  public term;
  @Input()
  public showScanBundleWarningNote;

  constructor(
    protected cdr: ChangeDetectorRef,
    private http: Services.UsgmHttp,
    private apiMapping: Services.ApiMapping,
    private userDataService: UserDataService,
    private notificationService: Services.NotificationService,
    private dialog: MatDialog,
  ) {}

  ngOnDestroy() {
    this.cdr.detach();
    this.unSubscriber.next({});
    this.unSubscriber.complete();
  }

  cancelAndClose() {
    this.confirmationCompleted.emit();
  }

  confirmAndChange() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: '',
      title: 'Note: You will be logged out to complete this change of address. To access your account, please log in again.',
      cancelText: 'No',
      confirmText: 'Yes',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.changeWarehouse();
      }
    });
  }

  changeWarehouse() {
    if (!this.warehouse?.id || !this.plan?.id || !this.term?.term_id) {
      this.notificationService.showError('Please pass through step 1 and 2 again and make sure Warehouse and Plan are selected');
      return this.cdr.detectChanges();
    }
    this.loading = true;
    const requestBody = {
      warehouseId: this.warehouse.id,
      planId: this.plan.uuid,
      termId: this.term.term_id,
      userId: this.userDataService.getUserId(),
    };
    this.cdr.detectChanges();
    this.userDataService
      .changeWarehouse(requestBody)
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        response => {
          this.loading = false;
          this.notificationService.showSuccess('US Global Mail address changed successfully');
          this.cdr.detectChanges();
          this.userDataService.logoutUser();
        },
        err => {
          this.loading = false;
          this.cdr.detectChanges();
          setTimeout(() => {
            this.notificationService.showError(err.error?.message || 'Unable to change the US Global Mail address. Please contact support@usglobalmail.com for assistance');
          }, 0);
        },
      );
  }
}
