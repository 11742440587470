// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host mat-card {
  margin-bottom: 20px;
}
:host mat-card mat-icon {
  margin-right: 10px;
  min-width: 24px;
}
:host mat-card mat-card-content {
  display: flex;
  align-items: center;
}
:host mat-card mat-card-content.box-success {
  border-color: #96d48a;
  color: #306e24;
  background-color: #e6f5e4;
}
:host mat-card mat-card-content.box-error {
  border-color: #f4a190;
  color: #aa2c39;
  background-color: #fce8e5;
}
:host mat-card mat-card-content.box-info {
  color: #2d6a8a;
  background-color: #e5f3fa;
  border-color: #8ccce6;
}
:host mat-card mat-card-content.box-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #f8d283;
}
:host mat-card mat-card-content > span {
  font-weight: 500;
}
:host mat-card mat-card-content > a {
  margin-left: 5px;
  color: #077abe;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/app/components/info-box/info-box.component.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEE;EACE,mBAAA;AADJ;AAEI;EACE,kBAAA;EACA,eAAA;AAAN;AAEI;EACE,aAAA;EACA,mBAAA;AAAN;AACM;EACE,qBAAA;EACA,cAAA;EACA,yBAAA;AACR;AACM;EACE,qBAAA;EACA,cAAA;EACA,yBAAA;AACR;AACM;EACE,cAAA;EACA,yBAAA;EACA,qBAAA;AACR;AAEM;EACE,cAAA;EACA,yBAAA;EACA,qBAAA;AAAR;AAEM;EACE,gBAAA;AAAR;AAEM;EACE,gBAAA;EACA,cCnCS;EDoCT,gBAAA;AAAR","sourcesContent":["@import '../../../styles/variables.scss';\n:host {\n  mat-card {\n    margin-bottom: 20px;\n    mat-icon {\n      margin-right: 10px;\n      min-width: 24px;\n    }\n    mat-card-content {\n      display: flex;\n      align-items: center;\n      &.box-success {\n        border-color: #96d48a;\n        color: #306e24;\n        background-color: #e6f5e4;\n      }\n      &.box-error {\n        border-color: #f4a190;\n        color: #aa2c39;\n        background-color: #fce8e5;\n      }\n      &.box-info {\n        color: #2d6a8a;\n        background-color: #e5f3fa;\n        border-color: #8ccce6;\n      }\n\n      &.box-warning {\n        color: #8a6d3b;\n        background-color: #fcf8e3;\n        border-color: #f8d283;\n      }\n      & > span {\n        font-weight: 500;\n      }\n      & > a {\n        margin-left: 5px;\n        color: $usgm-secondary;\n        font-weight: 500;\n      }\n    }\n  }\n}\n","$usgm-primary: #fb7e16;\n$usgm-primary-hover: #e67212;\n$usgm-secondary: #077abe;\n$usgm-secondary-hover: #0565b3;\n$usgm-default-hover: #9ccae5;\n$nav-button-color: #00838f;\n$usgm-green: #35b54f;\n$usgm-red: #e00202;\n$usgm-orange: #fb7e16;\n$fontFira: 'FiraSans', sans-serif;\n$greyColor: #e5e5e5;\n$darkgrey: #21242c;\n$greyColor1: #4c4c4c;\n$white: #fff;\n$lightGrey: #dbe2eb;\n$greyColor2: rgba(76, 76, 76, 0.4);\n$greyColor3: #fffbfb;\n$greyColor4: #f4f4f4;\n$darkgrey1: #1c335d;\n$blueColor: #4386fc;\n$greenColor: #78be43;\n$black: #000;\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
