import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Platform } from '@ionic/angular';
import * as Constants from '../../utils/constants';
import { AppRoutes } from '../../models/constants/app-routes.constant';

@Component({
  selector: 'usgm-reset-password-confirmation',
  templateUrl: './reset-password-confirmation.component.html',
  styleUrls: ['./reset-password-confirmation.component.scss'],
})
export class ResetPasswordConfirmationComponent implements OnInit {
  public imgSrc = Constants.USGM_LOGO;
  public isMobileView = false;
  private email;

  constructor(
    public router: Router,
    private location: Location,
    private plt: Platform,
  ) {}

  public ngOnInit() {
    this.email = history.state.email;
    // if (this.plt.platforms().indexOf('cordova') >= 0) {
    //   this.imgSrc = Constants.USGM_MINI_LOGO;
    //   this.isMobileView = true;
    // }
  }

  public backBtnClicked() {
    this.location.back();
  }

  public backToLoginPage() {
    this.router.navigate([`/${AppRoutes.login}`]);
  }
}
