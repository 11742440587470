import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { MicroserviceType } from '../models/enums/microservices';
import { urlEncodeQueryParams } from '../utils/helpers';

interface ICaptchaProtectedParams {
  token: string;
  captchaVersion: string;
}

@Injectable()
export class ApiMapping {
  public static MAIL_FOLDERS_ROUTE = 'mail-folders';

  private static URL_MAP = {
    [MicroserviceType.ACCOUNT]: environment.accountApiUrl,
    [MicroserviceType.MAIL]: environment.mailApiUrl,
    [MicroserviceType.PAYMENT]: environment.paymentsServerUrl,
  };

  private accountServerUrl = environment.accountApiUrl;
  private paymentsServerUrl = environment.paymentsServerUrl;
  private mailServerUrl = environment.mailApiUrl;
  private notificationsServerUrl = environment.notificationsApiUrl;
  private postmenServerUrl = environment.postmenApiUrl;
  private geolocationServerUrl = environment.geolocationApiUrl;

  constructor() {}

  facebookAuth() {
    return `${this.accountServerUrl}/auth/facebook`;
  }

  downloadIds(id: string, token?: string, captchaVersion?: number) {
    return `${this.accountServerUrl}/download/document/${id}?token=${token}&cap_version=${captchaVersion}`;
  }

  downloadForms(id, token?: string, captchaVersion?: number) {
    return `${this.accountServerUrl}/download/form/${id}?token=${token}&cap_version=${captchaVersion}`;
  }

  googleAuth() {
    return `${this.accountServerUrl}/auth/google`;
  }

  amazonAuth() {
    return `${this.accountServerUrl}/auth/amazon`;
  }

  signupUser() {
    return `${this.accountServerUrl}/signup`;
  }

  loginUser(token, cap_version) {
    return `${this.accountServerUrl}/login?token=${token}&cap_version=${cap_version}`;
  }

  logoutUser() {
    return `${this.accountServerUrl}/logout`;
  }

  authNsUser() {
    return `${this.accountServerUrl}/auth/native`;
  }

  refreshSession() {
    return `${this.accountServerUrl}/refresh/token`;
  }

  getEnvironmentSecrets() {
    return `${this.accountServerUrl}/environment`;
  }

  getDefaultWarehouses() {
    return `${this.accountServerUrl}/warehouse/default`;
  }

  getApplicationSettings() {
    return `${this.accountServerUrl}/application/settings`;
  }

  getWarehousesByState(query) {
    return `${this.accountServerUrl}/warehouse/by/address?term=${query}`;
  }

  assignWarehouse() {
    return `${this.accountServerUrl}/user/assign/warehouse`;
  }

  getPlans(includeNonInitials?: boolean, preSelectedPlanId?: string): string {
    if (includeNonInitials) {
      return `${this.paymentsServerUrl}/plans?initials=false`;
    }
    let result = `${this.paymentsServerUrl}/plans`;
    if (preSelectedPlanId) {
      result += `?preselected=${preSelectedPlanId}`;
    }
    return result;
  }

  getPlansByWarehouse(warehouseId): string {
    return `${this.accountServerUrl}/warehouse/plans?warehouse=${warehouseId}`;
  }

  getUserVendorDetails() {
    return `${this.paymentsServerUrl}/payment/vendor`;
  }

  getUserSubscriptions() {
    return `${this.paymentsServerUrl}/subscriptions`;
  }

  updateUserSubscriptionForVendor(vendorSubscriptionId: string) {
    return `${this.paymentsServerUrl}/subscription/update/vendor/${vendorSubscriptionId}`;
  }

  closeUserAccount() {
    return `${this.accountServerUrl}/customer/updateStatus`;
  }

  scheduledCloseUserAccount() {
    return `${this.accountServerUrl}/customer/schedule/account/closure`;
  }

  getCouponByCode(couponCode: string): string {
    return `${this.paymentsServerUrl}/coupon/code/${couponCode}`;
  }

  getReferrerByCode(referrerCode: string): string {
    return `${this.paymentsServerUrl}/referrer/check/${referrerCode}`;
  }

  planOptions(planId: any) {
    return `${this.accountServerUrl}/plan-options/${planId}`;
  }

  selectAddress() {
    return `${this.accountServerUrl}/select-address`;
  }

  createSubscription() {
    return `${this.paymentsServerUrl}/subscribe`;
  }

  getInbox(page: number, pageSize: number, folderId?: number) {
    let url = `${this.mailServerUrl}/inbox?page=${page}&page_size=${pageSize}`;
    if (folderId) {
      url += `&folder_id=${folderId}`;
    }
    return url;
  }

  getQuarantine(page: number, pageSize: number) {
    return `${this.mailServerUrl}/quarantine?page=${page}&page_size=${pageSize}`;
  }

  putInboxRead() {
    return `${this.mailServerUrl}/inbox/unread`;
  }

  putQuarantineRead() {
    return `${this.mailServerUrl}/quarantine/unread`;
  }

  getScanPricing() {
    return `${this.mailServerUrl}/scan/pricing`;
  }

  confirmScan() {
    return `${this.mailServerUrl}/scan`;
  }

  getScanDetails(scanId: any) {
    return `${this.mailServerUrl}/scan/${scanId}`;
  }

  getPendingScans(page: number, pageSize: number, scanType: string, folderId: number) {
    return `${this.mailServerUrl}/scan/pending?page=${page}&category_type=${scanType}&page_size=${pageSize}&folder_id=${folderId}`;
  }

  getCompletedScans(page: number, pageSize: number, scanType: string, folderId: number) {
    return `${this.mailServerUrl}/scan/completed?page=${page}&category_type=${scanType}&page_size=${pageSize}&folder_id=${folderId}`;
  }

  cancelScan(scanId: any) {
    return `${this.mailServerUrl}/scan/cancel/${scanId}`;
  }

  viewScan(scanId: any) {
    return `${this.mailServerUrl}/view/scan/${scanId}`;
  }

  downloadScan(scanId: any) {
    return `${this.mailServerUrl}/download/scan/${scanId}`;
  }

  markScanRead() {
    return `${this.mailServerUrl}/scan/mark/read`;
  }

  deleteScan(scanId: any) {
    return `${this.mailServerUrl}/scan/delete/${scanId}`;
  }

  deleteMultipleScans() {
    return `${this.mailServerUrl}/scan/multiple/delete`;
  }

  exportMultipleScans({ captchaVersion, token }: ICaptchaProtectedParams) {
    const args = {
      token,
      cap_version: captchaVersion,
    };
    return `${this.accountServerUrl}/scan/batch/export?${urlEncodeQueryParams(args)}`;
  }

  getExportedScans() {
    return `${this.accountServerUrl}/scan/batch/export`;
  }

  getShippingInfo() {
    return `${this.mailServerUrl}/ship/info`;
  }

  getInvoiceDetails(invoiceId: string): string {
    return `${this.paymentsServerUrl}/invoice/vendor/${invoiceId}`;
  }

  createUserAddress(userId) {
    return `${this.accountServerUrl}/user/${userId}/address`;
  }

  updateUserAddress(user_id, address_id) {
    return `${this.accountServerUrl}/user/${user_id}/address/${address_id}`;
  }

  deleteUserAddress(address_id) {
    return `${this.accountServerUrl}/user/address/${address_id}/delete`;
  }

  getUserAddresses(user_id) {
    return `${this.accountServerUrl}/user/${user_id}/addresses`;
  }

  getUserAddressesByType(user_id, addressType) {
    return `${this.accountServerUrl}/user/${user_id}/addresses?address_type=${addressType}`;
  }

  setDefaultUserAddress(user_id, address_id) {
    return `${this.accountServerUrl}/user/${user_id}/address/${address_id}/default`;
  }

  getShippersForItems() {
    return `${this.postmenServerUrl}/rates`;
  }

  validateAddress() {
    return `${this.postmenServerUrl}/validateAddress`;
  }

  submitShippingRequest() {
    return `${this.mailServerUrl}/shipment`;
  }

  getShipmentDetails(shipmentId: any) {
    return `${this.mailServerUrl}/admin/shipment/${shipmentId}`;
  }

  getShipmentById(shipmentId: any) {
    return `${this.mailServerUrl}/shipment/${shipmentId}`;
  }

  editShippingRequest(shipmentId: any) {
    return `${this.mailServerUrl}/shipment/${shipmentId}?userSubmitted=true`;
  }

  getPendingShipments(page: number, pageSize: number, shipmentType: string) {
    return `${this.mailServerUrl}/shipment/pending?page=${page}&category_type=${shipmentType}&page_size=${pageSize}`;
  }

  getCompletedShipments(page: number, pageSize: number, shipmentType: string) {
    return `${this.mailServerUrl}/shipment/completed?page=${page}&category_type=${shipmentType}&page_size=${pageSize}`;
  }

  cancelShipment(shipmentId: any) {
    return `${this.mailServerUrl}/shipment/${shipmentId}/cancel?is_user_submitted=true`;
  }

  updateInboxIssue(reportedIssueId: any) {
    return `${this.mailServerUrl}/report/issue/${reportedIssueId}/post`;
  }

  getInboxIssueDetail(issueId: any) {
    return `${this.mailServerUrl}/issue/${issueId}`;
  }

  resolveReportedIssue(reportedIssueId: any) {
    return `${this.mailServerUrl}/report/issue/${reportedIssueId}/resolve`;
  }

  updateScanIssue(scanId: any) {
    return `${this.mailServerUrl}/scan/${scanId}/issue/post`;
  }

  updateShipmentIssue(shipmentId: any) {
    return `${this.mailServerUrl}/shipment/${shipmentId}/issue/post`;
  }

  reportedIssueByCustomer() {
    return `${this.mailServerUrl}/report/issue/v2`;
  }

  getDiscards(pageId: number, pageSize: number) {
    return `${this.mailServerUrl}/mail/discard?page=${pageId}&page_size=${pageSize}`;
  }

  discardMailRequest() {
    return `${this.mailServerUrl}/mail/discard/request`;
  }

  postDeclarationInfo(ItemId: number) {
    return `${this.mailServerUrl}/admin/shipment/add/item/${ItemId}/declarations`;
  }

  verifyGetAddress(user_id, address_type): string {
    return `${this.accountServerUrl}/user/${user_id}/addresses?address_type=${address_type}`;
  }

  generateAndSendMFACode(userId: string): string {
    return `${this.accountServerUrl}/mfa/code/generate?user=${userId}`;
  }

  getUserAccountStatus(): string {
    return `${this.accountServerUrl}/onboarding/user/verify/account/statuses`;
  }

  getAccountNames(): string {
    return this.accountServerUrl + `/user/account/names`;
  }

  getAccountNamesWithOnlyStatus(): string {
    return this.accountServerUrl + `/account/users/all/status`;
  }

  uploadPDF(userId): string {
    return this.accountServerUrl + `/onboarding/user/form/file?userId=${userId}`;
  }

  getIDsDocumentsOfUser(userId?: string, token?: string, captchaVersion?: number, includeFileUrl?: boolean): string {
    const queryParams = {
      userId: userId,
      token: token,
      cap_version: captchaVersion,
      include_file_url: !includeFileUrl,
    };
    return this.accountServerUrl + `/onboarding/user/documents?${urlEncodeQueryParams(queryParams)}`;
  }

  saveIDsDocumentsOfUser(userId): string {
    return this.accountServerUrl + `/user/documents/upload?userId=${userId}`;
  }

  deleteIDsDocumentsOfUser(documentId): string {
    return this.accountServerUrl + `/onboarding/document/${documentId}`;
  }

  updateChildUsers(): string {
    return this.accountServerUrl + `/user/names/update`;
  }

  updateSubscription(): string {
    return this.accountServerUrl + `/user/subscription/update`;
  }

  saveAccountNames(): string {
    return this.accountServerUrl + `/user/accounts/name`;
  }

  deleteAccountNames(userId): string {
    return this.accountServerUrl + `/onboarding/user/secondary/account/delete/${userId}`;
  }

  createLabel(): string {
    return `${this.accountServerUrl}/label/new`;
  }

  recreateForm(formId): string {
    return `${this.accountServerUrl}/onboarding/form/recreate/${formId}`;
  }

  getUserAnnouncements(page: number) {
    return `${this.notificationsServerUrl}/announcements?page=${page}`;
  }

  readUserAnnouncement(announcementId: string) {
    return `${this.notificationsServerUrl}/user/read/announcement/${announcementId}`;
  }

  archiveUserAnnouncement(announcementId: string) {
    return `${this.notificationsServerUrl}/user/archive/announcement/${announcementId}`;
  }

  getCountries(): string {
    return `${this.geolocationServerUrl}/countries`;
  }

  getStates(countryCodes: string): string {
    return `${this.geolocationServerUrl}/states/${countryCodes}`;
  }

  getNotaryPlans() {
    return `${this.accountServerUrl}/admin/notary/plans`;
  }

  getnotaryPlansByUserId(userId?: number) {
    return `${this.accountServerUrl}/notary/plan/user${userId ? '?userId=' + userId : ''}`;
  }

  saveNotaryRequest(planId) {
    return `${this.accountServerUrl}/user/notary/plan/${planId}`;
  }

  getNotaryPhysicalAddress() {
    return `${this.accountServerUrl}/notary/shipping/address`;
  }

  getPortalSessionObject(customerId) {
    return `${this.paymentsServerUrl}/portal/session/${customerId}`;
  }

  getSettingsAccountNamesOnlyStatus(userId: string): string {
    return `${this.accountServerUrl}/account/users/all/status?userId=${userId}`;
  }

  submitAccountDetails(user_id: any) {
    return `${this.accountServerUrl}/user/${user_id}`;
  }

  getShipMethods() {
    return `${this.accountServerUrl}/shipping/methods`;
  }

  getShipmentSettings(user_setting_type: string) {
    return `${this.accountServerUrl}/user/settings/options?user_setting_type=${user_setting_type}`;
  }

  saveShipmentSettings(user_id: string) {
    return `${this.accountServerUrl}/user/setting/options?user_id=${user_id}`;
  }

  updateShipmentSettings(user_id: string, user_settings_id: string) {
    return `${this.accountServerUrl}/user/setting/options/?user_setting_id=${user_settings_id}&user_id=${user_id}`;
  }

  getUserInvoices(page: number): string {
    return `${this.paymentsServerUrl}/invoices?page=${page}`;
  }

  getUserCreditNotes(page: number): string {
    return `${this.paymentsServerUrl}/credits?page=${page}`;
  }

  legacyMigrationRedirectUser() {
    return `${this.accountServerUrl}/legacy/migration/redirect/user`;
  }

  warehouseSubscription(warehouseId: number) {
    return `${this.accountServerUrl}/warehouse/subscribe-coming-soon/${warehouseId}`;
  }

  legacyMigrationGenerateToken() {
    return `${this.accountServerUrl}/legacy/migration/generate/token`;
  }

  getInvoicePdfUrl(invoiceId: string): string {
    return `${this.paymentsServerUrl}/invoice/pdf/${invoiceId}`;
  }

  getCreditPdfUrl(creditId: string, invoiceId: string): string {
    return `${this.paymentsServerUrl}/credit/pdf/${invoiceId}/${creditId}`;
  }

  getUserUnbilledCharges(page: number): string {
    return `${this.paymentsServerUrl}/unbilled/charges?page=${page}`;
  }

  getUserInfo(user_id: any) {
    return `${this.accountServerUrl}/user/${user_id}`;
  }

  getUserOnboardForm(user_id: any) {
    return `${this.accountServerUrl}/onboarding/user/id/form?userId=${user_id}`;
  }

  setUserOnboardForm(user_id: any) {
    return `${this.accountServerUrl}/onboarding/user/form?userId=${user_id}`;
  }

  getUserAllForm1583(user_id: any) {
    return `${this.accountServerUrl}/onboarding/user/form?userId=${user_id}`;
  }

  getDownloadForm(userId?: number, token?: string, captchaVersion?: number) {
    const queryParams = {
      userId: userId,
      token: token,
      cap_version: captchaVersion,
    };
    return `${this.accountServerUrl}/download/form?${urlEncodeQueryParams(queryParams)}`;
  }

  getDownloadPdfForm(userId?: string, token?: string, captchaVersion?: number): string {
    const queryParams = {
      userId: userId,
      token: token,
      cap_version: captchaVersion,
    };
    return `${this.accountServerUrl}/download/form?${urlEncodeQueryParams(queryParams)}`;
  }

  getFormsList(user_id: string) {
    return `${this.accountServerUrl}/onboarding/user/form?userId=${user_id}&fromInbox=true`;
  }

  createSignNowDocuments(id?: string) {
    return `${this.accountServerUrl}/onboarding/form/create/zohosign/forms${id ? '?newUserId=' + id : ''}`;
  }

  getPlanById(plan_id: string) {
    return `${this.paymentsServerUrl}/plan/${plan_id}`;
  }

  getUserFormDownload(userId: string, token: string, captchaVersion: number) {
    return `${this.accountServerUrl}/download/form?userId=${userId}&token=${token}&cap_version=${captchaVersion}`;
  }

  zohoSignSendReminder(userId: string) {
    return `${this.accountServerUrl}/onboarding/form/create/zohosign/send/reminder?userId=${userId}`;
  }

  getReferrerByUserId(userId) {
    return `${this.accountServerUrl}/user/referrer/link/details?userId=${userId}`;
  }

  getReferrerUsersByReferrerId(userId) {
    return `${this.accountServerUrl}/user/referred/list?userId=${userId}`;
  }

  aboutReferrals() {
    return `${this.accountServerUrl}/about/referral`;
  }

  getBoxNumberByUser(userId) {
    return `${this.accountServerUrl}/box/user/${userId}`;
  }

  triggerResetPassword() {
    return `${this.accountServerUrl}/reset-password/trigger`;
  }

  verifyTokenPasswordUpdate() {
    return `${this.accountServerUrl}/reset-password/validate/token`;
  }

  resetPasswordUpdate() {
    return `${this.accountServerUrl}/reset-password/update`;
  }

  getDimensions(): string {
    return `${this.geolocationServerUrl}/packing/dimensions`;
  }

  unsubscribeEmail() {
    return `${this.accountServerUrl}/user/email/unsubscribe`;
  }

  subscribeEmail() {
    return `${this.accountServerUrl}/user/email/subscribe`;
  }

  collectPaymentForInvoice() {
    return `${this.paymentsServerUrl}/payment/collect/new`;
  }

  getAllScanBundlePlans() {
    return `${this.paymentsServerUrl}/scan-bundle/`;
  }

  getUserScanBundle() {
    return `${this.paymentsServerUrl}/scan-bundle/user`;
  }

  buyScanBundle(scanBundleId: number) {
    return `${this.paymentsServerUrl}/scan-bundle/purchase/${scanBundleId}`;
  }

  cancelScanBundle(scanBundleId: number) {
    return `${this.paymentsServerUrl}/scan-bundle/cancel/${scanBundleId}`;
  }

  getMoveToFolder(folderId: number, microservice: MicroserviceType = MicroserviceType.MAIL): string {
    const serviceUrl = ApiMapping.URL_MAP[microservice];
    return `${serviceUrl}/move-to-folder/${folderId}`;
  }

  getCRUDServiceDeletePath(routeName: string, id: number, microservice: MicroserviceType = MicroserviceType.ACCOUNT) {
    const serviceUrl = ApiMapping.URL_MAP[microservice];
    return `${serviceUrl}/${routeName}/${id}`;
  }

  getCRUDServiceSoftDeletePath(routeName: string, id: number, microservice: MicroserviceType = MicroserviceType.ACCOUNT) {
    const serviceUrl = ApiMapping.URL_MAP[microservice];
    return `${serviceUrl}/${routeName}/soft/${id}`;
  }

  getCRUDServiceGetPath(routeName: string, id: number, microservice: MicroserviceType = MicroserviceType.ACCOUNT) {
    const serviceUrl = ApiMapping.URL_MAP[microservice];
    return `${serviceUrl}/${routeName}/${id}`;
  }

  getCRUDServiceCreatePath(routeName: string, microservice: MicroserviceType = MicroserviceType.ACCOUNT): string {
    const serviceUrl = ApiMapping.URL_MAP[microservice];
    return `${serviceUrl}/${routeName}`;
  }

  getCRUDServiceUpdatePath(routeName: string, id: number, microservice: MicroserviceType = MicroserviceType.ACCOUNT): string {
    const serviceUrl = ApiMapping.URL_MAP[microservice];
    return `${serviceUrl}/${routeName}/${id}`;
  }

  getCRUDServiceListPath(crudName: string, microservice: MicroserviceType = MicroserviceType.ACCOUNT): string {
    const serviceUrl = ApiMapping.URL_MAP[microservice];
    return `${serviceUrl}/${crudName}`;
  }

  buildQueryParams(originalUrl: string, page: number = 0, perPage: number = 10, orderBy: string = '', orderAs: string = 'ASC', filters: any = null) {
    if (!originalUrl) {
      return '';
    }
    const appendChar: string = originalUrl.includes('?') ? '&' : '?';
    let queryString = `limit=${perPage}`;
    if (page) {
      queryString += `&offset=${page * perPage}`;
    }
    if (orderBy) {
      queryString += `&order=${orderBy},${orderAs}`;
    }
    if (filters) {
      queryString += `&filters=${JSON.stringify(filters)}`;
    }
    return `${originalUrl}${appendChar}${queryString}`;
  }

  getCustomerImportantDates(id) {
    return `${this.accountServerUrl}/customer/${id}/important/dates`;
  }

  changeWarehouse() {
    return `${this.accountServerUrl}/customer/warehouse/switch`;
  }

  submitMFACode() {
    return `${this.accountServerUrl}/mfa/code/submit`;
  }

  getAccountDetails() {
    return `${this.accountServerUrl}/account/details`;
  }

  disableMFA() {
    return `${this.accountServerUrl}/mfa/disable`;
  }

  enableMFA() {
    return `${this.accountServerUrl}/mfa/enable`;
  }

  getTeamMembers() {
    return `${this.accountServerUrl}/org/users`;
  }

  deleteTeamMember(user) {
    return `${this.accountServerUrl}/org/user/cancel/${user.uuid}`;
  }

  pauseTeamMember(user) {
    return `${this.accountServerUrl}/org/user/pause/${user.uuid}`;
  }

  unpauseTeamMember(user) {
    return `${this.accountServerUrl}/org/user/unpause/${user.uuid}`;
  }

  resendTeamMemberInvitation(user) {
    return `${this.accountServerUrl}/org/user/resend/invitation/${user.uuid}`;
  }

  updateTeamMemberRole(user) {
    return `${this.accountServerUrl}/org/user/role/update/${user.uuid}`;
  }

  getTeamMemberFolders(user) {
    return `${this.accountServerUrl}/org/user/${user.uuid}/folders`;
  }

  updateTeamMemberFolders(user) {
    return `${this.accountServerUrl}/org/user/folders/update/${user.uuid}`;
  }

  inviteTeamMembers() {
    return `${this.accountServerUrl}/org/invite`;
  }

  getOrgInviteDetails(token) {
    return `${this.accountServerUrl}/org/invite/user/get?token=${token}`;
  }

  acceptOrgInvite() {
    return `${this.accountServerUrl}/org/invite/accept`;
  }

  GetMailNotesByMailId(id: number, oldestFirst?: boolean) {
    return `${this.mailServerUrl}/mail/${id}/notes/all${oldestFirst ? '?oldestFirst=true' : ''}`;
  }

  AddMailNotes(mailId: number) {
    return `${this.mailServerUrl}/mail/${mailId}/add/new/note`;
  }

  EditMailNotes(noteId: number) {
    return `${this.mailServerUrl}/mail/update/note/${noteId}`;
  }

  DeleteNote(noteId: string) {
    return `${this.mailServerUrl}/mail/delete/note/${noteId}`;
  }

  extractDocumentInfo(token?: string, captchaVersion?: number) {
    return `${this.accountServerUrl}/onboarding/document/extract?token=${token}&cap_version=${captchaVersion}`;
  }

  saveCompanyAddress(companyUserUUID: string) {
    return `${this.accountServerUrl}/onboarding/company/${companyUserUUID}/address`;
  }

  getCompanyAddress(companyUserUUID: string) {
    return `${this.accountServerUrl}/onboarding/company/${companyUserUUID}/address`;
  }

  getUserFreeScans() {
    return `${this.accountServerUrl}/free-scans`;
  }

  getFormErrorsWaitingForCustomerInput() {
    return `${this.accountServerUrl}/user/form/error/requests`;
  }

  resolveFormErrorUploadPrimaryDocument(formErrorUUID: string) {
    return `${this.accountServerUrl}/user/form/error/${formErrorUUID}/resolve/upload-primary-document`;
  }

  resolveFormErrorUploadSecondaryDocument(formErrorUUID: string) {
    return `${this.accountServerUrl}/user/form/error/${formErrorUUID}/resolve/upload-secondary-document`;
  }

  resolveFormErrorAddCompanyAddress(formErrorUUID: string) {
    return `${this.accountServerUrl}/user/form/error/${formErrorUUID}/resolve/add-company-address`;
  }

  resolveFormErrorAddBillingAddress(formErrorUUID: string) {
    return `${this.accountServerUrl}/user/form/error/${formErrorUUID}/resolve/add-billing-address`;
  }

  getFormsWaitingForSignature() {
    return `${this.accountServerUrl}/user/form/pending-signature`;
  }

  submitFormSignature(formUUID: string) {
    return `${this.accountServerUrl}/user/form/${formUUID}/sign`;
  }
}
