import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'usgm-item-popup',
  templateUrl: './item-popup.component.html',
  styleUrls: ['./item-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemPopupComponent implements OnInit {
  @Input() item;

  constructor() {}

  public ngOnInit() {}
}
