import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, EventEmitter, Output, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as Services from '../../../services';
import * as moment from 'moment/moment';
import { UserType, RecordStatus, DocumentType } from '@usgm/usgm-payloads-library-front';
import { PdfViewerDialogComponent } from '../../../components/pdf-viewer-dialog';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Relation, CompanyDocumentType } from '../../../models/get-notarized.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CaptchaBaseComponent } from '../../../captcha-base-component/captcha-base-component.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Animations } from '../../../animations/element.animations';
import { ImageDialogComponent } from '../../../components/image-dialog';
import { DialogComponent } from '../../../components/dialog';
import { environment } from '../../../../environments/environment';
import { normalizeFullName, renderRelation } from '../../../utils/helpers';

@Component({
  selector: 'usgm-onboarding-id-identification',
  templateUrl: './user-id-identification.component.html',
  styleUrls: ['./user-id-identification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: Animations,
})
export class SettingsOnboardingAddIdsComponent extends CaptchaBaseComponent implements OnInit {
  protected readonly renderRelation = renderRelation;

  public primaryDocumentType = [
    DocumentType.DRIVER_LICENSE,
    'UNIFORMED_SERVICE_ID' as DocumentType,
    'US_ACCESS_CARD' as DocumentType,
    'US_UNIVERSITY_ID_CARD' as DocumentType,
    DocumentType.PASSPORT,
    'MATRICULA_CONSULAR' as DocumentType,
    'NEXUS_CARD' as DocumentType,
    DocumentType.CERTIFICATE_OF_NATURALIZATION,
    'US_PERMANENT_RESIDENT_CARD' as DocumentType,
  ].map(item => item.split('_').join(' '));

  public companyAllowedDocumentList = ['Utility Bill', 'Bank Statement', 'Lease Agreement', 'Business Rates Bill', 'Tax Bill', 'Insurance Certificate', 'Other'];

  public businessDocumentType = Object.values(CompanyDocumentType).map(item => item.split('_').join(' '));

  private secondaryDocumentType = [
    DocumentType.DRIVER_LICENSE,
    DocumentType.CURRENT_LEASE,
    'MORTGAGE_OR_DEED_OF_TRUST' as DocumentType,
    'HOME_OR_VEHICLE_INSURANCE_POLICY' as DocumentType,
    DocumentType.VEHICLE_REGISTRATION_CARD,
    DocumentType.VOTER_CARD,
  ].map(item => item.split('_').join(' '));

  public data: any;
  public loading = false;
  public fileSize = 10 * 1024 * 1024;
  public primaryIdType = '';
  public secondaryIdType = '';
  public allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'image/tiff', 'image/tif'];
  private unSubscriber: Subject<any> = new Subject();
  private selectedData: any = {};
  private primaryUploadAnimationStepIndex = 0;
  private secondaryUploadAnimationStepIndex = 0;
  private uploadAnimationTimeline = [
    { text: 'Uploading', icon: 'file_upload', keep: 3 },
    { text: 'Analyzing image', icon: 'memory', keep: 5 },
    { text: 'Extracting data', icon: 'person' },
  ];
  public noIDNeeded = false;
  public primaryIDFieldsForm: FormGroup;
  public secondaryIDAddressForm: FormGroup;
  public showPrimaryIDFields = false;
  public showSecondaryIDFields = false;
  public primaryIDExtracting = false;
  public primaryIDUploadText = '';
  public primaryIDUploadIcon = 'file_upload';
  public secondaryIDExtracting = false;
  public secondaryIDUploadText = '';
  public secondaryIDUploadIcon = 'file_upload';
  @Output() goToNextPage = new EventEmitter<any>();
  @ViewChild('primaryId') primaryId: ElementRef;
  @ViewChild('secondaryId') secondaryId: ElementRef;
  @Input() selectedUser;

  constructor(
    protected http: Services.UsgmHttp,
    public formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    protected verifyService: Services.VerifyAccountService,
    protected apiMapping: Services.ApiMapping,
    public override notificationService: Services.NotificationService,
    protected userDataService: Services.UserDataService,
    public override dialog: MatDialog,
    public override recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
  }

  public override ngOnInit() {
    this.primaryIDFieldsForm = this.formBuilder.group({
      first_name: [''],
      middle_name: [''],
      last_name: [''],
      name: ['', Validators.required],
      id_number: ['', Validators.required],
      issuing_entity: ['', Validators.required],
      expiration_date: ['', Validators.required],
    });
    this.secondaryIDAddressForm = this.formBuilder.group({
      name: ['', Validators.required],
      address_line: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postal_code: ['', Validators.required],
      country: ['', Validators.required],
    });
    this.getData();
  }

  async getData() {
    try {
      const accountDetails = await this.userDataService.getSettingsAccountNamesPromise(this.apiMapping.getSettingsAccountNamesOnlyStatus(this.userDataService.getUserId()));
      const accountNames = accountDetails['data']['accountNames'];
      const selectedUserDetails = accountNames.find(name => name.user.uuid === this.selectedUser);
      this.selectedData = {
        name: selectedUserDetails.user.name,
        id: selectedUserDetails.user.name,
        relation: selectedUserDetails.relation.relationType,
        documents: [],
      };
      if (['COMPANY', 'MINOR CHILD', Relation.DECEASED, Relation.TRUST].includes(selectedUserDetails.relation.relationType)) {
        this.noIDNeeded = true;
      }
      this.downloadIDsFirstCall(this.downloadDocuments.bind(this));
      this.cdRef.detectChanges();
    } catch (error) {
      this.loading = false;
      this.cdRef.detectChanges();
    }
  }

  private async downloadDocuments(token, captchaVersion) {
    try {
      const formDetails = await this.verifyService.getNamePromise(this.apiMapping.getIDsDocumentsOfUser(undefined, token, captchaVersion));

      const documentArray = (formDetails.documents || []).find(doc => doc.user_id === this.selectedUser)?.documents || [];

      this.selectedData.documents = documentArray;

      if (documentArray.length && documentArray[0].user_type === 'PRIMARY') {
        this.primaryIdType = documentArray[0].document_type ? documentArray[0].document_type.split('_').join(' ') : '';
      }
      if (documentArray.length > 1 && documentArray[1] && documentArray[1].user_type === 'SECONDARY') {
        this.secondaryIdType = documentArray[1].document_type ? documentArray[1].document_type.split('_').join(' ') : '';
      }
      this.data = this.selectedData;
      this.loading = false;
      this.updateDocumentFieldsAndAddressForms();
      this.cdRef.detectChanges();
    } catch (error) {
      this.loading = false;
      if (error.status === 422) {
        this.verifyCaptcha(this.downloadDocuments.bind(this));
      }
      this.cdRef.detectChanges();
    }
  }

  public onContinue() {
    if (this.noIDNeeded) {
      this.goToNextPage.emit({ page: 'VIEW_FORM' });
    } else if (!this.primaryIdType || !this.primaryIdType.trim()) {
      this.notificationService.showError('Please select primary id type');
      return false;
    } else if (this.data.relation !== Relation.MINOR_CHILD && this.data.relation !== Relation.COMPANY && (!this.secondaryIdType || !this.secondaryIdType.trim())) {
      this.notificationService.showError('Please select secondary id type');
      return false;
    } else if (this.data.documents.length === 0) {
      this.notificationService.showError('Please upload ids to proceed');
      return false;
    } else if (!this.data.documents[0].document_url && !this.data.documents[0].files) {
      this.notificationService.showError('Please upload primary id to proceed');
      return false;
    } else if (Object.keys(this.primaryIDFieldsForm.controls).some(it => this.primaryIDFieldsForm.controls[it].errors)) {
      this.notificationService.showError('Please fill details for a primary document');
      return false;
    } else if (Object.keys(this.secondaryIDAddressForm.controls).some(it => this.secondaryIDAddressForm.controls[it].errors)) {
      this.notificationService.showError('Please fill address details for a secondary document');
      return false;
    } else if (this.data.relation !== Relation.MINOR_CHILD && this.data.relation !== Relation.COMPANY && !this.data.documents[1].document_url && !this.data.documents[1].files) {
      this.notificationService.showError('Please upload secondary id to proceed');
      return false;
    } else if (this.data?.documents[0]?.status === RecordStatus.REJECTED || this.data?.documents[1]?.status === RecordStatus.REJECTED) {
      if (this.data.relation === Relation.MINOR_CHILD || this.data.relation === Relation.COMPANY) {
        if (!this.data.documents[0].files) {
          this.notificationService.showError('Please reupload primary id');
          return false;
        }
      } else {
        if (this.data.documents[0].status === RecordStatus.REJECTED && !this.data.documents[0].files) {
          this.notificationService.showError('Please reupload primary id');
          return false;
        }
        if (this.data.documents[1].status === RecordStatus.REJECTED && !this.data.documents[1].files) {
          this.notificationService.showError('Please reupload secondary id');
          return false;
        }
      }
      // tslint:disable-next-line: max-line-length
    } else if (
      (this.data.relation !== Relation.MINOR_CHILD && this.data.relation !== Relation.COMPANY && !this?.data?.documents[0]?.files && !this?.data?.documents[1]?.files) ||
      (!this?.data?.documents[0]?.files && !this?.data?.documents[1]?.files)
    ) {
      this.goToNextPage.emit({ page: 'VIEW_FORM' });
    } else if ([Relation.COMPANY, Relation.MINOR_CHILD].includes(this.data.relation) && !this?.data?.documents[0]?.files) {
      this.goToNextPage.emit({ page: 'MANAGE_ACCOUNT_NAME' });
    } else {
      this.saveIds();
    }
  }

  ifUrlIsPdf(url: string) {
    return url.toLowerCase().includes(`.pdf`);
  }

  openFormPreview(id) {
    this.downloadIDsFirstCall(this.downloadIDsFromServer.bind(this), id);
  }

  downloadIDsFromServer(token, captchaVersion, id) {
    this.dialog.open(PdfViewerDialogComponent, {
      maxWidth: 'calc(100vw - 10px)',
      panelClass: 'no-padding',
      data: {
        url: this.apiMapping.downloadIds(id, token, captchaVersion),
        fileType: `pdf`,
        v2CaptchaCallback: () => this.verifyCaptcha(this.downloadIDsFromServer.bind(this), id),
      },
    });
  }

  saveIds() {
    const formData = new FormData();
    const documentTypesString = [];
    const userTypesString = [];
    if (this.data.documents[0] && this.data.documents[0].files) {
      formData.append('document_url', this.data.documents[0].files);
      documentTypesString.push(this.primaryIdType.split(' ').join('_').toUpperCase());
      userTypesString.push(UserType.PRIMARY);
    }
    if (this.data.documents[1] && this.data.documents[1].files) {
      documentTypesString.push(this.secondaryIdType.split(' ').join('_').toUpperCase());
      userTypesString.push(UserType.SECONDARY);
      formData.append('document_url', this.data.documents[1].files);
    }
    formData.append('document_type', `${documentTypesString.join(',')}`);
    formData.append('user_type', `${userTypesString.join(',')}`);
    formData.append(
      'primary_document_fields',
      JSON.stringify({
        first_name: this.primaryIDFieldsForm.get('first_name').value,
        middle_name: this.primaryIDFieldsForm.get('middle_name').value,
        last_name: this.primaryIDFieldsForm.get('last_name').value,
        name: this.primaryIDFieldsForm.get('name').value,
        id_number: this.primaryIDFieldsForm.get('id_number').value,
        expiration_date: this.primaryIDFieldsForm.get('expiration_date').value,
        issuing_entity: this.primaryIDFieldsForm.get('issuing_entity').value,
      }),
    );
    formData.append(
      'secondary_document_address',
      JSON.stringify({
        name: this.secondaryIDAddressForm.get('name').value,
        address_line: this.secondaryIDAddressForm.get('address_line').value,
        city: this.secondaryIDAddressForm.get('city').value,
        state: this.secondaryIDAddressForm.get('state').value,
        postal_code: this.secondaryIDAddressForm.get('postal_code').value,
        country: this.secondaryIDAddressForm.get('country').value,
      }),
    );
    this.loading = true;
    this.cdRef.detectChanges();
    this.verifyService
      .saveIDsDocumentsOfUser(this.apiMapping.saveIDsDocumentsOfUser(this.selectedUser), formData)
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        apiResponse => {
          if (apiResponse) {
            this.loading = false;
            this.goToNextPage.emit({ page: 'VIEW_FORM' });
          }
          this.cdRef.detectChanges();
        },
        error => {
          this.loading = false;
          this.cdRef.detectChanges();
          this.notificationService.showError('Unable to upload ids!!');
        },
      );
  }

  onFileDropped(fileEvent: Array<any>, documentType) {
    this.validateFile(fileEvent, documentType);
  }

  validateFile(fileEvent: File[], documentType) {
    const file = fileEvent[0];
    if (file) {
      if (!this.allowedTypes.includes(file.type)) {
        this.notificationService.showWarning('Allowed types are png, jpg, jpeg, pdf, tiff, tif');
        if (documentType === 'secondary') {
          this.secondaryId.nativeElement.value = '';
        } else {
          this.primaryId.nativeElement.value = '';
        }
        return false;
      } else if (file.size > this.fileSize) {
        this.notificationService.showWarning(`uploaded file should be of size less than ${this.fileSize / (1024 * 1024)} MB.`);
        if (documentType === 'secondary') {
          this.secondaryId.nativeElement.value = '';
        } else {
          this.primaryId.nativeElement.value = '';
        }
        return false;
      }
      this.readURL(file, documentType);
    }
  }

  readURL(file, target) {
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        file.data = e.target.result;
        if (target === 'primary') {
          if (this.data.documents[0]) {
            this.data.documents[0].files = file;
          } else {
            this.data.documents.push({ files: file });
          }
        } else {
          if (this.data.documents[1]) {
            this.data.documents[1].files = file;
          } else {
            this.data.documents.push({ files: file });
          }
        }
        this.cdRef.detectChanges();
        this.runCaptchaVerificationForKYC(target, file);
      };
      reader.readAsDataURL(file);
    }
  }

  deleteFile(index) {
    if (this.data.documents[index].document_url && this.data.documents[index].status !== 'APPROVED') {
      this.deleteFileFromServer(this.data.documents[index].id, index);
    } else if (this.data.documents[index].files) {
      this.data.documents[index].files = null;
    }
    if (index === 0) {
      this.showPrimaryIDFields = false;
      this.emptyPrimaryIDFieldsForm();
    }
    if (index === 1) {
      this.emptySecondaryIDFieldsForm();
      this.showSecondaryIDFields = false;
    }
    this.cdRef.detectChanges();
  }

  deleteFileFromServer(id, index) {
    this.loading = true;
    this.cdRef.detectChanges();
    this.verifyService
      .deleteIDsDocumentsOfUser(this.apiMapping.deleteIDsDocumentsOfUser(id))
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        apiResponse => {
          if (apiResponse) {
            this.getData();
            this.cdRef.detectChanges();
          }
        },
        () => {
          this.loading = false;
          this.cdRef.detectChanges();
          this.notificationService.showError('Error occured while deleting file. Please try again.');
        },
      );
  }

  public getSecondaryDocumentTypeOptions() {
    return this.secondaryDocumentType.filter(it => it !== this.primaryIdType);
  }

  public hasErrors(fieldName: any, isPrimaryDocument: boolean = true): boolean {
    const formInstance = isPrimaryDocument ? this.primaryIDFieldsForm : this.secondaryIDAddressForm;
    return !!formInstance.controls[fieldName].errors;
  }

  public fieldEdited(fieldName: any, isPrimaryDocument: boolean = true): boolean {
    const formInstance = isPrimaryDocument ? this.primaryIDFieldsForm : this.secondaryIDAddressForm;
    return (formInstance.controls[fieldName] || {})['dirty'];
  }

  private requestKYCInformation(token, captchaVersion, [userType, file]) {
    const formData = new FormData();
    formData.append('document_type', this.primaryIdType);
    formData.append('user_type', userType.toUpperCase());
    formData.append('document_image', file);
    if (userType === 'primary') {
      this.primaryIDExtracting = true;
      this.primaryIDUploadText = 'Uploading';
    } else if (userType === 'secondary') {
      this.secondaryIDExtracting = true;
      this.secondaryIDUploadText = 'Uploading';
    }
    this.cdRef.detectChanges();
    this.startUploadAnimation(userType);
    this.http.http_post(this.apiMapping.extractDocumentInfo(token, captchaVersion), formData).then(
      (data: any) => {
        const entityMap = {};
        data?.data?.entities?.forEach(it => {
          if (it.confidence > 0.3 && !entityMap[it.entityKey]) {
            entityMap[it.entityKey] = it.entityValue;
          }
        });
        if (userType === 'primary') {
          this.primaryIDFieldsForm.controls['first_name'].patchValue(entityMap['first_name']);
          this.primaryIDFieldsForm.controls['middle_name'].patchValue(entityMap['middle_name']);
          this.primaryIDFieldsForm.controls['last_name'].patchValue(entityMap['last_name']);
          this.primaryIDFieldsForm.controls['name'].patchValue(normalizeFullName(entityMap));
          this.primaryIDFieldsForm.controls['id_number'].patchValue(entityMap['id_number'] || 'N/A');
          this.primaryIDFieldsForm.controls['issuing_entity'].patchValue(entityMap['issuing_entity'] || 'N/A');
          this.showPrimaryIDFields = true;
          this.primaryIDExtracting = false;
          const expirationDate = moment(entityMap['expiration_date']);
          if (entityMap['expiration_date'] && expirationDate.isValid()) {
            const diffDays = moment().diff(expirationDate, 'days');
            if (diffDays >= 0) {
              this.deleteFile(0);
              this.openExpiredDocumentDialog();
            } else {
              this.primaryIDFieldsForm.controls['expiration_date'].patchValue(expirationDate.format('YYYY-MM-DD'));
            }
          } else {
            this.primaryIDFieldsForm.controls['expiration_date'].patchValue('N/A');
          }
        } else if (userType === 'secondary') {
          this.secondaryIDAddressForm.controls['name'].patchValue(normalizeFullName(entityMap));
          this.secondaryIDAddressForm.controls['address_line'].patchValue(entityMap['street_address'] || '');
          this.secondaryIDAddressForm.controls['city'].patchValue(entityMap['city'] || '');
          this.secondaryIDAddressForm.controls['state'].patchValue(entityMap['state'] || '');
          this.secondaryIDAddressForm.controls['postal_code'].patchValue(entityMap['postal_code'] || '');
          this.secondaryIDAddressForm.controls['country'].patchValue('US');
          this.showSecondaryIDFields = true;
          this.secondaryIDExtracting = false;
        }
        this.cdRef.detectChanges();
      },
      (error: any) => {
        this.loading = false;
        if (error.status === 422) {
          this.verifyCaptcha(this.requestKYCInformation.bind(this), [userType, file]);
        }
        if (userType === 'primary') {
          this.showPrimaryIDFields = true;
          this.primaryIDExtracting = false;
          this.emptyPrimaryIDFieldsForm();
        } else if (userType === 'secondary') {
          this.showSecondaryIDFields = true;
          this.secondaryIDExtracting = false;
          this.emptySecondaryIDFieldsForm();
        }
        this.cdRef.detectChanges();
      },
    );
  }

  private emptyPrimaryIDFieldsForm() {
    this.primaryIDFieldsForm.controls['first_name'].patchValue('');
    this.primaryIDFieldsForm.controls['middle_name'].patchValue('');
    this.primaryIDFieldsForm.controls['last_name'].patchValue('');
    this.primaryIDFieldsForm.controls['name'].patchValue('');
    this.primaryIDFieldsForm.controls['id_number'].patchValue('');
    this.primaryIDFieldsForm.controls['issuing_entity'].patchValue('');
    this.primaryIDFieldsForm.controls['expiration_date'].patchValue('');
  }

  private emptySecondaryIDFieldsForm() {
    this.secondaryIDAddressForm.controls['name'].patchValue('');
    this.secondaryIDAddressForm.controls['address_line'].patchValue('');
    this.secondaryIDAddressForm.controls['city'].patchValue('');
    this.secondaryIDAddressForm.controls['state'].patchValue('');
    this.secondaryIDAddressForm.controls['postal_code'].patchValue('');
    this.secondaryIDAddressForm.controls['country'].patchValue('');
  }

  private updateDocumentFieldsAndAddressForms() {
    const primaryDocument = this.data?.documents[0];
    const secondaryDocument = this.data?.documents[1];
    this.primaryIDFieldsForm.controls['first_name'].patchValue(primaryDocument?.document_fields?.first_name || '');
    this.primaryIDFieldsForm.controls['middle_name'].patchValue(primaryDocument?.document_fields?.middle_name || '');
    this.primaryIDFieldsForm.controls['last_name'].patchValue(primaryDocument?.document_fields?.last_name || '');
    this.primaryIDFieldsForm.controls['name'].patchValue(primaryDocument?.document_fields?.name || '');
    this.primaryIDFieldsForm.controls['id_number'].patchValue(primaryDocument?.document_fields?.id_number || '');
    this.primaryIDFieldsForm.controls['expiration_date'].patchValue(primaryDocument?.document_fields?.expiration_date || '');
    this.primaryIDFieldsForm.controls['issuing_entity'].patchValue(primaryDocument?.document_fields?.issuing_entity || '');
    this.secondaryIDAddressForm.controls['name'].patchValue(secondaryDocument?.document_address?.name || '');
    this.secondaryIDAddressForm.controls['address_line'].patchValue(secondaryDocument?.document_address?.address_line || '');
    this.secondaryIDAddressForm.controls['city'].patchValue(secondaryDocument?.document_address?.city || '');
    this.secondaryIDAddressForm.controls['state'].patchValue(secondaryDocument?.document_address?.state || '');
    this.secondaryIDAddressForm.controls['postal_code'].patchValue(secondaryDocument?.document_address?.postal_code || '');
    this.secondaryIDAddressForm.controls['country'].patchValue(secondaryDocument?.document_address?.country || '');
    this.showPrimaryIDFields = !!primaryDocument?.uuid;
    this.showSecondaryIDFields = !!secondaryDocument?.uuid;
  }

  private startUploadAnimation(userType: string) {
    if (userType === 'primary') {
      this.primaryUploadAnimationStepIndex = 0;
    } else if (userType === 'secondary') {
      this.secondaryUploadAnimationStepIndex = 0;
    }
    this.uploadAnimationTickHandler(userType);
  }

  private uploadAnimationTickHandler(userType: string, increment = false) {
    let keep = 0;
    if (userType === 'primary') {
      if (increment) {
        this.primaryUploadAnimationStepIndex++;
      }
      this.primaryIDUploadText = this.uploadAnimationTimeline[this.primaryUploadAnimationStepIndex].text;
      this.primaryIDUploadIcon = this.uploadAnimationTimeline[this.primaryUploadAnimationStepIndex].icon;
      keep = this.uploadAnimationTimeline[this.primaryUploadAnimationStepIndex].keep;
    } else if (userType === 'secondary') {
      if (increment) {
        this.secondaryUploadAnimationStepIndex++;
      }
      this.secondaryIDUploadText = this.uploadAnimationTimeline[this.secondaryUploadAnimationStepIndex].text;
      this.secondaryIDUploadIcon = this.uploadAnimationTimeline[this.secondaryUploadAnimationStepIndex].icon;
      keep = this.uploadAnimationTimeline[this.secondaryUploadAnimationStepIndex].keep;
    }
    if (keep) {
      setTimeout(() => this.uploadAnimationTickHandler(userType, true), keep * 1000);
    }
    this.cdRef.detectChanges();
  }

  openImageFullScreen(src) {
    this.dialog.open(ImageDialogComponent, {
      minWidth: 'calc(100vw - 10px)',
      minHeight: 'calc(100vh - 10px)',
      data: { imgSrc: src },
      panelClass: 'post-dialog-container',
    });
  }

  runCaptchaVerificationForKYC(userType, file) {
    this.extractDocumentInfoFirstCall(this.requestKYCInformation.bind(this), [userType, file]);
  }

  openExpiredDocumentDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: `We regret to inform you that the primary document you have submitted appears to be expired, according to our system's records.
      To proceed, we kindly ask you to provide a current and valid document. If you believe this is a mistake and your document is indeed valid, please do not hesitate to reach out to us for
      assistance. We are committed to resolving any issues promptly. Should you need to discuss this further, please click Contact Us below or call us at +1 281-596-8965`,
      title: 'Document is expired',
      cancelText: 'Ok, got it',
      confirmText: 'Contact Us',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        window.open(`${environment.usglobalMailWordPress}/contact-us`, '_blank');
      }
    });
  }
}
