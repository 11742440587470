import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreditCardDisplayPipe } from './credit-card-display.pipe';
import { CapitalizeIDsPipe } from './capitalize-IDs.pipe';

@NgModule({
  declarations: [CreditCardDisplayPipe, CapitalizeIDsPipe],
  imports: [CommonModule],
  exports: [CreditCardDisplayPipe, CapitalizeIDsPipe],
})
export class PipesModule {}
