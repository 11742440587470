// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 767px) {
  .settings-onboarding-container {
    min-width: 500px;
    max-width: 550px;
    padding: 0px 5px;
  }
}
@media only screen and (max-width: 767px) {
  .settings-onboarding-container {
    min-width: calc(100vw - 1px);
    max-width: calc(100vw - 1px);
    padding: 0px 5px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/onboarding/process-container/process-container.component.scss"],"names":[],"mappings":"AAAA;EACE;IACE,gBAAA;IACA,gBAAA;IACA,gBAAA;EACF;AACF;AAEA;EACE;IACE,4BAAA;IACA,4BAAA;IACA,gBAAA;EAAF;AACF","sourcesContent":["@media only screen and (min-width: 767px) {\n  .settings-onboarding-container {\n    min-width: 500px;\n    max-width: 550px;\n    padding: 0px 5px;\n  }\n}\n\n@media only screen and (max-width: 767px) {\n  .settings-onboarding-container {\n    min-width: calc(100vw - 1px);\n    max-width: calc(100vw - 1px);\n    padding: 0px 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
