import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { FaqAccordionComponent } from './faq-accordion.component';

@NgModule({
  declarations: [FaqAccordionComponent],
  imports: [BrowserModule, CommonModule, MatIconModule, MatExpansionModule],
  exports: [FaqAccordionComponent],
})
export class FaqAccordionModule {}
