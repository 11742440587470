// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 480px;
  width: 100vw;
  height: 100%;
  background: #f2f6f7;
}
:host .item-details-container {
  margin: 10px;
  background: white;
  padding: 10px;
}
:host .usgm-green {
  font-weight: bold;
}
:host .image {
  max-width: 100%;
}
:host .data-row {
  display: flex;
}
:host .time-frame {
  color: #329a32;
}`, "",{"version":3,"sources":["webpack://./src/app/discard/discard-details-popup/discard-details-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;EACA,iBAAA;EACA,aAAA;AACJ;AAEE;EACE,iBAAA;AAAJ;AAGE;EACE,eAAA;AADJ;AAIE;EACE,aAAA;AAFJ;AAKE;EACE,cAAA;AAHJ","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 480px;\n  width: 100vw;\n  height: 100%;\n  background: #f2f6f7;\n\n  .item-details-container {\n    margin: 10px;\n    background: white;\n    padding: 10px;\n  }\n\n  .usgm-green {\n    font-weight: bold;\n  }\n\n  .image {\n    max-width: 100%;\n  }\n\n  .data-row {\n    display: flex;\n  }\n\n  .time-frame {\n    color: #329a32;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
