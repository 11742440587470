import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, retry } from 'rxjs/operators';
import * as Services from '../../services';
import * as SharedHelpers from '../../utils/helpers';
import { FormType } from '@usgm/usgm-payloads-library-front';
import { OnboardFormResponse, Relation } from '../../models/get-notarized.model';
import { AllForm } from '../../models/get-notarized.model';
import { UsgmHttp } from '../../services';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CaptchaBaseComponent } from '../../captcha-base-component/captcha-base-component.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'usgm-form-rejection-resolve',
  templateUrl: './form-rejection-resolve.component.html',
  styleUrls: ['./form-rejection-resolve.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormRejectionResolveComponent extends CaptchaBaseComponent implements OnInit {
  private unSubscriber: Subject<any> = new Subject();
  public allForm1583List: AllForm[] = [];
  public allowedTypes = ['application/pdf'];
  public errorNotes: any;
  public errorStatus: any;
  public fileSize = 10 * 1024 * 1024;
  public loading = false;
  public userNames = [];
  public settingPageLoad: boolean;
  @Output() navigateBackToForm = new EventEmitter<any>();
  @Output() goToNextPage = new EventEmitter<any>();
  @Input() selectedId: number;
  @Input() activePlan: number;

  constructor(
    private cdRef: ChangeDetectorRef,
    protected verifyService: Services.VerifyAccountService,
    protected apiMapping: Services.ApiMapping,
    public override notificationService: Services.NotificationService,
    protected userDataService: Services.UserDataService,
    protected http: UsgmHttp,
    public override dialog: MatDialog,
    public override recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
  }

  public override ngOnInit() {
    this.getAddedName();
    this.getUserAllForm1583();
  }

  getUserAllForm1583() {
    this.verifyService
      .getUserAllForms(this.apiMapping.getUserAllForm1583(this.selectedId))
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(response => {
        if (response && response.form1583 && response.form1583.length > 0) {
          this.allForm1583List = response.form1583;
          if (this.allForm1583List && this.allForm1583List[0] && this.allForm1583List[0].form1583) {
            if (this.allForm1583List[0].form1583.notes) {
              this.errorNotes = this.allForm1583List[0].form1583.notes;
            }
            if (this.allForm1583List[0].form1583.status) {
              this.errorStatus = this.allForm1583List[0].form1583.status;
            }
          }
        }
        SharedHelpers.detectChanges(this.cdRef);
      });
  }

  public getAddedName(): void {
    this.loading = true;
    this.verifyService
      .getName(this.apiMapping.getAccountNames())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(
        apiResponse => {
          if (apiResponse) {
            for (const user of apiResponse.data.accountNames) {
              if (user.is_from_account_verification && user.relation.relationType === Relation.MINOR_CHILD) {
                this.userNames = this.userNames.map(item => {
                  if (item.relation) {
                    item.name = item.name + `, ${user.user.name} (${user.relation.relationType})`;
                  }
                  return item;
                });
              } else {
                this.userNames.push({
                  name: user.user.name + ` (${user.relation.relationType})`,
                  id: user.user.uuid,
                  relation: user.relation.relationType,
                });
              }
            }
            this.loading = false;
            this.cdRef.detectChanges();
          }
        },
        error => {
          this.loading = false;
          this.cdRef.detectChanges();
        },
      );
  }

  uploadFormFunction(userId) {
    const fileData = this.userNames.find(item => item.id === userId);
    if (fileData && fileData.userFile) {
      const formData = new FormData();
      formData.append('form', fileData.userFile);
      formData.append('form_type', FormType.FORM_1583_NOTARIZED);
      this.loading = true;
      this.verifyService
        .uploadPDF(this.apiMapping.uploadPDF(userId), formData)
        .pipe(takeUntil(this.unSubscriber))
        .subscribe(
          apiResponse => {
            this.loading = false;
            this.cdRef.detectChanges();
            this.goBack();
          },
          error => {
            this.loading = false;
            this.cdRef.detectChanges();
            this.notificationService.showError('Error occured while uploading file. Please try again');
          },
        );
    } else {
      this.notificationService.showWarning('Please upload a pdf file before proceed');
    }
  }

  goBack() {
    this.goToNextPage.emit({ page: 'MANAGE_ACCOUNT_NAME' });
  }

  onFileDropped(fileEvent: Array<any>, userId) {
    this.validateFile(fileEvent, userId);
  }

  validateFile(fileEvent: File[], userId) {
    const file = fileEvent[0];
    if (file) {
      if (!this.allowedTypes.includes(file.type)) {
        this.notificationService.showWarning('Allowed types is pdf only');
        return false;
      } else if (file.size > this.fileSize) {
        this.notificationService.showWarning(`upload file will be less than ${this.fileSize / 1024 / 1024} MB.`);
        return false;
      }
      this.readURL(file, userId);
    }
  }

  readURL(file, userId) {
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        file.data = e.target.result;
        for (const user of this.userNames) {
          if (user.id === userId) {
            user.userFile = file;
            break;
          }
        }
        this.cdRef.detectChanges();
      };
      reader.readAsDataURL(file);
    }
  }

  recreateForm() {
    this.loading = true;
    this.verifyService
      .recreateForm(this.apiMapping.recreateForm(this.selectedId))
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        apiResponse => {
          if (apiResponse) {
            this.downloadForm1583();
          }
        },
        error => {
          this.loading = false;
          this.cdRef.detectChanges();
          this.notificationService.showError('Error occured while downloading form. Please try again');
        },
      );
  }

  downloadForm1583() {
    this.verifyService
      .getUserFormData(this.apiMapping.getUserOnboardForm(this.selectedId))
      .pipe(takeUntil(this.unSubscriber), retry(2))
      .subscribe(
        (response: OnboardFormResponse) => {
          this.loading = false;
          if (response && response.form && response.form.form_url) {
            this.downloadFormsFirstCall(this.downLoadForm.bind(this), response);
          }
          this.cdRef.detectChanges();
        },
        _error => {
          this.loading = false;
          this.cdRef.detectChanges();
          this.notificationService.showError('Unable to download file.');
        },
      );
  }

  downLoadForm(token, captchaVersion, response) {
    this.http.get(this.apiMapping.downloadForms(response.form.id, token, captchaVersion), { responseType: 'blob' }).subscribe(
      result => {
        const fileName = response.form.form_url.split('/').pop().split('#')[0].split('?')[0];
        SharedHelpers.downloadFile(result, fileName);
        this.loading = false;
        this.notificationService.showWarning('File downloaded.');
        SharedHelpers.detectChanges(this.cdRef);
      },
      error => {
        if (error.status === 422) {
          this.verifyCaptcha(this.downLoadForm.bind(this), response);
        } else {
          this.loading = false;
          SharedHelpers.detectChanges(this.cdRef);
          this.notificationService.showError('Unable to downloaded form.');
        }
      },
    );
  }

  clearFormValue(): void {
    this.navigateBackToForm.emit();
  }
}
