// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 480px;
  width: 100vw;
  height: 100%;
  background: #f2f6f7;
}
:host .shipment-details-container {
  margin: 10px;
  padding: 10px;
  background: white;
}
:host .usgm-green {
  font-weight: bold;
}
:host .image {
  max-width: 100%;
}
:host .shipment-details {
  font-size: 128%;
}
:host .mail-id-text {
  font-size: 128%;
  color: #333 !important;
}
:host .data-row {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/app/shipments/shipment-details-popup/shipment-details-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,iBAAA;AACJ;AAEE;EACE,iBAAA;AAAJ;AAGE;EACE,eAAA;AADJ;AAIE;EACE,eAAA;AAFJ;AAKE;EACE,eAAA;EACA,sBAAA;AAHJ;AAME;EACE,aAAA;AAJJ","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 480px;\n  width: 100vw;\n  height: 100%;\n  background: #f2f6f7;\n\n  .shipment-details-container {\n    margin: 10px;\n    padding: 10px;\n    background: white;\n  }\n\n  .usgm-green {\n    font-weight: bold;\n  }\n\n  .image {\n    max-width: 100%;\n  }\n\n  .shipment-details {\n    font-size: 128%;\n  }\n\n  .mail-id-text {\n    font-size: 128%;\n    color: #333 !important;\n  }\n\n  .data-row {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
