// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.loader-container {
  width: 100%;
}

.main-container {
  min-width: 300px;
  max-width: 500px;
}

.day-box {
  width: 40%;
  margin-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/scanning-options/scanning-options.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,UAAA;EACA,iBAAA;AACF","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  width: 100%;\n  height: 100%;\n  padding: 5px;\n}\n\n.loader-container {\n  width: 100%;\n}\n\n.main-container {\n  min-width: 300px;\n  max-width: 500px;\n}\n\n.day-box {\n  width: 40%;\n  margin-left: 15px\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
