export const emptyGetShippersPostData = {
  ship_to: {
    contact_name: '',
    phone: '',
    email: '',
    street1: '',
    city: '',
    postal_code: '',
    state: '',
    country: '',
  },
  parcels: [
    {
      description: '',
      box_type: 'custom',
      weight: {
        value: 0,
        unit: 'lb',
      },
      dimension: {
        width: 0,
        height: 0,
        depth: 0,
        unit: 'in',
      },
      items: [],
    },
  ],
};

export const emptyParcelItem = {
  description: 'Food Bar',
  quantity: 2,
  price: {
    amount: 3,
    currency: 'USD',
  },
  weight: {
    value: 0.6,
    unit: 'lb',
  },
};
