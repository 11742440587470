import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileSaverModule } from 'ngx-filesaver';
import { IonicModule } from '@ionic/angular';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { DirectivesModule } from '../directives';

import { ShipmentsComponent } from './shipments.component';
import { ShipmentDetailsPopupComponent } from './shipment-details-popup/shipment-details-popup.component';
import { ShipmentsTableComponent } from './shipments-table/shipments-table.component';
import { InboxModule } from '../inbox';

import { ComponentsModule } from '../components';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';

@NgModule({
  declarations: [ShipmentsComponent, ShipmentDetailsPopupComponent, ShipmentsTableComponent],
  imports: [
    BrowserModule,
    CommonModule,
    ComponentsModule,
    DirectivesModule,
    FileSaverModule,
    FormsModule,
    InboxModule,
    IonicModule,
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatTooltipModule,
    PinchZoomModule,
    ReactiveFormsModule,
    RouterModule,
    VirtualScrollerModule,
    ComponentsModule,
  ],
  exports: [ShipmentsComponent, ShipmentDetailsPopupComponent, ShipmentsTableComponent],
})
export class ShipmentsModule {}
