export * from './register.component';
export * from './register-header/header.component';
export * from './select-plan/select-plan.component';
export * from './select-option/select-option.component';
export * from './select-term/select-term.component';
export * from './select-region/select-region.component';
export * from './signup-page/signup-page.component';
export * from './payment-info/payment-info.component';
export * from './payment-complete/payment-complete.component';
export * from './select-warehouse-by-address/select-warehouse-by-address.component';
export { RegisterModule } from './register.module';
