import { Component, OnInit, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditLabelDialogComponent } from '../../components/sidebar';
import { MailFolderService, UserDataService } from '../../services';
import * as SharedHelpers from '../../utils/helpers';
import { DialogComponent } from '../../components/dialog';

@Component({
  selector: 'usgm-manage-folder',
  templateUrl: './manage-folder.component.html',
  styleUrls: ['./manage-folder.component.scss'],
})
export class ManageFolderComponent implements OnInit, OnDestroy {
  @Output() closeNav = new EventEmitter();
  public folders: any[] = [];

  constructor(
    public dialog: MatDialog,
    public maiFolderService: MailFolderService,
    public userDataService: UserDataService,
    private cdr: ChangeDetectorRef,
  ) {}

  async loadFolders(force: boolean = false): Promise<void> {
    this.folders = await this.userDataService.getUserFolders(force);
    SharedHelpers.detectChanges(this.cdr);
  }

  public menuItemChildOptionClicked(menuItemChild, calloutOption) {
    if (calloutOption === 'EDIT') {
      this.openDialog(menuItemChild, calloutOption);
    } else if (calloutOption === 'REMOVE FOLDER') {
      console.log('delete label');
    }
  }

  public deleteConfirmation(folder: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: `Deleting this folder will revert your items back into your MailBox`,
      title: 'Are you sure you wish to delete this folder?',
      cancelText: 'No',
      confirmText: 'Yes',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.maiFolderService.delete(folder.id).subscribe(() => {
          this.loadFolders(true);
        });
      }
    });
  }

  public openDialog(menuItemChild, calloutOption): void {
    const dialogRef = this.dialog.open(EditLabelDialogComponent, {
      width: '400px',
      data: { data: menuItemChild, calloutOption },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadFolders(true);
      console.log('The dialog was closed', result);
    });
  }

  ngOnInit() {
    this.loadFolders(true);
    this.userDataService.foldersUpdate.subscribe(folders => {
      this.folders = folders;
    });
  }

  ngOnDestroy() {}

  closeSideNav() {
    this.closeNav.emit({});
  }

  addNewFolder() {
    this.openDialog({}, {});
    return false;
  }

  editFolder(folder: any) {
    this.openDialog(folder, {});
    return false;
  }

  public deleteFolder(folder: any) {
    this.deleteConfirmation(folder);
  }
}
