// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 480px;
  width: 100%;
  height: 100%;
  padding: 5px;
}
:host .content-container {
  padding-top: 0px;
}
:host ::ng-deep .mat-mdc-checkbox.mat-accent {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
}
:host ::ng-deep .mat-mdc-checkbox.mat-accent .mat-checkbox-inner-container {
  margin: 4px 8px auto 0;
}

.day-box {
  width: 40%;
  margin-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/automatic-forwarding/automatic-forwarding.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AACF;AACE;EACE,gBAAA;AACJ;AAGI;EACE,+HAAA;AADN;AAEM;EACE,sBAAA;AAAR;;AAMA;EACE,UAAA;EACA,iBAAA;AAHF","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 480px;\n  width: 100%;\n  height: 100%;\n  padding: 5px;\n\n  .content-container {\n    padding-top: 0px;\n  }\n\n  ::ng-deep {\n    .mat-mdc-checkbox.mat-accent {\n      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */\n      .mat-checkbox-inner-container {\n        margin: 4px 8px auto 0;\n      }\n    }\n  }\n}\n\n.day-box {\n  width: 40%;\n  margin-left: 15px\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
