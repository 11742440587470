export interface OnboardFormResponse {
  form: OnboardForm;
  message: string;
  status: number;
}

export interface FormDetails {
  business_city: string;
  business_type: string;
  applicant_name: string;
  business_state: string;
  business_address: string;
  delivery_address: string;
  business_zip_code: string;
  form_notarize_date: string;
  applicant_firm_name: string;
  applicant_home_city: string;
  applicant_home_state: string;
  business_phone_number: string;
  delivery_address_city: string;
  applicant_home_address: string;
  applicant_phone_number: string;
  delivery_address_state: string;
  signature_of_applicant: string;
  applicant_home_zip_code: string;
  applicant_name_on_mails: string;
  additional_names_in_form: string;
  corporation_business_name: string;
  delivery_address_zip_code: string;
  signature_of_notary_agent: string;
  applicant_firm_member_names: string;
  new_authorized_delivery_name: string;
  new_authorized_delivery_address: string;
  corporation_officers_names_address: string;
  new_authorized_delivery_address_city: string;
  new_authorized_delivery_address_state: string;
  new_authorized_delivery_address_zip_code: string;
}

export interface OnboardForm {
  id: number;
  user_id: number;
  form_url: string;
  form_type: string;
  notes: any;
  status: string;
  added_by: any;
  form_1583_fields: FormDetails;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: any;
}

export interface AllForm {
  user_id: number;
  form1583: OnboardForm;
}

export enum Relation {
  SYSTEM = 'SYSTEM',
  ENTITY = 'ENTITY',
  USER = 'USER',
  PRIMARY = 'PRIMARY',
  SPOUSE = 'SPOUSE',
  ADULT = 'ADULT',
  MINOR_CHILD = 'MINOR CHILD',
  CHILD = 'CHILD (18+)',
  COMPANY = 'COMPANY',
  EMPLOYEE = 'EMPLOYEE',
  DECEASED = 'DECEASED',
  TRUST = 'TRUST',
  OTHER = 'OTHER',
}

export enum CompanyDocumentType {
  BANK_STATEMENT = 'BANK STATEMENT',
  INSURANCE_CERTIFICATE = 'INSURANCE CERTIFICATE',
  LEASE_MORTGAGE_STATEMENT = 'LEASE/MORTGAGE STATEMENT',
  TAX_INVOICE = 'TAX INVOICE',
  UTILITY_BILL = 'UTILITY BILL',
  OTHER = 'OTHER',
}
