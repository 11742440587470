import { Injectable } from '@angular/core';
import { AccountStatus, AddressType, FormType, IAddressAttributes, Relation, SubscriptionStatus } from '@usgm/usgm-payloads-library-front';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import jwt_decode from 'jwt-decode';
import { interval, Observable, Subject } from 'rxjs';
import { Platform } from '@ionic/angular';
// import { Facebook } from '@ionic-native/facebook/ngx';
// import { GooglePlus } from '@ionic-native/google-plus/ngx';
// import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { MenuItems, MenuItemStoragePrefix, RESOLVE_FORM_ERRORS_PAGE_NAME } from '../models/constants/menu-items.constant';
import { PushNotificationsService } from './push-notifications.service';
import { ApiMapping } from './api-mapping.service';
import { UsgmHttp } from './usgm-http.service';
import Version from '../../assets/app-version.json';
import { AppRoutes } from '../models/constants/app-routes.constant';
import { environment } from '../../environments/environment';
import { AnalyticsEvent } from '../models/enums/analytics-events';
// import * as SharedHelpers from '../utils/helpers';
import { VerifyAccountService } from '../verify-account/verify-account.service';
import { NotificationService } from './notification.service';
import { AccountNamesModel } from '../models/account-names-model';
import { MailFolderService } from './mail-folders.service';
import { VerifyAccountService as VerifyAccountServices } from './verify-account.service';
import { switchMap } from 'rxjs/operators';
import { WarehouseConfiguration } from '../models/warehouse';
import { IFormError, IFormToSign } from '../models/form-error';

declare var UXCam: any;
const Chargebee: any = (window as any).Chargebee;

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  public menuCountUpdated$ = new Subject<any>();
  public heightUpdated$ = new Subject<any>();
  public announcementsUpdated = new Subject<any>();
  public warehouseAddressFetched = new Subject<any>();
  public requestedFormErrorsFetched = new Subject<any>();
  public requestedFormsToSignFetched = new Subject<any>();
  public foldersUpdate = new Subject<any>();
  public notaryPlan;
  public orgParentId = '';
  public shouldPreventInboxNavigation = false;
  public documentRejectedStatusMessage = '';
  public onboardingCompleted = false;
  public tempUserDetails = null;
  public warehouses: any[] = [];
  public pathsThatNeedSubscription = [AppRoutes.inbox, AppRoutes.scans, AppRoutes.shipments, AppRoutes.discards, AppRoutes.settings, AppRoutes.welcome, AppRoutes.verifyAccount];
  public isMobileMode = false;
  public refreshSessionSubject = new Subject<any>();
  public redirectTo = `/${AppRoutes.inbox}`;
  public currentUserSubscription: any = {};
  public plansList = [];
  public isRefreshingSession = false;
  public warehouseEmail = '';
  public warehousePhone = '';
  public warehouseConfiguration: WarehouseConfiguration;
  public channelPartnerName = '';
  public warehouseOperationHours = null;
  private _isMaintenanceEnabled = false;
  public maintenanceEnabledSubject = new Subject<any>();

  private userAnnouncementsData: any;
  private appVersion = Version.version;
  private onboardingStatus;
  private accessToken = '';
  private refreshToken = '';
  private userId = '';
  private userData: any = {};
  private roleType = '';
  private isValidSession = false;
  private screenWidth = 768;
  private loggedInNativeFbUser: any;
  private warehouseBoxNumber: string;
  private loggedInNativeGoogleUser: any;
  private userDestinationAddresses: any = {
    BILLING: [],
    SHIPPING: [],
    WAREHOUSE: [],
    CURRENT_MAILING: [],
    CHECK_DEPOSIT: [],
  };
  private userAllAddresses: any = {};
  private warehouseAddress: any;
  private vendorDetailsAvailable = false;
  private chargebeePortal;
  private subscriptionId;
  private userReferrerData: any;
  private userReferrersList: any;
  private envFetched = false;
  private folders = [];
  private foldersData: { requestTimestamp: number; isLoading: boolean } = { requestTimestamp: null, isLoading: null };
  private requestedFormErrors: IFormError[];
  private requestedFormsToSign: IFormToSign[];

  constructor(
    private router: Router,
    // private fb: Facebook,
    // private googlePlus: GooglePlus,
    private pushNotificationsService: PushNotificationsService,
    private http: UsgmHttp,
    private titleService: Title,
    // private metaDataService: Meta,
    private platform: Platform,
    private apiMapping: ApiMapping,
    protected verifyAccountService: VerifyAccountService,
    private notificationService: NotificationService,
    public verifyService: VerifyAccountServices,
    public mailFolderService: MailFolderService, // private firebaseX: FirebaseX,
  ) {
    this.setupRedirectPath();
    this.initFromLocalStorage();
    this.initNativeFbDetails();
    this.initNativeGoogleDetails();
    this.titleService.setTitle(`Legacy - US Global Mail`);
    // if (this.platform.platforms().indexOf('cordova') !== 0) {
    //   this.metaDataService.addTags([
    //     { name: 'apple-itunes-app', content: `app-id=${environment.iosAppId}` },
    //     { name: 'google-play-app', content: `app-id=${environment.androidAppId}` },
    //   ]); // web only
    // }
    this.initAllAddresses();
    this.setMenuItems();
  }

  setOnboardingStatus(status) {
    this.onboardingStatus = status;
  }

  getOnboardingStatus() {
    return this.onboardingStatus;
  }

  setupRedirectPath() {
    this.redirectTo = window.location.pathname + window.location.search; // save original url entered by user
    if (!this.redirectTo || this.redirectTo === '/') {
      this.redirectTo = localStorage.getItem('redirectTo') || AppRoutes.inbox;
    }
    if (this.redirectTo.indexOf(AppRoutes.login) >= 0) {
      this.redirectTo = AppRoutes.inbox; // redirect url can not be login
    }
    if ((this.redirectTo || '').indexOf('success=true') >= 0) {
      const paramsString = this.redirectTo.split('?')[1];
      const params = paramsString.split('&');
      const paramsMap = {};
      params.forEach(element => {
        paramsMap[element.split('=')[0]] = element.split('=')[1];
      });
      this.setSocialLoginFlag(true);
      this.setUserSession(paramsMap, true); // social login
      this.redirectTo = paramsMap['redirect_url'] || this.redirectTo.split('?')[0];
    } else if ((this.redirectTo || '').indexOf('/signup') >= 0) {
      const { search } = window.location;
      const params = new URLSearchParams(search);
      this.setPlanDetails({
        plan_id: params.get('plan_id'),
        term_id: params.get('term_id'),
      });
      this.setWarehouseId(params.get('warehouse_id'));
      this.setCouponCode(params.get('coupon_code'));
      this.setPlanVisiblity(params.get('show_plans'));
    }
    localStorage.setItem('redirectTo', this.redirectTo);
  }

  getPortalInstance() {
    if (this.vendorDetailsAvailable) {
      return {
        portalInstance: this.chargebeePortal,
        subscriptionId: this.subscriptionId,
      };
    }
    this.http.http_get(this.apiMapping.getUserVendorDetails()).then(
      response => {
        if ((response.payment_sources || []).length) {
          const vendorCustomerId = response.payment_sources[response.payment_sources.length - 1].customer.vendor_customer_id;
          const chargebeeInstance = Chargebee.getInstance();
          this.subscriptionId = response.subscription?.vendor_subscription_id;
          chargebeeInstance.setPortalSession(() => {
            return this.http.http_get(this.apiMapping.getPortalSessionObject(vendorCustomerId));
          });
          this.chargebeePortal = chargebeeInstance.createChargebeePortal();
          this.vendorDetailsAvailable = true;
        } else {
          this.vendorDetailsAvailable = false;
        }
      },
      error => {
        this.vendorDetailsAvailable = false;
      },
    );
  }

  getAppVersion() {
    return this.appVersion;
  }

  initFromLocalStorage() {
    if (localStorage.getItem('userData')) {
      this.setUserSession(JSON.parse(localStorage.getItem('userData')), true);
    }
    const menuItems = JSON.parse(JSON.stringify(MenuItems));
    menuItems.forEach(menuItem => {
      sessionStorage.setItem(`${MenuItemStoragePrefix}${menuItem.icon}`, '0');
    });
  }

  preventInboxNavigation() {
    return this.shouldPreventInboxNavigation;
  }

  checkPrimaryUserStatus(names: any[]) {
    this.shouldPreventInboxNavigation = false;
    names.forEach(name => {
      if (
        name.relation.relationType === Relation.PRIMARY &&
        ![AccountStatus.APPROVED, AccountStatus.NOTARIZED_DOCUMENT_ADDED, AccountStatus.NOTARIZED_DOCUMENT_APPROVED].includes(name.accountStatus)
      ) {
        this.shouldPreventInboxNavigation = true;
      }
    });
  }

  setDocumentRejectedStatus(name) {
    this.documentRejectedStatusMessage = '';
    switch (name.accountStatus) {
      case AccountStatus.DOCUMENT_PRIMARY_REJECTED:
        this.documentRejectedStatusMessage = 'Your primary ID was rejected. Please upload a new one.';
        break;
      case AccountStatus.DOCUMENT_SECONDARY_REJECTED:
        this.documentRejectedStatusMessage = 'Your secondary ID was rejected. Please upload a new one.';
        break;
      case AccountStatus.FORM_1583_REJECTED:
        this.documentRejectedStatusMessage = 'Your form 1583 was rejected. Please click here to view the issue.';
        break;
    }
  }

  shouldVerifyAccount() {
    return !this.notaryPlan && !this.orgParentId;
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  getAnnouncementData() {
    return this.userAnnouncementsData;
  }

  setAnnouncementData(data) {
    this.userAnnouncementsData = data;
    this.announcementsUpdated.next({});
  }

  private initNativeFbDetails() {
    this.loggedInNativeFbUser = JSON.parse(localStorage.getItem('ns-fb-logged-in-user') || '{}');
  }

  private initNativeGoogleDetails() {
    this.loggedInNativeGoogleUser = JSON.parse(localStorage.getItem('ns-google-logged-in-user') || '{}');
  }

  getNsLoggedinUser(provider: string) {
    if (provider === 'google') {
      return this.loggedInNativeGoogleUser;
    }
    return this.loggedInNativeFbUser;
  }

  setPlanDetails(params) {
    localStorage.setItem('plan', JSON.stringify(params));
  }

  getPlanDetails() {
    return JSON.parse(localStorage.getItem('plan'));
  }

  setCouponCode(couponCode) {
    if (couponCode) {
      localStorage.setItem('coupon_code', couponCode.toUpperCase());
    } else {
      localStorage.removeItem('coupon_code');
    }
  }

  getCouponCode() {
    return localStorage.getItem('coupon_code');
  }

  setPlanVisiblity(planVisibility) {
    if (planVisibility) {
      localStorage.setItem('show_plans', planVisibility);
    }
  }

  getPlanVisiblity() {
    return localStorage.getItem('show_plans') || '';
  }

  clearPlanPreferences() {
    localStorage.removeItem('coupon_code');
    localStorage.removeItem('show_plans');
    localStorage.removeItem('plan');
  }

  setWarehouseId(warehouseId) {
    if (warehouseId) {
      localStorage.setItem('warehouse_id', warehouseId);
    }
  }

  getLegacyRedirectResponse() {
    return sessionStorage.getItem('legacy_redirect_response') || null;
  }

  getWarehouseId() {
    return localStorage.getItem('warehouse_id');
  }

  removeWarehouseId() {
    return localStorage.removeItem('warehouse_id');
  }

  updateNsFbUserDetails(param, value) {
    this.loggedInNativeFbUser[param] = value;
    localStorage.setItem('ns-fb-logged-in-user', JSON.stringify(this.loggedInNativeFbUser));
  }

  getNsLoggedinFbUser() {
    return this.loggedInNativeFbUser;
  }

  deleteNsFbUser() {
    localStorage.removeItem('ns-fb-logged-in-user');
  }

  updateNsGoogleUserDetails(param, value) {
    this.loggedInNativeGoogleUser[param] = value;
    localStorage.setItem('ns-google-logged-in-user', JSON.stringify(this.loggedInNativeGoogleUser));
  }

  deleteIonicUsers() {
    this.loggedInNativeFbUser = JSON.parse(localStorage.getItem('ns-fb-logged-in-user') || '{}');
    this.loggedInNativeGoogleUser = JSON.parse(localStorage.getItem('ns-google-logged-in-user') || '{}');
  }

  getNsLoggedinGoogleUser() {
    return this.loggedInNativeGoogleUser;
  }

  deleteNsGoogleUser() {
    localStorage.removeItem('ns-google-logged-in-user');
  }

  getAccessToken(): string {
    return this.accessToken || '';
  }

  getRefreshToken(): string {
    return this.refreshToken || '';
  }

  getUserId(): string {
    return this.userId || '';
  }

  getUserRole(): string {
    return this.roleType || '';
  }

  setAccessToken(token: string) {
    this.accessToken = token || '';
    this.isValidSession = true;
  }

  setUserSubscriptions(subscriptions: any[]) {
    this.currentUserSubscription = {};
    subscriptions.forEach(subscription => {
      if (this.isActiveSubscription(subscription)) {
        this.currentUserSubscription = subscription;
      }
    });
    this.getPortalInstance();
    const currentUrl = this.router.url.replace('/', '');
    if (this.pathsThatNeedSubscription.includes(currentUrl) && this.isValidSession && !this.userSubscribed()) {
      setTimeout(() => {
        this.router.navigate([AppRoutes.paymentInfo]);
      }, 100);
    }
  }

  userSubscribed() {
    return this.isActiveSubscription(this.currentUserSubscription);
  }

  isActiveSubscription(subscription) {
    return [SubscriptionStatus.ACTIVE, SubscriptionStatus.FUTURE, SubscriptionStatus.IN_TRIAL].includes((subscription || {}).subscription_status);
  }

  setSocialLoginFlag(isSocialLogin) {
    localStorage.setItem('is_social_login', isSocialLogin);
  }

  setMFAData(mfaData) {
    localStorage.setItem('mfa_temp_token', mfaData.mfa_temp_token);
    localStorage.setItem('mfa_user_id', mfaData.user_id);
  }

  getMFAData() {
    return {
      mfa_temp_token: localStorage.getItem('mfa_temp_token'),
      user_id: localStorage.getItem('mfa_user_id'),
    };
  }

  saveCompanyAddress(companyUserUUID: string, address: any) {
    return this.http.post(this.apiMapping.saveCompanyAddress(companyUserUUID), address);
  }

  getCompanyAddress(companyUserUUID: string) {
    return this.http.get(this.apiMapping.getCompanyAddress(companyUserUUID)).toPromise();
  }

  isSocialLogin() {
    return localStorage.getItem('is_social_login') === 'true';
  }

  isLegacyUser() {
    return localStorage.getItem('is_legacy') === 'true';
  }

  getLegacyMigrationDate() {
    return localStorage.getItem('migration_date');
  }

  public async fetchEnvironment() {
    if (!this.envFetched) {
      try {
        const response = await this.http
          .get(this.apiMapping.getEnvironmentSecrets(), {
            observe: 'response',
          })
          .toPromise();
        this.setupEnvironmentVariables(response.headers.get('environment'));
        this.onEnvironmentSecretsFetched();
      } catch (error) {
        console.log(error);
      }
    }
  }

  async getBoxNumber(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (!this.getWarehouseBoxNumber()) {
        try {
          const boxNumberData = await this.http.http_get(this.apiMapping.getBoxNumberByUser(this.userData['user_uuid']));
          const userData = JSON.parse(localStorage.getItem('userData'));
          userData['warehouse_box_number'] = boxNumberData.box.warehouse_box_number;
          this.warehouseBoxNumber = userData['warehouse_box_number'];
          this.userData = userData;
          localStorage.setItem('userData', JSON.stringify(this.userData));
          return resolve();
        } catch (err) {
          return reject();
        }
      }
      resolve();
    });
  }

  async setUserSession(userData: any, fetchedFromLocalStorage = false) {
    this.userData = userData;
    localStorage.setItem('userData', JSON.stringify(this.userData));
    this.setAccessToken(userData['token']);
    this.warehouseBoxNumber = userData['warehouse_box_number'];
    this.refreshToken = userData['refresh_token'];
    this.userId = userData['user_uuid'];
    this.roleType = userData['role_type'];
    this.orgParentId = this.getUserOrgParentId();
    if (!fetchedFromLocalStorage) {
      try {
        await this.getSettingsAccountNamesData();
      } catch (error) {
        console.log(error);
      }
      this.fetchWarehouseAddress();
      this.fetchWarehouseConfigs();
      this.fetchFormErrorsWaitingForCustomerInput();
      this.fetchFormsWaitingToSign();
    }
    this.pollToCheckResetPasswordDone();
  }

  public async getSettingsAccountNamesData() {
    const apiResponse: AccountNamesModel[] = (await this.getSettingsAccountNames(this.apiMapping.getSettingsAccountNamesOnlyStatus(this.getUserId())).toPromise()) as any;
    this.setAccountNames(apiResponse['data']['accountNames']);
    this.checkPrimaryUserStatus(apiResponse['data']['accountNames']);
  }

  public setAccountNames(accountNames: any[]) {
    const rejectedName = accountNames.filter(value => value.accountStatus && value.accountStatus.toLowerCase().includes('rejected'));
    if (rejectedName && rejectedName.length) {
      this.verifyService.setValue(true);
      this.setDocumentRejectedStatus(rejectedName[0]);
    } else {
      this.setDocumentRejectedStatus({});
      this.verifyService.setValue(false);
    }
  }

  setupEnvironmentVariables(envToken: string) {
    const envObject = jwt_decode(atob(envToken));
    environment['stripePublishKey'] = envObject['stripePublishKey'];
    environment['uaPushAppKey'] = envObject['uaPushAppKey'];
    environment['uaPushAppSecret'] = envObject['uaPushAppSecret'];
    environment['chargebeeSite'] = envObject['chargebeeSite'];
    environment['uxCamAppKey'] = envObject['uxCamAppKey'];
    environment['encryptionKey'] = envObject['encryptionKey'];
    environment['pollingInterval'] = envObject['pollingInterval'];
    this.envFetched = true;
  }

  onEnvironmentSecretsFetched() {
    if (Chargebee && !Chargebee.inited) {
      Chargebee.init({
        site: environment['chargebeeSite'],
      });
    }
    // this.getPortalInstance();
    this.startLoggedinUserAnalytics();
    this.setupPushNotifications();
    this.pushNotificationsService.setupUserData(this.getUserId(), this.getUserEmail(), this.getUserFullName()); // setup push user
  }

  async vendorSubscriptionChanged() {
    this.vendorDetailsAvailable = false;
    await this.getSubscription();
  }

  async getSubscription() {
    const userSubscriptions = await this.getUserSubscription().toPromise();
    this.setUserSubscriptions(userSubscriptions);
  }

  async setLegacyData(userData) {
    const userDetail = await this.getUserDetailById(userData['user_uuid']).toPromise();
    localStorage.setItem('is_legacy', userDetail.is_legacy);
    localStorage.setItem('migration_date', userDetail.migration_date);
  }

  public getActivePlan() {
    for (const plan of this.plansList) {
      if (plan.id === this.currentUserSubscription.plan_id) {
        return plan;
      }
    }
  }

  public isPayAsYouGoSubscriber() {
    const activePlan = this.getActivePlan();
    if ((activePlan || {}).options) {
      return activePlan.options['is_pay_as_you_go'];
    }
    return false;
  }

  startLoggedinUserAnalytics() {
    // this.firebaseX.setUserId(`${this.userId}`);
    // this.firebaseX.setUserProperty('user_id', `${this.userId}`);
    // if (this.platform.is('cordova') && typeof UXCam !== 'undefined') {
    //   UXCam.stopSessionAndUploadData();
    //   UXCam.optIntoSchematicRecordings(); // records a video of the screen for a session
    //   UXCam.startWithKey(environment.uxCamAppKey);
    //   UXCam.setUserIdentity(this.getUserFullName());
    //   UXCam.setUserProperty('user_id', this.getUserId());
    //   UXCam.setUserProperty('email', this.getUserEmail());
    //   UXCam.setUserProperty('alias', this.getUserFullName());
    //   UXCam.startNewSession();
    //   this.logEventForAnalytics(AnalyticsEvent.LOGGED_IN, this.getUserId());
    // }
  }

  logEventForAnalytics(eventName, properties) {
    // if (this.platform.is('cordova') && typeof UXCam !== 'undefined') {
    //   UXCam.logEvent(eventName, properties);
    // }
  }

  setScreenForAnalytics(screenName: string) {
    // this.firebaseX.setScreenName(screenName);
    // if (this.platform.is('cordova') && typeof UXCam !== 'undefined') {
    //   UXCam.tagScreenName(SharedHelpers.snakeToSentenceCase(screenName));
    // }
  }

  getUserDetails() {
    return this.userData;
  }

  setupPushNotifications() {
    this.pushNotificationsService.setupPushNotifications(); // setup push
  }

  getUserEmail(): string {
    return (this.getDecodedAccessToken(this.accessToken) || {})['email'];
  }

  getUserFullName(): string {
    return (this.getDecodedAccessToken(this.accessToken) || {})['name'];
  }

  getUserOrgParentId(): string {
    return (this.getDecodedAccessToken(this.accessToken) || {})['orgParentId'];
  }

  isSessionValid(): boolean {
    return this.isValidSession;
  }

  setTemporaryUserDetails(data) {
    this.tempUserDetails = data;
  }

  removeTemporaryUserDetails() {
    this.tempUserDetails = null;
  }

  getTemporaryUserDetails() {
    return this.tempUserDetails;
  }

  async redirectBasedOnUserName(legacyUserName: string) {
    if (legacyUserName) {
      try {
        const response = await this.http.post(this.apiMapping.legacyMigrationRedirectUser(), { username: legacyUserName }).toPromise();
        response['username'] = legacyUserName;
        sessionStorage.setItem('legacy_redirect_response', JSON.stringify(response));
        this.router.navigate([response['redirect_url']]);
        if (response['redirect_url'] === '/login') {
          sessionStorage.removeItem('legacy_redirect_response');
        } else {
          sessionStorage.setItem('disable_social_logins', 'true');
        }
        if (localStorage.getItem('userData')) {
          const userData = JSON.parse(localStorage.getItem('userData'));
          const decodedToken = this.getDecodedAccessToken(userData['token']);
          if (response['email'] !== decodedToken.email) {
            localStorage.removeItem('userData');
          }
        }
      } catch (error) {
        this.notificationService.showError('Unable to get legacy redirect url.');
      }
    }
  }

  redirectLegacyUser() {
    let legacyRedirectResponse = this.getLegacyRedirectResponse();
    if (legacyRedirectResponse) {
      legacyRedirectResponse = JSON.parse(legacyRedirectResponse);
      if (legacyRedirectResponse['redirect_url'] === '/reset-password') {
        this.router.navigate([legacyRedirectResponse['redirect_url']]);
      }
      return;
    }
    if (sessionStorage.getItem('legacy_username')) {
      this.router.navigate([AppRoutes.newPassword]);
      return;
    }
  }

  async legacyMigrationGenerateAndReturnToken(): Promise<string> {
    if (this.warehouseBoxNumber) {
      try {
        const response = await this.http.post(this.apiMapping.legacyMigrationGenerateToken(), { box_number: this.warehouseBoxNumber }).toPromise();
        return response['token'];
      } catch (error) {
        this.notificationService.showError('Unable to generate legacy migration token');
      }
    }
  }

  refreshSession() {
    this.refreshSessionSubject.next({});
  }

  logoutUser() {
    this.setupRedirectPath();
    this.http.http_post(this.apiMapping.logoutUser(), {}).then(resp => {});
    this.chargebeePortal = undefined;
    this.vendorDetailsAvailable = false;
    this.accessToken = '';
    this.refreshToken = '';
    this.roleType = '';
    this.notaryPlan = null;
    this.onboardingStatus = null;
    this.onboardingCompleted = false;
    this.warehouseBoxNumber = null;
    localStorage.removeItem('userData');
    localStorage.removeItem('is_social_login');
    localStorage.removeItem('is_legacy');
    localStorage.removeItem('migration_date');
    localStorage.removeItem('coupon_code');
    this.deleteNsFbUser();
    this.deleteNsGoogleUser();
    this.deleteIonicUsers();
    this.isValidSession = false;
    // this.googlePlus.logout();
    // this.fb.getLoginStatus().then(res => {
    //   this.fb.logout();
    // });
    this.logEventForAnalytics(AnalyticsEvent.LOGGED_OUT, this.getUserId());
    this.router.navigate([AppRoutes.login]);
  }

  setMaintenanceMode(enabled: boolean) {
    if (this._isMaintenanceEnabled !== enabled && enabled) {
      this.router.navigate([AppRoutes.maintenance]);
    }
    this._isMaintenanceEnabled = enabled;
    this.maintenanceEnabledSubject.next(this._isMaintenanceEnabled);
  }

  public get isMaintenanceEnabled(): boolean {
    return this._isMaintenanceEnabled;
  }

  shouldDisableSocialLogins() {
    return sessionStorage.getItem('disable_social_logins') || null;
  }

  setUserAllAddresses(addressResponse: any) {
    const allAddresses: IAddressAttributes[] = addressResponse.addresses;
    this.sortAddresses(allAddresses);
    allAddresses.forEach(address => {
      address.address_type = address.address_type;
      this.userAllAddresses[address.address_type] = this.userAllAddresses[address.address_type] || [];
      this.userAllAddresses[address.address_type].push(address);
    });
  }

  initAllAddresses(): void {
    Object.keys(AddressType).forEach(addressType => {
      this.userAllAddresses[addressType] = [];
    });
  }

  hasUserAllAddresses(): boolean {
    let boolHasAddresses = false;
    Object.keys(AddressType).forEach(addressType => {
      if (boolHasAddresses) {
        return boolHasAddresses;
      }
      boolHasAddresses = Boolean(this.userAllAddresses[addressType].length > 0);
    });
    return boolHasAddresses;
  }

  getUserAllAddresses(): any {
    const returnAddresses = [];
    Object.keys(AddressType).forEach(addressType => {
      if (this.userAllAddresses[addressType].length > 0) {
        for (const address of this.userAllAddresses[addressType]) {
          returnAddresses.push(address);
        }
      }
    });
    return returnAddresses;
  }

  clearUserAllAddresses() {
    Object.keys(AddressType).forEach(addressType => {
      this.userAllAddresses[addressType] = [];
    });
  }

  setUserDestinationAddresses(addressResponse: any, addressType: string) {
    const allAddresses: IAddressAttributes[] = addressResponse.addresses;
    this.sortAddresses(allAddresses);
    this.userDestinationAddresses[addressType] = allAddresses;
  }

  clearUserDestinationAddresses(addressType: string) {
    this.userDestinationAddresses[addressType] = [];
  }

  hasUserDestinationAddresses(addressType: string): boolean {
    return (this.userDestinationAddresses[addressType] || []).length > 0;
  }

  getUserDestinationAddresses(addressType: string): IAddressAttributes[] {
    return JSON.parse(JSON.stringify(this.userDestinationAddresses[addressType] || []));
  }

  sortAddresses(addresses: any[]) {
    addresses.sort((a, b) => b.id - a.id); // sort by id descending
    addresses.sort((a, b) => (b.is_default ? 1 : a.is_default ? -1 : 0)); // bring default to top
  }

  getWarehouseBoxNumber() {
    return this.warehouseBoxNumber;
  }

  async getUserFolders(reload: boolean = true) {
    const currentTimestamp = new Date().getTime();
    if (!this.foldersData.requestTimestamp || currentTimestamp - this.foldersData.requestTimestamp > 10000) {
      reload = true;
    }
    if (!reload) {
      if (this.foldersData.isLoading) {
        return await this.foldersUpdate.toPromise();
      }
      if (this.folders?.length) {
        return this.folders;
      }
    }

    this.foldersData.requestTimestamp = currentTimestamp;
    this.foldersData.isLoading = true;
    try {
      const promiseResult = await this.mailFolderService.getFolders();
      const folders = promiseResult || []; // fetch once
      this.folders = folders.map(folder => ({
        ...folder,
        callout_options: ['EDIT', 'REMOVE FOLDER'],
      }));
      this.foldersData.isLoading = false;
      Promise.resolve().then(() => this.foldersUpdate.next(this.folders));
      return this.folders || [];
    } catch (error) {
      this.foldersData.isLoading = false;
      this.foldersUpdate.error(error);
      throw error;
    }
  }

  setupPlatform() {
    this.setScreenWidth(window.innerWidth);
    this.isMobileMode = window.innerWidth <= 1024;
  }

  getScreenWidth() {
    return this.screenWidth;
  }

  setScreenWidth(width) {
    this.screenWidth = width;
  }

  getScreenHeight() {
    const element = document.getElementsByTagName('body')[0];
    const computedStyle = getComputedStyle(element);
    let screenHeight = window.innerHeight; // = element.clientHeight; //  height with padding
    screenHeight -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
    return screenHeight;
  }

  fetchWarehouseAddress() {
    this.http.get(this.apiMapping.getUserAddressesByType(this.getUserId(), AddressType.WAREHOUSE)).subscribe((data: any) => {
      this.warehouseAddress = data.addresses[0] || {};
      this.warehouseEmail = data.warehouseEmail;
      this.warehousePhone = data.warehousePhone;
      this.channelPartnerName = data.channelPartnerName;
      this.warehouseOperationHours = data.warehouseOperationalHours;
      this.setWarehouseAddress(this.warehouseAddress);
      this.warehouseAddressFetched.next(this.warehouseAddress);
    });
  }

  fetchWarehouseConfigs() {
    this.http.get(this.apiMapping.getApplicationSettings()).subscribe((data: WarehouseConfiguration) => {
      this.warehouseConfiguration = data;
    });
  }

  fetchFormErrorsWaitingForCustomerInput() {
    if (this.orgParentId) {
      return;
    }
    this.http.get(this.apiMapping.getFormErrorsWaitingForCustomerInput()).subscribe((data: IFormError[]) => {
      this.setRequestedFormErrors(data);
    });
  }

  fetchFormsWaitingToSign() {
    if (this.orgParentId) {
      return;
    }
    this.http.get(this.apiMapping.getFormsWaitingForSignature()).subscribe((data: IFormToSign[]) => {
      this.setFormsWaitingToSign(data);
    });
  }

  getDefaultWarehouses(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.http.http_get(this.apiMapping.getDefaultWarehouses()); // fetch once
        const warehouses = (response && response.results) || []; // fetch once
        resolve(warehouses);
      } catch (error) {
        reject(error);
      }
    });
  }

  getWarehousesByState(query): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const warehouses = (await this.http.http_get(this.apiMapping.getWarehousesByState(query))) || []; // fetch once
        resolve(warehouses);
      } catch (error) {
        reject(error);
      }
    });
  }

  getWarehouseAddress() {
    return this.warehouseAddress;
  }

  setWarehouseAddress(address) {
    this.warehouseAddress = address;
  }

  getRequestedFormErrors() {
    return this.requestedFormErrors;
  }

  setRequestedFormErrors(value: IFormError[]) {
    this.requestedFormErrors = value;
    this.requestedFormErrorsFetched.next(this.requestedFormErrors);
    this.setMenuItems();
  }

  getFormsWaitingToSign() {
    return this.requestedFormsToSign;
  }

  setFormsWaitingToSign(value: IFormToSign[]) {
    this.requestedFormsToSign = value;
    this.requestedFormsToSignFetched.next(this.requestedFormsToSign);
    this.setMenuItems();
  }

  getSettingsAccountNames(user_id: string) {
    return this.http.get(user_id);
  }

  getSettingsAccountNamesPromise(user_id: string): Promise<any> {
    return this.http.get(user_id).toPromise();
  }

  submitAccountDetails(apiUrl: string, params: any): Observable<any> {
    return this.http.put(apiUrl, params);
  }

  generateAndSendMFACode(userId: string) {
    return this.http.get(this.apiMapping.generateAndSendMFACode(userId));
  }

  postDeclarationInfo(mailItemId: any, declarations) {
    return this.http.patch(this.apiMapping.postDeclarationInfo(mailItemId), declarations);
  }

  getUserMailingAddressById(userId: any) {
    return this.http.get(this.apiMapping.verifyGetAddress(userId, AddressType.BILLING));
  }

  getAddressesByUserId(userId: string): Observable<any> {
    return this.http.get(this.apiMapping.getUserAddresses(userId));
  }

  getShipmentMethodList(): Observable<any> {
    return this.http.get(this.apiMapping.getShipMethods());
  }

  getShipmentSettings(user_setting_type: string): Observable<any> {
    return this.http.get(this.apiMapping.getShipmentSettings(user_setting_type));
  }

  saveShipmentData(userId: string, params: any): Observable<any> {
    return this.http.post(this.apiMapping.saveShipmentSettings(userId), params);
  }

  changeWarehouse(params: any): Observable<any> {
    return this.http.post(this.apiMapping.changeWarehouse(), params);
  }

  updateShipmentData(userId: string, userSettingsId: string, params: any): Observable<any> {
    return this.http.put(this.apiMapping.updateShipmentSettings(userId, userSettingsId), params);
  }

  getFormList(userId): Observable<any> {
    return this.http.get(this.apiMapping.getFormsList(userId));
  }

  getFormListPromise(userId: string): Promise<any> {
    return this.http.get(this.apiMapping.getFormsList(userId)).toPromise();
  }

  getUserSubscription(): Observable<any> {
    return this.http.get(this.apiMapping.getUserSubscriptions());
  }

  getUserPlanById(plan_id): Observable<any> {
    return this.http.get(this.apiMapping.getPlanById(plan_id));
  }

  getUserDetailById(user_id): Observable<any> {
    return this.http.get(this.apiMapping.getUserInfo(user_id));
  }

  async getUserNotaryPlan() {
    try {
      const userNotaryPlan = await this.http.http_get(this.apiMapping.getnotaryPlansByUserId());
      if (userNotaryPlan && userNotaryPlan.planData) {
        const notaryPlan = userNotaryPlan.planData.plan_type.toLowerCase();
        if (notaryPlan === 'notarycam') {
          this.notaryPlan = notaryPlan;
          this.setMenuItems();
        } else {
          const userForm1583s = await this.http.http_get(this.apiMapping.getFormsList(this.getUserId()));
          if (userForm1583s && userForm1583s.form1583 && userForm1583s.form1583.length) {
            for (let x = 0; x < userForm1583s.form1583.length; x++) {
              if (userForm1583s.form1583[x].form1583.form_type === FormType.FORM_1583) {
                this.setMenuItems();
                break;
              }
              if (x === userForm1583s.form1583.length - 1) {
                this.notaryPlan = notaryPlan;
                this.setMenuItems();
              }
            }
          } else {
            this.setMenuItems();
          }
        }
      } else {
        this.setMenuItems();
      }
    } catch (error) {
      this.setMenuItems();
    }
  }

  setNotaryPlan(plan) {
    this.notaryPlan = plan;
    this.setMenuItems();
  }

  setMenuItems() {
    let menus = JSON.parse(JSON.stringify(MenuItems));
    const mobileMenus = JSON.parse(JSON.stringify(MenuItems)).filter(value => {
      return [RESOLVE_FORM_ERRORS_PAGE_NAME, 'Verify Account', 'MailBox', 'Scans', 'Shipments', 'Settings'].indexOf(value.name) > -1;
    });
    if (this.orgParentId) {
      menus = menus.filter(it => !it.excludeForSubAccounts);
    }
    if (!this.shouldVerifyAccount()) {
      this.onboardingCompleted = true;
      const ind = this.findWithAttr(menus, 'name', 'verify account');
      const mobileInd = this.findWithAttr(mobileMenus, 'name', 'verify account');
      if (ind > -1) {
        menus.splice(ind, 1);
        mobileMenus.splice(mobileInd, 1);
      }
    }
    if (!this.getRequestedFormErrors()?.length && !this.getFormsWaitingToSign()?.length) {
      const ind = this.findWithAttr(menus, 'name', RESOLVE_FORM_ERRORS_PAGE_NAME);
      const mobileInd = this.findWithAttr(mobileMenus, 'name', RESOLVE_FORM_ERRORS_PAGE_NAME);
      if (ind > -1) {
        menus.splice(ind, 1);
        mobileMenus.splice(mobileInd, 1);
      }
    }
    this.verifyAccountService.updateMenuItemsListEvent(menus);
    this.verifyAccountService.updateMobileMenuItemsListEvent(mobileMenus);
  }

  public findWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr].toLowerCase() === value?.toLowerCase()) {
        return i;
      }
    }
    return -1;
  }

  isStatusNew(userData) {
    if (this.platform.is('cordova') && userData['account_status'] !== 'APPROVED') {
      this.notificationService.showError(userData['active_account_message']);
      return true;
    }
    return false;
  }

  hasUserReferrerData() {
    return Boolean(this.userReferrerData);
  }

  getUserReferrerData() {
    return this.userReferrerData;
  }

  setUserReferrerData(responseData) {
    this.userReferrerData = responseData;
  }

  hasUserReferrersList() {
    return Boolean(this.userReferrersList);
  }

  getUserReferrersList() {
    return this.userReferrersList;
  }

  setUserReferrersList(responseData) {
    this.userReferrersList = responseData;
  }

  showCaptcha() {
    const d = document.getElementsByTagName('body')[0];
    if (d.className.indexOf('captcha-visible') < 0) {
      d.className += ' captcha-visible';
    }
  }

  hideCaptcha() {
    const d = document.getElementsByTagName('body')[0];
    d.className = d.className.replace(' captcha-visible', '').replace('captcha-visible ', '');
  }

  pollToCheckResetPasswordDone() {
    if (this.isSessionValid() && environment['pollingInterval']) {
      const subscription = interval(environment['pollingInterval'] * 1000)
        .pipe(
          switchMap(() => {
            return this.http.get(environment.resetPasswordPollingUrl);
          }),
        )
        .subscribe((response: any) => {
          if (response.logout) {
            this.logoutUser();
            subscription.unsubscribe();
          }
        });
    }
  }

  getAccountStatus(): AccountStatus {
    return this.userData.account_status as AccountStatus;
  }

  getCustomerImportantDates(id) {
    return this.http.get(this.apiMapping.getCustomerImportantDates(id));
  }

  submitMFACode(enteredCode: string): any {
    const mfaData = this.getMFAData();
    return this.http.post(this.apiMapping.submitMFACode(), {
      code: enteredCode,
      mfa_temp_token: mfaData.mfa_temp_token,
      user_id: mfaData.user_id,
    });
  }

  getAccountDetails() {
    return this.http.get(this.apiMapping.getAccountDetails());
  }

  disableMFA() {
    return this.http.get(this.apiMapping.disableMFA());
  }

  enableMFA(enteredCode: string) {
    const mfaData = this.getMFAData();
    return this.http
      .post(this.apiMapping.enableMFA(), {
        mfaTempToken: mfaData.mfa_temp_token,
        verificationCode: enteredCode,
      })
      .toPromise();
  }

  getTeamMembers(): Observable<any> {
    return this.http.get(this.apiMapping.getTeamMembers());
  }

  deleteTeamMember(user) {
    return this.http.post(this.apiMapping.deleteTeamMember(user), {});
  }

  pauseTeamMember(user) {
    return this.http.post(this.apiMapping.pauseTeamMember(user), {});
  }

  unpauseTeamMember(user) {
    return this.http.post(this.apiMapping.unpauseTeamMember(user), {});
  }

  resendTeamMemberInvitation(user) {
    return this.http.post(this.apiMapping.resendTeamMemberInvitation(user), {});
  }

  updateTeamMemberRole(user, role) {
    return this.http.post(this.apiMapping.updateTeamMemberRole(user), { role });
  }

  getTeamMemberFolders(user) {
    return this.http.get(this.apiMapping.getTeamMemberFolders(user));
  }

  updateTeamMemberFolders(user, mailFolderIds) {
    return this.http.post(this.apiMapping.updateTeamMemberFolders(user), { mailFolderIds });
  }

  inviteTeamMembers(invites) {
    return this.http.post(this.apiMapping.inviteTeamMembers(), invites);
  }

  getOrgUserInviteDetails(token) {
    return this.http.get(this.apiMapping.getOrgInviteDetails(token));
  }

  acceptOrgInvite(payload) {
    return this.http.post(this.apiMapping.acceptOrgInvite(), payload);
  }
}
