// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 480px;
  width: 100%;
  height: 100%;
  padding: 10px;
}
:host .referral-copy-btn {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}
:host .social-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 143%;
  color: white;
}
:host .social-button.fb-button {
  background: #4267b2;
}
:host .social-button.fb-button:hover {
  background: #294988;
}
:host .social-button.twitter-button {
  background: #55acee;
}
:host .social-button.twitter-button:hover {
  background: #4294d3;
}
:host .social-button.whatsapp-button {
  background: #25d366;
}
:host .social-button.whatsapp-button:hover {
  background: #23bb5b;
}
:host .social-button.email-button {
  background: #e52226;
}
:host .social-button.email-button:hover {
  background: #cc1f21;
}
:host .referral-table {
  border-top: 1px solid #e1e5f3;
}
:host .referral-table tr {
  border-bottom: 1px solid #e1e5f3;
}
:host .referral-table tr td {
  padding: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/refer-friend/refer-friend.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;AAAJ;AAEI;EACE,mBAAA;AAAN;AAEM;EACE,mBAAA;AAAR;AAII;EACE,mBAAA;AAFN;AAIM;EACE,mBAAA;AAFR;AAMI;EACE,mBAAA;AAJN;AAMM;EACE,mBAAA;AAJR;AAQI;EACE,mBAAA;AANN;AAQM;EACE,mBAAA;AANR;AAWE;EACE,6BAAA;AATJ;AAWI;EACE,gCAAA;AATN;AAWM;EACE,eAAA;AATR","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 480px;\n  width: 100%;\n  height: 100%;\n  padding: 10px;\n\n  .referral-copy-btn {\n    width: 44px;\n    height: 44px;\n    display: flex;\n    align-items: center;\n    border-radius: 4px;\n  }\n\n  .social-button {\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    font-size: 143%;\n    color: white;\n\n    &.fb-button {\n      background: #4267b2;\n\n      &:hover {\n        background: #294988;\n      }\n    }\n\n    &.twitter-button {\n      background: #55acee;\n\n      &:hover {\n        background: #4294d3;\n      }\n    }\n\n    &.whatsapp-button {\n      background: #25d366;\n\n      &:hover {\n        background: #23bb5b;\n      }\n    }\n\n    &.email-button {\n      background: #e52226;\n\n      &:hover {\n        background: #cc1f21;\n      }\n    }\n  }\n\n  .referral-table {\n    border-top: 1px solid #e1e5f3;\n\n    tr {\n      border-bottom: 1px solid #e1e5f3;\n\n      td {\n        padding: 10px 0;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
