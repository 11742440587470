import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { SignFormPopupComponent } from './sign-form-popup.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SignaturePadPopupComponent } from './signature-pad-popup/signature-pad-popup.component';

@NgModule({
  declarations: [SignFormPopupComponent, SignaturePadPopupComponent],
  imports: [CommonModule, MatDialogModule, PdfViewerModule, PinchZoomModule, MatButtonModule, MatIconModule],
  exports: [SignFormPopupComponent, SignaturePadPopupComponent],
})
export class SignFormPopupModule {}
