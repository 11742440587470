// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host .error-msg {
  color: #e00202;
}
:host .fix-error-icon {
  top: 27px;
}`, "",{"version":3,"sources":["webpack://./src/app/inbox/field-error-display/field-error-display.component.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;AAGE;EACE,cCCO;ADFX;AAIE;EACE,SAAA;AAFJ","sourcesContent":["@import '../../../styles/variables';\n\n:host {\n  display: block;\n\n  .error-msg {\n    color: $usgm-red;\n  }\n\n  .fix-error-icon {\n    top: 27px;\n  }\n}\n","$usgm-primary: #fb7e16;\n$usgm-primary-hover: #e67212;\n$usgm-secondary: #077abe;\n$usgm-secondary-hover: #0565b3;\n$usgm-default-hover: #9ccae5;\n$nav-button-color: #00838f;\n$usgm-green: #35b54f;\n$usgm-red: #e00202;\n$usgm-orange: #fb7e16;\n$fontFira: 'FiraSans', sans-serif;\n$greyColor: #e5e5e5;\n$darkgrey: #21242c;\n$greyColor1: #4c4c4c;\n$white: #fff;\n$lightGrey: #dbe2eb;\n$greyColor2: rgba(76, 76, 76, 0.4);\n$greyColor3: #fffbfb;\n$greyColor4: #f4f4f4;\n$darkgrey1: #1c335d;\n$blueColor: #4386fc;\n$greenColor: #78be43;\n$black: #000;\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
