import { AbstractControl } from '@angular/forms';

export function emailMatchValidator(legacyEmail: string) {
  return (control: AbstractControl) => {
    if (control.valid && legacyEmail && control.value) {
      const email = control.value;
      if (email.trim() !== legacyEmail.trim()) {
        return { emailMatchInvalid: true };
      } else {
        return null;
      }
    }
  };
}
