import { Injectable } from '@angular/core';

@Injectable()
export class RegistrationDataService {
  private userPlanSelections = {};
  private loadedPlansData: any = {};
  private loadedOptionsData: any = {};
  private loadedTermsData: any = {};
  private loadedRegionsData: any = {};

  constructor() {
    this.initFromSavedData();
  }

  getUserPlanSelections(): any {
    return this.userPlanSelections;
  }

  updateUserPlanSelections(key: any, value: any) {
    this.userPlanSelections[key] = value;
    this.saveCurrentData();
  }

  setPlans(data): any {
    this.loadedPlansData = JSON.parse(JSON.stringify(data));
    this.saveCurrentData();
  }

  getPlans() {
    return this.copyObject(this.loadedPlansData);
  }

  setOptionsForId(id, data): any {
    this.loadedOptionsData[id + ''] = JSON.parse(JSON.stringify(data));
    this.saveCurrentData();
  }

  getOptionsForId(id) {
    return this.copyObject(this.loadedOptionsData[id + '']);
  }

  setTermsForId(id, data): any {
    this.loadedTermsData[id + ''] = JSON.parse(JSON.stringify(data));
    this.saveCurrentData();
  }

  getTermsForId(id) {
    return this.copyObject(this.loadedTermsData[id + '']);
  }

  setRegions(data): any {
    this.loadedRegionsData = JSON.parse(JSON.stringify(data));
    this.saveCurrentData();
  }

  getRegions() {
    return this.copyObject(this.loadedRegionsData);
  }

  copyObject(variable) {
    return Object.keys(variable || {}).length > 0 ? JSON.parse(JSON.stringify(variable)) : null;
  }

  saveCurrentData() {
    sessionStorage.setItem(
      'registrationData',
      JSON.stringify({
        userPlanSelections: this.userPlanSelections,
        loadedPlansData: this.loadedPlansData,
        loadedOptionsData: this.loadedOptionsData,
        loadedTermsData: this.loadedTermsData,
        loadedRegionsData: this.loadedRegionsData,
      }),
    );
  }

  initFromSavedData() {
    const savedData = JSON.parse(sessionStorage.getItem('registrationData')) || {};
    this.userPlanSelections = savedData.userPlanSelections || {};
    this.loadedPlansData = savedData.loadedPlansData || {};
    this.loadedOptionsData = savedData.loadedOptionsData || {};
    this.loadedTermsData = savedData.loadedTermsData || {};
    this.loadedRegionsData = savedData.loadedRegionsData || {};
    return savedData;
  }

  hasSelectedPlans(): boolean {
    return this.userPlanSelections['plan-id'] && this.userPlanSelections['option'] && this.userPlanSelections['region'] && this.userPlanSelections['term'];
  }
}
