import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { UserDataService } from '../../services';

@Component({
  selector: 'usgm-upgrade-business-plan',
  templateUrl: './upgrade-business-plan.component.html',
})
export class UpgradeBusinessPlanComponent implements OnInit {
  @Input() businessNameList: any[] = [];
  @Output() deleteEvent = new EventEmitter<any>();
  @Output() addMoreEvent = new EventEmitter<string>();

  constructor(
    protected matStepper: MatStepper,
    protected userDataService: UserDataService,
  ) {}

  public ngOnInit() {}

  public onAddingNames(): void {
    this.matStepper.next();
  }

  public callDelete(index: number): void {
    const data = {
      index: index,
      type: 'business',
    };
    this.deleteEvent.next(data);
  }

  public isPayAsYouGoSubscriber() {
    return this.userDataService.isPayAsYouGoSubscriber();
  }

  public callAddMore(): void {
    this.addMoreEvent.next('business');
  }
}
