// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.look-up {
  min-width: 181px;
}
.look-up .title {
  color: #fb7e16;
  font-size: 143%;
}
.look-up .popoverbutton {
  background-color: #cde0e6;
  background-color: #fb7e16;
  color: #fff;
  border-width: 0;
  border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/inbox/lookup-popup/dialog.component.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;AACD;AACC;EACC,cAAA;EACA,eAAA;AACF;AAEC;EACC,yBAAA;EACA,yBAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;AAAF","sourcesContent":[".look-up {\n\tmin-width: 181px;\n\n\t.title {\n\t\tcolor: #fb7e16;\n\t\tfont-size: 143%;\n\t}\n\n\t.popoverbutton {\n\t\tbackground-color: #cde0e6;\n\t\tbackground-color: #fb7e16;\n\t\tcolor: #fff;\n\t\tborder-width: 0;\n\t\tborder-radius: 3px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
