import { Component, OnInit } from '@angular/core';
import { findRelevantBetaAppPath } from '../../utils/helpers';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { UserDataService } from '../../services';

@Component({
  selector: 'usgm-beta-badge',
  templateUrl: './beta-badge.component.html',
  styleUrls: ['./beta-badge.component.scss'],
})
export class BetaBadgeComponent implements OnInit {
  public userData: any = {};

  constructor(
    protected router: Router,
    protected userDataService: UserDataService,
  ) {}

  ngOnInit() {
    this.userData = this.userDataService.getDecodedAccessToken(this.userDataService.getAccessToken()) || {};
  }

  public onBadgeClick() {
    const betaPath = findRelevantBetaAppPath(this.router.url?.split('?')[0]);
    window.location.href = `${environment.betaAppUrl}${betaPath}`;
  }
}
