import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'usgm-recaptcha-dialog',
  templateUrl: './recaptcha-dialog.component.html',
  styleUrls: ['./recaptcha-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecaptchaDialogComponent {
  public siteKey = environment.recaptchaSiteKeyV2;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RecaptchaDialogComponent>,
  ) {}

  public resolved(token) {
    this.dialogRef.close({ event: 'resolved', token: token });
  }

  public errored(error) {
    this.dialogRef.close({ event: 'errored', error: error });
  }
}
