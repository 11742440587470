import { Directive, HostListener } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Directive({
  selector: '[usgmStepperScroller]',
})
export class MatStepperScrollerDirective {
  constructor(private stepper: MatStepper) {}

  @HostListener('selectionChange', ['$event'])
  selectionChanged(selection: StepperSelectionEvent) {
    setTimeout(() => {
      const stepId = this.stepper._getStepLabelId(selection.selectedIndex - 1);
      const stepElement = document.getElementById(stepId);
      if (stepElement) {
        stepElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }, 250);
  }
}
