import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserDataService } from './user-data.service';
import { AppRoutes } from '../models/constants/app-routes.constant';

@Injectable()
export class SignUpRouteGuardService implements CanActivate {
  constructor(
    private userDataService: UserDataService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(resolve => {
      const pathToActivate = route.url[0].path.split('?')[0];
      resolve(this.checkIfCanActivate(pathToActivate));
    });
  }

  checkIfCanActivate(path): boolean {
    if (path === AppRoutes.unsubscribe) {
      return true;
    } else if (!this.userDataService.isSessionValid()) {
      return true;
    } else {
      this.router.navigate([AppRoutes.inbox]);
      return false;
    }
  }
}
