import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CouponType, IPlanAttributes } from '@usgm/usgm-payloads-library-front';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import * as Services from '../../services';
import { environment } from '../../../environments/environment';
import * as SharedHelpers from '../../utils/helpers';
import { AppRoutes } from '../../models/constants/app-routes.constant';
import { IPaymentVendorCustomer, IPaymentVendorSubscription } from '../../utils/constants';
import { Country, State, City } from 'country-state-city';
import { DialogWithInputComponent } from '../../components/dialog-with-input';
import { militaryBases } from '../../utils/constants';
import { AddressType } from '@usgm/usgm-payloads-library-front/Enums';
import { retry } from 'rxjs/operators';
import * as moment from 'moment-timezone';

const FREE_MONTH_MAGIC_TOKEN = 'FREE_MONTH_MAGIC_TOKEN';

@Component({
  selector: 'usgm-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss'],
})
export class PaymentInfoComponent implements OnInit, OnDestroy {
  private registeredUserData: any;
  private registerData: any = {};
  private planFetchRetryCount = 0;
  private cardNumber: any;
  private cardExpiry: any;
  private cardCvc: any;
  private cardHandler = this.onChange.bind(this);
  private stripe: any;
  public eligibleForFreeMonth = false;
  private warehouseAddress: any;
  private gtmTimeout = null;

  public loading = false;
  public stripeElementsLoading = true;
  public makingPayment = false;
  public paymentInfoForm: FormGroup;
  public plans: any[] = [];
  public selectedPlanData: any = {};
  public selectedTermData: any = {};
  public countries: any[];
  public states: any[];
  public cities: any[];
  public isCheckedTermOfUser: any = false;
  public selectedServiceStartDate: Date = new Date();
  public minStartDate = new Date();
  public appliedCoupon: any = {};
  public appliedReferrer: any = {};
  public couponAutoApplied = false;
  public couponInputMode = false;
  public referralInputMode = false;
  public verifyingCode = false;
  public numAddressLinesVisible = 1;
  public stripeCardNumberError: any;
  public stripeCardExpiryError: any;
  public stripeCardCvcError: any;

  constructor(
    public formBuilder: FormBuilder,
    public http: Services.UsgmHttp,
    public apiMapping: Services.ApiMapping,
    public userDataService: Services.UserDataService,
    public notificationService: Services.NotificationService,
    public registerDataService: Services.RegistrationDataService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit() {
    this.registerData = this.registerDataService.initFromSavedData() || {};
    this.registeredUserData = this.userDataService.getDecodedAccessToken(this.userDataService.getAccessToken());
    this.paymentInfoForm = this.formBuilder.group({
      address_1: ['', Validators.required],
      address_2: ['', []],
      address_3: ['', []],
      country: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      cardHolderName: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      phone: ['', Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(14)])],
      isCheckedTermOfUser: [false, []],
      selectedPlanId: [null, []],
      selectedTermId: [null, []],
      couponInput: ['', []],
      referralInput: ['', []],
    });
    if (localStorage.getItem('selectedPlan')) {
      this.paymentInfoForm.patchValue({ selectedPlanId: localStorage.getItem('selectedPlan') });
      localStorage.removeItem('selectedPlan');
    }
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['plan']) {
        this.paymentInfoForm.patchValue({ selectedPlanId: params['plan'] });
      }
      if (params['term']) {
        this.paymentInfoForm.patchValue({ selectedTermId: params['term'] });
      }
      if (params['want_free_month']) {
        this.checkIfCanGetFreeMonth();
      }
    });
    this.getPlans();
    this.getCountries();
    this.getPlanDetails();
  }

  private checkIfCanGetFreeMonth() {
    this.userDataService.getAccountDetails().subscribe((resp: any) => {
      this.eligibleForFreeMonth = resp && resp['data'] && resp['data']['mfaTempToken'] === FREE_MONTH_MAGIC_TOKEN;
      SharedHelpers.detectChanges(this.cdr);
    });
  }

  getPlanDetails() {
    const plan = this.userDataService.getPlanDetails();
    if (plan && plan.plan_id && plan.term_id) {
      this.paymentInfoForm.patchValue({ selectedPlanId: plan.plan_id });
      this.paymentInfoForm.patchValue({ selectedTermId: plan.term_id });
    }
  }

  getWarehouseAddress() {
    return new Promise<void>((resolve, reject) => {
      if (this.userDataService.getWarehouseAddress()) {
        this.warehouseAddress = this.userDataService.getWarehouseAddress() || {};
        return resolve();
      }
      this.http
        .get(this.apiMapping.getUserAddressesByType(this.userDataService.getUserId(), AddressType.WAREHOUSE))
        .pipe(retry(3))
        .subscribe(
          (data: any) => {
            this.warehouseAddress = data.addresses[0] || {};
            this.userDataService.setWarehouseAddress(this.warehouseAddress);
            return resolve();
          },
          error => {
            return reject(error);
          },
        );
    });
  }

  getCouponCodeAndApply() {
    const couponCode = this.userDataService.getCouponCode();
    if (couponCode) {
      this.paymentInfoForm.patchValue({ couponInput: couponCode });
      this.couponAutoApplied = true;
      this.applyCouponClicked();
      SharedHelpers.detectChanges(this.cdr);
    }
  }

  onChange(event) {
    if (event.elementType === 'cardNumber') {
      this.stripeCardNumberError = event.error ? event.error.message : null;
    }
    if (event.elementType === 'cardExpiry') {
      this.stripeCardExpiryError = event.error ? event.error.message : null;
    }
    if (event.elementType === 'cardCvc') {
      this.stripeCardCvcError = event.error ? event.error.message : null;
    }
    SharedHelpers.detectChanges(this.cdr);
  }

  public getPlans() {
    const preSelectedPlan = this.userDataService.getPlanDetails();
    this.http.http_get(this.apiMapping.getPlans(false, preSelectedPlan && preSelectedPlan.plan_id)).then(
      (data: any) => {
        this.handlePlans(data);
      },
      () => {
        if (this.planFetchRetryCount < 3) {
          this.notificationService.showError('Network error: unreliable connection. Retrying …');
          this.planFetchRetryCount++;
          this.getPlans();
        } else {
          this.notificationService.showError('Unable to load plan options. Please reload this page and try again');
        }
      },
    );
  }

  public async handlePlans(plans: any[]) {
    for (let i = 0; i < plans.length; i++) {
      if (plans[i].options?.is_freemium) {
        if (this.userDataService.getPlanVisiblity().toLocaleLowerCase() === 'current') {
          this.paymentInfoForm.get('selectedPlanId').disable();
        } else if (this.userDataService.getPlanVisiblity().toLocaleLowerCase() !== 'all') {
          plans.splice(i, 1);
          i--;
        }
      }
    }
    this.plans = this.filterPlansBasedOnCoupon(plans);
    this.registerDataService.setPlans(plans);
    this.setupPlanAndTerm();
    this.getCouponCodeAndApply();
    SharedHelpers.detectChanges(this.cdr);
    await this.getWarehouseAddress();
    // We have payment-info page acting as middleware to check user subscription, so when opening any page this page renders first.
    // That is why we put a timeout here to make sure that the GTM event is triggered only after customer stayed in this page for 10 seconds, which will be organic usage of the page.
    this.gtmTimeout = setTimeout(() => this.pushGTMEvent(), 10000);
  }

  private pushGTMEvent() {
    const dataLayer = window['dataLayer'];
    dataLayer.push({ ecommerce: null });
    const price = this.selectedPlanData.membership.items.find(it => it.months === 12).price;
    const eventPayload: any = {
      event: 'begin_checkout',
      ecommerce: {
        currency: 'USD',
        items: [
          {
            item_name: `${this.nameForPlan(this.selectedPlanData) || 'N/A'} plan`,
            item_brand: this.warehouseAddress?.city || 'N/A',
            item_category: this.warehouseAddress?.state || 'N/A',
            price: Number((price * 12).toFixed(2)),
            quantity: 1,
          },
        ],
      },
    };
    dataLayer.push(eventPayload);
    console.log(`pushed event ${JSON.stringify(eventPayload)}`);
  }

  public filterPlansBasedOnCoupon(plans) {
    const couponCode = this.userDataService.getCouponCode();
    if (couponCode) {
      plans = plans.filter(plan => plan.name.toUpperCase() !== 'BUSINESS');
    }
    return plans;
  }

  public setupStripeElements() {
    setTimeout(() => {
      this.stripe = (<any>window).Stripe(environment['stripePublishKey']);
      const elements = this.stripe.elements();
      this.cardNumber = elements.create('cardNumber'); // should be called only when field visible
      this.cardNumber.mount('#card-number');
      this.cardExpiry = elements.create('cardExpiry');
      this.cardExpiry.mount('#card-expiry');
      this.cardCvc = elements.create('cardCvc');
      this.cardCvc.mount('#card-cvc');
      this.cardNumber.addEventListener('change', this.cardHandler);
      this.cardExpiry.addEventListener('change', this.cardHandler);
      this.cardCvc.addEventListener('change', this.cardHandler);
      this.stripeElementsLoading = false;
    }, 1000);
  }

  public setupPlanAndTerm() {
    if (this.plans.length === 0) {
      return;
    }
    if (this.paymentInfoForm.get('selectedPlanId').value) {
      this.plans.forEach(item => {
        if (`${item.id}` === `${this.paymentInfoForm.get('selectedPlanId').value}`) {
          this.selectedPlanData = item;
        }
      });
    }
    if (!this.selectedPlanData.id) {
      this.plans.forEach(plan => {
        if (plan.name === 'FAMILY') {
          this.selectedPlanData = plan;
        }
      });
      this.selectedPlanData = this.selectedPlanData.id ? this.selectedPlanData : this.plans[0];
    }
    this.selectedPlanData.trial_period_in_months = (this.selectedPlanData.trial_period_in_days || 0) / 30;
    this.selectedTermData = {};
    this.selectedPlanData.membership.items.forEach(term => {
      if (`${term.term_id}` === `${this.paymentInfoForm.get('selectedTermId').value}`) {
        this.selectedTermData = term;
      }
    });
    if (!this.selectedTermData.term_id) {
      this.selectedPlanData.membership.items.forEach(term => {
        if (term.months === 12) {
          this.selectedTermData = term;
        }
      });
      this.selectedTermData = this.selectedTermData.term_id ? this.selectedTermData : this.selectedPlanData.membership.items[0];
    }
    this.paymentInfoForm.patchValue({ selectedPlanId: this.selectedPlanData.id, selectedTermId: this.selectedTermData.term_id });
  }

  public termSelected(term) {
    this.paymentInfoForm.patchValue({ selectedTermId: term.term_id });
    this.setupPlanAndTerm();
  }

  public monthsToString(months) {
    return SharedHelpers.monthsToFormattedString(months);
  }

  public async getCountries() {
    this.loading = true;
    this.countries = Country.getAllCountries();
    this.setupStripeElements();
    this.loading = false;
    this.paymentInfoForm.controls['country'].setValue('US');
    this.countrySelected({});
  }

  public countrySelected(event): void {
    this.states = [];
    this.cities = [];
    const id = this.paymentInfoForm.get('country').value;
    if (id) {
      this.getStatesByCountryId(id);
    }
  }
  public stateSelected(countryCode: string, stateCode: string) {
    if (stateCode === 'add_new_option') {
      const dialogRefToAddState = this.dialog.open(DialogWithInputComponent, {
        data: {
          title: 'Add a new State',
          cancelText: 'Cancel',
          confirmText: 'Add',
          addEvent: 'ADD_NEW_STATE',
        },
      });

      dialogRefToAddState?.afterClosed().subscribe(result => {
        if (result.event === 'ADD_NEW_STATE') {
          this.states.push({
            isoCode: result.data,
            name: result.data,
          });
          this.paymentInfoForm.patchValue({
            state: result.data,
          });
          this.paymentInfoForm.patchValue({
            city: '',
          });
        }
      });
    } else {
      this.cities = City.getCitiesOfState(countryCode, stateCode);
    }
  }

  public citySelected() {
    const selectedCity = this.paymentInfoForm.controls['city'].value;
    if (selectedCity && selectedCity === 'add_new_option') {
      const dialogRefToAddCity = this.dialog.open(DialogWithInputComponent, {
        data: {
          title: 'Add a new city',
          cancelText: 'Cancel',
          confirmText: 'Add',
          addEvent: 'ADD_NEW_CITY',
        },
      });

      dialogRefToAddCity?.afterClosed().subscribe(result => {
        if (result.event === 'ADD_NEW_CITY') {
          this.cities.push({
            isoCode: result.data,
            name: result.data,
          });
          this.paymentInfoForm.patchValue({
            city: result.data,
          });
        }
      });
    }
  }

  public getStatesByCountryId(id: string) {
    this.states = State.getStatesOfCountry(id);
    if (id === 'US' || id === 'USA') {
      this.states = [...this.states, ...militaryBases];
    }
  }

  public hasErrors(fieldName: any): boolean {
    return !!this.paymentInfoForm.controls[fieldName].errors;
  }

  public hasError(fieldName: any, errorName: any): boolean {
    return this.paymentInfoForm.controls[fieldName].hasError(errorName);
  }

  public fieldEdited(fieldName: any): boolean {
    return (this.paymentInfoForm.controls[fieldName] || {})['dirty'];
  }

  selectServiceStartDateEvent(event: MatDatepickerInputEvent<Date>) {
    this.selectedServiceStartDate = event.value;
  }

  onDateChanged(args) {
    this.selectedServiceStartDate = args.value;
  }

  public async submitPaymentInfo() {
    this.makingPayment = false;
    Object.keys(this.paymentInfoForm.controls).forEach(key => {
      this.paymentInfoForm.get(key).markAsDirty();
    });
    if (this.paymentInfoForm.invalid) {
      return;
    } // stop here if form is invalid

    if (this.paymentInfoForm.get('isCheckedTermOfUser').value !== true) {
      this.notificationService.showWarning('Please read and agree to our terms of use!');
      return;
    }
    if (this.registeredUserData === null || this.registeredUserData === '') {
      this.notificationService.showError('Your session is invalid. Please try again.');
      return;
    }
    this.makingPayment = true;
    let response;
    const cardHolderName = this.paymentInfoForm.get('cardHolderName').value;
    try {
      response = await this.stripe.createToken(this.cardNumber, { name: cardHolderName });
      if (response.error) {
        this.notificationService.showError(response.error.message);
        this.makingPayment = false;
        return;
      }
    } catch (error) {
      this.notificationService.showError(error.error.message);
      this.makingPayment = false;
      return;
    }
    this.paymentInfoForm.disable();
    // Response token schema: https://stripe.com/docs/api/tokens
    this.createChargebeeSubscription(response.token);
    localStorage.removeItem('registrationData');
  }

  public createChargebeeSubscription(stripeToken): void {
    const payload = this.chargebeeSubscriptionPayload(stripeToken);
    if (this.eligibleForFreeMonth) {
      payload.wantFreeMonth = true;
    }
    localStorage.setItem('gtmPurchaseData', JSON.stringify({ ...payload, warehouseAddress: this.warehouseAddress }));
    this.http.http_post(this.apiMapping.createSubscription(), payload).then(
      async (data: any) => {
        if (data != null && data.status === 201) {
          try {
            const userSubscriptions = await this.http.http_get(this.apiMapping.getUserSubscriptions());
            this.userDataService.setUserSubscriptions(userSubscriptions);
          } catch (error) {}
          this.userDataService.clearPlanPreferences();
          this.notificationService.showSuccess('Subscribed successfully!');
          this.router.navigate([AppRoutes.paymentComplete]);
        } else {
          this.notificationService.showError('Unable to create chargebee subscription. Please try again.');
        }
      },
      (errorJson: any) => {
        this.paymentInfoForm.enable();
        this.makingPayment = false;
        const errorMessage = (errorJson.error || {}).message || errorJson.message;
        if (errorMessage.indexOf('zip') >= 0) {
          this.paymentInfoForm.get('zip').setErrors({ postalCodeInvalid: true });
          this.notificationService.showError('Error creating subscription: Invalid Zip code');
        } else {
          this.notificationService.showError(errorMessage);
        }
      },
    );
  }

  verifyCode(code: string, type: string) {
    if (!code) {
      this.notificationService.showError(`Please enter ${type} code.`);
      return;
    }
    this.verifyingCode = true;
    let apiUrl = this.apiMapping.getCouponByCode(code);
    if (type === 'referral') {
      apiUrl = this.apiMapping.getReferrerByCode(code);
    }
    this.http.http_get(apiUrl).then(
      (response: any) => {
        this.verifyingCode = false;
        if ((response || {}).id) {
          if (type === 'referral') {
            this.appliedReferrer = response;
          } else if (type === 'coupon') {
            let canCouponBeUsedForSelectedPlan = false;
            (this.selectedPlanData.discounts || []).forEach(discount => {
              if (discount.coupon_id === response.id) {
                canCouponBeUsedForSelectedPlan = true;
              }
            });
            if (!canCouponBeUsedForSelectedPlan) {
              this.notificationService.showError(`Unable to apply ${type} for selected plan.`);
              return;
            } else {
              this.appliedCoupon = response;
            }
          }
          this.clearCodeEnterModes();
          this.notificationService.showSuccess(`Successfully applied ${type}!`);
        } else {
          this.notificationService.showError(`Unable to apply ${type}. Please try again.`);
        }
      },
      (errorJson: any) => {
        this.verifyingCode = false;
        SharedHelpers.detectChanges(this.cdr);
        this.notificationService.showError(`Unable to apply ${type}. ${errorJson.error.message}.`);
      },
    );
  }

  public clearCodeEnterModes() {
    this.paymentInfoForm.patchValue({ couponInput: '', referralInput: '' });
    this.referralInputMode = false;
    this.couponInputMode = false;
    SharedHelpers.detectChanges(this.cdr);
  }

  public enterCouponClicked() {
    if (this.couponAutoApplied) {
      return;
    }
    this.clearCodeEnterModes();
    this.couponInputMode = true;
    SharedHelpers.detectChanges(this.cdr);
  }

  public cancelCouponInput() {
    this.clearCodeEnterModes();
  }

  public applyCouponClicked() {
    this.verifyCode(this.paymentInfoForm.get('couponInput').value, 'coupon');
  }

  public enterReferralClicked() {
    this.clearCodeEnterModes();
    this.referralInputMode = true;
    SharedHelpers.detectChanges(this.cdr);
  }

  public cancelReferralInput() {
    this.clearCodeEnterModes();
  }

  public applyReferralClicked() {
    this.verifyCode(this.paymentInfoForm.get('referralInput').value, 'referral');
  }

  public savedAmount() {
    let basicMembership;
    (((this.selectedPlanData || {}).membership || {}).items || []).forEach(membership => {
      if (membership.months < ((basicMembership || {}).months || 10000)) {
        basicMembership = membership;
      }
    });
    return this.selectedTermData.months * (((basicMembership || {}).price || 0) - ((this.selectedTermData || {}).price || 0));
  }

  public savingsMessage() {
    const savings = [];
    if (this.eligibleForFreeMonth) {
      savings.push('1 month free');
    }
    if (this.selectedPlanData.trial_period_in_months) {
      savings.push(`${this.selectedPlanData.trial_period_in_months} month${this.selectedPlanData.trial_period_in_months > 1 ? 's' : ''} free`);
    }
    if (this.savedAmount() > 0) {
      savings.push(`$${this.savedAmount().toFixed(2)} off`);
    }
    if ((this.appliedCoupon || {}).coupon_code) {
      if (this.appliedCoupon.coupon_type === CouponType.FREE_MONTH) {
        savings.push(`${this.appliedCoupon.number_of_free_months} month${this.appliedCoupon.number_of_free_months > 1 ? 's' : ''} free`);
      } else if (this.appliedCoupon.coupon_type === CouponType.PERCENTAGE_OFF) {
        savings.push(`${this.appliedCoupon.percentage_off_amount}% off`);
      }
    }
    return `You saved:<br> ${savings.join(' +<br>')}!`;
  }

  public discountAmount() {
    let discount = 0;
    if ((this.appliedCoupon || {}).coupon_code) {
      discount = parseFloat(
        (
          (((this.selectedTermData || {}).price || 0) * ((this.selectedTermData || {}).months || 1) * parseFloat((this.appliedCoupon.percentage_off_amount || 0) + '')) /
          100.0
        ).toFixed(2),
      );
    }
    return discount;
  }

  public amountToPay(considerTrial: boolean) {
    if (considerTrial && (this.selectedPlanData.trial_period_in_days > 0 || this.eligibleForFreeMonth)) {
      return 0;
    }
    return parseFloat((((this.selectedTermData || {}).price || 0) * ((this.selectedTermData || {}).months || 1) - this.discountAmount()).toFixed(2) + '');
  }

  private chargebeeSubscriptionPayload(stripeToken): IPaymentVendorSubscription {
    return {
      billing_cycles: this.selectedTermData.months,
      customer: {
        email: this.registeredUserData.email,
        warehouse_id: this.userDataService.getWarehouseId() as any,
        first_name: (this.registeredUserData.name || '').split(' ')[0] || '',
        last_name: (this.registeredUserData.name || '').split(' ')[1] || '',
        billing_address: {
          city: this.paymentInfoForm.get('city').value,
          country: this.paymentInfoForm.get('country').value,
          first_name: (this.paymentInfoForm.get('cardHolderName').value || '').split(' ')[0] || '',
          last_name: (this.paymentInfoForm.get('cardHolderName').value || '').split(' ')[1] || '',
          address_line: this.paymentInfoForm.get('address_1').value,
          address_line_2: this.paymentInfoForm.get('address_2').value,
          address_line_3: this.paymentInfoForm.get('address_3').value,
          state: this.paymentInfoForm.get('state').value,
          zip: this.paymentInfoForm.get('zip').value,
          phone_number: this.paymentInfoForm.get('phone').value,
        },
        payment_method: stripeToken, // temporary stripe token object
      } as IPaymentVendorCustomer, // TODO: remove typecast
      start_date: Math.round(+this.selectedServiceStartDate / 1000),
      coupon: (this.appliedCoupon || {}).coupon_code || '',
      referrer_code: (this.appliedReferrer || {}).referral_code || '',
      addons: [],
      plan_id: this.selectedPlanData.id,
      term_id: this.selectedTermData.term_id,
      plan_unit_price: this.selectedTermData.price * 100,
    };
  }

  deleteAddressLine() {
    this.paymentInfoForm.patchValue({ address_3: '' });
    this.numAddressLinesVisible = this.numAddressLinesVisible - 1;
    if (this.numAddressLinesVisible <= 1) {
      this.paymentInfoForm.patchValue({ address_2: '' });
    }
  }

  public nameForPlan(plan: IPlanAttributes) {
    if (plan) {
      return SharedHelpers.snakeToSentenceCase(plan.name);
    }
    return '';
  }

  public renderPaymentScheduleMessage(selectedPlanData, term) {
    let result = `${selectedPlanData.trial_period_in_days} days free. Then $${Math.round(term['price'] * term['months'] * 100) / 100}/`;
    result += term['months'] === 1 ? 'month, starting ' : term['months'] === 12 ? 'year, billed on ' : '2 years, billed on ';
    result += moment().add(selectedPlanData.trial_period_in_days, 'days').format('MMM DD, YYYY');
    return result;
  }

  ngOnDestroy() {
    this.cdr.detach();
    if (this.gtmTimeout) {
      clearTimeout(this.gtmTimeout);
      this.gtmTimeout = null;
    }
  }
}
