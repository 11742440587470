import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as Services from '../../services';
import { Animations } from '../../animations/element.animations';
import { environment } from '../../../environments/environment';
import { DialogComponent } from '../../components/dialog';
import { PdfViewerDialogComponent } from '../../components/pdf-viewer-dialog';
import * as SharedHelpers from '../../utils/helpers';
import { AccountStatus, Status } from '@usgm/usgm-payloads-library-front';
import { IssueReportedRequestType } from '../../utils/constants';

@Component({
  selector: 'usgm-scan-details-popup',
  templateUrl: './scan-details-popup.component.html',
  styleUrls: ['./scan-details-popup.component.scss'],
  animations: Animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanDetailsPopupComponent implements OnInit, OnDestroy {
  public loading = false;
  public downloading = false;
  public submittingIssue = false;
  public comment = '';
  public selectedDeclarationUrl = '';
  public showZoomedImage = false;

  @Input() scan: any = {};
  @Input() scanType = '';
  @Output() closeNav = new EventEmitter();
  @Output() closeNavAndReloadScans = new EventEmitter();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected http: Services.UsgmHttp,
    protected dialog: MatDialog,
    protected apiMapping: Services.ApiMapping,
    protected notificationService: Services.NotificationService,
    protected userDataService: Services.UserDataService,
  ) {}

  ngOnInit() {}

  confirmCancel() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: `Are you sure you want to cancel this ${this.scan.category_type_name}?`,
      title: 'Please confirm!',
      cancelText: 'No',
      confirmText: 'Yes, cancel!',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.cancelRequest();
      }
    });
  }

  cancelRequest() {
    this.loading = true;
    this.http.put(this.apiMapping.cancelScan(this.scan.id), {}).subscribe(
      (data: any) => {
        this.loading = false;
        if (data.message.toLowerCase().indexOf('cancelled successfully') >= 0) {
          this.closeSideNavAndReloadScans();
        } else {
          this.notificationService.showError('Unable to cancel request. Please try again.');
        }
      },
      (error: any) => {
        this.loading = false;
        this.notificationService.showError('Unable to cancel request. Please try again.');
      },
    );
  }

  ifUnboxImagesContainPDF(unboxImages) {
    if (!unboxImages || !unboxImages.length) {
      return true;
    }
    return !!unboxImages.find(item => item.includes('.pdf') || item.includes('.PDF'));
  }

  downloadPdf() {
    this.downloading = true;
    this.http.get(this.apiMapping.downloadScan(this.scan.id), { responseType: 'blob', observe: 'response' }).subscribe(
      result => {
        const contentType = result.headers.get('content-type');
        SharedHelpers.downloadFile(result.body, `${this.scan.request_type_without_text} #${this.scan.id}.${contentType.split('/')[1]}`, contentType);
        this.downloading = false;
        this.notificationService.showWarning('File downloaded.');
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.downloading = false;
        this.notificationService.showError('Unable to download file. Please try again.');
      },
    );
  }

  viewPdf() {
    this.dialog.open(PdfViewerDialogComponent, {
      maxWidth: 'calc(100vw - 10px)',
      panelClass: 'no-padding',
      data: { url: this.apiMapping.viewScan(this.scan.id), fileType: this.scan.file_extension },
    });
  }

  submitIssueComment() {
    if (this.comment.length < 2) {
      this.notificationService.showError('Please enter at least 2 characters.');
      return;
    }
    this.submittingIssue = true;
    const payload = {
      requestID: this.scan.id,
      comment: this.comment,
      reportAnIssueType: IssueReportedRequestType.SCAN_REQUEST,
      mailItemId: this.scan.mail.id,
      requestedOn: this.scan.isoCreatedOn,
    };
    this.http.post(this.apiMapping.reportedIssueByCustomer(), { ...payload }).subscribe(
      (response: { status: Status; message: string }) => {
        this.comment = '';
        this.scan.contactSupportEnabled = false;
        this.submittingIssue = false;
        SharedHelpers.detectChanges(this.cdr);
        this.notificationService.showSuccess(response.message);
      },
      error => {
        this.submittingIssue = false;
        this.notificationService.showError(error.message);
      },
    );
  }

  closeSideNavAndReloadScans() {
    this.closeNavAndReloadScans.emit({});
  }

  closeSideNav() {
    this.closeNav.emit({});
  }

  allActionsDisabled() {
    return this.userDataService.getAccountStatus() === AccountStatus.SUSPENDED;
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
