import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';
import { IssueDetailPopupComponent } from './issue-detail-popup.component';
import { IssueRowModule } from '../issue-row';

@NgModule({
  declarations: [IssueDetailPopupComponent],
  imports: [CommonModule, IonicModule, IssueRowModule, MatInputModule],
  exports: [IssueDetailPopupComponent],
})
export class IssueDetailPopupModule {}
