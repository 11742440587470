// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host .main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: calc(100vh - 280px);
}
:host .logo-cont {
  margin-top: 40px;
  margin-left: 40px;
}
:host .logo-cont img {
  width: 270px;
}
:host .title {
  text-align: left;
  color: #4C4C4C;
  font-size: 40px;
  line-height: 52px;
}
:host .note-box {
  font-size: 22px;
  line-height: 30px;
  color: #4C4C4C;
  text-align: left;
  margin-bottom: 0;
  max-width: 740px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/components/maintenance/maintenance.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;AAGE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,OAAA;EACA,2BAAA;AADJ;AAIE;EACE,gBAAA;EACA,iBAAA;AAFJ;AAGI;EACE,YAAA;AADN;AAKE;EACE,gBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AAHJ;AAME;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;AAJJ","sourcesContent":["@import '../../../styles/variables';\n\n:host {\n  display: block;\n\n  .main-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    flex: 1;\n    height: calc(100vh - 280px);\n  }\n\n  .logo-cont {\n    margin-top: 40px;\n    margin-left: 40px;\n    img {\n      width: 270px;\n    }\n  }\n\n  .title {\n    text-align: left;\n    color: #4C4C4C;\n    font-size: 40px;\n    line-height: 52px;\n  }\n\n  .note-box {\n    font-size: 22px;\n    line-height: 30px;\n    color: #4C4C4C;\n    text-align: left;\n    margin-bottom: 0;\n    max-width: 740px;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
