import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Animations } from '../../animations/element.animations';
import * as Services from '../../services';
import * as SharedHelpers from '../../utils/helpers';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'usgm-enable-mfa-popup',
  templateUrl: './enable-mfa-popup.component.html',
  styleUrls: ['./enable-mfa-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: Animations,
})
export class EnableMfaPopupComponent implements OnInit, OnDestroy {
  private unSubscriber: Subject<any> = new Subject();
  public enteredCode = '';
  public loading = false;
  public resending = false;

  private unsubscribeService: Subject<any> = new Subject();
  public mfaCountdownCounter;
  private mfaCounterInterval;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<EnableMfaPopupComponent>,
    private userDataService: Services.UserDataService,
    private apiMapping: Services.ApiMapping,
    private http: Services.UsgmHttp,
    private notificationService: Services.NotificationService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.startMFAResendCountdown();
  }

  startMFAResendCountdown() {
    this.mfaCountdownCounter = 15; // seconds
    if (this.mfaCounterInterval) {
      this.clearMFAResendInterval();
    }
    SharedHelpers.detectChanges(this.cdr);
    this.mfaCounterInterval = setInterval(() => {
      this.mfaCountdownCounter = Math.max(0, this.mfaCountdownCounter - 1);
      if (this.mfaCountdownCounter === 0) {
        this.clearMFAResendInterval();
      }
      SharedHelpers.detectChanges(this.cdr);
    }, 1000);
  }

  clearMFAResendInterval() {
    clearInterval(this.mfaCounterInterval);
    this.mfaCounterInterval = null;
  }

  public submitVerificationCode() {
    this.loading = true;
    SharedHelpers.detectChanges(this.cdr);
    this.userDataService
      .enableMFA(this.enteredCode)
      .then(response => {
        this.loading = false;
        this.notificationService.showSuccess('2-Step verification enabled');
        this.dialogRef.close(true);
      })
      .catch(error => {
        this.loading = false;
        SharedHelpers.detectChanges(this.cdr);
        this.notificationService.showError(error.error?.message || 'Can not enable 2-Step Verification');
      });
  }

  public resendVerificationCode() {
    this.resending = true;
    const mfaData = this.userDataService.getMFAData();
    this.userDataService
      .generateAndSendMFACode(this.userDataService.getUserId())
      .pipe(takeUntil(this.unsubscribeService))
      .subscribe(
        mfaResponse => {
          this.resending = false;
          this.enteredCode = '';
          if (mfaResponse && mfaResponse['data']) {
            this.userDataService.setMFAData({
              ...mfaData,
              mfa_temp_token: mfaResponse['data'],
            });
            this.notificationService.showSuccess('A new verification code has been sent to you over email');
            this.startMFAResendCountdown();
          } else {
            this.notificationService.showError('Something went wrong in generating and sending a verification code');
          }
          SharedHelpers.detectChanges(this.cdr);
        },
        (error: any) => {
          this.notificationService.showError(error.error?.message || 'Something went wrong in generating and sending a verification code');
          this.resending = false;
          SharedHelpers.detectChanges(this.cdr);
        },
      );
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.unSubscriber.next({});
    this.unSubscriber.complete();
  }
}
