import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ApiMapping, UserDataService, UsgmHttp, NotificationService } from '../../services';
import * as SharedHelpers from '../../utils/helpers';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppRoutes } from '../../models/constants/app-routes.constant';
import { environment } from '../../../environments/environment';
import { ReferrerModel, ReferrerUserModel } from '../../models/referrer-model';

@Component({
  selector: 'usgm-refer-friend',
  templateUrl: './refer-friend.component.html',
  styleUrls: ['./refer-friend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferFriendComponent implements OnInit, OnDestroy {
  public referralCode: string;
  public referrerData: any;
  public userReferrersList: any;
  public fetchingReferrerData = false;
  public fetchingUserReferrersList = false;
  public unsubscribeService: Subject<any> = new Subject();

  @Output() closeNav = new EventEmitter();

  constructor(
    protected cdr: ChangeDetectorRef,
    private userDataService: UserDataService,
    private http: UsgmHttp,
    private apiMapping: ApiMapping,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    this.getUserReferrerData();
  }

  copiedToClipboard(event) {
    this.notificationService.showSuccess('Referral Code copied to clipboard');
  }

  getUserReferrerData() {
    if (this.userDataService.hasUserReferrerData()) {
      this.referrerData = this.userDataService.getUserReferrerData();
      this.setReferralCode();
      this.getListOfUserRefferers();
    } else {
      this.fetchingReferrerData = true;
      this.http
        .get(this.apiMapping.getReferrerByUserId(this.userDataService.getUserId()))
        .pipe(takeUntil(this.unsubscribeService))
        .subscribe(
          (res: ReferrerModel) => {
            if (res) {
              this.referrerData = res;
              this.fetchingReferrerData = false;
              this.userDataService.setUserReferrerData(res);
              this.setReferralCode();
              this.getListOfUserRefferers();
              SharedHelpers.detectChanges(this.cdr);
            }
          },
          (error: any) => {
            this.fetchingReferrerData = false;
            SharedHelpers.detectChanges(this.cdr);
          },
        );
    }
  }

  setReferralCode() {
    if (this.referrerData && this.referrerData.referrer) {
      this.referralCode = this.referrerData.referrer.referral_code;
    }
  }

  getListOfUserRefferers() {
    if (this.userDataService.hasUserReferrersList()) {
      this.userReferrersList = this.userDataService.getUserReferrersList();
    } else {
      this.fetchingUserReferrersList = true;
      this.http
        .get(this.apiMapping.getReferrerUsersByReferrerId(this.userDataService.getUserId()))
        .pipe(takeUntil(this.unsubscribeService))
        .subscribe(
          (res: ReferrerUserModel) => {
            if (res['referrers'] && res['referrers'].length > 0) {
              this.userReferrersList = res['referrers'];
              this.userDataService.setUserReferrersList(res['referrers']);
              SharedHelpers.detectChanges(this.cdr);
            }
            this.fetchingReferrerData = false;
          },
          (error: any) => {
            this.fetchingReferrerData = false;
            SharedHelpers.detectChanges(this.cdr);
          },
        );
    }
  }

  handleFacebookShare() {
    const sharerUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.getStartedLink()}&quote=${this.shareText()}`;
    window.open(sharerUrl, '_system');
  }

  handleWhatsappShare() {
    const url = this.userDataService.isMobileMode ? 'whatsapp://' : 'https://web.whatsapp.com/';
    const sharerUrl = `${url}send?text=${this.shareText()} ${this.getStartedLink()}`;
    window.open(sharerUrl, '_system');
  }

  shareText() {
    return `US Global Mail makes your postal mail virtual and accessible online 24/7! \n
Sign up for US Global Mail's Smart Mailbox today and we both will get a complimentary month of service. Use this code at checkout: ${this.referralCode} \n
Get started: `;
  }

  getStartedLink() {
    return `${environment.appUrl}/${AppRoutes.signup}`;
  }

  closeSideNav() {
    this.closeNav.emit({});
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
