import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserDataService } from '../../services';
import * as SharedHelpers from '../../utils/helpers';
import { VerifyAccountService } from '../../verify-account/verify-account.service';

@Component({
  selector: 'usgm-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabBarComponent implements OnInit, OnDestroy {
  public menuItems: any[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    public userDataService: UserDataService,
    protected verifyAccountService: VerifyAccountService,
  ) {}

  ngOnInit() {
    this.verifyAccountService.mobileMenuItemsList.subscribe(data => {
      if (this.menuItems.length !== data.length) {
        this.menuItems = data;
        this.setMobileMenu();
        SharedHelpers.detectChanges(this.cdr);
      }
    });
  }

  setMobileMenu() {
    this.menuItems.forEach(menuItem => {
      menuItem.expanded = false;
      menuItem.selected = this.router.url.indexOf(menuItem.path) >= 0;
      if (menuItem.icon === 'inbox') {
        menuItem.children = [];
      }
    });
    this.userDataService.menuCountUpdated$.subscribe(event => {
      this.menuItems.forEach(menuItem => {
        if (
          (event.name === 'inbox' && menuItem.icon === 'inbox') ||
          (event.name === 'scans' && menuItem.icon === 'scans') ||
          (event.name === 'shipments' && menuItem.icon === 'shipments')
        ) {
          menuItem.count = event.count;
        }
      });
      SharedHelpers.detectChanges(this.cdr);
    });
  }

  navToPath(path) {
    this.router.navigateByUrl(path);
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
