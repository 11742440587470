// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host virtual-scroller {
  height: 600px;
}
:host .selection-control {
  cursor: pointer;
}
:host td {
  min-height: 40px;
  padding: 20px 10px 20px 0;
  position: relative;
}
:host .issue-icon {
  position: absolute;
  top: calc(50% - 10px);
  left: 10px;
}
:host .table-image {
  margin: 10px 20px;
}
:host tbody {
  border-top: 1px solid #e0e0e0;
}`, "",{"version":3,"sources":["webpack://./src/app/shipments/shipments-table/shipments-table.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,gBAAA;EACA,yBAAA;EACA,kBAAA;AADJ;AAIE;EACE,kBAAA;EACA,qBAAA;EACA,UAAA;AAFJ;AAKE;EACE,iBAAA;AAHJ;AAME;EACE,6BAAA;AAJJ","sourcesContent":[":host {\n  display: block;\n\n  virtual-scroller {\n    height: 600px;\n  }\n\n  .selection-control {\n    cursor: pointer;\n  }\n\n  td {\n    min-height: 40px;\n    padding: 20px 10px 20px 0;\n    position: relative;\n  }\n\n  .issue-icon {\n    position: absolute;\n    top: calc(50% - 10px);\n    left: 10px;\n  }\n\n  .table-image {\n    margin: 10px 20px;\n  }\n\n  tbody {\n    border-top: 1px solid #e0e0e0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
