// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host td.label {
  width: 40%;
}
@media (max-width: 330px) {
  :host td.label {
    width: 50%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/inbox/item-popup/item-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,UAAA;AACJ;AAEE;EACE;IACE,UAAA;EAAJ;AACF","sourcesContent":[":host {\n  display: block;\n\n  td.label {\n    width: 40%;\n  }\n\n  @media (max-width: 330px) {\n    td.label {\n      width: 50%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
