import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Animations } from '../../animations/element.animations';
import { AppRoutes } from '../../models/constants/app-routes.constant';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserDataService } from '../../services';
import * as Constants from '../../utils/constants';

@Component({
  selector: 'usgm-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  animations: Animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceComponent implements OnInit, OnDestroy {
  private unSubscriber: Subject<any> = new Subject();
  public imgSrc = Constants.USGM_LOGO;
  public isMobileView = false;
  public oldMaintenanceValue = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    public userDataService: UserDataService,
    private plt: Platform,
  ) {
    // if (this.plt.platforms().indexOf('cordova') >= 0) {
    //   this.imgSrc = Constants.USGM_MINI_LOGO;
    //   this.isMobileView = true;
    // }
    this.oldMaintenanceValue = this.userDataService.isMaintenanceEnabled;
  }

  ngOnDestroy(): void {
    this.cdr.detach();
    this.unSubscriber.next({});
    this.unSubscriber.complete();
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (!this.userDataService.isMaintenanceEnabled) {
        this.router.navigate([AppRoutes.welcome]);
      }
    }, 2000);
    this.userDataService.maintenanceEnabledSubject.pipe(takeUntil(this.unSubscriber)).subscribe(enabled => {
      this.oldMaintenanceValue = enabled;
      if (!enabled && this.oldMaintenanceValue !== enabled) {
        this.router.navigate([AppRoutes.welcome]);
        window.location.reload();
      }
    });
  }
}
