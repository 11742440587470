// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  padding: 10px;
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
}
:host ::ng-deep.mat-mdc-card-header-text {
  display: none;
}
:host .mat-mdc-radio-button {
  margin-left: -12px !important;
}
:host .mat-mdc-card-title {
  margin: 0px;
  margin-left: 10px;
}
:host .suggestion-container {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 16px;
}
:host .suggestion-container-selected {
  background: #f4fcff;
}
:host .mat-mdc-card-header {
  padding: 0px !important;
}
:host .edit-btn {
  margin: 0px;
  padding: 0px;
  text-align: left;
  width: 100px;
  margin-left: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/inbox/suggest-address-dialog/suggest-address-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;EAEA,2HAAA;AAAF;AACE;EACE,aAAA;AACJ;AAEE;EACE,6BAAA;AAAJ;AAGE;EACE,WAAA;EACA,iBAAA;AADJ;AAIE;EACE,eAAA;EACA,kBAAA;EACA,aAAA;AAFJ;AAKE;EACE,mBAAA;AAHJ;AAME;EACE,uBAAA;AAJJ;AAOE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,2BAAA;AALJ","sourcesContent":[":host {\n  display: block;\n  padding: 10px;\n\n  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */\n  ::ng-deep.mat-mdc-card-header-text {\n    display: none;\n  }\n\n  .mat-mdc-radio-button {\n    margin-left: -12px !important;\n  }\n\n  .mat-mdc-card-title {\n    margin: 0px;\n    margin-left: 10px;\n  }\n\n  .suggestion-container {\n    margin-top: 5px;\n    margin-bottom: 5px;\n    padding: 16px;\n  }\n\n  .suggestion-container-selected {\n    background: #f4fcff;\n  }\n\n  .mat-mdc-card-header {\n    padding: 0px !important\n  }\n\n  .edit-btn {\n    margin: 0px;\n    padding: 0px;\n    text-align: left;\n    width: 100px;\n    margin-left: 0px !important;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
