import { MatDialog } from '@angular/material/dialog';
import { IAddressAttributes, UserType } from '@usgm/usgm-payloads-library-front';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Subject } from 'rxjs';
import { retry, takeUntil } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import * as Services from '../../services';
import { UsgmHttp } from '../../services';
import { HttpErrorResponse } from '@angular/common/http';
import * as SharedHelpers from '../../utils/helpers';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AllForm, FormDetails, OnboardFormResponse, Relation } from '../../models/get-notarized.model';
import { AccountStatus, AddressType, RecordStatus } from '@usgm/usgm-payloads-library-front/Enums';
import { VerifyAccountService } from '../verify-account.service';
import { PdfViewerDialogComponent } from '../../components/pdf-viewer-dialog';
import { CaptchaBaseComponent } from '../../../app/captcha-base-component/captcha-base-component.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { expirableMemoize } from '../../utils/expirable_memoize';
import { renderRelation } from '../../utils/helpers';

const DATE_FORMAT = 'MMM DD yyyy';

@Component({
  selector: 'usgm-get-notarized',
  templateUrl: './get-notarized.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetNotarizedComponent extends CaptchaBaseComponent implements OnInit {
  private unSubscriber: Subject<any> = new Subject();
  private pmbOpenedDate = null;
  private primaryUserEmail = null;
  public notaryPlans = [];
  public activePlan: any;
  formReviewed = false;
  firstFormName = '';
  secondFormName = '';
  currentFormName = this.firstFormName;
  notarizedForm = '';
  downLoadingPdf = false;
  userName: string;
  userForm: FormGroup;
  submitted: Boolean = Boolean(false);
  loading: Boolean = Boolean(false);
  public cities: any;
  public states: any[] = [];
  public countries: any;
  public channelPartnerName: string;
  public warehouseAddress: IAddressAttributes;
  public notarization: Boolean = Boolean(false);
  public currentAddressData: IAddressAttributes;
  public allAddresses: any[] = [];
  public userNamesData: any[] = [];
  public currentNameIndex: number;
  public currentFormData: FormDetails;
  public account_name_on_form: string;
  public applicant_account_name_on_form: string;
  public allDocuments: any[] = [];
  public accountStatus = AccountStatus;
  public allRelativeNames: any[] = [];
  public allForm1583List: AllForm[] = [];
  public boxNumberValue: string;
  public isBusinessPlan: Boolean = Boolean(false);
  public continueBtnClick: Boolean = Boolean(false);
  public mobileMode: Boolean = Boolean(false);
  public spinnerLoad: Boolean = Boolean(false);
  public planType: string;
  public notarizedPlanSelected: string;
  public businessDataLoaded = false;
  public selectedId: string;
  public settingPageLoad = false;
  public displayDataFlag = false;
  public allAccountNames: any[] = [];
  public typeBusiness = false;
  public typeResidential = false;
  public primaryDocumentType = null;
  public secondaryDocumentType = null;

  public getUserMailingAddressByIdMemoized: Function;

  @Input() item;

  @Input() set notaryPlan(value: string) {
    if (value) {
      this.notarization = true;
      this.notarizedForm = value;
      this.notarizedPlanSelected = value;
      this.cdRef.detectChanges();
    }
  }

  @Input() set addressesArray(value: []) {
    this.allAddresses = value;
    this.getMailingAddress();
    SharedHelpers.detectChanges(this.cdRef);
  }

  @Output() nameUpdate = new EventEmitter();

  constructor(
    private matDialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private stepper: MatStepper,
    protected verifyService: Services.VerifyAccountService,
    protected apiMapping: Services.ApiMapping,
    public override notificationService: Services.NotificationService,
    protected userDataService: Services.UserDataService,
    private formBuilder: FormBuilder,
    private verifyAccountService: VerifyAccountService,
    private http: UsgmHttp,
    public override dialog: MatDialog,
    public override recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
    this.getUserMailingAddressByIdMemoized = expirableMemoize(
      id => this.userDataService.getUserMailingAddressById(id).pipe(takeUntil(this.unSubscriber), retry(3)).toPromise(),
      5,
      true,
    );
    this.initForm();
  }

  public override ngOnInit() {
    this.verifyAccountService.count.subscribe(c => {
      if (c > 0) {
        this.getAccountNames();
        this.getAllForms();
      }
    });
    this.verifyAccountService.fetchDocuments.subscribe(c => {
      if (c > 0) {
        this.displayDataFlag = true;
        this.downloadIDsFirstCall(this.getDocuments.bind(this));
      }
    });
    this.verifyService.settingsNameId.subscribe(value => {
      if (value) {
        this.settingPageLoad = value.isSettings;
        this.selectedId = value.id;
      } else {
        this.settingPageLoad = false;
        this.selectedId = null;
      }
      SharedHelpers.detectChanges(this.cdRef);
    });
    this.getAccountNames();
    this.getAllForms();
    this.getAllNotaryPlans();
    this.downloadIDsFirstCall(this.getDocuments.bind(this));
    this.getUserBoxNumber();
    this.setDisplayMode();
    this.getSubscriptions();
    this.getWarehouseAddress();
    this.isBusinessPlan = true;
  }

  async getSubscriptions() {
    this.userDataService
      .getUserSubscription()
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(response => {
        if (response && response.length) {
          const subscription = response.find(item => this.userDataService.isActiveSubscription(item));
          if (subscription) {
            this.pmbOpenedDate = moment(subscription.createdAt).format(DATE_FORMAT) || '';
            this.setDeliveryAddress();
            this.getSelectedPlan(subscription.plan_id);
          }
        }
      });
  }

  async getSelectedPlan(planId) {
    this.userDataService
      .getUserPlanById(planId)
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(response => {
        if (response && response.account_names_plan) {
          this.planType = response.account_names_plan.max_names > 0 && response.options.is_business ? 'business' : 'other';
          this.getMailingAddress();
        }
        SharedHelpers.detectChanges(this.cdRef);
      });
  }

  public setDisplayMode() {
    this.mobileMode = this.userDataService.isMobileMode;
    if (this.mobileMode === true) {
      this.scrollToTop();
      SharedHelpers.detectChanges(this.cdRef);
    }
  }

  public downloadPdf(token, captchaVersion, selectedId = null) {
    if (this.downLoadingPdf) {
      return false;
    }
    this.downLoadingPdf = true;
    this.http.get(this.apiMapping.getDownloadPdfForm(selectedId, token, captchaVersion), { responseType: 'arraybuffer' }).subscribe(
      result => {
        SharedHelpers.UnZip(result);
        this.notificationService.showWarning('File downloaded.');
        this.downLoadingPdf = false;
        this.cdRef.detectChanges();
      },
      error => {
        if (error.status === 422) {
          this.verifyCaptcha(this.downloadPdf.bind(this), selectedId);
        } else {
          this.downLoadingPdf = false;
          this.cdRef.detectChanges();
          this.notificationService.showError('Unable to download file. Please try again.');
        }
      },
    );
  }

  downloadZipForm(selectedId?: number) {
    this.downloadFormsFirstCall(this.downloadPdf.bind(this), selectedId);
  }

  initForm() {
    this.userForm = this.formBuilder.group({
      pmb_open_date: [null],
      pmb_close_date: [null],
      delivery_address_line: [null],
      delivery_pmb_number: [null],
      delivery_city: [null],
      delivery_state: [null],
      delivery_postal_code: [null],
      applicant_last_name: [null],
      applicant_first_name: [null],
      applicant_middle_name: [null],
      applicant_phone_number: [null],
      applicant_email: [null],
      applicant_address_line: [null],
      applicant_city: [null],
      applicant_state: [null],
      applicant_postal_code: [null],
      applicant_country: [null],
      company_name: [null],
      company_type_of_business: [null],
      company_address_line: [null],
      company_city: [null],
      company_state: [null],
      company_postal_code: [null],
      company_country: [null],
      company_phone_number: [null],
      company_place_of_registration: [null],
      document_primary_full_name: [null],
      document_primary_id_number: [null],
      document_primary_issuing_entity: [null],
      document_primary_expiration_date: [null],
      document_primary_type: [null],
      document_secondary_full_name: [null],
      document_secondary_address_line: [null],
      document_secondary_city: [null],
      document_secondary_state: [null],
      document_secondary_postal_code: [null],
      document_secondary_country: [null],
      document_secondary_type: [null],
      additional_names_in_form: [null],
      usage_type: [null],
    });
  }

  // convenience getter for easy access to form fields
  get userFormData() {
    return this.userForm.controls;
  }

  async updateName(deviceData) {
    if (!this.allRelativeNames[this.currentNameIndex]) {
      return false;
    }
    if (!this.boxNumberValue) {
      this.notificationService.showError('You are not assigned a box number. Please contact the admin to resolve this issue to proceed further.');
      return false;
    }
    this.submitted = true;
    // stop here if form is invalid
    if (this.userForm.invalid) {
      this.cdRef.detectChanges();
      this.scrollToTop();
      return;
    }
    const params = { form_1583_fields: this.userForm.value };

    this.spinnerLoad = true;
    const id = this.allRelativeNames[this.currentNameIndex].user.uuid;

    this.verifyService
      .setUserFormData(this.apiMapping.setUserOnboardForm(id), params)
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        resp => {
          this.submitted = false;
          this.allRelativeNames[this.currentNameIndex].status = AccountStatus.FORM_1583_ADDED;
          if (deviceData !== 'MobileSubmit') {
          } else {
            if (this.continueBtnClick === false) {
              this.downloadForm1583(id);
            }
          }
          if (this.currentNameIndex !== this.allRelativeNames.length - 1) {
            this.userForm.reset();
          }
          if (this.settingPageLoad) {
            this.nameUpdate.emit();
          } else {
            if (deviceData !== 'MobileSubmit') {
              this.continueName();
            }
          }
          this.spinnerLoad = false;
          this.scrollToTop();
          this.cdRef.detectChanges();
        },
        error => {
          this.spinnerLoad = false;
          this.submitted = false;
          this.notificationService.showError('Unable to save form 1583');
          SharedHelpers.detectChanges(this.cdRef);
        },
      );
  }

  notarizedPopup(isMobileSubmit): void {
    if (isMobileSubmit === 'MobileSubmit') {
      this.submitAllForm();
    }
    this.notarization = true;
    this.cdRef.detectChanges();
  }

  async submitAllForm() {
    if (this.settingPageLoad && this.selectedId) {
      this.continueBtnClick = true;
      await this.updateName('MobileSubmit');
    } else {
      this.allRelativeNames.filter(async (allRelativeNameData: any, index: number) => {
        this.continueBtnClick = true;
        this.sortUserData(index);
        await this.updateName('MobileSubmit');
      });
    }
  }

  navigateBackToForm() {
    this.scrollToTop();
    this.notarization = false;
    // this.notarizedForm = '';
    if (this.settingPageLoad) {
      this.selectName(this.currentNameIndex);
    } else {
      this.selectName(this.allRelativeNames.length - 1);
    }
  }

  navigateBackToFormOnlyPlan() {
    this.scrollToTop();
    this.notarization = true;
    this.notarizedForm = '';
  }

  public getAllNotaryPlans(): void {
    this.spinnerLoad = true;
    SharedHelpers.detectChanges(this.cdRef);
    this.verifyService
      .getnotaryPlans(this.apiMapping.getNotaryPlans())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(
        apiResponse => {
          // this is temporary disable for notarycam
          this.notaryPlans = (apiResponse.plan || []).filter(plan => plan.plan_type !== 'NOTARYCAM').sort((a, b) => (a.plan_type < b.plan_type ? 1 : -1));
          this.notarizedForm = this.notaryPlans[0].plan_type.toLowerCase();
          this.activePlan = this.notaryPlans[0];
          this.spinnerLoad = false;
          SharedHelpers.detectChanges(this.cdRef);
        },
        (error: HttpErrorResponse) => {
          this.spinnerLoad = false;
          SharedHelpers.detectChanges(this.cdRef);
          this.notificationService.showError(error.message);
        },
      );
  }

  openNotarizedForm(type): void {
    this.notarizedForm = type;
    this.activePlan = this.notaryPlans.find(item => item.plan_type.toLowerCase() === type);
  }

  onBack(): void {
    if (this.currentFormName === this.firstFormName) {
      this.stepper.previous();
    } else if (this.currentFormName === this.secondFormName) {
      this.currentFormName = this.firstFormName;
      this.formReviewed = false;
      this.cdRef.detectChanges();
    }
  }

  scrollToTop(): void {
    const stepperElement = document.getElementById('stepper');
    if (stepperElement) {
      stepperElement.scrollIntoView();
    }
  }

  async getMailingAddress(mobileSubmit = false) {
    const apiResponse = await this.getUserMailingAddressByIdMemoized(this.userDataService.getUserId());
    if (apiResponse) {
      this.allAddresses = apiResponse['addresses'];
      this.getAddressData();
      if (mobileSubmit) {
        await this.updateName('MobileSubmit');
      }
    }
  }

  async getWarehouseAddress() {
    if (this.userDataService.getWarehouseAddress()) {
      this.warehouseAddress = this.userDataService.getWarehouseAddress() || {};
      this.channelPartnerName = this.userDataService.channelPartnerName || '';
    }
    if (!this.warehouseAddress) {
      this.http
        .get(this.apiMapping.getUserAddressesByType(this.userDataService.getUserId(), AddressType.WAREHOUSE))
        .pipe(retry(3))
        .subscribe((data: any) => {
          this.warehouseAddress = data.addresses[0] || {};
          this.channelPartnerName = data.channelPartnerName || '';
          this.userDataService.setWarehouseAddress(this.warehouseAddress);
          this.setDeliveryAddress();
        });
    } else {
      this.setDeliveryAddress();
    }
  }

  async getUserBoxNumber(counter = 0) {
    const warehouseBoxNumber = this.userDataService.getWarehouseBoxNumber();
    if (warehouseBoxNumber) {
      this.boxNumberValue = warehouseBoxNumber;
      this.cdRef.detectChanges();
      this.setDeliveryAddress();
    } else {
      if (counter < 3) {
        await this.userDataService.getBoxNumber();
        this.getUserBoxNumber(counter++);
      }
    }
  }

  setDeliveryAddress() {
    if (!this.warehouseAddress?.address_line) {
      return false;
    }
    this.userFormData.pmb_open_date.setValue(this.pmbOpenedDate || '');
    this.userFormData.delivery_address_line.setValue(
      `${this.warehouseAddress.address_line} ${this.warehouseAddress.address_line_2 || ''} ${this.warehouseAddress.address_line_3 || ''}`,
    );
    this.userFormData.delivery_pmb_number.setValue(this.boxNumberValue || '');
    this.userFormData.delivery_city.setValue(this.warehouseAddress.city?.trim() || '');
    this.userFormData.delivery_state.setValue(this.warehouseAddress.state?.trim() || '');
    this.userFormData.delivery_postal_code.setValue(this.warehouseAddress.postal_code?.trim() || '');
    SharedHelpers.detectChanges(this.cdRef);
  }

  getAddressData() {
    const allAddressData = this.allAddresses;
    const maxValueOfId = Math.max(...allAddressData.map(recentRecord => recentRecord.id), 0);
    if (isNaN(maxValueOfId)) {
      this.currentAddressData = allAddressData[allAddressData.length - 1];
    } else {
      this.currentAddressData = allAddressData.find(data => {
        if (data.id === maxValueOfId) {
          return data;
        }
      });
    }
    if (this.currentAddressData) {
      this.setCurrentAddress();
    }
  }

  async setCurrentAddress() {
    const selectedUser = this.allRelativeNames[this.currentNameIndex];
    let documentsUserId = selectedUser.user.id;
    if ([Relation.COMPANY, Relation.TRUST, Relation.DECEASED].includes(selectedUser.relation?.relationType)) {
      documentsUserId = this.allRelativeNames.find(it => it.relation?.relationType === Relation.PRIMARY)?.user?.id;
    }
    const userDocuments = this.allDocuments.find(it => it.user_id === documentsUserId);
    const primaryDocument = userDocuments?.documents.find(it => it.user_type === UserType.PRIMARY);
    const secondaryDocument = userDocuments?.documents.find(it => it.user_type === UserType.SECONDARY);
    const nameParts = primaryDocument?.document_fields?.name?.trim() ? primaryDocument.document_fields.name.trim().split(' ') : this.currentAddressData.name.split(' ');
    this.userFormData.applicant_first_name.setValue(primaryDocument?.document_fields?.first_name || (nameParts?.length ? nameParts[0] : ''));
    this.userFormData.applicant_middle_name.setValue(primaryDocument?.document_fields?.middle_name || '');
    this.userFormData.applicant_last_name.setValue(primaryDocument?.document_fields?.last_name || (nameParts?.length > 1 ? nameParts.slice(1).join(' ') : ''));
    this.userFormData.applicant_phone_number.setValue(this.currentAddressData.phone_number?.trim() || '');
    this.userFormData.applicant_email.setValue(this.primaryUserEmail || '');
    this.userFormData.applicant_address_line.setValue(secondaryDocument?.document_address?.address_line?.trim() || this.currentAddressData.address_line);
    this.userFormData.applicant_city.setValue(secondaryDocument?.document_address?.city?.trim() || this.currentAddressData.city);
    this.userFormData.applicant_state.setValue(secondaryDocument?.document_address?.state?.trim() || this.currentAddressData.state);
    this.userFormData.applicant_postal_code.setValue(secondaryDocument?.document_address?.postal_code?.trim() || this.currentAddressData.postal_code);
    this.userFormData.applicant_country.setValue(secondaryDocument?.document_address?.country?.trim() || this.currentAddressData.country);
    this.typeBusiness = [Relation.COMPANY, Relation.TRUST].includes(selectedUser.relation?.relationType);
    this.typeResidential = !this.typeBusiness;
    this.userFormData.usage_type.setValue(this.typeBusiness ? 'business' : 'residential');

    if (this.typeBusiness) {
      const selectUserCompanyAddressResponse = await this.userDataService.getCompanyAddress(selectedUser.user.uuid);
      const selectUserCompanyAddress = selectUserCompanyAddressResponse['response'];
      if (selectUserCompanyAddress) {
        this.userFormData.company_name.setValue(selectedUser.user.name);
        this.userFormData.company_type_of_business.setValue(selectUserCompanyAddress.company_type_of_business);
        this.userFormData.company_address_line.setValue(
          `${selectUserCompanyAddress.address_line} ${selectUserCompanyAddress.address_line_2 || ''} ${selectUserCompanyAddress.address_line_3 || ''}`,
        );
        this.userFormData.company_city.setValue(selectUserCompanyAddress.city);
        this.userFormData.company_state.setValue(selectUserCompanyAddress.state);
        this.userFormData.company_postal_code.setValue(selectUserCompanyAddress.postal_code);
        this.userFormData.company_country.setValue(selectUserCompanyAddress.country);
        this.userFormData.company_phone_number.setValue(selectUserCompanyAddress.phone_number);
        this.userFormData.company_place_of_registration.setValue(selectUserCompanyAddress.company_place_of_registration);
      }
    } else if (selectedUser.relation?.relationType === Relation.DECEASED) {
      this.userFormData.company_name.setValue(selectedUser.user.name);
      this.userFormData.company_type_of_business.setValue('N/A');
      this.userFormData.company_address_line.setValue(secondaryDocument?.document_address?.address_line?.trim() || this.currentAddressData.address_line);
      this.userFormData.company_city.setValue(secondaryDocument?.document_address?.city?.trim() || this.currentAddressData.city);
      this.userFormData.company_state.setValue(secondaryDocument?.document_address?.state?.trim() || this.currentAddressData.state);
      this.userFormData.company_postal_code.setValue(secondaryDocument?.document_address?.postal_code?.trim() || this.currentAddressData.postal_code);
      this.userFormData.company_country.setValue(secondaryDocument?.document_address?.country?.trim() || this.currentAddressData.country);
      this.userFormData.company_phone_number.setValue(this.currentAddressData?.phone_number?.trim() || '');
      this.userFormData.company_place_of_registration.setValue('N/A');
    }

    SharedHelpers.detectChanges(this.cdRef);
  }

  getAccountNames() {
    this.verifyService
      .getName(this.apiMapping.getAccountNamesWithOnlyStatus())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(
        apiResponse => {
          if (apiResponse) {
            this.allAccountNames = apiResponse.data.accountNames;
            this.sortNamesByRelation(apiResponse.data.accountNames);
            const primaryUser = this.allAccountNames?.find(it => it.relation?.relationType === Relation.PRIMARY);
            this.primaryUserEmail = primaryUser?.user?.email;
            this.sortUserData();
            if (this.settingPageLoad && this.selectedId) {
              const index = this.userNamesData.findIndex(user => user.user.uuid === this.selectedId);
              if (index !== -1) {
                if (this.userNamesData[index].priority === 1) {
                  this.selectedId = this.allRelativeNames[0].user.uuid;
                  this.selectName(0);
                } else {
                  const i = this.allRelativeNames.findIndex(user => user.user.uuid === this.selectedId);
                  this.selectName(i);
                }
                SharedHelpers.detectChanges(this.cdRef);
              }
            }
          }
        },
        error => {
          this.setDefaultData();
        },
      );
  }

  sortUserData(currentIndex = 0) {
    if (this.userNamesData.length && this.userNamesData.length > 0) {
      this.currentNameIndex = currentIndex;
      this.setNames();
      this.getUserFormDetails();
    } else {
      this.setDefaultData();
    }
  }

  getFormTitleName() {
    let selectedUser = this.allRelativeNames[this.currentNameIndex];
    if ([Relation.COMPANY, Relation.TRUST, Relation.DECEASED].includes(selectedUser.relation.relationType)) {
      selectedUser = this.allRelativeNames.find(item => item.relation.relationType === Relation.PRIMARY || item.relation.relationType === Relation.USER);
    }
    return selectedUser;
  }

  setNames() {
    this.applicant_account_name_on_form = '';
    this.account_name_on_form = '';
    const selectedUser = this.allRelativeNames[this.currentNameIndex];
    this.applicant_account_name_on_form += this.getFormTitleName()?.user?.name;
    this.account_name_on_form += selectedUser?.usertitle;

    const names = [];
    this.userNamesData.filter(name => {
      if (name.priority === selectedUser.priority) {
        if (selectedUser.priority === 1 && name.user.id !== selectedUser.user.id) {
          names.push(name.user.name);
        }
      }
    });
    this.userFormData.additional_names_in_form.setValue(names.join('\n'));
    let documentsUserId = selectedUser.user.id;
    if ([Relation.COMPANY, Relation.TRUST, Relation.DECEASED].includes(selectedUser.relation?.relationType)) {
      documentsUserId = this.allRelativeNames.find(it => it.relation?.relationType === Relation.PRIMARY)?.user?.id;
    }
    const userDocuments = this.allDocuments.find(it => it.user_id === documentsUserId);
    const primaryDocument = userDocuments?.documents.find(it => it.user_type === UserType.PRIMARY);
    const secondaryDocument = userDocuments?.documents.find(it => it.user_type === UserType.SECONDARY);
    this.userFormData.document_primary_full_name.setValue(primaryDocument?.document_fields?.name?.trim() || '');
    this.userFormData.document_primary_id_number.setValue(primaryDocument?.document_fields?.id_number?.trim() || '');
    this.userFormData.document_primary_issuing_entity.setValue(primaryDocument?.document_fields?.issuing_entity?.trim() || '');
    this.userFormData.document_primary_expiration_date.setValue(primaryDocument?.document_fields?.expiration_date?.trim() || '');
    this.userFormData.document_primary_type.setValue(primaryDocument?.document_type);
    this.primaryDocumentType = primaryDocument?.document_type;
    this.userFormData.document_secondary_full_name.setValue(secondaryDocument?.document_address?.name?.trim() || '');
    this.userFormData.document_secondary_address_line.setValue(secondaryDocument?.document_address?.address_line?.trim() || '');
    this.userFormData.document_secondary_city.setValue(secondaryDocument?.document_address?.city?.trim() || '');
    this.userFormData.document_secondary_state.setValue(secondaryDocument?.document_address?.state?.trim() || '');
    this.userFormData.document_secondary_postal_code.setValue(secondaryDocument?.document_address?.postal_code?.trim() || '');
    this.userFormData.document_secondary_country.setValue(secondaryDocument?.document_address?.country?.trim() || '');
    this.userFormData.document_secondary_type.setValue(secondaryDocument?.document_type);
    this.secondaryDocumentType = secondaryDocument?.document_type;

    this.setCurrentAddress();
    SharedHelpers.detectChanges(this.cdRef);
  }

  async setDefaultData(mobileSubmit = false) {
    const selectedUser = this.allRelativeNames[this.currentNameIndex];
    this.userName = selectedUser.user.name;
    await this.getWarehouseAddress();
    await this.getMailingAddress(mobileSubmit);
    SharedHelpers.detectChanges(this.cdRef);
  }

  getUserFormDetails(mobileSubmit = false) {
    const selected = this.allRelativeNames[this.currentNameIndex];
    this.verifyService
      .getUserFormData(this.apiMapping.getUserOnboardForm(selected.user.uuid))
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        (apiResponse: OnboardFormResponse) => {
          if (apiResponse && apiResponse.form && apiResponse.form.form_1583_fields) {
            this.currentFormData = apiResponse.form.form_1583_fields;
            this.setFormDetails();
          } else {
            SharedHelpers.detectChanges(this.cdRef);
          }
          this.businessDataLoaded = true;
          this.setDefaultData(mobileSubmit);
          SharedHelpers.detectChanges(this.cdRef);
        },
        _error => {
          this.spinnerLoad = false;
          this.setDefaultData(mobileSubmit);
          SharedHelpers.detectChanges(this.cdRef);
        },
      );
  }

  setFormDetails() {
    // this.userFormData.form_notarize_date.setValue(this.currentFormData.form_notarize_date);
    // this.userFormData.business_phone_number.setValue(this.currentFormData.business_phone_number);
    // this.userFormData.business_type.setValue(this.currentFormData.business_type);
    // this.userFormData.applicant_firm_member_names.setValue(this.currentFormData.applicant_firm_member_names);
    // this.userFormData.corporation_officers_names_address.setValue(this.currentFormData.corporation_officers_names_address);
    // this.userFormData.corporation_business_name.setValue(this.currentFormData.corporation_business_name);
  }

  continueName() {
    if (this.currentNameIndex !== this.allRelativeNames.length - 1) {
      this.currentNameIndex = this.currentNameIndex + 1;
      this.setNames();
      this.getUserFormDetails();
    } else {
      this.notarizedPopup('normalSubmit');
    }
    SharedHelpers.detectChanges(this.cdRef);
  }

  sortNamesByRelation(names: any[]) {
    // names = names.filter(item => item.relation && item.relation.relationType && item.relation.relationType !== Relation.COMPANY);
    names.map(item => {
      const relation = item.relation.relationType || '';
      if (relation === Relation.PRIMARY || relation === Relation.USER) {
        item.priority = 1;
        item.secondaryPriority = 1;
        item.relation.relationType = Relation.PRIMARY;
      } else if (relation === Relation.MINOR_CHILD && item.is_from_account_verification) {
        item.priority = 1;
        item.secondaryPriority = 2;
      } else {
        item.priority = 2;
        item.secondaryPriority = 4;
      }
    });
    names.sort((a, b) => {
      return a.priority < b.priority ? -1 : a.priority > b.priority ? 1 : a.secondaryPriority < b.secondaryPriority ? -1 : a.secondaryPriority > b.secondaryPriority ? 1 : 0;
    });
    this.userNamesData = names;
    this.validateNames();
  }

  validateNames() {
    const allData = [];
    this.userNamesData.forEach(item => {
      item.title = item.user.name + ' (' + renderRelation(item.relation.relationType) + ')';
      item.usertitle = item.user.name;
      if (item.priority === 1) {
        if (allData.length > 0) {
          const index = allData.findIndex(user => user.priority === 1);
          if (index !== -1) {
            if (allData[index].title !== undefined && allData[index].title !== '') {
              allData[index].title += ', ' + item.user.name + ' (' + renderRelation(item.relation.relationType) + ')';
              allData[index].usertitle += ', ' + item.user.name;
            } else {
              allData[index].title = item.user.name + ' (' + renderRelation(item.relation.relationType) + ')';
              allData[index].usertitle = item.user.name;
            }
          } else {
            allData.push(item);
          }
        } else {
          allData.push(item);
        }
      } else {
        allData.push(item);
      }
    });
    if (this.allForm1583List.length > 0) {
      allData.filter((record, index) => {
        const form = this.allForm1583List.find(form1583 => form1583.user_id === record.user.uuid);
        if (form !== undefined && form.form1583 && form.form1583.form_1583_fields) {
          const status = form.form1583.status;
          if (status === RecordStatus.IN_REVIEW) {
            allData[index].status = AccountStatus.FORM_1583_ADDED;
          } else if (status === RecordStatus.APPROVED) {
            allData[index].status = AccountStatus.FORM_1583_APPROVED;
          } else if (status === RecordStatus.REJECTED) {
            allData[index].status = AccountStatus.FORM_1583_REJECTED;
            allData[index].notes = form.form1583.notes;
          }
        }
      });
    }
    this.allRelativeNames = allData;
  }

  getDocuments(token, captchaVersion) {
    this.verifyService
      .getName(this.apiMapping.getIDsDocumentsOfUser(undefined, token, captchaVersion))
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        async response => {
          if (response && response.documents) {
            this.allDocuments = response.documents;
            this.setNames();
          }
        },
        error => {
          if (error.status === 422) {
            this.verifyCaptcha(this.getDocuments.bind(this));
          }
        },
      );
  }

  selectName(index: number, isMobile = false) {
    this.userForm.reset();
    this.currentNameIndex = index;
    this.setNames();
    this.getUserFormDetails(isMobile);
    if (!isMobile) {
      this.scrollToTop();
    } else {
      this.spinnerLoad = true;
      this.continueBtnClick = false;
    }
    SharedHelpers.detectChanges(this.cdRef);
  }

  getAllForms() {
    const userId = this.userDataService.getUserId();
    this.verifyService
      .getUserAllForms(this.apiMapping.getUserAllForm1583(userId))
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(response => {
        if (response && response.form1583 && response.form1583.length > 0) {
          this.allForm1583List = response.form1583;
        }
      });
  }

  downloadForm1583(id: string, downloadOffline = false) {
    this.spinnerLoad = true;
    this.verifyService
      .getUserFormData(this.apiMapping.getUserOnboardForm(id))
      .pipe(takeUntil(this.unSubscriber), retry(2))
      .subscribe(
        (response: OnboardFormResponse) => {
          this.spinnerLoad = false;
          if (response && response.form && response.form.form_url) {
            if (downloadOffline) {
              this.downloadFormsFirstCall(this.downLoadForm.bind(this), response);
            } else {
              this.downloadFormsFirstCall(this.openFormPDF.bind(this), response.form.id);
            }
          }
          this.cdRef.detectChanges();
        },
        _error => {
          this.spinnerLoad = false;
          this.cdRef.detectChanges();
          this.notificationService.showError('Unable to view file.');
        },
      );
  }

  downLoadForm(token, captchaVersion, response) {
    this.http.get(this.apiMapping.downloadForms(response.form.id, token, captchaVersion), { responseType: 'blob' }).subscribe(
      result => {
        const fileName = response.form.form_url.split('/').pop().split('#')[0].split('?')[0];
        SharedHelpers.downloadFile(result, fileName);
        this.notificationService.showWarning('File downloaded.');
        SharedHelpers.detectChanges(this.cdRef);
      },
      error => {
        if (error.status === 422) {
          this.verifyCaptcha(this.downLoadForm.bind(this), response);
        } else {
          this.notificationService.showError('Unable to downloaded form.');
        }
      },
    );
  }

  openFormPDF(token, captchaVersion, id: number) {
    this.spinnerLoad = false;
    this.matDialog.open(PdfViewerDialogComponent, {
      maxWidth: 'calc(100vw - 10px)',
      panelClass: 'no-padding',
      data: { url: this.apiMapping.downloadForms(id, token, captchaVersion), fileType: `pdf`, v2CaptchaCallback: () => this.verifyCaptcha(this.openFormPDF.bind(this), id) },
    });
  }
}
