// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host virtual-scroller {
  height: 600px;
}
:host .selection-control {
  cursor: pointer;
}
:host tbody {
  border-top: 1px solid #e0e0e0;
}
:host td {
  vertical-align: top;
  padding: 10px 0;
}
:host .permanently-deleted-after {
  color: #329a32;
}
:host .tracking {
  color: #4886bb;
}`, "",{"version":3,"sources":["webpack://./src/app/discard/discard-table/discard-table.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,6BAAA;AADJ;AAIE;EACE,mBAAA;EACA,eAAA;AAFJ;AAKE;EACE,cAAA;AAHJ;AAME;EACE,cAAA;AAJJ","sourcesContent":[":host {\n  display: block;\n\n  virtual-scroller {\n    height: 600px;\n  }\n\n  .selection-control {\n    cursor: pointer;\n  }\n\n  tbody {\n    border-top: 1px solid #e0e0e0;\n  }\n\n  td {\n    vertical-align: top;\n    padding: 10px 0;\n  }\n\n  .permanently-deleted-after {\n    color: #329a32;\n  }\n\n  .tracking {\n    color: #4886bb;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
