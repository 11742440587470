import { Component, OnInit, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';
import * as Constants from '../../utils/constants';
import { UserDataService } from '../../services';
import { environment } from '../../../environments/environment';

declare var $;

@Component({
  selector: 'usgm-signup-header',
  templateUrl: './signup-header.component.html',
  styleUrls: ['signup-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupHeaderComponent implements OnInit {
  public imgSrc = Constants.USGM_LOGO;
  public homePageUrl = environment.usglobalMailWordPress;

  constructor(
    private ref: ElementRef,
    private location: Location,
    private userDataService: UserDataService,
  ) {}

  public ngOnInit() {
    if ($(window).width() < 998) {
      $(document).ready(function () {
        $('button#toggle-button').click(function () {
          $('.toggle-menu').parent().parent().toggleClass('toggle-menu-btn');
        });

        $('.menu-nav ul li span.down_arrow').each(function () {
          $(this).click(function () {
            $(this).parent().toggleClass('open');
          });
        });
      });
    }

    document.querySelectorAll('.header-dropdown').forEach(target => {
      target.addEventListener('mouseenter', this.mouseOver.bind(this), false);
    });
    document.querySelectorAll('.header-dropdown-container').forEach(target => {
      target.addEventListener('mouseleave', this.mouseOut.bind(this), false);
    });
  }

  mouseOver(event) {
    if ($(window).width() > 999) {
      const target = event.target.getAttribute('target');
      if (target) {
        if (document.getElementById(target)) {
          document.getElementById(target).style.display = 'flex';
        }
      }
    }
  }

  mouseOut(event) {
    if ($(window).width() > 999) {
      const target = event.target.getAttribute('target');
      if (target) {
        if (document.getElementById(target)) {
          document.getElementById(target).style.display = 'none';
        }
      }
    }
  }

  public backBtnClicked() {
    this.location.back();
  }

  public logout() {
    this.userDataService.logoutUser();
  }
}
