import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Subject } from 'rxjs';
import { takeUntil, retry } from 'rxjs/operators';
import * as Services from '../../services';
import { DocumentType, UserType, RecordStatus } from '@usgm/usgm-payloads-library-front';
import { VerifyAccountService } from '../verify-account.service';
import { CompanyDocumentType, Relation } from '../../models/get-notarized.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MatDialog } from '@angular/material/dialog';
import { CaptchaBaseComponent } from '../../captcha-base-component/captcha-base-component.component';
import { renderRelation } from '../../utils/helpers';

const DocumentInterfacePlaceHolder = {
  added_by: null,
  createdAt: null,
  deletedAt: null,
  document_type: null,
  document_url: null,
  notes: null,
  status: null,
  updatedAt: null,
  user_id: null,
  user_type: null,
  files: null,
};

@Component({
  selector: 'usgm-add-ids',
  templateUrl: './add-ids.component.html',
  styleUrls: ['./add-ids.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddIdsComponent extends CaptchaBaseComponent implements OnInit {
  protected readonly renderRelation = renderRelation;

  @Input() item;
  @Output() completeStep: EventEmitter<number> = new EventEmitter();
  public dialogRef: any;
  public currentUserName;
  public primaryDocumentType = [
    DocumentType.DRIVER_LICENSE,
    'UNIFORMED_SERVICE_ID' as DocumentType,
    'US_ACCESS_CARD' as DocumentType,
    'US_UNIVERSITY_ID_CARD' as DocumentType,
    DocumentType.PASSPORT,
    'MATRICULA_CONSULAR' as DocumentType,
    'NEXUS_CARD' as DocumentType,
    DocumentType.CERTIFICATE_OF_NATURALIZATION,
    'US_PERMANENT_RESIDENT_CARD' as DocumentType,
  ].map(item => item.split('_').join(' '));

  public companyDocumentType = Object.values(CompanyDocumentType).map(item => item.split('_').join(' '));
  public secondaryDocumentType = [
    DocumentType.DRIVER_LICENSE,
    DocumentType.CURRENT_LEASE,
    'MORTGAGE_OR_DEED_OF_TRUST' as DocumentType,
    'HOME_OR_VEHICLE_INSURANCE_POLICY' as DocumentType,
    DocumentType.VEHICLE_REGISTRATION_CARD,
    DocumentType.VOTER_CARD,
  ].map(item => item.split('_').join(' '));
  public idsData = [];
  public allAccountNames = [];

  private unSubscriber: Subject<any> = new Subject();

  public selectedId: string;
  public settingPageLoad: boolean;
  public settingUserIndex = 0;
  public loading = false;
  public companyTypeUser = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private stepper: MatStepper,
    protected verifyService: Services.VerifyAccountService,
    protected apiMapping: Services.ApiMapping,
    public override notificationService: Services.NotificationService,
    protected userDataService: Services.UserDataService,
    protected verifyAccountService: VerifyAccountService,
    public override dialog: MatDialog,
    public override recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
  }

  public override ngOnInit() {
    this.verifyAccountService.count.subscribe(c => {
      if (c > 0) {
        this.getAddedName(true);
      }
    });
    this.verifyAccountService.removeFrom1583ForCompanyTypeUser.subscribe(value => {
      this.companyTypeUser = value;
    });
    this.verifyService.settingsNameId.subscribe(value => {
      if (value) {
        this.settingPageLoad = value.isSettings;
        this.selectedId = value.id;
        this.getAddedName(true);
      } else {
        this.settingPageLoad = false;
        this.selectedId = null;
        this.getAddedName(true);
      }
      this.cdRef.detectChanges();
    });
  }

  public getAddedName(startFromBeginning): void {
    this.verifyService
      .getName(this.apiMapping.getAccountNamesWithOnlyStatus())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(
        apiResponse => {
          if (apiResponse) {
            const x = [];
            let count = 0;
            for (const user of apiResponse.data.accountNames) {
              x.push({
                userId: user.user.uuid,
                accountStatus: user.accountStatus,
                relation: user.relation.relationType,
                name: user.user.name,
                documents: [],
                idStatus: 'Pending',
                active: count === 0,
              });
              if (this.settingPageLoad && user.user.uuid === this.selectedId) {
                this.settingUserIndex = count;
                this.cdRef.detectChanges();
              }
              count++;
            }
            this.downloadIDsFirstCall(this.getDocumentsOfAllUser.bind(this), { userData: x, startFromBeginning: startFromBeginning });
          }
          //  If user comes from setting -> setting account name page
          if (this.settingPageLoad) {
            if (this.selectedId) {
              this.changeUserFromList(this.allAccountNames[this.settingUserIndex]);
            } else {
              this.currentUserName = null;
              this.scrollToTop();
            }
          }
        },
        error => {
          this.loading = false;
          this.cdRef.detectChanges();
        },
      );
  }

  public getDocumentsOfAllUser(token, captchaVersion, extraData: { userData: any; startFromBeginning: boolean }): void {
    this.verifyService
      .getName(this.apiMapping.getIDsDocumentsOfUser(undefined, token, captchaVersion))
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(
        apiResponse => {
          if (apiResponse) {
            const userData = extraData.userData;
            const startFromBegining = extraData.startFromBeginning;
            for (const user of userData) {
              const documents = (apiResponse.documents.find(item => user.userId === item.user_id) || {}).documents;
              user.documents = this.processDocuments(user, documents);
              const statusAndNotes = this.processDocumentStatus(user.relation, documents);
              user.idStatus = statusAndNotes.status;
              user.notes = statusAndNotes.notes;
            }
            this.idsData = userData?.filter(it => ![Relation.COMPANY, Relation.MINOR_CHILD, Relation.DECEASED, Relation.TRUST].includes(it.relation));
            this.allAccountNames = userData;
            if (startFromBegining) {
              if (this.settingUserIndex) {
                this.currentUserName = this.settingUserIndex;
              } else {
                this.currentUserName = 0;
              }
            }
            this.loading = false;
            this.cdRef.detectChanges();
          }
        },
        error => {
          this.loading = false;
          this.cdRef.detectChanges();
          if (error.status === 422) {
            this.verifyCaptcha(this.getDocumentsOfAllUser.bind(this), extraData);
          }
        },
      );
  }

  processDocumentStatus(relation, documents) {
    const docStatus = { status: 'Pending', notes: null };
    if (documents.length) {
      // tslint:disable-next-line: max-line-length
      docStatus.status =
        relation !== Relation.MINOR_CHILD && relation !== Relation.COMPANY
          ? documents.length === 2
            ? 'Complete'
            : 'ID Pending'
          : documents.length === 1
            ? 'Complete'
            : 'ID Pending';
      if (relation === Relation.MINOR_CHILD || relation === Relation.COMPANY) {
        if (documents[0].status) {
          docStatus.status = documents[0].status.split('_').join(' ');
          docStatus.notes = documents[0].notes;
        }
      } else {
        if (documents.length === 2) {
          if (documents[0].status === RecordStatus.REJECTED || (documents[1] && documents[1].status === RecordStatus.REJECTED)) {
            docStatus.status = RecordStatus.REJECTED.split('_').join(' ');
          } else if (documents[0].status === RecordStatus.IN_REVIEW || (documents[1] && documents[1].status === RecordStatus.IN_REVIEW)) {
            docStatus.status = RecordStatus.IN_REVIEW.split('_').join(' ');
          } else if (documents[0].status === RecordStatus.APPROVED && documents[1] && documents[1].status === RecordStatus.APPROVED) {
            docStatus.status = RecordStatus.APPROVED.split('_').join(' ');
          }
        }
        docStatus.notes = `${documents[0].notes ? documents[0].notes : ''}`;
        docStatus.notes += documents[0].notes && documents[1] && documents[1].notes ? `<br>` : '';
        docStatus.notes += documents[1] && documents[1].notes ? documents[1].notes : '';
      }
    }
    return docStatus;
  }

  idSubmitPopup(): void {
    this.completeStep.emit(2);
    this.currentUserName = 0;
    this.cdRef.detectChanges();
    if (this.settingPageLoad && this.companyTypeUser) {
      this.verifyAccountService.removeFrom1583ForCompanyTypeUserEvent(false);
      this.verifyAccountService.resetStepperIndexEvent(0);
      return;
    }
    setTimeout(() => {
      this.verifyAccountService.nextDocuments();
      if (this.settingPageLoad && this.selectedId) {
        this.verifyService.settingsNameId.next({
          isSettings: true,
          id: this.selectedId,
        });
      }
      this.stepper.next();
      this.scrollToTop();
    }, 300);
  }

  changeUserFromList(user) {
    const idData = this.idsData.find(it => it.userId === user?.userId);
    const index = this.idsData.indexOf(idData);
    const t = [...this.idsData].splice(0, index);
    if (t && t.length) {
      for (let x = 0; x < t.length; x++) {
        if (t[x].idStatus === RecordStatus.REJECTED) {
          if (!this.settingPageLoad) {
            this.notificationService.showError('Please reupload the rejected ids before proceeding to this user');
            break;
          }
        }
        if (x === t.length - 1) {
          this.currentUserName = index;
          this.loading = false;
          this.cdRef.detectChanges();
          this.scrollToTop();
        }
      }
    } else {
      this.currentUserName = index;
      this.loading = false;
      this.cdRef.detectChanges();
      this.scrollToTop();
    }
  }

  onIDSubmit(data): void {
    if (this.settingPageLoad && this.selectedId) {
      this.idSubmitPopup();
    } else {
      if (data.status) {
        this.getAddedName(false);
      }
      if (data.index < this.idsData.length - 1) {
        this.currentUserName = data.index + 1;
        this.cdRef.detectChanges();
        this.scrollToTop();
      } else {
        this.idSubmitPopup();
      }
    }
  }

  setLoader(event) {
    this.loading = event;
    this.cdRef.detectChanges();
  }

  resetData($event): void {
    this.getAddedName(false);
  }

  processDocuments(user, documents) {
    let returnArray = (user.relation === Relation.MINOR_CHILD || user.relation === Relation.COMPANY ? new Array(1) : new Array(2)).fill({ ...DocumentInterfacePlaceHolder });
    if (documents) {
      if (user.relation === Relation.MINOR_CHILD || user.relation === Relation.COMPANY) {
        returnArray = documents && documents.length ? documents : [{ ...DocumentInterfacePlaceHolder, user_type: UserType.PRIMARY }];
      } else {
        if (documents && !documents.length) {
          returnArray = [
            {
              ...DocumentInterfacePlaceHolder,
              user_type: UserType.PRIMARY,
            },
            {
              ...DocumentInterfacePlaceHolder,
              user_type: UserType.SECONDARY,
            },
          ];
        } else if (documents.length === 1) {
          returnArray =
            documents[0].user_type === UserType.SECONDARY
              ? [{ ...DocumentInterfacePlaceHolder, user_type: UserType.PRIMARY }, documents[0]]
              : [documents[0], { ...DocumentInterfacePlaceHolder, user_type: UserType.SECONDARY }];
        } else {
          for (const y of documents) {
            if (y.user_type === UserType.PRIMARY) {
              returnArray[0] = y;
            } else if (y.user_type === UserType.SECONDARY) {
              returnArray[1] = y;
            }
          }
        }
      }
    }
    return returnArray;
  }

  scrollToTop(): void {
    const stepperElement = document.getElementById('stepper');
    if (stepperElement) {
      stepperElement.scrollIntoView();
    }
  }
}
