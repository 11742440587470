import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Constants from '../utils/constants';
import { AppRoutes } from '../models/constants/app-routes.constant';

@Component({
  selector: 'usgm-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  public imgSrc = Constants.USGM_LOGO;

  constructor(private router: Router) {}

  public ngOnInit() {}

  public login() {
    this.router.navigate([`/${AppRoutes.login}`]);
  }

  public signup() {
    this.router.navigate([`/${AppRoutes.signup}`]);
  }
}
