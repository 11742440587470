import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as SharedHelpers from '../../utils/helpers';

@Component({
  selector: 'usgm-payment-details-popup',
  templateUrl: './payment-details-popup.component.html',
  styleUrls: ['./payment-details-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDetailsPopupComponent implements OnInit, OnDestroy {
  public loading = false;
  public declaredValue = 0;

  private _payment: any = {};

  get payment() {
    return this._payment;
  }

  @Input() set payment(input: any) {
    this._payment = input;
    SharedHelpers.detectChanges(this.cdr);
  }

  @Output() closeNav = new EventEmitter();

  constructor(protected cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  closeSideNav() {
    this.closeNav.emit({});
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
