import { Component, OnInit, EventEmitter, Input, Output, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AddressType, IAddressAttributes, MailType } from '@usgm/usgm-payloads-library-front';
import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { AddressService } from '../../services';
import * as Services from '../../services';
import * as SharedHelpers from '../../utils/helpers';
import { emptyGetShippersPostData, emptyParcelItem } from './get-shippers-post-data.model';
import { emptyAddressData, emptyDeclaration } from '../../models/address-model';
import { PICKUP_SERVICE_TYPE } from '../../utils/constants';
import * as moment from 'moment-timezone';
import { Country, State, City, ICountry, IState, ICity } from 'country-state-city';
import { DialogWithInputComponent } from '../../components/dialog-with-input';
import { MatDialog } from '@angular/material/dialog';
import { militaryBases } from '../../utils/constants';
import { AddressValidationResult } from '../../models/address-validation-result';
import { SuggestAddressDialogComponent } from '../suggest-address-dialog/suggest-address-dialog.component';
const USA_TERRITORIES = [
  'VI',
  'GU',
  'AS',
  'MP',
  'PR',
  'UM',
  'US-VI',
  'US_GU',
  'US-AS',
  'US-MP',
  'US-PR',
  'US-UM',
  'VIRGIN ISLANDS',
  'UNITED STATES VIRGIN ISLANDS',
  'GUAM',
  'AMERICAN SAMOA',
  'NORTHERN MARIANA ISLANDS',
  'PUERTO RICO',
  'UINTED STATES MINOR OUTLYING ISLANDS',
  'MINOR OUTLYING ISLANDS',
];

@Component({
  selector: 'usgm-ship-item',
  templateUrl: './ship-item.component.html',
  styleUrls: ['./ship-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true },
    },
  ],
})
export class ShipItemComponent implements OnInit, OnDestroy {
  public minDate = moment().tz('America/Chicago').toDate();
  public singleItemView = true;
  public loading = false;
  public makingDefaultAddress = false;
  public fetchingAddresses = false;
  public savingAddress = false;
  public countries: any;
  public states: any;
  public cities: any;
  public addressFormVisible = false;
  public editingAddressId = null;
  public loadingStates = false;
  public shippingRequestId = '';
  public showShippingSubmittedScreen = false;
  public shippingInfo: any = {};
  public destinationAddresses: IAddressAttributes[] = [];
  public addressForm: FormGroup;
  public selectedShipperOption: any;
  public shippingDeclarationRequired = false;
  public insureShipment = false;
  public insured_amount = 0;
  public shipmentDetails: any = {
    destinationAddress: JSON.parse(JSON.stringify(emptyAddressData)),
    requested_ship_date: moment().tz('America/Chicago').toDate(),
    is_new_items_included: false,
    is_open_and_consolidate_permitted: false,
    is_expedited: false,
    packing_instructions: '',
  };
  public shipperTabVisited = false;
  public shipperOptionError = false;
  public hasShippingDeclarationError = false;
  public hasCheckedShippingDeclaration = false;
  public canExpediteShipment = false;
  public addressIdToSetDefault = 0;
  public shipperOptions: any[] = [];
  public fetchingShippers = false;
  public addressCreated = false;
  public editShipmentMode = false;
  public combinationInvalid = false;
  public numAddressLinesVisible = 1;
  public weekendFilter = SharedHelpers.weekendFilter;
  public consolidation_charges = [];
  public consolidation_cost = 0;
  public signature_cost = 8;
  public packingDimensions: any = {};
  public planId;
  public isValidatingAddress = false;

  @ViewChild('shippingStepper') shippingStepper: MatStepper;

  private _items: any[] = [];

  get items(): any[] {
    return this._items;
  }

  public isShipmentIncludePackage = () => {
    return !!this._items.find(i => i.mail_type === MailType.PACKAGE);
  };

  @Input() set items(value: any[]) {
    this.initShippingDeclarations(value);
    this._items = value;
    this.singleItemView = this._items.length === 1;
  }

  @Input() editShipmentData: any;

  @Output() closeNav = new EventEmitter();
  @Output() updateInbox = new EventEmitter();

  constructor(
    public formBuilder: FormBuilder,
    public _addressService: AddressService,
    protected cdr: ChangeDetectorRef,
    protected apiMapping: Services.ApiMapping,
    protected userDataService: Services.UserDataService,
    protected notificationService: Services.NotificationService,
    protected http: Services.UsgmHttp,
    public dialog: MatDialog,
  ) {
    this.shipmentDetails.requested_ship_date = this.minDate = this.adjustDateAfter3PMAndOnWeekend(this.minDate);
  }

  adjustDateAfter3PMAndOnWeekend(date: Date) {
    if (moment(date).tz('America/Chicago').hour() >= 15) {
      date = moment(date).tz('America/Chicago').add(1, 'day').startOf('day').toDate(); // if past 3pm CST, default to next business day
    }

    if (moment(date).tz('America/Chicago').weekday() === 6) {
      date = moment(date).tz('America/Chicago').add(2, 'day').startOf('day').toDate();
    }
    if (moment(date).tz('America/Chicago').weekday() === 0) {
      date = moment(date).tz('America/Chicago').add(1, 'day').startOf('day').toDate();
    }
    const holidays = [
      [6, 4], // July 4
      [8, 2], // September 2 (Labour Day)
      [10, 28], // November 28 (Thanksgiving Day)
      [11, 25], // December 25 (Christmas Day)
      [0, 1], // January 1 (New Year Day)
    ];
    let warehouseDate = moment(date).tz('America/Chicago');
    while (holidays.find(it => warehouseDate.month() === it[0] && warehouseDate.date() === it[1])) {
      warehouseDate = warehouseDate.add(1, 'day');
      date = warehouseDate.startOf('day').toDate();
    }
    return date;
  }

  // Check if valid Declaraion exists
  hasItemShippingDecalarations() {
    // make array of valid declarations in items and see if at least one is valid
    return this._items.map(item => this.isValid(item)).includes(true);
  }

  isValid(item) {
    // if no declaraion or not an array no valid declaration
    if (!item.shipping_declarations || item.shipping_declarations.length === 0) {
      return false;
    } else {
      // make array of valid declarations in decalaraions and see if at least one is non empty
      return item.shipping_declarations.map(declaration => !!declaration.description).includes(true);
    }
  }

  ngOnInit() {
    this.initForm();
    if ((this.editShipmentData || {})['id']) {
      this.editShipmentMode = true;
      this._items = [];
      this.selectedShipperOption = this.editShipmentData.service;
      this.shipmentDetails.destination_address = this.editShipmentData.destination_address;
      this.shipmentDetails.is_new_items_included = this.editShipmentData.is_new_items_included;
      this.shipmentDetails.is_open_and_consolidate_permitted = this.editShipmentData.is_open_and_consolidate_permitted;
      this.shipmentDetails.is_expedited = this.editShipmentData.is_expedited;
      this.shipmentDetails.packing_instructions = this.editShipmentData.packing_instructions;
      // set mails
      this.editShipmentData.declaration_data.forEach(item => {
        if (item.mail_id) {
          this._items.push({
            id: item.mail_id,
            mail_type: item.mail_type,
            image_url: item.image_url,
            shipping_declarations: item.declarations,
            weight: item.dimensions.weight,
            measurement: {
              height: item.dimensions.height,
              length: item.dimensions.length,
              width: item.dimensions.width,
            },
          });
        }
      });
      this.singleItemView = this._items.length === 1;
    }
    this.getShippingInfo();
    this.getDestinationAddresses(true);
    this.getCountries();
    this.getSubscriptions();
    document.addEventListener('keydown', SharedHelpers.consumeEnterKeyEvent);
  }

  getSubscriptions() {
    this.userDataService.getUserSubscription().subscribe(response => {
      if (response && response.length) {
        const subscription = response.find(item => this.userDataService.isActiveSubscription(item));
        if (subscription) {
          this.planId = subscription.plan_id;
          this.getSelectedPlan(subscription.plan_id);
        }
      }
      SharedHelpers.detectChanges(this.cdr);
    });
  }

  getSelectedPlan(planId) {
    this.userDataService.getUserPlanById(planId).subscribe(response => {
      if (response && response.shipment) {
        this.consolidation_charges = response.shipment?.shipment_setting?.consolidation_charges || [];
      }
      SharedHelpers.detectChanges(this.cdr);
    });
  }

  closeSideNav() {
    this.closeNav.emit({});
  }

  selectCourier(option) {
    this.selectedShipperOption = option;
    if (this.selectedShipperOption?.service_type !== PICKUP_SERVICE_TYPE && this._items.length > 1) {
      this.shipmentDetails.is_open_and_consolidate_permitted = true;
      const isPackageIncluded = this._items.find(i => i.mail_type === MailType.PACKAGE || i.mail_type === MailType.SOFTPAK);
      if (isPackageIncluded) {
        this.consolidation_cost = this.consolidation_charges.find(i => i.mail_type === MailType.PACKAGE)?.amount || 0.0;
      } else {
        this.consolidation_cost = this.consolidation_charges.find(i => i.mail_type === MailType.LETTER)?.amount || 0.0;
      }
    } else {
      this.shipmentDetails.is_open_and_consolidate_permitted = false;
      this.consolidation_cost = 0.0;
    }
  }

  initForm() {
    this.addressForm = this.formBuilder.group({
      name: [null, Validators.required],
      address_line: [null, Validators.required],
      address_line_2: [null, []],
      address_line_3: [null, []],
      city: [null, Validators.required],
      country: [null, Validators.required],
      state: [null, Validators.required],
      postal_code: [null, [Validators.required]],
      phone_number: ['', [Validators.required, Validators.pattern(SharedHelpers.phoneNumberPattern)]],
      is_default: [false, []],
      user_id: [this.userDataService.getUserId(), []],
    });
  }

  public getShippingInfo() {
    this.http.get(this.apiMapping.getShippingInfo()).subscribe((data: any) => {
      this.shippingInfo = data.shipping_info;
    });
  }

  getDestinationAddresses(force: boolean = false) {
    return new Promise((resolve, reject) => {
      if (!force && this.userDataService.hasUserDestinationAddresses(AddressType.SHIPPING)) {
        this.handleUserDestinationAddresses(this.userDataService.getUserDestinationAddresses(AddressType.SHIPPING));
        return resolve(false);
      }
      this.fetchingAddresses = true;
      SharedHelpers.detectChanges(this.cdr);
      return this.http.get(this.apiMapping.getUserAddressesByType(this.userDataService.getUserId(), AddressType.SHIPPING)).subscribe(
        (response: any) => {
          this.userDataService.setUserDestinationAddresses(response, AddressType.SHIPPING);
          this.handleUserDestinationAddresses(this.userDataService.getUserDestinationAddresses(AddressType.SHIPPING));
          resolve(response);
        },
        error => {
          this.fetchingAddresses = false;
          SharedHelpers.detectChanges(this.cdr);
          return reject(error);
        },
      );
    });
  }

  setDefaultAddress(addressId: number) {
    this.makingDefaultAddress = true;
    this.addressIdToSetDefault = addressId;
    this.http.put(this.apiMapping.setDefaultUserAddress(this.userDataService.getUserId(), addressId), {}).subscribe(
      response => {
        this.destinationAddresses.forEach((address: IAddressAttributes) => {
          address.is_default = address.id === addressId;
        });
        this.addressIdToSetDefault = 0;
        this.makingDefaultAddress = false;
        this.userDataService.sortAddresses(this.destinationAddresses);
        this.userDataService.clearUserDestinationAddresses(AddressType.SHIPPING); // clear existing to update addresses
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.makingDefaultAddress = false;
        this.notificationService.showError('Unable to process request. Please try again');
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  async submitAddress() {
    this.validateAllFormFields(this.addressForm);
    if (!this.addressForm.valid) {
      return;
    }
    const postData = JSON.parse(JSON.stringify(this.addressForm.value));
    postData.id = this.editingAddressId;
    postData.country = postData.country.Id;
    const isValid = await this.checkIfAddressValid(postData as IAddressAttributes);
    if (!isValid) {
      return;
    }
    postData.address_type = AddressType.SHIPPING;
    this.savingAddress = true;
    SharedHelpers.detectChanges(this.cdr);
    let savePromise = null;
    postData.is_validated = true;
    try {
      if (!this.editingAddressId) {
        savePromise = this._addressService.addAddress(postData);
      } else {
        savePromise = this._addressService.updateAddress({
          id: this.editingAddressId,
          ...postData,
        });
      }
      const response = await savePromise;
      this.savingAddress = false;
      this.addressCreated = true;
      this.notificationService.showSuccess('Address created!');
      this.userDataService.clearUserDestinationAddresses(AddressType.SHIPPING); // clear existing to update addresses
      this.getDestinationAddresses(true);
      this.resetAddressForm();
      SharedHelpers.detectChanges(this.cdr);
    } catch (err) {
      this.savingAddress = false;
      this.notificationService.showError('Unable to process request. Please try again');
      SharedHelpers.detectChanges(this.cdr);
    }
  }

  async getDimensions(items) {
    const dimensionalItems = items.map(item => {
      return {
        length: item.measurement.length,
        width: item.measurement.width,
        height: item.measurement.height,
        weight: item.weight,
        type: item.mail_type,
      };
    });
    try {
      return await this.http.post(this.apiMapping.getDimensions(), dimensionalItems).toPromise();
    } catch (err) {
      throw new Error(err);
    }
  }

  async getShippersForItems() {
    this.shipperOptions = [];
    this.fetchingShippers = true;
    this.packingDimensions = await this.getDimensions(this.items);
    SharedHelpers.detectChanges(this.cdr);
    const postData = JSON.parse(JSON.stringify(emptyGetShippersPostData));
    const postDataParcel = postData.parcels[0];
    const destinationCountryCode = (this.shipmentDetails.destinationAddress.country || '').toLowerCase().indexOf('in') !== -1 ? 'IND' : 'USA';
    this._items.forEach(item => {
      item.shipping_declarations = item.shipping_declarations || [];
      if (!item.shipping_declarations.length) {
        const parcelItem = JSON.parse(JSON.stringify(emptyParcelItem));
        parcelItem.description = '0 Item';
        parcelItem.quantity = 1;
        parcelItem.price.amount = 0.1;
        parcelItem.origin_country = destinationCountryCode;
        parcelItem.weight.value = parseFloat(item.weight + '');
        parcelItem.mail_type = item.mail_type;
        postDataParcel.items.push(parcelItem);
        return;
      }
      item.shipping_declarations.forEach(declaration => {
        const parcelItem = JSON.parse(JSON.stringify(emptyParcelItem));
        parcelItem.description = declaration.description || '0 Item';
        parcelItem.quantity = declaration.quantity || 1;
        parcelItem.price.amount = declaration.item_value || 0.1;
        parcelItem.origin_country = destinationCountryCode;
        parcelItem.weight.value = parseFloat(item.weight + '') / (item.shipping_declarations.length * parcelItem.quantity);
        parcelItem.mail_type = item.mail_type;
        postDataParcel.items.push(parcelItem);
      });
      if (item.shipment_info && item.shipment_info.declarations) {
        item.shipping_declarations = item.shipment_info.declarations;
      }
    });
    postDataParcel.weight.value = parseFloat(this.packingDimensions.weight + '');
    postDataParcel.dimension.height = parseFloat(this.packingDimensions.height + '');
    postDataParcel.dimension.width = parseFloat(this.packingDimensions.width + '');
    postDataParcel.dimension.depth = parseFloat(this.packingDimensions.length + '');
    postDataParcel.description = this._items.length + ' items';
    postData.ship_to = {
      contact_name: this.shipmentDetails.destinationAddress.name,
      phone: this.shipmentDetails.destinationAddress.phone_number,
      email: this.userDataService.getUserEmail(),
      street1: this.shipmentDetails.destinationAddress.address_line,
      city: this.shipmentDetails.destinationAddress.city,
      postal_code: this.shipmentDetails.destinationAddress.postal_code,
      state: this.shipmentDetails.destinationAddress.state,
      country: this.shipmentDetails.destinationAddress.country,
      type: 'residential',
    };
    if (this.shipmentDetails.destinationAddress.address_line_2) {
      postData.ship_to['street2'] = this.shipmentDetails.destinationAddress.address_line_2;
    }
    if (this.shipmentDetails.destinationAddress.address_line_3) {
      postData.ship_to['street3'] = this.shipmentDetails.destinationAddress.address_line_3;
    }
    let insuredAmount = 0;
    if (this.insureShipment) {
      if (this.shippingDeclarationRequired) {
        this._items.forEach(item => {
          item.shipping_declarations.forEach(declaration => {
            insuredAmount += declaration.item_value * declaration.quantity;
          });
        });
      } else {
        insuredAmount = this.insured_amount;
      }
    }
    postData.insured_amount = insuredAmount;
    postData.plan_id = this.planId;
    this.http.post(this.apiMapping.getShippersForItems(), postData).subscribe(
      response => {
        this.setupShippersData(response);
        this.fetchingShippers = false;
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.fetchingShippers = false;
        SharedHelpers.detectChanges(this.cdr);
        this.notificationService.showError('Error fetching shippers: ' + error.error.message);
      },
    );
  }

  setupShippersData(response) {
    this.shipperOptions = (response['data'] || {})['rates'] || [];
    const mailTypes = this.items.map(item => item.mail_type);
    this.shipperOptions.forEach((shipperOption, index) => {
      shipperOption['id'] = index;
      shipperOption['service_name'] = SharedHelpers.underscoreToSentenceCase(shipperOption['service_name'] || shipperOption['service_type']);
      if (isNaN(new Date(shipperOption['delivery_date']).getTime())) {
        shipperOption['delivery_date'] = null;
      }
      shipperOption[`trackable`] = SharedHelpers.checkIfTrackable(this.shipmentDetails.destinationAddress.country, mailTypes, shipperOption.service_type);
      if (this.editShipmentMode) {
        if (this.editShipmentData.service.service_type === shipperOption.service_type && this.editShipmentData.service.name === shipperOption.service_name) {
          this.selectedShipperOption = shipperOption;
        }
      }
    });

    if (this.shipperOptions.length === 0 && this.destinationAddresses.length !== 0) {
      setTimeout(() => {
        this.notificationService.showError('No shippers found for current package and destination!');
      }, 100);
    }
  }

  submitShipping() {
    if (!this.shipmentDetails.destinationAddress.address_line) {
      this.shippingStepper.selectedIndex = 0;
      this.notificationService.showError('Please select a shipping address');
      return;
    }
    if (this.shippingDeclarationRequired) {
      if (!this.shippingItemsDeclared()) {
        this.notificationService.showError('Please declare items in your shipping request');
        return;
      }
    }
    if (!this.selectedShipperOption) {
      this.shippingStepper.selectedIndex = !this.shippingDeclarationRequired ? 1 : 2;
      this.notificationService.showError('Please select a shipper');
      return;
    }
    this.loading = true;
    (this.editShipmentMode
      ? this.http.put(this.apiMapping.editShippingRequest(this.editShipmentData.id), this.submitShippingRequestPostData())
      : this.http.post(this.apiMapping.submitShippingRequest(), this.submitShippingRequestPostData())
    ).subscribe(
      response => {
        this.loading = false;
        if (response['shipment_id']) {
          this.shippingRequestId = response['shipment_id'];
          this.showShippingSubmittedScreen = true;
          if (!this.editShipmentMode) {
            this.updateInbox.emit();
          }
        } else {
          this.notificationService.showError('Shipment request already submitted');
        }
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.loading = false;
        this.notificationService.showError(error.error?.message || 'Unable to process request. Please try again');
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  public shippingItemsDeclared(): boolean {
    let shippingDeclared = true;
    this._items.forEach(item => {
      shippingDeclared = shippingDeclared && ((item.shipping_declarations || []).length !== 0 || !this.itemShippingDeclarationApplicable(item));
      if (this.itemShippingDeclarationApplicable(item)) {
        item.shipping_declarations.forEach(declaration => {
          if (!declaration.description || !declaration.item_value || !declaration.quantity) {
            shippingDeclared = false;
          }
        });
      }
    });
    return shippingDeclared;
  }

  submitShippingRequestPostData() {
    let requestedShipmentDate: string | Date = moment(this.shipmentDetails.requested_ship_date).tz('America/Chicago').toDate();
    if (!moment(this.shipmentDetails.requested_ship_date).tz('America/Chicago').isSame(moment().tz('America/Chicago'), 'date')) {
      requestedShipmentDate = moment(this.shipmentDetails.requested_ship_date).tz('America/Chicago').startOf('date').toDate();
    }
    requestedShipmentDate = this.adjustDateAfter3PMAndOnWeekend(requestedShipmentDate).toISOString();

    const baseRate = (this.selectedShipperOption.detailed_charges || []).find(charge => charge.type === 'base');
    if (this.shipmentDetails.is_expedited) {
      this.shipmentDetails[`expedite_cost`] = this.shippingInfo.expedite_price;
    }
    this.shipmentDetails[`consolidation_cost`] = this.shipmentDetails.is_open_and_consolidate_permitted ? this.consolidation_cost : 0;
    this.shipmentDetails[`signatureId`] = this.shipmentDetails.signatureRequired ? 2 : 1;
    const postData = {
      shipment_category_type: this.selectedShipperOption.service_type === PICKUP_SERVICE_TYPE ? 'CUSTOMER_PICKUP_REQUEST' : 'SHIPMENT_REQUEST',
      destination_address: {
        name: this.shipmentDetails.destinationAddress.name,
        address_line: this.shipmentDetails.destinationAddress.address_line,
        city: this.shipmentDetails.destinationAddress.city,
        country: this.shipmentDetails.destinationAddress.country,
        state: this.shipmentDetails.destinationAddress.state,
        postal_code: this.shipmentDetails.destinationAddress.postal_code,
        phone_number: this.shipmentDetails.destinationAddress.phone_number,
      },
      destination_address_id: this.shipmentDetails.destinationAddress.id,
      items: [],
      shipper: {
        name: this.selectedShipperOption.service_name,
        service_type: this.selectedShipperOption.service_type,
        slug: this.selectedShipperOption.shipper_account.slug,
        is_ship_today: this.selectedShipperOption.shipToday,
        is_trackable: this.selectedShipperOption.trackable,
        eta: this.selectedShipperOption.delivery_date,
        retail_rate: (this.selectedShipperOption.total_charge || {}).amount,
        our_rate: (this.selectedShipperOption.discounted_charge || {}).amount,
        base_rate: baseRate.charge.amount,
      },
      shipment_detail: JSON.parse(JSON.stringify({ ...this.shipmentDetails, requested_ship_date: requestedShipmentDate })),
      insured_amount: 0,
    };
    if (this.shipmentDetails.destinationAddress.address_line_2) {
      postData.destination_address['address_line_2'] = this.shipmentDetails.destinationAddress.address_line_2;
    }
    if (this.shipmentDetails.destinationAddress.address_line_3) {
      postData.destination_address['address_line_3'] = this.shipmentDetails.destinationAddress.address_line_3;
    }
    delete postData.shipment_detail['destinationAddress'];
    this._items.forEach(item => {
      postData.items.push({ mail_id: item.id, declarations: item.shipping_declarations });
    });
    let insuredAmount = 0;
    if (this.insureShipment) {
      if (this.shippingDeclarationRequired) {
        this._items.forEach(item => {
          item.shipping_declarations.forEach(declaration => {
            insuredAmount += declaration.item_value * declaration.quantity;
          });
        });
      } else {
        insuredAmount = this.insured_amount;
      }
    }
    postData.insured_amount = insuredAmount;
    return postData;
  }

  getRates() {
    if (this.insured_amount) {
      this.getShippersForItems();
    }
  }

  insuranceNotNeeded() {
    this.selectedShipperOption = null;
    if (!this.insureShipment) {
      this.getShippersForItems();
    } else {
      this.shipperOptions = [];
    }
  }

  stepChanged($event: StepperSelectionEvent) {
    // previously selected step checks
    if ($event.previouslySelectedStep.label === 'Declare Item') {
      this.checkForShippingDeclarationErrors();
    } else if ($event.previouslySelectedStep.label === 'Select Shipper') {
      this.shipperOptionError = !(this.selectedShipperOption || {}).service_name;
    }
    // current selected step checks
    if ($event.selectedStep.label === 'Select Shipper') {
      if (this.shippingDeclarationRequired && this.checkIfAllItemsAreNotLetters(this.items)) {
        this.getShippersForItems();
      } else if (!this.shippingDeclarationRequired && this.checkIfAllItemsAreNotLetters(this.items)) {
        this.insureShipment = false;
        this.shipperOptions = [];
        this.selectedShipperOption = null;
        this.getShippersForItems();
      } else {
        this.insureShipment = false;
        this.getShippersForItems();
      }
    } else if ($event.selectedStep.label === 'Shipment Details') {
      this.checkIfCanExpediteShipment();
      this.selectCourier(this.selectedShipperOption);
    } else if ($event.selectedStep.label === 'Declare Item') {
      this.checkForShippingDeclarationErrors();
      this.hasCheckedShippingDeclaration = true;
    }
  }

  checkForShippingDeclarationErrors() {
    this.hasShippingDeclarationError = this.shippingDeclarationRequired && !this.shippingItemsDeclared();
  }

  async onAddressScreenNextClick(): Promise<void> {
    if (this.isNextDisabled()) {
      return;
    }
    const is_validated = this.shipmentDetails.destinationAddress?.is_validated;
    if (is_validated) {
      this.shippingStepper.next();
      return;
    }
    const result = await this.checkIfAddressValid(this.shipmentDetails.destinationAddress);
    if (result) {
      if (this.shipmentDetails.destinationAddress.id && !this.shipmentDetails.destinationAddress.is_validated) {
        this.shipmentDetails.destinationAddress.is_validated = true;
        this._addressService.updateAddress(this.shipmentDetails.destinationAddress);
      }
      this.shippingStepper.next();
    }
  }

  async checkIfAddressValid(addressAttributes: IAddressAttributes): Promise<boolean> {
    const postData = JSON.parse(JSON.stringify(addressAttributes));
    this.isValidatingAddress = true;
    try {
      const validationResults = await this._addressService.validateAddress(postData);
      this.isValidatingAddress = false;
      this.cdr.detectChanges();
      if (validationResults.suggestedAddresses.length || !validationResults.addressComplete) {
        this.openSuggestAddressDialog(validationResults);
        return false;
      }
    } catch (err) {
      console.log(err);
      this.isValidatingAddress = false;
      this.notificationService.showError('Error while validating the address.');
    }
    return true;
  }

  public openSuggestAddressDialog(validationResult: AddressValidationResult) {
    const dialogRef = this.dialog.open(SuggestAddressDialogComponent, {
      width: '600px',
      data: { validationResult },
    });
    dialogRef.afterClosed().subscribe(async ({ addressSaved, wantEdit, address, providedAddressId } = { addressSaved: false, wantEdit: false }) => {
      await this.getDestinationAddresses(true);
      this.onPopupClose(addressSaved, wantEdit, address, providedAddressId);
    });
  }

  onPopupClose(saved: boolean, wantEdit: boolean, address: IAddressAttributes, providedAddressId: number): void {
    if (wantEdit) {
      this.addressFormVisible = true;
      this.addressForm.reset();
      this.editingAddressId = providedAddressId;
      const foundCity = this.cities.find(city => city.name === address.city || city.isoCode === address.city);
      if (!foundCity) {
        this.cities.push({
          isoCode: address.city,
          name: address.city,
        });
      }
      this.addressForm.patchValue({
        ...address,
        country: { Id: address.country },
      });
      SharedHelpers.detectChanges(this.cdr);
      return;
    }
    if (!saved) {
      return;
    }
    this.shipmentDetails.destinationAddress = address;
    this.resetAddressForm();
    this.shippingStepper.next();
    SharedHelpers.detectChanges(this.cdr);
  }

  checkDeclarationsAndMoveToNextStep() {
    this.checkForShippingDeclarationErrors();
    SharedHelpers.detectChanges(this.cdr);
    if (!this.hasShippingDeclarationError) {
      this.shippingStepper.next();
    } else {
      this.notificationService.showError('Please add declarations for all items');
    }
  }

  removeDeclarationForItem(item, index) {
    item.shipping_declarations.splice(index, 1);
  }

  checkIfCanExpediteShipment() {
    if (!this.userDataService.warehouseConfiguration?.enableExpediteShipping) {
      this.canExpediteShipment = false;
      return;
    }
    const currentHoustonDateTime = moment().tz('America/Chicago');
    if (moment(this.shipmentDetails.requested_ship_date).isSame(currentHoustonDateTime, 'date')) {
      this.shipmentDetails.requested_ship_date = currentHoustonDateTime.add(5, 'minutes').toDate();
    }
    if (moment(this.shipmentDetails.requested_ship_date).tz('America/Chicago').isSame(currentHoustonDateTime, 'date')) {
      if (currentHoustonDateTime.hour() >= 13 && currentHoustonDateTime.hour() <= 15) {
        this.canExpediteShipment = true;
      }
    }
  }

  handleUserDestinationAddresses(addresses: any[]) {
    let defaultAddress = (addresses || []).find(a => a.is_default) || addresses[0];
    if (this.addressCreated) {
      const addressesCopy = JSON.parse(JSON.stringify(addresses));
      defaultAddress = addressesCopy.sort((a, b) => b.id - a.id)[0];
      this.addressCreated = false;
    }
    this.destinationAddresses = addresses;
    if (this.editShipmentMode) {
      const editShipmentAddress = this.editShipmentData.destination_address;
      addresses.some(address => {
        if (
          editShipmentAddress.address_line === address.address_line &&
          (editShipmentAddress.address_line_2 || '') === (address.address_line_2 || '') &&
          (editShipmentAddress.address_line_3 || '') === (address.address_line_3 || '') &&
          editShipmentAddress.city === address.city &&
          editShipmentAddress.country === address.country &&
          editShipmentAddress.phone_number === address.phone_number &&
          editShipmentAddress.state === address.state &&
          editShipmentAddress.postal_code === address.postal_code &&
          editShipmentAddress.name === address.name
        ) {
          defaultAddress = address;
          return true;
        }
        return false;
      });
    }
    this.addressClicked(defaultAddress || JSON.parse(JSON.stringify(emptyAddressData)));
    this.fetchingAddresses = false;
    SharedHelpers.detectChanges(this.cdr);
  }

  addShippingDeclarationForItem(item) {
    item.shipping_declarations.push(JSON.parse(JSON.stringify(emptyDeclaration)));
    this.resetShipperSelection();
    this.checkForShippingDeclarationErrors();
  }

  addressClicked(address: IAddressAttributes) {
    this.shipmentDetails.destinationAddress = address;
    this.shippingDeclarationRequired = false;
    (this.items || []).forEach(item => {
      if (this.itemShippingDeclarationApplicable(item)) {
        this.shippingDeclarationRequired = true;
      }
    });
    this.shippingDeclarationRequired = this.shippingDeclarationRequired && this.checkIfShipmentIsNotBoundForUSMilitaryBase(address);
    this.initShippingDeclarations(this._items);
    this.resetShipperSelection();
    SharedHelpers.detectChanges(this.cdr);
  }

  isAddressSelected() {
    if (!this.shipmentDetails.destinationAddress?.address_line && this.shipmentDetails.destinationAddress?.id === 1) {
      return false;
    }
    return true;
  }

  isNextDisabled() {
    if (!this.isAddressSelected()) {
      return true;
    }
    return this.isValidatingAddress;
  }

  checkIfShipmentIsNotBoundForUSMilitaryBase(address: IAddressAttributes) {
    if ([`APO`, `DPO`, `FPO`].map(i => i.toLowerCase()).includes(address?.city?.trim()?.toLowerCase())) {
      return true;
    }
    if (address.country === 'United States' || address.country === 'US' || address.country === 'USA') {
      return USA_TERRITORIES.map(i => i.toLowerCase()).includes(address?.state?.trim()?.toLowerCase());
    } else {
      return true;
    }
  }

  itemShippingDeclarationApplicable(item): boolean {
    return ![MailType.LETTER, MailType.MAGAZINE, MailType.LARGELETTER].includes(item.mail_type);
  }

  resetShipperSelection() {
    if (this.selectedShipperOption) {
      this.selectedShipperOption = null;
      this.shipperOptionError = true;
    }
  }

  public getCountries() {
    this.countries = Country.getAllCountries().map((country: ICountry) => ({ Id: country.isoCode, Name: country.name }));
    this.addressForm.patchValue({ country: { Id: 'US', Name: 'United States' } });
    this.countrySelected();
  }

  public getStatesByCountryId(id: string) {
    this.states = State.getStatesOfCountry(id);
    if (id === 'US' || id === 'USA') {
      this.states = [...this.states, ...militaryBases];
    }
  }

  isFieldInvalid(field: string) {
    return !this.addressForm.get(field).valid && this.addressForm.get(field).touched;
  }

  countrySelected(): void {
    this.states = [];
    this.cities = [];
    this.addressForm.patchValue({ state: '' });
    this.addressForm.get('city').reset();
    this.addressForm.get('postal_code').reset();
    const selectedCountry = this.addressForm.get('country').value;
    if (selectedCountry) {
      const Id = selectedCountry.Id;
      if (Id) {
        this.getStatesByCountryId(Id);
      }
    }
  }

  public stateSelected(country: { Id: string; Name: string }, stateCode: string) {
    if (stateCode === 'add_new_option') {
      const dialogRefToAddState = this.dialog.open(DialogWithInputComponent, {
        data: {
          title: 'Add a new State',
          cancelText: 'Cancel',
          confirmText: 'Add',
          addEvent: 'ADD_NEW_STATE',
        },
      });

      dialogRefToAddState?.afterClosed().subscribe(result => {
        if (result.event === 'ADD_NEW_STATE') {
          this.states.push({
            isoCode: result.data,
            name: result.data,
          });
          this.addressForm.patchValue({
            state: result.data,
          });
          this.addressForm.patchValue({
            city: '',
          });
          this.cities = [];
          this.addressForm.get('postal_code').reset();
          SharedHelpers.detectChanges(this.cdr);
        }
      });
    } else {
      this.cities = City.getCitiesOfState(country.Id, stateCode);
    }
  }

  public citySelected() {
    const selectedCity = this.addressForm.controls['city'].value;
    if (selectedCity && selectedCity === 'add_new_option') {
      const dialogRefToAddCity = this.dialog.open(DialogWithInputComponent, {
        data: {
          title: 'Add a new city',
          cancelText: 'Cancel',
          confirmText: 'Add',
          addEvent: 'ADD_NEW_CITY',
        },
      });

      dialogRefToAddCity?.afterClosed().subscribe(result => {
        if (result.event === 'ADD_NEW_CITY') {
          this.cities.push({
            isoCode: result.data,
            name: result.data,
          });
          this.addressForm.patchValue({
            city: result.data,
          });
          SharedHelpers.detectChanges(this.cdr);
        }
      });
    }
  }

  public getDeliveryDate(shipperOption) {
    if (!shipperOption.deliveryBusinessDays) {
      return '';
    }
    const dayStr = shipperOption.deliveryBusinessDays.toString().trim() === '1' ? 'day' : 'days';
    return `${shipperOption.deliveryBusinessDays} ${dayStr}`;
  }

  public compareByID(o1: any, o2: any) {
    return (o1 || {}).Id === (o2 || {}).Id;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldInvalid(field),
      'has-feedback': this.isFieldInvalid(field),
    };
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  deleteAddressLine() {
    this.addressForm.patchValue({ address_line_3: '' });
    this.numAddressLinesVisible = this.numAddressLinesVisible - 1;
    if (this.numAddressLinesVisible <= 1) {
      this.addressForm.patchValue({ address_line_2: '' });
    }
  }

  resetAddressForm() {
    this.addressForm.reset();
    this.addressForm.patchValue({ is_default: false, user_id: this.userDataService.getUserId() });
    this.addressFormVisible = false;
    this.editingAddressId = null;
  }

  initShippingDeclarations(items: any[]) {
    items.forEach(item => {
      if (!item.shipping_declarations) {
        item.shipping_declaration = JSON.parse(JSON.stringify(emptyDeclaration));
        item.shipping_declarations = (this.editShipmentData || {})['id'] ? item.shipping_declarations : [JSON.parse(JSON.stringify(emptyDeclaration))];
      }
    });
  }

  checkIfAllItemsAreNotLetters(items: any[]): boolean {
    const item = items.filter(im => im.mail_type !== 'LETTER');
    return !!item.length;
  }

  public get isPickupRequest(): boolean {
    return this.selectedShipperOption?.service_type === PICKUP_SERVICE_TYPE;
  }

  ngOnDestroy(): void {
    this.cdr.detach();
    document.removeEventListener('keydown', SharedHelpers.consumeEnterKeyEvent);
  }

  onAddNewAddressClicked() {
    this.addressFormVisible = !this.addressFormVisible;
    this.editingAddressId = null;
  }
}
