import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import * as SharedHelpers from '../../utils/helpers';
import { UserDataService } from '../../services';
import * as Services from '../../services';

@Component({
  selector: 'usgm-change-warehouse',
  templateUrl: './change-warehouse.component.html',
  styleUrls: ['./change-warehouse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeWarehouseComponent implements OnInit, OnDestroy {
  @ViewChild('changeWarehouseStepper') changeWarehouseStepper: MatStepper;
  @Output() closeNav = new EventEmitter();

  public changeWarehousePayload: any = {};
  public currentWarehouse;
  public boxNumber;
  public hasActiveScanBundle: boolean = false;

  constructor(
    protected cdr: ChangeDetectorRef,
    public userDataService: UserDataService,
    private apiMapping: Services.ApiMapping,
    private http: Services.UsgmHttp,
  ) {}

  ngOnInit() {
    this.getBoxNumber();
    this.getUserScanBundles();
  }

  closeSideNav() {
    this.closeNav.emit({});
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  async getBoxNumber() {
    await this.userDataService.getBoxNumber();
    this.boxNumber = this.userDataService.getWarehouseBoxNumber();
    SharedHelpers.detectChanges(this.cdr);
  }

  private async getUserScanBundles() {
    const userScanBundle: any = await this.http.get(this.apiMapping.getUserScanBundle()).toPromise();
    if (userScanBundle?.results?.find(it => it.subscription_status === 'ACTIVE')) {
      this.hasActiveScanBundle = true;
      SharedHelpers.detectChanges(this.cdr);
    }
  }

  onWarehouseSelected(warehouse) {
    this.changeWarehousePayload.warehouse = warehouse;
    setTimeout(() => {
      this.changeWarehouseStepper.next();
    }, 0);
  }

  onWarehousesLoaded(eventData) {
    this.currentWarehouse = eventData.current;
  }

  onPlanSelected(planEvent) {
    this.changeWarehousePayload.plan = planEvent.plan;
    this.changeWarehousePayload.term = planEvent.term;
    setTimeout(() => {
      console.log(this.changeWarehousePayload);
      this.changeWarehouseStepper.next();
    }, 0);
  }

  onConfirmationComplete() {
    this.changeWarehouseStepper.reset();
    this.changeWarehousePayload = {};
    this.closeSideNav();
  }
}
