import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { UsgmHttp } from './usgm-http.service';
import { ApiMapping } from './api-mapping.service';

declare var UA;

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  private webUaSDK;
  private userId = '';
  private userEmail = '';
  private userFullName = '';
  private userDeviceData = {};
  private deviceDataKey = 'PUSH_DEVICE_DATA';
  private deviceFamily = 'web';
  private setupPushDone = false;

  constructor(
    private platform: Platform,
    private router: Router,
    private http: UsgmHttp,
    private apiMapping: ApiMapping,
  ) {}

  public setupPushNotifications() {
    this.initUserDeviceData();
    this.userDeviceData['created_at'] = this.userDeviceData['created_at'] || new Date();
    this.userDeviceData['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.userDeviceData['timezone_offset'] = -(new Date().getTimezoneOffset() / 60);
    this.userDeviceData['is_active'] = true;
    this.userDeviceData['platforms'] = this.platform.platforms();
    this.userDeviceData['user_agent'] = navigator.userAgent;
    this.saveUserDeviceData();
    // if (this.platform.is('cordova')) {
    //   this.deviceFamily = this.platform.is('ios') ? 'ios' : 'android';
    // } else if (typeof UA !== 'undefined') {
    this.deviceFamily = 'web';
    // }
  }

  public setupUserData(userId: string, userEmail: string, userFullName: string) {
    if (this.setupPushDone) {
      return;
    }
    this.userId = userId;
    this.userEmail = userEmail || '';
    this.userFullName = userFullName || '';
    const tags = this.getTags();
    // if (this.platform.is('cordova')) {
    //   // this.setupWebPushUser(this.userId, tags); // UA web startup completed before usgm login
    // }
    this.setupPushDone = true;
  }

  public handleDeepLink(deepLink) {
    const deepLinkParts = deepLink.split('?');
    const mainNav = deepLinkParts[0];
    const itemId = (deepLinkParts[1] || '').split('=')[1] || '';
    switch (mainNav) {
      case 'inbox':
        this.router.navigateByUrl(`inbox?inboxId=${itemId}`);
        break;
      case 'quarantine':
        this.router.navigateByUrl(`inbox?quarantineId=${itemId}`);
        break;
      case 'shipment/processed':
        this.router.navigateByUrl(`shipments?id=${itemId}&type=processed`);
        break;
      case 'shipment/error':
        this.router.navigateByUrl(`shipments?id=${itemId}&type=error`);
        break;
      default:
        break;
    }
  }

  public handleDeviceRegistration(event, isWeb) {
    // send channelID and registrationToken to usgm-notifications backend
    if (isWeb) {
      console.log('Web registration');
      this.userDeviceData['channel_id'] = event['channelID'];
    } else {
      console.log('Mobile registration', event['registrationToken']);
      this.userDeviceData['channel_id'] = event['channelID'];
      this.userDeviceData['device_token'] = event['registrationToken'];
    }
    this.saveUserDeviceData();
  }

  public setupMobilePushUser(userId, tags) {
    tags.push('mobile');
    tags = tags.filter((item, pos) => tags.indexOf(item) === pos);
    this.saveUserDeviceData();
  }

  private setupWebPushUser(userId, tags) {
    tags.push('web');
    tags = tags.filter((item, pos) => tags.indexOf(item) === pos);
  }

  private getTags() {
    const tags = JSON.parse(JSON.stringify(this.platform.platforms())) || [];
    if (this.userEmail.length < 128) {
      tags.push(this.userEmail);
    }
    if (this.userFullName.length < 128) {
      tags.push(this.userFullName);
    }
    tags.push(`user-${this.userId}`);
    return tags;
  }

  private initUserDeviceData() {
    this.userDeviceData = JSON.parse(localStorage.getItem(this.deviceDataKey) || '{}');
  }

  private saveUserDeviceData() {
    localStorage.setItem(this.deviceDataKey, JSON.stringify(this.userDeviceData));
  }
}
