import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as Services from '../../services';
import { AppRoutes } from '../../models/constants/app-routes.constant';
import * as SharedHelpers from '../../utils/helpers';

@Component({
  selector: 'usgm-select-warehouse',
  templateUrl: './select-warehouse.component.html',
  styleUrls: ['./select-warehouse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectWarehouseComponent implements OnInit, OnDestroy {
  public loading = true;
  public warehouses: any[] = [];
  public dropdownOpen: Boolean = false;
  public selectedWarehouse: any = {};

  constructor(
    private cdr: ChangeDetectorRef,
    public apiMapping: Services.ApiMapping,
    public http: Services.UsgmHttp,
    public userDataService: Services.UserDataService,
    public notificationService: Services.NotificationService,
    private router: Router,
  ) {}

  public ngOnInit() {
    this.userDataService.getWarehousesByState('').then(
      (warehouses: any) => {
        this.warehouses = warehouses.results || [];
        this.loading = false;
        SharedHelpers.detectChanges(this.cdr);
      },
      (error: any) => {
        console.log(error);
        this.notificationService.showError('Unable to load warehouse options. Please reload this page and try again');
      },
    );
  }

  public warehouseConfirmClicked() {
    this.loading = true;
    this.http.http_put(this.apiMapping.assignWarehouse(), { warehouse_id: this.selectedWarehouse.id }).then(
      (data: any) => {
        this.http.http_post(this.apiMapping.refreshSession(), {}).then(
          async userData => {
            await this.userDataService.setUserSession(userData);
            this.router.navigateByUrl(`${AppRoutes.paymentInfo}`);
            this.loading = false;
            SharedHelpers.detectChanges(this.cdr);
          },
          err => {
            this.router.navigateByUrl(`${AppRoutes.paymentInfo}`);
            this.loading = false;
            SharedHelpers.detectChanges(this.cdr);
          },
        );
      },
      (error: any) => {
        console.log(error);
        this.notificationService.showError('Unable to select warehouse. Please reload this page and try again');
      },
    );
  }

  public warehouseSelected(warehouse: any) {
    this.selectedWarehouse = warehouse || {};
    this.dropdownOpen = false;
    SharedHelpers.detectChanges(this.cdr);
  }

  public capitalizeFirstLetter(value: string) {
    if (!value) {
      return '-- select --';
    }
    return value.substr(0, 1).toUpperCase() + value.substr(1).toLowerCase();
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
