import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';
import { AccountStatus } from '@usgm/usgm-payloads-library-front';
import { PdfViewerDialogComponent } from '../../components/pdf-viewer-dialog';
import * as Services from '../../services';
import * as SharedHelpers from '../../utils/helpers';

@Component({
  selector: 'usgm-scans-list-table',
  templateUrl: './scans-list-table.component.html',
  styleUrls: ['./scans-list-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScansListTableComponent implements OnInit {
  private _numHeaders = 6;
  @Input() bulkSelectEnabled: boolean = false;
  @Input() scans: any[] = [];
  @Input() allSelected: boolean = false;
  @Input() tableType = '';
  @Input() rowHeight = 40;
  @Output() scanDetailClicked = new EventEmitter();
  @Output() fetchMoreCalled = new EventEmitter();
  @Output() contactSupportClicked = new EventEmitter();
  @Output() scansDeleted: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() toggleSelectionChanged = new EventEmitter();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected http: Services.UsgmHttp,
    protected dialog: MatDialog,
    protected apiMapping: Services.ApiMapping,
    protected notificationService: Services.NotificationService,
    protected userDataService: Services.UserDataService,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.fixHeadersWidth();
  }

  public ngOnInit() {
    this.fixHeadersWidth();
  }

  public fixHeadersWidth() {
    setTimeout(() => {
      for (let i = 1; i <= this._numHeaders; i++) {
        const element = document.querySelectorAll('usgm-scans-list-table .header-' + i)[0];
        if (element) {
          element.removeAttribute('width');
        }
      }
    }, 10);
    setTimeout(() => {
      for (let i = 1; i <= this._numHeaders; i++) {
        const element = document.querySelectorAll('usgm-scans-list-table .header-' + i)[0];
        if (element) {
          element.setAttribute('width', Math.min(element.clientWidth, 350) + 'px');
        }
      }
    }, 100);
  }

  public downloadPdf(scan: any) {
    this.http.get(this.apiMapping.downloadScan(scan.id), { responseType: 'blob', observe: 'response' }).subscribe(
      result => {
        const contentType = result.headers.get('content-type');
        SharedHelpers.downloadFile(result.body, `${scan.request_type_without_text} #${scan.id}.${contentType.split('/')[1]}`, contentType);
        this.notificationService.showWarning('File downloaded.');
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.notificationService.showError('Unable to download file. Please try again.');
      },
    );
  }

  public deleteScan(scan: any) {
    this.scansDeleted.emit(scan);
  }

  public viewPdf(scan: any) {
    this.dialog.open(PdfViewerDialogComponent, {
      maxWidth: 'calc(100vw - 10px)',
      panelClass: 'no-padding',
      data: { url: this.apiMapping.viewScan(scan.id), fileType: scan.file_extension },
    });
  }

  public scanClicked(scan: any) {
    this.scanDetailClicked.emit(scan);
  }

  public contactSupport(scan: any) {
    scan.contactSupportEnabled = true;
    this.contactSupportClicked.emit(scan);
  }

  public trackItems(index, item) {
    return item.id;
  }

  public fetchMore(event: IPageInfo) {
    if (!this.scans) {
      return;
    }
    if (event.endIndex !== this.scans.length - 1) {
      return;
    }
    this.fetchMoreCalled.emit(true);
  }

  public detectChanges() {
    SharedHelpers.detectChanges(this.cdr);
  }

  public allActionsDisabled() {
    return this.userDataService.getAccountStatus() === AccountStatus.SUSPENDED;
  }

  public toggleItemSelection($event: MouseEvent, scan: any) {
    $event.stopPropagation();

    scan.selected = !scan.selected;
    if (!scan.selected && this.allSelected) {
      this.allSelected = false;
    }
    this.toggleSelectionChanged.emit(scan);
  }
  public identify(index, item) {
    return item.id;
  }
}
