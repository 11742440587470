import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Services from '../../../services';
import { Animations } from '../../../animations/element.animations';
import * as SharedHelpers from '../../../utils/helpers';
import { AccountStatus } from '@usgm/usgm-payloads-library-front/Enums';
import { EnterDeclarationsBaseComponent } from '../../../components/enter-declarations/enter-declarations-base/enter-declarations-base.component';
import { MailFolderEvent } from '../../../models/mail-folder-event';
import { PdfViewerDialogComponent } from '../../../components/pdf-viewer-dialog';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '../../../components/image-dialog';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SettingNavViewOptions } from '../../../models/constants/settings-options.constant';

@Component({
  selector: 'usgm-item-details-popup',
  templateUrl: './item-details-popup.component.html',
  styleUrls: ['./item-details-popup.component.scss'],
  animations: Animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemDetailsPopupComponent implements OnInit, OnDestroy {
  private unSubscriber: Subject<any> = new Subject();
  private folders = [];
  public folderId = null;
  public hideDeclarations = false;
  public issueDetails = {};
  public downloading = false;
  public loading = false;
  public userData: any = {};
  public notes = '';
  public lastNote = null;
  public submittingNotes = false;
  public firstTimeNotesLoading = false;
  public notesLength = 0;
  private _item: any = {};
  get item(): any {
    return this._item;
  }

  @ViewChild(EnterDeclarationsBaseComponent)
  declarationsbase: EnterDeclarationsBaseComponent;

  @Input() set item(value) {
    this._item = value || {};
    if (this._item.id) {
      this.getNotesForMailItem();
    }
    if (this._item.is_issue_reported) {
      this.fetchIssueDetails();
    }
    if (this._item.scan_info?.scan_id) {
      this._item.scan_info.scan_id = this._item.scan_uuid;
    }
  }

  @Output() closeNav = new EventEmitter();
  @Output() closeNavAndReloadItems = new EventEmitter();
  @Output() scanItemClicked = new EventEmitter();
  @Output() shipItemClicked = new EventEmitter();
  @Output() returnItemClicked = new EventEmitter();
  @Output() unboxClicked = new EventEmitter();
  @Output() discardItemClicked = new EventEmitter();
  @Output() depositCheckClicked = new EventEmitter();
  @Output() reportIssueClicked = new EventEmitter();
  @Output() moveItem = new EventEmitter();
  @Output() addAndMoveItem = new EventEmitter();
  @Output() viewMailNotes = new EventEmitter();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected http: Services.UsgmHttp,
    protected apiMapping: Services.ApiMapping,
    protected userDataService: Services.UserDataService,
    protected notificationService: Services.NotificationService,
    protected router: Router,
    protected dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.userData = this.userDataService.getDecodedAccessToken(this.userDataService.getAccessToken()) || {};
    this.activatedRoute.queryParams.subscribe(params => {
      this.folderId = null;
      if (params['folderId']) {
        this.folderId = parseInt(params['folderId'], 10);
      }
      SharedHelpers.detectChanges(this.cdr);
    });
    this.hideDeclarations = !this.item || !this.item.shipping_declarations || !this.item.shipping_declarations.length || !this.item.shipping_declarations[0].description;
    this.loadFolders();
    this.userDataService.foldersUpdate.subscribe(folders => {
      this.folders = folders;
    });
  }

  async loadFolders() {
    this.folders = await this.userDataService.getUserFolders(false);
  }

  fetchIssueDetails() {
    this.http.get(this.apiMapping.getInboxIssueDetail(this.item.issue_info.issue_id)).subscribe((data: any) => {
      this.issueDetails = data.issue;
      SharedHelpers.setPropertiesForIssue(this.issueDetails);
      SharedHelpers.detectChanges(this.cdr);
    });
  }

  closeSideNavAndReloadScans() {
    this.closeNavAndReloadItems.emit({});
  }

  closeSideNav() {
    this.closeNav.emit({});
  }

  getAllButCurrentFolder() {
    return this.folders.filter(folder => folder.id !== this.folderId);
  }

  moveItemsToFolder($event: MailFolderEvent) {
    this.item.selected = true;
    this.moveItem.emit($event);
  }

  onAddFolderAndMove($event: MailFolderEvent) {
    this.addAndMoveItem.emit(Event);
  }

  onScanItemClick() {
    this.item.selected = true;
    this.scanItemClicked.emit(this.item);
  }

  onShipItemClick() {
    this.item.selected = true;
    this.shipItemClicked.emit(this.item);
  }

  shouldButtonBeDisabled(item): boolean {
    return SharedHelpers.actionsDisabledForItem(item);
  }

  onReturnItemClick() {
    this.item.selected = true;
    this.returnItemClicked.emit(this.item);
  }

  onUnboxClick() {
    this.item.selected = true;
    this.unboxClicked.emit(this.item);
  }

  viewMailNotesClick() {
    this.item.selected = true;
    this.viewMailNotes.emit(this.item.id);
  }

  onDepositCheckClick() {
    this.item.selected = true;
    this.depositCheckClicked.emit(this.item);
  }

  onReportIssueClick() {
    this.item.selected = true;
    this.reportIssueClicked.emit(this.item);
  }

  onDiscardItemClick() {
    this.item.selected = true;
    this.discardItemClicked.emit(this.item);
  }

  onAddNameItemClick() {
    this.router.navigate(['/settings'], { queryParams: { page: SettingNavViewOptions.MANAGE_ACCOUNT_NAMES } });
  }

  allActionsDisabled() {
    return this.userDataService.getAccountStatus() === AccountStatus.SUSPENDED;
  }

  downloadPdf(scanInfo) {
    this.downloading = true;
    const extension = this.getExtension(scanInfo);
    this.http.get(this.apiMapping.downloadScan(scanInfo.scan_id), { responseType: 'blob' }).subscribe(
      result => {
        SharedHelpers.downloadFile(result, `${scanInfo.category_type} #${scanInfo.scan_id}.${extension}`);
        this.downloading = false;
        this.notificationService.showWarning('File downloaded.');
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.downloading = false;
        this.notificationService.showError('Unable to download file. Please try again.');
      },
    );
  }

  viewPdf(scanInfo) {
    const extension = this.getExtension(scanInfo);
    this.dialog.open(PdfViewerDialogComponent, {
      maxWidth: 'calc(100vw - 10px)',
      panelClass: 'no-padding',
      data: { url: this.apiMapping.viewScan(scanInfo.scan_id), fileType: extension },
    });
  }

  private getExtension(scanInfo) {
    return scanInfo.category_type === 'UNBOXING_REQUEST' ? 'jpg' : 'pdf';
  }

  public checkIfOlderThanSixMonth(date: string) {
    return !(moment().diff(moment(date, 'DD/MM/YYYY hh:mm a'), 'months', true) >= 6);
  }

  ngOnDestroy() {
    this.declarationsbase?.save();
    this.cdr.detach();
    this.unSubscriber.next({});
    this.unSubscriber.complete();
  }

  openImageFullScreen(src) {
    this.dialog.open(ImageDialogComponent, {
      minWidth: 'calc(100vw - 10px)',
      minHeight: 'calc(100vh - 10px)',
      data: { imgSrc: src },
      panelClass: 'post-dialog-container',
    });
  }

  private getNotesForMailItem() {
    this.submittingNotes = true;
    this.cdr.detectChanges();
    this.http
      .get(this.apiMapping.GetMailNotesByMailId(this._item.id))
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        response => {
          const submittingNotes = (response['data'] || []).map(i => ({ ...i, created_on: moment(i.created_on).format('MMM DD YYYY hh:mm A') }));
          this.notesLength = submittingNotes.length;
          this.lastNote = submittingNotes.length ? submittingNotes[0] : null;
          this.submittingNotes = false;
          this.firstTimeNotesLoading = true;
          this.cdr.detectChanges();
        },
        error => {
          this.submittingNotes = false;
          this.cdr.detectChanges();
          this.notificationService.showError(error?.error?.message || error?.message);
        },
      );
  }

  public submitNotes() {
    this.submittingNotes = true;
    this.cdr.detectChanges();
    this.http
      .post(this.apiMapping.AddMailNotes(this._item.id), {
        note: this.notes,
      })
      .subscribe(
        response => {
          this.notificationService.showSuccess(response['message']);
          this.notes = '';
          this.getNotesForMailItem();
          this.cdr.detectChanges();
        },
        error => {
          this.submittingNotes = false;
          this.cdr.detectChanges();
          this.notificationService.showError(error?.error?.message || error?.message);
        },
      );
  }
}
