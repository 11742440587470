import { Routes } from '@angular/router';
import { PaymentCompleteComponent, PaymentInfoComponent, SignupComponent, SelectPlanComponent } from './register';
import { InboxComponent } from './inbox';
import { ScansListComponent } from './scans-list';
import { ShipmentsComponent } from './shipments';
import { WelcomeComponent } from './welcome';
import { BillingHistoryComponent } from './billing-history';
import { PaymentPageComponent } from './payment-page';
import { VerifyAccountComponent } from './verify-account';
import { NewPasswordComponent, ResetPasswordComponent, ResetPasswordConfirmationComponent } from './reset-password';
import { SettingsPageComponent } from './settings-page';
import { DiscardComponent } from './discard';
import { RouteGuardService } from './services';
import { SignUpRouteGuardService } from './services/signup-route-guard.service';
import { AppRoutes } from './models/constants/app-routes.constant';
import { RouteVerifyAccountDoneService } from './services/route-verify-account-done.service';
import { SelectWarehouseComponent } from './register/select-warehouse/select-warehouse.component';
import { NewPasswordConfirmationComponent } from './reset-password/new-password-confirmation/new-password-confirmation.component';
import { EmailUnsubscribeComponent } from './email-unsubscribe/email-unsubscribe.component';
import { SelectWarehouseByAddressComponent } from './register';
import { AcceptOrgInviteComponent } from './accept-org-invite';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { ResolveFormErrorComponent } from './resolve-form-error';

export const ROUTES: Routes = [
  { path: '', redirectTo: 'inbox', pathMatch: 'full' },
  { path: AppRoutes.signup, component: SignupComponent, canActivate: [SignUpRouteGuardService] },
  {
    path: AppRoutes.selectPlan,
    component: SelectPlanComponent,
  },
  {
    path: AppRoutes.selectWarehouseByAddress,
    component: SelectWarehouseByAddressComponent,
    canActivate: [SignUpRouteGuardService],
  },
  {
    path: AppRoutes.selectWarehouse,
    component: SelectWarehouseComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: AppRoutes.paymentInfo,
    component: PaymentInfoComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: AppRoutes.paymentComplete,
    component: PaymentCompleteComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: AppRoutes.login,
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  { path: AppRoutes.resetPassword, component: ResetPasswordComponent },
  { path: AppRoutes.resetPasswordConfirmation, component: ResetPasswordConfirmationComponent },
  { path: AppRoutes.newPassword, component: NewPasswordComponent },
  { path: AppRoutes.acceptOrgInvite, component: AcceptOrgInviteComponent },
  { path: AppRoutes.newPasswordConfirmation, component: NewPasswordConfirmationComponent },
  {
    path: AppRoutes.inbox,
    component: InboxComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: AppRoutes.scans,
    component: ScansListComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: AppRoutes.shipments,
    component: ShipmentsComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: AppRoutes.welcome,
    component: WelcomeComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: AppRoutes.verifyAccount,
    component: VerifyAccountComponent,
    canActivate: [RouteGuardService, RouteVerifyAccountDoneService],
  },
  {
    path: AppRoutes.billingHistory,
    component: BillingHistoryComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: AppRoutes.payments,
    component: PaymentPageComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: AppRoutes.discards,
    component: DiscardComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: AppRoutes.settings,
    component: SettingsPageComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: AppRoutes.submitVerificationDocuments,
    component: ResolveFormErrorComponent,
    canActivate: [RouteGuardService],
  },
  {
    path: AppRoutes.unsubscribe,
    component: EmailUnsubscribeComponent,
    canActivate: [],
  },
  {
    path: AppRoutes.maintenance,
    component: MaintenanceComponent,
    canActivate: [],
  },
  { path: '**', redirectTo: '' },
];
