import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'usgm-child-user-id-identification',
  templateUrl: './child-user-id-identification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildUserIdIdentificationComponent implements OnInit {
  @Input() data: any;
  @Input() documentType: any[];
  @Output() continueEvent: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  public ngOnInit() {}

  public onContinue(): void {
    this.continueEvent.emit(true);
  }
}
