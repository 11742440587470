import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Animations } from '../../animations/element.animations';
import * as Services from '../../services';

@Component({
  selector: 'usgm-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss'],
  animations: Animations,
})
export class SelectOptionComponent implements OnInit {
  private currentPlanId = 0;
  public optionData: any = {
    page_title: null,
    page_sub_title: null,
    version: 1,
    releaseDate: null,
    page_features_html: [],
  };
  public loading = false;

  constructor(
    private http: Services.UsgmHttp,
    private apiMapping: Services.ApiMapping,
    private notificationService: Services.NotificationService,
    private registerDataService: Services.RegistrationDataService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public ngOnInit() {
    this.currentPlanId = this.route.snapshot.params['planId'];
    if (this.registerDataService.getOptionsForId(this.currentPlanId)) {
      this.optionData = this.registerDataService.getOptionsForId(this.currentPlanId);
    } else {
      this.getPlanOptions();
    }
  }

  public getPlanOptions() {
    this.loading = true;
    this.http.http_get(this.apiMapping.planOptions(this.currentPlanId)).then(
      (data: any) => {
        for (let i = 0; i < (data['planOptions'] || []).length; i++) {
          const planOption = data['planOptions'][i];
          if (planOption['plan_option_features']) {
            data['plan_option_features'] = planOption['plan_option_features'];
            delete planOption['plan_option_features'];
            break;
          }
        }
        this.optionData = data;
        this.registerDataService.setOptionsForId(this.currentPlanId, this.optionData);
        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
        this.notificationService.showError('Unable to fetch options. Please try again.');
      },
    );
  }

  public optionButtonClicked(option: any) {
    this.router.navigate(['/register/select-term', option.id]);
    this.registerDataService.updateUserPlanSelections('option', option.id);
  }
}
