// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .main-container {
  padding: 5px 15px;
  max-width: 600px;
}
:host .nav-bar {
  align-items: center;
}
:host .status {
  line-height: 1;
  width: 100px;
}
:host .download-btn {
  width: 100px;
  padding: 5px 10px;
  text-transform: uppercase;
  border-radius: 4px;
  border: 1px solid #acacac;
  font-size: 80%;
  min-width: 80px;
  display: inline-block;
  text-align: center;
  line-height: 1;
}
:host .empty-placeholder {
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/scans-exports-list/scans-exports-list.component.scss"],"names":[],"mappings":"AAEE;EACE,iBAAA;EACA,gBAAA;AADJ;AAGE;EACE,mBAAA;AADJ;AAGE;EACE,cAAA;EACA,YAAA;AADJ;AAGE;EACE,YAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;EACA,qBAAA;EACA,kBAAA;EACA,cAAA;AADJ;AAGE;EACE,gBAAA;AADJ","sourcesContent":["@import '../../../styles/variables';\n:host {\n  .main-container {\n    padding: 5px 15px;\n    max-width: 600px;\n  }\n  .nav-bar {\n    align-items: center;\n  }\n  .status {\n    line-height: 1;\n    width: 100px;\n  }\n  .download-btn {\n    width: 100px;\n    padding: 5px 10px;\n    text-transform: uppercase;\n    border-radius: 4px;\n    border: 1px solid #acacac;\n    font-size: 80%;\n    min-width: 80px;\n    display: inline-block;\n    text-align: center;\n    line-height: 1;\n  }\n  .empty-placeholder {\n    font-weight: 500;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
