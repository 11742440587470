import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../services';

@Component({
  selector: 'usgm-form-error-requests-notification',
  templateUrl: './form-error-requests-notification.component.html',
})
export class FormErrorRequestsNotificationComponent implements OnInit {
  public showNotification = false;

  constructor(private userDataService: UserDataService) {}

  ngOnInit() {
    this.update();
    this.userDataService.requestedFormErrorsFetched.subscribe(data => this.update());
  }

  private update() {
    this.showNotification = !!this.userDataService.getRequestedFormErrors()?.length || !!this.userDataService.getFormsWaitingToSign()?.length;
  }
}
