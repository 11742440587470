// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  min-height: 100vh;
}
:host .region-container {
  max-width: 450px;
}
:host .dropdown-toggle {
  padding: 10px 10px 10px 40px;
  height: 60px;
  border: 2px solid #e8f0f2;
  border-radius: 5px;
  background: #f5f9fa;
  color: black;
}
:host .region-card {
  max-width: 500px;
}
:host .pricing {
  right: 0px;
  position: absolute;
  margin-right: 20px;
}
:host .pricing.selected {
  font-size: 85%;
  margin-right: 10px;
  bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/register/select-region/select-region.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,4BAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,UAAA;EACA,kBAAA;EACA,kBAAA;AAFJ;AAII;EACE,cAAA;EACA,kBAAA;EACA,WAAA;AAFN","sourcesContent":[":host {\n  display: block;\n  min-height: 100vh;\n\n  .region-container {\n    max-width: 450px;\n  }\n\n  .dropdown-toggle {\n    padding: 10px 10px 10px 40px;\n    height: 60px;\n    border: 2px solid #e8f0f2;\n    border-radius: 5px;\n    background: #f5f9fa;\n    color: black;\n  }\n\n  .region-card {\n    max-width: 500px;\n  }\n\n  .pricing {\n    right: 0px;\n    position: absolute;\n    margin-right: 20px;\n\n    &.selected {\n      font-size: 85%;\n      margin-right: 10px;\n      bottom: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
