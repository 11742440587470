import { AppRoutes } from './app-routes.constant';

export const RESOLVE_FORM_ERRORS_PAGE_NAME = 'Action Needed';

export const MenuItems = [
  {
    name: RESOLVE_FORM_ERRORS_PAGE_NAME,
    icon: 'submit-verification-documents',
    id: 'submit-verification-documents',
    path: `/${AppRoutes.submitVerificationDocuments}`,
    count: 0,
    excludeForSubAccounts: true,
  },
  {
    name: 'Verify Account',
    icon: 'verify-account',
    id: 'verify-account',
    path: `/${AppRoutes.welcome}`,
    count: 0,
    excludeForSubAccounts: true,
  },
  {
    name: 'MailBox',
    icon: 'inbox',
    id: 'mailbox',
    path: `/${AppRoutes.inbox}`,
    children: [],
    count: 0,
  },
  {
    name: 'Scans',
    icon: 'scans',
    id: 'scans',
    path: `/${AppRoutes.scans}`,
    children: [],
    count: 0,
  },
  {
    name: 'Shipments',
    icon: 'shipments',
    id: 'shipments',
    path: `/${AppRoutes.shipments}`,
    count: 0,
  },
  {
    name: 'Billing History',
    icon: 'payment',
    id: 'billing-history',
    path: `/${AppRoutes.billingHistory}`,
    count: 0,
    excludeForSubAccounts: true,
  },
  {
    name: 'Discarded',
    icon: 'discard',
    id: 'trash',
    path: `/${AppRoutes.discards}`,
    count: 0,
  },
  {
    name: 'Settings',
    icon: 'settings',
    id: 'settings',
    path: `/${AppRoutes.settings}`,
    count: 0,
    showNewLabel: true,
  },
];

export const MenuItemStoragePrefix = 'menu_item_count_';
