import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'usgm-multiple-scan-dialog',
  templateUrl: './multiple-scan-dialog.component.html',
  styleUrls: ['./multiple-scan-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleScanDialogComponent {
  message: string;
  scanText: string;
  unboxText: string;
  imageUrl: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message || '';
    this.scanText = data.scanText;
    this.unboxText = data.unboxText;
    this.imageUrl = data.imageUrl || '';
  }
}
