import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalIDs' })
export class CapitalizeIDsPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    return value
      .replace(/ Id$/g, ' ID')
      .replace(/^Us /, 'US ')
      .replace(/^Driver License$/, 'US Driver License');
  }
}
