import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog';
import * as Services from '../../services';
import * as SharedHelpers from '../../utils/helpers';

@Component({
  selector: 'usgm-issue-row',
  templateUrl: './issue-row.component.html',
  styleUrls: ['./issue-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueRowComponent implements OnInit, OnDestroy {
  public updatingIssueStatusId = 0;
  public postingMessageId = 0;

  @Input() item: any = {};
  @Input() issueType = 'inbox';
  @Input() resolveDisabled = false;
  @Output() issueUpdated = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
    private http: Services.UsgmHttp,
    private apiMapping: Services.ApiMapping,
    private notificationService: Services.NotificationService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.item.showAllPosts = ['scan', 'shipment'].includes(this.issueType);
  }

  public newPostForIssue() {
    const comment = document.getElementById(`post_issue_${this.item.id}`)['value'];
    if ((comment || '').length < 2) {
      this.notificationService.showError('Please type at least 2 characters');
      return;
    }
    this.postingMessageId = this.item.id;
    let postUrl = this.apiMapping.updateInboxIssue(this.item.id);
    switch (this.issueType) {
      case 'scan':
        postUrl = this.apiMapping.updateScanIssue(this.item.id);
        break;
      case 'shipment':
        postUrl = this.apiMapping.updateShipmentIssue(this.item.id);
        break;
    }
    this.http.post(postUrl, { comment }).subscribe(
      (data: any) => {
        this.postingMessageId = -1;
        document.getElementById(`post_issue_${this.item.id}`)['value'] = '';
        this.getAndUpdateIssueDetails();
      },
      (error: any) => {
        this.postingMessageId = -1;
        if (error.status !== 401 && error.status !== 403) {
          this.notificationService.showError('Unable to post message. Please try again.');
        }
      },
    );
  }

  public getAndUpdateIssueDetails() {
    let getUrl = this.apiMapping.getInboxIssueDetail(this.item.id);
    switch (this.issueType) {
      case 'scan':
        getUrl = this.apiMapping.getScanDetails(this.item.id);
        break;
      case 'shipment':
        getUrl = this.apiMapping.getShipmentDetails(this.item.id);
        break;
    }
    this.http.get(getUrl).subscribe((data: any) => {
      const issueData = data.issue || data.scan || data;
      this.item.posts = issueData.posts || issueData.issues;
      this.item.is_resolved = issueData.is_resolved;
      this.item.updated_on = issueData.updated_on;
      SharedHelpers.setPropertiesForIssue(this.item);
      SharedHelpers.detectChanges(this.cdr);
      this.issueUpdated.next({});
    });
  }

  public setIssueResolution(issue, issueResolutionStatus: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: `Are you sure you want to set this issue as ${issueResolutionStatus ? '' : 'un'}resolved?`,
      title: 'Please confirm!',
      cancelText: 'No',
      confirmText: 'Yes!',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updatingIssueStatusId = issue.id;
        SharedHelpers.detectChanges(this.cdr);
        this.http.patch(this.apiMapping.resolveReportedIssue(issue.id), { is_resolved: issueResolutionStatus }).subscribe(
          (data: any) => {
            issue.is_resolved = issueResolutionStatus;
            this.updatingIssueStatusId = -1;
            SharedHelpers.detectChanges(this.cdr);
          },
          (error: any) => {
            this.updatingIssueStatusId = -1;
            this.notificationService.showError('Unable to update issue. Please try again.');
          },
        );
      }
    });
  }

  postsViewToggle() {
    this.item.showAllPosts = !this.item.showAllPosts;
    this.issueUpdated.next({});
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }
}
