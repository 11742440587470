// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 480px;
  width: 100vw;
  height: 100%;
  background: #f2f6f7;
}
:host .invoice-details-container {
  margin: 10px;
  padding: 10px;
  background: white;
}
:host .invoice-details-container .update-payment-method {
  margin-top: 10px;
}
:host .usgm-green {
  font-weight: bold;
}
:host .image {
  max-width: 100%;
}
:host .data-row {
  display: flex;
}
:host .carousel-arrow {
  font-size: 2rem;
  color: #077abe;
}
:host th,
:host td {
  padding: 10px;
}
:host thead tr {
  border-top: 1px solid #ddd;
}
:host tr {
  border-bottom: 1px solid #ddd;
}`, "",{"version":3,"sources":["webpack://./src/app/billing-history/credit-details-popup/credit-details-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,iBAAA;AACJ;AACI;EACE,gBAAA;AACN;AAGE;EACE,iBAAA;AADJ;AAIE;EACE,eAAA;AAFJ;AAKE;EACE,aAAA;AAHJ;AAME;EACE,eAAA;EACA,cAAA;AAJJ;AAOE;;EAEE,aAAA;AALJ;AAQE;EACE,0BAAA;AANJ;AASE;EACE,6BAAA;AAPJ","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 480px;\n  width: 100vw;\n  height: 100%;\n  background: #f2f6f7;\n\n  .invoice-details-container {\n    margin: 10px;\n    padding: 10px;\n    background: white;\n\n    .update-payment-method {\n      margin-top: 10px;\n    }\n  }\n\n  .usgm-green {\n    font-weight: bold;\n  }\n\n  .image {\n    max-width: 100%;\n  }\n\n  .data-row {\n    display: flex;\n  }\n\n  .carousel-arrow {\n    font-size: 2rem;\n    color: #077abe;\n  }\n\n  th,\n  td {\n    padding: 10px;\n  }\n\n  thead tr {\n    border-top: 1px solid #ddd;\n  }\n\n  tr {\n    border-bottom: 1px solid #ddd;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
