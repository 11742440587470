import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatStepperScrollerDirective } from './stepper-scroll.directive';
import { SwipeGestureDirective } from './swipe.directive';
import { DragDropFileDirective } from './dragDropFileUpload.directive';

@NgModule({
  declarations: [MatStepperScrollerDirective, SwipeGestureDirective, DragDropFileDirective],
  imports: [CommonModule],
  exports: [MatStepperScrollerDirective, SwipeGestureDirective, DragDropFileDirective],
})
export class DirectivesModule {}
