import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'usgm-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogWithInputComponent {
  public title: string;
  private addEvent: string;
  public cancelText: string;
  public confirmText: string;
  public inputValue: string;
  public placeholder: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogWithInputComponent>,
  ) {
    this.title = data.title;
    this.cancelText = data.cancelText;
    this.confirmText = data.confirmText;
    this.addEvent = data.addEvent;
    this.placeholder = data.placeholder;
  }

  saveData() {
    if (!this?.inputValue?.trim()) {
      return;
    }
    this.dialogRef.close({ event: this.addEvent, data: this.inputValue.trim() });
  }
}
