export const environment = {
  production: false,
  e2e: false,
  appUrl: 'https://usgm-inbox-react-staging.usglobalmail.com',
  accountApiUrl: 'https://usgm-accounts-staging.usglobalmail.com/v1',
  paymentsServerUrl: 'https://usgm-payments-staging.usglobalmail.com/v1',
  mailApiUrl: 'https://usgm-mails-staging.usglobalmail.com/v1',
  notificationsApiUrl: 'https://usgm-notifications-staging.usglobalmail.com/v1',
  postmenApiUrl: 'https://usgm-postmen-staging.usglobalmail.com/v1',
  geolocationApiUrl: 'https://usgm-geolocation.usglobalmail.com/v1',
  recaptchaSiteKey: '6LfEglclAAAAAHp0aEWxCTSsGk94mRecmdWagCOZ',
  recaptchaSiteKeyV2: '6LfDhFclAAAAAAZpAlKhuTS09MXHquaku2IZlZwb',
  googleMapKey: 'AIzaSyAeoVOVvM2kM5xJZyXhMVniYxn_teAFhJs',
  iosAppId: '1530999683',
  sentryDSN: '',
  androidAppId: 'com.staging.usglobalmail',
  loginEncryptionKey: 'I7pbayZhgg',
  gaConfigCode: 'test',
  resetPasswordPollingUrl: 'https://us-central1-gcp-usgm-staging.cloudfunctions.net/staging-reset-password-polling/resetPasswordPolling',
  legacyAppUrl: 'https://stage-account.usglobalmail.com',
  usglobalMailWordPress: 'https://stage.usglobalmail.com',
  calendlyUrl: 'https://calendly.com/itusglobalmail/30min?hide_event_type_details=1',
  betaAppUrl: 'https://usgm-inbox-staging.usglobalmail.com',
};
