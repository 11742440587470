import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as Services from '../../../services';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'usgm-settings-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsAddAddressComponent implements OnInit, OnDestroy {
  @Input() item;
  @Output() completeStep: EventEmitter<number> = new EventEmitter();
  addressForm: FormGroup;
  loading = false;
  public names = [];
  public userData: any;
  @Output() parentFun: EventEmitter<number> = new EventEmitter();
  @Input() selectedUser;
  @Output() goToNextPage = new EventEmitter<any>();

  constructor(
    private _formBuilder: FormBuilder,
    protected verifyService: Services.VerifyAccountService,
    protected apiMapping: Services.ApiMapping,
    protected notificationService: Services.NotificationService,
    protected cdr: ChangeDetectorRef,
    protected userDataService: Services.UserDataService,
    public dialog: MatDialog,
  ) {
    this.userData = this.userDataService.getDecodedAccessToken(this.userDataService.getAccessToken()) || {};
    this.addressForm = this._formBuilder.group({
      customer_id: this.selectedUser,
      place_of_registration: [null, [Validators.required]],
      type_of_business: [null, [Validators.required]],
      address_1: [null, [Validators.required]],
      address_2: [null, []],
      address_3: [null, []],
      country: [null, [Validators.required]],
      city: [null, [Validators.required]],
      state: [null, [Validators.required]],
      postal_code: [null, [Validators.required]],
      phone_number: [null, [Validators.required]],
    });
  }

  public async ngOnInit() {
    const companyAddressResult = await this.userDataService.getCompanyAddress(this.selectedUser);
    const companyAddress = companyAddressResult['response'];
    this.addressForm.controls['place_of_registration'].setValue(companyAddress?.company_place_of_registration || '');
    this.addressForm.controls['type_of_business'].setValue(companyAddress?.company_type_of_business || '');
    this.addressForm.controls['address_1'].setValue(companyAddress?.address_line || '');
    this.addressForm.controls['address_2'].setValue(companyAddress?.address_line_2 || '');
    this.addressForm.controls['address_3'].setValue(companyAddress?.address_line_3 || '');
    this.addressForm.controls['country'].setValue(companyAddress?.country || '');
    this.addressForm.controls['city'].setValue(companyAddress?.city || '');
    this.addressForm.controls['state'].setValue(companyAddress?.state || '');
    this.addressForm.controls['postal_code'].setValue(companyAddress?.postal_code || '');
    this.addressForm.controls['phone_number'].setValue(companyAddress?.phone_number || '');
    this.cdr.detectChanges();
  }

  get addressData() {
    return this.addressForm.value;
  }

  public isFieldInvalid(field: string) {
    return !this.addressForm?.get(field)?.valid && this.addressForm?.get(field)?.touched;
  }

  public displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldInvalid(field),
      'has-feedback': this.isFieldInvalid(field),
    };
  }

  private mapAddressAttributes() {
    const formValues = this.addressData;
    return {
      company_place_of_registration: formValues.place_of_registration,
      company_type_of_business: formValues.type_of_business,
      name: this.selectedUser,
      address_line: formValues.address_1,
      address_line_2: formValues.address_2,
      address_line_3: formValues.address_3,
      city: formValues.city,
      state: formValues.state,
      country: formValues.country,
      postal_code: formValues.postal_code,
      phone_number: formValues.phone_number,
    };
  }

  onSubmit(): void {
    if (this.addressForm.valid) {
      this.userDataService.saveCompanyAddress(this.selectedUser, this.mapAddressAttributes()).subscribe(
        res => {
          if (res) {
            this.notificationService.showSuccess('Address saved successfully');
            this.goToNextPage.emit({ page: 'ADD_IDS', comingFrom: 'ADD_ADDRESS' });
          }
        },
        (error: any) => {
          this.notificationService.showError(error.error?.message || error.message || 'Failed saving address, please try again');
        },
      );
    } else {
      this.notificationService.showError('Please fill all required fields');
    }
  }

  ngOnDestroy(): void {}
}
