import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import * as Services from '../../services';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';
import { AccountStatus } from '@usgm/usgm-payloads-library-front';

@Component({
  selector: 'usgm-discards-table',
  styleUrls: ['./discard-table.component.scss'],
  templateUrl: './discard-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscardTableComponent implements OnInit {
  private _numHeaders = 8;
  @Input() items;
  @Input() allSelected;
  @Input() mailsHeight;
  @Output() itemClicked = new EventEmitter();
  @Output() itemSelectionChanged = new EventEmitter();
  @Output() fetchMoreCalled = new EventEmitter();
  @Output() restoreOptionRequested = new EventEmitter();

  constructor(
    protected http: Services.UsgmHttp,
    protected userDataService: Services.UserDataService,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.fixHeadersWidth();
  }

  public ngOnInit() {
    this.fixHeadersWidth();
  }

  public restoreButtonClicked(event: any, item: any) {
    event.stopPropagation();
    this.restoreOptionRequested.emit(item);
  }

  public fixHeadersWidth() {
    setTimeout(() => {
      for (let i = 1; i <= this._numHeaders; i++) {
        const element = document.querySelectorAll('usgm-discards-table .header-' + i)[0];
        if (element) {
          let elementWidth = 150;
          if (i === 1) {
            elementWidth = 40;
          } else if (i === 3) {
            elementWidth = 200;
          } else if (i === 4) {
            elementWidth = 100;
          } else if (i === 7) {
            elementWidth = 200;
          } else if (i === this._numHeaders) {
            elementWidth = 3;
          }
          element.setAttribute('width', Math.min(elementWidth, 350) + 'px');
        }
      }
    }, 100);
  }

  public rowClicked(item) {
    this.itemClicked.next(item);
  }

  public toggleItemSelection(event, item: any) {
    event.stopPropagation();
    item.selected = !item.selected;
    if (!item.selected && this.allSelected) {
      this.allSelected = false;
    }
    this.itemSelectionChanged.emit({});
  }

  public trackItems(index, item) {
    return item.id;
  }

  public fetchMore(event: IPageInfo) {
    if (this.items && event.endIndex === this.items.length - 1) {
      this.fetchMoreCalled.emit(true);
    }
  }

  public allActionsDisabled() {
    return this.userDataService.getAccountStatus() === AccountStatus.SUSPENDED;
  }
}
