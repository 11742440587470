import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UserDataService, NotificationService } from '../../../../services';
import { haversineDistance } from '../../../../utils/helpers';
import * as SharedHelpers from '../../../../utils/helpers';

const DEFAULT_SEARCH_RADIUS = 100; // miles
const MAX_SEARCH_RADIUS = 10000; // miles
const APPROVED_STATUS = 'APPROVED';

@Component({
  selector: 'usgm-select-warehouse-step',
  templateUrl: './select-warehouse-step.component.html',
  styleUrls: ['./select-warehouse-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectWarehouseStepComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  public loading = false;
  public noMoreWarehousesToLoad = true;
  public filteredWarehouses: any[] = [];
  public currentWarehouseId;
  public currentWarehouse;

  private minSearchResultsToDisplay = 0;
  private searchRadius = DEFAULT_SEARCH_RADIUS;
  private searchedPlace: any;
  private warehouses: any[] = [];
  private searchBox: google.maps.places.SearchBox;

  @Output() warehouseSelected = new EventEmitter();
  @Output() warehousesLoaded = new EventEmitter();

  constructor(
    protected cdr: ChangeDetectorRef,
    private userDataService: UserDataService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    const decodedToken = this.userDataService.getDecodedAccessToken(this.userDataService.getAccessToken()) || {};
    this.currentWarehouseId = decodedToken.warehouseId;
    this.loadWarehouses();
  }

  public ngAfterViewInit() {
    this.searchBox = new google.maps.places.SearchBox(this.searchInput.nativeElement);
    this.searchBox.addListener('places_changed', () => {
      const places = this.searchBox.getPlaces();
      if (places.length === 0) {
        return;
      }
      this.startSearchByPlace(places[0]);
    });
  }

  private async loadWarehouses() {
    try {
      this.loading = true;
      let warehouses: any = await this.userDataService.getWarehousesByState('');
      warehouses = warehouses?.results || [];
      for (const warehouse of warehouses) {
        warehouse.addressString = this.getWareHouseAddressString(warehouse.Address);
        warehouse.hasAvailableBoxNumbers = !warehouse.occupiedBoxNumbersCount || warehouse.occupiedBoxNumbersCount < warehouse.capacity;
      }
      const warehousesApproved = warehouses
        .filter(warehouse => warehouse.WarehouseStatus?.status === APPROVED_STATUS)
        .sort((a, b) => (a.Address?.name || '').localeCompare(b.Address?.name));
      const warehousesNotApproved = warehouses
        .filter(warehouse => warehouse.WarehouseStatus?.status !== APPROVED_STATUS)
        .sort((a, b) => (a.Address?.name || '').localeCompare(b.Address?.name));
      warehouses = warehousesApproved.concat(warehousesNotApproved);
      this.warehouses = warehouses;
      this.currentWarehouse = (this.warehouses || []).find(w => w.id === this.currentWarehouseId);
      this.warehousesLoaded.emit({ all: this.warehouses, current: this.currentWarehouse });
      this.loading = false;
      this.filterWarehouses();
    } catch (error) {
      this.loading = false;
      this.notificationService.showError(`Unable to fetch warehouses' list. Please try again after sometime.`);
    }
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  public checkAndResetSearchValue(event) {
    if (event.target.value === '') {
      this.resetSearch();
    }
  }

  public resetSearch() {
    this.searchInput.nativeElement.value = '';
    this.searchInput.nativeElement.focus();
    this.searchRadius = DEFAULT_SEARCH_RADIUS;
    this.minSearchResultsToDisplay = 0;
    this.searchedPlace = null;
    this.filterWarehouses();
  }

  private filterWarehouses() {
    if (!this.searchedPlace) {
      this.filteredWarehouses = [...this.warehouses];
      for (const w of this.filteredWarehouses) {
        w.distance = undefined;
      }
    } else {
      const place = this.searchedPlace;
      const placeLat = place.geometry.location.lat;
      const placeLng = place.geometry.location.lng;
      this.filteredWarehouses = this.warehouses.filter(w => {
        const distanceFromCenter = haversineDistance(
          {
            lat: typeof placeLat === 'function' ? placeLat() : placeLat,
            lng: typeof placeLng === 'function' ? placeLng() : placeLng,
          },
          w.location || {},
        );
        w.distance = Math.ceil(distanceFromCenter);
        return distanceFromCenter <= this.searchRadius;
      });
      this.filteredWarehouses.sort((a, b) => a.distance - b.distance);
      this.noMoreWarehousesToLoad = this.filteredWarehouses.length === this.warehouses.length;
      if (!this.filteredWarehouses.length || this.filteredWarehouses.length < this.minSearchResultsToDisplay) {
        if (this.searchRadius < MAX_SEARCH_RADIUS) {
          return this.loadMoreSearchResults();
        }
        SharedHelpers.detectChanges(this.cdr);
        return;
      }
    }
    this.filteredWarehouses = this.filteredWarehouses.filter(w => w.id !== this.currentWarehouseId);
    SharedHelpers.detectChanges(this.cdr);
  }

  isWarehouseComingSoon(warehouse) {
    const status = warehouse.WarehouseStatus?.status;
    return status !== APPROVED_STATUS;
  }

  private loadMoreSearchResults() {
    this.searchRadius += DEFAULT_SEARCH_RADIUS;
    this.filterWarehouses();
  }

  public selectWarehouse(warehouse) {
    this.warehouseSelected.emit(warehouse);
  }

  public loadMoreClickHandler() {
    this.minSearchResultsToDisplay = this.filteredWarehouses.length + 1;
    this.loadMoreSearchResults();
  }

  private startSearchByPlace(place) {
    this.searchedPlace = place;
    this.searchRadius = DEFAULT_SEARCH_RADIUS;
    this.minSearchResultsToDisplay = 0;
    this.filterWarehouses();
  }

  private getWareHouseAddressString(address) {
    let addressString = '';
    addressString = this.appendStringToAddress(addressString, address?.address_line);
    addressString = this.appendStringToAddress(addressString, address?.city);
    addressString = this.appendStringToAddress(addressString, address?.state);
    addressString = this.appendStringToAddress(addressString, address?.country);
    addressString = this.appendStringToAddress(addressString, address?.postal_code);
    return addressString;
  }

  private appendStringToAddress(addressString, addressProperty) {
    if (addressProperty && addressProperty !== '') {
      return `${addressString}${addressString.length ? ', ' : ''}${addressProperty}`;
    }
    return addressString;
  }
}
