export const CardsData = [
  {
    id: '1',
    type: 'Visa',
    card_holder_name: 'Mary',
    card_number: '4242424242424242',
    cvc: '983',
    expiry: '10/21',
    status: 'Approved',
    is_default: true,
    address: '272, sec 6',
    country: 'US',
    State: 'California',
    city: 'San Francisco',
    zip: '123456',
  },
  {
    id: '2',
    type: 'Mastercard',
    card_holder_name: 'Tom',
    card_number: '4000056655665556',
    cvc: '983',
    expiry: '10/21',
    status: 'Pending',
    is_default: false,
    address: '272, sec 6',
    country: 'US',
    State: 'California',
    city: 'San Francisco',
    zip: '123456',
  },
  {
    id: '3',
    type: 'American Express',
    card_holder_name: 'Josh',
    card_number: '5555555555554444',
    cvc: '983',
    expiry: '10/21',
    status: 'Pending',
    is_default: false,
    address: '272, sec 6',
    country: 'US',
    State: 'California',
    city: 'San Francisco',
    zip: '123456',
  },
  {
    id: '4',
    type: 'Visa',
    card_holder_name: 'Mark',
    card_number: '2223003122003222',
    cvc: '983',
    expiry: '10/21',
    status: 'Approved',
    is_default: false,
    address: '272, sec 6',
    country: 'US',
    State: 'California',
    city: 'San Francisco',
    zip: '123456',
  },
];
