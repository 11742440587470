import { Component, OnInit, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import * as Services from '../../services';
import * as Helpers from '../../utils/helpers';
import * as Constants from '../../utils/constants';
import { AppRoutes } from '../../models/constants/app-routes.constant';
import { Subscription } from 'rxjs';

@Component({
  selector: 'usgm-signup',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss'],
})
export class SignupComponent implements OnInit, OnDestroy {
  private amazonLoginView: any;
  private warehouseId;
  private recaptchaSubscription: Subscription;
  private selected_plan_id: any;
  private userEmail = '';
  public registerForm: FormGroup;
  public loading = false;
  public fbSignupUrl = '';
  public googleSignupUrl = '';
  public passwordFieldInputType = 'password';
  public isMobileView = false;
  public amazonSignupUrl = '';
  public isCordovaAvailable = false;
  public disableSocialLogins;
  validStrings: string[] = ['LTD', 'LLC', 'CORP', 'LLP', 'ESQ', 'PLC', 'INC', '.LTD', '.LLC', '.CORP', '.LLP', '.ESQ', '.PLC', '.INC'];

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private http: Services.UsgmHttp,
    private apiMapping: Services.ApiMapping,
    public router: Router,
    private notificationService: Services.NotificationService,
    private ref: ElementRef,
    private userDataService: Services.UserDataService,
    private registerDataService: Services.RegistrationDataService,
    // private fb: Facebook,
    // private googlePlus: GooglePlus,
    private plt: Platform,
    // private iab: InAppBrowser,
    private ngZone: NgZone,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  async stringValidator(control: AbstractControl): Promise<ValidationErrors | null> {
    const inputValue = control.value;
    if (this.validStrings.filter(i => inputValue?.toLowerCase()?.includes(i.toLowerCase())).length) {
      return { invalidString: true };
    } else {
      return null;
    }
  }

  public ngOnInit() {
    this.userDataService.showCaptcha();
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(Helpers.emailPattern)]],
      password: ['', [Validators.required, Validators.minLength(4)]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required, this.stringValidator.bind(this)],
    });
    // if (this.plt.platforms().indexOf('cordova') >= 0) {
    //   this.isCordovaAvailable = true;
    // }
    if (!this.ref.nativeElement.offsetWidth || this.ref.nativeElement.offsetWidth < 900) {
      // null offsetWidth means nativescript
      this.isMobileView = true;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      setTimeout(() => {
        const signupChannel = params['method'] || 'email';
        if (params['success']) {
          this.notificationService.showSuccess('Successfully signed up with ' + signupChannel + '!');
        } else if (params['error']) {
          this.notificationService.showError('Failed to signup with ' + signupChannel + ('. Error: ' + params['message']));
        }
        if (params['warehouse_id']) {
          this.warehouseId = params['warehouse_id'];
        } else {
          this.router.navigate([AppRoutes.selectWarehouseByAddress]);
        }
        if (params['selected_plan_id']) {
          this.selected_plan_id = params['selected_plan_id'];
        }
        if (params['email']) {
          this.userEmail = params['email'];
        }
        this.loadInitialData();
      }, 100);
    });
  }

  loadInitialData() {
    this.registerDataService.saveCurrentData();
    let successRef = `/${AppRoutes.paymentInfo}`;
    if (this.selected_plan_id) {
      successRef = `${successRef}?selected_plan_id=${this.selected_plan_id}`;
    }
    this.userDataService.redirectLegacyUser();
    this.disableSocialLogins = this.userDataService.shouldDisableSocialLogins();
    this.fbSignupUrl = this.apiMapping.facebookAuth() + Helpers.currentUrlAsRef(successRef, this.router.url.split('?')[0], this.warehouseId);
    this.googleSignupUrl = this.apiMapping.googleAuth() + Helpers.currentUrlAsRef(successRef, this.router.url.split('?')[0], this.warehouseId);
    this.amazonSignupUrl = this.apiMapping.amazonAuth() + Helpers.currentUrlAsRef(successRef, this.router.url.split('?')[0], this.warehouseId);
    if (this.userEmail) {
      this.registerForm.controls['email'].setValue(this.userEmail);
    }

    if (this.userDataService.getNsLoggedinFbUser().token) {
      this.fbUsgmLogin();
    }
  }

  // convenience functions for easy access to form fields
  public hasErrors(fieldName: any): boolean {
    return !!this.registerForm.controls[fieldName].errors;
  }

  public hasError(fieldName: any, errorName: any): boolean {
    return this.registerForm.controls[fieldName].hasError(errorName);
  }

  public fieldEdited(fieldName: any): boolean {
    return this.registerForm.controls[fieldName].dirty && this.registerForm.controls[fieldName].touched;
  }

  onHiddenFocus(event, fieldName) {
    if (event.target.value) {
      this.registerForm.controls[fieldName].setValue(event.target.value);
    }
  }

  onFbSignup(eventData) {
    if (eventData.error) {
      console.log('Error during fb signup: ' + eventData.error);
    } else {
      this.userDataService.updateNsFbUserDetails('token', eventData.loginResponse.token);
      this.getFbUserDetails();
    }
  }

  getFbUserDetails() {
    this.loading = true;
    const accessToken = this.userDataService.getNsLoggedinFbUser().token;
    this.http.http_get(Constants.FACEBOOK_GRAPH_API_URL + '/me?fields=name,email&access_token=' + accessToken).then(
      userResp => {
        this.userDataService.updateNsFbUserDetails('id', userResp.id);
        this.userDataService.updateNsFbUserDetails('name', userResp.name);
        this.userDataService.updateNsFbUserDetails('email', userResp.email);
        this.loading = false;
        this.fbUsgmLogin();
        this.http.http_get(Constants.FACEBOOK_GRAPH_API_URL + '/' + userResp.userId + '/picture?type=large&redirect=false&access_token=' + accessToken).then(
          imageResp => {
            this.userDataService.updateNsFbUserDetails('profile_url', (imageResp.data || {}).url);
          },
          function (err) {},
        );
      },
      err => {
        this.loading = false;
        this.notificationService.showError('Error getting your details from facebook: ' + err);
      },
    );
  }

  fbUsgmLogin() {
    const postData = {
      email: this.userDataService.getNsLoggedinFbUser().email,
      profile_id: this.userDataService.getNsLoggedinFbUser().id,
      token: this.userDataService.getNsLoggedinFbUser().token,
      name: this.userDataService.getNsLoggedinFbUser().name,
      provider: 'facebook',
      warehouse_id: this.warehouseId,
    };
    this.socialLogin(postData);
  }

  socialLogin(postData) {
    this.loading = true;
    this.http
      .http_post(this.apiMapping.authNsUser(), postData)
      .then(
        (data: any) => {
          if (data['token']) {
            // redirect to payment info page
            this.handleSignupSuccessWithSessionData(data, true);
          } else {
            this.notificationService.showError('Unable to login. Please try again.');
            this.loading = false;
          }
        },
        (error: any) => {
          this.loading = false;
          if (error.status === 401) {
            this.notificationService.showError('Unable to login. ' + (error.error['message'] || 'Please try again.'));
          } else {
            this.notificationService.showError('Unable to login. Please try again.');
          }
        },
      )
      .catch((exception: any) => {
        this.loading = false;
        this.notificationService.showError('Unable to login. Please try again.');
        console.log('error fetching data', exception);
      });
  }

  onFbLogout(eventData) {
    if (eventData.error) {
      console.log('Error during fb logout: ' + eventData.error);
    } else {
      this.userDataService.deleteNsFbUser();
    }
  }

  public signupWithEmail() {
    Object.keys(this.registerForm.controls).forEach(key => {
      this.registerForm.get(key).markAsDirty();
    });
    if (this.registerForm.invalid) {
      return;
    }

    if (this.recaptchaSubscription) {
      this.recaptchaSubscription.unsubscribe();
    }
    this.recaptchaSubscription = this.recaptchaV3Service.execute('signup').subscribe(token => {
      this.loading = true;
      // const warehouseType = this.userDataService.getWarehouseType();
      const headers = {};
      if (this.warehouseId) {
        headers['warehouse-id'] = this.warehouseId;
      } else {
        this.notificationService.showError('Please select a warehouse first');
        return false;
      }
      this.http
        .post(
          this.apiMapping.signupUser(),
          {
            email: this.registerForm.get('email').value,
            name: this.registerForm.get('firstName').value + ' ' + this.registerForm.get('lastName').value,
            password: this.registerForm.get('password').value,
          },
          {
            headers,
          },
        )
        .toPromise()
        .then(
          (data: any) => {
            if (data['token']) {
              this.handleSignupSuccessWithSessionData(data, false);
            } else if (data['error']) {
              this.notificationService.showError('Failed to signup. Error: ' + data['message']);
            } else {
              this.notificationService.showError('Unable to signup. Please try again.');
            }
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (error['error']) {
              this.notificationService.showError('Failed to signup. Error: ' + error.error['message']);
            } else {
              this.notificationService.showError('Unable to signup. Please try again.');
            }
          },
        )
        .catch((exception: any) => {
          this.notificationService.showError('Unable to signup. Please try again.');
          this.loading = false;
          console.log('error in email signup', exception);
        });
    });
  }

  public async handleSignupSuccessWithSessionData(userData: any, isSocialLogin: boolean) {
    await this.userDataService.setUserSession(userData);
    await this.userDataService.getSubscription();
    await this.userDataService.getUserNotaryPlan();
    this.userDataService.setSocialLoginFlag(isSocialLogin);
    this.loading = false;
    // this.notificationService.showSuccess('Welcome to USGM!');
    this.userDataService.hideCaptcha();
    this.userDataService.removeWarehouseId();
    this.router.navigate([userData.redirect_url || AppRoutes.paymentInfo]);
  }

  public ngOnDestroy() {
    if (this.recaptchaSubscription) {
      this.recaptchaSubscription.unsubscribe();
    }
  }
}
