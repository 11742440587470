import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PreloadAllModules, RouterModule, RouteReuseStrategy, Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ShareModule } from 'ngx-sharebuttons';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { BillingHistoryModule } from './billing-history';
import { InboxModule } from './inbox';
import { LoginModule } from './login';
import { ScansListModule } from './scans-list';
import { LandingPageModule } from './landing-page';
import { ShipmentsModule } from './shipments';
import { SettingsModule } from './settings-page';
import { NoContentComponent } from './no-content/no-content.component';
import { RegisterModule } from './register';
import { PaymentPageModule } from './payment-page';
import { ComponentsModule } from './components';
import { ServicesModule } from './services';
import { WelcomeModule } from './welcome';
import { VerifyAccountModule } from './verify-account';
import { ResetPasswordModule } from './reset-password';
import { DiscardModule } from './discard';
import { environment } from '../environments/environment';
import { EmailUnsubscribeModule } from './email-unsubscribe/email-unsubscribe.module';
import { DialogsModule } from './dialogs';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaDialogComponent } from './components/recaptcha-dialog';

if (environment.sentryDSN) {
  Sentry.init({
    dsn: environment.sentryDSN,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', environment.appUrl],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

// Application wide providers
const APP_PROVIDERS = [...APP_RESOLVER_PROVIDERS];

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, NoContentComponent, RecaptchaDialogComponent],
  imports: [
    BillingHistoryModule,
    BrowserModule,
    BrowserAnimationsModule,
    ComponentsModule,
    EmailUnsubscribeModule,
    DialogsModule,
    FormsModule,
    HttpClientModule,
    InboxModule,
    IonicModule.forRoot({ hardwareBackButton: false }),
    LandingPageModule,
    LoginModule,
    PaymentPageModule,
    ResetPasswordModule,
    SettingsModule,
    ShareModule,
    ScansListModule,
    ShipmentsModule,
    ReactiveFormsModule,
    RegisterModule,
    WelcomeModule,
    VerifyAccountModule,
    DiscardModule,
    RouterModule.forRoot(ROUTES, {
      useHash: Boolean(history.pushState) === false,
      preloadingStrategy: PreloadAllModules,
    }),
    ServicesModule,
    RecaptchaModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'googleTagManagerId', useValue: environment.gaConfigCode },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    APP_PROVIDERS,
  ],
})
export class AppModule {}
