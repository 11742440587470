import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, Input, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { retry, takeUntil } from 'rxjs/operators';
import * as Services from '../../../services';
import * as SharedHelpers from '../../../utils/helpers';

@Component({
  selector: 'usgm-list-notarization-options',
  templateUrl: './list-notarize-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListNotarizeOptionsComponent implements OnInit {
  @Output() goToNextPage = new EventEmitter<any>();
  @Output() forScreenBackNavigation = new EventEmitter<any>();
  public notaryPlans = [];
  public loading = false;
  @Input() selectedId: number;
  private unSubscriber: Subject<any> = new Subject();
  constructor(
    protected verifyService: Services.VerifyAccountService,
    protected apiMapping: Services.ApiMapping,
    protected notificationService: Services.NotificationService,
    protected cdr: ChangeDetectorRef,
    protected userDataService: Services.UserDataService,
  ) {}

  public ngOnInit() {
    this.getAllNotaryPlans();
  }

  public getAllNotaryPlans(): void {
    this.verifyService
      .getnotaryPlans(this.apiMapping.getNotaryPlans())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(
        apiResponse => {
          this.notaryPlans = (apiResponse.plan || []).filter(plan => plan.plan_type !== 'NOTARYCAM').sort((a, b) => (a.plan_type < b.plan_type ? 1 : -1));
          SharedHelpers.detectChanges(this.cdr);
        },
        (error: HttpErrorResponse) => {
          this.notificationService.showError(error.message);
        },
      );
  }

  sendNotaryRequest(plan) {
    this.goToNextPage.emit({ page: plan.plan_type, selectedNotaryPlan: plan.plan_type, data: plan });
  }

  onBack() {
    this.forScreenBackNavigation.emit({ page: 'VIEW_FORM' });
  }
}
