import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'usgm-faq',
  templateUrl: './faq-accordion.component.html',
  styleUrls: ['faq-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqAccordionComponent implements OnInit, OnDestroy {
  @Input() FAQArray = [];
  @Input() FAQQuestion = '';

  constructor(protected cdr: ChangeDetectorRef) {}

  public ngOnInit() {}

  ngOnDestroy() {
    this.cdr.detach();
  }
}
