import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Platform } from '@ionic/angular';
import * as Constants from '../../utils/constants';
import { passwordMatchValidator } from './passwordmatch.validation';
import { AppRoutes } from '../../models/constants/app-routes.constant';
import * as Services from '../../services';

@Component({
  selector: 'usgm-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
  public loading = false;
  public imgSrc = Constants.USGM_LOGO;
  public newPasswordForm: FormGroup;
  public passwordFieldInputType = 'password';
  public confirmPasswordFieldInputType = 'password';
  public isMobileView = false;

  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private location: Location,
    private plt: Platform,
    private http: Services.UsgmHttp,
    private notificationService: Services.NotificationService,
    private apiMapping: Services.ApiMapping,
    private userDataService: Services.UserDataService,
  ) {}

  public ngOnInit() {
    // if (this.plt.platforms().indexOf('cordova') >= 0) {
    //   this.imgSrc = Constants.USGM_MINI_LOGO;
    //   this.isMobileView = true;
    // }
    this.newPasswordForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(4)]],
        newPassword: ['', [Validators.required, Validators.minLength(4)]],
      },
      {
        validator: passwordMatchValidator(),
      },
    );
  }

  public backBtnClicked() {
    this.location.back();
  }

  onHiddenFocus(event, fieldName) {
    if (event.target.value) {
      this.newPasswordForm.controls[fieldName].setValue(event.target.value);
    }
  }

  public resetPassword() {
    this.loading = true;
    this.http
      .post(
        this.apiMapping.resetPasswordUpdate(),
        { password: this.newPasswordForm.get('password').value },
        {
          headers: { Authorization: sessionStorage.getItem('token') },
        },
      )
      .toPromise()
      .then(
        data => {
          sessionStorage.removeItem('legacy_username');
          sessionStorage.removeItem('disable_social_logins');
          this.router.navigate([AppRoutes.newPasswordConfirmation]);
        },
        (error: any) => {
          this.loading = false;
          this.router.navigate([AppRoutes.login]);
          sessionStorage.removeItem('disable_social_logins');
          this.notificationService.showError('Unable to reset password. Please try again.');
        },
      );
  }

  async handleLoginSuccessWithSessionData(userData: any) {
    await this.userDataService.setUserSession(userData);
    await this.userDataService.getSubscription();
    await this.userDataService.getUserNotaryPlan();
    this.userDataService.setSocialLoginFlag(false);
    this.loading = false;
    this.router.navigate([AppRoutes.inbox]);
  }

  // convenience functions for easy access to form fields
  public hasErrors(fieldName: any): boolean {
    return !!this.newPasswordForm.controls[fieldName].errors;
  }

  public hasError(fieldName: any, errorName: any): boolean {
    return this.newPasswordForm.controls[fieldName].hasError(errorName);
  }

  public fieldEdited(fieldName: any): boolean {
    return this.newPasswordForm.controls[fieldName].dirty && this.newPasswordForm.controls[fieldName].touched;
  }
}
