export enum ExternalScripts {
  CALENDLY_WIDGET = 'calendly_widget',
  GOOGLE_OPTIMIZER = 'google_optimizer',
  ZENDESK = 'zendesk',
}

interface Scripts {
  id?: string;
  name: ExternalScripts;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { name: ExternalScripts.CALENDLY_WIDGET, src: 'https://assets.calendly.com/assets/external/widget.js' },
  { name: ExternalScripts.GOOGLE_OPTIMIZER, src: 'https://www.googleoptimize.com/optimize.js?id=GTM-NJBP99S' },
  { id: 'ze-snippet', name: ExternalScripts.ZENDESK, src: 'https://static.zdassets.com/ekr/snippet.js?key=ab3f1b24-310f-4b6a-baec-7d13b10611c8' },
];
