import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { DiscardComponent } from './discard.component';
import { ComponentsModule } from '../components/components.module';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { DiscardTableComponent } from './discard-table/discard-table.component';
import { DiscardDetailsPopupComponent } from './discard-details-popup/discard-details-popup.component';
import { DirectivesModule } from '../directives';

@NgModule({
  declarations: [DiscardComponent, DiscardTableComponent, DiscardDetailsPopupComponent],
  imports: [
    BrowserModule,
    CommonModule,
    ComponentsModule,
    FormsModule,
    IonicModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatTooltipModule,
    MatStepperModule,
    MatSlideToggleModule,
    PinchZoomModule,
    ReactiveFormsModule,
    RouterModule,
    VirtualScrollerModule,
    DirectivesModule,
  ],
  providers: [HttpClientModule],
  exports: [DiscardComponent, DiscardTableComponent, DiscardDetailsPopupComponent],
})
export class DiscardModule {}
