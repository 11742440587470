export const AppRoutes = {
  signup: 'signup',
  plans: 'plans',
  terms: 'terms',
  selectWarehouse: 'select-warehouse',
  paymentInfo: 'payment-info',
  paymentComplete: 'payment-complete',
  login: 'login',
  maintenance: 'maintenance',
  resetPassword: 'reset-password',
  resetPasswordConfirmation: 'reset-password-confirmation',
  newPassword: 'new-password',
  newPasswordConfirmation: 'new-password-confirmation',
  inbox: 'inbox',
  scans: 'scans',
  shipments: 'shipments',
  welcome: 'welcome',
  billingHistory: 'billing-history',
  verifyAccount: 'verify-account',
  payments: 'payments',
  discards: 'discards',
  settings: 'settings',
  unsubscribe: 'unsubscribe',
  selectWarehouseByAddress: 'select-virtual-address',
  selectPlan: 'select-plan',
  acceptOrgInvite: 'accept-org-invite',
  submitVerificationDocuments: 'submit-verification-documents',
};
