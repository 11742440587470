import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HttpClientModule } from '@angular/common/http';
// import { PopoverModule } from 'ngx-bootstrap/popover';
// import { ModalModule } from 'ngx-bootstrap/modal';
import { IonicModule } from '@ionic/angular';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { DirectivesModule } from '../directives';
import { InboxComponent } from './inbox.component';
import { CreateLabelDialogComponent } from './create-label-dialog/create-label-dialog.component';
import { SuggestAddressDialogComponent } from './suggest-address-dialog/suggest-address-dialog.component';
import { ItemPopupComponent } from './item-popup/item-popup.component';
import { ItemsTableComponent } from './items-table/items-table.component';
import { MobileItemDetailComponent } from './mobile-item-detail/mobile-item-detail.component';
import { ScanOptionsComponent } from './scan-options/scan-options.component';
import { ComponentsModule } from '../components';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { ShipItemComponent } from './ship-item/ship-item.component';

import { ItemDetailsPopupComponent } from './items-table/item-details-popup/item-details-popup.component';
import { DepositCheckComponent } from './deposit-check/deposit-check.component';
import { ReportIssuePopupComponent } from './items-table/report-issue-popup/report-issue-popup.component';
import { UnboxPopupComponent } from './items-table/unbox-popup/unbox-popup.component';
import { MultipleScanDialogComponent } from './multiple-scan-dialog/multiple-scan-dialog.component';
import { IonicGestureConfig } from '../services/ionic-gesture.service';
import { EnterDeclarationsBaseComponent } from '../components/enter-declarations/enter-declarations-base/enter-declarations-base.component';
import { EnterDeclarationsDialogComponent } from '../components/enter-declarations/enter-declarations-dialog.component';
import { AddNotesPopupComponent } from './items-table/add-note-popup/add-note-popup.component';
import { MatIconModule } from '@angular/material/icon';
import { AcceptLookupComponent } from './lookup-dialog';
import { LookupPopupComponent } from './lookup-popup';
import { QuarantinePopupComponent } from './quarantine-popup';
import { LocalDatePipe } from '../pipes/local-date.pipe';

@NgModule({
  declarations: [
    CreateLabelDialogComponent,
    SuggestAddressDialogComponent,
    DepositCheckComponent,
    InboxComponent,
    ItemPopupComponent,
    ItemsTableComponent,
    MobileItemDetailComponent,
    ScanOptionsComponent,
    ShipItemComponent,
    ItemDetailsPopupComponent,
    UnboxPopupComponent,
    ReportIssuePopupComponent,
    AddNotesPopupComponent,
    MultipleScanDialogComponent,
    EnterDeclarationsBaseComponent,
    EnterDeclarationsDialogComponent,
    AcceptLookupComponent,
    QuarantinePopupComponent,
    LookupPopupComponent,
    LocalDatePipe,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    ComponentsModule,
    DirectivesModule,
    FormsModule,
    IonicModule,
    // PopoverModule.forRoot(),
    // ModalModule.forRoot(),
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    MatTooltipModule,
    MatStepperModule,
    MatSlideToggleModule,
    PinchZoomModule,
    ReactiveFormsModule,
    RouterModule,
    VirtualScrollerModule,
    MatCardModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ],
  providers: [
    HttpClientModule,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: IonicGestureConfig,
    },
  ],
  exports: [
    CreateLabelDialogComponent,
    SuggestAddressDialogComponent,
    DepositCheckComponent,
    InboxComponent,
    ItemPopupComponent,
    ItemsTableComponent,
    ScanOptionsComponent,
    ShipItemComponent,
    ItemDetailsPopupComponent,
    UnboxPopupComponent,
    ReportIssuePopupComponent,
    AddNotesPopupComponent,
    MultipleScanDialogComponent,
    LocalDatePipe,
  ],
})
export class InboxModule {}
