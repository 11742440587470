// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .badge-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #479e5c;
  color: #fff;
  font-weight: normal;
  font-size: 9px;
  cursor: pointer;
  line-height: 9px;
  border-radius: 12px;
  padding: 4px 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/beta-badge/beta-badge.component.scss"],"names":[],"mappings":"AAEE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;AADJ","sourcesContent":["@import '../../../styles/variables.scss';\n:host {\n  .badge-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: #479e5c;\n    color: #fff;\n    font-weight: normal;\n    font-size: 9px;\n    cursor: pointer;\n    line-height: 9px;\n    border-radius: 12px;\n    padding: 4px 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
