import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import * as Services from '../services';
import { environment } from '../../environments/environment';
import * as SharedHelpers from '../utils/helpers';

const Chargebee: any = (window as any).Chargebee;

@Component({
  selector: 'usgm-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss', '../inbox/inbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentPageComponent implements OnInit, OnDestroy {
  private chargebeePortal;
  private subscriptionId;
  public loading = false;
  public mobileMode = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private http: Services.UsgmHttp,
    private apiMapping: Services.ApiMapping,
    private userDataService: Services.UserDataService,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDisplayMode();
  }

  public setDisplayMode() {
    this.mobileMode = this.userDataService.isMobileMode;
  }

  public ngOnInit() {
    this.setDisplayMode();
    this.loading = true;
    Chargebee.init({
      site: environment['chargebeeSite'],
    });
    this.http.http_get(this.apiMapping.getUserVendorDetails()).then(
      response => {
        if ((response.payment_sources || []).length) {
          const vendorCustomerId = response.payment_sources[response.payment_sources.length - 1].customer.vendor_customer_id;
          const chargebeeInstance = Chargebee.getInstance();
          this.subscriptionId = response.subscription?.vendor_subscription_id;
          chargebeeInstance.setPortalSession(() => {
            return this.http.http_get(this.apiMapping.getPortalSessionObject(vendorCustomerId));
          });
          this.chargebeePortal = chargebeeInstance.createChargebeePortal();
        }
        this.loading = false;
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.loading = false;
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  openSection(sectionType) {
    this.chargebeePortal.openSection(
      {
        sectionType,
        params: {
          subscriptionId: this.subscriptionId,
        },
      },
      {
        close() {},
      },
    );
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }
}
