// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  min-height: 100vh;
  background: white;
}
:host .resetpassword-card {
  max-width: 500px;
  margin: auto;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
:host .resetpassword-title {
  margin-top: 5.5rem;
  margin-bottom: 1.5rem;
}
:host .notification-text {
  max-width: 600px;
  margin: auto;
  padding-top: 2rem;
}
:host .select-btn {
  padding: 13px;
  background-color: #0077c4;
}
@media (max-width: 600px) {
  :host .navbar {
    padding: 0.5rem 0.5rem;
  }
  :host .usgm-nav-border {
    justify-content: center;
  }
}
@media (max-width: 400px) {
  :host .resetpassword-card {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  :host .resetpassword-title {
    margin-bottom: 5.5rem;
    margin-bottom: 0;
  }
  :host a.col.btn {
    margin: 5px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/reset-password/reset-password-confirmation/reset-password-confirmation.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;EACA,iBAAA;AACF;AACE;EACE,gBAAA;EACA,YAAA;EACA,6BAAA;EACA,8BAAA;AACJ;AAEE;EACE,kBAAA;EACA,qBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,YAAA;EACA,iBAAA;AADJ;AAIE;EACE,aAAA;EACA,yBAAA;AAFJ;AAKE;EACE;IACE,sBAAA;EAHJ;EAME;IACE,uBAAA;EAJJ;AACF;AAOE;EACE;IACE,6BAAA;IACA,8BAAA;EALJ;EAQE;IACE,qBAAA;IACA,gBAAA;EANJ;EASE;IACE,sBAAA;EAPJ;AACF","sourcesContent":[":host {\n  display: block;\n  min-height: 100vh;\n  background: white;\n\n  .resetpassword-card {\n    max-width: 500px;\n    margin: auto;\n    padding-left: 3rem !important;\n    padding-right: 3rem !important;\n  }\n\n  .resetpassword-title {\n    margin-top: 5.5rem;\n    margin-bottom: 1.5rem;\n  }\n\n  .notification-text {\n    max-width: 600px;\n    margin: auto;\n    padding-top: 2rem;\n  }\n\n  .select-btn {\n    padding: 13px;\n    background-color: #0077c4;\n  }\n\n  @media (max-width: 600px) {\n    .navbar {\n      padding: 0.5rem 0.5rem;\n    }\n\n    .usgm-nav-border {\n      justify-content: center;\n    }\n  }\n\n  @media (max-width: 400px) {\n    .resetpassword-card {\n      padding-left: 1rem !important;\n      padding-right: 1rem !important;\n    }\n\n    .resetpassword-title {\n      margin-bottom: 5.5rem;\n      margin-bottom: 0;\n    }\n\n    a.col.btn {\n      margin: 5px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
