import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VerifyAccountService {
  counter = 0;
  fetchDocumentCounter = 0;
  count: BehaviorSubject<number>;
  fetchDocuments: BehaviorSubject<number>;
  update: BehaviorSubject<boolean>;
  verificationCompleted: BehaviorSubject<boolean>;
  removeVerifyTabFromList: BehaviorSubject<boolean>;
  menuItemsList: BehaviorSubject<any[]>;
  mobileMenuItemsList: BehaviorSubject<any[]>;
  removeFrom1583ForCompanyTypeUser: BehaviorSubject<boolean>;
  resetStepperIndex: BehaviorSubject<number>;

  constructor() {
    this.count = new BehaviorSubject(this.counter);
    this.fetchDocuments = new BehaviorSubject(this.fetchDocumentCounter);
    this.update = new BehaviorSubject(false);
    this.verificationCompleted = new BehaviorSubject(false);
    this.removeVerifyTabFromList = new BehaviorSubject(false);
    this.menuItemsList = new BehaviorSubject([]);
    this.mobileMenuItemsList = new BehaviorSubject([]);
    this.removeFrom1583ForCompanyTypeUser = new BehaviorSubject(false);
    this.resetStepperIndex = new BehaviorSubject(0);
  }

  nextCount() {
    this.count.next(++this.counter);
  }

  nextDocuments() {
    this.fetchDocuments.next(++this.fetchDocumentCounter);
  }

  verificationCompleteEvent(flag: boolean) {
    this.verificationCompleted.next(flag);
  }

  removeVerifyTabFromListEvent(flag: boolean) {
    this.removeVerifyTabFromList.next(flag);
  }

  updateMenuItemsListEvent(data: any) {
    this.menuItemsList.next(data);
  }

  updateMobileMenuItemsListEvent(data: any) {
    this.mobileMenuItemsList.next(data);
  }

  removeFrom1583ForCompanyTypeUserEvent(data: boolean) {
    this.removeFrom1583ForCompanyTypeUser.next(data);
  }

  resetStepperIndexEvent(data: number) {
    this.resetStepperIndex.next(data);
  }
}
