import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { StepperSelectionEvent, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AddressType, IAddressAttributes, MailType } from '@usgm/usgm-payloads-library-front';
import { AddressService, ApiMapping, UserDataService, NotificationService, UsgmHttp } from '../../services';
import * as SharedHelpers from '../../utils/helpers';
import { emptyGetShippersPostData, emptyParcelItem } from '../ship-item/get-shippers-post-data.model';
import { MatStepper } from '@angular/material/stepper';
import { emptyAddressData, emptyDeclaration } from '../../models/address-model';
import { Country, State, City, ICountry, IState, ICity } from 'country-state-city';
import { DialogWithInputComponent } from '../../components/dialog-with-input';
import { MatDialog } from '@angular/material/dialog';
import { militaryBases } from '../../utils/constants';
@Component({
  selector: 'usgm-deposit-check',
  templateUrl: './deposit-check.component.html',
  styleUrls: ['./deposit-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true },
    },
  ],
})
export class DepositCheckComponent implements OnInit, OnDestroy {
  private shippingDeclarationRequired = false;
  private addressCreated = false;
  private planId;
  public loading = false;
  public loadingStates = false;
  public requestSubmittedView = false;
  public makingDefaultAddress = false;
  public fetchingAddresses = false;
  public savingAddress = false;
  public countries: any;
  public states: any;
  public cities: any;
  public displayItemIds = '';
  public mailBoxId = '';
  public depositSlipForm: FormGroup;
  public depositCheckRequestId = 0;
  public addressForm: FormGroup;
  public addressFormVisible = false;
  public destinationAddresses: IAddressAttributes[] = [];
  public addressDropdownOpen = false;
  public destinationAddress = JSON.parse(JSON.stringify(emptyAddressData));
  public shippingInfo: any = {};
  public selectedShipperOption: any = {};
  public addressIdToSetDefault = 0;
  public shipperOptions: any[] = [];
  public fetchingShippers = false;
  public singleItemView = true;
  public editRequestMode = false;
  public numAddressLinesVisible = 1;

  private _items: any[] = [];
  get items(): any {
    return this._items;
  }

  @Input() set items(value: any) {
    this.initShippingDeclarations(value);
    this._items = value || {};
    this.singleItemView = this._items.length === 1;
  }

  @Input() editDepositCheckData: any;

  @Output() closeNav = new EventEmitter();
  @ViewChild('depositCheckStepper') shippingStepper: MatStepper;

  constructor(
    public formBuilder: FormBuilder,
    public _addressService: AddressService,
    protected cdr: ChangeDetectorRef,
    protected apiMapping: ApiMapping,
    protected userDataService: UserDataService,
    protected notificationService: NotificationService,
    protected http: UsgmHttp,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.mailBoxId = this.userDataService.getWarehouseBoxNumber();
    this.initForm();
    if ((this.editDepositCheckData || {})['id']) {
      this.editRequestMode = true;
      this._items = [];
      this.selectedShipperOption = this.editDepositCheckData.service;
      this.destinationAddress = this.editDepositCheckData.destination_address;
      this.depositSlipForm.patchValue({
        bank_name: this.editDepositCheckData.bank_detail.name,
        bank_account: this.editDepositCheckData.bank_detail.account_number,
        bank_state: this.editDepositCheckData.bank_detail.state,
        packing_instructions: this.editDepositCheckData.packing_instructions,
      });
      // set mails
      this.editDepositCheckData.declaration_data.forEach(item => {
        if (item.mail_id) {
          this._items.push({
            id: item.mail_id,
            mail_type: item.mail_type,
            image_url: item.image_url,
            shipping_declarations: item.declarations,
            weight: item.dimensions.weight,
            measurement: {
              height: item.dimensions.height,
              length: item.dimensions.length,
              width: item.dimensions.width,
            },
          });
        }
      });
      this.singleItemView = this._items.length === 1;
    }
    this.getSubscriptions();
    this.getShippingInfo();
    this.getDestinationAddresses();
    this.getCountries();
    document.addEventListener('keydown', SharedHelpers.consumeEnterKeyEvent);
  }

  closeSideNav() {
    this.closeNav.emit({});
  }

  public initForm() {
    this.depositSlipForm = this.formBuilder.group({
      bank_name: [null, Validators.required],
      bank_account: [null, Validators.required],
      bank_state: [null, Validators.required],
      packing_instructions: [null, []],
    });
    this.addressForm = this.formBuilder.group({
      address_type: [AddressType.CHECK_DEPOSIT, Validators.required],
      name: [null, Validators.required],
      address_line: [null, Validators.required],
      address_line_2: [null, []],
      address_line_3: [null, []],
      city: [null, Validators.required],
      country: [null, Validators.required],
      state: [null, Validators.required],
      postal_code: [null, [Validators.required]],
      phone_number: ['', [Validators.required, Validators.pattern(SharedHelpers.phoneNumberPattern)]],
      is_default: [false, []],
      user_id: [this.userDataService.getUserId(), []],
    });
  }

  public getShippingInfo() {
    this.http.get(this.apiMapping.getShippingInfo()).subscribe((data: any) => {
      this.shippingInfo = data.shipping_info;
    });
  }

  getSubscriptions() {
    this.userDataService.getUserSubscription().subscribe(response => {
      if (response && response.length) {
        const subscription = response.find(item => this.userDataService.isActiveSubscription(item));
        if (subscription) {
          this.planId = subscription.plan_id;
          this.getDestinationAddresses();
        }
      }
      SharedHelpers.detectChanges(this.cdr);
    });
  }

  submitRequest() {
    if (!this.destinationAddress.address_line) {
      this.shippingStepper.selectedIndex = 1;
      this.notificationService.showError('Please select an address');
      return;
    }
    if (this.depositSlipForm.invalid) {
      this.shippingStepper.selectedIndex = 0;
      this.notificationService.showError('Please fill your bank details');
      return;
    }
    if (this.shippingDeclarationRequired) {
      if (!this.shippingItemsDeclared()) {
        this.notificationService.showError('Please declare items in your request');
        return;
      }
    }
    if (!this.selectedShipperOption) {
      this.shippingStepper.selectedIndex = !this.shippingDeclarationRequired ? 1 : 2;
      this.notificationService.showError('Please select a shipper');
      return;
    }
    this.loading = true;
    (this.editRequestMode
      ? this.http.put(this.apiMapping.editShippingRequest(this.editDepositCheckData.id), this.submitDepositRequestPostData())
      : this.http.post(this.apiMapping.submitShippingRequest(), this.submitDepositRequestPostData())
    ).subscribe(
      response => {
        this.loading = false;
        if (response['shipment_id']) {
          this.depositCheckRequestId = response['shipment_id'];
          this.requestSubmittedView = true;
        } else {
          this.notificationService.showError('Shipment request already submitted');
        }
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.loading = false;
        this.notificationService.showError('Unable to process request. Please try again');
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  getDestinationAddresses() {
    if (this.userDataService.hasUserDestinationAddresses(AddressType.CHECK_DEPOSIT)) {
      this.handleUserDestinationAddresses(this.userDataService.getUserDestinationAddresses(AddressType.CHECK_DEPOSIT));
      return;
    }
    this.fetchingAddresses = true;
    this.http.get(this.apiMapping.getUserAddressesByType(this.userDataService.getUserId(), AddressType.CHECK_DEPOSIT)).subscribe(
      (response: any) => {
        this.userDataService.setUserDestinationAddresses(response, AddressType.CHECK_DEPOSIT);
        this.handleUserDestinationAddresses(this.userDataService.getUserDestinationAddresses(AddressType.CHECK_DEPOSIT));
      },
      error => {
        this.fetchingAddresses = false;
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  setDefaultAddress(addressId: number) {
    this.makingDefaultAddress = true;
    this.addressIdToSetDefault = addressId;
    this.http.put(this.apiMapping.setDefaultUserAddress(this.userDataService.getUserId(), addressId), {}).subscribe(
      response => {
        this.destinationAddresses.forEach((address: IAddressAttributes) => {
          address.is_default = address.id === addressId;
        });
        this.addressIdToSetDefault = 0;
        this.makingDefaultAddress = false;
        this.userDataService.sortAddresses(this.destinationAddresses);
        this.userDataService.clearUserDestinationAddresses(AddressType.CHECK_DEPOSIT); // clear existing to update addresses
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.makingDefaultAddress = false;
        this.notificationService.showError('Unable to process request. Please try again');
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  submitAddress() {
    this.validateAllFormFields(this.addressForm);
    if (!this.addressForm.valid) {
      return;
    }
    const postData = JSON.parse(JSON.stringify(this.addressForm.value));
    postData.country = postData.country.Id;
    postData.address_type = AddressType.CHECK_DEPOSIT;
    this.savingAddress = true;
    this.http.post(this.apiMapping.createUserAddress(this.userDataService.getUserId()), postData).subscribe(
      response => {
        this.resetAddressForm();
        this.savingAddress = false;
        this.addressCreated = true;
        this.notificationService.showSuccess('Address created!');
        this.userDataService.clearUserDestinationAddresses(AddressType.CHECK_DEPOSIT); // clear existing to update addresses
        this.getDestinationAddresses();
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.savingAddress = false;
        this.notificationService.showError('Unable to process request. Please try again');
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  async getDimensions(items) {
    const dimensionalItems = this.items.map(item => {
      return {
        length: item.measurement.length,
        width: item.measurement.width,
        height: item.measurement.height,
        weight: item.weight,
        type: item.mail_type,
      };
    });
    try {
      return await this.http.post(this.apiMapping.getDimensions(), dimensionalItems).toPromise();
    } catch (err) {
      throw new Error(err);
    }
  }

  async getShippersForItems() {
    this.shipperOptions = [];
    this.fetchingShippers = true;
    SharedHelpers.detectChanges(this.cdr);
    const dimensions: any = await this.getDimensions(this.items);
    const postData = JSON.parse(JSON.stringify(emptyGetShippersPostData));
    const postDataParcel = postData.parcels[0];
    const destinationCountryCode = (this.destinationAddress.country || '').toLowerCase().indexOf('in') !== -1 ? 'IND' : 'USA';
    this._items.forEach(item => {
      item.shipping_declarations = item.shipping_declarations || [];
      item.shipping_declarations.forEach(declaration => {
        const parcelItem = JSON.parse(JSON.stringify(emptyParcelItem));
        parcelItem.description = declaration.description;
        parcelItem.quantity = declaration.quantity;
        parcelItem.price.amount = declaration.item_value;
        parcelItem.origin_country = destinationCountryCode;
        parcelItem.weight.value = parseFloat(item.weight + '') / (item.shipping_declarations.length * parcelItem.quantity);
        postDataParcel.items.push(parcelItem);
      });
      if (!item.shipping_declarations.length) {
        const parcelItem = JSON.parse(JSON.stringify(emptyParcelItem));
        parcelItem.description = 'Item #' + item.id;
        parcelItem.quantity = 1;
        parcelItem.price.amount = 1;
        parcelItem.origin_country = destinationCountryCode;
        parcelItem.weight.value = parseFloat(item.weight + '');
        postDataParcel.items.push(parcelItem);
      }
    });
    postDataParcel.weight.value = parseFloat(dimensions.weight + '');
    postDataParcel.dimension.height = parseFloat(dimensions.height + '');
    postDataParcel.dimension.width = parseFloat(dimensions.width + '');
    postDataParcel.dimension.depth = parseFloat(dimensions.length + '');
    postDataParcel.description = this._items.length + ' items';
    postData.ship_to = {
      contact_name: this.destinationAddress.name,
      phone: this.destinationAddress.phone_number,
      email: this.userDataService.getUserEmail(),
      street1: this.destinationAddress.address_line,
      city: this.destinationAddress.city,
      postal_code: this.destinationAddress.postal_code,
      state: this.destinationAddress.state,
      country: this.destinationAddress.country,
      type: 'residential',
    };
    if (this.destinationAddress.address_line_2) {
      postData.ship_to['street2'] = this.destinationAddress.address_line_2;
    }
    if (this.destinationAddress.address_line_3) {
      postData.ship_to['street3'] = this.destinationAddress.address_line_3;
    }
    postData.plan_id = this.planId;
    this.http.post(this.apiMapping.getShippersForItems(), postData).subscribe(
      response => {
        this.setupShippersData(response);
        this.fetchingShippers = false;
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        console.log('Error getting shippers: ', error.error);
        this.fetchingShippers = false;
        SharedHelpers.detectChanges(this.cdr);
        this.notificationService.showError('Error fetching shippers: ' + error.error.message);
      },
    );
  }

  handleUserDestinationAddresses(addresses: any[]) {
    let defaultAddress = addresses[0];
    if (this.addressCreated) {
      const addressesCopy = JSON.parse(JSON.stringify(addresses));
      defaultAddress = addressesCopy.sort((a, b) => b.id - a.id)[0];
      this.addressCreated = false;
    }
    this.destinationAddresses = addresses;
    if (addresses?.length && this.planId) {
      this.addressClicked(defaultAddress || JSON.parse(JSON.stringify(emptyAddressData)));
    }
    this.fetchingAddresses = false;
    SharedHelpers.detectChanges(this.cdr);
  }

  setupShippersData(response) {
    this.shipperOptions = (response['data'] || {})['rates'] || [];
    this.shipperOptions.forEach((shipperOption, index) => {
      shipperOption['id'] = index;
      shipperOption['trackable'] = SharedHelpers.isServiceTrackableForDepositCheck(shipperOption['service_type'], shipperOption['trackable']);
      shipperOption['service_name'] = SharedHelpers.underscoreToSentenceCase(shipperOption['service_name'] || shipperOption['service_type']);
      if (isNaN(new Date(shipperOption['delivery_date']).getTime())) {
        shipperOption['delivery_date'] = null;
      }
      if (this.editRequestMode) {
        if (this.editDepositCheckData.service.service_type === shipperOption.service_type && this.editDepositCheckData.service.name === shipperOption.service_name) {
          this.selectedShipperOption = shipperOption;
        }
      }
    });
    // UNI-1604 - exclude trackable shipment options for deposit check requests
    this.shipperOptions = this.shipperOptions.filter(it => !!it.trackable);
    if (this.shipperOptions.length === 0 && this.destinationAddresses.length !== 0) {
      this.notificationService.showError('No shippers found for selected destination!');
    }
  }

  submitDepositRequestPostData() {
    const baseRate = (this.selectedShipperOption.detailed_charges || []).find(charge => charge.type === 'base');
    const postData = {
      shipment_category_type: 'CHECK_DEPOSIT_REQUEST',
      shipment_detail: { packing_instructions: this.depositSlipForm.get('packing_instructions').value },
      destination_address: {
        name: this.destinationAddress.name,
        address_line: this.destinationAddress.address_line,
        city: this.destinationAddress.city,
        country: this.destinationAddress.country,
        state: this.destinationAddress.state,
        postal_code: this.destinationAddress.postal_code,
        phone_number: this.destinationAddress.phone_number,
      },
      items: [],
      shipper: {
        name: this.selectedShipperOption.service_name,
        service_type: this.selectedShipperOption.service_type,
        slug: this.selectedShipperOption.shipper_account.slug,
        is_ship_today: this.selectedShipperOption.shipToday,
        is_trackable: this.selectedShipperOption.trackable,
        eta: this.selectedShipperOption.delivery_date,
        retail_rate: (this.selectedShipperOption.total_charge || {}).amount,
        our_rate: (this.selectedShipperOption.discounted_charge || {}).amount,
        base_rate: baseRate.charge.amount,
      },
      bank_detail: {
        name: this.depositSlipForm.get('bank_name').value,
        account_number: this.depositSlipForm.get('bank_account').value,
        state: this.depositSlipForm.get('bank_state').value,
      },
    };
    if (this.destinationAddress.address_line_2) {
      postData.destination_address['address_line_2'] = this.destinationAddress.address_line_2;
    }
    if (this.destinationAddress.address_line_3) {
      postData.destination_address['address_line_3'] = this.destinationAddress.address_line_3;
    }
    this._items.forEach(item => {
      postData.items.push({ mail_id: item.id, declarations: item.shipping_declarations });
    });
    return postData;
  }

  stepChanged($event: StepperSelectionEvent) {
    // previously selected step checks
    if ($event.previouslySelectedStep.label === 'Deposit Slip') {
      this.depositSlipForm.markAllAsTouched();
    } else if ($event.previouslySelectedStep.label === 'Select Destination') {
    } else if ($event.previouslySelectedStep.label === 'Select Shipper') {
    }
    // current selected step checks
    if ($event.selectedStep.label === 'Deposit Slip') {
    } else if ($event.selectedStep.label === 'Select Destination') {
    } else if ($event.selectedStep.label === 'Select Shipper') {
    }
  }

  addressClicked(address: IAddressAttributes) {
    this.destinationAddress = address;
    (this.items || []).forEach(item => {
      if (item.mail_type !== MailType.LETTER) {
        this.shippingDeclarationRequired = true;
      }
    });
    this.shippingDeclarationRequired = this.shippingDeclarationRequired && !(address.country === 'United States' || address.country === 'US');
    this.initShippingDeclarations(this._items);
    this.addressDropdownOpen = false;
    this.resetShipperSelection();
    this.getShippersForItems();
  }

  resetShipperSelection() {
    if (this.selectedShipperOption && !this.editRequestMode) {
      this.selectedShipperOption = null;
    }
  }

  public getCountries() {
    this.countries = Country.getAllCountries().map((country: ICountry) => ({ Id: country.isoCode, Name: country.name }));
    this.addressForm.patchValue({ country: { Id: 'US', Name: 'United States' } });
    this.countrySelected();
  }

  public getStatesByCountryId(id: string) {
    this.states = State.getStatesOfCountry(id);
    if (id === 'US' || id === 'USA') {
      this.states = [...this.states, ...militaryBases];
    }
  }

  public stateSelected(country: { Id: string; Name: string }, stateCode: string) {
    if (stateCode === 'add_new_option') {
      const dialogRefToAddState = this.dialog.open(DialogWithInputComponent, {
        data: {
          title: 'Add a new State',
          cancelText: 'Cancel',
          confirmText: 'Add',
          addEvent: 'ADD_NEW_STATE',
        },
      });

      dialogRefToAddState?.afterClosed().subscribe(result => {
        if (result.event === 'ADD_NEW_STATE') {
          this.states.push({
            isoCode: result.data,
            name: result.data,
          });
          this.addressForm.patchValue({
            state: result.data,
          });
          this.addressForm.patchValue({
            city: '',
          });
          this.cities = [];
          this.addressForm.get('postal_code').reset();
          SharedHelpers.detectChanges(this.cdr);
        }
      });
    } else {
      this.cities = City.getCitiesOfState(country.Id, stateCode);
    }
  }

  public citySelected() {
    const selectedCity = this.addressForm.controls['city'].value;
    if (selectedCity && selectedCity === 'add_new_option') {
      const dialogRefToAddCity = this.dialog.open(DialogWithInputComponent, {
        data: {
          title: 'Add a new city',
          cancelText: 'Cancel',
          confirmText: 'Add',
          addEvent: 'ADD_NEW_CITY',
        },
      });

      dialogRefToAddCity?.afterClosed().subscribe(result => {
        if (result.event === 'ADD_NEW_CITY') {
          this.cities.push({
            isoCode: result.data,
            name: result.data,
          });
          this.addressForm.patchValue({
            city: result.data,
          });
          SharedHelpers.detectChanges(this.cdr);
        }
      });
    }
  }

  isFieldInvalid(form: FormGroup, field: string) {
    return !form.get(field).valid && form.get(field).touched;
  }

  countrySelected(): void {
    this.states = [];
    this.addressForm.patchValue({ state: '' });
    this.addressForm.get('city').reset();
    this.addressForm.get('postal_code').reset();
    const selectedCountry = this.addressForm.get('country').value;
    if (selectedCountry) {
      const Id = selectedCountry.Id;
      if (Id) {
        this.getStatesByCountryId(Id);
      }
    }
  }

  public compareByID(o1: any, o2: any) {
    return (o1 || {}).Id === (o2 || {}).Id;
  }

  displayFieldCss(form: FormGroup, field: string) {
    return {
      'has-error': this.isFieldInvalid(form, field),
      'has-feedback': this.isFieldInvalid(form, field),
    };
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  deleteAddressLine() {
    this.addressForm.patchValue({ address_line_3: '' });
    this.numAddressLinesVisible = this.numAddressLinesVisible - 1;
    if (this.numAddressLinesVisible <= 1) {
      this.addressForm.patchValue({ address_line_2: '' });
    }
  }

  resetAddressForm() {
    this.addressForm.reset();
    this.addressForm.patchValue({ is_default: false, user_id: this.userDataService.getUserId(), address_type: AddressType.CHECK_DEPOSIT });
    this.addressFormVisible = false;
  }

  public shippingItemsDeclared(): boolean {
    let shippingDeclared = true;
    this._items.forEach(item => {
      shippingDeclared = shippingDeclared && (item.shipping_declarations || []).length !== 0;
    });
    return shippingDeclared;
  }

  initShippingDeclarations(items: any[]) {
    const item_ids = [];
    items.forEach(item => {
      item_ids.push(item.id);
      item.shipping_declaration = JSON.parse(JSON.stringify(emptyDeclaration));
      item.shipping_declarations = [{ description: `Item #${item.id}`, item_value: 0, quantity: 1, is_insured: false }];
    });
    this.displayItemIds = item_ids.join(', ');
  }

  checkStateInput(event) {
    const pattern = /[A-Za-z]/g;
    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  ngOnDestroy(): void {
    this.cdr.detach();
    document.removeEventListener('keydown', SharedHelpers.consumeEnterKeyEvent);
  }
}
