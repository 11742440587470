import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DialogComponent } from './dialog';
import { ImageDialogComponent } from './image-dialog';
import { HeaderModule } from './header';
import { FooterComponent } from './footer';
import { SignupHeaderComponent } from './signup-header/signup-header.component';
import { IssueDetailPopupModule } from './issue-detail-popup';
import { IssueRowModule } from './issue-row';
import { ModalModule } from './modal';
import { PdfViewerDialogModule } from './pdf-viewer-dialog';
import { SidebarModule } from './sidebar';
import { TabBarModule } from './tab-bar';
import { TitledSnackBarComponent } from './titled-snack-bar';
import { CarouselModule } from './carousel/carousel.module';
import { FaqAccordionModule } from './faq-accordion';
import { SlideshowModule } from '../modules/slideshow/slideshow.module';
import { RouterModule } from '@angular/router';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { FieldErrorDisplayComponent } from '../inbox/field-error-display/field-error-display.component';
import { RegisterHeaderComponent } from '../register/register-header/header.component';
import { ScheduledAccountClosureDialogComponent } from './scheduled-account-closure-dialog/dialog.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { FormErrorRequestsNotificationComponent } from './form-error-requests-notification/form-error-requests-notification.component';
import { SignFormPopupModule } from '../resolve-form-error/sign-form-popup/sign-form-dialog.module';

@NgModule({
  declarations: [
    DialogComponent,
    FooterComponent,
    SignupHeaderComponent,
    TitledSnackBarComponent,
    ImageDialogComponent,
    FieldErrorDisplayComponent,
    RegisterHeaderComponent,
    ScheduledAccountClosureDialogComponent,
    InfoBoxComponent,
    FormErrorRequestsNotificationComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    HeaderModule,
    IssueDetailPopupModule,
    IssueRowModule,
    MatButtonModule,
    MatDialogModule,
    ModalModule,
    PdfViewerDialogModule,
    ReactiveFormsModule,
    SidebarModule,
    TabBarModule,
    CarouselModule,
    SlideshowModule,
    RouterModule,
    PinchZoomModule,
    MatCardModule,
    MatIconModule,
    SignFormPopupModule,
  ],
  exports: [
    DialogComponent,
    ImageDialogComponent,
    FieldErrorDisplayComponent,
    RegisterHeaderComponent,
    FooterComponent,
    FaqAccordionModule,
    HeaderModule,
    IssueDetailPopupModule,
    IssueRowModule,
    MatDialogModule,
    ModalModule,
    PdfViewerDialogModule,
    SidebarModule,
    TabBarModule,
    TitledSnackBarComponent,
    CarouselModule,
    SignupHeaderComponent,
    SlideshowModule,
    InfoBoxComponent,
    FormErrorRequestsNotificationComponent,
    SignFormPopupModule,
  ],
})
export class ComponentsModule {}
