// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 480px;
  width: 100%;
  height: 100%;
  padding: 5px;
}
:host .control-label {
  font-weight: bold;
  margin-left: 10px;
}

.no-header.mat-stepper-horizontal {
  max-width: calc(100vw - 20px);
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/billing-details/billing-details.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AACF;AACE;EACE,iBAAA;EACA,iBAAA;AACJ;;AAIE;EACE,6BAAA;AADJ","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 480px;\n  width: 100%;\n  height: 100%;\n  padding: 5px;\n\n  .control-label {\n    font-weight: bold;\n    margin-left: 10px;\n  }\n}\n\n.no-header {\n  &.mat-stepper-horizontal {\n    max-width: calc(100vw - 20px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
