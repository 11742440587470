// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  min-height: 100vh;
  background-color: white;
}
:host .disabled {
  opacity: 0.7;
}
:host .title {
  margin: 1.5rem 0;
}
:host .container {
  max-width: 620px;
  margin-top: 0.5rem;
}
:host .show-password-button {
  position: absolute;
  right: 30px;
  top: 21px;
  cursor: pointer;
  padding: 3px;
  border-radius: 5px;
}
:host #gd-r-email,
:host #gd-r-full-name,
:host #gd-r-password {
  height: unset;
  background-color: white;
  box-shadow: none;
}
:host .invalid-feedback {
  display: block;
}
:host .select-btn {
  padding: 13px;
  background-color: #0077c4;
}
:host input {
  width: 100%;
  border: none;
  outline: none;
}
:host ::placeholder {
  color: #b9c6cc;
  opacity: 1;
  font-size: 12px;
}
@media (max-width: 400px) {
  :host a.col.btn {
    margin: 5px !important;
  }
  :host .form-group {
    margin-bottom: 0.5rem;
  }
  :host #gd-r-email,
  :host #gd-r-full-name,
  :host #gd-r-password {
    height: unset;
    background-color: white;
    box-shadow: none;
  }
  :host .show-password-button {
    top: 21px;
  }
}
@media (min-width: 800px) {
  :host .show-password-button:hover {
    color: green;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/accept-org-invite/accept-org-invite.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;EACA,uBAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,kBAAA;AADJ;AAIE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AAFJ;AAKE;;;EAGE,aAAA;EACA,uBAAA;EACA,gBAAA;AAHJ;AAME;EACE,cAAA;AAJJ;AAOE;EACE,aAAA;EACA,yBAAA;AALJ;AAQE;EACE,WAAA;EACA,YAAA;EACA,aAAA;AANJ;AASE;EACE,cAAA;EACA,UAAA;EACA,eAAA;AAPJ;AAUE;EACE;IACE,sBAAA;EARJ;EAWE;IACE,qBAAA;EATJ;EAYE;;;IAGE,aAAA;IACA,uBAAA;IACA,gBAAA;EAVJ;EAaE;IACE,SAAA;EAXJ;AACF;AAcE;EAEI;IACE,YAAA;EAbN;AACF","sourcesContent":[":host {\n  display: block;\n  min-height: 100vh;\n  background-color: white;\n\n  .disabled {\n    opacity: 0.7;\n  }\n\n  .title {\n    margin: 1.5rem 0;\n  }\n\n  .container {\n    max-width: 620px;\n    margin-top: 0.5rem;\n  }\n\n  .show-password-button {\n    position: absolute;\n    right: 30px;\n    top: 21px;\n    cursor: pointer;\n    padding: 3px;\n    border-radius: 5px;\n  }\n\n  #gd-r-email,\n  #gd-r-full-name,\n  #gd-r-password {\n    height: unset;\n    background-color: white;\n    box-shadow: none;\n  }\n\n  .invalid-feedback {\n    display: block;\n  }\n\n  .select-btn {\n    padding: 13px;\n    background-color: #0077c4;\n  }\n\n  input {\n    width: 100%;\n    border: none;\n    outline: none;\n  }\n\n  ::placeholder {\n    color: #b9c6cc;\n    opacity: 1;\n    font-size: 12px;\n  }\n\n  @media (max-width: 400px) {\n    a.col.btn {\n      margin: 5px !important;\n    }\n\n    .form-group {\n      margin-bottom: 0.5rem;\n    }\n\n    #gd-r-email,\n    #gd-r-full-name,\n    #gd-r-password {\n      height: unset;\n      background-color: white;\n      box-shadow: none;\n    }\n\n    .show-password-button {\n      top: 21px;\n    }\n  }\n\n  @media (min-width: 800px) {\n    .show-password-button {\n      &:hover {\n        color: green;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
