// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-list .list-box span.rejected {
  background: #ff5722;
  color: #fff;
}

.red-text {
  color: #ff5722 !important;
  font-weight: normal !important;
}

.progress-list .list-box h3 {
  display: flex;
  align-items: center;
  word-wrap: normal;
  word-break: break-all;
}

.mobile-form {
  display: block !important;
}

.min-width-100 {
  min-width: 100px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/verify-account/add-ids/add-ids.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,yBAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,qBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,2BAAA;AACF","sourcesContent":[".progress-list .list-box span.rejected {\n  background: #ff5722;\n  color: #fff;\n}\n\n.red-text {\n  color: #ff5722 !important;\n  font-weight: normal !important;\n}\n\n.progress-list .list-box h3 {\n  display: flex;\n  align-items: center;\n  word-wrap: normal;\n  word-break: break-all;\n}\n\n.mobile-form {\n  display: block !important;\n}\n\n.min-width-100 {\n  min-width: 100px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
