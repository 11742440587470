import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'usgm-titled-snack-bar',
  templateUrl: './titled-snack-bar.component.html',
  styleUrls: ['./titled-snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitledSnackBarComponent {
  title: string;
  message: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.title = data.title;
    this.message = data.message || '';
  }
}
