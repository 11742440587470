type TInflectArgs = { count?: number };

export type TConfirmationMessageParams = TInflectArgs;

const pluralizeSimple = (word: string, count?: number) => {
  return count === 1 ? word : `${word}s`;
};

export const CONFIRMATION_DIALOGS = {
  delete: {
    message: ({ count }: TConfirmationMessageParams) => `Are you sure you want to delete selected ${pluralizeSimple('scan', count)}.`,
    title: 'Please confirm!',
    cancelText: 'No',
    confirmText: 'Yes, delete!',
  },
  download: {
    message: `Your exports will be available under Exports in "Settings > Exports". <br> You will get an email notification once the export is completed.`,
    title: ({ count }: TConfirmationMessageParams) => `Do you want to export selected ${pluralizeSimple('scan', count)} in a zip file?`,
    cancelText: 'Close',
    confirmText: 'Export',
  },
};

export type TConfirmationDIalogType = keyof typeof CONFIRMATION_DIALOGS;

export const ALERT_MESSAGES = {
  EXPORT_SUCCESS: `Export has been initiated and it’s in progress now.`,
  EXPORT_FAILED: ({ count }: TInflectArgs) =>
    `Apologies, we encountered an issue while attempting to export the ${pluralizeSimple(
      'scan',
      count,
    )}. Please try again, and if the problem persists, contact support for assistance.`,
  DELETE_SUCCESS: ({ count }: TInflectArgs) => `${count > 1 ? 'Scans have' : 'Scan has'} been successfully deleted.`,
  DELETE_FAILED: ({ count }: TInflectArgs) =>
    `Apologies, we encountered an issue while attempting to delete the ${pluralizeSimple(
      'scan',
      count,
    )}. Please try again, and if the problem persists, contact support for assistance.`,
};
