import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'usgm-progress-line',
  templateUrl: './progress-line.component.html',
  styleUrls: ['./progress-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressLineComponent implements OnInit {
  steps = [
    {
      index: 0,
      title: 'pick Address',
      status: 'pending',
    },
    {
      index: 1,
      title: 'pick a plan',
      status: 'pending',
    },
    {
      index: 2,
      title: 'create account',
      status: 'pending',
    },
    {
      index: 3,
      title: 'payment',
      status: 'pending',
    },
    {
      index: 4,
      title: 'Done',
      status: 'pending',
    },
  ];

  constructor() {}

  public ngOnInit() {}

  @Input() set currentIndex(value: number) {
    if (value !== null || value !== undefined) {
      this.steps = this.steps.map(step => ({
        ...step,
        status: step.index < value ? 'done' : step.index === value ? 'active' : 'pending',
      }));
    }
  }

  getActiveTitle() {
    const activeStep = this.steps.find(step => step.status === 'active');
    return activeStep?.title || '';
  }
}
