import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { UserDataService, NotificationService, MailFolderService } from '../../services';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog';
import { FormControl } from '@angular/forms';
import * as SharedHelpers from '../../utils/helpers';
import { MatSelect } from '@angular/material/select';
import { EditLabelDialogComponent } from '../../components/sidebar';
import { takeUntil } from 'rxjs/operators';
import { ChangePlanPopupComponent } from '../change-plan-popup/change-plan-popup.component';

export interface InviteRow {
  email: string;
  name?: string;
  role: string;
  status?: string;
  message?: string;
  duplicate?: boolean;
}

const DEFAULT_INVITES = [
  {
    email: '',
    role: 'REGULAR',
  },
  {
    email: '',
    role: 'REGULAR',
  },
  {
    email: '',
    role: 'REGULAR',
  },
];

@Component({
  selector: 'usgm-manage-team-members',
  templateUrl: './manage-team-members.component.html',
  styleUrls: ['./manage-team-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTeamMembersComponent implements OnInit, OnDestroy {
  public loadingList = false;
  public teamMembers = [];
  public unsubscribeService: Subject<any> = new Subject();
  public sortedMailFolders: any[] = [];
  public selectedUserMailFolders = [];
  public selectedUser;
  public mailFolderSearchTerm = '';
  public loadingMailFolders = false;
  public folderMultiCtrl: FormControl = new FormControl();
  public invites: InviteRow[];
  public sendingInvitations = false;
  public teamMembersNotSupported = false;

  private allMailFolders = [];
  public invitationsSent = false;

  @Output() closeNav = new EventEmitter();
  @ViewChild('teamMembersStepper', { static: true }) teamMembersStepper: MatStepper;
  @ViewChild('mailFolderSelect') mailFolderSelect: MatSelect;

  constructor(
    private cdr: ChangeDetectorRef,
    private userDataService: UserDataService,
    private notificationService: NotificationService,
    private mailFolderService: MailFolderService,
    private dialog: MatDialog,
  ) {}

  async ngOnInit() {
    const subscriptions = await this.userDataService.getUserSubscription().toPromise();
    const activeSubscription = subscriptions?.find(item => this.userDataService.isActiveSubscription(item));
    if (!activeSubscription) {
      this.notificationService.showError('Can not load subscription details, please reload the page');
      return;
    }
    const plan = await this.userDataService.getUserPlanById(activeSubscription.plan_id).toPromise();
    if (!plan?.other_options?.is_org_accounts_supported) {
      this.teamMembersNotSupported = true;
      SharedHelpers.detectChanges(this.cdr);
      return;
    }
    this.loadTeamMembers();
    this.resetInvites();
  }

  private loadTeamMembers() {
    this.loadingList = true;
    this.userDataService
      .getTeamMembers()
      .pipe(takeUntil(this.unsubscribeService))
      .subscribe(
        (resp: any) => {
          this.teamMembers = resp;
          this.loadingList = false;
          this.cdr.detectChanges();
        },
        (error: any) => {
          this.loadingList = false;
          this.notificationService.showError(this.extractErrorMessage(error, 'Unable to get sub user list. Please contact support.'));
          this.cdr.detectChanges();
        },
      );
  }

  private async loadUserMailFolders() {
    try {
      this.allMailFolders = await this.mailFolderService.getFolders();
      this.sortedMailFolders = [...(this.allMailFolders || [])];
      this.sortMailFolders();
    } catch (error) {
      this.notificationService.showError(this.extractErrorMessage(error, 'Unable to get user mail folders. Please contact support.'));
    }
  }

  private extractErrorMessage(error, fallback) {
    return error?.error?.message || error?.statusText || error?.message || fallback;
  }

  public goToTeamMembersScreen() {
    this.teamMembersStepper.selectedIndex = 0;
    if (this.invitationsSent) {
      this.resetInvites();
    }
  }

  public goToInviteMemberScreen() {
    this.teamMembersStepper.selectedIndex = 1;
  }

  public switchToBusinessPlanClicked() {
    this.dialog.open(ChangePlanPopupComponent, {
      width: '500px',
    });
  }

  public async prepareSetMailFolders(user) {
    this.loadingMailFolders = true;
    this.selectedUser = user;
    this.userDataService
      .getTeamMemberFolders(user)
      .pipe(takeUntil(this.unsubscribeService))
      .subscribe(
        async (data: any) => {
          this.selectedUserMailFolders = data.data?.map(it => it.id);
          await this.loadUserMailFolders();
          this.mailFolderSelect.open();
          this.loadingMailFolders = false;
          this.cdr.detectChanges();
        },
        (error: any) => {
          this.loadingMailFolders = false;
          this.notificationService.showError(this.extractErrorMessage(error, 'Unable to get sub user mail folders. Please contact support.'));
          this.cdr.detectChanges();
        },
      );
    this.cdr.detectChanges();
  }

  public submitMailFolders() {
    if (!this.selectedUser || !this.selectedUserMailFolders || (this.selectedUserMailFolders.length === 1 && this.selectedUserMailFolders[0] === -1)) {
      return this.notificationService.showError('Something went wrong. Please reload the page and try again.');
    }
    this.userDataService
      .updateTeamMemberFolders(this.selectedUser, this.selectedUserMailFolders)
      .pipe(takeUntil(this.unsubscribeService))
      .subscribe(
        () => {
          this.notificationService.showSuccess('User mail folders have been successfully updated.');
        },
        (error: any) => {
          this.notificationService.showError(this.extractErrorMessage(error, 'Unable to assign mail folders to sub-user. Please contact support.'));
        },
      );
  }

  public deleteTeamMember(user) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: 'This action is permanent and cannot be undone. The deleted user will lose all access to the system and any associated data. Do you wish to proceed?',
      title: 'Are you sure you want to delete this user?',
      cancelText: 'No',
      confirmText: 'Yes, Delete!',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userDataService
          .deleteTeamMember(user)
          .pipe(takeUntil(this.unsubscribeService))
          .subscribe(
            () => {
              this.loadTeamMembers();
              this.notificationService.showSuccess('User successfully deleted. The user has been removed from the system and no longer has access.');
            },
            (error: any) => {
              this.notificationService.showError(this.extractErrorMessage(error, 'Unable to delete user. Please contact support.'));
            },
          );
      }
    });
  }

  public pauseTeamMember(user) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: 'The user will temporarily lose access to the application until it is unpaused. Do you wish to proceed?',
      title: 'Are you sure you want to pause this user?',
      cancelText: 'No',
      confirmText: 'Yes, Pause!',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userDataService
          .pauseTeamMember(user)
          .pipe(takeUntil(this.unsubscribeService))
          .subscribe(
            () => {
              this.loadTeamMembers();
              this.notificationService.showSuccess(`User successfully paused. The user's access to the application has been temporarily suspended.`);
            },
            (error: any) => {
              this.notificationService.showError(this.extractErrorMessage(error, 'Unable to pause user. Please contact support.'));
            },
          );
      }
    });
  }

  public unpauseTeamMember(user) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      message: 'The user will regain access to the application. Do you wish to proceed?',
      title: 'Are you sure you want to unpause this user?',
      cancelText: 'No',
      confirmText: 'Yes, Unpause!',
    };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userDataService
          .unpauseTeamMember(user)
          .pipe(takeUntil(this.unsubscribeService))
          .subscribe(
            () => {
              this.loadTeamMembers();
              this.notificationService.showSuccess('User successfully unpaused. The user has regained access to the application.');
            },
            (error: any) => {
              this.notificationService.showError(this.extractErrorMessage(error, 'Unable to unpause user. Please contact support.'));
            },
          );
      }
    });
  }

  public resendTeamMemberInvitation(user) {
    this.userDataService
      .resendTeamMemberInvitation(user)
      .pipe(takeUntil(this.unsubscribeService))
      .subscribe(
        () => {
          this.notificationService.showSuccess(`Invitation email successfully resent. The invitation has been resent to the recipient's email address.`);
        },
        (error: any) => {
          this.notificationService.showError(this.extractErrorMessage(error, 'Unable to resend notification. Please contact support.'));
        },
      );
  }

  public updateTeamMemberRole(user, role) {
    let title = 'Are you sure you want to grant the Manager role to this user?';
    let message = 'The user will have expanded access to perform a wider range of tasks. Do you wish to proceed?';
    let confirmText = 'Yes, Grant!';
    if (role === 'REGULAR') {
      title = 'Are you sure you want to revoke the Manager role from this user?';
      message = 'The user will have limited access and be able to perform mail-related tasks only. Do you wish to proceed?';
      confirmText = 'Yes, Revoke!';
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = { message, title, cancelText: 'No', confirmText };
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userDataService
          .updateTeamMemberRole(user, role)
          .pipe(takeUntil(this.unsubscribeService))
          .subscribe(
            () => {
              this.loadTeamMembers();
              let notificationMessage = 'Manager role successfully granted. The user now has expanded access to perform a wider range of tasks.';
              if (role === 'REGULAR') {
                notificationMessage = `Manager role successfully revoked. The user now has limited access and can perform tasks
                 related to mail items only. Don\'t forget to assign mail folder(s) to the user.`;
              }
              this.notificationService.showSuccessForDuration(notificationMessage, 10000);
            },
            (error: any) => {
              this.notificationService.showError(this.extractErrorMessage(error, 'Unable to update user role. Please contact support.'));
            },
          );
      }
    });
  }

  public sortMailFolders() {
    const search = this.mailFolderSearchTerm?.toLowerCase();
    this.sortedMailFolders.sort((a, b) => {
      if (search) {
        if (a?.name?.toLowerCase()?.indexOf(search) > -1 && b?.name?.toLowerCase()?.indexOf(search) === -1) {
          return -1;
        } else if (a?.name?.toLowerCase()?.indexOf(search) === -1 && b?.name?.toLowerCase()?.indexOf(search) > -1) {
          return 1;
        }
      }
      if (this.selectedUserMailFolders.indexOf(a?.uuid) > -1 && this.selectedUserMailFolders.indexOf(b?.uuid) === -1) {
        return -1;
      } else if (this.selectedUserMailFolders.indexOf(a?.uuid) === -1 && this.selectedUserMailFolders.indexOf(b?.uuid) > -1) {
        return 1;
      } else {
        return a.name < b.name ? -1 : 1;
      }
    });
    SharedHelpers.detectChanges(this.cdr);
  }

  public resetMailFolderSearch() {
    this.mailFolderSearchTerm = '';
    this.sortMailFolders();
  }

  public onCreateNewMailFolder() {
    const dialogRef = this.dialog.open(EditLabelDialogComponent, {
      width: '400px',
      data: { data: {}, calloutOption: {} },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadUserMailFolders();
    });
  }

  public deleteInvite(index) {
    this.invites.splice(index, 1);
  }

  public addInviteRow() {
    this.invites.push({
      email: '',
      role: 'REGULAR',
    });
  }

  public sendInvitations() {
    const emailMap = {};
    this.invites.forEach(invite => {
      if (!invite.email) {
        return;
      }
      if (emailMap[invite.email]) {
        invite.duplicate = true;
      }
      emailMap[invite.email] = true;
    });
    this.invites = this.invites.filter(it => !it.duplicate);
    const populatedInvites = this.invites.filter(it => it.email || it.name);
    if (!populatedInvites.length) {
      return this.notificationService.showError('Please fill in the Email for users you want to invite.');
    }
    if (populatedInvites.some(it => !it.email || !it.role)) {
      return this.notificationService.showError('Please make sure email addresses are populated for all users.');
    }
    if (populatedInvites.some(it => !SharedHelpers.emailPattern.test(it.email))) {
      return this.notificationService.showError('Please enter valid email address for all recipients.');
    }
    this.sendingInvitations = true;
    this.userDataService
      .inviteTeamMembers(populatedInvites)
      .pipe(takeUntil(this.unsubscribeService))
      .subscribe(
        (result: any) => {
          this.sendingInvitations = false;
          const allSuccess = result.data?.every(it => !!it.success);
          const allFailed = result.data?.every(it => !it.success);
          if (allSuccess) {
            this.notificationService.showSuccessForDuration(
              'The invitation emails have been dispatched successfully to the intended recipients. Once they accept the invitation and ' +
                'complete the registration process, the users will become active.',
              15000,
            );
          } else if (allFailed) {
            this.notificationService.showError('No users were invited as a result of the errors mentioned above.');
          } else {
            this.notificationService.showWarning('Due to the errors described above, some user(s) were not invited.');
          }
          result.data?.forEach(item => {
            const inviteItem = this.invites.find(it => it.email && it.email.toLowerCase() === item.email?.toLowerCase());
            if (inviteItem) {
              inviteItem.status = item.success ? 'success' : 'error';
              inviteItem.message = item.message;
            }
          });
          this.invitationsSent = true;
          SharedHelpers.detectChanges(this.cdr);
          this.loadTeamMembers();
        },
        (error: any) => {
          this.sendingInvitations = false;
          this.notificationService.showError(this.extractErrorMessage(error, 'Unable to invite sub users. Please contact support.'));
          SharedHelpers.detectChanges(this.cdr);
        },
      );
  }

  public resetInvites() {
    this.invites = JSON.parse(JSON.stringify(DEFAULT_INVITES));
    this.invitationsSent = false;
    SharedHelpers.detectChanges(this.cdr);
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  closeSideNav() {
    this.closeNav.emit({});
  }
}
