// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: fixed;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  left: 0;
  width: 100%;
  box-shadow: 0 9999em 0 9999em #ffffff;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/tab-bar/tab-bar.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,wCAAA;EACA,mCAAA;EACA,OAAA;EACA,WAAA;EACA,qCAAA;EACA,UAAA;AACF","sourcesContent":[":host {\n  display: block;\n  position: fixed;\n  bottom: constant(safe-area-inset-bottom);\n  bottom: env(safe-area-inset-bottom);\n  left: 0;\n  width: 100%;\n  box-shadow: 0 9999em 0 9999em #ffffff;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
