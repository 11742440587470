// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 480px;
  width: 100vw;
  height: 100%;
  background: #f2f6f7;
}
:host .report-details-container {
  margin: 20px 10px;
  background: white;
  padding: 10px 0;
}
:host .report-details-container form {
  width: 100%;
}
:host textarea.error {
  border: 2px solid red;
}
:host .usgm-green {
  font-weight: bold;
}
:host .form-control {
  padding: 10px 10px 10px 10px;
  height: 50px;
}
:host .form-control.shorter {
  height: 40px;
}
:host .form-control:disabled {
  color: #777;
  background-color: #ccc;
}
:host .report-issue-request-submitted {
  margin-top: 10%;
}
:host .report-issue-request-submitted .row {
  margin: auto;
}
:host .report-issue-request-submitted .row .title {
  font-size: 186%;
}
:host .report-issue-request-submitted .message {
  width: 50%;
  margin: auto;
}
:host .btn {
  line-height: 26px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/inbox/items-table/report-issue-popup/report-issue-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;EACA,iBAAA;EACA,eAAA;AACJ;AACI;EACE,WAAA;AACN;AAGE;EACE,qBAAA;AADJ;AAIE;EACE,iBAAA;AAFJ;AAKE;EACE,4BAAA;EACA,YAAA;AAHJ;AAKI;EACE,YAAA;AAHN;AAMI;EACE,WAAA;EACA,sBAAA;AAJN;AAQE;EACE,eAAA;AANJ;AAQI;EACE,YAAA;AANN;AAQM;EACE,eAAA;AANR;AAUI;EACE,UAAA;EACA,YAAA;AARN;AAYE;EACE,iBAAA;EACA,iBAAA;AAVJ","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 480px;\n  width: 100vw;\n  height: 100%;\n  background: #f2f6f7;\n\n  .report-details-container {\n    margin: 20px 10px;\n    background: white;\n    padding: 10px 0;\n\n    form {\n      width: 100%;\n    }\n  }\n\n  textarea.error {\n    border: 2px solid red;\n  }\n\n  .usgm-green {\n    font-weight: bold;\n  }\n\n  .form-control {\n    padding: 10px 10px 10px 10px;\n    height: 50px;\n\n    &.shorter {\n      height: 40px;\n    }\n\n    &:disabled {\n      color: #777;\n      background-color: #ccc;\n    }\n  }\n\n  .report-issue-request-submitted {\n    margin-top: 10%;\n\n    .row {\n      margin: auto;\n\n      .title {\n        font-size: 186%;\n      }\n    }\n\n    .message {\n      width: 50%;\n      margin: auto;\n    }\n  }\n\n  .btn {\n    line-height: 26px;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
