import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'usgm-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() modalId = '';
  @Input() headerTitle = '';

  constructor() {}

  ngOnInit() {}
}
