import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Animations } from '../../animations/element.animations';
import * as Services from '../../services';
import { AccountStatus } from '@usgm/usgm-payloads-library-front/Enums';
import { DialogComponent } from '../../components/dialog';
import { ScheduledAccountCloseStatus } from '../../utils/constants';

@Component({
  selector: 'usgm-close-account-popup',
  templateUrl: './close-account-popup.component.html',
  styleUrls: ['./close-account-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: Animations,
})
export class CloseAccountPopupComponent implements OnInit, OnDestroy {
  @ViewChild('closeReasonInput') closeReasonInput: ElementRef;

  private unSubscriber: Subject<any> = new Subject();
  public closeReason = '';
  public loading = false;
  public acknowledged = false;
  public acknowledged2 = false;
  public highlightCheckbox = false;
  public highlightCheckbox2 = false;
  public highlightReasonInput = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<CloseAccountPopupComponent>,
    private userDataService: Services.UserDataService,
    private apiMapping: Services.ApiMapping,
    private http: Services.UsgmHttp,
    private notificationService: Services.NotificationService,
    private matDialog: MatDialog,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {}

  public onAcknowledgementChanged() {
    this.acknowledged = !this.acknowledged;
    this.highlightCheckbox = false;
  }

  public onAcknowledgementChanged2() {
    this.acknowledged2 = !this.acknowledged2;
    this.highlightCheckbox2 = false;
  }

  private highlightErroredCheckbox() {
    this.highlightCheckbox = true;
    this.notificationService.showError('Please give your consent that you want to close your account');
  }

  private highlightErroredCheckbox2() {
    this.highlightCheckbox2 = true;
    this.notificationService.showError('Please give your consent that you want to close your account');
  }

  private highlightErroredCloseReasonInput() {
    this.highlightReasonInput = true;
    this.notificationService.showError('Please specify a reason why you want to close your account');
    this.closeReasonInput?.nativeElement?.focus();
  }

  public confirmCloseAccount() {
    if (!this.acknowledged) {
      this.highlightErroredCheckbox();
    }
    if (!this.acknowledged2) {
      this.highlightErroredCheckbox2();
    }
    if (!this.isValidCloseReason()) {
      this.highlightErroredCloseReasonInput();
    }
    if (!this.acknowledged || !this.isValidCloseReason() || !this.acknowledged2) {
      return;
    }
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.autoFocus = true;
    confirmDialogConfig.closeOnNavigation = true;
    confirmDialogConfig.data = {
      message: 'Closing your account will cancel your subscription and you will not have access to your account and mail items anymore',
      title: 'Are you sure you want to close your account?',
      cancelText: 'No',
      confirmText: 'Yes, Close!',
    };
    const dialogRef = this.matDialog.open(DialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.cdr.detectChanges();
        this.http
          .http_post(this.apiMapping.closeUserAccount(), {
            userId: this.userDataService.getUserId(),
            status: AccountStatus.CLOSED,
            closeReason: this.closeReason,
          })
          .then(response => {
            this.loading = false;
            this.notificationService.showSuccess('Your account was successfully closed');
            this.userDataService.logoutUser();
            this.dialogRef.close();
          })
          .catch(error => {
            this.loading = false;
            if (error.status === 400) {
              const secondaryDialog = this.matDialog.open(DialogComponent, {
                data: {
                  message: error?.error?.message,
                  title: 'Account could not be deleted',
                  okText: 'OK',
                },
              });
              secondaryDialog.afterClosed().subscribe((confirmation: boolean) => {
                this.dialogRef.close();
              });
            } else {
              this.notificationService.showError(
                `Your account cannot be closed because of unpaid invoices. Please add a new payment method or update your billing info to correct the problem and try again`,
              );
              this.dialogRef.close();
            }
          });
      }
    });
  }

  isValidCloseReason(): boolean {
    if (!this.closeReason) {
      return false;
    }

    if (!this.closeReason.replace(/\s/g, '')) {
      return false;
    }
    return true;
  }

  public confirmScheduledCloseAccount() {
    if (!this.acknowledged) {
      this.highlightErroredCheckbox();
    }
    if (!this.acknowledged2) {
      this.highlightErroredCheckbox2();
    }
    if (!this.isValidCloseReason()) {
      this.highlightErroredCloseReasonInput();
    }
    if (!this.acknowledged || !this.isValidCloseReason() || !this.acknowledged2) {
      return;
    }
    this.loading = true;
    this.http
      .http_post(this.apiMapping.scheduledCloseUserAccount(), {
        closeReason: this.closeReason,
        status: ScheduledAccountCloseStatus.ACTIVE,
      })
      .then(response => {
        this.loading = false;
        this.dialogRef.close({ event: 'scheduledCancellation', date: response.data.closure_date });
      })
      .catch(error => {
        this.loading = false;
        this.dialogRef.close();
        this.notificationService.showError(error?.error?.message || error?.message);
      });
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.unSubscriber.next({});
    this.unSubscriber.complete();
  }
}
