import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as Services from '../../../services';
import { retry, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { VerifyAccountService } from '../../../verify-account/verify-account.service';
import * as SharedHelpers from '../../../utils/helpers';
import { OnboardFormResponse, Relation } from '../../../models/get-notarized.model';
import { environment } from '../../../../environments/environment';
import { ExternalScripts } from '../../../services/scripts/script.store';
import { MatDialog } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CaptchaBaseComponent } from '../../../captcha-base-component/captcha-base-component.component';

declare var Calendly;

@Component({
  selector: 'usgm-notarize-with-usgm-mobile',
  templateUrl: './notarize-with-usgm.component.html',
  styleUrls: ['./notarize-with-usgm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotarizeWithUsgmMobileComponent extends CaptchaBaseComponent implements OnInit {
  public selectedId: string;
  public settingPageLoad: boolean;
  public userNames = [];
  public meetScheduled = false;
  public fileSize = 10 * 1024 * 1024;
  public allowedTypes = ['application/pdf'];
  public formList = [];

  private unSubscriber: Subject<any> = new Subject();
  @Input() data;
  @Input() loading;
  @Input() selectedUser;
  @Input() newNotaryRequestApplicableUser = [];
  @Output() goToNextPage = new EventEmitter<any>();
  @Output() forScreenBackNavigation = new EventEmitter<any>();
  @ViewChild('container', { read: ElementRef, static: false }) container: ElementRef;

  constructor(
    protected verifyService: Services.VerifyAccountService,
    private cdRef: ChangeDetectorRef,
    public override notificationService: Services.NotificationService,
    protected verifyAccountService: VerifyAccountService,
    protected userDataService: Services.UserDataService,
    protected scriptService: Services.ScriptService,
    protected apiMapping: Services.ApiMapping,
    private http: Services.UsgmHttp,
    public override dialog: MatDialog,
    public override recaptchaV3Service: ReCaptchaV3Service,
  ) {
    super(dialog, notificationService, recaptchaV3Service);
  }

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    if ((event.data || {}).event === 'calendly.event_scheduled') {
      this.meetScheduled = true;
      Calendly.closePopupWidget();
      this.createZohoDocumentAndSaveNotaryRequest();
    }
  }

  onBack() {
    this.forScreenBackNavigation.emit({ page: 'VIEW_FORM' });
  }
  public override ngOnInit() {
    this.getAddedName();
    if (!this.data) {
      this.publicGetNotaryWithUsgmPlan();
    }
    const body = <HTMLDivElement>document.body;

    const l = document.createElement('link');
    l.rel = 'stylesheet';
    l.href = 'https://assets.calendly.com/assets/external/widget.css';
    body.appendChild(l);

    this.scriptService
      .load(ExternalScripts.CALENDLY_WIDGET)
      .then(data => {
        console.log('Calendly widget script dynamically loaded', data);
      })
      .catch(error => console.log(error));
  }

  publicGetNotaryWithUsgmPlan() {
    this.verifyService
      .getnotaryPlans(this.apiMapping.getNotaryPlans())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(
        apiResponse => {
          this.data = apiResponse.plan.find(p => p.plan_type === 'NOTARYUSGM');
          this.cdRef.detectChanges();
        },
        error => {
          this.notificationService.showError(error.message);
        },
      );
  }

  public scheduleMeet() {
    const userName = this.userDataService.getUserFullName();
    const userEmail = this.userDataService.getUserEmail();
    Calendly.initPopupWidget({ url: `${environment.calendlyUrl}&name=${userName}&email=${userEmail}` });
  }

  public getAddedName(): void {
    this.verifyService
      .getName(this.apiMapping.getAccountNamesWithOnlyStatus())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(apiResponse => {
        if (apiResponse) {
          for (const user of apiResponse.data.accountNames) {
            if (user.is_from_account_verification && user.relation.relationType === Relation.MINOR_CHILD) {
              this.userNames = this.userNames.map(item => {
                if (item.relation === Relation.PRIMARY) {
                  item.name += `, ${user.user.name} (${user.relation.relationType})`;
                }
                return item;
              });
            } else if (user.relation.relationType !== Relation.COMPANY) {
              const userForm = this.formList.find(item => item.user_id === user.user.uuid);
              if (userForm && userForm.form1583) {
                this.userNames.push({
                  name: user.user.name + ` (${user.relation.relationType})`,
                  id: user.user.uuid,
                  relation: user.relation.relationType,
                });
              }
            }
          }
          this.cdRef.detectChanges();
        }
      });
  }

  clearFormValue(): void {
    if (this.newNotaryRequestApplicableUser.length) {
      this.forScreenBackNavigation.emit({ page: 'NOTARY_OPTIONS_LISTING_MULTIPLE' });
    } else {
      this.forScreenBackNavigation.emit({ page: 'NOTARY_OPTIONS_LISTING' });
    }
  }

  completeVerificationProcess() {
    this.createZohoDocumentAndSaveNotaryRequest();
  }

  public async createZohoDocumentAndSaveNotaryRequest() {
    try {
      this.loading = true;
      this.cdRef.detectChanges();
      // UNI-1525 - no need to automatically create zoho sign document on calendly schedule
      // await this.verifyService.createSignNowDocuments(this.apiMapping.createSignNowDocuments(this.selectedId));
      await this.verifyService
        // tslint:disable-next-line: max-line-length
        .saveNotaryRequestPromise(this.apiMapping.saveNotaryRequest(this.data.uuid), {
          notaryRequestForUsers: this.newNotaryRequestApplicableUser.length ? this.newNotaryRequestApplicableUser : [this.selectedUser],
        });
      this.loading = false;
      this.cdRef.detectChanges();
      if (this.newNotaryRequestApplicableUser.length) {
        this.goToNextPage.emit({ page: 'MANAGE_ACCOUNT_NAME' });
      } else {
        this.goToNextPage.emit({ page: 'UPLOAD_NOTARISED_FORM' });
      }
    } catch (error) {
      this.loading = false;
      this.cdRef.detectChanges();
      this.notificationService.showError(error.message);
    }
  }

  onFileDropped(fileEvent: Array<any>, userId) {
    this.validateFile(fileEvent, userId);
  }

  validateFile(fileEvent: File[], userId) {
    if (userId) {
      const userName = this.userNames.find(item => item.id === userId);
      userName.isSubmitted = false;
    }
    const files = fileEvent;
    const file = fileEvent[0];
    if (file) {
      if (!this.allowedTypes.includes(file.type)) {
        this.notificationService.showWarning('Allowed types is pdf only');
        return false;
      } else if (file.size > this.fileSize) {
        this.notificationService.showWarning(`upload file will be less than ${this.fileSize / 1024 / 1024} MB.`);
        return false;
      }
      this.readURL(file, userId);
    }
  }

  readURL(file, userId) {
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        file.data = e.target.result;
        for (const user of this.userNames) {
          if (user.id === userId) {
            user.userFile = file;
            break;
          }
        }
        this.cdRef.detectChanges();
      };
      reader.readAsDataURL(file);
    }
  }

  async downloadForm1583FromServer() {
    if (this.newNotaryRequestApplicableUser.length) {
      for (const user of this.newNotaryRequestApplicableUser) {
        this.downloadForm1583(user);
      }
    } else {
      this.downloadForm1583();
    }
  }

  downloadForm1583(userId?: string) {
    this.loading = true;
    this.verifyService
      .getUserFormData(this.apiMapping.getUserOnboardForm(userId || this.selectedUser))
      .pipe(takeUntil(this.unSubscriber), retry(2))
      .subscribe(
        (response: OnboardFormResponse) => {
          this.loading = false;
          if (response?.form?.form_url) {
            this.downloadFormsFirstCall(this.downLoadForm.bind(this), response);
          }
          this.cdRef.detectChanges();
        },
        _error => {
          this.loading = false;
          this.cdRef.detectChanges();
          this.notificationService.showError('Unable to view file.');
        },
      );
  }

  downLoadForm(token, captchaVersion, response) {
    this.http.get(this.apiMapping.downloadForms(response.form.id, token, captchaVersion), { responseType: 'blob' }).subscribe(
      result => {
        const fileName = response.form.form_url.split('/').pop().split('#')[0].split('?')[0];
        SharedHelpers.downloadFile(result, fileName);
        this.notificationService.showWarning('File downloaded.');
        SharedHelpers.detectChanges(this.cdRef);
      },
      error => {
        if (error.status === 422) {
          this.verifyCaptcha(this.downLoadForm.bind(this), response);
        } else {
          this.notificationService.showError('Unable to downloaded form.');
        }
      },
    );
  }
}
