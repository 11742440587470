import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MatMenuModule } from '@angular/material/menu';
import { CarouselComponent } from './carousel.component';
import { CarouselItemDirective } from './carousel-item.directive';
import { CarouselItemElementDirective } from './carousel-item-element.directive';

@NgModule({
  declarations: [CarouselComponent, CarouselItemDirective, CarouselItemElementDirective],
  imports: [BrowserModule, CommonModule, MatMenuModule],
  exports: [CarouselComponent, CarouselItemDirective, CarouselItemElementDirective],
})
export class CarouselModule {}
