// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 480px;
}
:host .reason-field {
  min-height: 100px;
  width: 100%;
  border-color: #a8a8a8;
  padding: 10px;
}
:host .reason-field:focus {
  outline: none;
  border-color: #a8a8a8;
}
:host .card-box {
  overflow: hidden;
}
:host .card-box ::ng-deep {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
}
:host .card-box ::ng-deep .mat-checkbox-inner-container {
  margin-top: 5px;
}
:host .flex-col {
  display: flex;
  flex-direction: column;
}
:host .margin-top {
  margin-top: 12px;
  margin-left: 0px !important;
}
:host .height-40 {
  height: 40px;
}
:host .font-size-114 {
  font-size: 114%;
}
:host .margin-to-14 {
  margin-top: 14px;
}
:host .usgm-lightest.error,
:host .col-form-label.error {
  color: red !important;
}
:host .close-button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fefefe;
  color: #000;
}
@media (max-width: 767px) {
  :host .mat-button.btn-secondary {
    height: 50px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/close-account-popup/close-account-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;AACF;AACE;EACE,iBAAA;EACA,WAAA;EACA,qBAAA;EACA,aAAA;AACJ;AAEE;EACE,aAAA;EACA,qBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAGI;EAEE,+HAAA;AAFN;AAGM;EACE,eAAA;AADR;AAME;EACE,aAAA;EACA,sBAAA;AAJJ;AAOE;EACE,gBAAA;EACA,2BAAA;AALJ;AAQE;EACE,YAAA;AANJ;AASE;EACE,eAAA;AAPJ;AAUE;EACE,gBAAA;AARJ;AAWE;;EAEE,qBAAA;AATJ;AAYE;EACE,kBAAA;EACA,QAAA;EACA,MAAA;EACA,yBAAA;EACA,WAAA;AAVJ;AAaE;EAEI;IACE,YAAA;EAZN;AACF","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 480px;\n\n  .reason-field {\n    min-height: 100px;\n    width: 100%;\n    border-color: #a8a8a8;\n    padding: 10px;\n  }\n\n  .reason-field:focus {\n    outline: none;\n    border-color: #a8a8a8;\n  }\n\n  .card-box {\n    overflow: hidden;\n\n    ::ng-deep {\n\n      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */\n      .mat-checkbox-inner-container {\n        margin-top: 5px;\n      }\n    }\n  }\n\n  .flex-col {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .margin-top {\n    margin-top: 12px;\n    margin-left: 0px !important;\n  }\n\n  .height-40 {\n    height: 40px;\n  }\n\n  .font-size-114 {\n    font-size: 114%;\n  }\n\n  .margin-to-14 {\n    margin-top: 14px;\n  }\n\n  .usgm-lightest.error,\n  .col-form-label.error {\n    color: red !important;\n  }\n\n  .close-button {\n    position: absolute;\n    right: 0;\n    top: 0;\n    background-color: #fefefe;\n    color: #000;\n  }\n\n  @media (max-width: 767px) {\n    .mat-button {\n      &.btn-secondary {\n        height: 50px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
