import { Component, OnInit, Inject, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'usgm-online-notarization-popup',
  templateUrl: './online-notarization-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnlineNotarizationPopupComponent implements OnInit, OnDestroy {
  @Input() item;
  public timeRemaining = 5;
  public interval;
  public url;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<OnlineNotarizationPopupComponent>,
  ) {
    this.url = data.url;
  }

  public ngOnInit() {}

  openNewTab() {
    window.open(this.url, '_blank');
    this.dialogRef.close('success');
  }

  public ngOnDestroy() {}
}
