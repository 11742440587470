import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UserSetting } from '@usgm/usgm-payloads-library-front/Enums/Settings';

import { Subject } from 'rxjs';
import * as Services from '../../services';
import { takeUntil } from 'rxjs/operators';
import { SettingsModel } from '../../models/settings.model';
import * as SharedHelpers from '../../utils/helpers';
import { mapUserScanBundle } from '../../utils/helpers';
import { ScanBundle } from '../manage-scan-bundles/manage-scan-bundles.component';

@Component({
  selector: 'usgm-scanning-options',
  templateUrl: './scanning-options.component.html',
  styleUrls: ['./scanning-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanningOptionsComponent implements OnInit, OnDestroy {
  @Output() closeNav = new EventEmitter();

  public pageLoading: boolean;
  private userId: string;
  private userSettings: SettingsModel;
  private unSubscriber: Subject<any> = new Subject();
  public loading = false;

  public lettersSelected: boolean;
  public largeLettersSelected: boolean;

  public userSelectedScanBundle: ScanBundle = null;

  constructor(
    private http: Services.UsgmHttp,
    private apiMapping: Services.ApiMapping,
    private userDataService: Services.UserDataService,
    private notificationService: Services.NotificationService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.userId = this.userDataService.getUserId();
    void this.loadSettings();
  }

  async loadSettings() {
    this.pageLoading = true;
    SharedHelpers.detectChanges(this.cdRef);
    try {
      await Promise.all([this.getUserScanBundleData(), this.getUserSettings()]);
    } finally {
      this.pageLoading = false;
      SharedHelpers.detectChanges(this.cdRef);
    }
  }

  async getUserSettings() {
    const response = await this.userDataService.getShipmentSettings(UserSetting.ALL).toPromise();
    if (response && response.UserSettings) {
      this.userSettings = response.UserSettings;
      if (response.UserSettings.scan_options) {
        this.largeLettersSelected = response.UserSettings.scan_options.auto_scan_large_letters;
        this.lettersSelected = response.UserSettings.scan_options.auto_scan_letters;
      }
    }
    SharedHelpers.detectChanges(this.cdRef);
  }

  async getUserScanBundleData() {
    try {
      const userScanBundle: any = await this.http.get(this.apiMapping.getUserScanBundle()).toPromise();
      this.userSelectedScanBundle = mapUserScanBundle(userScanBundle);
    } catch (error) {
      this.notificationService.showError(error?.message || 'Error while loading configurations.');
    }
    SharedHelpers.detectChanges(this.cdRef);
  }

  public submitRequest() {
    this.loading = true;
    const scan_options = {
      auto_scan_letters: this.lettersSelected,
      auto_scan_large_letters: this.largeLettersSelected,
    };
    const params = {
      scan_options,
    };
    let type;
    let apiRequest;
    if (this.userSettings?.uuid) {
      type = 'update';
      apiRequest = this.userDataService.updateShipmentData(this.userId, this.userSettings.uuid, params);
    } else {
      type = 'save';
      apiRequest = this.userDataService.saveShipmentData(this.userId, params);
    }
    apiRequest.pipe(takeUntil(this.unSubscriber)).subscribe(
      response => {
        this.loading = false;
        this.notificationService.showSuccess('Your changes have been saved successfully.');
        this.closeSideNav();
      },
      err => {
        this.notificationService.showError('Unable to ' + type + ' scan settings');
        this.loading = false;
      },
    );
  }

  public closeSideNav() {
    this.closeNav.emit({});
  }

  ngOnDestroy(): void {
    this.unSubscriber.next({});
    this.unSubscriber.complete();
  }
}
