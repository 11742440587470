import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserDataService } from './user-data.service';
import { AppRoutes } from '../models/constants/app-routes.constant';
import { NotificationService } from './notification.service';
import { AccountStatus } from '@usgm/usgm-payloads-library-front/Enums';

@Injectable()
export class RouteGuardService implements CanActivate {
  constructor(
    private userDataService: UserDataService,
    private router: Router,
    private notificationService: NotificationService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(resolve => {
      resolve(this.checkIfCanActivate(route, state));
    });
  }

  checkIfCanActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const pathToActivate = route.url[0].path.split('?')[0];
    if (!this.userDataService.isSessionValid()) {
      this.router.navigate([AppRoutes.login]);
      return false;
    } else if (!this.userDataService.userSubscribed()) {
      if (pathToActivate !== AppRoutes.paymentInfo && pathToActivate !== AppRoutes.selectWarehouse) {
        this.router.navigate([AppRoutes.paymentInfo]);
        return false;
      } else {
        return true;
      }
    } else if (this.userDataService.shouldVerifyAccount()) {
      if (!this.userDataService.preventInboxNavigation()) {
        return true;
      }
      if ([AppRoutes.paymentComplete, AppRoutes.welcome, AppRoutes.settings, AppRoutes.billingHistory, AppRoutes.submitVerificationDocuments].includes(pathToActivate)) {
        return true;
      }
      if (pathToActivate !== AppRoutes.verifyAccount) {
        this.notificationService.showWarning('Please complete account setup to access this page.');
        this.router.navigate([AppRoutes.verifyAccount]);
        return false;
      } else {
        return true;
      }
    } else if (pathToActivate === AppRoutes.welcome) {
      this.router.navigate([AppRoutes.inbox]); // user already verified
      return false;
    } else if (this.userDataService.getAccountStatus() === AccountStatus.CLOSED) {
      if (pathToActivate !== AppRoutes.billingHistory) {
        this.router.navigate([AppRoutes.billingHistory]); // user already verified
        return false;
      }
      return true;
    } else {
      localStorage.setItem('redirectTo', route.url[0].path);
      return true;
    }
  }
}
