import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment-timezone';
import * as Services from '../../services';
import * as SharedHelpers from '../../utils/helpers';
import { PortalSections } from '../../models/constants/portal-sections';
import { Router } from '@angular/router';
import { AppRoutes } from '../../models/constants/app-routes.constant';
import { LineItemType } from '@usgm/usgm-payloads-library-front';

@Component({
  selector: 'usgm-invoice-details-popup',
  templateUrl: './invoice-details-popup.component.html',
  styleUrls: ['./invoice-details-popup.component.scss', '../billing-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDetailsPopupComponent implements OnInit, OnDestroy {
  public downloading = false;
  public shipmentid: any = null;
  public loadingPayNow = false;

  @Input() invoice: any = {};
  @Input() details: any = {};
  @Output() closeNav = new EventEmitter();
  @Output() refreshInvoices = new EventEmitter();

  constructor(
    protected cdr: ChangeDetectorRef,
    private http: Services.UsgmHttp,
    private notificationService: Services.NotificationService,
    private userDataService: Services.UserDataService,
    private apiMapping: Services.ApiMapping,
    private router: Router,
  ) {}

  ngOnInit() {}

  @Input() set shipmentId(value) {
    if (value !== this.shipmentid) {
      this.shipmentid = value;
      SharedHelpers.detectChanges(this.cdr);
    }
  }

  downloadPdf() {
    if (this.userDataService.isMobileMode) {
      this.notificationService.showWarning('Opening pdf in new tab. Please save in desired location');
    }
    this.downloading = true;
    this.http.http_get(this.apiMapping.getInvoicePdfUrl(this.invoice.id)).then(
      result => {
        window.open(result.download_url, '_system');
        this.downloading = false;
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.downloading = false;
        this.notificationService.showError('Unable to download invoice. Please try again.');
      },
    );
  }

  closeSideNav() {
    this.closeNav.emit({});
  }

  public statusFormatted(statusStr) {
    return statusStr.split('_').join(' ');
  }

  public onChangePaymentMethod() {
    const { portalInstance, subscriptionId } = this.userDataService.getPortalInstance();
    if (portalInstance) {
      portalInstance.openSection({
        sectionType: PortalSections.PAYMENT_SOURCES,
        params: { subscriptionId },
      });
    }
  }

  public onPayNow() {
    this.loadingPayNow = true;
    const requestPayload = {
      user_id: this.userDataService.getUserId(),
      vendor_invoice_id: this.invoice.id,
    };
    this.http.http_post(this.apiMapping.collectPaymentForInvoice(), requestPayload).then(
      (data: any) => {
        this.loadingPayNow = false;
        this.notificationService.showSuccess('Invoice paid successfully');
        this.router.navigate([AppRoutes.billingHistory]);
        this.refreshInvoices.emit({});
        this.closeSideNav();
        this.cdr.detectChanges();
      },
      (error: any) => {
        this.loadingPayNow = false;
        this.notificationService.showError('Payment failed. Please make sure you provided correct credit card details');
        this.cdr.detectChanges();
      },
    );
  }

  public constructLineItemDescription(lineItem: any): string {
    if (lineItem?.line_item_type === LineItemType.STORAGE_FEES) {
      const dateFrom: string = moment(lineItem.date_from * 1000).format('DD MMM YYYY');
      const dateTo: string = moment(lineItem.date_to * 1000).format('DD MMM YYYY');
      const unitAmount = lineItem.unit_amount / 100; //dollars
      let result = `Storage charges for mail item ${lineItem.request_id} for the period of ${dateFrom} to ${dateTo}`;
      result += `<br/>Charged for: ${lineItem.quantity} day${lineItem.quantity > 1 ? 's' : ''}`;
      result += `<br/>Storage price per day: $${unitAmount}`;
      return result;
    }
    return `${lineItem?.description}${lineItem?.quantity > 1 ? ' x' + lineItem.quantity : ''}`;
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
