import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UserDataService, NotificationService } from '../../services';

const GOOGLE_MAPS_SEARCH_BASE_URL = 'https://google.com/maps/search';
const WEEK_DAYS = [
  { id: 1, name: 'MON' },
  { id: 2, name: 'TUE' },
  { id: 3, name: 'WED' },
  { id: 4, name: 'THU' },
  { id: 5, name: 'FRI' },
  { id: 6, name: 'SAT' },
  { id: 7, name: 'SUN' },
];

@Component({
  selector: 'usgm-get-help',
  templateUrl: './get-help.component.html',
  styleUrls: ['./get-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetHelpComponent implements OnInit, OnDestroy {
  public emailAddress: string;
  public phoneNumber: string;
  private warehouseAddress: any;
  public warehouseOperationHours: any;
  public mobileMode = false;

  @Output() closeNav = new EventEmitter();

  constructor(
    protected cdr: ChangeDetectorRef,
    private userDataService: UserDataService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    this.mobileMode = this.userDataService.isMobileMode;
    this.emailAddress = this.userDataService.warehouseEmail;
    this.phoneNumber = this.userDataService.warehousePhone;
    this.warehouseAddress = this.userDataService.getWarehouseAddress();
    this.warehouseOperationHours = this.userDataService.warehouseOperationHours;
    if (this.warehouseOperationHours && !Array.isArray(this.warehouseOperationHours)) {
      this.warehouseOperationHours = [this.warehouseOperationHours];
    }
  }

  copiedToClipboard(event) {
    this.notificationService.showSuccess('Info copied to clipboard');
  }

  closeSideNav() {
    this.closeNav.emit({});
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  getAddressSearchURL() {
    if (!this.warehouseAddress) {
      return '';
    }
    return `${GOOGLE_MAPS_SEARCH_BASE_URL}/${encodeURIComponent(this.formatWarehouseAddress())}`;
  }

  formatWarehouseAddress() {
    if (!this.warehouseAddress) {
      return 'N/A';
    }
    return `${this.warehouseAddress.address_line}, ${this.warehouseAddress.city}, ${this.warehouseAddress.state}, ${this.warehouseAddress.postal_code}`;
  }

  groupWarehouseOperationHours() {
    const weekDaysMap = {};
    (this.warehouseOperationHours || []).forEach(hours => {
      weekDaysMap[hours.day] = weekDaysMap[hours.day] || [];
      weekDaysMap[hours.day].push(hours.start + ' - ' + hours.end);
    });
    return WEEK_DAYS.map(dayData => ({ day: dayData.name, hours: weekDaysMap[dayData.id] || ['closed'] }));
  }
}
