// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  min-height: 100vh;
  background: white;
  font-family: "FiraSans";
}
:host .p-heading {
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
  color: #4C4C4C;
}
:host p.regular-text {
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: #4C4C4C;
}
:host div.regular-text {
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: #4C4C4C;
}
:host .padding-top-bottom {
  padding-top: 119px;
  padding-bottom: 119px;
}
:host .btn-secondary {
  margin-top: 36px;
  width: 212px;
  height: 56px;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "FiraSans";
  background-color: #4386FC !important;
}
:host button.btn.btn-secondary:hover {
  background-color: #3872fc !important;
}`, "",{"version":3,"sources":["webpack://./src/app/email-unsubscribe/email-unsubscribe.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,uBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAGE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADJ;AAIE;EACE,kBAAA;EACA,qBAAA;AAFJ;AAKE;EACE,gBAAA;EACA,YAAA;EACA,YAAA;EACA,0BAAA;EACA,eAAA;EACA,uBAAA;EACA,oCAAA;AAHJ;AAME;EACE,oCAAA;AAJJ","sourcesContent":[":host {\n  display: block;\n  min-height: 100vh;\n  background: white;\n  font-family: 'FiraSans';\n\n  .p-heading {\n    font-weight: 400;\n    font-size: 40px;\n    line-height: 52px;\n    color: #4C4C4C;\n  }\n\n  p.regular-text {\n    font-weight: 300;\n    font-size: 24px;\n    line-height: 36px;\n    color: #4C4C4C;\n  }\n\n  div.regular-text {\n    font-weight: 300;\n    font-size: 24px;\n    line-height: 36px;\n    color: #4C4C4C;\n  }\n\n  .padding-top-bottom {\n    padding-top: 119px;\n    padding-bottom: 119px;\n  }\n\n  .btn-secondary {\n    margin-top: 36px;\n    width: 212px;\n    height: 56px;\n    text-transform: capitalize;\n    font-size: 16px;\n    font-family: 'FiraSans';\n    background-color: #4386FC !important;\n  }\n\n  button.btn.btn-secondary:hover {\n    background-color: #3872fc !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
