import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'usgm-settings-onboarding-progress-line',
  templateUrl: './progress-line.component.html',
  styleUrls: ['./progress-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsOnboardingProgressLineComponent {
  public steps = [];
  constructor(protected cdr: ChangeDetectorRef) {}

  @Input() set data(value: []) {
    if (value !== null && value !== undefined) {
      this.steps = value;
      this.cdr.detectChanges();
    }
  }
}
