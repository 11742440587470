import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { IonicModule } from '@ionic/angular';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { FileSaverModule } from 'ngx-filesaver';
import { ComponentsModule } from '../components';
import { DirectivesModule } from '../directives';
import { ScanDetailsPopupComponent } from './scan-details-popup/scan-details-popup.component';
import { ScansListTableComponent } from './scans-list-table/scans-list-table.component';
import { ScansListComponent } from './scans-list.component';

@NgModule({
  declarations: [ScanDetailsPopupComponent, ScansListComponent, ScansListTableComponent],
  imports: [
    BrowserModule,
    CommonModule,
    ComponentsModule,
    DirectivesModule,
    FileSaverModule,
    FormsModule,
    IonicModule,
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatTooltipModule,
    PinchZoomModule,
    ReactiveFormsModule,
    RouterModule,
    VirtualScrollerModule,
  ],
  exports: [ScanDetailsPopupComponent, ScansListComponent, ScansListTableComponent],
})
export class ScansListModule {}
