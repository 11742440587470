import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, Input, EventEmitter } from '@angular/core';
import * as Services from '../../services';
import * as SharedHelpers from '../../utils/helpers';

@Component({
  selector: 'usgm-notarize-form',
  templateUrl: './notarize-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotarizeFormComponent implements OnInit {
  @Output() formType = new EventEmitter<string>();
  @Output() navigateBackToForm = new EventEmitter<any>();
  @Input() notaryPlans = [];

  public selectedId: string;
  public settingPageLoad: boolean;

  constructor(
    protected verifyService: Services.VerifyAccountService,
    protected apiMapping: Services.ApiMapping,
    protected notificationService: Services.NotificationService,
    protected cdr: ChangeDetectorRef,
    protected userDataService: Services.UserDataService,
  ) {}

  public ngOnInit() {
    this.verifyService.settingsNameId.subscribe(value => {
      if (value) {
        this.settingPageLoad = value.isSettings;
        this.selectedId = value.id;
      } else {
        this.settingPageLoad = false;
        this.selectedId = null;
      }
      SharedHelpers.detectChanges(this.cdr);
    });
  }

  sendFormType(type): void {
    this.formType.emit(type);
  }

  public sendNotaryRequest(planType): void {
    this.sendFormType(planType.toLowerCase());
    SharedHelpers.detectChanges(this.cdr);
  }

  onBack() {
    this.navigateBackToForm.emit();
  }
}
