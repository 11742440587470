import { memoize } from 'lodash';

type TFunc = (...args: any) => any;

export function expirableMemoize(func: TFunc, cacheDurationInSeconds: number, argsAreAlwaysPrimitives: boolean): TFunc {
  const memoizeFunc = memoize(func, (...keyArgs) => keyResolver(argsAreAlwaysPrimitives, ...keyArgs));
  const timeCache = new Map<string, number>();

  return (...args) => {
    const key = keyResolver(argsAreAlwaysPrimitives, args);
    const currentTimestamp = Date.now();
    if (!timeCache.has(key)) {
      timeCache.set(key, currentTimestamp);
      return memoizeFunc(...args);
    }

    // tslint:disable-next-line:no-non-null-assertion
    const timeDiff = (currentTimestamp - timeCache.get(key)!) / 1000;
    if (timeDiff < cacheDurationInSeconds) {
      return memoizeFunc(...args);
    }
    memoizeFunc.cache.delete(key);
    timeCache.set(key, currentTimestamp);
    return memoizeFunc(...args);
  };
}

export function keyResolver(argsAreAlwaysPrimitives: boolean, ...args: any[]): string {
  if (argsAreAlwaysPrimitives) {
    return args.join('][');
  }
  return JSON.stringify(args);
}
