// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 500px;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.reject-color {
  color: red;
}

.mobile-form {
  display: block !important;
}

.close-error-box {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}

.font-size-12 {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/onboarding/manage-account-names/manage-account-names.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,MAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,UAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 500px;\n  width: 100%;\n  height: 100%;\n  padding: 5px;\n}\n\n.reject-color {\n  color: red;\n}\n\n.mobile-form {\n  display: block !important;\n}\n\n.close-error-box {\n  position: absolute;\n  right: 0;\n  top: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  z-index: 2;\n  cursor: pointer;\n}\n\n.font-size-12 {\n  font-size: 12px\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
