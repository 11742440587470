import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { FamilyNameModel } from '../../models/address-model';
import * as Services from '../../services';
import { VerifyAccountService } from '../verify-account.service';
import { Relation } from '../../models/get-notarized.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ACCOUNT_NAMES_HARD_LIMIT } from '../../utils/constants';
import { DialogComponent } from '../../components/dialog';
import { environment } from '../../../environments/environment';
import { renderRelation } from '../../utils/helpers';

@Component({
  selector: 'usgm-upgrade-family-plan',
  templateUrl: './upgrade-family-plan.component.html',
  styleUrls: ['./upgrade-family-plan.component.scss'],
})
export class UpgradeFamilyPlanComponent implements OnInit {
  protected readonly renderRelation = renderRelation;
  public familyNameList: FamilyNameModel[] = [];
  public relationTypes: any[] = [];
  public loading: boolean;
  public errorAddingName = false;
  public selectedId: string;
  public settingPageLoad: boolean;
  public num = Number;
  public maxNamesAdd: number;
  public costOfExtraAccountNamePerMonth = 5; // USD

  @Output() deleteEvent = new EventEmitter<any>();
  @Output() addMoreEvent = new EventEmitter<string>();
  @Output() resetIdsEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() updateSubscription: EventEmitter<boolean> = new EventEmitter();
  @Output() updateFamilyNameList = new EventEmitter<any>();
  @Output() changePlanEvent = new EventEmitter<any>();
  @Input() headerMessage: string;
  @Input() fromSettings;

  @Input() set familyNameListCompleteList(value: any[]) {
    this.familyNameList = value;
    if (this.settingPageLoad && this.selectedId) {
      const selectedUser = this.familyNameList.find(item => item.id === this.selectedId);
      if (selectedUser && selectedUser.description === Relation.COMPANY) {
        this.secondaryUserDescriptionChangeOnEdit(selectedUser.description, this.selectedId);
      }
    }
  }

  @Input() set maxNames(value: number) {
    if (value && this.maxNamesAdd !== value) {
      this.maxNamesAdd = value;
      this.cdRef.detectChanges();
    }
  }

  constructor(
    private matDialog: MatDialog,
    protected matStepper: MatStepper,
    protected verifyService: Services.VerifyAccountService,
    private http: Services.UsgmHttp,
    protected apiMapping: Services.ApiMapping,
    protected userDataService: Services.UserDataService,
    protected notificationService: Services.NotificationService,
    protected verifyAccountService: VerifyAccountService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {
    this.relationTypes.push(Relation.ADULT, Relation.MINOR_CHILD, Relation.DECEASED);
  }

  public ngOnInit() {
    this.verifyService.settingsNameId.subscribe(value => {
      if (value) {
        this.settingPageLoad = value.isSettings;
        this.selectedId = value.id;
      } else {
        this.settingPageLoad = false;
        this.selectedId = null;
      }
      if (this.settingPageLoad && !this.selectedId) {
        this.familyNameList = [new FamilyNameModel()];
        this.familyNameList[0].name = '';
      }
      this.cdRef.detectChanges();
    });
  }

  relationType(rel) {
    if (rel.toLowerCase() === `child (18+)`) {
      rel = `child`;
    }
    return rel.toUpperCase().split(' ').join('_');
  }

  @Input() set planType(value) {
    if (value === 'business' || value === 'pay_as_you_go') {
      this.relationTypes.push(Relation.COMPANY, Relation.TRUST);
    }
  }

  onAddingNames(): void {
    if (this.loading) {
      return;
    }
    const payload = [];
    for (let i = 0; i < this.familyNameList.length; i++) {
      if (!this.familyNameList[i].name && !this.familyNameList[i].description) {
        if (this.familyNameList.length > 1) {
          this.familyNameList.splice(i, 1);
        }
      }
      if (this.familyNameList[i] && (!this.familyNameList[i].name || !this.familyNameList[i].description)) {
        this.notificationService.showWarning(
          !this.familyNameList[i].name ? `Please add name for user number ${i + 1} !!` : `Please select description for user number ${i + 1} !!`,
        );
        return;
      }
      if (this.familyNameList[i]) {
        payload.push({
          childId: this.familyNameList[i].id,
          relation: this.relationType(this.familyNameList[i].description),
          notes: null,
          name: this.familyNameList[i].name,
          is_from_account_verification: !this.fromSettings,
          is_added_before_account_verification_completion: !this.userDataService.onboardingCompleted,
        });
      }
    }
    if (payload.length) {
      this.loading = true;
      this.addNameAPI(payload);
    }
  }

  async addNameAPI(payload) {
    this.verifyService.addName(this.apiMapping.saveAccountNames(), payload).subscribe(
      apiResponse => {
        if (apiResponse) {
          if (apiResponse.userIds && apiResponse.userIds.length > 0 && this.settingPageLoad) {
            const id = this.selectedId || apiResponse.userIds[apiResponse.userIds.length - 1];
            this.verifyService.settingsNameId.next({
              isSettings: true,
              id,
            });
          }
          setTimeout(() => {
            document.querySelector('.inbox-container').scrollTop = 0;
            this.verifyAccountService.nextCount();
            this.updateFamilyNameList.emit();
            this.loading = false;
            this.matStepper.next();
            this.cdRef.detectChanges();
          }, 100);
        }
      },
      error => {
        this.loading = false;
        this.cdRef.detectChanges();
        this.notificationService.showError(error?.error?.message || 'Unable to add the account name !!');
      },
    );
  }

  callDelete(index: number): void {
    const data = {
      index: index,
      type: 'family',
    };
    this.deleteEvent.next(data);
    this.errorAddingName = this.maxNamesAdd && this.maxNamesAdd < this.familyNameList.length;
    this.cdRef.detectChanges();
  }

  closeErrorBox() {
    this.errorAddingName = false;
    this.cdRef.detectChanges();
  }

  callAddMore(): void {
    if (this.familyNameList?.length >= ACCOUNT_NAMES_HARD_LIMIT) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.closeOnNavigation = true;
      dialogConfig.data = {
        message: `Please contact support if you need to add more than ${ACCOUNT_NAMES_HARD_LIMIT} account names`,
        title: 'You have reached the global limit of account names',
        confirmText: 'Contact Support',
        showCloseIcon: true,
      };
      const dialogRefContactSupport = this.matDialog.open(DialogComponent, dialogConfig);
      dialogRefContactSupport.afterClosed().subscribe(result => {
        if (result) {
          window.open(`${environment.usglobalMailWordPress}/contact-us`, '_blank');
        }
      });
      return;
    }
    this.errorAddingName = this.maxNamesAdd && this.maxNamesAdd <= this.familyNameList.length;
    this.cdRef.detectChanges();
    this.addMoreEvent.next('family');
  }

  secondaryUserDescriptionChange(value) {
    if (this.settingPageLoad) {
      if (value !== Relation.COMPANY) {
        this.verifyAccountService.removeFrom1583ForCompanyTypeUserEvent(false);
      }
    }
  }

  secondaryUserDescriptionChangeOnEdit(value, id) {
    if (this.settingPageLoad && id === this.selectedId) {
      if (value !== Relation.COMPANY) {
        this.verifyAccountService.removeFrom1583ForCompanyTypeUserEvent(false);
      }
    }
  }
}
