// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  max-width: 480px;
  padding: 20px;
  width: 100%;
  height: 100%;
}
:host .payment-nav-bar {
  font-size: 171%;
  line-height: 50px;
  width: 100%;
}
:host .payment-nav-bar button span {
  font-size: 171%;
}
:host .mail-id-text {
  font-size: 128%;
  color: #333 !important;
}
:host .payments-table th {
  background: #f7f9fa;
}
:host .payments-table th,
:host .payments-table td {
  padding: 5px;
}
:host .payments-table th button:hover,
:host .payments-table td button:hover {
  background: #eee;
}`, "",{"version":3,"sources":["webpack://./src/app/payment-page/payment-details-popup/payment-details-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;EACA,WAAA;AACJ;AACI;EACE,eAAA;AACN;AAGE;EACE,eAAA;EACA,sBAAA;AADJ;AAKI;EACE,mBAAA;AAHN;AAMI;;EAEE,YAAA;AAJN;AAMM;;EACE,gBAAA;AAHR","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  max-width: 480px;\n  padding: 20px;\n  width: 100%;\n  height: 100%;\n\n  .payment-nav-bar {\n    font-size: 171%;\n    line-height: 50px;\n    width: 100%;\n\n    button span {\n      font-size: 171%;\n    }\n  }\n\n  .mail-id-text {\n    font-size: 128%;\n    color: #333 !important;\n  }\n\n  .payments-table {\n    th {\n      background: #f7f9fa;\n    }\n\n    th,\n    td {\n      padding: 5px;\n\n      button:hover {\n        background: #eee;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
