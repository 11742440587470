// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

span.spinner-container {
  position: relative;
  width: 25px;
  height: 21px;
}

.mobile-form {
  display: block !important;
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/onboarding/notarize-offline/notarize-offline-mobile.component.scss"],"names":[],"mappings":"AAAA;EACE;IACE,yBAAA;EACF;AACF;AAEA;EACE,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,yBAAA;EACA,uCAAA;AAAF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AACF;;AACA;EACE,yBAAA;AAEF","sourcesContent":["@keyframes spinner {\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.spinner:before {\n  content: '';\n  box-sizing: border-box;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 20px;\n  height: 20px;\n  margin-top: -10px;\n  margin-left: -10px;\n  border-radius: 50%;\n  border: 2px solid #ffffff;\n  border-top-color: #000000;\n  animation: spinner 0.8s linear infinite;\n}\nspan.spinner-container {\n  position: relative;\n  width: 25px;\n  height: 21px;\n}\n.mobile-form {\n  display: block !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
