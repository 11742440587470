import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Subject } from 'rxjs';
import { takeUntil, retry } from 'rxjs/operators';
import * as Services from '../../services';
import * as SharedHelpers from '../../utils/helpers';
import { UpgradePlanComponent } from '../upgrade-plan/upgrade-plan.component';
import { DialogComponent } from '../../components/dialog';
import { FamilyNameModel } from '../../models/address-model';
import { IPlanAttributes, Relation } from '@usgm/usgm-payloads-library-front';
import { VerifyAccountService } from '../verify-account.service';

@Component({
  selector: 'usgm-add-names',
  templateUrl: './add-names.component.html',
  styleUrls: ['./add-names.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNamesComponent implements OnInit, OnDestroy {
  public fromSettingsValue;
  public addedName: string;
  public submitted = false;
  public userData: any[] = [];
  public dialogRef: any;
  public loading = false;
  public addNameType: 'family' | 'business';
  public nameLoaded = false;
  public headerMessages = {
    family: `Complete this step to receive mail in your name and your\nfamily member's names.`,
    business: `Complete this step to receive mail in your name and your\ncompany's names.`,
  };
  public familyNameList: FamilyNameModel[] = [];
  public plansList = [];
  public message: string;
  public selectedUser: any;
  public settingPageLoad = false;
  public planNameText = 'You can add as many as you want';
  public maxNames = 0;
  public selectedPlanId: string;
  public subscriptionValue = false;
  public planType = null;

  @Input() set fromSettings(value) {
    if (value) {
      this.fromSettingsValue = value;
    }
  }

  @Input() selectedId: string;
  @Output() nameUpdate = new EventEmitter();
  @Output() completeStep: EventEmitter<number> = new EventEmitter();

  // private
  private unSubscriber: Subject<any> = new Subject();

  constructor(
    protected verifyService: Services.VerifyAccountService,
    protected apiMapping: Services.ApiMapping,
    protected notificationService: Services.NotificationService,
    protected cdr: ChangeDetectorRef,
    protected userDataService: Services.UserDataService,
    protected verifyAccountEventService: VerifyAccountService,
    protected matStepper: MatStepper,
    private matDialog: MatDialog,
  ) {}

  public ngOnInit() {
    this.getPlansList();
    this.verifyService.settingsNameId.pipe(takeUntil(this.unSubscriber)).subscribe(res => {
      if (res) {
        this.familyNameList = [];
        if (res.isSettings === true && res.id) {
          this.settingPageLoad = true;
          this.getPlansList();
        } else {
          this.settingPageLoad = false;
        }
        SharedHelpers.detectChanges(this.cdr);
      }
    });
  }

  public getAddedName(): void {
    this.verifyService
      .getName(this.apiMapping.getAccountNamesWithOnlyStatus())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(
        apiResponse => {
          if (apiResponse) {
            this.userData = apiResponse.data.accountNames;
            if (this.userData.length) {
              const data = this.userData[0].user;
              if (data !== undefined) {
                this.addedName = data.name;
              }
              this.familyNameList = [];
              for (let i = 0; i < this.userData.length; i++) {
                if (this.userData[i].relation.relationType === 'USER') {
                  this.userData[i].relation.relationType = Relation.PRIMARY;
                }
                if (this.settingPageLoad && this.userData[i].user.uuid === this.selectedId) {
                  if (this.userData[i].relation.relationType === Relation.COMPANY) {
                    // this.verifyAccountEventService.removeFrom1583ForCompanyTypeUserEvent(true);
                  }
                }
                this.familyNameList.push({
                  name: this.userData[i].user.name,
                  description: this.userData[i].relation.relationType,
                  id: this.userData[i].user.uuid,
                });
              }
            }
          }
          this.nameLoaded = true;
          this.hideShowLoader(false);
          SharedHelpers.detectChanges(this.cdr);
        },
        error => {
          console.log(error);
          this.hideShowLoader(false);
          this.notificationService.showError('Unable to get the name !!!!');
        },
      );
  }

  updateSubscription() {
    this.getSubscriptions();
  }

  /**
   * Fetch the plan list data
   */
  public getPlansList(): void {
    this.hideShowLoader(true);
    this.verifyService
      .getPlans(this.apiMapping.getPlans())
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        apiResponse => {
          this.getSubscriptions();
          this.addNameType = `family`;
          this.message = this.headerMessages[`family`];
          this.plansList = apiResponse;
          this.userDataService.plansList = SharedHelpers.clone(this.plansList);
          SharedHelpers.detectChanges(this.cdr);
          this.getAddedName();
        },
        error => {
          this.hideShowLoader(false);
          console.log(error);
        },
      );
  }

  public onSubmit(): void {
    this.submitted = true;
    if (this.addedName && this.addedName.trim() !== '') {
      this.submitted = false;
      const payload = [
        {
          childId: this.selectedUser.user.uuid,
          relation: this.selectedUser.relation.relationType,
          notes: null,
          name: this.addedName,
        },
      ];
      this.loading = true;
      this.verifyService
        .addName(this.apiMapping.saveAccountNames(), payload)
        .pipe(takeUntil(this.unSubscriber))
        .subscribe(
          apiResponse => {
            if (apiResponse) {
              this.loading = false;
              this.nameUpdate.emit();
              this.matStepper.next();
            }
          },
          error => {
            this.loading = false;
            this.notificationService.showError('Unable to update the primary account name !!');
          },
        );
    }
  }

  public upgradePlan(): void {
    this.dialogRef = this.matDialog.open(UpgradePlanComponent);
    this.dialogRef.afterClosed().subscribe((result: 'family' | 'business') => {
      if (result) {
        this.addNameType = result;
        if (result === 'family') {
          this.message = `Complete this step to receive mail in your name and your\nfamily member's names. ${this.planNameText}`;
        } else {
          this.message = `Complete this step to receive mail in your name and your\ncompany's names. ${this.planNameText}`;
        }
        if (!this.familyNameList.length) {
          this.familyNameList.push({
            name: '',
            description: '',
            id: null,
          });
        }
        SharedHelpers.detectChanges(this.cdr);
      }
    });
  }

  addMoreNames(): void {
    if (this.subscriptionValue) {
      this.notificationService.showWarning(`Please upgrade your plan to add more names!`);
      return;
    }
    this.addNewName();
  }

  addNewName() {
    const newRecord = {
      name: '',
      description: '',
      id: null,
    };
    this.familyNameList.push(newRecord);
    SharedHelpers.detectChanges(this.cdr);
  }

  updateFamilyNameList() {
    this.nameUpdate.emit();
    this.completeStep.emit(1);
    this.getAddedName();
  }

  deleteName(index) {
    const userId = this.familyNameList[index].id;
    this.verifyService
      .deleteName(this.apiMapping.deleteAccountNames(userId))
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        apiResponse => {
          this.familyNameList.splice(index, 1);
          this.nameUpdate.emit();
          SharedHelpers.detectChanges(this.cdr);
        },
        error => {
          this.notificationService.showError('Unable to delete the name !!!!');
        },
      );
  }

  deleteNameRecord(index: number, type: 'family' | 'business'): void {
    if (index !== -1) {
      if (this.familyNameList[index].id) {
        const dialogMsg = {
          message: 'Are you sure to delete this name?',
          title: ' ',
          confirmText: 'YES',
          cancelText: 'NO',
        };
        this.dialogRef = this.matDialog.open(DialogComponent, {
          data: dialogMsg,
          restoreFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((confirmation: boolean) => {
          if (confirmation) {
            this.deleteName(index);
            SharedHelpers.detectChanges(this.cdr);
          }
        });
      } else {
        this.familyNameList.splice(index, 1);
        SharedHelpers.detectChanges(this.cdr);
      }
    }
  }

  callDelete(data: { index: number; type: 'family' | 'business' }): void {
    this.deleteNameRecord(data.index, data.type);
  }

  hideShowLoader(show: boolean) {
    if (this.settingPageLoad) {
      this.loading = show;
    }
    SharedHelpers.detectChanges(this.cdr);
  }

  getSubscriptions() {
    this.userDataService
      .getUserSubscription()
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        response => {
          if (response && response.length) {
            const subscription = response.find(item => this.userDataService.isActiveSubscription(item));
            if (subscription) {
              this.selectedPlanId = subscription.plan_id;
              this.getSelectedPlan();
            } else {
              this.validatePlanName();
            }
          } else {
            this.subscriptionValue = true;
          }
          SharedHelpers.detectChanges(this.cdr);
        },
        err => this.validatePlanName(),
      );
  }

  async getSelectedPlan() {
    this.userDataService
      .getUserPlanById(this.selectedPlanId)
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        response => {
          if (response && response.account_names_plan) {
            this.maxNames = 0;
            if (response.account_names_plan.max_names || response.account_names_plan.max_names === 0) {
              this.maxNames = SharedHelpers.maxNamesForPlan(response);
              this.planType =
                (response.account_names_plan.max_names === 0 || response.account_names_plan.max_names > 1) && response.options.is_business
                  ? 'business'
                  : response.account_names_plan.max_names > 1
                    ? 'family'
                    : response.options['is_pay_as_you_go']
                      ? 'pay_as_you_go'
                      : 'individual';
              for (const plan of this.plansList) {
                if (plan.id === this.selectedPlanId) {
                  plan.isSelected = true;
                } else {
                  plan.isSelected = false;
                }
              }
            }
          }
          this.validatePlanName();
          SharedHelpers.detectChanges(this.cdr);
        },
        err => {
          this.validatePlanName();
        },
      );
  }

  validatePlanName() {
    if (this.subscriptionValue) {
      this.planNameText = 'Please upgrade your plan to add more names!';
    } else if (this.maxNames === 0) {
      this.planNameText = 'You can add as many as you want';
    } else if (this.maxNames === 1) {
      this.planNameText = `You can add 1 name at max as per your current subscription plan`;
    } else if (this.maxNames > 1) {
      this.planNameText = `You can add ${this.maxNames} names at max as per your current subscription plan`;
    }
    this.message = this.headerMessages[`family`];
    SharedHelpers.detectChanges(this.cdr);
  }

  /**
   * On Destroy
   */
  public ngOnDestroy(): void {
    this.unSubscriber.next({});
    this.unSubscriber.complete();
  }
}
