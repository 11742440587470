import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';

import { WelcomeComponent } from './welcome.component';

import { ComponentsModule } from '../components';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';

@NgModule({
  declarations: [WelcomeComponent],
  imports: [
    BrowserModule,
    CommonModule,
    ComponentsModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    VirtualScrollerModule,
  ],
  exports: [WelcomeComponent],
})
export class WelcomeModule {}
