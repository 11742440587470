import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'usgm-manage-label-rules',
  templateUrl: './manage-label-rules.component.html',
  styleUrls: ['./manage-label-rules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageLabelRulesComponent implements OnInit {
  public loading = false;
  public labels = ['Personale', 'Family', 'Staff', 'Office'];
  public rules = {
    has_to_rule: false,
    has_from_rule: false,
    to_rule: '',
    from_rule: '',
    label_to_apply: 'Personale',
  };

  @Output() closeNav = new EventEmitter();
  @ViewChild('labelRulesPagesStepper', { static: true }) labelRulesPagesStepper: MatStepper;

  constructor() {}

  ngOnInit() {}

  public submitNewRule() {
    console.log('submitNewRule');
  }

  public closeSideNav() {
    this.closeNav.emit({});
  }
}
