import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import SignaturePad from 'signature_pad';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'usgm-signature-pad',
  templateUrl: './signature-pad-popup.component.html',
  styleUrls: ['./signature-pad-popup.component.scss'],
})
export class SignaturePadPopupComponent implements AfterViewInit {
  private signaturePad: SignaturePad;

  public submitEnabled = false;

  constructor(
    private dialogRef: MatDialogRef<SignaturePadPopupComponent>,
    protected cdRef: ChangeDetectorRef,
  ) {
  }

  ngAfterViewInit() {
    const parentElement = document.getElementById('signature-pad');
    const canvas = parentElement.querySelector('canvas');
    this.signaturePad = new SignaturePad(canvas);
    this.signaturePad.addEventListener("beginStroke", () => {
      this.submitEnabled = true;
      this.cdRef.detectChanges();
    }, { once: true });
  }

  public submitSignature() {
    this.dialogRef.disableClose = true;
    this.submitEnabled = false;
    this.cdRef.detectChanges();
    const signatureImageBase64 = this.signaturePad.toDataURL(); // PNG
    this.dialogRef.close({ signature: signatureImageBase64 });
  }
}
