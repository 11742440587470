import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as Services from '../../services';
import { AppRoutes } from '../../models/constants/app-routes.constant';

@Component({
  selector: 'usgm-select-term',
  templateUrl: './select-term.component.html',
  styleUrls: ['./select-term.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectTermComponent implements OnInit, OnDestroy {
  private currentPlan: any;
  public loading = false;
  public termData: any = {
    term_id: 0,
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private registerDataService: Services.RegistrationDataService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public ngOnInit() {
    const plans = this.registerDataService.getPlans();
    plans.forEach(plan => {
      if (plan.id === this.registerDataService.getUserPlanSelections()['plan-id']) {
        this.currentPlan = plan;
      }
    });
    this.termData = this.currentPlan.membership.data;
    for (let i = 0; i < (this.termData || []).length; i++) {
      if (this.termData[i]['title'].indexOf('24') > -1) {
        this.termData[i]['pricing_features_html'] = ['Six months <span class="usgm-green bold">FREE</span>', 'You save <span class="usgm-green bold">25%</span>'];
      } else if (this.termData[i]['title'].indexOf('12') > -1) {
        this.termData[i]['pricing_features_html'] = ['Two months <span class="usgm-green bold">FREE</span>', 'You save <span class="usgm-green bold">16%</span>'];
      } else {
        this.termData[i]['pricing_features_html'] = ['Cancel anytime', ''];
      }
    }
  }

  public termButtonClicked(option: any) {
    this.router.navigateByUrl(`${AppRoutes.paymentInfo}?plan=${this.currentPlan.id}&term=${this.termData.term_id}`);
    this.registerDataService.updateUserPlanSelections('term', option.id);
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
