import { AfterViewInit, Component, ContentChildren, QueryList, ViewChildren, ElementRef, ViewChild, Input } from '@angular/core';
import { CarouselItemDirective } from './carousel-item.directive';
import { CarouselItemElementDirective } from './carousel-item-element.directive';
import { AnimationPlayer, AnimationFactory, AnimationBuilder, animate, style } from '@angular/animations';

@Component({
  selector: 'usgm-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  exportAs: 'carousel',
})
export class CarouselComponent implements AfterViewInit {
  @ContentChildren(CarouselItemDirective) items: QueryList<CarouselItemDirective>;
  @ViewChildren(CarouselItemElementDirective, { read: ElementRef }) private itemsElements: QueryList<ElementRef>;
  @ViewChild('carousel') private carousel: ElementRef;
  @Input() timing = '250ms ease-in';
  @Input() showControls = true;
  private player: AnimationPlayer;
  private itemWidth: number;
  public currentSlide = 0;
  public isNextVisible = true;
  public isPreviousVisible = false;
  public carouselWrapperStyle = {};

  constructor(private builder: AnimationBuilder) {}

  next() {
    if (this.currentSlide + 1 === this.items.length) {
      return;
    }
    this.currentSlide = (this.currentSlide + 1) % this.items.length;
    const offset = this.currentSlide * this.itemWidth;
    const myAnimation: AnimationFactory = this.builder.build([animate(this.timing, style({ transform: `translateX(-${offset}px)` }))]);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
    this.isNextVisible = !(this.currentSlide + 1 === this.items.length);
    this.isPreviousVisible = this.currentSlide > 0;
  }

  prev() {
    if (this.currentSlide === 0) {
      return;
    }
    this.currentSlide = (this.currentSlide - 1 + this.items.length) % this.items.length;
    const offset = this.currentSlide * this.itemWidth;
    const myAnimation: AnimationFactory = this.builder.build([animate(this.timing, style({ transform: `translateX(-${offset}px)` }))]);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
    this.isNextVisible = !(this.currentSlide + 1 === this.items.length);
    this.isPreviousVisible = this.currentSlide > 0;
  }

  ngAfterViewInit(): void {
    if (this.itemsElements) {
      setTimeout(() => {
        this.itemWidth = this.itemsElements.first.nativeElement.getBoundingClientRect().width;
        this.carouselWrapperStyle = {
          width: this.itemWidth ? `${this.itemWidth}px` : '100%',
        };
      }, 300);
    }
  }
}
