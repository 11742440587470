import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { forkJoin } from 'rxjs';
import { IssueReportedRequestType } from '../../../utils/constants';
import * as Services from '../../../services';
import * as SharedHelpers from '../../../utils/helpers';

@Component({
  selector: 'usgm-report-issue-popup',
  templateUrl: './report-issue-popup.component.html',
  styleUrls: ['./report-issue-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportIssuePopupComponent implements OnInit, OnDestroy {
  public loading = false;
  public reportIssueRequestSubmitted = false;
  public postData = { issues: [] };
  public showErrors = false;

  @Output() closeNav = new EventEmitter();
  @Output() closeNavAndReloadItems = new EventEmitter();

  private _items: any[] = [];
  get items(): any[] {
    return this._items;
  }

  @Input() set items(value: any[]) {
    this.postData.issues = [];
    this._items = value;
    this._items.forEach(item => {
      this.postData.issues.push({ mail_id: item.id, mail_type: item.mail_type, comment: '' });
    });
  }

  constructor(
    protected cdr: ChangeDetectorRef,
    protected http: Services.UsgmHttp,
    protected apiMapping: Services.ApiMapping,
    protected notificationService: Services.NotificationService,
  ) {}

  ngOnInit() {
    setTimeout(() => {
      document.getElementById(`comment_${this.postData.issues[0].mail_id}`).focus();
    }, 500);
  }

  closeSideNavAndReload() {
    this.closeNavAndReloadItems.emit({});
  }

  closeSideNav() {
    this.closeNav.emit({});
  }

  submitIssue() {
    let issues = false;
    this.postData.issues.forEach(issue => {
      if ((issue.comment || '').length === 0) {
        document.getElementById(`comment_${issue.mail_id}`).focus();
        issues = true;
      }
    });
    if (issues) {
      this.showErrors = true;
      SharedHelpers.detectChanges(this.cdr);
      return;
    }
    this.loading = true;
    const requestArray = [];
    for (const data of this.postData.issues) {
      const payload = {
        requestID: data.mail_id,
        comment: data.comment,
        reportAnIssueType: IssueReportedRequestType.MAIL_REQUEST,
        requestedOn: new Date().toISOString(),
      };
      requestArray.push(this.http.post(this.apiMapping.reportedIssueByCustomer(), payload));
    }
    forkJoin(requestArray).subscribe(
      response => {
        this.loading = false;
        this.reportIssueRequestSubmitted = true;
        SharedHelpers.detectChanges(this.cdr);
      },
      error => {
        this.loading = false;
        this.notificationService.showError(error.error.message);
        SharedHelpers.detectChanges(this.cdr);
      },
    );
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
