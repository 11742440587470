import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as Services from '../../services';
import { retry, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { VerifyAccountService } from '../verify-account.service';
import { Router } from '@angular/router';
import { AppRoutes } from '../../models/constants/app-routes.constant';
import { FormType } from '@usgm/usgm-payloads-library-front';
import { Relation } from '../../models/get-notarized.model';
import { environment } from '../../../environments/environment';
import { ExternalScripts } from '../../services/scripts/script.store';

declare var Calendly;

@Component({
  selector: 'usgm-notarize-with-usgm',
  templateUrl: './notarize-with-usgm.component.html',
  styleUrls: ['./notarize-with-usgm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotarizeWithUsgmComponent implements OnInit {
  public selectedId: string;
  public settingPageLoad: boolean;
  public uploadFormNotarizedComplete = false;
  public userNames = [];
  public uploadForm = false;
  public uploadFormSectionOpen = false;
  public meetScheduled = false;
  public fileSize = 10 * 1024 * 1024;
  public allowedTypes = ['application/pdf'];
  public formList = [];
  public usersFromAccountVerificationsIds = [];
  private unSubscriber: Subject<any> = new Subject();

  @Input() data;
  @Input() downLoadingPdf;
  @Output() clearForm = new EventEmitter<string>();
  @Output() navigateBackToForm = new EventEmitter<any>();
  @Output() downloadZipForm = new EventEmitter<any>();

  @ViewChild('container', { read: ElementRef, static: false }) container: ElementRef;

  constructor(
    protected verifyService: Services.VerifyAccountService,
    private cdRef: ChangeDetectorRef,
    protected notificationService: Services.NotificationService,
    protected verifyAccountService: VerifyAccountService,
    protected userDataService: Services.UserDataService,
    protected scriptService: Services.ScriptService,
    protected apiMapping: Services.ApiMapping,
    private router: Router,
  ) {}

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    if ((event.data || {}).event === 'calendly.event_scheduled') {
      this.meetScheduled = true;
      Calendly.closePopupWidget();
      this.createZohoDocumentAndSaveNotaryRequest(); // if scheduling meet isn't necessary to proceed, use calendly.profile_page_viewed to enable navigation to next page
    }
  }

  @Input() set isNotarizedSelected(value: boolean) {
    if (value) {
      this.getFormStatuses();
    } else {
      this.uploadForm = true;
    }
  }

  onBack() {
    this.navigateBackToForm.emit();
  }
  public ngOnInit() {
    this.getAddedName();
    const body = <HTMLDivElement>document.body;

    const l = document.createElement('link');
    l.rel = 'stylesheet';
    l.href = 'https://assets.calendly.com/assets/external/widget.css';
    body.appendChild(l);

    this.scriptService
      .load(ExternalScripts.CALENDLY_WIDGET)
      .then(data => {
        console.log('Calendly widget script dynamically loaded', data);
      })
      .catch(error => console.log(error));

    this.verifyService.settingsNameId.subscribe(value => {
      if (value) {
        this.settingPageLoad = value.isSettings;
        this.selectedId = value.id;
      } else {
        this.settingPageLoad = false;
        this.selectedId = null;
      }
      this.cdRef.detectChanges();
    });
  }

  public scheduleMeet() {
    const userName = this.userDataService.getUserFullName();
    const userEmail = this.userDataService.getUserEmail();
    Calendly.initPopupWidget({ url: `${environment.calendlyUrl}&name=${userName}&email=${userEmail}` });
  }

  public getAddedName(): void {
    this.verifyService
      .getName(this.apiMapping.getAccountNamesWithOnlyStatus())
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(apiResponse => {
        if (apiResponse) {
          this.usersFromAccountVerificationsIds = apiResponse.data.accountNames.filter(user => user.is_from_account_verification).map(user => user.user.uuid);
          for (const user of apiResponse.data.accountNames) {
            if (user.is_from_account_verification && user.relation.relationType === Relation.MINOR_CHILD) {
              this.userNames = this.userNames.map(item => {
                if (item.relation === Relation.PRIMARY) {
                  item.name = item.name + `, ${user.user.name} (${user.relation.relationType})`;
                }
                return item;
              });
            } else {
              const userForm = this.formList.find(item => item.user_id === user.user.id);
              if (userForm && userForm.form1583) {
                this.userNames.push({
                  name: user.user.name + ` (${user.relation.relationType})`,
                  id: user.user.uuid,
                  relation: user.relation.relationType,
                });
              }
            }
          }
          this.setUsersSubmissionStatus();
          this.cdRef.detectChanges();
        }
      });
  }

  public getFormStatuses() {
    this.verifyService
      .getFormList(this.apiMapping.getFormsList(this.userDataService.getUserId()))
      .pipe(takeUntil(this.unSubscriber), retry(3))
      .subscribe(response => {
        if (response && response.form1583 && response.form1583.length) {
          this.formList = response.form1583;
          for (let x = 0; x < response.form1583.length; x++) {
            if (response.form1583[x].form1583.form_type === FormType.FORM_1583) {
              this.uploadFormSectionOpen = true;
              break;
            }
            if (x === response.form1583.length - 1) {
              this.uploadFormSectionOpen = false;
              this.uploadFormNotarizedComplete = true;
              this.userDataService.setNotaryPlan(`notaryusgm`);
            }
          }
          if (!this.userNames?.length) {
            this.getAddedName();
          }
          this.setUsersSubmissionStatus();
          this.cdRef.detectChanges();
        }
      });
  }

  public setUsersSubmissionStatus() {
    if (this.formList && this.formList.length && this.userNames && this.userNames.length) {
      this.userNames = this.userNames.map(userName => {
        const form1583Object = this.formList.find(value => value.user_id === userName.id);
        if (form1583Object && form1583Object.form1583.form_type === FormType.FORM_1583_NOTARIZED) {
          return {
            ...userName,
            isSubmitted: true,
          };
        } else {
          return {
            ...userName,
            isSubmitted: false,
          };
        }
      });
    }
  }

  downloadAllForms() {
    if (this.settingPageLoad && this.selectedId) {
      this.downloadZipForm.emit(this.selectedId);
    } else {
      this.downloadZipForm.emit();
    }
  }

  downloadUserForms(userId) {
    this.downloadZipForm.emit(userId);
  }

  clearFormValue(): void {
    this.navigateBackToForm.emit();
  }

  completeVerificationProcess() {
    this.createZohoDocumentAndSaveNotaryRequest();
  }

  public removeVerifyTabFromList() {
    this.router.navigate([`/${AppRoutes.inbox}`]);
  }

  public async createZohoDocumentAndSaveNotaryRequest() {
    try {
      this.downLoadingPdf = true;
      this.cdRef.detectChanges();
      // UNI-1525 - no need to automatically create zoho sign document on calendly schedule
      // await this.verifyService.createSignNowDocuments(this.apiMapping.createSignNowDocuments());
      await this.verifyService.saveNotaryRequestPromise(this.apiMapping.saveNotaryRequest(this.data.uuid), { notaryRequestForUsers: this.usersFromAccountVerificationsIds });
      this.verifyAccountService.verificationCompleteEvent(true);
      this.uploadForm = false;
      this.uploadFormSectionOpen = true;
      this.downLoadingPdf = false;
      this.getFormStatuses();
      this.cdRef.detectChanges();
    } catch (error) {
      this.downLoadingPdf = false;
      this.cdRef.detectChanges();
      this.notificationService.showError(error.message);
    }
  }

  uploadFormFuntion(userId) {
    const fileData = this.userNames.find(item => item.id === userId);
    if (fileData && fileData.userFile) {
      this.downLoadingPdf = true;
      const formData = new FormData();
      formData.append('form', fileData.userFile);
      formData.append('form_type', FormType.FORM_1583_NOTARIZED);
      this.verifyService
        .uploadPDF(this.apiMapping.uploadPDF(userId), formData)
        .pipe(takeUntil(this.unSubscriber))
        .subscribe(
          apiResponse => {
            if (apiResponse) {
              this.getFormStatuses();
              this.downLoadingPdf = false;
              this.cdRef.detectChanges();
            }
          },
          error => {
            this.downLoadingPdf = false;
            this.cdRef.detectChanges();
            this.notificationService.showError('Error occurred while uploading file. Please try again');
          },
        );
    } else {
      this.notificationService.showWarning('Please upload a pdf file before proceed');
    }
  }

  onFileDropped(fileEvent: Array<any>, userId) {
    this.validateFile(fileEvent, userId);
  }

  validateFile(fileEvent: File[], userId) {
    if (userId) {
      const userName = this.userNames.find(item => item.id === userId);
      userName.isSubmitted = false;
    }
    const files = fileEvent;
    const file = fileEvent[0];
    if (file) {
      if (!this.allowedTypes.includes(file.type)) {
        this.notificationService.showWarning('Allowed types is pdf only');
        return false;
      } else if (file.size > this.fileSize) {
        this.notificationService.showWarning(`upload file will be less than ${this.fileSize / 1024 / 1024} MB.`);
        return false;
      }
      this.readURL(file, userId);
    }
  }

  readURL(file, userId) {
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        file.data = e.target.result;
        for (const user of this.userNames) {
          if (user.id === userId) {
            user.userFile = file;
            break;
          }
        }
        this.cdRef.detectChanges();
      };
      reader.readAsDataURL(file);
    }
  }
}
