// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host .top-options {
  padding: 0 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/payment-page/payment-page.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,iBAAA;AACJ","sourcesContent":[":host {\n  display: block;\n\n  .top-options {\n    padding: 0 20px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
