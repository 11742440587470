export class ShipmentModel {
  do_shipment_scheduling: boolean;
  shipment_schedule: string | null;
  shipment_schedule_day: string;
  schedule_address_id: string;
  schedule_open_and_repack: boolean;
  schedule_default_packing_instructions: string;
  letters_service: string;
  large_packages_service: string;
  small_packages_service: string;

  constructor() {
    this.do_shipment_scheduling = false;
    this.schedule_open_and_repack = false;
    this.shipment_schedule = null;
    this.shipment_schedule_day = '';
    this.schedule_address_id = '';
    this.letters_service = '';
    this.large_packages_service = '';
    this.small_packages_service = '';
  }
}

export interface SettingsModel {
  createdAt: Date;
  created_by: number;
  id: number;
  shipping_preferences: ShipmentModel[];
  signature_image: string;
  updatedAt: Date;
  updated_by: number;
  user_id: number;
  uuid: string;
}
