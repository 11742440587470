// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host virtual-scroller {
  height: 600px;
}
:host .selection-control {
  cursor: pointer;
}
:host tbody {
  border-top: 1px solid #e0e0e0;
}
:host .floating-buttons-container {
  position: absolute;
  right: 10px;
}
:host .image-title {
  width: 140px;
  margin: auto;
}
:host .vertical-align-center {
  vertical-align: middle !important;
}
:host td {
  vertical-align: top;
  padding: 10px 10px 10px 0;
  word-break: break-word;
}
:host td .look-up {
  min-width: 181px;
}
:host td .look-up .title {
  color: #fb7e16;
  font-size: 143%;
}
:host td .look-up .popoverbutton {
  background-color: #cde0e6;
  background-color: #fb7e16;
  color: #fff;
  border-width: 0;
  border-radius: 3px;
}
:host td .item-indicator-container {
  padding: 5px 10px;
}
:host td .item-indicator-container .scan-indicator-btn {
  transform: scale(0.9);
  background-color: #dfdfdf;
  box-shadow: none;
}
:host td .item-indicator-container .scan-indicator-btn mat-icon {
  color: #4886bb;
}
:host td .item-indicator-container .scan-indicator-btn.disabled {
  pointer-events: unset;
  opacity: 0.5;
  cursor: not-allowed;
}
:host .header-3 {
  width: 120px;
}`, "",{"version":3,"sources":["webpack://./src/app/inbox/items-table/items-table.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,6BAAA;AADJ;AAIE;EACE,kBAAA;EACA,WAAA;AAFJ;AAKE;EACE,YAAA;EACA,YAAA;AAHJ;AAME;EACE,iCAAA;AAJJ;AAQE;EACE,mBAAA;EACA,yBAAA;EACA,sBAAA;AANJ;AAQI;EACE,gBAAA;AANN;AAQM;EACE,cAAA;EACA,eAAA;AANR;AASM;EACE,yBAAA;EACA,yBAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;AAPR;AAWI;EACE,iBAAA;AATN;AAUM;EACE,qBAAA;EACA,yBAAA;EACA,gBAAA;AARR;AAUQ;EACE,cAAA;AARV;AAWQ;EACE,qBAAA;EACA,YAAA;EACA,mBAAA;AATV;AAeE;EACE,YAAA;AAbJ","sourcesContent":[":host {\n  display: block;\n\n  virtual-scroller {\n    height: 600px;\n  }\n\n  .selection-control {\n    cursor: pointer;\n  }\n\n  tbody {\n    border-top: 1px solid #e0e0e0;\n  }\n\n  .floating-buttons-container {\n    position: absolute;\n    right: 10px;\n  }\n\n  .image-title {\n    width: 140px;\n    margin: auto;\n  }\n\n  .vertical-align-center {\n    vertical-align: middle !important\n  }\n\n\n  td {\n    vertical-align: top;\n    padding: 10px 10px 10px 0;\n    word-break: break-word;\n\n    .look-up {\n      min-width: 181px;\n\n      .title {\n        color: #fb7e16;\n        font-size: 143%;\n      }\n\n      .popoverbutton {\n        background-color: #cde0e6;\n        background-color: #fb7e16;\n        color: #fff;\n        border-width: 0;\n        border-radius: 3px;\n      }\n    }\n\n    .item-indicator-container {\n      padding: 5px 10px;\n      .scan-indicator-btn {\n        transform: scale(0.9);\n        background-color: #dfdfdf;\n        box-shadow: none;\n\n        mat-icon {\n          color: #4886bb;\n        }\n\n        &.disabled {\n          pointer-events: unset;\n          opacity: 0.5;\n          cursor: not-allowed;\n        }\n      }\n    }\n  }\n\n  .header-3 {\n    width: 120px;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
