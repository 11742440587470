import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class NotificationService {
  showMessageSubject = new Subject<any>();

  constructor() {}

  showMessage(msg: string) {
    this.showMessageSubject.next({ panelClass: '', message: msg, duration: 5000 });
  }

  showMessageForDuration(msg: string, duration: number) {
    this.showMessageSubject.next({ panelClass: '', message: msg, duration: duration });
  }

  showTitledMessage(title: string, message: string, duration?: number, horizontalPosition: string = 'center') {
    this.showMessageSubject.next({ panelClass: 'default-notification', message, title, duration: duration || 5000, horizontalPosition });
  }

  showSuccess(msg: string) {
    this.showMessageSubject.next({ panelClass: 'success-notification', message: msg, duration: 5000 });
  }

  showSuccessForDuration(msg: string, duration: number) {
    this.showMessageSubject.next({ panelClass: 'success-notification', message: msg, duration: duration });
  }

  showSuccessForDurationWithAction(msg: string, duration: number, action: string) {
    this.showMessageSubject.next({ panelClass: 'success-notification', message: msg, duration: duration, action: action });
  }

  showSuccessWithActionCallback(msg: string, action: string): Observable<void> {
    this.showMessageSubject.next({ panelClass: 'success-notification', message: msg, duration: 5000, action: action });
    return this.showMessageSubject; // refactor later
  }

  showSuccessForDurationWithActionCallback(msg: string, duration: number, action: string): Observable<void> {
    this.showMessageSubject.next({ panelClass: 'success-notification', message: msg, duration: duration, action: action });
    return this.showMessageSubject; // refactor later
  }

  showWarning(msg: string) {
    this.showMessageSubject.next({ panelClass: 'warning-notification', message: msg, duration: 5000 });
  }

  showWarningForDuration(msg: string, duration: number) {
    this.showMessageSubject.next({ panelClass: 'warning-notification', message: msg, duration: duration });
  }

  showWarningForDurationWithAction(msg: string, duration: number, action: string) {
    this.showMessageSubject.next({ panelClass: 'warning-notification', message: msg, duration: duration, action: action });
  }

  showWarningWithActionCallback(msg: string, action: string): Observable<void> {
    this.showMessageSubject.next({ panelClass: 'warning-notification', message: msg, duration: 5000, action: action });
    return this.showMessageSubject; // refactor later
  }

  showWarningForDurationWithActionCallback(msg: string, duration: number, action: string): Observable<void> {
    this.showMessageSubject.next({ panelClass: 'warning-notification', message: msg, duration: duration, action: action });
    return this.showMessageSubject; // refactor later
  }

  showError(msg: string) {
    console.log(`dispatching showMessageSubject.next: ${msg}`);
    this.showMessageSubject.next({ panelClass: 'error-notification', message: msg, duration: 5000 });
  }

  showErrorForDuration(msg: string, duration: number) {
    this.showMessageSubject.next({ panelClass: 'error-notification', message: msg, duration: duration });
  }

  showErrorForDurationWithAction(msg: string, duration: number, action: string) {
    this.showMessageSubject.next({ panelClass: 'error-notification', message: msg, duration: duration, action: action });
  }

  showErrorWithActionCallback(msg: string, action: string): Observable<void> {
    this.showMessageSubject.next({ panelClass: 'error-notification', message: msg, duration: 5000, action: action });
    return this.showMessageSubject; // refactor later
  }

  showErrorForDurationWithActionCallback(msg: string, duration: number, action: string): Observable<void> {
    this.showMessageSubject.next({ panelClass: 'error-notification', message: msg, duration: duration, action: action });
    return this.showMessageSubject; // refactor later
  }
}
