// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host virtual-scroller {
  height: 600px;
}
:host .selection-control {
  cursor: pointer;
}
:host .selected {
  background: #f4fcff;
  border-right: 2px solid #377bb5;
}
:host td {
  min-height: 40px;
  padding: 20px 10px 20px 0;
}
:host tbody {
  border-top: 1px solid #e0e0e0;
}`, "",{"version":3,"sources":["webpack://./src/app/payment-page/payments-table/payments-table.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,mBAAA;EACA,+BAAA;AADJ;AAIE;EACE,gBAAA;EACA,yBAAA;AAFJ;AAKE;EACE,6BAAA;AAHJ","sourcesContent":[":host {\n  display: block;\n\n  virtual-scroller {\n    height: 600px;\n  }\n\n  .selection-control {\n    cursor: pointer;\n  }\n\n  .selected {\n    background: #f4fcff;\n    border-right: 2px solid #377bb5;\n  }\n\n  td {\n    min-height: 40px;\n    padding: 20px 10px 20px 0;\n  }\n\n  tbody {\n    border-top: 1px solid #e0e0e0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
