import { PortalSections } from './portal-sections';

export enum SettingNavViewOptions {
  GET_A_FREE_MONTH = 0,
  ACCOUNT_DETAILS = 1,
  BILLING_DETAILS = 2,
  MANAGE_ACCOUNT_NAMES = 3,
  MANAGE_ADDRESS_BOOK = 4,
  AUTOMATIC_FORWARDING = 5,
  MANAGE_LABEL_RULES = 6,
  PORTAL_ACCOUNT_DETAILS = 7,
  PORTAL_BILLING_ADDRESS = 8,
  PORTAL_PAYMENT_SOURCES = 9,
  GET_HELP = 10,
  MANAGE_FOLDER = 12,
  MANAGE_TEAM_MEMBERS = 13,
  CHANGE_WAREHOUSE = 14,
  SCAN_OPTIONS = 16,
  MANAGE_SCAN_BUNDLES = 15,
  MANAGE_SCAN_EXPORTS = 17,
}

export const SettingOptionsGroups = {
  BILLING: 'Billing',
  ACCOUNTS: 'Account Settings',
  SHIPMENTS: 'Shipping Address & Scheduling',
  EXTRA: 'More',
};

export const SettingsOptions = [
  {
    name: 'Get a Free Month',
    icon: 'users',
    index: SettingNavViewOptions.GET_A_FREE_MONTH,
    excludeForSubUsers: true,
    group: SettingOptionsGroups.EXTRA,
  },
  {
    name: 'Scan Bundles',
    customIcon: 'scans',
    index: SettingNavViewOptions.MANAGE_SCAN_BUNDLES,
    group: SettingOptionsGroups.EXTRA,
    excludeForSubUsers: false,
    excludeForRegularSubUsers: true,
    showNewLabel: true,
  },
  {
    name: 'Account Details',
    icon: 'inbox',
    index: SettingNavViewOptions.ACCOUNT_DETAILS,
    group: SettingOptionsGroups.ACCOUNTS,
  },
  {
    name: 'Billing Details',
    icon: 'scans',
    index: SettingNavViewOptions.BILLING_DETAILS,
    hidden: true,
    group: SettingOptionsGroups.BILLING,
    excludeForSubUsers: true,
  },
  {
    name: 'Account Names',
    icon: 'shipments',
    index: SettingNavViewOptions.MANAGE_ACCOUNT_NAMES,
    exclamation: true,
    excludeForSubUsers: true,
    group: SettingOptionsGroups.ACCOUNTS,
  },
  {
    name: 'Shipping Address',
    icon: 'payment',
    index: SettingNavViewOptions.MANAGE_ADDRESS_BOOK,
    group: SettingOptionsGroups.SHIPMENTS,
  },
  {
    name: 'Automatic Scans',
    customIcon: 'scan-setting-icon',
    index: SettingNavViewOptions.SCAN_OPTIONS,
    group: SettingOptionsGroups.EXTRA,
    excludeForSubUsers: false,
    excludeForRegularSubUsers: true,
  },
  {
    name: 'Shipment Scheduling',
    icon: 'discard',
    index: SettingNavViewOptions.AUTOMATIC_FORWARDING,
    hidden: false,
    excludeForSubUsers: false,
    excludeForRegularSubUsers: true,
    group: SettingOptionsGroups.SHIPMENTS,
  },
  {
    name: 'Label Rules',
    icon: 'settings',
    index: SettingNavViewOptions.MANAGE_LABEL_RULES,
    hidden: true,
    excludeForSubUsers: true,
    group: SettingOptionsGroups.ACCOUNTS,
  },
  {
    name: 'Payment Details',
    icon: 'payment',
    index: SettingNavViewOptions.PORTAL_PAYMENT_SOURCES,
    section_type: PortalSections.PAYMENT_SOURCES,
    excludeForSubUsers: true,
    group: SettingOptionsGroups.BILLING,
  },
  {
    name: 'Billing Address',
    customIcon: 'custom-icon',
    index: SettingNavViewOptions.PORTAL_BILLING_ADDRESS,
    section_type: PortalSections.ADDRESS,
    excludeForSubUsers: true,
    group: SettingOptionsGroups.BILLING,
  },
  {
    name: 'Exports',
    matIcon: 'cloud_download',
    index: SettingNavViewOptions.MANAGE_SCAN_EXPORTS,
    excludeForSubUsers: true,
    excludeForRegularSubUsers: true,
    group: SettingOptionsGroups.EXTRA,
    showNewLabel: true,
  },
  {
    name: 'Get Help',
    icon: 'bell',
    index: SettingNavViewOptions.GET_HELP,
    group: SettingOptionsGroups.EXTRA,
  },
  {
    name: 'Folders',
    icon: 'bell',
    index: SettingNavViewOptions.MANAGE_FOLDER,
    excludeForRegularSubUsers: true,
    group: SettingOptionsGroups.ACCOUNTS,
  },
  {
    name: 'Team Members',
    icon: 'shipments',
    index: SettingNavViewOptions.MANAGE_TEAM_MEMBERS,
    excludeForRegularSubUsers: true,
    group: SettingOptionsGroups.ACCOUNTS,
    showNewLabel: true,
  },
  {
    name: 'Your Address',
    icon: 'bell',
    index: SettingNavViewOptions.CHANGE_WAREHOUSE,
    excludeForSubUsers: true,
    group: SettingOptionsGroups.ACCOUNTS,
  },
];
