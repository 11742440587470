// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.white-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.white-box {
  background: #fff;
  max-width: 400px;
  border-radius: 3px;
  position: relative;
}

.link-btn {
  font-weight: bold;
  font-family: "FiraSans-Bold";
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/onboarding/notarization-completed/notarization-completed.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AACA;EACE,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AAEF;;AAAA;EACE,iBAAA;EACA,4BAAA;EACA,0BAAA;AAGF","sourcesContent":[".white-box-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n.white-box {\n  background: #fff;\n  max-width: 400px;\n  border-radius: 3px;\n  position: relative;\n}\n.link-btn {\n  font-weight: bold;\n  font-family: 'FiraSans-Bold';\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
