import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ComponentsModule } from '../components/components.module';
import { EmailUnsubscribeComponent } from './email-unsubscribe.component';

@NgModule({
  declarations: [EmailUnsubscribeComponent],
  imports: [BrowserModule, CommonModule, ComponentsModule],
  exports: [EmailUnsubscribeComponent],
})
export class EmailUnsubscribeModule {}
