import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ShipmentScheduleSetting, UserSetting } from '@usgm/usgm-payloads-library-front/Enums/Settings';
import { Subject } from 'rxjs';
import * as Services from '../../services';
import { takeUntil } from 'rxjs/operators';
import { ShipmentModel, SettingsModel } from '../../models/settings.model';
import * as SharedHelpers from '../../utils/helpers';

@Component({
  selector: 'usgm-automatic-forwarding',
  templateUrl: './automatic-forwarding.component.html',
  styleUrls: ['./automatic-forwarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomaticForwardingComponent implements OnInit, OnDestroy {
  @Output() closeNav = new EventEmitter();
  @ViewChild('forwardingPagesStepper', { static: false }) forwardingPagesStepper: MatStepper;

  private userId: string;
  private shipmentSettings: SettingsModel;
  private userSettings: SettingsModel;
  private unSubscriber: Subject<any> = new Subject();
  public loading = false;
  public daysCount = 31;
  public weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  public doesAgreeWithTerms = false;
  public shipmentSchedules: string[] = [];
  public addressList: any[] = [];
  public shipmentMethods: any[] = [];
  public shipmentForm: ShipmentModel = new ShipmentModel();
  public showStep: boolean;
  public selectAddressErrorMessage = '';
  public selectShipmentErrorMessage = '';
  public boxErrorMessage = '';
  public serviceErrorMessage = '';

  constructor(
    private userDataService: Services.UserDataService,
    private notificationService: Services.NotificationService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.generateValues();
  }

  ngOnInit() {
    this.getUserAddress();
    this.getShipmentMethods();
    this.getShipmentSettings();
  }

  validate(): boolean {
    this.boxErrorMessage = this.serviceErrorMessage = this.selectAddressErrorMessage = this.selectAddressErrorMessage = '';
    this.serviceErrorMessage = '';
    if (!this.doesAgreeWithTerms && this.shipmentForm.do_shipment_scheduling) {
      this.boxErrorMessage = 'Box must be checked to enable automatic shipment.';
      const stepperElement = document.getElementById('mainScheduleSettingContainer');
      if (stepperElement) {
        stepperElement.scrollIntoView();
      }
      return false;
    }

    if (!this.shipmentForm.shipment_schedule) {
      this.selectShipmentErrorMessage = 'Please select shipment schedule.';
      return false;
    }

    if (!this.shipmentForm.schedule_address_id) {
      this.selectAddressErrorMessage = 'Please select address.';
      return false;
    }

    if (!this.addressList.find(a => a.id === this.shipmentForm.schedule_address_id)) {
      this.selectAddressErrorMessage = 'Please select valid address.';
      return false;
    }

    if (!this.shipmentForm.letters_service || !this.shipmentForm.small_packages_service || !this.shipmentForm.large_packages_service) {
      this.serviceErrorMessage = 'Please select a service.';
      return false;
    }

    return true;
  }

  public submitRequest() {
    const isValid = this.validate();
    if (!isValid) {
      return false;
    }
    this.loading = true;
    const shipmentPreference = {
      do_shipment_scheduling: this.shipmentForm.do_shipment_scheduling,
      large_packages_service: this.shipmentForm.large_packages_service,
      letters_service: this.shipmentForm.letters_service,
      schedule_address_id: this.shipmentForm.schedule_address_id,
      schedule_default_packing_instructions: this.shipmentForm.schedule_default_packing_instructions,
      schedule_open_and_repack: this.shipmentForm.schedule_open_and_repack,
      shipment_schedule: this.shipmentForm.shipment_schedule,
      small_packages_service: this.shipmentForm.small_packages_service,
      shipment_schedule_day: Number(this.shipmentForm.shipment_schedule_day) || null,
    };
    const params = {
      shipping_preferences: [shipmentPreference],
    };
    let type;
    let apiRequest;
    if (this.userSettings?.uuid) {
      type = 'update';
      apiRequest = this.userDataService.updateShipmentData(this.userId, this.userSettings.uuid, params);
    } else {
      type = 'save';
      apiRequest = this.userDataService.saveShipmentData(this.userId, params);
    }
    apiRequest.pipe(takeUntil(this.unSubscriber)).subscribe(
      response => {
        this.loading = false;
        if (type === 'save') {
          this.notificationService.showSuccess('Shipment scheduling saved successfully');
        } else {
          this.notificationService.showSuccess('Shipment scheduling updated successfully');
        }
        this.closeSideNav();
      },
      err => {
        this.notificationService.showError('Unable to ' + type + ' shipment scheduling');
        this.loading = false;
      },
    );
  }

  public closeSideNav() {
    this.closeNav.emit({});
  }

  generateValues() {
    this.userId = this.userDataService.getUserId();

    this.shipmentSchedules = Object.keys(ShipmentScheduleSetting)
      .map(key => ShipmentScheduleSetting[key])
      .filter(key => key !== ShipmentScheduleSetting.BI_WEEKLY);
  }

  getShipmentSettings() {
    const user_setting_type = UserSetting.SHIPPING_PREFERENCES;
    this.userDataService
      .getShipmentSettings(user_setting_type)
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        response => {
          if (response && response.UserSettings) {
            this.userSettings = response.UserSettings;
            this.setValues();
            this.changeSchedule(this.shipmentForm.shipment_schedule_day);
          } else {
          }
          SharedHelpers.detectChanges(this.cdRef);
        },
        () => {
          SharedHelpers.detectChanges(this.cdRef);
        },
      );
  }

  setValues() {
    if (this.userSettings.shipping_preferences && this.userSettings.shipping_preferences.length > 0 && this.userSettings.uuid) {
      this.shipmentSettings = this.userSettings;
      this.shipmentForm = this.shipmentSettings.shipping_preferences[0];
      this.doesAgreeWithTerms = this.shipmentForm ? this.shipmentForm.do_shipment_scheduling : false;
    }
    SharedHelpers.detectChanges(this.cdRef);
  }

  getUserAddress() {
    this.userDataService
      .getAddressesByUserId(this.userId)
      .pipe(takeUntil(this.unSubscriber))
      .subscribe(
        (response: { addresses: any[] }) => {
          if (response && response.addresses && response.addresses.length > 0) {
            this.addressList = response.addresses;
          }
        },
        err => {
          this.addressList = [];
        },
      );
  }

  getShipmentMethods() {
    this.userDataService
      .getShipmentMethodList()
      .pipe(takeUntil(this.unSubscriber))
      .subscribe((response: { data: any[] }) => {
        if (response && response.data) {
          console.log(response.data);
          this.shipmentMethods = response.data || [];
          this.shipmentMethods.forEach(item => (item.method_name = item.method_name.replace('Endicia', 'USPS')));
          this.shipmentMethods = this.shipmentMethods.filter(item => item.method_name.indexOf('Aramex') === -1).sort((a, b) => a.method_name.localeCompare(b.method_name));
        }
      });
  }

  counter(number) {
    return new Array(number).fill(1);
  }

  changeSchedule(day: string = '1') {
    switch (this.shipmentForm.shipment_schedule.toUpperCase()) {
      case ShipmentScheduleSetting.WEEKLY:
        this.changeScheduledDay(day);
        break;
      case ShipmentScheduleSetting.MONTHLY:
        this.changeScheduledDay(day);
        break;
      default:
        this.changeScheduledDay(null);
    }
  }

  isWeeklySchedule(): boolean {
    return this.shipmentForm.shipment_schedule && this.shipmentForm.shipment_schedule.toUpperCase() === ShipmentScheduleSetting.WEEKLY;
  }

  isMonthlySchedule(): boolean {
    return this.shipmentForm.shipment_schedule && this.shipmentForm.shipment_schedule.toUpperCase() === ShipmentScheduleSetting.MONTHLY;
  }

  changeScheduledDay(day) {
    this.shipmentForm.shipment_schedule_day = day;
  }

  ngOnDestroy(): void {
    this.unSubscriber.next({});
    this.unSubscriber.complete();
  }
}
