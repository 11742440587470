import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as SharedHelpers from '../../utils/helpers';
import * as Helpers from '../../utils/helpers';
import { Animations } from '../../animations/element.animations';
import * as Services from '../../services';
import { AccountStatus, Status } from '@usgm/usgm-payloads-library-front';
import { IssueReportedRequestType } from '../../utils/constants';

@Component({
  selector: 'usgm-shipment-details-popup',
  templateUrl: './shipment-details-popup.component.html',
  styleUrls: ['./shipment-details-popup.component.scss'],
  animations: Animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentDetailsPopupComponent implements OnInit, OnDestroy {
  public loading = false;
  public submittingIssue = false;
  public declaredValue = 0;
  public comment = '';
  public selectedDeclarationUrl = '';
  public showZoomedImage = false;

  public get shipment() {
    return this._shipment;
  }

  @Input()
  public set shipment(value) {
    this._shipment = value;
    if (value) {
      this.calculateDeclaredValue();
    }
  }

  @Input() shipmentType = '';
  @Output() closeNav = new EventEmitter();
  @Output() closeNavAndReloadShipments = new EventEmitter();
  @Output() closeNavAndEditShipment = new EventEmitter();
  @Output() cancelShipment = new EventEmitter();

  private _shipment;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected http: Services.UsgmHttp,
    protected apiMapping: Services.ApiMapping,
    protected notificationService: Services.NotificationService,
    protected userDataService: Services.UserDataService,
  ) {}

  ngOnInit() {}

  calculateDeclaredValue() {
    if (Object.keys(this.shipment || {}).length) {
      const declarationDataArray = this.shipment.declaration_data || [];
      let declaredValue = 0;
      (declarationDataArray || []).forEach(declarationData => {
        (declarationData.declarations || []).forEach(declaration => {
          declaredValue += declaration.item_value * (declaration.quantity || 1);
        });
      });
      this.declaredValue = declaredValue;
      SharedHelpers.detectChanges(this.cdr);
    }
  }

  submitIssueComment() {
    if (this.comment.length < 2) {
      this.notificationService.showError('Please enter at least 2 characters.');
      return;
    }
    this.submittingIssue = true;
    const payload = {
      requestID: this.shipment.id,
      comment: this.comment,
      reportAnIssueType: IssueReportedRequestType.SHIPMENT_REQUEST,
      requestedOn: this.shipment.isoCreatedOn,
    };
    this.http.post(this.apiMapping.reportedIssueByCustomer(), { ...payload }).subscribe(
      (response: { status: Status; message: string }) => {
        this.comment = '';
        this.shipment.contactSupportEnabled = false;
        this.submittingIssue = false;
        SharedHelpers.detectChanges(this.cdr);
        this.notificationService.showSuccess(response.message);
      },
      error => {
        this.submittingIssue = false;
        this.notificationService.showError(error.message);
      },
    );
  }

  checkShipmentStatusForSupport(shipment) {
    return [
      `PICKING_MISSING_ITEM`,
      `PICKING_FOUND_ITEM`,
      `PICKING_WRONG_ENTRY`,
      `PACKING`,
      `PROCESSING`,
      `WAITING_FOR_PAYMENT`,
      `PAYMENT_RECEIVED`,
      `PARTIALLY_PROCESSED`,
      `PROCESSED`,
      `QUOTE_SENT`,
      `QUOTE_DECIDED`,
      `SHIPMENT`,
      `DELETED`,
    ].includes(shipment.shipment_status);
  }

  redirectBasedOnService(serviceName, trackingNumber = null) {
    Helpers.redirectBasedOnService(serviceName, trackingNumber);
  }

  editRequest() {
    this.closeNavAndEditShipment.emit(this.shipment);
  }

  formattedArrivalDate(dateString) {
    return SharedHelpers.stringToLocaleDate(dateString);
  }

  closeSideNavAndReloadShipments() {
    this.closeNavAndReloadShipments.emit({});
  }

  confirmCancel() {
    this.cancelShipment.next({});
  }

  closeSideNav() {
    this.closeNav.emit({});
  }

  allActionsDisabled() {
    return this.userDataService.getAccountStatus() === AccountStatus.SUSPENDED;
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
