import { Component, Input } from '@angular/core';

const TYPE_TO_ICONS = {
  error: 'error',
  info: 'info',
  success: 'check_circle',
  warning: 'warning',
} as const;

type TInfoBoxType = keyof typeof TYPE_TO_ICONS;
type TIcon = (typeof TYPE_TO_ICONS)[TInfoBoxType];

@Component({
  selector: 'usgm-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class InfoBoxComponent {
  @Input() message: string = '';
  @Input() icon: TIcon = 'info';
  @Input() type: TInfoBoxType = 'success';
  @Input() actionLink: string = '';
  @Input() actionLinkParams: any;
  @Input() actionText: string = '';
  @Input() actionInNewTab: boolean = false;

  public getClassObject(): any {
    return {
      'info-card-content': true,
      ['box-' + this.type]: true,
    };
  }
}
