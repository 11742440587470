import { saveAs } from 'file-saver';
import { AccountStatus, IAddressAttributes, IPlanAttributes, MailType } from '@usgm/usgm-payloads-library-front';
// import { File } from '@ionic-native/file/ngx';
import {
  DEPOSIT_CHECK_NON_TRACKABLE_SERVICE,
  nonTrackableMailTypeUSPSDomestic,
  nonTrackableMailTypeUSPSInternationl,
  nonTrackableServiceType,
  PICKUP_SERVICE_TYPE,
} from './constants';
import { AccountNamesModel } from '../models/account-names-model';
import * as jsZip from 'jszip';
import * as moment from 'moment-timezone';
import { Relation } from '../models/get-notarized.model';
import { AppRoutes } from '../models/constants/app-routes.constant';

const OS_Android = 'Android';
const OS_iOS = 'iOS';

export function currentUrlAsRef(successRedirect: string, errorRedirect: string, warehouseId?: string): string {
  let currentURL = '?successRef=' + successRedirect + '&errorRef=' + errorRedirect;
  if (warehouseId) {
    currentURL += '&warehouseId=' + warehouseId;
  }
  return currentURL;
}

export function isServiceTrackableForDepositCheck(shipmentService, isTrackable) {
  return DEPOSIT_CHECK_NON_TRACKABLE_SERVICE.includes(shipmentService) ? false : isTrackable;
}

export function checkIfTrackable(country, mailTypes, shipmentService, parcelInfo = []) {
  if (country === '') {
    return false;
  }
  if (shipmentService === PICKUP_SERVICE_TYPE) {
    return false;
  }
  if (!nonTrackableServiceType.includes(shipmentService)) {
    return true;
  }
  if (parcelInfo && parcelInfo.length && parcelInfo[0]?.label_info?.is_only_documents) {
    return false;
  }
  const nonTrackable = country === 'US' || 'USA' || 'United States' ? nonTrackableMailTypeUSPSDomestic : nonTrackableMailTypeUSPSInternationl;
  return !mailTypes.every(item => nonTrackable.includes(item));
}

export function addressAttributesToString(address: IAddressAttributes): string {
  return `${address.address_line} ${address.address_line_2 || ''} ${address.address_line_3 || ''}, ${address.city},
     ${address.state}, ${address.postal_code}, ${address.country}`;
}

export function iconFontClassToText(className) {
  let text = '';
  if (className === 'icon-suitcase') {
    text = String.fromCharCode(0xe90e);
  } else if (className === 'icon-minivan') {
    text = String.fromCharCode(0xe90b);
  } else if (className === 'icon-bag') {
    text = String.fromCharCode(0xe900);
  } else if (className === 'icon-umbrella') {
    text = String.fromCharCode(0xe910);
  } else if (className === 'icon-briefcase') {
    text = String.fromCharCode(0xe902);
  } else if (className === 'icon-cart') {
    text = String.fromCharCode(0xe903);
  } else if (className === 'icon-box') {
    text = String.fromCharCode(0xe901);
  } else if (className === 'icon-truck') {
    text = String.fromCharCode(0xe90f);
  } else if (className === 'icon-globe') {
    text = String.fromCharCode(0xe908);
  } else if (className === 'icon-check') {
    text = String.fromCharCode(0xe918);
  } else if (className === 'icon-mail') {
    text = String.fromCharCode(0xe916);
  } else if (className === 'icon-profile') {
    text = String.fromCharCode(0xe917);
  } else if (className === 'icon-lock') {
    text = String.fromCharCode(0xe915);
  } else if (className === 'icon-star') {
    text = String.fromCharCode(0xe919);
  } else if (className === 'icon-step-item') {
    text = String.fromCharCode(0xe914);
  } else if (className === 'icon-clock') {
    text = String.fromCharCode(0xe924);
  } else if (className === 'icon-reload') {
    text = String.fromCharCode(0xe922);
  } else if (className === 'icon-clipboard') {
    text = String.fromCharCode(0xe921);
  } else if (className === 'icon-trash') {
    text = String.fromCharCode(0xe920);
  } else if (className === 'icon-settings') {
    text = String.fromCharCode(0xe923);
  }
  return text;
}

export const emailPattern =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phoneNumberPattern = /^\d{8,15}$/;

export const detectChanges = cdr => {
  if (cdr && !cdr['destroyed']) {
    cdr.detectChanges();
  }
};

export const weekendFilter = (d: Date): boolean => {
  const warehouseDate = moment(d).hours(13).tz('America/Chicago');
  const day = warehouseDate.weekday();
  const holidays = [
    [6, 4], // July 4
    [8, 2], // September 2 (Labour Day)
    [10, 28], // November 28 (Thanksgiving Day)
    [11, 25], // December 25 (Christmas Day)
    [0, 1], // January 1 (New Year Day)
  ];
  return day !== 0 && day !== 6 && !holidays.find(it => it[0] === warehouseDate.month() && it[1] === warehouseDate.date()); // saturday, sunday and weekends not allowed
};

export const nextDaysDate = date => {
  const tomorrow = new Date(date);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0);
  tomorrow.setMinutes(0);
  return tomorrow;
};

export const daysExpiredTillDate = (dateString: string) => {
  const today: any = new Date();
  const referenceDate: any = new Date(dateString);
  return Math.floor((today - referenceDate) / (1000 * 60 * 60 * 24));
};

export const isToday = dateToCheck => {
  const today = new Date();
  return dateToCheck.getDate() === today.getDate() && dateToCheck.getMonth() === today.getMonth() && dateToCheck.getFullYear() === today.getFullYear();
};

export const formatTime = date => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return hours + ':' + minutes + ' ' + ampm;
};

export const stringToLocaleDate = (inputDate: string): string => {
  const newDate = new Date(inputDate);
  let newString = `${newDate.toLocaleDateString()} ${formatTime(newDate)}`;
  if (isToday(newDate)) {
    newString = `Today ${formatTime(newDate)}`;
  }
  return newString;
};

export const stringToLocaleDateAsSentence = (inputDate: string): string => {
  const newDate = new Date(inputDate);
  let newString = `on ${newDate.toLocaleDateString()} at ${formatTime(newDate)}`;
  if (isToday(newDate)) {
    newString = `today at ${formatTime(newDate)}`;
  }
  return newString;
};

export function haversineDistance(pt1, pt2) {
  const R = 3958.8; // Radius of the Earth in miles
  const rlat1 = pt1.lat * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = pt2.lat * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (pt2.lng - pt1.lng) * (Math.PI / 180); // Radian difference (longitudes)

  return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
}

export const humanizedDuration = (dateString: string, isFuture: boolean) => {
  return isFuture ? `In ${dateString}` : `${dateString} ago`;
};

export const stringToTimeSinceDate = (inputDate: string): string => {
  const differenceInMs = +new Date() - +new Date(inputDate);
  let seconds = Math.floor(differenceInMs / 1000);
  let isFutureDate = false;
  if (seconds < 0) {
    isFutureDate = true;
    seconds = Math.abs(seconds);
  }
  let interval = seconds / 31536000;

  if (interval > 1) {
    return humanizedDuration(`${Math.floor(interval)} year${interval >= 2 ? 's' : ''}`, isFutureDate);
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return humanizedDuration(`${Math.floor(interval)} month${interval >= 2 ? 's' : ''}`, isFutureDate);
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return humanizedDuration(`${Math.floor(interval)} day${interval >= 2 ? 's' : ''}`, isFutureDate);
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return humanizedDuration(`${Math.floor(interval)} hour${interval >= 2 ? 's' : ''}`, isFutureDate);
  }
  interval = seconds / 60;
  if (interval > 1) {
    return humanizedDuration(`${Math.floor(interval)} minute${interval >= 2 ? 's' : ''}`, isFutureDate);
  }
  return humanizedDuration(`${Math.floor(seconds)} second${seconds >= 2 ? 's' : ''}`, isFutureDate);
};

export const extractLabels = (labels: any) => {
  let labelArr: any[];
  labelArr = [];
  for (let j = 0; j < (labels || []).length; j++) {
    labelArr.push(labels[j]['name']);
  }
  return labelArr;
};

export const actionsDisabledForItem = item => {
  return item.scan_info && item.scan_info.status === 'IN_PROCESS';
};

export const sortByDateParam = (array, param, isAscending) => {
  (array || []).sort(function (a, b) {
    const firstDate = new Date(a[param]);
    const secondDate = new Date(b[param]);
    if (isAscending) {
      return firstDate < secondDate ? -1 : firstDate > secondDate ? 1 : 0;
    }
    return firstDate < secondDate ? 1 : firstDate > secondDate ? -1 : 0;
  });
};

export const scanUpdateDateFormat = dateString => {
  return moment.utc(dateString).local().format('MM/DD/YYYY hh:mm a');
};

export const setPropertiesForMail = (mail: any) => {
  mail.arrival_date = stringToLocaleDate(mail.arrival_date);
  mail.sender_name = (mail.sender || {})['name'] || '';
  mail.bookmarked = mail.is_starred;
  mail.tracking_number = (mail.tracking || {})['id'] || '';
  mail.selected = false;
  mail.item_thumbnail_image = mail.image_url;
  mail.item_large_image = mail.image_url;
  mail.arrived_date = mail.arrival_date;
  mail.measurement = mail.measurement || { length: '-', width: '-', height: '-' };
  mail.measurements =
    `${Math.round(parseFloat(mail.measurement['length']) * 100) / 100} x ${Math.round(parseFloat(mail.measurement['width']) * 100) / 100} x` +
    `${Math.round(parseFloat(mail.measurement['height']) * 100) / 100}`;
  mail.weight = Math.round(parseFloat(mail.weight || 0) * 100) / 100;
  if (mail.measurements.indexOf('null') > -1) {
    mail.measurements = 'NA';
  }
  mail.user_labels = extractLabels(mail.labels);
  mail.is_issue_reported = ((mail.issue_info || {}).issue_id || 0) > 0;
  mail.is_ship_allowed_for_item = [MailType.LETTER, MailType.PACKAGE, MailType.CATALOG, MailType.SOFTPAK, MailType.LARGELETTER, MailType.MAGAZINE].includes((mail || {}).mail_type);
  mail.is_scan_allowed_for_item =
    [MailType.LETTER, MailType.LARGELETTER].includes((mail || {}).mail_type) &&
    (!mail?.scan_info?.scan_id || ['COMPLETED', 'CANCELLED', 'DELETED', 'REJECTED'].includes(mail.scan_info.status));
  mail.rescan_allowed = mail?.scan_info && mail?.scan_info?.status !== 'IN_PROCESS';
  mail.is_unbox_allowed_for_item =
    [MailType.PACKAGE, MailType.SOFTPAK].includes((mail || {}).mail_type) &&
    (!mail?.scan_info?.scan_id || ['COMPLETED', 'CANCELLED', 'DELETED', 'REJECTED'].includes(mail.scan_info.status));
  mail.is_reunbox_allowed_for_item = [MailType.PACKAGE, MailType.SOFTPAK].includes((mail || {}).mail_type) && mail?.scan_info?.scan_id && mail?.scan_info?.status !== 'IN_PROCESS';

  if ((mail.scan_info || {}).category_type === 'SCAN_REQUEST') {
    mail.is_scanned = (mail.scan_info || {}).status === 'COMPLETED';
  }
  if (((mail || {}).scan_info || {}).scan_id) {
    mail.scan_info.updated_on_as_sentence = stringToLocaleDateAsSentence(mail.scan_info.updated_on);
    mail.scan_info.updated_on = stringToLocaleDate(mail.scan_info.updated_on);
  }
  (mail.scan_status_transition || []).sort(function (a, b) {
    return (new Date(a) as any) - (new Date(b) as any);
  });
  if (mail?.scan_info?.scan_id && mail?.scan_info.status !== 'CANCELLED') {
    mail.scan_status_transition = (mail.scan_status_transition || []).map(item => {
      let resultString = `${mail.scan_info.category_type === 'UNBOXING_REQUEST' ? 'An open' : 'A scan'}`;
      if (item.status === 'IN_PROCESS') {
        resultString += ` request for this item was requested ${scanUpdateDateFormat(item.updated_on)}.\n`;
      }
      if (item.status === 'COMPLETED') {
        resultString += ` request for this item was completed ${scanUpdateDateFormat(item.updated_on)}.\n`;
      }
      if (item.status === 'DELETED') {
        resultString += ` result for this item was deleted ${scanUpdateDateFormat(item.updated_on)}`;
      }
      return resultString;
    });
  }

  mail.is_discard_allowed_for_item = true;
  mail.shipment_requested = ((mail.shipment_info || {}).shipment_id || 0) > 0;
  mail.is_deposit_check_allowed = (mail.shipment_info || {}).category_type !== 'UNBOXING_REQUEST' && [MailType.LARGELETTER, MailType.LETTER].includes((mail || {}).mail_type);
  mail.is_declare_allowed = [MailType.PACKAGE, MailType.SOFTPAK].includes((mail || {}).mail_type);
  mail.is_return_allowed_for_item = (mail.shipment_info || {}).category_type !== 'UNBOXING_REQUEST';
  mail.more_options_allowed_for_item = mail.is_discard_allowed_for_item || !mail.is_issue_reported || mail.is_deposit_check_allowed || mail.is_return_allowed_for_item;
};

export const setPropertiesForIssue = (issue: any) => {
  issue.updated_on_as_sentence = stringToLocaleDateAsSentence(issue.updated_on);
  issue.updated_on = stringToLocaleDate(issue.updated_on);
  issue.posts = issue.posts || issue.issues;
  issue.user = issue.user || issue.submitted_by;
  (issue.posts || []).forEach(post => {
    post.posted_on = stringToLocaleDate(post.posted_on);
  });
  sortByDateParam(issue.posts, 'posted_on', true);
};

export const setPropertiesForIssues = (issues: any[]) => {
  (issues || []).forEach(issue => {
    setPropertiesForIssue(issue);
  });
  return issues;
};

export const snakeToSentenceCase = (inputString: string) => {
  inputString = (inputString || '').split('_').join(' ').split('-').join(' ');
  return inputString.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const underscoreToSentenceCase = (inputString: string) => {
  inputString = (inputString || '').split('_').join(' ');
  return inputString;
};

export const clone = (data: any) => {
  let node;
  if (Array.isArray(data)) {
    node = data.length > 0 ? data.slice(0) : [];
    node.forEach((e, i) => {
      if ((typeof e === 'object' && e !== ({} as any)) || (Array.isArray(e) && e.length > 0)) {
        node[i] = clone(e);
      }
    });
  } else if (data && typeof data === 'object') {
    node = data instanceof Date ? data : Object.assign({}, data);
    Object.keys(node).forEach(key => {
      if ((typeof node[key] === 'object' && node[key] !== ({} as any)) || (Array.isArray(node[key]) && node[key].length > 0)) {
        node[key] = clone(node[key]);
      }
    });
  } else {
    node = data;
  }
  return node;
};

export const downloadFile = async (blob, fileName, fileType = null) => {
  const newBlob = new Blob([blob], { type: fileType ? fileType : 'application/pdf' });
  // if (window.cordova && cordova.platformId !== 'browser') {
  //   let storageLocation = '';
  //   const fileObject = new File();
  //   switch ((window as any).device.platform) {
  //     case 'Android':
  //       storageLocation = fileObject.dataDirectory;
  //       break;
  //     case 'iOS':
  //       storageLocation = fileObject.documentsDirectory;
  //       break;
  //   }
  //   try {
  //     await fileObject.writeFile(storageLocation, fileName, blob, { replace: true });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // } else {
  saveAs(newBlob, fileName);
  //}
};

export async function UnZip(blob) {
  jsZip.loadAsync(blob).then(zip => {
    Object.keys(zip.files).forEach(async filename => {
      // for each file in zip
      zip.files[filename].async('blob').then(fileData => {
        // process file as a blob
        processFile(filename, fileData);
      });
    });
    if (Object.keys(zip.files).length > 1) {
      alert('Form 1853 for Multiple users being downloaded, Please provide permission if necessary');
    }
  });
}

export const consumeEnterKeyEvent = event => {
  if (event['keyCode'] === 13) {
    event.preventDefault();
  }
};

export const monthsToFormattedString = months => {
  const years = Math.floor(months / 12);
  const leftOverMonths = months % 12;
  if (leftOverMonths === 0) {
    return `${years} year${years > 1 ? 's' : ''}`;
  } else if (years === 0) {
    return `${months} Month${months > 1 ? 's' : ''}`;
  } else {
    return `${years} year${years > 1 ? 's' : ''}, ${months} Month${months > 1 ? 's' : ''}`;
  }
};

export const getFileType = (fileUrl: string) => {
  const fileName = fileUrl.split('/').pop().split('#')[0].split('?')[0];
  const ext = fileName.substr(fileName.lastIndexOf('.') + 1);
  if (ext.toLowerCase() !== 'pdf') {
    return 'image';
  } else {
    return 'pdf';
  }
};

export const redirectBasedOnService = (serviceName: string, trackingNumber: string) => {
  serviceName = serviceName.toLowerCase();
  let url = '';
  if (trackingNumber) {
    if (serviceName.includes('fedex')) {
      url = `https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`;
    } else if (serviceName.includes('ups')) {
      url = `http://wwwapps.ups.com/WebTracking/track?trackNums=${trackingNumber}&track.x=Track`;
    } else if (serviceName.includes('usps')) {
      url = `https://tools.usps.com/go/TrackConfirmAction!input.action?t&tLabels=${trackingNumber}`;
    } else if (serviceName.includes('dhl')) {
      url = `https://www.dhl.com/content/g0/en/express/tracking.shtml?AWB=${trackingNumber}&brand=DH`;
    } else if (serviceName.includes('aramex')) {
      url = `https://www.aramex.com/express/track-results-multiple.aspx?ShipmentNumber=${trackingNumber}`;
    }
    window.open(url, '_blank');
  }
  return false;
};

async function processFile(filename: string, fileData: any) {
  // if (window.cordova && cordova.platformId !== 'browser') {
  //   let storageLocation = '';
  //   const fileObject = new File();
  //   switch ((window as any).device.platform) {
  //     case OS_Android:
  //       storageLocation = fileObject.dataDirectory;
  //       break;
  //     case OS_iOS:
  //       storageLocation = fileObject.documentsDirectory;
  //       break;
  //   }
  //   try {
  //     await fileObject.writeFile(storageLocation, filename, fileData, { replace: true });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // } else {
  const newBlob = new Blob([fileData], { type: 'application/pdf' });
  saveAs(newBlob, filename);
  // }
}

export function maxNamesForPlan(plan: IPlanAttributes): number {
  return plan?.account_names_plan?.max_names || 0;
}

export function statusFromName(accountNames: AccountNamesModel[], accountName: AccountNamesModel) {
  const status = accountName.accountStatus as AccountStatus;
  if (status === AccountStatus.SUSPENDED) {
    return 'Payment Hold';
  }
  if (accountName.relation && accountName.relation.relationType && [Relation.MINOR_CHILD].includes(accountName.relation.relationType as any)) {
    const primaryUserName = accountNames.find(item => item.relation && item.relation.relationType === Relation.PRIMARY);
    if (primaryUserName.accountStatus === AccountStatus.APPROVED && status === AccountStatus.DOCUMENT_PRIMARY_APPROVED) {
      return 'Approved & active';
    }
  }
  const ID_REJECTED = [AccountStatus.DOCUMENT_PRIMARY_REJECTED, AccountStatus.DOCUMENT_SECONDARY_REJECTED];
  const FORM_REJECTED = [AccountStatus.FORM_1583_REJECTED];
  const APPROVED_AND_ACTIVE = [AccountStatus.FORM_1583_APPROVED, AccountStatus.APPROVED];
  const WAITING_FOR_DOCUMENTS = [
    AccountStatus.ADDED_ADDRESS,
    AccountStatus.CHANGE_OF_ADDRESS_ADDED,
    AccountStatus.CHANGE_OF_ADDRESS_CONFIRMED,
    AccountStatus.DOCUMENT_PRIMARY_ADDED,
    AccountStatus.DOCUMENT_PRIMARY_APPROVED,
    AccountStatus.DOCUMENT_SECONDARY_ADDED,
    AccountStatus.DOCUMENT_SECONDARY_APPROVED,
    AccountStatus.FORM_1583_ADDED,
    AccountStatus.NAME_ADDED,
    AccountStatus.PLAN_SUBSCRIBED,
    AccountStatus.NOTARIZED_DOCUMENT_ADDED,
    AccountStatus.PENDING_TO_ACTIVATION,
  ];
  if (WAITING_FOR_DOCUMENTS.includes(status)) {
    return 'Pending verification';
  } else if (ID_REJECTED.includes(status)) {
    return 'ID rejected';
  } else if (FORM_REJECTED.includes(status)) {
    return 'Form rejected';
  } else if (APPROVED_AND_ACTIVE.includes(status)) {
    return 'Approved & active';
  } else {
    return status
      .toString()
      .split('_')
      .join(' ')
      .replace(/\b\w/g, first => first.toLocaleLowerCase());
  }
}

export function mapUserScanBundle(userScanBundle) {
  const activeScanBundle = (userScanBundle.results || []).filter(bundle => bundle?.status?.toUpperCase() === 'ACTIVE');
  return activeScanBundle.length
    ? {
        id: activeScanBundle[0].BundleOption.id,
        scanBundleId: activeScanBundle[0].id,
        pageCount: activeScanBundle[0].BundleOption.num_pages,
        price: activeScanBundle[0].BundleOption.price,
        planType: activeScanBundle[0].BundleOption.lifetime_days === 365 ? 'year' : 'month',
        usedPageCount: activeScanBundle[0].pages_used,
        expiryDate: moment(activeScanBundle[0].end_date).format('MM/DD/YYYY'),
        subscription_status: activeScanBundle[0]?.subscription_status?.toUpperCase() || '',
      }
    : null;
}

export function urlEncodeQueryParams(data) {
  const params = Object.keys(data).map(key => (data[key] ? `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}` : ''));
  return params.filter(value => !!value).join('&');
}

export function renderRelation(relationValue: string): string {
  if (relationValue === Relation.TRUST) {
    return 'Estate / Trust';
  }
  return relationValue;
}

export function normalizeFullName(docAIResponse: any) {
  let name = docAIResponse.full_name || '';
  if (docAIResponse.first_name && docAIResponse.last_name) {
    name = `${docAIResponse.first_name}${docAIResponse.middle_name ? ' ' + docAIResponse.middle_name : ''}${docAIResponse.last_name ? ' ' + docAIResponse.last_name : ''}`;
  }
  return name;
}

export function findRelevantBetaAppPath(currentPath: string): string {
  const STABLE_TO_BETA_ROUTE_MAP = {
    [AppRoutes.verifyAccount]: '/app/verify-account',
    [AppRoutes.inbox]: '/app/inbox/mails',
    [AppRoutes.scans]: '/app/scans/pending',
    [AppRoutes.shipments]: '/app/shipments/pending',
    [AppRoutes.billingHistory]: '/app/billing-history/invoices',
    [AppRoutes.discards]: '/app/discards',
    [AppRoutes.settings]: '/app/settings/account-settings/account-details',
    [AppRoutes.selectWarehouseByAddress]: '/select-virtual-address',
    [AppRoutes.selectPlan]: '/select-plan',
    [AppRoutes.welcome]: '/app/verify-account',
  };
  return STABLE_TO_BETA_ROUTE_MAP[currentPath?.replace(/^\//, '')] || '/';
}
