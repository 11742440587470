// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.packages-box {
  border-bottom: 1px solid #999292;
  margin: 0 10px;
  padding: 20px;
}
.packages-box:last-child {
  border-bottom: none;
}
.packages-box .package-name {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #4c4c4c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}
.packages-box .package-price {
  color: #66d165;
  font-size: 48px;
  line-height: 48px;
  font-weight: 300;
  margin: 10px 0;
}
.packages-box .package-meta {
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  font-weight: 300;
  text-align: left;
  margin-bottom: 30px;
}
.packages-box .btn {
  padding: 9px 16px;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 16px;
  display: block;
  margin-bottom: 12px;
  width: 250px;
}`, "",{"version":3,"sources":["webpack://./src/app/settings-page/change-warehouse/steps/select-plan-step/select-plan-step.component.scss"],"names":[],"mappings":"AAEA;EACE,gCAAA;EACA,cAAA;EACA,aAAA;AADF;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,qBAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAFJ;AAKE;EACE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAHJ;AAME;EACE,eAAA;EACA,iBAAA;EACA,SAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AAJJ;AAOE;EACE,iBAAA;EACA,gBAAA;EACA,0BAAA;EACA,eAAA;EACA,cAAA;EACA,mBAAA;EACA,YAAA;AALJ","sourcesContent":["@import '../../../../../styles/variables.scss';\n\n.packages-box {\n  border-bottom: 1px solid #999292;\n  margin: 0 10px;\n  padding: 20px;\n\n  &:last-child {\n    border-bottom: none;\n  }\n\n  .package-name {\n    letter-spacing: 0.1em;\n    text-transform: uppercase;\n    color: #4c4c4c;\n    font-style: normal;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 16px;\n  }\n\n  .package-price {\n    color: #66d165;\n    font-size: 48px;\n    line-height: 48px;\n    font-weight: 300;\n    margin: 10px 0;\n  }\n\n  .package-meta {\n    font-size: 16px;\n    line-height: 26px;\n    margin: 0;\n    font-weight: 300;\n    text-align: left;\n    margin-bottom: 30px;\n  }\n\n  .btn {\n    padding: 9px 16px;\n    font-weight: 400;\n    text-transform: capitalize;\n    font-size: 16px;\n    display: block;\n    margin-bottom: 12px;\n    width: 250px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
