import { Observable } from 'rxjs';
import { UsgmHttp } from './usgm-http.service';
import { ApiMapping } from './api-mapping.service';
import { ListOptions } from '../models/list-options';
import { MicroserviceType } from '../models/enums/microservices';

export class BaseService<Model> {
  constructor(
    protected routePath: string,
    protected httpClient: UsgmHttp,
    protected apiMapping: ApiMapping,
    protected serviceType: MicroserviceType,
  ) {}

  getList(listOptions: ListOptions): Observable<any> {
    const filters = listOptions.filters && Object.keys(listOptions.filters).length ? listOptions.filters : null;
    const url = this.apiMapping.buildQueryParams(
      this.apiMapping.getCRUDServiceListPath(this.routePath, this.serviceType),
      listOptions.page,
      listOptions.limit,
      listOptions.order[0],
      listOptions.order[1],
      filters,
    );
    return this.httpClient.get(url);
  }

  update(id: number, body: Partial<Model>): Observable<any> {
    return this.httpClient.put(this.apiMapping.getCRUDServiceUpdatePath(this.routePath, id, this.serviceType), body);
  }

  create(body: Partial<Model>): Observable<any> {
    return this.httpClient.post(this.apiMapping.getCRUDServiceCreatePath(this.routePath, this.serviceType), body);
  }

  get(id: number): Observable<any> {
    return this.httpClient.get(this.apiMapping.getCRUDServiceGetPath(this.routePath, id, this.serviceType));
  }

  delete(id: number): Observable<any> {
    return this.httpClient.delete(this.apiMapping.getCRUDServiceDeletePath(this.routePath, id, this.serviceType));
  }

  softDelete(id: number): Observable<any> {
    return this.httpClient.delete(this.apiMapping.getCRUDServiceSoftDeletePath(this.routePath, id, this.serviceType));
  }
}
