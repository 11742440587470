import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import * as Services from '../services';
import * as SettingsConstants from '../models/constants/settings-options.constant';
import * as SharedHelpers from '../utils/helpers';
import { MatDialog } from '@angular/material/dialog';
import { ChangePlanPopupComponent } from './change-plan-popup/change-plan-popup.component';
import { HeaderComponent } from '../components';

@Component({
  selector: 'usgm-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss', '../inbox/inbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsPageComponent implements OnInit, OnDestroy {
  public settingsOptions = [];
  public settingsOptionsList = [];
  public loadingSettingsOptions = true;
  public SettingsNavOptions = SettingsConstants.SettingNavViewOptions;
  public mobileMode = false;
  public selectedSettingOption = -1;
  public isRejected: boolean = Boolean(false);
  public scanBundleSupported = true;

  private userData: any = {};

  @ViewChild('settingsSubMenuDrawer') settingsSubMenuDrawer: MatSidenav;
  @ViewChild('usgmHeader') usgmHeader: HeaderComponent;

  constructor(
    private cdr: ChangeDetectorRef,
    private http: Services.UsgmHttp,
    private router: Router,
    private userDataService: Services.UserDataService,
    private apiMapping: Services.ApiMapping,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private verifyService: Services.VerifyAccountService,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDisplayMode();
  }

  async ngOnInit() {
    let provisionalSettingsOptions = JSON.parse(JSON.stringify(SettingsConstants.SettingsOptions));
    this.userData = this.userDataService.getDecodedAccessToken(this.userDataService.getAccessToken()) || {};
    if (this.userData.orgParentId) {
      provisionalSettingsOptions = provisionalSettingsOptions.filter(it => !it.excludeForSubUsers);
    }
    if (this.userData.orgRole === 'REGULAR') {
      provisionalSettingsOptions = provisionalSettingsOptions.filter(it => !it.excludeForRegularSubUsers);
    }
    SharedHelpers.detectChanges(this.cdr);
    this.verifyService.getValue().subscribe(value => {
      this.isRejected = value;
      SharedHelpers.detectChanges(this.cdr);
    });
    this.userDataService.getSettingsAccountNamesData();

    this.setDisplayMode();
    SharedHelpers.detectChanges(this.cdr);
    this.settingsOptionsList = provisionalSettingsOptions;
    this.groupSettingsOptions();
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.activatedRoute.snapshot.queryParamMap.has('page')) {
        this.settingsOptionsList.forEach(settingOption => {
          if (`${settingOption.index}` === params['page']) {
            this.settingsOptionClicked(settingOption);
            this.router.navigate([this.router.url.split('?')[0]], { queryParams: {} });
            setTimeout(() => this.settingsOptionClicked(settingOption), 10);
          }
        });
      }
    });
  }

  public groupSettingsOptions() {
    const settingGroupingArray = [];
    for (const property in SettingsConstants.SettingOptionsGroups) {
      if (property && SettingsConstants.SettingOptionsGroups[property]) {
        const groupSettingsOptions = this.settingsOptionsList.filter(item => item.group === SettingsConstants.SettingOptionsGroups[property]);
        if (groupSettingsOptions.length) {
          settingGroupingArray.push({
            title: SettingsConstants.SettingOptionsGroups[property],
            settingsOptions: groupSettingsOptions,
          });
        }
      }
    }
    this.loadingSettingsOptions = false;
    this.settingsOptions = settingGroupingArray;
    SharedHelpers.detectChanges(this.cdr);
  }

  checkIfRejected(status: string) {
    return status.toLowerCase().includes('rejected');
  }

  public setDisplayMode() {
    this.mobileMode = this.userDataService.isMobileMode;
  }

  public settingsOptionClicked(settingsOption: any) {
    console.log(settingsOption);
    if (
      settingsOption.index === SettingsConstants.SettingNavViewOptions.PORTAL_ACCOUNT_DETAILS ||
      settingsOption.index === SettingsConstants.SettingNavViewOptions.PORTAL_BILLING_ADDRESS ||
      settingsOption.index === SettingsConstants.SettingNavViewOptions.PORTAL_PAYMENT_SOURCES
    ) {
      this.openSection(settingsOption.section_type);
      return;
    }
    this.settingsOptionsList.forEach(element => (element.selected = false));
    settingsOption.selected = true;
    this.selectedSettingOption = settingsOption.index;
    this.userDataService.setScreenForAnalytics(settingsOption.name);
    this.settingsSubMenuDrawer.open();
    SharedHelpers.detectChanges(this.cdr);
  }

  public closeSettingOptionDrawer() {
    this.selectedSettingOption = -1;
    this.settingsSubMenuDrawer.close();
  }

  public openSection(sectionType) {
    const { portalInstance, subscriptionId } = this.userDataService.getPortalInstance();
    if (portalInstance) {
      portalInstance.openSection(
        {
          sectionType,
          params: { subscriptionId },
        },
        {
          close: () => {},
          subscriptionChanged: data => {
            this.vendorSubscriptionUpdated(data.subscription.id);
          },
          subscriptionCancelled: data => {
            this.userDataService.vendorSubscriptionChanged();
          },
        },
      );
    }
  }

  public vendorSubscriptionUpdated(vendorSubscriptionId: string) {
    this.http.http_post(this.apiMapping.updateUserSubscriptionForVendor(vendorSubscriptionId), {}).then(response => {
      this.userDataService.vendorSubscriptionChanged();
    });
  }

  public showChangePlanPopup() {
    const dialogRef = this.dialog.open(ChangePlanPopupComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  public updateHeaderWarningStatusForAccountClosure() {
    this.usgmHeader.getUserImportantDates();
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
