// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signature-pad-container {
  margin-top: 40px;
}
.signature-pad-container .signature-pad-body {
  text-align: center;
}
.signature-pad-container .signature-pad-body canvas {
  background-color: #f5f9fa;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.disabled {
  cursor: none;
  opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/app/resolve-form-error/sign-form-popup/signature-pad-popup/signature-pad-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AAAI;EAGE,yBAAA;EACA,uHAAA;AAAN;;AAKA;EACE,YAAA;EACA,YAAA;AAFF","sourcesContent":[".signature-pad-container {\n  margin-top: 40px;\n  .signature-pad-body {\n    text-align: center;\n\n    canvas {\n      //width: 400px;\n      //height: 120px;\n      background-color: #f5f9fa;\n      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);\n    }\n  }\n}\n\n.disabled {\n  cursor: none;\n  opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
