import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {
  constructor() {}

  set(key: string, value: string, expires?: Date): void {
    let cookieValue = `${key}=${value};secure;`;
    if (expires) {
      cookieValue += `;expires='${expires.toUTCString()}'`;
    }
    document.cookie = cookieValue;
  }

  deleteCookie(key: string): void {
    this.set(key, '', new Date(+0));
  }

  get(key: string): string {
    const decodedCookie: string = decodeURIComponent(document.cookie);
    const pairs: string[] = decodedCookie.split(/;\s*/);

    const prefix = `${key}=`;
    for (const pair of pairs) {
      if (pair.indexOf(prefix) === 0) {
        return pair.substring(prefix.length);
      }
    }
    return '';
  }
}
