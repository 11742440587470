// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  min-height: 300px;
  min-width: 240px;
}
:host .viewer-nav-bar {
  width: 100%;
  z-index: 1;
  background: white;
  border-bottom: 1px solid #ccc;
}
:host .viewer-nav-bar .close-button {
  position: absolute;
  top: -7px;
  right: 0;
  background-color: #fefefe;
  color: #000;
}
:host .pdf-container {
  width: calc(100vw - 20px);
  max-width: 800px;
  overflow: hidden;
}
:host img,
:host pdf-viewer {
  display: none;
}
:host img.show,
:host pdf-viewer.show {
  display: block;
}
:host pdf-viewer {
  width: 100%;
  max-width: 100vw;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pdf-viewer-dialog/pdf-viewer-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;AACF;AACE;EACE,WAAA;EACA,UAAA;EACA,iBAAA;EACA,6BAAA;AACJ;AACI;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,yBAAA;EACA,WAAA;AACN;AAGE;EACE,yBAAA;EACA,gBAAA;EACA,gBAAA;AADJ;AAIE;;EAEE,aAAA;AAFJ;AAII;;EACE,cAAA;AADN;AAKE;EACE,WAAA;EACA,gBAAA;AAHJ","sourcesContent":[":host {\n  display: block;\n  position: relative;\n  min-height: 300px;\n  min-width: 240px;\n\n  .viewer-nav-bar {\n    width: 100%;\n    z-index: 1;\n    background: white;\n    border-bottom: 1px solid #ccc;\n\n    .close-button {\n      position: absolute;\n      top: -7px;\n      right: 0;\n      background-color: #fefefe;\n      color: #000;\n    }\n  }\n\n  .pdf-container {\n    width: calc(100vw - 20px);\n    max-width: 800px;\n    overflow: hidden;\n  }\n\n  img,\n  pdf-viewer {\n    display: none;\n\n    &.show {\n      display: block;\n    }\n  }\n\n  pdf-viewer {\n    width: 100%;\n    max-width: 100vw;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
