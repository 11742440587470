import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';
import * as Helpers from '../../utils/helpers';

@Component({
  selector: 'usgm-shipments-table',
  templateUrl: './shipments-table.component.html',
  styleUrls: ['./shipments-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentsTableComponent implements OnInit {
  private _numHeaders = 6;

  @Input() shipments = [];
  @Input() tableType = '';
  @Input() rowHeight = 40;
  @Output() shipmentDetailClicked = new EventEmitter();
  @Output() fetchMoreCalled = new EventEmitter();
  @Output() contactSupportClicked = new EventEmitter();

  constructor() {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.fixHeadersWidth();
  }

  public ngOnInit() {
    this.fixHeadersWidth();
  }

  public fixHeadersWidth() {
    setTimeout(() => {
      for (let i = 1; i <= this._numHeaders; i++) {
        const element = document.querySelectorAll('usgm-shipments-table .header-' + i)[0];
        if (element) {
          element.removeAttribute('width');
        }
      }
    }, 10);
    setTimeout(() => {
      for (let i = 1; i <= this._numHeaders; i++) {
        const element = document.querySelectorAll('usgm-shipments-table .header-' + i)[0];
        if (element) {
          element.setAttribute('width', Math.min(element.clientWidth, 350) + 'px');
        }
      }
    }, 100);
  }

  public shipmentClicked(shipment: any) {
    this.shipmentDetailClicked.emit(shipment);
  }

  public contactSupport(shipment: any) {
    shipment.contactSupportEnabled = true;
    this.contactSupportClicked.emit(shipment);
  }

  public trackItems(index, item) {
    return item.id;
  }

  redirectBasedOnService(serviceName, trackingNumber = null) {
    Helpers.redirectBasedOnService(serviceName, trackingNumber);
  }

  public fetchMore(event: IPageInfo) {
    if (!this.shipments) {
      return;
    }
    if (event.endIndex !== this.shipments.length - 1) {
      return;
    }
    this.fetchMoreCalled.emit(true);
  }
}
