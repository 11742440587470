import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { AnnouncementsDialogComponent } from './announcements-dialog.component';
import { HeaderComponent } from './header.component';
import { BetaBadgeComponent } from '../beta-badge/beta-badge.component';

@NgModule({
  declarations: [AnnouncementsDialogComponent, HeaderComponent, BetaBadgeComponent],
  imports: [BrowserModule, CommonModule, MatButtonModule, MatMenuModule, MatPaginatorModule, MatProgressSpinnerModule, MatTooltipModule, RouterModule],
  exports: [AnnouncementsDialogComponent, HeaderComponent, BetaBadgeComponent],
})
export class HeaderModule {}
